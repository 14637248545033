function create_PKCS8_enc(e, t, r, i) {
  return new Promise(function (t, n) {
    var s = (Promise.resolve(), new org.pkijs.simpl.cms.PBKDF2_params());
    (s.salt = new org.pkijs.asn1.OCTETSTRING({ value_hex: e.salt.buffer })),
      (s.iterationCount = e.iterations);
    var a = s.toSchema(),
      o = new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
        algorithm_id: r,
        algorithm_params: a,
      }),
      c = new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
        algorithm_id: i,
        algorithm_params: new org.pkijs.asn1.OCTETSTRING({
          value_hex: e.iv.buffer,
        }),
      }),
      l = new org.pkijs.simpl.pkcs8.PARAMETERS({
        EncAlgWPBKDF2params: o,
        encryptionScheme: c,
      }),
      u = new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
        algorithm_id: SD_PBES2,
        algorithm_params: l.toSchema(),
      }),
      p = e.cipherText.toString(CryptoJS.enc.Base64),
      h = _base64ToArrayBuffer(p),
      f = new org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52({
        encryptionAlgorithm: u,
        encryptedData: new org.pkijs.asn1.OCTETSTRING({ value_hex: h }),
      }),
      g = f.toSchema(),
      m = g.toBER(!1);
    t(m);
  });
}
function SDSgLib_openOldPKCS8PrivateKey(e, t, r) {
  var i = forge.asn1,
    n = i.fromDer(e);
  if (16 != n.type) return void r("Invalid TAG " + n.type, null);
  if (2 != n.value.length)
    return void r("Invalid number of objects found " + n.value.length, null);
  if (16 != n.value[0].type)
    return void r(
      "Invalid tag for internal object found " + n.value[0].type,
      null
    );
  if (4 != n.value[1].type)
    return void r(
      "Invalid tag for internal object found " + n.value[1].type,
      null
    );
  if (2 != n.value[0].value.length)
    return void r(
      "Invalid number of objects found in internal SEQ " +
        n.value[0].value.length,
      null
    );
  var s = i.derToOid(n.value[0].value[0].value);
  if ("1.2.840.113549.1.5.3" != s)
    return void r("Unsupported algorithm " + s, null);
  if (2 != n.value[0].value[1].value.length)
    return void r(
      "Invalid number of objects found in internal SEQ while retrieving parameters " +
        n.value[0].value.length,
      null
    );
  var a = n.value[0].value[1].value[0].value,
    o =
      (parseInt(forge.util.bytesToHex(n.value[0].value[1].value[1].value), 16),
      8),
    c = forge.md.md5.create(),
    l = forge.pbe.opensslDeriveBytes(t, a, o, c),
    u = forge.util.createBuffer(l),
    e = u.getBytes(o);
  var p = n.value[1].value;
  try {
    var h = forge.cipher.createDecipher("DES-CBC", e);
    h.start({ iv: a }), h.update(forge.util.createBuffer(p));
    var f = (h.finish(), h.output.getBytes());
    if (48 != f[0])
      return void r(
        "Unable to decrypt private key, maybe wrong password",
        null
      );
  } catch (g) {º
  }
  r(null, "OK");
}
function SgLib_PBKDF2(e, t, r, i) {
  var n = forge.util.hexToBytes(t);
  return new Promise(function (t, s) {
    try {
      var a = forge.pkcs5.pbkdf2(e, n, r, i / 8, "sha1"),
        o = forge.util.bytesToHex(a);
      t(o);
    } catch (c) {
      s(c);
    }
  });
}
function SDSgLib_encodePKCS7Detached(e, t, r) {
  var i = null;
  return SDSgLib_encodePKCS7(e, t, r, i);
}
function SDSgLib_encodePKCS7WithContent(e, t, r, i) {
  return SDSgLib_encodePKCS7(e, t, r, i);
}
function SDSgLib_encodePKCS7(e, t, r, i) {
  var n = "";
  if (r == SD_SHA1_NAME) n = SD_SHA1_DOID;
  else if (r == SD_SHA256_NAME) n = SD_SHA256_DOID;
  else if (r == SD_SHA384_NAME) n = SD_SHA384_DOID;
  else {
    if (r != SD_SHA512_NAME)
      return new Promise(function (e, t) {
        t(SD_UNKNOWN_ALGORITHM);
      });
    n = SD_SHA512_DOID;
  }
  return t instanceof ArrayBuffer && null != t && 0 != t.length
    ? "string" != typeof e || null == e || 0 == e.length
      ? new Promise(function (e, t) {
          t(SD_ERROR_EMPTY_CERTIFICATE);
        })
      : new Promise(function (r, s) {
          var a = org.pkijs.fromBER(str2ab(e)),
            o = new org.pkijs.simpl.CERT({ schema: a.result }),
            c = Promise.resolve();
          (c = c.then(function () {
            var e = new org.pkijs.simpl.cms.EncapsulatedContentInfo({
              eContentType: DATA_CONTENT_TYPE,
            });
            return (
              null != i &&
                (e.eContent = new org.pkijs.asn1.OCTETSTRING({ value_hex: i })),
              new org.pkijs.simpl.CMS_SIGNED_DATA({
                version: 1,
                digestAlgorithms: [
                  new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: n,
                    algorithm_params: new org.pkijs.asn1.NULL(),
                  }),
                ],
                encapContentInfo: e,
                signerInfos: [
                  new org.pkijs.simpl.CMS_SIGNER_INFO({
                    version: 1,
                    sid: new org.pkijs.simpl.cms.IssuerAndSerialNumber({
                      issuer: o.issuer,
                      serialNumber: o.serialNumber,
                    }),
                    digestAlgorithm: new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                      algorithm_id: n,
                      algorithm_params: new org.pkijs.asn1.NULL(),
                    }),
                    signatureAlgorithm:
                      new org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                        algorithm_id: SD_RSAENCRYPTION,
                      }),
                    signature: new org.pkijs.asn1.OCTETSTRING({ value_hex: t }),
                  }),
                ],
                certificates: [o],
              })
            );
          })),
            c.then(
              function (e) {
                var t = e.toSchema(!0),
                  i = new org.pkijs.simpl.CMS_CONTENT_INFO({
                    contentType: CMS_SIGNED_DATA,
                    content: t,
                  }),
                  t = i.toSchema(!0),
                  n = t.toBER(!1);
                r(n);
              },
              function (e) {
                alert(SD_ERROR_ENCODING_PKCS7 + e);
              }
            );
        })
    : new Promise(function (e, t) {
        t(SD_ERROR_EMPTY_SIGNATURE);
      });
}
function decipher3DES(e, t, r) {
  try {
    var i = CryptoJS.TripleDES.decrypt({ ciphertext: e }, t, {
      iv: r,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    void 0 === i && (i = forge.util.hexToBytes("00"));
  } catch (n) {
    return forge.util.hexToBytes("00");
  }
  return i;
}
function decipherAES(e, t, r) {
  var i = forge.util.hexToBytes("00"),
    n = forge.cipher.createDecipher("AES-CBC", t);
  n.start({ iv: r }), n.update(e);
  var s = n.finish();
  return s && (i = n.output.getBytes()), i;
}
function cipher3DES(e, t, r) {
  var i = CryptoJS.TripleDES.encrypt(
    CryptoJS.enc.Hex.parse(e),
    CryptoJS.enc.Hex.parse(t),
    {
      iv: CryptoJS.enc.Hex.parse(r),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return i.ciphertext;
}
function makeOddParity(e, t) {
  var r = 0,
    i = 0,
    n = 0,
    s = 0;
  for (r = 0; r < t; r++) {
    for (n = 0, i = 0; i < 8; i++) (s = 1), (s <<= i), 0 != (e[r] & s) && n++;
    n % 2 == 0 && (e[r] = 1 ^ e[r]);
  }
  return forge.util.binary.hex.encode(e);
}
function SDSgLib_openPKCS8PrivateKey(e, t) {
  return new Promise(function (r, i) {
    var n, s;
    window.msCrypto
      ? (n = window.msCrypto)
      : window.crypto
      ? (n = window.crypto)
      : i(Error("Web Cryptography API not supported. [No Crypto Namespace]")),
      n.webkitSubtle
        ? (s = n.webkitSubtle)
        : n.subtle
        ? (s = n.subtle)
        : i(
            Error(
              "Web Cryptography API not supported. [No Crypto.Subtle Namespace]"
            )
          );
    var a = org.pkijs.fromBER(rawStringToBuffer(e));
    a.offset === -1 && i(Error(SDSGL_ERR_01));
    var o;
    try {
      var o = new org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52({
        schema: a.result,
      });
    } catch (c) {
      i(Error(SDSGL_ERR_00));
    }
    var l = o.encryptionAlgorithm.parameters.encryptionScheme.algorithm_id;
    l != SD_TripleDES &&
      l != SD_AES_128 &&
      l != SD_AES_192 &&
      l != SD_AES_256 &&
      i(Error(SDSGL_ERR_02 + l));
    var u,
      p =
        o.encryptionAlgorithm.parameters.EncAlgWPBKDF2params.keyDevParams
          .iterationCount,
      h = binStringToHex(
        arrayBufferToString(
          o.encryptionAlgorithm.parameters.EncAlgWPBKDF2params.keyDevParams.salt
            .value_block.value_hex
        )
      ),
      f = binStringToHex(
        arrayBufferToString(o.encryptedData.value_block.value_hex, !1, !0)
      ),
      g = 0;
    switch (l) {
      case SD_TripleDES:
        g = 192;
        break;
      case SD_AES_128:
        g = 128;
        break;
      case SD_AES_192:
        g = 192;
        break;
      case SD_AES_256:
        g = 256;
    }
    var m = forge.util.createBuffer(t);
    SgLib_PBKDF2(m, h, p, g).then(function (e) {
      var n,
        s,
        a = binStringToHex(
          arrayBufferToString(
            o.encryptionAlgorithm.parameters.encryptionScheme.algorithm_params
              .value_block.value_hex,
            !1,
            !0
          )
        );
      l == SD_TripleDES
        ? ((e = makeOddParity(forge.util.binary.hex.decode(e), g / 8)),
          (n = CryptoJS.enc.Hex.parse(e)),
          (s = CryptoJS.enc.Hex.parse(a)),
          (u = CryptoJS.enc.Hex.parse(f)))
        : ((n = forge.util.hexToBytes(e)),
          (s = forge.util.hexToBytes(a)),
          (u = forge.util.hexToBytes(f)));
      var c;
      if (l == SD_TripleDES) c = decipher3DES(u, n, s);
      else {
        var d = forge.util.createBuffer(n, "raw"),
          y = forge.util.createBuffer(s, "raw"),
          k = forge.util.createBuffer(u, "raw");
        c = decipherAES(k, d, y);
      }
      void 0 === c && (c = forge.util.hexToBytes("A0"));
      var v = c.toString(CryptoJS.enc.Latin1);
      v.length <= 0 && i(Error(SDSGL_ERR_03)),
        "0" != v[0]
          ? (
            (m = forge.util.createBuffer(forge.util.encodeUtf8(t))),
            SgLib_PBKDF2(m, h, p, 192).then(function (e) {
              e = makeOddParity(forge.util.binary.hex.decode(e), 24);
              var t = CryptoJS.enc.Hex.parse(e),
                n = decipher3DES(u, t, s),
                a = n.toString(CryptoJS.enc.Latin1);
              a.length <= 0 &&
                (
                i(Error(SDSGL_ERR_03))),
                "0" != a[0] ? i(Error(SDSGL_ERR_03)) : r(a);
            }))
          : r(v);
    });
  });
}
function SDSgLib_cipherPKCS8Key(e, t) {
  return new Promise(function (r, i) {
    var n,
      s,
      a = 2048;
    window.msCrypto
      ? (n = window.msCrypto)
      : window.crypto
      ? (n = window.crypto)
      : i(Error("Web Cryptography API not supported. [No Crypto Namespace]")),
      n.webkitSubtle
        ? (s = n.webkitSubtle)
        : n.subtle
        ? (s = n.subtle)
        : i(
            Error(
              "Web Cryptography API not supported. [No Crypto.Subtle Namespace]"
            )
          );
    var o = new Uint8Array(8);
    n.getRandomValues(o);
    var c = new Uint8Array(8);
    n.getRandomValues(c);
    var l = uint8ArrayToHexStr(o);
    SgLib_PBKDF2(t, l, a, 192).then(function (t) {
      t = makeOddParity(forge.util.binary.hex.decode(t), 24);
      var n = uint8ArrayToHexStr(c),
        s = cipher3DES(arrayBufferToHexString(e), t, n),
        l = { cipherText: s, salt: o, iv: c, iterations: a };
      create_PKCS8_enc(l, SD_PBES2, SD_PBKDF2, SD_TripleDES).then(
        function (e) {
          r(e);
        },
        function (e) {
          i(Error("Error while encoding ciphered key: " + e.message));
        }
      );
    });
  });
}
function signHashO(e, t, r, i, n, s) {
  return new Promise(function (s, a) {
    SDSgLib_openPKCS8PrivateKey(e, t).then(
      function (e) {
        "0" != e[0] && a(Error(determineError(SDSGL_ERR_03)));
        var o;
        (o = formatPEM(window.btoa(e))),
          signHash_2(n, i, r, o, t, doP7, function (e, t) {
            return e ? void a(e) : void s(t);
          });
      },
      function (e) {
        a(Error("Error during getting pkcs8: " + e.message));
      }
    );
  });
}
function pkcs7FromHashO(e, t, r, i, n, s) {
  return new Promise(function (a, o) {
    SDSgLib_openPKCS8PrivateKey(t, e).then(
      function (t) {
        "0" != t[0] && o(Error(determineError(SDSGL_ERR_03)));
        var c;
        (c = formatPEM(window.btoa(t))),
          signHash_2(n, i, forge.util.encode64(r), c, e, s, function (e, t) {
            return e ? void o(Error(e)) : void a(t);
          });
      },
      function (e) {
        o(Error("Error during getting pkcs8: " + e.message));
      }
    );
  });
}
function signHash(e, t, r, i, n, s) {
  return new Promise(function (a, o) {
    SDSgLib_openPKCS8PrivateKey(e, t).then(
      function (e) {
        if ("0" != e[0]) return void o(Error(determineError(SDSGL_ERR_03)));
        var c;
        c = formatPEM(window.btoa(e));
        var l = signHash_2(n, i, r, c, t, s);
        return l.indexOf("[Error] -") != -1 ? void o(l) : void a(l);
      },
      function (e) {
        o(Error("[Error] - Error during getting pkcs8: " + e.message));
      }
    );
  });
}
function determineError(e) {
  var t =
      "La llave privada no pudo abrirse, de favor verifique la clave de acceso proporcionada.",
    r = "Las llaves Privada y Pública proporcionadas no se corresponden.",
    i = "Debe proporcionar una Llave Privada, de favor verifique.",
    n = "De favor proporcione un certificado digital.",
    s = "Este no es un certificado digital, de favor verifique.",
    a = "";
  return (
    e instanceof String ? (a = e) : ((e = e.toString()), (a = e)),
    e.indexOf("Error while decrypting private key") != -1
      ? (a = t)
      : "Error: [Error] - El par de llaves proporcionado no es consistente" == e
      ? (a = r)
      : "[Error] - No se ha seleccionado una llave privada" == e
      ? (a = i)
      : "Error: Error while decoding pkcs#8 encrypted private key stream" == e
      ? (a = i)
      : "Error: [Error] - No se ha seleccionado un certificado digital" == e
      ? (a = n)
      : "Error: Cannot read X.509 certificate. ASN.1 object is not an X509v3 Certificate." ==
        e
      ? (a = s)
      : "Error: Error while decrypting private key" == e
      ? (a = t)
      : "Error: Cannot read private key. ASN.1 object does not contain an RSAPrivateKey." ==
        e
      ? (a = i)
      : "[SgDataCrypto] - Error al desencriptar la llave privada, de favor verifique su password." ==
          e && (a = t),
    a
  );
}
function checkKeyPairConsistency(e, t, r) {
  return new Promise(function (i, n) {
    var s = btoa(t);
    if (s.startsWith("MI")) certificate = CERT_HEADER + s + CERT_TRAILER;
    else if (s.startsWith("LS0tL")) certificate = forge.util.decode64(s);
    else {
      if (!s.startsWith("TUlJ")) {
        var a = "[Error] - No se ha seleccionado un certificado digital";
        return void n(Error(a));
      }
      certificate =
        CERT_HEADER + forge.util.decode64(certB64Byte) + CERT_TRAILER;
    }
    var o = forge.pki,
      c = o.certificateFromPem(certificate);
    SDSgLib_openPKCS8PrivateKey(e, r).then(
      function (e) {
        if ("0" != e[0]) return void n(determineError(Error(SDSGL_ERR_03)));
        var t;
        if (((t = window.btoa(e)), t.startsWith("MI")))
          privKey = KEY_HEADER + t + KEY_TRAILER;
        else if (t.startsWith("LS0tL")) privKey = forge.util.decode64(t);
        else {
          if (!t.startsWith("TUlJ")) {
            var r = "[Error] - No se ha seleccionado una llave privada";
            return void n(Error(r));
          }
          privKey = KEY_HEADER + forge.util.decode64(t) + KEY_TRAILER;
        }
        var s = o.privateKeyFromPem(privKey);
        if (!checkConsistency(s, c.publicKey)) {
          var a = "[Error] - El par de llaves proporcionado no es consistente";
          return void n(Error(a));
        }
        i("true");
      },
      function (t) {
        var s = t.toString();
        if (
          (
          s.indexOf("Unexpected format or file") == -1)
        )
          return void n(Error(s));
        var a = openOldKey(e, r);
        if (a.indexOf("Error") != -1) return void n("" + a);
        var l;
        if (((l = window.btoa(a)), l.startsWith("MI")))
          privKey = KEY_HEADER + l + KEY_TRAILER;
        else if (l.startsWith("LS0tL")) privKey = forge.util.decode64(l);
        else {
          if (!l.startsWith("TUlJ")) {
            var t = "[Error] - No se ha seleccionado una llave privada";
            return void n(Error(t));
          }
          privKey = KEY_HEADER + forge.util.decode64(l) + KEY_TRAILER;
        }
        var u = o.privateKeyFromPem(privKey);
        if (!checkConsistency(u, c.publicKey)) {
          var p = "[Error] - El par de llaves proporcionado no es consistente";
          n(Error(p));
        }
        i("true");
      }
    );
  });
}
function pkcs7FromHash(e, t, r, i, n, s) {
  return new Promise(function (a, o) {
    SDSgLib_openPKCS8PrivateKey(t, e).then(
      function (t) {
        "0" != t[0] &&
          o(
            "[Error] - Error al abrir la llave privada, de favor valide su clave de acceso."
          );
        var c;
        c = formatPEM(window.btoa(t));
        var l = signHash_2(n, i, forge.util.encode64(r), c, e, s);
        l.indexOf("[Error] -") != -1 ? o(l) : a(l);
      },
      function (e) {
        o(
          determineError(
            "[Error] - Error al procesar el formato de llave: " + e.message
          )
        );
      }
    );
  });
}
function empty(e) {
  if ("undefined" == typeof e || null === e) return !0;
}
function str2ab(e) {
  for (
    var t = new ArrayBuffer(e.length),
      r = new Uint8Array(t),
      i = 0,
      n = e.length;
    i < n;
    i++
  )
    r[i] = e.charCodeAt(i);
  return t;
}
function StringToBinary(e) {
  var t, r, i, n, s, a;
  for (
    s = e.length, t = [], n = !1, i = a = 0;
    0 <= s ? a < s : a > s;
    i = 0 <= s ? ++a : --a
  ) {
    if (((r = String.prototype.charCodeAt.call(e, i)), r > 255)) {
      (n = !0), (t = null);
      break;
    }
    t.push(r);
  }
  return n === !0
    ? unescape(encodeURIComponent(e))
    : String.fromCharCode.apply(null, Array.prototype.slice.apply(t));
}
function StringToUint8Array(e) {
  var t, r, i, n, s, a;
  for (
    t = StringToBinary(e),
      r = t.length,
      i = new ArrayBuffer(r),
      n = new Uint8Array(i),
      s = a = 0;
    0 <= r ? a < r : a > r;
    s = 0 <= r ? ++a : --a
  )
    n[s] = String.prototype.charCodeAt.call(t, s);
  return n;
}
function uint8ArrayToHexStr(e) {
  for (var t, r = "", i = 0; i < e.byteLength; i++)
    (t = e[i].toString(16)), t.length < 2 && (t = "0" + t), (r += t);
  return r;
}
function arrayBufferToHexString(e) {
  for (var t, r = new Uint8Array(e), i = "", n = 0; n < r.byteLength; n++)
    (t = r[n].toString(16)), t.length < 2 && (t = "0" + t), (i += t);
  return i;
}
function binStringToHex(e) {
  for (var t, r = "", i = 0, n = e.length; i < n; ++i)
    (t = e.charCodeAt(i)),
      (r = r + (t >> 4).toString(16) + (15 & t).toString(16));
  return r;
}
function rawStringToBuffer(e) {
  var t,
    r = e.length,
    i = new Array(r);
  for (t = 0; t < r; ++t) i[t] = 255 & e.charCodeAt(t);
  return new Uint8Array(i).buffer;
}
function arrayBufferToString(e, t, r) {
  for (
    var i = new Uint8Array(e), n = i.length, s = "", a = 0;
    a < n;
    a += 65535
  ) {
    var o = 65535;
    a + 65535 > n && (o = n - a),
      (s += String.fromCharCode.apply(null, i.subarray(a, a + o)));
  }
  return s ? t && t(s) : r && r("buffer was invalid"), s;
}
function wordToByteArray(e) {
  var t,
    r,
    i,
    n = [];
  for (r = 0; r < e.length; ++r)
    for (t = e[r], i = 3; i >= 0; --i) n.push((t >> (8 * i)) & 255);
  return n;
}
function _base64ToArrayBuffer(e) {
  for (
    var t = window.atob(e), r = t.length, i = new Uint8Array(r), n = 0;
    n < r;
    n++
  )
    i[n] = t.charCodeAt(n);
  return i.buffer;
}
function toHexString(e) {
  return Array.from(e, function (e) {
    return ("0" + (255 & e).toString(16)).slice(-2);
  }).join("");
}
function convertStringToArrayBufferView(e) {
  for (var t = new Uint8Array(e.length), r = 0; r < e.length; r++)
    t[r] = e.charCodeAt(r);
  return t;
}
function convertArrayBufferViewtoString(e) {
  for (var t = "", r = 0; r < e.byteLength; r++) t += String.fromCharCode(e[r]);
  return t;
}
function formatPEM(e) {
  for (var t = e.length, r = "", i = 0, n = 0; i < t; i++, n++)
    n > 63 && ((r = r), (n = 0)), (r += e[i]);
  return r;
}
function base64toBlob(e, t) {
  t = t || "";
  for (
    var r = 1024,
      i = atob(e),
      n = i.length,
      s = Math.ceil(n / r),
      a = new Array(s),
      o = 0;
    o < s;
    ++o
  ) {
    for (
      var c = o * r, l = Math.min(c + r, n), u = new Array(l - c), p = c, h = 0;
      p < l;
      ++h, ++p
    )
      u[h] = i[p].charCodeAt(0);
    a[o] = new Uint8Array(u);
  }
  return new Blob(a, { type: t });
}
function pbkdf1(e, t, r, i) {
  var n,
    s,
    a = forge.util.decodeUtf8(e),
    o = forge.util.bytesToHex(a),
    l = (forge.util.hexToBytes(t), o + t);
  for (s = 0; s < r; s += 1)
    (n = forge.md.md5.create()),
      n.update(forge.util.hexToBytes(l), "binary"),
      (l = n.digest().toHex());
  var u = l;
  l = u.substr(0, 16);
  var p = u.substr(16, 32),
    h = decodeHexStringToByteArray(l);
  for (s = 0; s < 8; s++) {
    for (Cnt1s = 0, j = 0; j < 8; j++)
      (c = 1), (c <<= j), 0 != (h[s] & c) && Cnt1s++;
    Cnt1s % 2 == 0 && (h[s] ^= 1);
  }
  l = toHexString(h);
  var f = forge.cipher.createDecipher("DES-CBC", forge.util.hexToBytes(l));
  f.start({ iv: forge.util.hexToBytes(p) }),
    f.update(forge.util.createBuffer(forge.util.hexToBytes(i)));
  var g = f.finish();
  return g
    ? f.output.toHex()
    : ( "");
}
var CryptoJS =
  CryptoJS ||
  (function (e, t) {
    var r = {},
      i = (r.lib = {}),
      n = function () {},
      s = (i.Base = {
        extend: function (e) {
          n.prototype = this;
          var t = new n();
          return (
            e && t.mixIn(e),
            t.hasOwnProperty("init") ||
              (t.init = function () {
                t.$super.init.apply(this, arguments);
              }),
            (t.init.prototype = t),
            (t.$super = this),
            t
          );
        },
        create: function () {
          var e = this.extend();
          return e.init.apply(e, arguments), e;
        },
        init: function () {},
        mixIn: function (e) {
          for (var t in e) e.hasOwnProperty(t) && (this[t] = e[t]);
          e.hasOwnProperty("toString") && (this.toString = e.toString);
        },
        clone: function () {
          return this.init.prototype.extend(this);
        },
      }),
      a = (i.WordArray = s.extend({
        init: function (e, r) {
          (e = this.words = e || []),
            (this.sigBytes = r != t ? r : 4 * e.length);
        },
        toString: function (e) {
          return (e || c).stringify(this);
        },
        concat: function (e) {
          var t = this.words,
            r = e.words,
            i = this.sigBytes;
          if (((e = e.sigBytes), this.clamp(), i % 4))
            for (var n = 0; n < e; n++)
              t[(i + n) >>> 2] |=
                ((r[n >>> 2] >>> (24 - 8 * (n % 4))) & 255) <<
                (24 - 8 * ((i + n) % 4));
          else if (65535 < r.length)
            for (n = 0; n < e; n += 4) t[(i + n) >>> 2] = r[n >>> 2];
          else t.push.apply(t, r);
          return (this.sigBytes += e), this;
        },
        clamp: function () {
          var t = this.words,
            r = this.sigBytes;
          (t[r >>> 2] &= 4294967295 << (32 - 8 * (r % 4))),
            (t.length = e.ceil(r / 4));
        },
        clone: function () {
          var e = s.clone.call(this);
          return (e.words = this.words.slice(0)), e;
        },
        random: function (t) {
          for (var r = [], i = 0; i < t; i += 4)
            r.push((4294967296 * e.random()) | 0);
          return new a.init(r, t);
        },
      })),
      o = (r.enc = {}),
      c = (o.Hex = {
        stringify: function (e) {
          var t = e.words;
          e = e.sigBytes;
          for (var r = [], i = 0; i < e; i++) {
            var n = (t[i >>> 2] >>> (24 - 8 * (i % 4))) & 255;
            r.push((n >>> 4).toString(16)), r.push((15 & n).toString(16));
          }
          return r.join("");
        },
        parse: function (e) {
          for (var t = e.length, r = [], i = 0; i < t; i += 2)
            r[i >>> 3] |= parseInt(e.substr(i, 2), 16) << (24 - 4 * (i % 8));
          return new a.init(r, t / 2);
        },
      }),
      l = (o.Latin1 = {
        stringify: function (e) {
          var t = e.words;
          e = e.sigBytes;
          for (var r = [], i = 0; i < e; i++)
            r.push(
              String.fromCharCode((t[i >>> 2] >>> (24 - 8 * (i % 4))) & 255)
            );
          return r.join("");
        },
        parse: function (e) {
          for (var t = e.length, r = [], i = 0; i < t; i++)
            r[i >>> 2] |= (255 & e.charCodeAt(i)) << (24 - 8 * (i % 4));
          return new a.init(r, t);
        },
      }),
      u = (o.Utf8 = {
        stringify: function (e) {
          try {
            return decodeURIComponent(escape(l.stringify(e)));
          } catch (t) {
            throw Error("Malformed UTF-8 data");
          }
        },
        parse: function (e) {
          return l.parse(unescape(encodeURIComponent(e)));
        },
      }),
      p = (i.BufferedBlockAlgorithm = s.extend({
        reset: function () {
          (this._data = new a.init()), (this._nDataBytes = 0);
        },
        _append: function (e) {
          "string" == typeof e && (e = u.parse(e)),
            this._data.concat(e),
            (this._nDataBytes += e.sigBytes);
        },
        _process: function (t) {
          var r = this._data,
            i = r.words,
            n = r.sigBytes,
            s = this.blockSize,
            o = n / (4 * s),
            o = t ? e.ceil(o) : e.max((0 | o) - this._minBufferSize, 0);
          if (((t = o * s), (n = e.min(4 * t, n)), t)) {
            for (var c = 0; c < t; c += s) this._doProcessBlock(i, c);
            (c = i.splice(0, t)), (r.sigBytes -= n);
          }
          return new a.init(c, n);
        },
        clone: function () {
          var e = s.clone.call(this);
          return (e._data = this._data.clone()), e;
        },
        _minBufferSize: 0,
      }));
    i.Hasher = p.extend({
      cfg: s.extend(),
      init: function (e) {
        (this.cfg = this.cfg.extend(e)), this.reset();
      },
      reset: function () {
        p.reset.call(this), this._doReset();
      },
      update: function (e) {
        return this._append(e), this._process(), this;
      },
      finalize: function (e) {
        return e && this._append(e), this._doFinalize();
      },
      blockSize: 16,
      _createHelper: function (e) {
        return function (t, r) {
          return new e.init(r).finalize(t);
        };
      },
      _createHmacHelper: function (e) {
        return function (t, r) {
          return new h.HMAC.init(e, r).finalize(t);
        };
      },
    });
    var h = (r.algo = {});
    return r;
  })(Math);
!(function () {
  var e = CryptoJS,
    t = e.lib.WordArray;
  e.enc.Base64 = {
    stringify: function (e) {
      var t = e.words,
        r = e.sigBytes,
        i = this._map;
      e.clamp(), (e = []);
      for (var n = 0; n < r; n += 3)
        for (
          var s =
              (((t[n >>> 2] >>> (24 - 8 * (n % 4))) & 255) << 16) |
              (((t[(n + 1) >>> 2] >>> (24 - 8 * ((n + 1) % 4))) & 255) << 8) |
              ((t[(n + 2) >>> 2] >>> (24 - 8 * ((n + 2) % 4))) & 255),
            a = 0;
          4 > a && n + 0.75 * a < r;
          a++
        )
          e.push(i.charAt((s >>> (6 * (3 - a))) & 63));
      if ((t = i.charAt(64))) for (; e.length % 4; ) e.push(t);
      return e.join("");
    },
    parse: function (e) {
      var r = e.length,
        i = this._map,
        n = i.charAt(64);
      n && ((n = e.indexOf(n)), -1 != n && (r = n));
      for (var n = [], s = 0, a = 0; a < r; a++)
        if (a % 4) {
          var o = i.indexOf(e.charAt(a - 1)) << (2 * (a % 4)),
            c = i.indexOf(e.charAt(a)) >>> (6 - 2 * (a % 4));
          (n[s >>> 2] |= (o | c) << (24 - 8 * (s % 4))), s++;
        }
      return t.create(n, s);
    },
    _map: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  };
})(),
  (function (e) {
    function t(e, t, r, i, n, s, a) {
      return (
        (e = e + ((t & r) | (~t & i)) + n + a),
        ((e << s) | (e >>> (32 - s))) + t
      );
    }
    function r(e, t, r, i, n, s, a) {
      return (
        (e = e + ((t & i) | (r & ~i)) + n + a),
        ((e << s) | (e >>> (32 - s))) + t
      );
    }
    function i(e, t, r, i, n, s, a) {
      return (e = e + (t ^ r ^ i) + n + a), ((e << s) | (e >>> (32 - s))) + t;
    }
    function n(e, t, r, i, n, s, a) {
      return (
        (e = e + (r ^ (t | ~i)) + n + a), ((e << s) | (e >>> (32 - s))) + t
      );
    }
    for (
      var s = CryptoJS,
        a = s.lib,
        o = a.WordArray,
        c = a.Hasher,
        a = s.algo,
        l = [],
        u = 0;
      64 > u;
      u++
    )
      l[u] = (4294967296 * e.abs(e.sin(u + 1))) | 0;
    (a = a.MD5 =
      c.extend({
        _doReset: function () {
          this._hash = new o.init([
            1732584193, 4023233417, 2562383102, 271733878,
          ]);
        },
        _doProcessBlock: function (e, s) {
          for (var a = 0; 16 > a; a++) {
            var o = s + a,
              c = e[o];
            e[o] =
              (16711935 & ((c << 8) | (c >>> 24))) |
              (4278255360 & ((c << 24) | (c >>> 8)));
          }
          var a = this._hash.words,
            o = e[s + 0],
            c = e[s + 1],
            u = e[s + 2],
            p = e[s + 3],
            h = e[s + 4],
            f = e[s + 5],
            g = e[s + 6],
            m = e[s + 7],
            d = e[s + 8],
            y = e[s + 9],
            k = e[s + 10],
            v = e[s + 11],
            E = e[s + 12],
            S = e[s + 13],
            _ = e[s + 14],
            b = e[s + 15],
            I = a[0],
            A = a[1],
            C = a[2],
            T = a[3],
            I = t(I, A, C, T, o, 7, l[0]),
            T = t(T, I, A, C, c, 12, l[1]),
            C = t(C, T, I, A, u, 17, l[2]),
            A = t(A, C, T, I, p, 22, l[3]),
            I = t(I, A, C, T, h, 7, l[4]),
            T = t(T, I, A, C, f, 12, l[5]),
            C = t(C, T, I, A, g, 17, l[6]),
            A = t(A, C, T, I, m, 22, l[7]),
            I = t(I, A, C, T, d, 7, l[8]),
            T = t(T, I, A, C, y, 12, l[9]),
            C = t(C, T, I, A, k, 17, l[10]),
            A = t(A, C, T, I, v, 22, l[11]),
            I = t(I, A, C, T, E, 7, l[12]),
            T = t(T, I, A, C, S, 12, l[13]),
            C = t(C, T, I, A, _, 17, l[14]),
            A = t(A, C, T, I, b, 22, l[15]),
            I = r(I, A, C, T, c, 5, l[16]),
            T = r(T, I, A, C, g, 9, l[17]),
            C = r(C, T, I, A, v, 14, l[18]),
            A = r(A, C, T, I, o, 20, l[19]),
            I = r(I, A, C, T, f, 5, l[20]),
            T = r(T, I, A, C, k, 9, l[21]),
            C = r(C, T, I, A, b, 14, l[22]),
            A = r(A, C, T, I, h, 20, l[23]),
            I = r(I, A, C, T, y, 5, l[24]),
            T = r(T, I, A, C, _, 9, l[25]),
            C = r(C, T, I, A, p, 14, l[26]),
            A = r(A, C, T, I, d, 20, l[27]),
            I = r(I, A, C, T, S, 5, l[28]),
            T = r(T, I, A, C, u, 9, l[29]),
            C = r(C, T, I, A, m, 14, l[30]),
            A = r(A, C, T, I, E, 20, l[31]),
            I = i(I, A, C, T, f, 4, l[32]),
            T = i(T, I, A, C, d, 11, l[33]),
            C = i(C, T, I, A, v, 16, l[34]),
            A = i(A, C, T, I, _, 23, l[35]),
            I = i(I, A, C, T, c, 4, l[36]),
            T = i(T, I, A, C, h, 11, l[37]),
            C = i(C, T, I, A, m, 16, l[38]),
            A = i(A, C, T, I, k, 23, l[39]),
            I = i(I, A, C, T, S, 4, l[40]),
            T = i(T, I, A, C, o, 11, l[41]),
            C = i(C, T, I, A, p, 16, l[42]),
            A = i(A, C, T, I, g, 23, l[43]),
            I = i(I, A, C, T, y, 4, l[44]),
            T = i(T, I, A, C, E, 11, l[45]),
            C = i(C, T, I, A, b, 16, l[46]),
            A = i(A, C, T, I, u, 23, l[47]),
            I = n(I, A, C, T, o, 6, l[48]),
            T = n(T, I, A, C, m, 10, l[49]),
            C = n(C, T, I, A, _, 15, l[50]),
            A = n(A, C, T, I, f, 21, l[51]),
            I = n(I, A, C, T, E, 6, l[52]),
            T = n(T, I, A, C, p, 10, l[53]),
            C = n(C, T, I, A, k, 15, l[54]),
            A = n(A, C, T, I, c, 21, l[55]),
            I = n(I, A, C, T, d, 6, l[56]),
            T = n(T, I, A, C, b, 10, l[57]),
            C = n(C, T, I, A, g, 15, l[58]),
            A = n(A, C, T, I, S, 21, l[59]),
            I = n(I, A, C, T, h, 6, l[60]),
            T = n(T, I, A, C, v, 10, l[61]),
            C = n(C, T, I, A, u, 15, l[62]),
            A = n(A, C, T, I, y, 21, l[63]);
          (a[0] = (a[0] + I) | 0),
            (a[1] = (a[1] + A) | 0),
            (a[2] = (a[2] + C) | 0),
            (a[3] = (a[3] + T) | 0);
        },
        _doFinalize: function () {
          var t = this._data,
            r = t.words,
            i = 8 * this._nDataBytes,
            n = 8 * t.sigBytes;
          r[n >>> 5] |= 128 << (24 - (n % 32));
          var s = e.floor(i / 4294967296);
          for (
            r[(((n + 64) >>> 9) << 4) + 15] =
              (16711935 & ((s << 8) | (s >>> 24))) |
              (4278255360 & ((s << 24) | (s >>> 8))),
              r[(((n + 64) >>> 9) << 4) + 14] =
                (16711935 & ((i << 8) | (i >>> 24))) |
                (4278255360 & ((i << 24) | (i >>> 8))),
              t.sigBytes = 4 * (r.length + 1),
              this._process(),
              t = this._hash,
              r = t.words,
              i = 0;
            4 > i;
            i++
          )
            (n = r[i]),
              (r[i] =
                (16711935 & ((n << 8) | (n >>> 24))) |
                (4278255360 & ((n << 24) | (n >>> 8))));
          return t;
        },
        clone: function () {
          var e = c.clone.call(this);
          return (e._hash = this._hash.clone()), e;
        },
      })),
      (s.MD5 = c._createHelper(a)),
      (s.HmacMD5 = c._createHmacHelper(a));
  })(Math),
  (function () {
    var e = CryptoJS,
      t = e.lib,
      r = t.Base,
      i = t.WordArray,
      t = e.algo,
      n = (t.EvpKDF = r.extend({
        cfg: r.extend({ keySize: 4, hasher: t.MD5, iterations: 1 }),
        init: function (e) {
          this.cfg = this.cfg.extend(e);
        },
        compute: function (e, t) {
          for (
            var r = this.cfg,
              n = r.hasher.create(),
              s = i.create(),
              a = s.words,
              o = r.keySize,
              r = r.iterations;
            a.length < o;

          ) {
            c && n.update(c);
            var c = n.update(e).finalize(t);
            n.reset();
            for (var l = 1; l < r; l++) (c = n.finalize(c)), n.reset();
            s.concat(c);
          }
          return (s.sigBytes = 4 * o), s;
        },
      }));
    e.EvpKDF = function (e, t, r) {
      return n.create(r).compute(e, t);
    };
  })(),
  CryptoJS.lib.Cipher ||
    (function (e) {
      var t = CryptoJS,
        r = t.lib,
        i = r.Base,
        n = r.WordArray,
        s = r.BufferedBlockAlgorithm,
        a = t.enc.Base64,
        o = t.algo.EvpKDF,
        c = (r.Cipher = s.extend({
          cfg: i.extend(),
          createEncryptor: function (e, t) {
            return this.create(this._ENC_XFORM_MODE, e, t);
          },
          createDecryptor: function (e, t) {
            return this.create(this._DEC_XFORM_MODE, e, t);
          },
          init: function (e, t, r) {
            (this.cfg = this.cfg.extend(r)),
              (this._xformMode = e),
              (this._key = t),
              this.reset();
          },
          reset: function () {
            s.reset.call(this), this._doReset();
          },
          process: function (e) {
            return this._append(e), this._process();
          },
          finalize: function (e) {
            return e && this._append(e), this._doFinalize();
          },
          keySize: 4,
          ivSize: 4,
          _ENC_XFORM_MODE: 1,
          _DEC_XFORM_MODE: 2,
          _createHelper: function (e) {
            return {
              encrypt: function (t, r, i) {
                return ("string" == typeof r ? g : f).encrypt(e, t, r, i);
              },
              decrypt: function (t, r, i) {
                return ("string" == typeof r ? g : f).decrypt(e, t, r, i);
              },
            };
          },
        }));
      r.StreamCipher = c.extend({
        _doFinalize: function () {
          return this._process(!0);
        },
        blockSize: 1,
      });
      var l = (t.mode = {}),
        u = function (t, r, i) {
          var n = this._iv;
          n ? (this._iv = e) : (n = this._prevBlock);
          for (var s = 0; s < i; s++) t[r + s] ^= n[s];
        },
        p = (r.BlockCipherMode = i.extend({
          createEncryptor: function (e, t) {
            return this.Encryptor.create(e, t);
          },
          createDecryptor: function (e, t) {
            return this.Decryptor.create(e, t);
          },
          init: function (e, t) {
            (this._cipher = e), (this._iv = t);
          },
        })).extend();
      (p.Encryptor = p.extend({
        processBlock: function (e, t) {
          var r = this._cipher,
            i = r.blockSize;
          u.call(this, e, t, i),
            r.encryptBlock(e, t),
            (this._prevBlock = e.slice(t, t + i));
        },
      })),
        (p.Decryptor = p.extend({
          processBlock: function (e, t) {
            var r = this._cipher,
              i = r.blockSize,
              n = e.slice(t, t + i);
            r.decryptBlock(e, t), u.call(this, e, t, i), (this._prevBlock = n);
          },
        })),
        (l = l.CBC = p),
        (p = (t.pad = {}).Pkcs7 =
          {
            pad: function (e, t) {
              for (
                var r = 4 * t,
                  r = r - (e.sigBytes % r),
                  i = (r << 24) | (r << 16) | (r << 8) | r,
                  s = [],
                  a = 0;
                a < r;
                a += 4
              )
                s.push(i);
              (r = n.create(s, r)), e.concat(r);
            },
            unpad: function (e) {
              e.sigBytes -= 255 & e.words[(e.sigBytes - 1) >>> 2];
            },
          }),
        (r.BlockCipher = c.extend({
          cfg: c.cfg.extend({ mode: l, padding: p }),
          reset: function () {
            c.reset.call(this);
            var e = this.cfg,
              t = e.iv,
              e = e.mode;
            if (this._xformMode == this._ENC_XFORM_MODE)
              var r = e.createEncryptor;
            else (r = e.createDecryptor), (this._minBufferSize = 1);
            this._mode = r.call(e, this, t && t.words);
          },
          _doProcessBlock: function (e, t) {
            this._mode.processBlock(e, t);
          },
          _doFinalize: function () {
            var e = this.cfg.padding;
            if (this._xformMode == this._ENC_XFORM_MODE) {
              e.pad(this._data, this.blockSize);
              var t = this._process(!0);
            } else (t = this._process(!0)), e.unpad(t);
            return t;
          },
          blockSize: 4,
        }));
      var h = (r.CipherParams = i.extend({
          init: function (e) {
            this.mixIn(e);
          },
          toString: function (e) {
            return (e || this.formatter).stringify(this);
          },
        })),
        l = ((t.format = {}).OpenSSL = {
          stringify: function (e) {
            var t = e.ciphertext;
            return (
              (e = e.salt),
              (e
                ? n.create([1398893684, 1701076831]).concat(e).concat(t)
                : t
              ).toString(a)
            );
          },
          parse: function (e) {
            e = a.parse(e);
            var t = e.words;
            if (1398893684 == t[0] && 1701076831 == t[1]) {
              var r = n.create(t.slice(2, 4));
              t.splice(0, 4), (e.sigBytes -= 16);
            }
            return h.create({ ciphertext: e, salt: r });
          },
        }),
        f = (r.SerializableCipher = i.extend({
          cfg: i.extend({ format: l }),
          encrypt: function (e, t, r, i) {
            i = this.cfg.extend(i);
            var n = e.createEncryptor(r, i);
            return (
              (t = n.finalize(t)),
              (n = n.cfg),
              h.create({
                ciphertext: t,
                key: r,
                iv: n.iv,
                algorithm: e,
                mode: n.mode,
                padding: n.padding,
                blockSize: e.blockSize,
                formatter: i.format,
              })
            );
          },
          decrypt: function (e, t, r, i) {
            return (
              (i = this.cfg.extend(i)),
              (t = this._parse(t, i.format)),
              e.createDecryptor(r, i).finalize(t.ciphertext)
            );
          },
          _parse: function (e, t) {
            return "string" == typeof e ? t.parse(e, this) : e;
          },
        })),
        t = ((t.kdf = {}).OpenSSL = {
          execute: function (e, t, r, i) {
            return (
              i || (i = n.random(8)),
              (e = o.create({ keySize: t + r }).compute(e, i)),
              (r = n.create(e.words.slice(t), 4 * r)),
              (e.sigBytes = 4 * t),
              h.create({ key: e, iv: r, salt: i })
            );
          },
        }),
        g = (r.PasswordBasedCipher = f.extend({
          cfg: f.cfg.extend({ kdf: t }),
          encrypt: function (e, t, r, i) {
            return (
              (i = this.cfg.extend(i)),
              (r = i.kdf.execute(r, e.keySize, e.ivSize)),
              (i.iv = r.iv),
              (e = f.encrypt.call(this, e, t, r.key, i)),
              e.mixIn(r),
              e
            );
          },
          decrypt: function (e, t, r, i) {
            return (
              (i = this.cfg.extend(i)),
              (t = this._parse(t, i.format)),
              (r = i.kdf.execute(r, e.keySize, e.ivSize, t.salt)),
              (i.iv = r.iv),
              f.decrypt.call(this, e, t, r.key, i)
            );
          },
        }));
    })(),
  (function () {
    function e(e, t) {
      var r = ((this._lBlock >>> e) ^ this._rBlock) & t;
      (this._rBlock ^= r), (this._lBlock ^= r << e);
    }
    function t(e, t) {
      var r = ((this._rBlock >>> e) ^ this._lBlock) & t;
      (this._lBlock ^= r), (this._rBlock ^= r << e);
    }
    var r = CryptoJS,
      i = r.lib,
      n = i.WordArray,
      i = i.BlockCipher,
      s = r.algo,
      a = [
        57, 49, 41, 33, 25, 17, 9, 1, 58, 50, 42, 34, 26, 18, 10, 2, 59, 51, 43,
        35, 27, 19, 11, 3, 60, 52, 44, 36, 63, 55, 47, 39, 31, 23, 15, 7, 62,
        54, 46, 38, 30, 22, 14, 6, 61, 53, 45, 37, 29, 21, 13, 5, 28, 20, 12, 4,
      ],
      o = [
        14, 17, 11, 24, 1, 5, 3, 28, 15, 6, 21, 10, 23, 19, 12, 4, 26, 8, 16, 7,
        27, 20, 13, 2, 41, 52, 31, 37, 47, 55, 30, 40, 51, 45, 33, 48, 44, 49,
        39, 56, 34, 53, 46, 42, 50, 36, 29, 32,
      ],
      c = [1, 2, 4, 6, 8, 10, 12, 14, 15, 17, 19, 21, 23, 25, 27, 28],
      l = [
        {
          0: 8421888,
          268435456: 32768,
          536870912: 8421378,
          805306368: 2,
          1073741824: 512,
          1342177280: 8421890,
          1610612736: 8389122,
          1879048192: 8388608,
          2147483648: 514,
          2415919104: 8389120,
          2684354560: 33280,
          2952790016: 8421376,
          3221225472: 32770,
          3489660928: 8388610,
          3758096384: 0,
          4026531840: 33282,
          134217728: 0,
          402653184: 8421890,
          671088640: 33282,
          939524096: 32768,
          1207959552: 8421888,
          1476395008: 512,
          1744830464: 8421378,
          2013265920: 2,
          2281701376: 8389120,
          2550136832: 33280,
          2818572288: 8421376,
          3087007744: 8389122,
          3355443200: 8388610,
          3623878656: 32770,
          3892314112: 514,
          4160749568: 8388608,
          1: 32768,
          268435457: 2,
          536870913: 8421888,
          805306369: 8388608,
          1073741825: 8421378,
          1342177281: 33280,
          1610612737: 512,
          1879048193: 8389122,
          2147483649: 8421890,
          2415919105: 8421376,
          2684354561: 8388610,
          2952790017: 33282,
          3221225473: 514,
          3489660929: 8389120,
          3758096385: 32770,
          4026531841: 0,
          134217729: 8421890,
          402653185: 8421376,
          671088641: 8388608,
          939524097: 512,
          1207959553: 32768,
          1476395009: 8388610,
          1744830465: 2,
          2013265921: 33282,
          2281701377: 32770,
          2550136833: 8389122,
          2818572289: 514,
          3087007745: 8421888,
          3355443201: 8389120,
          3623878657: 0,
          3892314113: 33280,
          4160749569: 8421378,
        },
        {
          0: 1074282512,
          16777216: 16384,
          33554432: 524288,
          50331648: 1074266128,
          67108864: 1073741840,
          83886080: 1074282496,
          100663296: 1073758208,
          117440512: 16,
          134217728: 540672,
          150994944: 1073758224,
          167772160: 1073741824,
          184549376: 540688,
          201326592: 524304,
          218103808: 0,
          234881024: 16400,
          251658240: 1074266112,
          8388608: 1073758208,
          25165824: 540688,
          41943040: 16,
          58720256: 1073758224,
          75497472: 1074282512,
          92274688: 1073741824,
          109051904: 524288,
          125829120: 1074266128,
          142606336: 524304,
          159383552: 0,
          176160768: 16384,
          192937984: 1074266112,
          209715200: 1073741840,
          226492416: 540672,
          243269632: 1074282496,
          260046848: 16400,
          268435456: 0,
          285212672: 1074266128,
          301989888: 1073758224,
          318767104: 1074282496,
          335544320: 1074266112,
          352321536: 16,
          369098752: 540688,
          385875968: 16384,
          402653184: 16400,
          419430400: 524288,
          436207616: 524304,
          452984832: 1073741840,
          469762048: 540672,
          486539264: 1073758208,
          503316480: 1073741824,
          520093696: 1074282512,
          276824064: 540688,
          293601280: 524288,
          310378496: 1074266112,
          327155712: 16384,
          343932928: 1073758208,
          360710144: 1074282512,
          377487360: 16,
          394264576: 1073741824,
          411041792: 1074282496,
          427819008: 1073741840,
          444596224: 1073758224,
          461373440: 524304,
          478150656: 0,
          494927872: 16400,
          511705088: 1074266128,
          528482304: 540672,
        },
        {
          0: 260,
          1048576: 0,
          2097152: 67109120,
          3145728: 65796,
          4194304: 65540,
          5242880: 67108868,
          6291456: 67174660,
          7340032: 67174400,
          8388608: 67108864,
          9437184: 67174656,
          10485760: 65792,
          11534336: 67174404,
          12582912: 67109124,
          13631488: 65536,
          14680064: 4,
          15728640: 256,
          524288: 67174656,
          1572864: 67174404,
          2621440: 0,
          3670016: 67109120,
          4718592: 67108868,
          5767168: 65536,
          6815744: 65540,
          7864320: 260,
          8912896: 4,
          9961472: 256,
          11010048: 67174400,
          12058624: 65796,
          13107200: 65792,
          14155776: 67109124,
          15204352: 67174660,
          16252928: 67108864,
          16777216: 67174656,
          17825792: 65540,
          18874368: 65536,
          19922944: 67109120,
          20971520: 256,
          22020096: 67174660,
          23068672: 67108868,
          24117248: 0,
          25165824: 67109124,
          26214400: 67108864,
          27262976: 4,
          28311552: 65792,
          29360128: 67174400,
          30408704: 260,
          31457280: 65796,
          32505856: 67174404,
          17301504: 67108864,
          18350080: 260,
          19398656: 67174656,
          20447232: 0,
          21495808: 65540,
          22544384: 67109120,
          23592960: 256,
          24641536: 67174404,
          25690112: 65536,
          26738688: 67174660,
          27787264: 65796,
          28835840: 67108868,
          29884416: 67109124,
          30932992: 67174400,
          31981568: 4,
          33030144: 65792,
        },
        {
          0: 2151682048,
          65536: 2147487808,
          131072: 4198464,
          196608: 2151677952,
          262144: 0,
          327680: 4198400,
          393216: 2147483712,
          458752: 4194368,
          524288: 2147483648,
          589824: 4194304,
          655360: 64,
          720896: 2147487744,
          786432: 2151678016,
          851968: 4160,
          917504: 4096,
          983040: 2151682112,
          32768: 2147487808,
          98304: 64,
          163840: 2151678016,
          229376: 2147487744,
          294912: 4198400,
          360448: 2151682112,
          425984: 0,
          491520: 2151677952,
          557056: 4096,
          622592: 2151682048,
          688128: 4194304,
          753664: 4160,
          819200: 2147483648,
          884736: 4194368,
          950272: 4198464,
          1015808: 2147483712,
          1048576: 4194368,
          1114112: 4198400,
          1179648: 2147483712,
          1245184: 0,
          1310720: 4160,
          1376256: 2151678016,
          1441792: 2151682048,
          1507328: 2147487808,
          1572864: 2151682112,
          1638400: 2147483648,
          1703936: 2151677952,
          1769472: 4198464,
          1835008: 2147487744,
          1900544: 4194304,
          1966080: 64,
          2031616: 4096,
          1081344: 2151677952,
          1146880: 2151682112,
          1212416: 0,
          1277952: 4198400,
          1343488: 4194368,
          1409024: 2147483648,
          1474560: 2147487808,
          1540096: 64,
          1605632: 2147483712,
          1671168: 4096,
          1736704: 2147487744,
          1802240: 2151678016,
          1867776: 4160,
          1933312: 2151682048,
          1998848: 4194304,
          2064384: 4198464,
        },
        {
          0: 128,
          4096: 17039360,
          8192: 262144,
          12288: 536870912,
          16384: 537133184,
          20480: 16777344,
          24576: 553648256,
          28672: 262272,
          32768: 16777216,
          36864: 537133056,
          40960: 536871040,
          45056: 553910400,
          49152: 553910272,
          53248: 0,
          57344: 17039488,
          61440: 553648128,
          2048: 17039488,
          6144: 553648256,
          10240: 128,
          14336: 17039360,
          18432: 262144,
          22528: 537133184,
          26624: 553910272,
          30720: 536870912,
          34816: 537133056,
          38912: 0,
          43008: 553910400,
          47104: 16777344,
          51200: 536871040,
          55296: 553648128,
          59392: 16777216,
          63488: 262272,
          65536: 262144,
          69632: 128,
          73728: 536870912,
          77824: 553648256,
          81920: 16777344,
          86016: 553910272,
          90112: 537133184,
          94208: 16777216,
          98304: 553910400,
          102400: 553648128,
          106496: 17039360,
          110592: 537133056,
          114688: 262272,
          118784: 536871040,
          122880: 0,
          126976: 17039488,
          67584: 553648256,
          71680: 16777216,
          75776: 17039360,
          79872: 537133184,
          83968: 536870912,
          88064: 17039488,
          92160: 128,
          96256: 553910272,
          100352: 262272,
          104448: 553910400,
          108544: 0,
          112640: 553648128,
          116736: 16777344,
          120832: 262144,
          124928: 537133056,
          129024: 536871040,
        },
        {
          0: 268435464,
          256: 8192,
          512: 270532608,
          768: 270540808,
          1024: 268443648,
          1280: 2097152,
          1536: 2097160,
          1792: 268435456,
          2048: 0,
          2304: 268443656,
          2560: 2105344,
          2816: 8,
          3072: 270532616,
          3328: 2105352,
          3584: 8200,
          3840: 270540800,
          128: 270532608,
          384: 270540808,
          640: 8,
          896: 2097152,
          1152: 2105352,
          1408: 268435464,
          1664: 268443648,
          1920: 8200,
          2176: 2097160,
          2432: 8192,
          2688: 268443656,
          2944: 270532616,
          3200: 0,
          3456: 270540800,
          3712: 2105344,
          3968: 268435456,
          4096: 268443648,
          4352: 270532616,
          4608: 270540808,
          4864: 8200,
          5120: 2097152,
          5376: 268435456,
          5632: 268435464,
          5888: 2105344,
          6144: 2105352,
          6400: 0,
          6656: 8,
          6912: 270532608,
          7168: 8192,
          7424: 268443656,
          7680: 270540800,
          7936: 2097160,
          4224: 8,
          4480: 2105344,
          4736: 2097152,
          4992: 268435464,
          5248: 268443648,
          5504: 8200,
          5760: 270540808,
          6016: 270532608,
          6272: 270540800,
          6528: 270532616,
          6784: 8192,
          7040: 2105352,
          7296: 2097160,
          7552: 0,
          7808: 268435456,
          8064: 268443656,
        },
        {
          0: 1048576,
          16: 33555457,
          32: 1024,
          48: 1049601,
          64: 34604033,
          80: 0,
          96: 1,
          112: 34603009,
          128: 33555456,
          144: 1048577,
          160: 33554433,
          176: 34604032,
          192: 34603008,
          208: 1025,
          224: 1049600,
          240: 33554432,
          8: 34603009,
          24: 0,
          40: 33555457,
          56: 34604032,
          72: 1048576,
          88: 33554433,
          104: 33554432,
          120: 1025,
          136: 1049601,
          152: 33555456,
          168: 34603008,
          184: 1048577,
          200: 1024,
          216: 34604033,
          232: 1,
          248: 1049600,
          256: 33554432,
          272: 1048576,
          288: 33555457,
          304: 34603009,
          320: 1048577,
          336: 33555456,
          352: 34604032,
          368: 1049601,
          384: 1025,
          400: 34604033,
          416: 1049600,
          432: 1,
          448: 0,
          464: 34603008,
          480: 33554433,
          496: 1024,
          264: 1049600,
          280: 33555457,
          296: 34603009,
          312: 1,
          328: 33554432,
          344: 1048576,
          360: 1025,
          376: 34604032,
          392: 33554433,
          408: 34603008,
          424: 0,
          440: 34604033,
          456: 1049601,
          472: 1024,
          488: 33555456,
          504: 1048577,
        },
        {
          0: 134219808,
          1: 131072,
          2: 134217728,
          3: 32,
          4: 131104,
          5: 134350880,
          6: 134350848,
          7: 2048,
          8: 134348800,
          9: 134219776,
          10: 133120,
          11: 134348832,
          12: 2080,
          13: 0,
          14: 134217760,
          15: 133152,
          2147483648: 2048,
          2147483649: 134350880,
          2147483650: 134219808,
          2147483651: 134217728,
          2147483652: 134348800,
          2147483653: 133120,
          2147483654: 133152,
          2147483655: 32,
          2147483656: 134217760,
          2147483657: 2080,
          2147483658: 131104,
          2147483659: 134350848,
          2147483660: 0,
          2147483661: 134348832,
          2147483662: 134219776,
          2147483663: 131072,
          16: 133152,
          17: 134350848,
          18: 32,
          19: 2048,
          20: 134219776,
          21: 134217760,
          22: 134348832,
          23: 131072,
          24: 0,
          25: 131104,
          26: 134348800,
          27: 134219808,
          28: 134350880,
          29: 133120,
          30: 2080,
          31: 134217728,
          2147483664: 131072,
          2147483665: 2048,
          2147483666: 134348832,
          2147483667: 133152,
          2147483668: 32,
          2147483669: 134348800,
          2147483670: 134217728,
          2147483671: 134219808,
          2147483672: 134350880,
          2147483673: 134217760,
          2147483674: 134219776,
          2147483675: 0,
          2147483676: 133120,
          2147483677: 2080,
          2147483678: 131104,
          2147483679: 134350848,
        },
      ],
      u = [
        4160749569, 528482304, 33030144, 2064384, 129024, 8064, 504, 2147483679,
      ],
      p = (s.DES = i.extend({
        _doReset: function () {
          for (var e = this._key.words, t = [], r = 0; 56 > r; r++) {
            var i = a[r] - 1;
            t[r] = (e[i >>> 5] >>> (31 - (i % 32))) & 1;
          }
          for (e = this._subKeys = [], i = 0; 16 > i; i++) {
            for (var n = (e[i] = []), s = c[i], r = 0; 24 > r; r++)
              (n[(r / 6) | 0] |= t[(o[r] - 1 + s) % 28] << (31 - (r % 6))),
                (n[4 + ((r / 6) | 0)] |=
                  t[28 + ((o[r + 24] - 1 + s) % 28)] << (31 - (r % 6)));
            for (n[0] = (n[0] << 1) | (n[0] >>> 31), r = 1; 7 > r; r++)
              n[r] >>>= 4 * (r - 1) + 3;
            n[7] = (n[7] << 5) | (n[7] >>> 27);
          }
          for (t = this._invSubKeys = [], r = 0; 16 > r; r++) t[r] = e[15 - r];
        },
        encryptBlock: function (e, t) {
          this._doCryptBlock(e, t, this._subKeys);
        },
        decryptBlock: function (e, t) {
          this._doCryptBlock(e, t, this._invSubKeys);
        },
        _doCryptBlock: function (r, i, n) {
          (this._lBlock = r[i]),
            (this._rBlock = r[i + 1]),
            e.call(this, 4, 252645135),
            e.call(this, 16, 65535),
            t.call(this, 2, 858993459),
            t.call(this, 8, 16711935),
            e.call(this, 1, 1431655765);
          for (var s = 0; 16 > s; s++) {
            for (
              var a = n[s], o = this._lBlock, c = this._rBlock, p = 0, h = 0;
              8 > h;
              h++
            )
              p |= l[h][((c ^ a[h]) & u[h]) >>> 0];
            (this._lBlock = c), (this._rBlock = o ^ p);
          }
          (n = this._lBlock),
            (this._lBlock = this._rBlock),
            (this._rBlock = n),
            e.call(this, 1, 1431655765),
            t.call(this, 8, 16711935),
            t.call(this, 2, 858993459),
            e.call(this, 16, 65535),
            e.call(this, 4, 252645135),
            (r[i] = this._lBlock),
            (r[i + 1] = this._rBlock);
        },
        keySize: 2,
        ivSize: 2,
        blockSize: 2,
      }));
    (r.DES = i._createHelper(p)),
      (s = s.TripleDES =
        i.extend({
          _doReset: function () {
            var e = this._key.words;
            (this._des1 = p.createEncryptor(n.create(e.slice(0, 2)))),
              (this._des2 = p.createEncryptor(n.create(e.slice(2, 4)))),
              (this._des3 = p.createEncryptor(n.create(e.slice(4, 6))));
          },
          encryptBlock: function (e, t) {
            this._des1.encryptBlock(e, t),
              this._des2.decryptBlock(e, t),
              this._des3.encryptBlock(e, t);
          },
          decryptBlock: function (e, t) {
            this._des3.decryptBlock(e, t),
              this._des2.encryptBlock(e, t),
              this._des1.decryptBlock(e, t);
          },
          keySize: 6,
          ivSize: 2,
          blockSize: 2,
        })),
      (r.TripleDES = i._createHelper(s));
  })(),
  (function (e, t) {
    "function" == typeof define && define.amd ? define([], t) : (e.forge = t());
  })(this, function () {
    var e, t, r;
    return (
      (function (i) {
        function n(e, t) {
          return E.call(e, t);
        }
        function s(e, t) {
          var r,
            i,
            n,
            s,
            a,
            o,
            c,
            l,
            u,
            p,
            h,
            f = t && t.split("/"),
            g = k.map,
            m = (g && g["*"]) || {};
          if (e && "." === e.charAt(0))
            if (t) {
              for (
                f = f.slice(0, f.length - 1),
                  e = e.split("/"),
                  a = e.length - 1,
                  k.nodeIdCompat &&
                    _.test(e[a]) &&
                    (e[a] = e[a].replace(_, "")),
                  e = f.concat(e),
                  u = 0;
                u < e.length;
                u += 1
              )
                if (((h = e[u]), "." === h)) e.splice(u, 1), (u -= 1);
                else if (".." === h) {
                  if (1 === u && (".." === e[2] || ".." === e[0])) break;
                  u > 0 && (e.splice(u - 1, 2), (u -= 2));
                }
              e = e.join("/");
            } else 0 === e.indexOf("./") && (e = e.substring(2));
          if ((f || m) && g) {
            for (r = e.split("/"), u = r.length; u > 0; u -= 1) {
              if (((i = r.slice(0, u).join("/")), f))
                for (p = f.length; p > 0; p -= 1)
                  if (((n = g[f.slice(0, p).join("/")]), n && (n = n[i]))) {
                    (s = n), (o = u);
                    break;
                  }
              if (s) break;
              !c && m && m[i] && ((c = m[i]), (l = u));
            }
            !s && c && ((s = c), (o = l)),
              s && (r.splice(0, o, s), (e = r.join("/")));
          }
          return e;
        }
        function a(e, t) {
          return function () {
            return f.apply(i, S.call(arguments, 0).concat([e, t]));
          };
        }
        function o(e) {
          return function (t) {
            return s(t, e);
          };
        }
        function c(e) {
          return function (t) {
            d[e] = t;
          };
        }
        function l(e) {
          if (n(y, e)) {
            var t = y[e];
            delete y[e], (v[e] = !0), h.apply(i, t);
          }
          if (!n(d, e) && !n(v, e)) throw new Error("No " + e);
          return d[e];
        }
        function u(e) {
          var t,
            r = e ? e.indexOf("!") : -1;
          return (
            r > -1 &&
              ((t = e.substring(0, r)), (e = e.substring(r + 1, e.length))),
            [t, e]
          );
        }
        function p(e) {
          return function () {
            return (k && k.config && k.config[e]) || {};
          };
        }
        var h,
          f,
          g,
          m,
          d = {},
          y = {},
          k = {},
          v = {},
          E = Object.prototype.hasOwnProperty,
          S = [].slice,
          _ = /\.js$/;
        (g = function (e, t) {
          var r,
            i = u(e),
            n = i[0];
          return (
            (e = i[1]),
            n && ((n = s(n, t)), (r = l(n))),
            n
              ? (e = r && r.normalize ? r.normalize(e, o(t)) : s(e, t))
              : ((e = s(e, t)),
                (i = u(e)),
                (n = i[0]),
                (e = i[1]),
                n && (r = l(n))),
            { f: n ? n + "!" + e : e, n: e, pr: n, p: r }
          );
        }),
          (m = {
            require: function (e) {
              return a(e);
            },
            exports: function (e) {
              var t = d[e];
              return "undefined" != typeof t ? t : (d[e] = {});
            },
            module: function (e) {
              return { id: e, uri: "", exports: d[e], config: p(e) };
            },
          }),
          (h = function (e, t, r, s) {
            var o,
              u,
              p,
              h,
              f,
              k,
              E = [],
              S = typeof r;
            if (((s = s || e), "undefined" === S || "function" === S)) {
              for (
                t =
                  !t.length && r.length ? ["require", "exports", "module"] : t,
                  f = 0;
                f < t.length;
                f += 1
              )
                if (((h = g(t[f], s)), (u = h.f), "require" === u))
                  E[f] = m.require(e);
                else if ("exports" === u) (E[f] = m.exports(e)), (k = !0);
                else if ("module" === u) o = E[f] = m.module(e);
                else if (n(d, u) || n(y, u) || n(v, u)) E[f] = l(u);
                else {
                  if (!h.p) throw new Error(e + " missing " + u);
                  h.p.load(h.n, a(s, !0), c(u), {}), (E[f] = d[u]);
                }
              (p = r ? r.apply(d[e], E) : void 0),
                e &&
                  (o && o.exports !== i && o.exports !== d[e]
                    ? (d[e] = o.exports)
                    : (p === i && k) || (d[e] = p));
            } else e && (d[e] = r);
          }),
          (e =
            t =
            f =
              function (e, t, r, n, s) {
                if ("string" == typeof e) return m[e] ? m[e](t) : l(g(e, t).f);
                if (!e.splice) {
                  if (((k = e), k.deps && f(k.deps, k.callback), !t)) return;
                  t.splice ? ((e = t), (t = r), (r = null)) : (e = i);
                }
                return (
                  (t = t || function () {}),
                  "function" == typeof r && ((r = n), (n = s)),
                  n
                    ? h(i, e, t, r)
                    : setTimeout(function () {
                        h(i, e, t, r);
                      }, 4),
                  f
                );
              }),
          (f.config = function (e) {
            return f(e);
          }),
          (e._defined = d),
          (r = function (e, t, r) {
            t.splice || ((r = t), (t = [])),
              !n(d, e) && !n(y, e) && (y[e] = [e, t, r]);
          }),
          (r.amd = { jQuery: !0 });
      })(),
      r("node_modules/almond/almond", function () {}),
      (function () {
        function e(e) {
          function t(e) {
            if (((this.data = ""), (this.read = 0), "string" == typeof e))
              this.data = e;
            else if (i.isArrayBuffer(e) || i.isArrayBufferView(e)) {
              var r = new Uint8Array(e);
              try {
                this.data = String.fromCharCode.apply(null, r);
              } catch (n) {
                for (var s = 0; s < r.length; ++s) this.putByte(r[s]);
              }
            } else
              (e instanceof t ||
                ("object" == typeof e &&
                  "string" == typeof e.data &&
                  "number" == typeof e.read)) &&
                ((this.data = e.data), (this.read = e.read));
            this._constructedStringLength = 0;
          }
          function r(e, t) {
            (t = t || {}),
              (this.read = t.readOffset || 0),
              (this.growSize = t.growSize || 1024);
            var r = i.isArrayBuffer(e),
              n = i.isArrayBufferView(e);
            return r || n
              ? (r
                  ? (this.data = new DataView(e))
                  : (this.data = new DataView(
                      e.buffer,
                      e.byteOffset,
                      e.byteLength
                    )),
                void (this.write =
                  "writeOffset" in t ? t.writeOffset : this.data.byteLength))
              : ((this.data = new DataView(new ArrayBuffer(0))),
                (this.write = 0),
                null !== e && void 0 !== e && this.putBytes(e),
                "writeOffset" in t && (this.write = t.writeOffset),
                void 0);
          }
          var i = (e.util = e.util || {});
          !(function () {
            function e(e) {
              if (e.source === window && e.data === t) {
                e.stopPropagation();
                var i = r.slice();
                (r.length = 0),
                  i.forEach(function (e) {
                    e();
                  });
              }
            }
            if ("undefined" != typeof process && process.nextTick)
              return (
                (i.nextTick = process.nextTick),
                void ("function" == typeof setImmediate
                  ? (i.setImmediate = setImmediate)
                  : (i.setImmediate = i.nextTick))
              );
            if ("function" == typeof setImmediate)
              return (
                (i.setImmediate = setImmediate),
                void (i.nextTick = function (e) {
                  return setImmediate(e);
                })
              );
            if (
              ((i.setImmediate = function (e) {
                setTimeout(e, 0);
              }),
              "undefined" != typeof window &&
                "function" == typeof window.postMessage)
            ) {
              var t = "forge.setImmediate",
                r = [];
              (i.setImmediate = function (e) {
                r.push(e), 1 === r.length && window.postMessage(t, "*");
              }),
                window.addEventListener("message", e, !0);
            }
            if ("undefined" != typeof MutationObserver) {
              var n = Date.now(),
                s = !0,
                a = document.createElement("div"),
                r = [];
              new MutationObserver(function () {
                var e = r.slice();
                (r.length = 0),
                  e.forEach(function (e) {
                    e();
                  });
              }).observe(a, { attributes: !0 });
              var o = i.setImmediate;
              i.setImmediate = function (e) {
                Date.now() - n > 15
                  ? ((n = Date.now()), o(e))
                  : (r.push(e),
                    1 === r.length && a.setAttribute("a", (s = !s)));
              };
            }
            i.nextTick = i.setImmediate;
          })(),
            (i.isArray =
              Array.isArray ||
              function (e) {
                return "[object Array]" === Object.prototype.toString.call(e);
              }),
            (i.isArrayBuffer = function (e) {
              return (
                "undefined" != typeof ArrayBuffer && e instanceof ArrayBuffer
              );
            }),
            (i.isArrayBufferView = function (e) {
              return e && i.isArrayBuffer(e.buffer) && void 0 !== e.byteLength;
            }),
            (i.ByteBuffer = t),
            (i.ByteStringBuffer = t);
          var n = 4096;
          (i.ByteStringBuffer.prototype._optimizeConstructedString = function (
            e
          ) {
            (this._constructedStringLength += e),
              this._constructedStringLength > n &&
                (this.data.substr(0, 1), (this._constructedStringLength = 0));
          }),
            (i.ByteStringBuffer.prototype.length = function () {
              return this.data.length - this.read;
            }),
            (i.ByteStringBuffer.prototype.isEmpty = function () {
              return this.length() <= 0;
            }),
            (i.ByteStringBuffer.prototype.putByte = function (e) {
              return this.putBytes(String.fromCharCode(e));
            }),
            (i.ByteStringBuffer.prototype.fillWithByte = function (e, t) {
              e = String.fromCharCode(e);
              for (var r = this.data; t > 0; )
                1 & t && (r += e), (t >>>= 1), t > 0 && (e += e);
              return (this.data = r), this._optimizeConstructedString(t), this;
            }),
            (i.ByteStringBuffer.prototype.putBytes = function (e) {
              return (
                (this.data += e),
                this._optimizeConstructedString(e.length),
                this
              );
            }),
            (i.ByteStringBuffer.prototype.putString = function (e) {
              return this.putBytes(i.encodeUtf8(e));
            }),
            (i.ByteStringBuffer.prototype.putInt16 = function (e) {
              return this.putBytes(
                String.fromCharCode((e >> 8) & 255) +
                  String.fromCharCode(255 & e)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt24 = function (e) {
              return this.putBytes(
                String.fromCharCode((e >> 16) & 255) +
                  String.fromCharCode((e >> 8) & 255) +
                  String.fromCharCode(255 & e)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt32 = function (e) {
              return this.putBytes(
                String.fromCharCode((e >> 24) & 255) +
                  String.fromCharCode((e >> 16) & 255) +
                  String.fromCharCode((e >> 8) & 255) +
                  String.fromCharCode(255 & e)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt16Le = function (e) {
              return this.putBytes(
                String.fromCharCode(255 & e) +
                  String.fromCharCode((e >> 8) & 255)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt24Le = function (e) {
              return this.putBytes(
                String.fromCharCode(255 & e) +
                  String.fromCharCode((e >> 8) & 255) +
                  String.fromCharCode((e >> 16) & 255)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt32Le = function (e) {
              return this.putBytes(
                String.fromCharCode(255 & e) +
                  String.fromCharCode((e >> 8) & 255) +
                  String.fromCharCode((e >> 16) & 255) +
                  String.fromCharCode((e >> 24) & 255)
              );
            }),
            (i.ByteStringBuffer.prototype.putInt = function (e, t) {
              var r = "";
              do (t -= 8), (r += String.fromCharCode((e >> t) & 255));
              while (t > 0);
              return this.putBytes(r);
            }),
            (i.ByteStringBuffer.prototype.putSignedInt = function (e, t) {
              return e < 0 && (e += 2 << (t - 1)), this.putInt(e, t);
            }),
            (i.ByteStringBuffer.prototype.putBuffer = function (e) {
              return this.putBytes(e.getBytes());
            }),
            (i.ByteStringBuffer.prototype.getByte = function () {
              return this.data.charCodeAt(this.read++);
            }),
            (i.ByteStringBuffer.prototype.getInt16 = function () {
              var e =
                (this.data.charCodeAt(this.read) << 8) ^
                this.data.charCodeAt(this.read + 1);
              return (this.read += 2), e;
            }),
            (i.ByteStringBuffer.prototype.getInt24 = function () {
              var e =
                (this.data.charCodeAt(this.read) << 16) ^
                (this.data.charCodeAt(this.read + 1) << 8) ^
                this.data.charCodeAt(this.read + 2);
              return (this.read += 3), e;
            }),
            (i.ByteStringBuffer.prototype.getInt32 = function () {
              var e =
                (this.data.charCodeAt(this.read) << 24) ^
                (this.data.charCodeAt(this.read + 1) << 16) ^
                (this.data.charCodeAt(this.read + 2) << 8) ^
                this.data.charCodeAt(this.read + 3);
              return (this.read += 4), e;
            }),
            (i.ByteStringBuffer.prototype.getInt16Le = function () {
              var e =
                this.data.charCodeAt(this.read) ^
                (this.data.charCodeAt(this.read + 1) << 8);
              return (this.read += 2), e;
            }),
            (i.ByteStringBuffer.prototype.getInt24Le = function () {
              var e =
                this.data.charCodeAt(this.read) ^
                (this.data.charCodeAt(this.read + 1) << 8) ^
                (this.data.charCodeAt(this.read + 2) << 16);
              return (this.read += 3), e;
            }),
            (i.ByteStringBuffer.prototype.getInt32Le = function () {
              var e =
                this.data.charCodeAt(this.read) ^
                (this.data.charCodeAt(this.read + 1) << 8) ^
                (this.data.charCodeAt(this.read + 2) << 16) ^
                (this.data.charCodeAt(this.read + 3) << 24);
              return (this.read += 4), e;
            }),
            (i.ByteStringBuffer.prototype.getInt = function (e) {
              var t = 0;
              do (t = (t << 8) + this.data.charCodeAt(this.read++)), (e -= 8);
              while (e > 0);
              return t;
            }),
            (i.ByteStringBuffer.prototype.getSignedInt = function (e) {
              var t = this.getInt(e),
                r = 2 << (e - 2);
              return t >= r && (t -= r << 1), t;
            }),
            (i.ByteStringBuffer.prototype.getBytes = function (e) {
              var t;
              return (
                e
                  ? ((e = Math.min(this.length(), e)),
                    (t = this.data.slice(this.read, this.read + e)),
                    (this.read += e))
                  : 0 === e
                  ? (t = "")
                  : ((t =
                      0 === this.read ? this.data : this.data.slice(this.read)),
                    this.clear()),
                t
              );
            }),
            (i.ByteStringBuffer.prototype.bytes = function (e) {
              return "undefined" == typeof e
                ? this.data.slice(this.read)
                : this.data.slice(this.read, this.read + e);
            }),
            (i.ByteStringBuffer.prototype.at = function (e) {
              return this.data.charCodeAt(this.read + e);
            }),
            (i.ByteStringBuffer.prototype.setAt = function (e, t) {
              return (
                (this.data =
                  this.data.substr(0, this.read + e) +
                  String.fromCharCode(t) +
                  this.data.substr(this.read + e + 1)),
                this
              );
            }),
            (i.ByteStringBuffer.prototype.last = function () {
              return this.data.charCodeAt(this.data.length - 1);
            }),
            (i.ByteStringBuffer.prototype.copy = function () {
              var e = i.createBuffer(this.data);
              return (e.read = this.read), e;
            }),
            (i.ByteStringBuffer.prototype.compact = function () {
              return (
                this.read > 0 &&
                  ((this.data = this.data.slice(this.read)), (this.read = 0)),
                this
              );
            }),
            (i.ByteStringBuffer.prototype.clear = function () {
              return (this.data = ""), (this.read = 0), this;
            }),
            (i.ByteStringBuffer.prototype.truncate = function (e) {
              var t = Math.max(0, this.length() - e);
              return (
                (this.data = this.data.substr(this.read, t)),
                (this.read = 0),
                this
              );
            }),
            (i.ByteStringBuffer.prototype.toHex = function () {
              for (var e = "", t = this.read; t < this.data.length; ++t) {
                var r = this.data.charCodeAt(t);
                r < 16 && (e += "0"), (e += r.toString(16));
              }
              return e;
            }),
            (i.ByteStringBuffer.prototype.toString = function () {
              return i.decodeUtf8(this.bytes());
            }),
            (i.DataBuffer = r),
            (i.DataBuffer.prototype.length = function () {
              return this.write - this.read;
            }),
            (i.DataBuffer.prototype.isEmpty = function () {
              return this.length() <= 0;
            }),
            (i.DataBuffer.prototype.accommodate = function (e, t) {
              if (this.length() >= e) return this;
              t = Math.max(t || this.growSize, e);
              var r = new Uint8Array(
                  this.data.buffer,
                  this.data.byteOffset,
                  this.data.byteLength
                ),
                i = new Uint8Array(this.length() + t);
              return i.set(r), (this.data = new DataView(i.buffer)), this;
            }),
            (i.DataBuffer.prototype.putByte = function (e) {
              return (
                this.accommodate(1), this.data.setUint8(this.write++, e), this
              );
            }),
            (i.DataBuffer.prototype.fillWithByte = function (e, t) {
              this.accommodate(t);
              for (var r = 0; r < t; ++r) this.data.setUint8(e);
              return this;
            }),
            (i.DataBuffer.prototype.putBytes = function (e, t) {
              if (i.isArrayBufferView(e)) {
                var r = new Uint8Array(e.buffer, e.byteOffset, e.byteLength),
                  n = r.byteLength - r.byteOffset;
                this.accommodate(n);
                var s = new Uint8Array(this.data.buffer, this.write);
                return s.set(r), (this.write += n), this;
              }
              if (i.isArrayBuffer(e)) {
                var r = new Uint8Array(e);
                this.accommodate(r.byteLength);
                var s = new Uint8Array(this.data.buffer);
                return s.set(r, this.write), (this.write += r.byteLength), this;
              }
              if (
                e instanceof i.DataBuffer ||
                ("object" == typeof e &&
                  "number" == typeof e.read &&
                  "number" == typeof e.write &&
                  i.isArrayBufferView(e.data))
              ) {
                var r = new Uint8Array(e.data.byteLength, e.read, e.length());
                this.accommodate(r.byteLength);
                var s = new Uint8Array(e.data.byteLength, this.write);
                return s.set(r), (this.write += r.byteLength), this;
              }
              if (
                (e instanceof i.ByteStringBuffer &&
                  ((e = e.data), (t = "binary")),
                (t = t || "binary"),
                "string" == typeof e)
              ) {
                var a;
                if ("hex" === t)
                  return (
                    this.accommodate(Math.ceil(e.length / 2)),
                    (a = new Uint8Array(this.data.buffer, this.write)),
                    (this.write += i.binary.hex.decode(e, a, this.write)),
                    this
                  );
                if ("base64" === t)
                  return (
                    this.accommodate(3 * Math.ceil(e.length / 4)),
                    (a = new Uint8Array(this.data.buffer, this.write)),
                    (this.write += i.binary.base64.decode(e, a, this.write)),
                    this
                  );
                if (
                  ("utf8" === t && ((e = i.encodeUtf8(e)), (t = "binary")),
                  "binary" === t || "raw" === t)
                )
                  return (
                    this.accommodate(e.length),
                    (a = new Uint8Array(this.data.buffer, this.write)),
                    (this.write += i.binary.raw.decode(a)),
                    this
                  );
                if ("utf16" === t)
                  return (
                    this.accommodate(2 * e.length),
                    (a = new Uint16Array(this.data.buffer, this.write)),
                    (this.write += i.text.utf16.encode(a)),
                    this
                  );
                throw new Error("Invalid encoding: " + t);
              }
              throw Error("Invalid parameter: " + e);
            }),
            (i.DataBuffer.prototype.putBuffer = function (e) {
              return this.putBytes(e), e.clear(), this;
            }),
            (i.DataBuffer.prototype.putString = function (e) {
              return this.putBytes(e, "utf16");
            }),
            (i.DataBuffer.prototype.putInt16 = function (e) {
              return (
                this.accommodate(2),
                this.data.setInt16(this.write, e),
                (this.write += 2),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt24 = function (e) {
              return (
                this.accommodate(3),
                this.data.setInt16(this.write, (e >> 8) & 65535),
                this.data.setInt8(this.write, (e >> 16) & 255),
                (this.write += 3),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt32 = function (e) {
              return (
                this.accommodate(4),
                this.data.setInt32(this.write, e),
                (this.write += 4),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt16Le = function (e) {
              return (
                this.accommodate(2),
                this.data.setInt16(this.write, e, !0),
                (this.write += 2),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt24Le = function (e) {
              return (
                this.accommodate(3),
                this.data.setInt8(this.write, (e >> 16) & 255),
                this.data.setInt16(this.write, (e >> 8) & 65535, !0),
                (this.write += 3),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt32Le = function (e) {
              return (
                this.accommodate(4),
                this.data.setInt32(this.write, e, !0),
                (this.write += 4),
                this
              );
            }),
            (i.DataBuffer.prototype.putInt = function (e, t) {
              this.accommodate(t / 8);
              do (t -= 8), this.data.setInt8(this.write++, (e >> t) & 255);
              while (t > 0);
              return this;
            }),
            (i.DataBuffer.prototype.putSignedInt = function (e, t) {
              return (
                this.accommodate(t / 8),
                e < 0 && (e += 2 << (t - 1)),
                this.putInt(e, t)
              );
            }),
            (i.DataBuffer.prototype.getByte = function () {
              return this.data.getInt8(this.read++);
            }),
            (i.DataBuffer.prototype.getInt16 = function () {
              var e = this.data.getInt16(this.read);
              return (this.read += 2), e;
            }),
            (i.DataBuffer.prototype.getInt24 = function () {
              var e =
                (this.data.getInt16(this.read) << 8) ^
                this.data.getInt8(this.read + 2);
              return (this.read += 3), e;
            }),
            (i.DataBuffer.prototype.getInt32 = function () {
              var e = this.data.getInt32(this.read);
              return (this.read += 4), e;
            }),
            (i.DataBuffer.prototype.getInt16Le = function () {
              var e = this.data.getInt16(this.read, !0);
              return (this.read += 2), e;
            }),
            (i.DataBuffer.prototype.getInt24Le = function () {
              var e =
                this.data.getInt8(this.read) ^
                (this.data.getInt16(this.read + 1, !0) << 8);
              return (this.read += 3), e;
            }),
            (i.DataBuffer.prototype.getInt32Le = function () {
              var e = this.data.getInt32(this.read, !0);
              return (this.read += 4), e;
            }),
            (i.DataBuffer.prototype.getInt = function (e) {
              var t = 0;
              do (t = (t << 8) + this.data.getInt8(this.read++)), (e -= 8);
              while (e > 0);
              return t;
            }),
            (i.DataBuffer.prototype.getSignedInt = function (e) {
              var t = this.getInt(e),
                r = 2 << (e - 2);
              return t >= r && (t -= r << 1), t;
            }),
            (i.DataBuffer.prototype.getBytes = function (e) {
              var t;
              return (
                e
                  ? ((e = Math.min(this.length(), e)),
                    (t = this.data.slice(this.read, this.read + e)),
                    (this.read += e))
                  : 0 === e
                  ? (t = "")
                  : ((t =
                      0 === this.read ? this.data : this.data.slice(this.read)),
                    this.clear()),
                t
              );
            }),
            (i.DataBuffer.prototype.bytes = function (e) {
              return "undefined" == typeof e
                ? this.data.slice(this.read)
                : this.data.slice(this.read, this.read + e);
            }),
            (i.DataBuffer.prototype.at = function (e) {
              return this.data.getUint8(this.read + e);
            }),
            (i.DataBuffer.prototype.setAt = function (e, t) {
              return this.data.setUint8(e, t), this;
            }),
            (i.DataBuffer.prototype.last = function () {
              return this.data.getUint8(this.write - 1);
            }),
            (i.DataBuffer.prototype.copy = function () {
              return new i.DataBuffer(this);
            }),
            (i.DataBuffer.prototype.compact = function () {
              if (this.read > 0) {
                var e = new Uint8Array(this.data.buffer, this.read),
                  t = new Uint8Array(e.byteLength);
                t.set(e),
                  (this.data = new DataView(t)),
                  (this.write -= this.read),
                  (this.read = 0);
              }
              return this;
            }),
            (i.DataBuffer.prototype.clear = function () {
              return (
                (this.data = new DataView(new ArrayBuffer(0))),
                (this.read = this.write = 0),
                this
              );
            }),
            (i.DataBuffer.prototype.truncate = function (e) {
              return (
                (this.write = Math.max(0, this.length() - e)),
                (this.read = Math.min(this.read, this.write)),
                this
              );
            }),
            (i.DataBuffer.prototype.toHex = function () {
              for (var e = "", t = this.read; t < this.data.byteLength; ++t) {
                var r = this.data.getUint8(t);
                r < 16 && (e += "0"), (e += r.toString(16));
              }
              return e;
            }),
            (i.DataBuffer.prototype.toString = function (e) {
              var t = new Uint8Array(this.data, this.read, this.length());
              if (((e = e || "utf8"), "binary" === e || "raw" === e))
                return i.binary.raw.encode(t);
              if ("hex" === e) return i.binary.hex.encode(t);
              if ("base64" === e) return i.binary.base64.encode(t);
              if ("utf8" === e) return i.text.utf8.decode(t);
              if ("utf16" === e) return i.text.utf16.decode(t);
              throw new Error("Invalid encoding: " + e);
            }),
            (i.createBuffer = function (e, t) {
              return (
                (t = t || "raw"),
                void 0 !== e && "utf8" === t && (e = i.encodeUtf8(e)),
                new i.ByteBuffer(e)
              );
            }),
            (i.fillString = function (e, t) {
              for (var r = ""; t > 0; )
                1 & t && (r += e), (t >>>= 1), t > 0 && (e += e);
              return r;
            }),
            (i.xorBytes = function (e, t, r) {
              for (var i = "", n = "", s = "", a = 0, o = 0; r > 0; --r, ++a)
                (n = e.charCodeAt(a) ^ t.charCodeAt(a)),
                  o >= 10 && ((i += s), (s = ""), (o = 0)),
                  (s += String.fromCharCode(n)),
                  ++o;
              return (i += s);
            }),
            (i.hexToBytes = function (e) {
              var t = "",
                r = 0;
              for (
                e.length & !0 &&
                ((r = 1), (t += String.fromCharCode(parseInt(e[0], 16))));
                r < e.length;
                r += 2
              )
                t += String.fromCharCode(parseInt(e.substr(r, 2), 16));
              return t;
            }),
            (i.bytesToHex = function (e) {
              return i.createBuffer(e).toHex();
            }),
            (i.int32ToBytes = function (e) {
              return (
                String.fromCharCode((e >> 24) & 255) +
                String.fromCharCode((e >> 16) & 255) +
                String.fromCharCode((e >> 8) & 255) +
                String.fromCharCode(255 & e)
              );
            });
          var s =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
            a = [
              62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1,
              -1, -1, 64, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
              13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1,
              -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
              39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
            ];
          (i.encode64 = function (e, t) {
            for (var r, i, n, a = "", o = "", c = 0; c < e.length; )
              (r = e.charCodeAt(c++)),
                (i = e.charCodeAt(c++)),
                (n = e.charCodeAt(c++)),
                (a += s.charAt(r >> 2)),
                (a += s.charAt(((3 & r) << 4) | (i >> 4))),
                isNaN(i)
                  ? (a += "==")
                  : ((a += s.charAt(((15 & i) << 2) | (n >> 6))),
                    (a += isNaN(n) ? "=" : s.charAt(63 & n))),
                t &&
                  a.length > t &&
                  ((o += a.substr(0, t) + "\r\n"), (a = a.substr(t)));
            return (o += a);
          }),
            (i.decode64 = function (e) {
              e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
              for (var t, r, i, n, s = "", o = 0; o < e.length; )
                (t = a[e.charCodeAt(o++) - 43]),
                  (r = a[e.charCodeAt(o++) - 43]),
                  (i = a[e.charCodeAt(o++) - 43]),
                  (n = a[e.charCodeAt(o++) - 43]),
                  (s += String.fromCharCode((t << 2) | (r >> 4))),
                  64 !== i &&
                    ((s += String.fromCharCode(((15 & r) << 4) | (i >> 2))),
                    64 !== n && (s += String.fromCharCode(((3 & i) << 6) | n)));
              return s;
            }),
            (i.encodeUtf8 = function (e) {
              return unescape(encodeURIComponent(e));
            }),
            (i.decodeUtf8 = function (e) {
              return decodeURIComponent(escape(e));
            }),
            (i.binary = { raw: {}, hex: {}, base64: {} }),
            (i.binary.raw.encode = function (e) {
              return String.fromCharCode.apply(null, e);
            }),
            (i.binary.raw.decode = function (e, t, r) {
              var i = t;
              i || (i = new Uint8Array(e.length)), (r = r || 0);
              for (var n = r, s = 0; s < e.length; ++s)
                i[n++] = e.charCodeAt(s);
              return t ? n - r : i;
            }),
            (i.binary.hex.encode = i.bytesToHex),
            (i.binary.hex.decode = function (e, t, r) {
              var i = t;
              i || (i = new Uint8Array(Math.ceil(e.length / 2))), (r = r || 0);
              var n = 0,
                s = r;
              for (
                1 & e.length && ((n = 1), (i[s++] = parseInt(e[0], 16)));
                n < e.length;
                n += 2
              )
                i[s++] = parseInt(e.substr(n, 2), 16);
              return t ? s - r : i;
            }),
            (i.binary.base64.encode = function (e, t) {
              for (var r, i, n, a = "", o = "", c = 0; c < e.byteLength; )
                (r = e[c++]),
                  (i = e[c++]),
                  (n = e[c++]),
                  (a += s.charAt(r >> 2)),
                  (a += s.charAt(((3 & r) << 4) | (i >> 4))),
                  isNaN(i)
                    ? (a += "==")
                    : ((a += s.charAt(((15 & i) << 2) | (n >> 6))),
                      (a += isNaN(n) ? "=" : s.charAt(63 & n))),
                  t &&
                    a.length > t &&
                    ((o += a.substr(0, t) + "\r\n"), (a = a.substr(t)));
              return (o += a);
            }),
            (i.binary.base64.decode = function (e, t, r) {
              var i = t;
              i || (i = new Uint8Array(3 * Math.ceil(e.length / 4))),
                (e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "")),
                (r = r || 0);
              for (var n, s, o, c, l = 0, u = r; l < e.length; )
                (n = a[e.charCodeAt(l++) - 43]),
                  (s = a[e.charCodeAt(l++) - 43]),
                  (o = a[e.charCodeAt(l++) - 43]),
                  (c = a[e.charCodeAt(l++) - 43]),
                  (i[u++] = (n << 2) | (s >> 4)),
                  64 !== o &&
                    ((i[u++] = ((15 & s) << 4) | (o >> 2)),
                    64 !== c && (i[u++] = ((3 & o) << 6) | c));
              return t ? u - r : i.subarray(0, u);
            }),
            (i.text = { utf8: {}, utf16: {} }),
            (i.text.utf8.encode = function (e, t, r) {
              e = i.encodeUtf8(e);
              var n = t;
              n || (n = new Uint8Array(e.length)), (r = r || 0);
              for (var s = r, a = 0; a < e.length; ++a)
                n[s++] = e.charCodeAt(a);
              return t ? s - r : n;
            }),
            (i.text.utf8.decode = function (e) {
              return i.decodeUtf8(String.fromCharCode.apply(null, e));
            }),
            (i.text.utf16.encode = function (e, t, r) {
              var i = t;
              i || (i = new Uint8Array(2 * e.length));
              var n = new Uint16Array(i.buffer);
              r = r || 0;
              for (var s = r, a = r, o = 0; o < e.length; ++o)
                (n[a++] = e.charCodeAt(o)), (s += 2);
              return t ? s - r : i;
            }),
            (i.text.utf16.decode = function (e) {
              return String.fromCharCode.apply(null, new Uint16Array(e.buffer));
            }),
            (i.deflate = function (e, t, r) {
              if (((t = i.decode64(e.deflate(i.encode64(t)).rval)), r)) {
                var n = 2,
                  s = t.charCodeAt(1);
                32 & s && (n = 6), (t = t.substring(n, t.length - 4));
              }
              return t;
            }),
            (i.inflate = function (e, t, r) {
              var n = e.inflate(i.encode64(t)).rval;
              return null === n ? null : i.decode64(n);
            });
          var o = function (e, t, r) {
              if (!e) throw new Error("WebStorage not available.");
              var n;
              if (
                (null === r
                  ? (n = e.removeItem(t))
                  : ((r = i.encode64(JSON.stringify(r))),
                    (n = e.setItem(t, r))),
                "undefined" != typeof n && n.rval !== !0)
              ) {
                var s = new Error(n.error.message);
                throw ((s.id = n.error.id), (s.name = n.error.name), s);
              }
            },
            c = function (e, t) {
              if (!e) throw new Error("WebStorage not available.");
              var r = e.getItem(t);
              if (e.init)
                if (null === r.rval) {
                  if (r.error) {
                    var n = new Error(r.error.message);
                    throw ((n.id = r.error.id), (n.name = r.error.name), n);
                  }
                  r = null;
                } else r = r.rval;
              return null !== r && (r = JSON.parse(i.decode64(r))), r;
            },
            l = function (e, t, r, i) {
              var n = c(e, t);
              null === n && (n = {}), (n[r] = i), o(e, t, n);
            },
            u = function (e, t, r) {
              var i = c(e, t);
              return null !== i && (i = r in i ? i[r] : null), i;
            },
            p = function (e, t, r) {
              var i = c(e, t);
              if (null !== i && r in i) {
                delete i[r];
                var n = !0;
                for (var s in i) {
                  n = !1;
                  break;
                }
                n && (i = null), o(e, t, i);
              }
            },
            h = function (e, t) {
              o(e, t, null);
            },
            f = function (e, t, r) {
              var i = null;
              "undefined" == typeof r && (r = ["web", "flash"]);
              var n,
                s = !1,
                a = null;
              for (var o in r) {
                n = r[o];
                try {
                  if ("flash" === n || "both" === n) {
                    if (null === t[0])
                      throw new Error("Flash local storage not available.");
                    (i = e.apply(this, t)), (s = "flash" === n);
                  }
                  ("web" !== n && "both" !== n) ||
                    ((t[0] = localStorage), (i = e.apply(this, t)), (s = !0));
                } catch (c) {
                  a = c;
                }
                if (s) break;
              }
              if (!s) throw a;
              return i;
            };
          (i.setItem = function (e, t, r, i, n) {
            f(l, arguments, n);
          }),
            (i.getItem = function (e, t, r, i) {
              return f(u, arguments, i);
            }),
            (i.removeItem = function (e, t, r, i) {
              f(p, arguments, i);
            }),
            (i.clearItems = function (e, t, r) {
              f(h, arguments, r);
            }),
            (i.parseUrl = function (e) {
              var t = /^(https?):\/\/([^:&^\/]*):?(\d*)(.*)$/g;
              t.lastIndex = 0;
              var r = t.exec(e),
                i =
                  null === r
                    ? null
                    : {
                        full: e,
                        scheme: r[1],
                        host: r[2],
                        port: r[3],
                        path: r[4],
                      };
              return (
                i &&
                  ((i.fullHost = i.host),
                  i.port
                    ? 80 !== i.port && "http" === i.scheme
                      ? (i.fullHost += ":" + i.port)
                      : 443 !== i.port &&
                        "https" === i.scheme &&
                        (i.fullHost += ":" + i.port)
                    : "http" === i.scheme
                    ? (i.port = 80)
                    : "https" === i.scheme && (i.port = 443),
                  (i.full = i.scheme + "://" + i.fullHost)),
                i
              );
            });
          var g = null;
          (i.getQueryVariables = function (e) {
            var t,
              r = function (e) {
                for (var t = {}, r = e.split("&"), i = 0; i < r.length; i++) {
                  var n,
                    s,
                    a = r[i].indexOf("=");
                  a > 0
                    ? ((n = r[i].substring(0, a)), (s = r[i].substring(a + 1)))
                    : ((n = r[i]), (s = null)),
                    n in t || (t[n] = []),
                    !(n in Object.prototype) &&
                      null !== s &&
                      t[n].push(unescape(s));
                }
                return t;
              };
            return (
              "undefined" == typeof e
                ? (null === g &&
                    (g =
                      "undefined" != typeof window &&
                      window.location &&
                      window.location.search
                        ? r(window.location.search.substring(1))
                        : {}),
                  (t = g))
                : (t = r(e)),
              t
            );
          }),
            (i.parseFragment = function (e) {
              var t = e,
                r = "",
                n = e.indexOf("?");
              n > 0 && ((t = e.substring(0, n)), (r = e.substring(n + 1)));
              var s = t.split("/");
              s.length > 0 && "" === s[0] && s.shift();
              var a = "" === r ? {} : i.getQueryVariables(r);
              return { pathString: t, queryString: r, path: s, query: a };
            }),
            (i.makeRequest = function (e) {
              var t = i.parseFragment(e),
                r = {
                  path: t.pathString,
                  query: t.queryString,
                  getPath: function (e) {
                    return "undefined" == typeof e ? t.path : t.path[e];
                  },
                  getQuery: function (e, r) {
                    var i;
                    return (
                      "undefined" == typeof e
                        ? (i = t.query)
                        : ((i = t.query[e]),
                          i && "undefined" != typeof r && (i = i[r])),
                      i
                    );
                  },
                  getQueryLast: function (e, t) {
                    var i,
                      n = r.getQuery(e);
                    return (i = n ? n[n.length - 1] : t);
                  },
                };
              return r;
            }),
            (i.makeLink = function (e, t, r) {
              e = jQuery.isArray(e) ? e.join("/") : e;
              var i = jQuery.param(t || {});
              return (
                (r = r || ""),
                e +
                  (i.length > 0 ? "?" + i : "") +
                  (r.length > 0 ? "#" + r : "")
              );
            }),
            (i.setPath = function (e, t, r) {
              if ("object" == typeof e && null !== e)
                for (var i = 0, n = t.length; i < n; ) {
                  var s = t[i++];
                  if (i == n) e[s] = r;
                  else {
                    var a = s in e;
                    (!a ||
                      (a && "object" != typeof e[s]) ||
                      (a && null === e[s])) &&
                      (e[s] = {}),
                      (e = e[s]);
                  }
                }
            }),
            (i.getPath = function (e, t, r) {
              for (
                var i = 0, n = t.length, s = !0;
                s && i < n && "object" == typeof e && null !== e;

              ) {
                var a = t[i++];
                (s = a in e), s && (e = e[a]);
              }
              return s ? e : r;
            }),
            (i.deletePath = function (e, t) {
              if ("object" == typeof e && null !== e)
                for (var r = 0, i = t.length; r < i; ) {
                  var n = t[r++];
                  if (r == i) delete e[n];
                  else {
                    if (!(n in e && "object" == typeof e[n] && null !== e[n]))
                      break;
                    e = e[n];
                  }
                }
            }),
            (i.isEmpty = function (e) {
              for (var t in e) if (e.hasOwnProperty(t)) return !1;
              return !0;
            }),
            (i.format = function (e) {
              for (
                var t, r, i = /%./g, n = 0, s = [], a = 0;
                (t = i.exec(e));

              ) {
                (r = e.substring(a, i.lastIndex - 2)),
                  r.length > 0 && s.push(r),
                  (a = i.lastIndex);
                var o = t[0][1];
                switch (o) {
                  case "s":
                  case "o":
                    n < arguments.length
                      ? s.push(arguments[n++ + 1])
                      : s.push("<?>");
                    break;
                  case "%":
                    s.push("%");
                    break;
                  default:
                    s.push("<%" + o + "?>");
                }
              }
              return s.push(e.substring(a)), s.join("");
            }),
            (i.formatNumber = function (e, t, r, i) {
              var n = e,
                s = isNaN((t = Math.abs(t))) ? 2 : t,
                a = void 0 === r ? "," : r,
                o = void 0 === i ? "." : i,
                c = n < 0 ? "-" : "",
                l = parseInt((n = Math.abs(+n || 0).toFixed(s)), 10) + "",
                u = l.length > 3 ? l.length % 3 : 0;
              return (
                c +
                (u ? l.substr(0, u) + o : "") +
                l.substr(u).replace(/(\d{3})(?=\d)/g, "$1" + o) +
                (s
                  ? a +
                    Math.abs(n - l)
                      .toFixed(s)
                      .slice(2)
                  : "")
              );
            }),
            (i.formatSize = function (e) {
              return (e =
                e >= 1073741824
                  ? i.formatNumber(e / 1073741824, 2, ".", "") + " GiB"
                  : e >= 1048576
                  ? i.formatNumber(e / 1048576, 2, ".", "") + " MiB"
                  : e >= 1024
                  ? i.formatNumber(e / 1024, 0) + " KiB"
                  : i.formatNumber(e, 0) + " bytes");
            }),
            (i.bytesFromIP = function (e) {
              return e.indexOf(".") !== -1
                ? i.bytesFromIPv4(e)
                : e.indexOf(":") !== -1
                ? i.bytesFromIPv6(e)
                : null;
            }),
            (i.bytesFromIPv4 = function (e) {
              if (((e = e.split(".")), 4 !== e.length)) return null;
              for (var t = i.createBuffer(), r = 0; r < e.length; ++r) {
                var n = parseInt(e[r], 10);
                if (isNaN(n)) return null;
                t.putByte(n);
              }
              return t.getBytes();
            }),
            (i.bytesFromIPv6 = function (e) {
              var t = 0;
              e = e.split(":").filter(function (e) {
                return 0 === e.length && ++t, !0;
              });
              for (
                var r = 2 * (8 - e.length + t), n = i.createBuffer(), s = 0;
                s < 8;
                ++s
              )
                if (e[s] && 0 !== e[s].length) {
                  var a = i.hexToBytes(e[s]);
                  a.length < 2 && n.putByte(0), n.putBytes(a);
                } else n.fillWithByte(0, r), (r = 0);
              return n.getBytes();
            }),
            (i.bytesToIP = function (e) {
              return 4 === e.length
                ? i.bytesToIPv4(e)
                : 16 === e.length
                ? i.bytesToIPv6(e)
                : null;
            }),
            (i.bytesToIPv4 = function (e) {
              if (4 !== e.length) return null;
              for (var t = [], r = 0; r < e.length; ++r)
                t.push(e.charCodeAt(r));
              return t.join(".");
            }),
            (i.bytesToIPv6 = function (e) {
              if (16 !== e.length) return null;
              for (var t = [], r = [], n = 0, s = 0; s < e.length; s += 2) {
                for (
                  var a = i.bytesToHex(e[s] + e[s + 1]);
                  "0" === a[0] && "0" !== a;

                )
                  a = a.substr(1);
                if ("0" === a) {
                  var o = r[r.length - 1],
                    c = t.length;
                  o && c === o.end + 1
                    ? ((o.end = c),
                      o.end - o.start > r[n].end - r[n].start &&
                        (n = r.length - 1))
                    : r.push({ start: c, end: c });
                }
                t.push(a);
              }
              if (r.length > 0) {
                var l = r[n];
                l.end - l.start > 0 &&
                  (t.splice(l.start, l.end - l.start + 1, ""),
                  0 === l.start && t.unshift(""),
                  7 === l.end && t.push(""));
              }
              return t.join(":");
            }),
            (i.estimateCores = function (e, t) {
              function r(e, o, c) {
                if (0 === o) {
                  var l = Math.floor(
                    e.reduce(function (e, t) {
                      return e + t;
                    }, 0) / e.length
                  );
                  return (
                    (i.cores = Math.max(1, l)),
                    URL.revokeObjectURL(a),
                    t(null, i.cores)
                  );
                }
                n(c, function (t, i) {
                  e.push(s(c, i)), r(e, o - 1, c);
                });
              }
              function n(e, t) {
                for (var r = [], i = [], n = 0; n < e; ++n) {
                  var s = new Worker(a);
                  s.addEventListener("message", function (n) {
                    if ((i.push(n.data), i.length === e)) {
                      for (var s = 0; s < e; ++s) r[s].terminate();
                      t(null, i);
                    }
                  }),
                    r.push(s);
                }
                for (var n = 0; n < e; ++n) r[n].postMessage(n);
              }
              function s(e, t) {
                for (var r = [], i = 0; i < e; ++i)
                  for (var n = t[i], s = (r[i] = []), a = 0; a < e; ++a)
                    if (i !== a) {
                      var o = t[a];
                      ((n.st > o.st && n.st < o.et) ||
                        (o.st > n.st && o.st < n.et)) &&
                        s.push(a);
                    }
                return r.reduce(function (e, t) {
                  return Math.max(e, t.length);
                }, 0);
              }
              if (
                ("function" == typeof e && ((t = e), (e = {})),
                (e = e || {}),
                "cores" in i && !e.update)
              )
                return t(null, i.cores);
              if (
                "undefined" != typeof navigator &&
                "hardwareConcurrency" in navigator &&
                navigator.hardwareConcurrency > 0
              )
                return (
                  (i.cores = navigator.hardwareConcurrency), t(null, i.cores)
                );
              if ("undefined" == typeof Worker)
                return (i.cores = 1), t(null, i.cores);
              if ("undefined" == typeof Blob)
                return (i.cores = 2), t(null, i.cores);
              var a = URL.createObjectURL(
                new Blob(
                  [
                    "(",
                    function () {
                      self.addEventListener("message", function (e) {
                        for (var t = Date.now(), r = t + 4; Date.now() < r; );
                        self.postMessage({ st: t, et: r });
                      });
                    }.toString(),
                    ")()",
                  ],
                  { type: "application/javascript" }
                )
              );
              r([], 5, 16);
            });
        }
        var i = "util";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/util", ["require", "module"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          (e.cipher = e.cipher || {}),
            (e.cipher.algorithms = e.cipher.algorithms || {}),
            (e.cipher.createCipher = function (t, r) {
              var i = t;
              if (
                ("string" == typeof i &&
                  ((i = e.cipher.getAlgorithm(i)), i && (i = i())),
                !i)
              )
                throw new Error("Unsupported algorithm: " + t);
              return new e.cipher.BlockCipher({
                algorithm: i,
                key: r,
                decrypt: !1,
              });
            }),
            (e.cipher.createDecipher = function (t, r) {
              var i = t;
              if (
                ("string" == typeof i &&
                  ((i = e.cipher.getAlgorithm(i)), i && (i = i())),
                !i)
              )
                throw new Error("Unsupported algorithm: " + t);
              return new e.cipher.BlockCipher({
                algorithm: i,
                key: r,
                decrypt: !0,
              });
            }),
            (e.cipher.registerAlgorithm = function (t, r) {
              (t = t.toUpperCase()), (e.cipher.algorithms[t] = r);
            }),
            (e.cipher.getAlgorithm = function (t) {
              return (
                (t = t.toUpperCase()),
                t in e.cipher.algorithms ? e.cipher.algorithms[t] : null
              );
            });
          var t = (e.cipher.BlockCipher = function (e) {
            (this.algorithm = e.algorithm),
              (this.mode = this.algorithm.mode),
              (this.blockSize = this.mode.blockSize),
              (this._finish = !1),
              (this._input = null),
              (this.output = null),
              (this._op = e.decrypt ? this.mode.decrypt : this.mode.encrypt),
              (this._decrypt = e.decrypt),
              this.algorithm.initialize(e);
          });
          (t.prototype.start = function (t) {
            t = t || {};
            var r = {};
            for (var i in t) r[i] = t[i];
            (r.decrypt = this._decrypt),
              (this._finish = !1),
              (this._input = e.util.createBuffer()),
              (this.output = t.output || e.util.createBuffer()),
              this.mode.start(r);
          }),
            (t.prototype.update = function (e) {
              for (
                e && this._input.putBuffer(e);
                !this._op.call(
                  this.mode,
                  this._input,
                  this.output,
                  this._finish
                ) && !this._finish;

              );
              this._input.compact();
            }),
            (t.prototype.finish = function (e) {
              e &&
                ("ECB" === this.mode.name || "CBC" === this.mode.name) &&
                ((this.mode.pad = function (t) {
                  return e(this.blockSize, t, !1);
                }),
                (this.mode.unpad = function (t) {
                  return e(this.blockSize, t, !0);
                }));
              var t = {};
              return (
                (t.decrypt = this._decrypt),
                (t.overflow = this._input.length() % this.blockSize),
                !(
                  !this._decrypt &&
                  this.mode.pad &&
                  !this.mode.pad(this._input, t)
                ) &&
                  ((this._finish = !0),
                  this.update(),
                  !(
                    this._decrypt &&
                    this.mode.unpad &&
                    !this.mode.unpad(this.output, t)
                  ) &&
                    !(
                      this.mode.afterFinish &&
                      !this.mode.afterFinish(this.output, t)
                    ))
              );
            });
        }
        var i = "cipher";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/cipher", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t) {
            if (
              ("string" == typeof t && (t = e.util.createBuffer(t)),
              e.util.isArray(t) && t.length > 4)
            ) {
              var r = t;
              t = e.util.createBuffer();
              for (var i = 0; i < r.length; ++i) t.putByte(r[i]);
            }
            return (
              e.util.isArray(t) ||
                (t = [t.getInt32(), t.getInt32(), t.getInt32(), t.getInt32()]),
              t
            );
          }
          function r(e) {
            e[e.length - 1] = (e[e.length - 1] + 1) & 4294967295;
          }
          function i(e) {
            return [(e / 4294967296) | 0, 4294967295 & e];
          }
          e.cipher = e.cipher || {};
          var n = (e.cipher.modes = e.cipher.modes || {});
          (n.ecb = function (e) {
            (e = e || {}),
              (this.name = "ECB"),
              (this.cipher = e.cipher),
              (this.blockSize = e.blockSize || 16),
              (this._ints = this.blockSize / 4),
              (this._inBlock = new Array(this._ints)),
              (this._outBlock = new Array(this._ints));
          }),
            (n.ecb.prototype.start = function (e) {}),
            (n.ecb.prototype.encrypt = function (e, t, r) {
              if (e.length() < this.blockSize && !(r && e.length() > 0))
                return !0;
              for (var i = 0; i < this._ints; ++i)
                this._inBlock[i] = e.getInt32();
              this.cipher.encrypt(this._inBlock, this._outBlock);
              for (var i = 0; i < this._ints; ++i)
                t.putInt32(this._outBlock[i]);
            }),
            (n.ecb.prototype.decrypt = function (e, t, r) {
              if (e.length() < this.blockSize && !(r && e.length() > 0))
                return !0;
              for (var i = 0; i < this._ints; ++i)
                this._inBlock[i] = e.getInt32();
              this.cipher.decrypt(this._inBlock, this._outBlock);
              for (var i = 0; i < this._ints; ++i)
                t.putInt32(this._outBlock[i]);
            }),
            (n.ecb.prototype.pad = function (e, t) {
              var r =
                e.length() === this.blockSize
                  ? this.blockSize
                  : this.blockSize - e.length();
              return e.fillWithByte(r, r), !0;
            }),
            (n.ecb.prototype.unpad = function (e, t) {
              if (t.overflow > 0) return !1;
              var r = e.length(),
                i = e.at(r - 1);
              return !(i > this.blockSize << 2) && (e.truncate(i), !0);
            }),
            (n.cbc = function (e) {
              (e = e || {}),
                (this.name = "CBC"),
                (this.cipher = e.cipher),
                (this.blockSize = e.blockSize || 16),
                (this._ints = this.blockSize / 4),
                (this._inBlock = new Array(this._ints)),
                (this._outBlock = new Array(this._ints));
            }),
            (n.cbc.prototype.start = function (e) {
              if (null === e.iv) {
                if (!this._prev) throw new Error("Invalid IV parameter.");
                this._iv = this._prev.slice(0);
              } else {
                if (!("iv" in e)) throw new Error("Invalid IV parameter.");
                (this._iv = t(e.iv)), (this._prev = this._iv.slice(0));
              }
            }),
            (n.cbc.prototype.encrypt = function (e, t, r) {
              if (e.length() < this.blockSize && !(r && e.length() > 0))
                return !0;
              for (var i = 0; i < this._ints; ++i)
                this._inBlock[i] = this._prev[i] ^ e.getInt32();
              this.cipher.encrypt(this._inBlock, this._outBlock);
              for (var i = 0; i < this._ints; ++i)
                t.putInt32(this._outBlock[i]);
              this._prev = this._outBlock;
            }),
            (n.cbc.prototype.decrypt = function (e, t, r) {
              if (e.length() < this.blockSize && !(r && e.length() > 0))
                return !0;
              for (var i = 0; i < this._ints; ++i)
                this._inBlock[i] = e.getInt32();
              this.cipher.decrypt(this._inBlock, this._outBlock);
              for (var i = 0; i < this._ints; ++i)
                t.putInt32(this._prev[i] ^ this._outBlock[i]);
              this._prev = this._inBlock.slice(0);
            }),
            (n.cbc.prototype.pad = function (e, t) {
              var r =
                e.length() === this.blockSize
                  ? this.blockSize
                  : this.blockSize - e.length();
              return e.fillWithByte(r, r), !0;
            }),
            (n.cbc.prototype.unpad = function (e, t) {
              if (t.overflow > 0) return !1;
              var r = e.length(),
                i = e.at(r - 1);
              return !(i > this.blockSize << 2) && (e.truncate(i), !0);
            }),
            (n.cfb = function (t) {
              (t = t || {}),
                (this.name = "CFB"),
                (this.cipher = t.cipher),
                (this.blockSize = t.blockSize || 16),
                (this._ints = this.blockSize / 4),
                (this._inBlock = null),
                (this._outBlock = new Array(this._ints)),
                (this._partialBlock = new Array(this._ints)),
                (this._partialOutput = e.util.createBuffer()),
                (this._partialBytes = 0);
            }),
            (n.cfb.prototype.start = function (e) {
              if (!("iv" in e)) throw new Error("Invalid IV parameter.");
              (this._iv = t(e.iv)),
                (this._inBlock = this._iv.slice(0)),
                (this._partialBytes = 0);
            }),
            (n.cfb.prototype.encrypt = function (e, t, r) {
              var i = e.length();
              if (0 === i) return !0;
              this.cipher.encrypt(this._inBlock, this._outBlock);
              {
                if (!(0 === this._partialBytes && i >= this.blockSize)) {
                  var n = (this.blockSize - i) % this.blockSize;
                  n > 0 && (n = this.blockSize - n),
                    this._partialOutput.clear();
                  for (var s = 0; s < this._ints; ++s)
                    (this._partialBlock[s] = e.getInt32() ^ this._outBlock[s]),
                      this._partialOutput.putInt32(this._partialBlock[s]);
                  if (n > 0) e.read -= this.blockSize;
                  else
                    for (var s = 0; s < this._ints; ++s)
                      this._inBlock[s] = this._partialBlock[s];
                  return (
                    this._partialBytes > 0 &&
                      this._partialOutput.getBytes(this._partialBytes),
                    n > 0 && !r
                      ? (t.putBytes(
                          this._partialOutput.getBytes(n - this._partialBytes)
                        ),
                        (this._partialBytes = n),
                        !0)
                      : (t.putBytes(
                          this._partialOutput.getBytes(i - this._partialBytes)
                        ),
                        void (this._partialBytes = 0))
                  );
                }
                for (var s = 0; s < this._ints; ++s)
                  (this._inBlock[s] = e.getInt32() ^ this._outBlock[s]),
                    t.putInt32(this._inBlock[s]);
              }
            }),
            (n.cfb.prototype.decrypt = function (e, t, r) {
              var i = e.length();
              if (0 === i) return !0;
              this.cipher.encrypt(this._inBlock, this._outBlock);
              {
                if (!(0 === this._partialBytes && i >= this.blockSize)) {
                  var n = (this.blockSize - i) % this.blockSize;
                  n > 0 && (n = this.blockSize - n),
                    this._partialOutput.clear();
                  for (var s = 0; s < this._ints; ++s)
                    (this._partialBlock[s] = e.getInt32()),
                      this._partialOutput.putInt32(
                        this._partialBlock[s] ^ this._outBlock[s]
                      );
                  if (n > 0) e.read -= this.blockSize;
                  else
                    for (var s = 0; s < this._ints; ++s)
                      this._inBlock[s] = this._partialBlock[s];
                  return (
                    this._partialBytes > 0 &&
                      this._partialOutput.getBytes(this._partialBytes),
                    n > 0 && !r
                      ? (t.putBytes(
                          this._partialOutput.getBytes(n - this._partialBytes)
                        ),
                        (this._partialBytes = n),
                        !0)
                      : (t.putBytes(
                          this._partialOutput.getBytes(i - this._partialBytes)
                        ),
                        void (this._partialBytes = 0))
                  );
                }
                for (var s = 0; s < this._ints; ++s)
                  (this._inBlock[s] = e.getInt32()),
                    t.putInt32(this._inBlock[s] ^ this._outBlock[s]);
              }
            }),
            (n.ofb = function (t) {
              (t = t || {}),
                (this.name = "OFB"),
                (this.cipher = t.cipher),
                (this.blockSize = t.blockSize || 16),
                (this._ints = this.blockSize / 4),
                (this._inBlock = null),
                (this._outBlock = new Array(this._ints)),
                (this._partialOutput = e.util.createBuffer()),
                (this._partialBytes = 0);
            }),
            (n.ofb.prototype.start = function (e) {
              if (!("iv" in e)) throw new Error("Invalid IV parameter.");
              (this._iv = t(e.iv)),
                (this._inBlock = this._iv.slice(0)),
                (this._partialBytes = 0);
            }),
            (n.ofb.prototype.encrypt = function (e, t, r) {
              var i = e.length();
              if (0 === e.length()) return !0;
              this.cipher.encrypt(this._inBlock, this._outBlock);
              {
                if (!(0 === this._partialBytes && i >= this.blockSize)) {
                  var n = (this.blockSize - i) % this.blockSize;
                  n > 0 && (n = this.blockSize - n),
                    this._partialOutput.clear();
                  for (var s = 0; s < this._ints; ++s)
                    this._partialOutput.putInt32(
                      e.getInt32() ^ this._outBlock[s]
                    );
                  if (n > 0) e.read -= this.blockSize;
                  else
                    for (var s = 0; s < this._ints; ++s)
                      this._inBlock[s] = this._outBlock[s];
                  return (
                    this._partialBytes > 0 &&
                      this._partialOutput.getBytes(this._partialBytes),
                    n > 0 && !r
                      ? (t.putBytes(
                          this._partialOutput.getBytes(n - this._partialBytes)
                        ),
                        (this._partialBytes = n),
                        !0)
                      : (t.putBytes(
                          this._partialOutput.getBytes(i - this._partialBytes)
                        ),
                        void (this._partialBytes = 0))
                  );
                }
                for (var s = 0; s < this._ints; ++s)
                  t.putInt32(e.getInt32() ^ this._outBlock[s]),
                    (this._inBlock[s] = this._outBlock[s]);
              }
            }),
            (n.ofb.prototype.decrypt = n.ofb.prototype.encrypt),
            (n.ctr = function (t) {
              (t = t || {}),
                (this.name = "CTR"),
                (this.cipher = t.cipher),
                (this.blockSize = t.blockSize || 16),
                (this._ints = this.blockSize / 4),
                (this._inBlock = null),
                (this._outBlock = new Array(this._ints)),
                (this._partialOutput = e.util.createBuffer()),
                (this._partialBytes = 0);
            }),
            (n.ctr.prototype.start = function (e) {
              if (!("iv" in e)) throw new Error("Invalid IV parameter.");
              (this._iv = t(e.iv)),
                (this._inBlock = this._iv.slice(0)),
                (this._partialBytes = 0);
            }),
            (n.ctr.prototype.encrypt = function (e, t, i) {
              var n = e.length();
              if (0 === n) return !0;
              if (
                (this.cipher.encrypt(this._inBlock, this._outBlock),
                0 === this._partialBytes && n >= this.blockSize)
              )
                for (var s = 0; s < this._ints; ++s)
                  t.putInt32(e.getInt32() ^ this._outBlock[s]);
              else {
                var a = (this.blockSize - n) % this.blockSize;
                a > 0 && (a = this.blockSize - a), this._partialOutput.clear();
                for (var s = 0; s < this._ints; ++s)
                  this._partialOutput.putInt32(
                    e.getInt32() ^ this._outBlock[s]
                  );
                if (
                  (a > 0 && (e.read -= this.blockSize),
                  this._partialBytes > 0 &&
                    this._partialOutput.getBytes(this._partialBytes),
                  a > 0 && !i)
                )
                  return (
                    t.putBytes(
                      this._partialOutput.getBytes(a - this._partialBytes)
                    ),
                    (this._partialBytes = a),
                    !0
                  );
                t.putBytes(
                  this._partialOutput.getBytes(n - this._partialBytes)
                ),
                  (this._partialBytes = 0);
              }
              r(this._inBlock);
            }),
            (n.ctr.prototype.decrypt = n.ctr.prototype.encrypt),
            (n.gcm = function (t) {
              (t = t || {}),
                (this.name = "GCM"),
                (this.cipher = t.cipher),
                (this.blockSize = t.blockSize || 16),
                (this._ints = this.blockSize / 4),
                (this._inBlock = new Array(this._ints)),
                (this._outBlock = new Array(this._ints)),
                (this._partialOutput = e.util.createBuffer()),
                (this._partialBytes = 0),
                (this._R = 3774873600);
            }),
            (n.gcm.prototype.start = function (t) {
              if (!("iv" in t)) throw new Error("Invalid IV parameter.");
              var n = e.util.createBuffer(t.iv);
              this._cipherLength = 0;
              var s;
              if (
                ((s =
                  "additionalData" in t
                    ? e.util.createBuffer(t.additionalData)
                    : e.util.createBuffer()),
                "tagLength" in t
                  ? (this._tagLength = t.tagLength)
                  : (this._tagLength = 128),
                (this._tag = null),
                t.decrypt &&
                  ((this._tag = e.util.createBuffer(t.tag).getBytes()),
                  this._tag.length !== this._tagLength / 8))
              )
                throw new Error(
                  "Authentication tag does not match tag length."
                );
              (this._hashBlock = new Array(this._ints)),
                (this.tag = null),
                (this._hashSubkey = new Array(this._ints)),
                this.cipher.encrypt([0, 0, 0, 0], this._hashSubkey),
                (this.componentBits = 4),
                (this._m = this.generateHashTable(
                  this._hashSubkey,
                  this.componentBits
                ));
              var a = n.length();
              if (12 === a)
                this._j0 = [n.getInt32(), n.getInt32(), n.getInt32(), 1];
              else {
                for (this._j0 = [0, 0, 0, 0]; n.length() > 0; )
                  this._j0 = this.ghash(this._hashSubkey, this._j0, [
                    n.getInt32(),
                    n.getInt32(),
                    n.getInt32(),
                    n.getInt32(),
                  ]);
                this._j0 = this.ghash(
                  this._hashSubkey,
                  this._j0,
                  [0, 0].concat(i(8 * a))
                );
              }
              (this._inBlock = this._j0.slice(0)),
                r(this._inBlock),
                (this._partialBytes = 0),
                (s = e.util.createBuffer(s)),
                (this._aDataLength = i(8 * s.length()));
              var o = s.length() % this.blockSize;
              for (
                o && s.fillWithByte(0, this.blockSize - o),
                  this._s = [0, 0, 0, 0];
                s.length() > 0;

              )
                this._s = this.ghash(this._hashSubkey, this._s, [
                  s.getInt32(),
                  s.getInt32(),
                  s.getInt32(),
                  s.getInt32(),
                ]);
            }),
            (n.gcm.prototype.encrypt = function (e, t, i) {
              var n = e.length();
              if (0 === n) return !0;
              if (
                (this.cipher.encrypt(this._inBlock, this._outBlock),
                0 === this._partialBytes && n >= this.blockSize)
              ) {
                for (var s = 0; s < this._ints; ++s)
                  t.putInt32((this._outBlock[s] ^= e.getInt32()));
                this._cipherLength += this.blockSize;
              } else {
                var a = (this.blockSize - n) % this.blockSize;
                a > 0 && (a = this.blockSize - a), this._partialOutput.clear();
                for (var s = 0; s < this._ints; ++s)
                  this._partialOutput.putInt32(
                    e.getInt32() ^ this._outBlock[s]
                  );
                if (0 === a || i) {
                  if (i) {
                    var o = n % this.blockSize;
                    (this._cipherLength += o),
                      this._partialOutput.truncate(this.blockSize - o);
                  } else this._cipherLength += this.blockSize;
                  for (var s = 0; s < this._ints; ++s)
                    this._outBlock[s] = this._partialOutput.getInt32();
                  this._partialOutput.read -= this.blockSize;
                }
                if (
                  (this._partialBytes > 0 &&
                    this._partialOutput.getBytes(this._partialBytes),
                  a > 0 && !i)
                )
                  return (
                    (e.read -= this.blockSize),
                    t.putBytes(
                      this._partialOutput.getBytes(a - this._partialBytes)
                    ),
                    (this._partialBytes = a),
                    !0
                  );
                t.putBytes(
                  this._partialOutput.getBytes(n - this._partialBytes)
                ),
                  (this._partialBytes = 0);
              }
              (this._s = this.ghash(this._hashSubkey, this._s, this._outBlock)),
                r(this._inBlock);
            }),
            (n.gcm.prototype.decrypt = function (e, t, i) {
              var n = e.length();
              if (n < this.blockSize && !(i && n > 0)) return !0;
              this.cipher.encrypt(this._inBlock, this._outBlock),
                r(this._inBlock),
                (this._hashBlock[0] = e.getInt32()),
                (this._hashBlock[1] = e.getInt32()),
                (this._hashBlock[2] = e.getInt32()),
                (this._hashBlock[3] = e.getInt32()),
                (this._s = this.ghash(
                  this._hashSubkey,
                  this._s,
                  this._hashBlock
                ));
              for (var s = 0; s < this._ints; ++s)
                t.putInt32(this._outBlock[s] ^ this._hashBlock[s]);
              n < this.blockSize
                ? (this._cipherLength += n % this.blockSize)
                : (this._cipherLength += this.blockSize);
            }),
            (n.gcm.prototype.afterFinish = function (t, r) {
              var n = !0;
              r.decrypt &&
                r.overflow &&
                t.truncate(this.blockSize - r.overflow),
                (this.tag = e.util.createBuffer());
              var s = this._aDataLength.concat(i(8 * this._cipherLength));
              this._s = this.ghash(this._hashSubkey, this._s, s);
              var a = [];
              this.cipher.encrypt(this._j0, a);
              for (var o = 0; o < this._ints; ++o)
                this.tag.putInt32(this._s[o] ^ a[o]);
              return (
                this.tag.truncate(this.tag.length() % (this._tagLength / 8)),
                r.decrypt && this.tag.bytes() !== this._tag && (n = !1),
                n
              );
            }),
            (n.gcm.prototype.multiply = function (e, t) {
              for (var r = [0, 0, 0, 0], i = t.slice(0), n = 0; n < 128; ++n) {
                var s = e[(n / 32) | 0] & (1 << (31 - (n % 32)));
                s &&
                  ((r[0] ^= i[0]),
                  (r[1] ^= i[1]),
                  (r[2] ^= i[2]),
                  (r[3] ^= i[3])),
                  this.pow(i, i);
              }
              return r;
            }),
            (n.gcm.prototype.pow = function (e, t) {
              for (var r = 1 & e[3], i = 3; i > 0; --i)
                t[i] = (e[i] >>> 1) | ((1 & e[i - 1]) << 31);
              (t[0] = e[0] >>> 1), r && (t[0] ^= this._R);
            }),
            (n.gcm.prototype.tableMultiply = function (e) {
              for (var t = [0, 0, 0, 0], r = 0; r < 32; ++r) {
                var i = (r / 8) | 0,
                  n = (e[i] >>> (4 * (7 - (r % 8)))) & 15,
                  s = this._m[r][n];
                (t[0] ^= s[0]), (t[1] ^= s[1]), (t[2] ^= s[2]), (t[3] ^= s[3]);
              }
              return t;
            }),
            (n.gcm.prototype.ghash = function (e, t, r) {
              return (
                (t[0] ^= r[0]),
                (t[1] ^= r[1]),
                (t[2] ^= r[2]),
                (t[3] ^= r[3]),
                this.tableMultiply(t)
              );
            }),
            (n.gcm.prototype.generateHashTable = function (e, t) {
              for (
                var r = 8 / t, i = 4 * r, n = 16 * r, s = new Array(n), a = 0;
                a < n;
                ++a
              ) {
                var o = [0, 0, 0, 0],
                  c = (a / i) | 0,
                  l = (i - 1 - (a % i)) * t;
                (o[c] = (1 << (t - 1)) << l),
                  (s[a] = this.generateSubHashTable(this.multiply(o, e), t));
              }
              return s;
            }),
            (n.gcm.prototype.generateSubHashTable = function (e, t) {
              var r = 1 << t,
                i = r >>> 1,
                n = new Array(r);
              n[i] = e.slice(0);
              for (var s = i >>> 1; s > 0; )
                this.pow(n[2 * s], (n[s] = [])), (s >>= 1);
              for (s = 2; s < i; ) {
                for (var a = 1; a < s; ++a) {
                  var o = n[s],
                    c = n[a];
                  n[s + a] = [
                    o[0] ^ c[0],
                    o[1] ^ c[1],
                    o[2] ^ c[2],
                    o[3] ^ c[3],
                  ];
                }
                s *= 2;
              }
              for (n[0] = [0, 0, 0, 0], s = i + 1; s < r; ++s) {
                var l = n[s ^ i];
                n[s] = [e[0] ^ l[0], e[1] ^ l[1], e[2] ^ l[2], e[3] ^ l[3]];
              }
              return n;
            });
        }
        var i = "cipherModes";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/cipherModes", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t, r) {
            var i = function () {
              return new e.aes.Algorithm(t, r);
            };
            e.cipher.registerAlgorithm(t, i);
          }
          function r() {
            (p = !0), (c = [0, 1, 2, 4, 8, 16, 32, 64, 128, 27, 54]);
            for (var e = new Array(256), t = 0; t < 128; ++t)
              (e[t] = t << 1), (e[t + 128] = ((t + 128) << 1) ^ 283);
            (a = new Array(256)),
              (o = new Array(256)),
              (l = new Array(4)),
              (u = new Array(4));
            for (var t = 0; t < 4; ++t)
              (l[t] = new Array(256)), (u[t] = new Array(256));
            for (var r, i, n, s, h, f, g, m = 0, d = 0, t = 0; t < 256; ++t) {
              (s = d ^ (d << 1) ^ (d << 2) ^ (d << 3) ^ (d << 4)),
                (s = (s >> 8) ^ (255 & s) ^ 99),
                (a[m] = s),
                (o[s] = m),
                (h = e[s]),
                (r = e[m]),
                (i = e[r]),
                (n = e[i]),
                (f = (h << 24) ^ (s << 16) ^ (s << 8) ^ (s ^ h)),
                (g =
                  ((r ^ i ^ n) << 24) ^
                  ((m ^ n) << 16) ^
                  ((m ^ i ^ n) << 8) ^
                  (m ^ r ^ n));
              for (var y = 0; y < 4; ++y)
                (l[y][m] = f),
                  (u[y][s] = g),
                  (f = (f << 24) | (f >>> 8)),
                  (g = (g << 24) | (g >>> 8));
              0 === m
                ? (m = d = 1)
                : ((m = r ^ e[e[e[r ^ n]]]), (d ^= e[e[d]]));
            }
          }
          function i(e, t) {
            for (
              var r,
                i = e.slice(0),
                n = 1,
                s = i.length,
                o = s + 6 + 1,
                l = h * o,
                p = s;
              p < l;
              ++p
            )
              (r = i[p - 1]),
                p % s === 0
                  ? ((r =
                      (a[(r >>> 16) & 255] << 24) ^
                      (a[(r >>> 8) & 255] << 16) ^
                      (a[255 & r] << 8) ^
                      a[r >>> 24] ^
                      (c[n] << 24)),
                    n++)
                  : s > 6 &&
                    p % s === 4 &&
                    (r =
                      (a[r >>> 24] << 24) ^
                      (a[(r >>> 16) & 255] << 16) ^
                      (a[(r >>> 8) & 255] << 8) ^
                      a[255 & r]),
                (i[p] = i[p - s] ^ r);
            if (t) {
              var f,
                g = u[0],
                m = u[1],
                d = u[2],
                y = u[3],
                k = i.slice(0);
              l = i.length;
              for (var p = 0, v = l - h; p < l; p += h, v -= h)
                if (0 === p || p === l - h)
                  (k[p] = i[v]),
                    (k[p + 1] = i[v + 3]),
                    (k[p + 2] = i[v + 2]),
                    (k[p + 3] = i[v + 1]);
                else
                  for (var E = 0; E < h; ++E)
                    (f = i[v + E]),
                      (k[p + (3 & -E)] =
                        g[a[f >>> 24]] ^
                        m[a[(f >>> 16) & 255]] ^
                        d[a[(f >>> 8) & 255]] ^
                        y[a[255 & f]]);
              i = k;
            }
            return i;
          }
          function n(e, t, r, i) {
            var n,
              s,
              c,
              p,
              h,
              f = e.length / 4 - 1;
            i
              ? ((n = u[0]), (s = u[1]), (c = u[2]), (p = u[3]), (h = o))
              : ((n = l[0]), (s = l[1]), (c = l[2]), (p = l[3]), (h = a));
            var g, m, d, y, k, v, E;
            (g = t[0] ^ e[0]),
              (m = t[i ? 3 : 1] ^ e[1]),
              (d = t[2] ^ e[2]),
              (y = t[i ? 1 : 3] ^ e[3]);
            for (var S = 3, _ = 1; _ < f; ++_)
              (k =
                n[g >>> 24] ^
                s[(m >>> 16) & 255] ^
                c[(d >>> 8) & 255] ^
                p[255 & y] ^
                e[++S]),
                (v =
                  n[m >>> 24] ^
                  s[(d >>> 16) & 255] ^
                  c[(y >>> 8) & 255] ^
                  p[255 & g] ^
                  e[++S]),
                (E =
                  n[d >>> 24] ^
                  s[(y >>> 16) & 255] ^
                  c[(g >>> 8) & 255] ^
                  p[255 & m] ^
                  e[++S]),
                (y =
                  n[y >>> 24] ^
                  s[(g >>> 16) & 255] ^
                  c[(m >>> 8) & 255] ^
                  p[255 & d] ^
                  e[++S]),
                (g = k),
                (m = v),
                (d = E);
            (r[0] =
              (h[g >>> 24] << 24) ^
              (h[(m >>> 16) & 255] << 16) ^
              (h[(d >>> 8) & 255] << 8) ^
              h[255 & y] ^
              e[++S]),
              (r[i ? 3 : 1] =
                (h[m >>> 24] << 24) ^
                (h[(d >>> 16) & 255] << 16) ^
                (h[(y >>> 8) & 255] << 8) ^
                h[255 & g] ^
                e[++S]),
              (r[2] =
                (h[d >>> 24] << 24) ^
                (h[(y >>> 16) & 255] << 16) ^
                (h[(g >>> 8) & 255] << 8) ^
                h[255 & m] ^
                e[++S]),
              (r[i ? 1 : 3] =
                (h[y >>> 24] << 24) ^
                (h[(g >>> 16) & 255] << 16) ^
                (h[(m >>> 8) & 255] << 8) ^
                h[255 & d] ^
                e[++S]);
          }
          function s(t) {
            t = t || {};
            var r,
              i = (t.mode || "CBC").toUpperCase(),
              n = "AES-" + i;
            r = t.decrypt
              ? e.cipher.createDecipher(n, t.key)
              : e.cipher.createCipher(n, t.key);
            var s = r.start;
            return (
              (r.start = function (t, i) {
                var n = null;
                i instanceof e.util.ByteBuffer && ((n = i), (i = {})),
                  (i = i || {}),
                  (i.output = n),
                  (i.iv = t),
                  s.call(r, i);
              }),
              r
            );
          }
          (e.aes = e.aes || {}),
            (e.aes.startEncrypting = function (e, t, r, i) {
              var n = s({ key: e, output: r, decrypt: !1, mode: i });
              return n.start(t), n;
            }),
            (e.aes.createEncryptionCipher = function (e, t) {
              return s({ key: e, output: null, decrypt: !1, mode: t });
            }),
            (e.aes.startDecrypting = function (e, t, r, i) {
              var n = s({ key: e, output: r, decrypt: !0, mode: i });
              return n.start(t), n;
            }),
            (e.aes.createDecryptionCipher = function (e, t) {
              return s({ key: e, output: null, decrypt: !0, mode: t });
            }),
            (e.aes.Algorithm = function (e, t) {
              p || r();
              var i = this;
              (i.name = e),
                (i.mode = new t({
                  blockSize: 16,
                  cipher: {
                    encrypt: function (e, t) {
                      return n(i._w, e, t, !1);
                    },
                    decrypt: function (e, t) {
                      return n(i._w, e, t, !0);
                    },
                  },
                })),
                (i._init = !1);
            }),
            (e.aes.Algorithm.prototype.initialize = function (t) {
              if (!this._init) {
                var r,
                  n = t.key;
                if (
                  "string" != typeof n ||
                  (16 !== n.length && 24 !== n.length && 32 !== n.length)
                ) {
                  if (
                    e.util.isArray(n) &&
                    (16 === n.length || 24 === n.length || 32 === n.length)
                  ) {
                    (r = n), (n = e.util.createBuffer());
                    for (var s = 0; s < r.length; ++s) n.putByte(r[s]);
                  }
                } else n = e.util.createBuffer(n);
                if (!e.util.isArray(n)) {
                  (r = n), (n = []);
                  var a = r.length();
                  if (16 === a || 24 === a || 32 === a) {
                    a >>>= 2;
                    for (var s = 0; s < a; ++s) n.push(r.getInt32());
                  }
                }
                if (
                  !e.util.isArray(n) ||
                  (4 !== n.length && 6 !== n.length && 8 !== n.length)
                )
                  throw new Error("Invalid key parameter.");
                var o = this.mode.name,
                  c = ["CFB", "OFB", "CTR", "GCM"].indexOf(o) !== -1;
                (this._w = i(n, t.decrypt && !c)), (this._init = !0);
              }
            }),
            (e.aes._expandKey = function (e, t) {
              return p || r(), i(e, t);
            }),
            (e.aes._updateBlock = n),
            t("AES-ECB", e.cipher.modes.ecb),
            t("AES-CBC", e.cipher.modes.cbc),
            t("AES-CFB", e.cipher.modes.cfb),
            t("AES-OFB", e.cipher.modes.ofb),
            t("AES-CTR", e.cipher.modes.ctr),
            t("AES-GCM", e.cipher.modes.gcm);
          var a,
            o,
            c,
            l,
            u,
            p = !1,
            h = 4;
        }
        var i = "aes";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/aes",
          ["require", "module", "./cipher", "./cipherModes", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          e.pki = e.pki || {};
          var t = (e.pki.oids = e.oids = e.oids || {});
          (t["1.2.840.113549.1.1.1"] = "rsaEncryption"),
            (t.rsaEncryption = "1.2.840.113549.1.1.1"),
            (t["1.2.840.113549.1.1.4"] = "md5WithRSAEncryption"),
            (t.md5WithRSAEncryption = "1.2.840.113549.1.1.4"),
            (t["1.2.840.113549.1.1.5"] = "sha1WithRSAEncryption"),
            (t.sha1WithRSAEncryption = "1.2.840.113549.1.1.5"),
            (t["1.2.840.113549.1.1.7"] = "RSAES-OAEP"),
            (t["RSAES-OAEP"] = "1.2.840.113549.1.1.7"),
            (t["1.2.840.113549.1.1.8"] = "mgf1"),
            (t.mgf1 = "1.2.840.113549.1.1.8"),
            (t["1.2.840.113549.1.1.9"] = "pSpecified"),
            (t.pSpecified = "1.2.840.113549.1.1.9"),
            (t["1.2.840.113549.1.1.10"] = "RSASSA-PSS"),
            (t["RSASSA-PSS"] = "1.2.840.113549.1.1.10"),
            (t["1.2.840.113549.1.1.11"] = "sha256WithRSAEncryption"),
            (t.sha256WithRSAEncryption = "1.2.840.113549.1.1.11"),
            (t["1.2.840.113549.1.1.12"] = "sha384WithRSAEncryption"),
            (t.sha384WithRSAEncryption = "1.2.840.113549.1.1.12"),
            (t["1.2.840.113549.1.1.13"] = "sha512WithRSAEncryption"),
            (t.sha512WithRSAEncryption = "1.2.840.113549.1.1.13"),
            (t["1.3.14.3.2.7"] = "desCBC"),
            (t.desCBC = "1.3.14.3.2.7"),
            (t["1.3.14.3.2.26"] = "sha1"),
            (t.sha1 = "1.3.14.3.2.26"),
            (t["2.16.840.1.101.3.4.2.1"] = "sha256"),
            (t.sha256 = "2.16.840.1.101.3.4.2.1"),
            (t["2.16.840.1.101.3.4.2.2"] = "sha384"),
            (t.sha384 = "2.16.840.1.101.3.4.2.2"),
            (t["2.16.840.1.101.3.4.2.3"] = "sha512"),
            (t.sha512 = "2.16.840.1.101.3.4.2.3"),
            (t["1.2.840.113549.2.5"] = "md5"),
            (t.md5 = "1.2.840.113549.2.5"),
            (t["1.2.840.113549.1.7.1"] = "data"),
            (t.data = "1.2.840.113549.1.7.1"),
            (t["1.2.840.113549.1.7.2"] = "signedData"),
            (t.signedData = "1.2.840.113549.1.7.2"),
            (t["1.2.840.113549.1.7.3"] = "envelopedData"),
            (t.envelopedData = "1.2.840.113549.1.7.3"),
            (t["1.2.840.113549.1.7.4"] = "signedAndEnvelopedData"),
            (t.signedAndEnvelopedData = "1.2.840.113549.1.7.4"),
            (t["1.2.840.113549.1.7.5"] = "digestedData"),
            (t.digestedData = "1.2.840.113549.1.7.5"),
            (t["1.2.840.113549.1.7.6"] = "encryptedData"),
            (t.encryptedData = "1.2.840.113549.1.7.6"),
            (t["1.2.840.113549.1.9.1"] = "emailAddress"),
            (t.emailAddress = "1.2.840.113549.1.9.1"),
            (t["1.2.840.113549.1.9.2"] = "unstructuredName"),
            (t.unstructuredName = "1.2.840.113549.1.9.2"),
            (t["1.2.840.113549.1.9.3"] = "contentType"),
            (t.contentType = "1.2.840.113549.1.9.3"),
            (t["1.2.840.113549.1.9.4"] = "messageDigest"),
            (t.messageDigest = "1.2.840.113549.1.9.4"),
            (t["1.2.840.113549.1.9.5"] = "signingTime"),
            (t.signingTime = "1.2.840.113549.1.9.5"),
            (t["1.2.840.113549.1.9.6"] = "counterSignature"),
            (t.counterSignature = "1.2.840.113549.1.9.6"),
            (t["1.2.840.113549.1.9.7"] = "challengePassword"),
            (t.challengePassword = "1.2.840.113549.1.9.7"),
            (t["1.2.840.113549.1.9.8"] = "unstructuredAddress"),
            (t.unstructuredAddress = "1.2.840.113549.1.9.8"),
            (t["1.2.840.113549.1.9.14"] = "extensionRequest"),
            (t.extensionRequest = "1.2.840.113549.1.9.14"),
            (t["1.2.840.113549.1.9.20"] = "friendlyName"),
            (t.friendlyName = "1.2.840.113549.1.9.20"),
            (t["1.2.840.113549.1.9.21"] = "localKeyId"),
            (t.localKeyId = "1.2.840.113549.1.9.21"),
            (t["1.2.840.113549.1.9.22.1"] = "x509Certificate"),
            (t.x509Certificate = "1.2.840.113549.1.9.22.1"),
            (t["1.2.840.113549.1.12.10.1.1"] = "keyBag"),
            (t.keyBag = "1.2.840.113549.1.12.10.1.1"),
            (t["1.2.840.113549.1.12.10.1.2"] = "pkcs8ShroudedKeyBag"),
            (t.pkcs8ShroudedKeyBag = "1.2.840.113549.1.12.10.1.2"),
            (t["1.2.840.113549.1.12.10.1.3"] = "certBag"),
            (t.certBag = "1.2.840.113549.1.12.10.1.3"),
            (t["1.2.840.113549.1.12.10.1.4"] = "crlBag"),
            (t.crlBag = "1.2.840.113549.1.12.10.1.4"),
            (t["1.2.840.113549.1.12.10.1.5"] = "secretBag"),
            (t.secretBag = "1.2.840.113549.1.12.10.1.5"),
            (t["1.2.840.113549.1.12.10.1.6"] = "safeContentsBag"),
            (t.safeContentsBag = "1.2.840.113549.1.12.10.1.6"),
            (t["1.2.840.113549.1.5.13"] = "pkcs5PBES2"),
            (t.pkcs5PBES2 = "1.2.840.113549.1.5.13"),
            (t["1.2.840.113549.1.5.12"] = "pkcs5PBKDF2"),
            (t.pkcs5PBKDF2 = "1.2.840.113549.1.5.12"),
            (t["1.2.840.113549.1.12.1.1"] = "pbeWithSHAAnd128BitRC4"),
            (t.pbeWithSHAAnd128BitRC4 = "1.2.840.113549.1.12.1.1"),
            (t["1.2.840.113549.1.12.1.2"] = "pbeWithSHAAnd40BitRC4"),
            (t.pbeWithSHAAnd40BitRC4 = "1.2.840.113549.1.12.1.2"),
            (t["1.2.840.113549.1.12.1.3"] = "pbeWithSHAAnd3-KeyTripleDES-CBC"),
            (t["pbeWithSHAAnd3-KeyTripleDES-CBC"] = "1.2.840.113549.1.12.1.3"),
            (t["1.2.840.113549.1.12.1.4"] = "pbeWithSHAAnd2-KeyTripleDES-CBC"),
            (t["pbeWithSHAAnd2-KeyTripleDES-CBC"] = "1.2.840.113549.1.12.1.4"),
            (t["1.2.840.113549.1.12.1.5"] = "pbeWithSHAAnd128BitRC2-CBC"),
            (t["pbeWithSHAAnd128BitRC2-CBC"] = "1.2.840.113549.1.12.1.5"),
            (t["1.2.840.113549.1.12.1.6"] = "pbewithSHAAnd40BitRC2-CBC"),
            (t["pbewithSHAAnd40BitRC2-CBC"] = "1.2.840.113549.1.12.1.6"),
            (t["1.2.840.113549.3.7"] = "des-EDE3-CBC"),
            (t["des-EDE3-CBC"] = "1.2.840.113549.3.7"),
            (t["2.16.840.1.101.3.4.1.2"] = "aes128-CBC"),
            (t["aes128-CBC"] = "2.16.840.1.101.3.4.1.2"),
            (t["2.16.840.1.101.3.4.1.22"] = "aes192-CBC"),
            (t["aes192-CBC"] = "2.16.840.1.101.3.4.1.22"),
            (t["2.16.840.1.101.3.4.1.42"] = "aes256-CBC"),
            (t["aes256-CBC"] = "2.16.840.1.101.3.4.1.42"),
            (t["2.5.4.3"] = "commonName"),
            (t.commonName = "2.5.4.3"),
            (t["2.5.4.5"] = "serialName"),
            (t.serialName = "2.5.4.5"),
            (t["2.5.4.6"] = "countryName"),
            (t.countryName = "2.5.4.6"),
            (t["2.5.4.7"] = "localityName"),
            (t.localityName = "2.5.4.7"),
            (t["2.5.4.8"] = "stateOrProvinceName"),
            (t.stateOrProvinceName = "2.5.4.8"),
            (t["2.5.4.10"] = "organizationName"),
            (t.organizationName = "2.5.4.10"),
            (t["2.5.4.11"] = "organizationalUnitName"),
            (t.organizationalUnitName = "2.5.4.11"),
            (t["2.16.840.1.113730.1.1"] = "nsCertType"),
            (t.nsCertType = "2.16.840.1.113730.1.1"),
            (t["2.5.29.1"] = "authorityKeyIdentifier"),
            (t["2.5.29.2"] = "keyAttributes"),
            (t["2.5.29.3"] = "certificatePolicies"),
            (t["2.5.29.4"] = "keyUsageRestriction"),
            (t["2.5.29.5"] = "policyMapping"),
            (t["2.5.29.6"] = "subtreesConstraint"),
            (t["2.5.29.7"] = "subjectAltName"),
            (t["2.5.29.8"] = "issuerAltName"),
            (t["2.5.29.9"] = "subjectDirectoryAttributes"),
            (t["2.5.29.10"] = "basicConstraints"),
            (t["2.5.29.11"] = "nameConstraints"),
            (t["2.5.29.12"] = "policyConstraints"),
            (t["2.5.29.13"] = "basicConstraints"),
            (t["2.5.29.14"] = "subjectKeyIdentifier"),
            (t.subjectKeyIdentifier = "2.5.29.14"),
            (t["2.5.29.15"] = "keyUsage"),
            (t.keyUsage = "2.5.29.15"),
            (t["2.5.29.16"] = "privateKeyUsagePeriod"),
            (t["2.5.29.17"] = "subjectAltName"),
            (t.subjectAltName = "2.5.29.17"),
            (t["2.5.29.18"] = "issuerAltName"),
            (t.issuerAltName = "2.5.29.18"),
            (t["2.5.29.19"] = "basicConstraints"),
            (t.basicConstraints = "2.5.29.19"),
            (t["2.5.29.20"] = "cRLNumber"),
            (t["2.5.29.21"] = "cRLReason"),
            (t["2.5.29.22"] = "expirationDate"),
            (t["2.5.29.23"] = "instructionCode"),
            (t["2.5.29.24"] = "invalidityDate"),
            (t["2.5.29.25"] = "cRLDistributionPoints"),
            (t["2.5.29.26"] = "issuingDistributionPoint"),
            (t["2.5.29.27"] = "deltaCRLIndicator"),
            (t["2.5.29.28"] = "issuingDistributionPoint"),
            (t["2.5.29.29"] = "certificateIssuer"),
            (t["2.5.29.30"] = "nameConstraints"),
            (t["2.5.29.31"] = "cRLDistributionPoints"),
            (t["2.5.29.32"] = "certificatePolicies"),
            (t["2.5.29.33"] = "policyMappings"),
            (t["2.5.29.34"] = "policyConstraints"),
            (t["2.5.29.35"] = "authorityKeyIdentifier"),
            (t["2.5.29.36"] = "policyConstraints"),
            (t["2.5.29.37"] = "extKeyUsage"),
            (t.extKeyUsage = "2.5.29.37"),
            (t["2.5.29.46"] = "freshestCRL"),
            (t["2.5.29.54"] = "inhibitAnyPolicy"),
            (t["1.3.6.1.5.5.7.3.1"] = "serverAuth"),
            (t.serverAuth = "1.3.6.1.5.5.7.3.1"),
            (t["1.3.6.1.5.5.7.3.2"] = "clientAuth"),
            (t.clientAuth = "1.3.6.1.5.5.7.3.2"),
            (t["1.3.6.1.5.5.7.3.3"] = "codeSigning"),
            (t.codeSigning = "1.3.6.1.5.5.7.3.3"),
            (t["1.3.6.1.5.5.7.3.4"] = "emailProtection"),
            (t.emailProtection = "1.3.6.1.5.5.7.3.4"),
            (t["1.3.6.1.5.5.7.3.8"] = "timeStamping"),
            (t.timeStamping = "1.3.6.1.5.5.7.3.8");
        }
        var i = "oids";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/oids", ["require", "module"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          var t = (e.asn1 = e.asn1 || {});
          (t.Class = {
            UNIVERSAL: 0,
            APPLICATION: 64,
            CONTEXT_SPECIFIC: 128,
            PRIVATE: 192,
          }),
            (t.Type = {
              NONE: 0,
              BOOLEAN: 1,
              INTEGER: 2,
              BITSTRING: 3,
              OCTETSTRING: 4,
              NULL: 5,
              OID: 6,
              ODESC: 7,
              EXTERNAL: 8,
              REAL: 9,
              ENUMERATED: 10,
              EMBEDDED: 11,
              UTF8: 12,
              ROID: 13,
              SEQUENCE: 16,
              SET: 17,
              PRINTABLESTRING: 19,
              IA5STRING: 22,
              UTCTIME: 23,
              GENERALIZEDTIME: 24,
              BMPSTRING: 30,
            }),
            (t.create = function (t, r, i, n) {
              if (e.util.isArray(n)) {
                for (var s = [], a = 0; a < n.length; ++a)
                  void 0 !== n[a] && s.push(n[a]);
                n = s;
              }
              return {
                tagClass: t,
                type: r,
                constructed: i,
                composed: i || e.util.isArray(n),
                value: n,
              };
            });
          var r = (t.getBerValueLength = function (e) {
            var t = e.getByte();
            if (128 !== t) {
              var r,
                i = 128 & t;
              return (r = i ? e.getInt((127 & t) << 3) : t);
            }
          });
          (t.fromDer = function (i, n) {
            if (
              (void 0 === n && (n = !0),
              "string" == typeof i && (i = e.util.createBuffer(i)),
              i.length() < 2)
            ) {
              var s = new Error("Too few bytes to parse DER.");
              throw ((s.bytes = i.length()), s);
            }
            var a = i.getByte(),
              o = 192 & a,
              c = 31 & a,
              l = r(i);
            if (i.length() < l) {
              if (n) {
                var s = new Error("Too few bytes to read ASN.1 value.");
                throw ((s.detail = i.length() + " < " + l), s);
              }
              l = i.length();
            }
            var u,
              p = 32 === (32 & a),
              h = p;
            if (
              !h &&
              o === t.Class.UNIVERSAL &&
              c === t.Type.BITSTRING &&
              l > 1
            ) {
              var f = i.read,
                g = i.getByte();
              if (0 === g) {
                a = i.getByte();
                var m = 192 & a;
                if (m === t.Class.UNIVERSAL || m === t.Class.CONTEXT_SPECIFIC)
                  try {
                    var d = r(i);
                    (h = d === l - (i.read - f)), h && (++f, --l);
                  } catch (y) {}
              }
              i.read = f;
            }
            if (h)
              if (((u = []), void 0 === l))
                for (;;) {
                  if (i.bytes(2) === String.fromCharCode(0, 0)) {
                    i.getBytes(2);
                    break;
                  }
                  u.push(t.fromDer(i, n));
                }
              else
                for (var k = i.length(); l > 0; )
                  u.push(t.fromDer(i, n)),
                    (l -= k - i.length()),
                    (k = i.length());
            else {
              if (void 0 === l) {
                if (n)
                  throw new Error(
                    "Non-constructed ASN.1 object of indefinite length."
                  );
                l = i.length();
              }
              if (c === t.Type.BMPSTRING) {
                u = "";
                for (var v = 0; v < l; v += 2)
                  u += String.fromCharCode(i.getInt16());
              } else u = i.getBytes(l);
            }
            return t.create(o, c, p, u);
          }),
            (t.toDer = function (r) {
              var i = e.util.createBuffer(),
                n = r.tagClass | r.type,
                s = e.util.createBuffer();
              if (r.composed) {
                r.constructed ? (n |= 32) : s.putByte(0);
                for (var a = 0; a < r.value.length; ++a)
                  void 0 !== r.value[a] && s.putBuffer(t.toDer(r.value[a]));
              } else if (r.type === t.Type.BMPSTRING)
                for (var a = 0; a < r.value.length; ++a)
                  s.putInt16(r.value.charCodeAt(a));
              else s.putBytes(r.value);
              if ((i.putByte(n), s.length() <= 127))
                i.putByte(127 & s.length());
              else {
                var o = s.length(),
                  c = "";
                do (c += String.fromCharCode(255 & o)), (o >>>= 8);
                while (o > 0);
                i.putByte(128 | c.length);
                for (var a = c.length - 1; a >= 0; --a)
                  i.putByte(c.charCodeAt(a));
              }
              return i.putBuffer(s), i;
            }),
            (t.oidToDer = function (t) {
              var r = t.split("."),
                i = e.util.createBuffer();
              i.putByte(40 * parseInt(r[0], 10) + parseInt(r[1], 10));
              for (var n, s, a, o, c = 2; c < r.length; ++c) {
                (n = !0), (s = []), (a = parseInt(r[c], 10));
                do
                  (o = 127 & a),
                    (a >>>= 7),
                    n || (o |= 128),
                    s.push(o),
                    (n = !1);
                while (a > 0);
                for (var l = s.length - 1; l >= 0; --l) i.putByte(s[l]);
              }
              return i;
            }),
            (t.derToOid = function (t) {
              var r;
              "string" == typeof t && (t = e.util.createBuffer(t));
              var i = t.getByte();
              r = Math.floor(i / 40) + "." + (i % 40);
              for (var n = 0; t.length() > 0; )
                (i = t.getByte()),
                  (n <<= 7),
                  128 & i ? (n += 127 & i) : ((r += "." + (n + i)), (n = 0));
              return r;
            }),
            (t.utcTimeToDate = function (e) {
              var t = new Date(),
                r = parseInt(e.substr(0, 2), 10);
              r = r >= 50 ? 1900 + r : 2e3 + r;
              var i = parseInt(e.substr(2, 2), 10) - 1,
                n = parseInt(e.substr(4, 2), 10),
                s = parseInt(e.substr(6, 2), 10),
                a = parseInt(e.substr(8, 2), 10),
                o = 0;
              if (e.length > 11) {
                var c = e.charAt(10),
                  l = 10;
                "+" !== c &&
                  "-" !== c &&
                  ((o = parseInt(e.substr(10, 2), 10)), (l += 2));
              }
              if (
                (t.setUTCFullYear(r, i, n),
                t.setUTCHours(s, a, o, 0),
                l && ((c = e.charAt(l)), "+" === c || "-" === c))
              ) {
                var u = parseInt(e.substr(l + 1, 2), 10),
                  p = parseInt(e.substr(l + 4, 2), 10),
                  h = 60 * u + p;
                (h *= 6e4), "+" === c ? t.setTime(+t - h) : t.setTime(+t + h);
              }
              return t;
            }),
            (t.generalizedTimeToDate = function (e) {
              var t = new Date(),
                r = parseInt(e.substr(0, 4), 10),
                i = parseInt(e.substr(4, 2), 10) - 1,
                n = parseInt(e.substr(6, 2), 10),
                s = parseInt(e.substr(8, 2), 10),
                a = parseInt(e.substr(10, 2), 10),
                o = parseInt(e.substr(12, 2), 10),
                c = 0,
                l = 0,
                u = !1;
              "Z" === e.charAt(e.length - 1) && (u = !0);
              var p = e.length - 5,
                h = e.charAt(p);
              if ("+" === h || "-" === h) {
                var f = parseInt(e.substr(p + 1, 2), 10),
                  g = parseInt(e.substr(p + 4, 2), 10);
                (l = 60 * f + g), (l *= 6e4), "+" === h && (l *= -1), (u = !0);
              }
              return (
                "." === e.charAt(14) &&
                  (c = 1e3 * parseFloat(e.substr(14), 10)),
                u
                  ? (t.setUTCFullYear(r, i, n),
                    t.setUTCHours(s, a, o, c),
                    t.setTime(+t + l))
                  : (t.setFullYear(r, i, n), t.setHours(s, a, o, c)),
                t
              );
            }),
            (t.dateToUtcTime = function (e) {
              if ("string" == typeof e) return e;
              var t = "",
                r = [];
              r.push(("" + e.getUTCFullYear()).substr(2)),
                r.push("" + (e.getUTCMonth() + 1)),
                r.push("" + e.getUTCDate()),
                r.push("" + e.getUTCHours()),
                r.push("" + e.getUTCMinutes()),
                r.push("" + e.getUTCSeconds());
              for (var i = 0; i < r.length; ++i)
                r[i].length < 2 && (t += "0"), (t += r[i]);
              return (t += "Z");
            }),
            (t.dateToGeneralizedTime = function (e) {
              if ("string" == typeof e) return e;
              var t = "",
                r = [];
              r.push("" + e.getUTCFullYear()),
                r.push("" + (e.getUTCMonth() + 1)),
                r.push("" + e.getUTCDate()),
                r.push("" + e.getUTCHours()),
                r.push("" + e.getUTCMinutes()),
                r.push("" + e.getUTCSeconds());
              for (var i = 0; i < r.length; ++i)
                r[i].length < 2 && (t += "0"), (t += r[i]);
              return (t += "Z");
            }),
            (t.integerToDer = function (t) {
              var r = e.util.createBuffer();
              if (t >= -128 && t < 128) return r.putSignedInt(t, 8);
              if (t >= -32768 && t < 32768) return r.putSignedInt(t, 16);
              if (t >= -8388608 && t < 8388608) return r.putSignedInt(t, 24);
              if (t >= -2147483648 && t < 2147483648)
                return r.putSignedInt(t, 32);
              var i = new Error("Integer too large; max is 32-bits.");
              throw ((i.integer = t), i);
            }),
            (t.derToInteger = function (t) {
              "string" == typeof t && (t = e.util.createBuffer(t));
              var r = 8 * t.length();
              if (r > 32) throw new Error("Integer too large; max is 32-bits.");
              return t.getSignedInt(r);
            }),
            (t.validate = function (r, i, n, s) {
              var a = !1;
              if (
                (r.tagClass !== i.tagClass &&
                  "undefined" != typeof i.tagClass) ||
                (r.type !== i.type && "undefined" != typeof i.type)
              )
                s &&
                  (r.tagClass !== i.tagClass &&
                    s.push(
                      "[" +
                        i.name +
                        '] Expected tag class "' +
                        i.tagClass +
                        '", got "' +
                        r.tagClass +
                        '"'
                    ),
                  r.type !== i.type &&
                    s.push(
                      "[" +
                        i.name +
                        '] Expected type "' +
                        i.type +
                        '", got "' +
                        r.type +
                        '"'
                    ));
              else if (
                r.constructed === i.constructed ||
                "undefined" == typeof i.constructed
              ) {
                if (((a = !0), i.value && e.util.isArray(i.value)))
                  for (var o = 0, c = 0; a && c < i.value.length; ++c)
                    (a = i.value[c].optional || !1),
                      r.value[o] &&
                        ((a = t.validate(r.value[o], i.value[c], n, s)),
                        a ? ++o : i.value[c].optional && (a = !0)),
                      !a &&
                        s &&
                        s.push(
                          "[" +
                            i.name +
                            '] Tag class "' +
                            i.tagClass +
                            '", type "' +
                            i.type +
                            '" expected value length "' +
                            i.value.length +
                            '", got "' +
                            r.value.length +
                            '"'
                        );
                a &&
                  n &&
                  (i.capture && (n[i.capture] = r.value),
                  i.captureAsn1 && (n[i.captureAsn1] = r));
              } else
                s &&
                  s.push(
                    "[" +
                      i.name +
                      '] Expected constructed "' +
                      i.constructed +
                      '", got "' +
                      r.constructed +
                      '"'
                  );
              return a;
            });
          var i = /[^\\u0000-\\u00ff]/;
          t.prettyPrint = function (r, n, s) {
            var a = "";
            (n = n || 0), (s = s || 2), n > 0 && (a += "\n");
            for (var o = "", c = 0; c < n * s; ++c) o += " ";
            switch (((a += o + "Tag: "), r.tagClass)) {
              case t.Class.UNIVERSAL:
                a += "Universal:";
                break;
              case t.Class.APPLICATION:
                a += "Application:";
                break;
              case t.Class.CONTEXT_SPECIFIC:
                a += "Context-Specific:";
                break;
              case t.Class.PRIVATE:
                a += "Private:";
            }
            if (r.tagClass === t.Class.UNIVERSAL)
              switch (((a += r.type), r.type)) {
                case t.Type.NONE:
                  a += " (None)";
                  break;
                case t.Type.BOOLEAN:
                  a += " (Boolean)";
                  break;
                case t.Type.BITSTRING:
                  a += " (Bit string)";
                  break;
                case t.Type.INTEGER:
                  a += " (Integer)";
                  break;
                case t.Type.OCTETSTRING:
                  a += " (Octet string)";
                  break;
                case t.Type.NULL:
                  a += " (Null)";
                  break;
                case t.Type.OID:
                  a += " (Object Identifier)";
                  break;
                case t.Type.ODESC:
                  a += " (Object Descriptor)";
                  break;
                case t.Type.EXTERNAL:
                  a += " (External or Instance of)";
                  break;
                case t.Type.REAL:
                  a += " (Real)";
                  break;
                case t.Type.ENUMERATED:
                  a += " (Enumerated)";
                  break;
                case t.Type.EMBEDDED:
                  a += " (Embedded PDV)";
                  break;
                case t.Type.UTF8:
                  a += " (UTF8)";
                  break;
                case t.Type.ROID:
                  a += " (Relative Object Identifier)";
                  break;
                case t.Type.SEQUENCE:
                  a += " (Sequence)";
                  break;
                case t.Type.SET:
                  a += " (Set)";
                  break;
                case t.Type.PRINTABLESTRING:
                  a += " (Printable String)";
                  break;
                case t.Type.IA5String:
                  a += " (IA5String (ASCII))";
                  break;
                case t.Type.UTCTIME:
                  a += " (UTC time)";
                  break;
                case t.Type.GENERALIZEDTIME:
                  a += " (Generalized time)";
                  break;
                case t.Type.BMPSTRING:
                  a += " (BMP String)";
              }
            else a += r.type;
            if (
              ((a += "\n"),
              (a += o + "Constructed: " + r.constructed + "\n"),
              r.composed)
            ) {
              for (var l = 0, u = "", c = 0; c < r.value.length; ++c)
                void 0 !== r.value[c] &&
                  ((l += 1),
                  (u += t.prettyPrint(r.value[c], n + 1, s)),
                  c + 1 < r.value.length && (u += ","));
              a += o + "Sub values: " + l + u;
            } else {
              if (((a += o + "Value: "), r.type === t.Type.OID)) {
                var p = t.derToOid(r.value);
                (a += p),
                  e.pki &&
                    e.pki.oids &&
                    p in e.pki.oids &&
                    (a += " (" + e.pki.oids[p] + ") ");
              }
              if (r.type === t.Type.INTEGER)
                try {
                  a += t.derToInteger(r.value);
                } catch (h) {
                  a += "0x" + e.util.bytesToHex(r.value);
                }
              else
                r.type === t.Type.OCTETSTRING
                  ? (i.test(r.value) || (a += "(" + r.value + ") "),
                    (a += "0x" + e.util.bytesToHex(r.value)))
                  : (a +=
                      r.type === t.Type.UTF8
                        ? e.util.decodeUtf8(r.value)
                        : r.type === t.Type.PRINTABLESTRING ||
                          r.type === t.Type.IA5String
                        ? r.value
                        : i.test(r.value)
                        ? "0x" + e.util.bytesToHex(r.value)
                        : 0 === r.value.length
                        ? "[null]"
                        : r.value);
            }
            return a;
          };
        }
        var i = "asn1";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/asn1", ["require", "module", "./util", "./oids"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t() {
            (n = String.fromCharCode(128)),
              (n += e.util.fillString(String.fromCharCode(0), 64)),
              (s = [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 1, 6, 11,
                0, 5, 10, 15, 4, 9, 14, 3, 8, 13, 2, 7, 12, 5, 8, 11, 14, 1, 4,
                7, 10, 13, 0, 3, 6, 9, 12, 15, 2, 0, 7, 14, 5, 12, 3, 10, 1, 8,
                15, 6, 13, 4, 11, 2, 9,
              ]),
              (a = [
                7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 5,
                9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 4, 11, 16,
                23, 4, 11, 16, 23, 4, 11, 16, 23, 4, 11, 16, 23, 6, 10, 15, 21,
                6, 10, 15, 21, 6, 10, 15, 21, 6, 10, 15, 21,
              ]),
              (o = new Array(64));
            for (var t = 0; t < 64; ++t)
              o[t] = Math.floor(4294967296 * Math.abs(Math.sin(t + 1)));
            c = !0;
          }
          function r(e, t, r) {
            for (var i, n, c, l, u, p, h, f, g = r.length(); g >= 64; ) {
              for (n = e.h0, c = e.h1, l = e.h2, u = e.h3, f = 0; f < 16; ++f)
                (t[f] = r.getInt32Le()),
                  (p = u ^ (c & (l ^ u))),
                  (i = n + p + o[f] + t[f]),
                  (h = a[f]),
                  (n = u),
                  (u = l),
                  (l = c),
                  (c += (i << h) | (i >>> (32 - h)));
              for (; f < 32; ++f)
                (p = l ^ (u & (c ^ l))),
                  (i = n + p + o[f] + t[s[f]]),
                  (h = a[f]),
                  (n = u),
                  (u = l),
                  (l = c),
                  (c += (i << h) | (i >>> (32 - h)));
              for (; f < 48; ++f)
                (p = c ^ l ^ u),
                  (i = n + p + o[f] + t[s[f]]),
                  (h = a[f]),
                  (n = u),
                  (u = l),
                  (l = c),
                  (c += (i << h) | (i >>> (32 - h)));
              for (; f < 64; ++f)
                (p = l ^ (c | ~u)),
                  (i = n + p + o[f] + t[s[f]]),
                  (h = a[f]),
                  (n = u),
                  (u = l),
                  (l = c),
                  (c += (i << h) | (i >>> (32 - h)));
              (e.h0 = (e.h0 + n) | 0),
                (e.h1 = (e.h1 + c) | 0),
                (e.h2 = (e.h2 + l) | 0),
                (e.h3 = (e.h3 + u) | 0),
                (g -= 64);
            }
          }
          var i = (e.md5 = e.md5 || {});
          (e.md = e.md || {}),
            (e.md.algorithms = e.md.algorithms || {}),
            (e.md.md5 = e.md.algorithms.md5 = i),
            (i.create = function () {
              c || t();
              var i = null,
                s = e.util.createBuffer(),
                a = new Array(16),
                o = {
                  algorithm: "md5",
                  blockLength: 64,
                  digestLength: 16,
                  messageLength: 0,
                  fullMessageLength: null,
                  messageLengthSize: 8,
                };
              return (
                (o.start = function () {
                  (o.messageLength = 0),
                    (o.fullMessageLength = o.messageLength64 = []);
                  for (var t = o.messageLengthSize / 4, r = 0; r < t; ++r)
                    o.fullMessageLength.push(0);
                  return (
                    (s = e.util.createBuffer()),
                    (i = {
                      h0: 1732584193,
                      h1: 4023233417,
                      h2: 2562383102,
                      h3: 271733878,
                    }),
                    o
                  );
                }),
                o.start(),
                (o.update = function (t, n) {
                  "utf8" === n && (t = e.util.encodeUtf8(t));
                  var c = t.length;
                  (o.messageLength += c),
                    (c = [(c / 4294967296) >>> 0, c >>> 0]);
                  for (var l = o.fullMessageLength.length - 1; l >= 0; --l)
                    (o.fullMessageLength[l] += c[1]),
                      (c[1] =
                        c[0] + ((o.fullMessageLength[l] / 4294967296) >>> 0)),
                      (o.fullMessageLength[l] = o.fullMessageLength[l] >>> 0),
                      (c[0] = (c[1] / 4294967296) >>> 0);
                  return (
                    s.putBytes(t),
                    r(i, a, s),
                    (s.read > 2048 || 0 === s.length()) && s.compact(),
                    o
                  );
                }),
                (o.digest = function () {
                  var t = e.util.createBuffer();
                  t.putBytes(s.bytes());
                  var c =
                      o.fullMessageLength[o.fullMessageLength.length - 1] +
                      o.messageLengthSize,
                    l = c & (o.blockLength - 1);
                  t.putBytes(n.substr(0, o.blockLength - l));
                  for (
                    var u, p = 0, h = o.fullMessageLength.length - 1;
                    h >= 0;
                    --h
                  )
                    (u = 8 * o.fullMessageLength[h] + p),
                      (p = (u / 4294967296) >>> 0),
                      t.putInt32Le(u >>> 0);
                  var f = { h0: i.h0, h1: i.h1, h2: i.h2, h3: i.h3 };
                  r(f, a, t);
                  var g = e.util.createBuffer();
                  return (
                    g.putInt32Le(f.h0),
                    g.putInt32Le(f.h1),
                    g.putInt32Le(f.h2),
                    g.putInt32Le(f.h3),
                    g
                  );
                }),
                o
              );
            });
          var n = null,
            s = null,
            a = null,
            o = null,
            c = !1;
        }
        var i = "md5";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/md5", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t() {
            (n = String.fromCharCode(128)),
              (n += e.util.fillString(String.fromCharCode(0), 64)),
              (s = !0);
          }
          function r(e, t, r) {
            for (var i, n, s, a, o, c, l, u, p = r.length(); p >= 64; ) {
              for (
                n = e.h0, s = e.h1, a = e.h2, o = e.h3, c = e.h4, u = 0;
                u < 16;
                ++u
              )
                (i = r.getInt32()),
                  (t[u] = i),
                  (l = o ^ (s & (a ^ o))),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 1518500249 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              for (; u < 20; ++u)
                (i = t[u - 3] ^ t[u - 8] ^ t[u - 14] ^ t[u - 16]),
                  (i = (i << 1) | (i >>> 31)),
                  (t[u] = i),
                  (l = o ^ (s & (a ^ o))),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 1518500249 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              for (; u < 32; ++u)
                (i = t[u - 3] ^ t[u - 8] ^ t[u - 14] ^ t[u - 16]),
                  (i = (i << 1) | (i >>> 31)),
                  (t[u] = i),
                  (l = s ^ a ^ o),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 1859775393 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              for (; u < 40; ++u)
                (i = t[u - 6] ^ t[u - 16] ^ t[u - 28] ^ t[u - 32]),
                  (i = (i << 2) | (i >>> 30)),
                  (t[u] = i),
                  (l = s ^ a ^ o),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 1859775393 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              for (; u < 60; ++u)
                (i = t[u - 6] ^ t[u - 16] ^ t[u - 28] ^ t[u - 32]),
                  (i = (i << 2) | (i >>> 30)),
                  (t[u] = i),
                  (l = (s & a) | (o & (s ^ a))),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 2400959708 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              for (; u < 80; ++u)
                (i = t[u - 6] ^ t[u - 16] ^ t[u - 28] ^ t[u - 32]),
                  (i = (i << 2) | (i >>> 30)),
                  (t[u] = i),
                  (l = s ^ a ^ o),
                  (i = ((n << 5) | (n >>> 27)) + l + c + 3395469782 + i),
                  (c = o),
                  (o = a),
                  (a = (s << 30) | (s >>> 2)),
                  (s = n),
                  (n = i);
              (e.h0 = (e.h0 + n) | 0),
                (e.h1 = (e.h1 + s) | 0),
                (e.h2 = (e.h2 + a) | 0),
                (e.h3 = (e.h3 + o) | 0),
                (e.h4 = (e.h4 + c) | 0),
                (p -= 64);
            }
          }
          var i = (e.sha1 = e.sha1 || {});
          (e.md = e.md || {}),
            (e.md.algorithms = e.md.algorithms || {}),
            (e.md.sha1 = e.md.algorithms.sha1 = i),
            (i.create = function () {
              s || t();
              var i = null,
                a = e.util.createBuffer(),
                o = new Array(80),
                c = {
                  algorithm: "sha1",
                  blockLength: 64,
                  digestLength: 20,
                  messageLength: 0,
                  fullMessageLength: null,
                  messageLengthSize: 8,
                };
              return (
                (c.start = function () {
                  (c.messageLength = 0),
                    (c.fullMessageLength = c.messageLength64 = []);
                  for (var t = c.messageLengthSize / 4, r = 0; r < t; ++r)
                    c.fullMessageLength.push(0);
                  return (
                    (a = e.util.createBuffer()),
                    (i = {
                      h0: 1732584193,
                      h1: 4023233417,
                      h2: 2562383102,
                      h3: 271733878,
                      h4: 3285377520,
                    }),
                    c
                  );
                }),
                c.start(),
                (c.update = function (t, n) {
                  "utf8" === n && (t = e.util.encodeUtf8(t));
                  var s = t.length;
                  (c.messageLength += s),
                    (s = [(s / 4294967296) >>> 0, s >>> 0]);
                  for (var l = c.fullMessageLength.length - 1; l >= 0; --l)
                    (c.fullMessageLength[l] += s[1]),
                      (s[1] =
                        s[0] + ((c.fullMessageLength[l] / 4294967296) >>> 0)),
                      (c.fullMessageLength[l] = c.fullMessageLength[l] >>> 0),
                      (s[0] = (s[1] / 4294967296) >>> 0);
                  return (
                    a.putBytes(t),
                    r(i, o, a),
                    (a.read > 2048 || 0 === a.length()) && a.compact(),
                    c
                  );
                }),
                (c.digest = function () {
                  var t = e.util.createBuffer();
                  t.putBytes(a.bytes());
                  var s =
                      c.fullMessageLength[c.fullMessageLength.length - 1] +
                      c.messageLengthSize,
                    l = s & (c.blockLength - 1);
                  t.putBytes(n.substr(0, c.blockLength - l));
                  for (
                    var u,
                      p,
                      h = (e.util.createBuffer(), 8 * c.fullMessageLength[0]),
                      f = 0;
                    f < c.fullMessageLength.length;
                    ++f
                  )
                    (u = 8 * c.fullMessageLength[f + 1]),
                      (p = (u / 4294967296) >>> 0),
                      (h += p),
                      t.putInt32(h >>> 0),
                      (h = u);
                  var g = { h0: i.h0, h1: i.h1, h2: i.h2, h3: i.h3, h4: i.h4 };
                  r(g, o, t);
                  var m = e.util.createBuffer();
                  return (
                    m.putInt32(g.h0),
                    m.putInt32(g.h1),
                    m.putInt32(g.h2),
                    m.putInt32(g.h3),
                    m.putInt32(g.h4),
                    m
                  );
                }),
                c
              );
            });
          var n = null,
            s = !1;
        }
        var i = "sha1";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/sha1", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t() {
            (n = String.fromCharCode(128)),
              (n += e.util.fillString(String.fromCharCode(0), 64)),
              (a = [
                1116352408, 1899447441, 3049323471, 3921009573, 961987163,
                1508970993, 2453635748, 2870763221, 3624381080, 310598401,
                607225278, 1426881987, 1925078388, 2162078206, 2614888103,
                3248222580, 3835390401, 4022224774, 264347078, 604807628,
                770255983, 1249150122, 1555081692, 1996064986, 2554220882,
                2821834349, 2952996808, 3210313671, 3336571891, 3584528711,
                113926993, 338241895, 666307205, 773529912, 1294757372,
                1396182291, 1695183700, 1986661051, 2177026350, 2456956037,
                2730485921, 2820302411, 3259730800, 3345764771, 3516065817,
                3600352804, 4094571909, 275423344, 430227734, 506948616,
                659060556, 883997877, 958139571, 1322822218, 1537002063,
                1747873779, 1955562222, 2024104815, 2227730452, 2361852424,
                2428436474, 2756734187, 3204031479, 3329325298,
              ]),
              (s = !0);
          }
          function r(e, t, r) {
            for (
              var i, n, s, o, c, l, u, p, h, f, g, m, d, y, k, v = r.length();
              v >= 64;

            ) {
              for (u = 0; u < 16; ++u) t[u] = r.getInt32();
              for (; u < 64; ++u)
                (i = t[u - 2]),
                  (i =
                    ((i >>> 17) | (i << 15)) ^
                    ((i >>> 19) | (i << 13)) ^
                    (i >>> 10)),
                  (n = t[u - 15]),
                  (n =
                    ((n >>> 7) | (n << 25)) ^
                    ((n >>> 18) | (n << 14)) ^
                    (n >>> 3)),
                  (t[u] = (i + t[u - 7] + n + t[u - 16]) | 0);
              for (
                p = e.h0,
                  h = e.h1,
                  f = e.h2,
                  g = e.h3,
                  m = e.h4,
                  d = e.h5,
                  y = e.h6,
                  k = e.h7,
                  u = 0;
                u < 64;
                ++u
              )
                (o =
                  ((m >>> 6) | (m << 26)) ^
                  ((m >>> 11) | (m << 21)) ^
                  ((m >>> 25) | (m << 7))),
                  (c = y ^ (m & (d ^ y))),
                  (s =
                    ((p >>> 2) | (p << 30)) ^
                    ((p >>> 13) | (p << 19)) ^
                    ((p >>> 22) | (p << 10))),
                  (l = (p & h) | (f & (p ^ h))),
                  (i = k + o + c + a[u] + t[u]),
                  (n = s + l),
                  (k = y),
                  (y = d),
                  (d = m),
                  (m = (g + i) | 0),
                  (g = f),
                  (f = h),
                  (h = p),
                  (p = (i + n) | 0);
              (e.h0 = (e.h0 + p) | 0),
                (e.h1 = (e.h1 + h) | 0),
                (e.h2 = (e.h2 + f) | 0),
                (e.h3 = (e.h3 + g) | 0),
                (e.h4 = (e.h4 + m) | 0),
                (e.h5 = (e.h5 + d) | 0),
                (e.h6 = (e.h6 + y) | 0),
                (e.h7 = (e.h7 + k) | 0),
                (v -= 64);
            }
          }
          var i = (e.sha256 = e.sha256 || {});
          (e.md = e.md || {}),
            (e.md.algorithms = e.md.algorithms || {}),
            (e.md.sha256 = e.md.algorithms.sha256 = i),
            (i.create = function () {
              s || t();
              var i = null,
                a = e.util.createBuffer(),
                o = new Array(64),
                c = {
                  algorithm: "sha256",
                  blockLength: 64,
                  digestLength: 32,
                  messageLength: 0,
                  fullMessageLength: null,
                  messageLengthSize: 8,
                };
              return (
                (c.start = function () {
                  (c.messageLength = 0),
                    (c.fullMessageLength = c.messageLength64 = []);
                  for (var t = c.messageLengthSize / 4, r = 0; r < t; ++r)
                    c.fullMessageLength.push(0);
                  return (
                    (a = e.util.createBuffer()),
                    (i = {
                      h0: 1779033703,
                      h1: 3144134277,
                      h2: 1013904242,
                      h3: 2773480762,
                      h4: 1359893119,
                      h5: 2600822924,
                      h6: 528734635,
                      h7: 1541459225,
                    }),
                    c
                  );
                }),
                c.start(),
                (c.update = function (t, n) {
                  "utf8" === n && (t = e.util.encodeUtf8(t));
                  var s = t.length;
                  (c.messageLength += s),
                    (s = [(s / 4294967296) >>> 0, s >>> 0]);
                  for (var l = c.fullMessageLength.length - 1; l >= 0; --l)
                    (c.fullMessageLength[l] += s[1]),
                      (s[1] =
                        s[0] + ((c.fullMessageLength[l] / 4294967296) >>> 0)),
                      (c.fullMessageLength[l] = c.fullMessageLength[l] >>> 0),
                      (s[0] = (s[1] / 4294967296) >>> 0);
                  return (
                    a.putBytes(t),
                    r(i, o, a),
                    (a.read > 2048 || 0 === a.length()) && a.compact(),
                    c
                  );
                }),
                (c.digest = function () {
                  var t = e.util.createBuffer();
                  t.putBytes(a.bytes());
                  var s =
                      c.fullMessageLength[c.fullMessageLength.length - 1] +
                      c.messageLengthSize,
                    l = s & (c.blockLength - 1);
                  t.putBytes(n.substr(0, c.blockLength - l));
                  for (
                    var u,
                      p,
                      h = (e.util.createBuffer(), 8 * c.fullMessageLength[0]),
                      f = 0;
                    f < c.fullMessageLength.length;
                    ++f
                  )
                    (u = 8 * c.fullMessageLength[f + 1]),
                      (p = (u / 4294967296) >>> 0),
                      (h += p),
                      t.putInt32(h >>> 0),
                      (h = u);
                  var g = {
                    h0: i.h0,
                    h1: i.h1,
                    h2: i.h2,
                    h3: i.h3,
                    h4: i.h4,
                    h5: i.h5,
                    h6: i.h6,
                    h7: i.h7,
                  };
                  r(g, o, t);
                  var m = e.util.createBuffer();
                  return (
                    m.putInt32(g.h0),
                    m.putInt32(g.h1),
                    m.putInt32(g.h2),
                    m.putInt32(g.h3),
                    m.putInt32(g.h4),
                    m.putInt32(g.h5),
                    m.putInt32(g.h6),
                    m.putInt32(g.h7),
                    m
                  );
                }),
                c
              );
            });
          var n = null,
            s = !1,
            a = null;
        }
        var i = "sha256";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/sha256", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t() {
            (s = String.fromCharCode(128)),
              (s += e.util.fillString(String.fromCharCode(0), 128)),
              (o = [
                [1116352408, 3609767458],
                [1899447441, 602891725],
                [3049323471, 3964484399],
                [3921009573, 2173295548],
                [961987163, 4081628472],
                [1508970993, 3053834265],
                [2453635748, 2937671579],
                [2870763221, 3664609560],
                [3624381080, 2734883394],
                [310598401, 1164996542],
                [607225278, 1323610764],
                [1426881987, 3590304994],
                [1925078388, 4068182383],
                [2162078206, 991336113],
                [2614888103, 633803317],
                [3248222580, 3479774868],
                [3835390401, 2666613458],
                [4022224774, 944711139],
                [264347078, 2341262773],
                [604807628, 2007800933],
                [770255983, 1495990901],
                [1249150122, 1856431235],
                [1555081692, 3175218132],
                [1996064986, 2198950837],
                [2554220882, 3999719339],
                [2821834349, 766784016],
                [2952996808, 2566594879],
                [3210313671, 3203337956],
                [3336571891, 1034457026],
                [3584528711, 2466948901],
                [113926993, 3758326383],
                [338241895, 168717936],
                [666307205, 1188179964],
                [773529912, 1546045734],
                [1294757372, 1522805485],
                [1396182291, 2643833823],
                [1695183700, 2343527390],
                [1986661051, 1014477480],
                [2177026350, 1206759142],
                [2456956037, 344077627],
                [2730485921, 1290863460],
                [2820302411, 3158454273],
                [3259730800, 3505952657],
                [3345764771, 106217008],
                [3516065817, 3606008344],
                [3600352804, 1432725776],
                [4094571909, 1467031594],
                [275423344, 851169720],
                [430227734, 3100823752],
                [506948616, 1363258195],
                [659060556, 3750685593],
                [883997877, 3785050280],
                [958139571, 3318307427],
                [1322822218, 3812723403],
                [1537002063, 2003034995],
                [1747873779, 3602036899],
                [1955562222, 1575990012],
                [2024104815, 1125592928],
                [2227730452, 2716904306],
                [2361852424, 442776044],
                [2428436474, 593698344],
                [2756734187, 3733110249],
                [3204031479, 2999351573],
                [3329325298, 3815920427],
                [3391569614, 3928383900],
                [3515267271, 566280711],
                [3940187606, 3454069534],
                [4118630271, 4000239992],
                [116418474, 1914138554],
                [174292421, 2731055270],
                [289380356, 3203993006],
                [460393269, 320620315],
                [685471733, 587496836],
                [852142971, 1086792851],
                [1017036298, 365543100],
                [1126000580, 2618297676],
                [1288033470, 3409855158],
                [1501505948, 4234509866],
                [1607167915, 987167468],
                [1816402316, 1246189591],
              ]),
              (c = {}),
              (c["SHA-512"] = [
                [1779033703, 4089235720],
                [3144134277, 2227873595],
                [1013904242, 4271175723],
                [2773480762, 1595750129],
                [1359893119, 2917565137],
                [2600822924, 725511199],
                [528734635, 4215389547],
                [1541459225, 327033209],
              ]),
              (c["SHA-384"] = [
                [3418070365, 3238371032],
                [1654270250, 914150663],
                [2438529370, 812702999],
                [355462360, 4144912697],
                [1731405415, 4290775857],
                [2394180231, 1750603025],
                [3675008525, 1694076839],
                [1203062813, 3204075428],
              ]),
              (c["SHA-512/256"] = [
                [573645204, 4230739756],
                [2673172387, 3360449730],
                [596883563, 1867755857],
                [2520282905, 1497426621],
                [2519219938, 2827943907],
                [3193839141, 1401305490],
                [721525244, 746961066],
                [246885852, 2177182882],
              ]),
              (c["SHA-512/224"] = [
                [2352822216, 424955298],
                [1944164710, 2312950998],
                [502970286, 855612546],
                [1738396948, 1479516111],
                [258812777, 2077511080],
                [2011393907, 79989058],
                [1067287976, 1780299464],
                [286451373, 2446758561],
              ]),
              (a = !0);
          }
          function r(e, t, r) {
            for (
              var i,
                n,
                s,
                a,
                c,
                l,
                u,
                p,
                h,
                f,
                g,
                m,
                d,
                y,
                k,
                v,
                E,
                S,
                _,
                b,
                I,
                A,
                C,
                T,
                N,
                j,
                w,
                R,
                O,
                B,
                D,
                P,
                U,
                L,
                x,
                G = r.length();
              G >= 128;

            ) {
              for (O = 0; O < 16; ++O)
                (t[O][0] = r.getInt32() >>> 0), (t[O][1] = r.getInt32() >>> 0);
              for (; O < 80; ++O)
                (P = t[O - 2]),
                  (B = P[0]),
                  (D = P[1]),
                  (i =
                    (((B >>> 19) | (D << 13)) ^
                      ((D >>> 29) | (B << 3)) ^
                      (B >>> 6)) >>>
                    0),
                  (n =
                    (((B << 13) | (D >>> 19)) ^
                      ((D << 3) | (B >>> 29)) ^
                      ((B << 26) | (D >>> 6))) >>>
                    0),
                  (L = t[O - 15]),
                  (B = L[0]),
                  (D = L[1]),
                  (s =
                    (((B >>> 1) | (D << 31)) ^
                      ((B >>> 8) | (D << 24)) ^
                      (B >>> 7)) >>>
                    0),
                  (a =
                    (((B << 31) | (D >>> 1)) ^
                      ((B << 24) | (D >>> 8)) ^
                      ((B << 25) | (D >>> 7))) >>>
                    0),
                  (U = t[O - 7]),
                  (x = t[O - 16]),
                  (D = n + U[1] + a + x[1]),
                  (t[O][0] =
                    (i + U[0] + s + x[0] + ((D / 4294967296) >>> 0)) >>> 0),
                  (t[O][1] = D >>> 0);
              for (
                d = e[0][0],
                  y = e[0][1],
                  k = e[1][0],
                  v = e[1][1],
                  E = e[2][0],
                  S = e[2][1],
                  _ = e[3][0],
                  b = e[3][1],
                  I = e[4][0],
                  A = e[4][1],
                  C = e[5][0],
                  T = e[5][1],
                  N = e[6][0],
                  j = e[6][1],
                  w = e[7][0],
                  R = e[7][1],
                  O = 0;
                O < 80;
                ++O
              )
                (u =
                  (((I >>> 14) | (A << 18)) ^
                    ((I >>> 18) | (A << 14)) ^
                    ((A >>> 9) | (I << 23))) >>>
                  0),
                  (p =
                    (((I << 18) | (A >>> 14)) ^
                      ((I << 14) | (A >>> 18)) ^
                      ((A << 23) | (I >>> 9))) >>>
                    0),
                  (h = (N ^ (I & (C ^ N))) >>> 0),
                  (f = (j ^ (A & (T ^ j))) >>> 0),
                  (c =
                    (((d >>> 28) | (y << 4)) ^
                      ((y >>> 2) | (d << 30)) ^
                      ((y >>> 7) | (d << 25))) >>>
                    0),
                  (l =
                    (((d << 4) | (y >>> 28)) ^
                      ((y << 30) | (d >>> 2)) ^
                      ((y << 25) | (d >>> 7))) >>>
                    0),
                  (g = ((d & k) | (E & (d ^ k))) >>> 0),
                  (m = ((y & v) | (S & (y ^ v))) >>> 0),
                  (D = R + p + f + o[O][1] + t[O][1]),
                  (i =
                    (w +
                      u +
                      h +
                      o[O][0] +
                      t[O][0] +
                      ((D / 4294967296) >>> 0)) >>>
                    0),
                  (n = D >>> 0),
                  (D = l + m),
                  (s = (c + g + ((D / 4294967296) >>> 0)) >>> 0),
                  (a = D >>> 0),
                  (w = N),
                  (R = j),
                  (N = C),
                  (j = T),
                  (C = I),
                  (T = A),
                  (D = b + n),
                  (I = (_ + i + ((D / 4294967296) >>> 0)) >>> 0),
                  (A = D >>> 0),
                  (_ = E),
                  (b = S),
                  (E = k),
                  (S = v),
                  (k = d),
                  (v = y),
                  (D = n + a),
                  (d = (i + s + ((D / 4294967296) >>> 0)) >>> 0),
                  (y = D >>> 0);
              (D = e[0][1] + y),
                (e[0][0] = (e[0][0] + d + ((D / 4294967296) >>> 0)) >>> 0),
                (e[0][1] = D >>> 0),
                (D = e[1][1] + v),
                (e[1][0] = (e[1][0] + k + ((D / 4294967296) >>> 0)) >>> 0),
                (e[1][1] = D >>> 0),
                (D = e[2][1] + S),
                (e[2][0] = (e[2][0] + E + ((D / 4294967296) >>> 0)) >>> 0),
                (e[2][1] = D >>> 0),
                (D = e[3][1] + b),
                (e[3][0] = (e[3][0] + _ + ((D / 4294967296) >>> 0)) >>> 0),
                (e[3][1] = D >>> 0),
                (D = e[4][1] + A),
                (e[4][0] = (e[4][0] + I + ((D / 4294967296) >>> 0)) >>> 0),
                (e[4][1] = D >>> 0),
                (D = e[5][1] + T),
                (e[5][0] = (e[5][0] + C + ((D / 4294967296) >>> 0)) >>> 0),
                (e[5][1] = D >>> 0),
                (D = e[6][1] + j),
                (e[6][0] = (e[6][0] + N + ((D / 4294967296) >>> 0)) >>> 0),
                (e[6][1] = D >>> 0),
                (D = e[7][1] + R),
                (e[7][0] = (e[7][0] + w + ((D / 4294967296) >>> 0)) >>> 0),
                (e[7][1] = D >>> 0),
                (G -= 128);
            }
          }
          var i = (e.sha512 = e.sha512 || {});
          (e.md = e.md || {}),
            (e.md.algorithms = e.md.algorithms || {}),
            (e.md.sha512 = e.md.algorithms.sha512 = i);
          var n = (e.sha384 = e.sha512.sha384 = e.sha512.sha384 || {});
          (n.create = function () {
            return i.create("SHA-384");
          }),
            (e.md.sha384 = e.md.algorithms.sha384 = n),
            (e.sha512.sha256 = e.sha512.sha256 || {
              create: function () {
                return i.create("SHA-512/256");
              },
            }),
            (e.md["sha512/256"] = e.md.algorithms["sha512/256"] =
              e.sha512.sha256),
            (e.sha512.sha224 = e.sha512.sha224 || {
              create: function () {
                return i.create("SHA-512/224");
              },
            }),
            (e.md["sha512/224"] = e.md.algorithms["sha512/224"] =
              e.sha512.sha224),
            (i.create = function (i) {
              if (
                (a || t(), "undefined" == typeof i && (i = "SHA-512"), i in c)
              ) {
                for (
                  var n = c[i],
                    o = null,
                    l = e.util.createBuffer(),
                    u = new Array(80),
                    p = 0;
                  p < 80;
                  ++p
                )
                  u[p] = new Array(2);
                var h = {
                  algorithm: i.replace("-", "").toLowerCase(),
                  blockLength: 128,
                  digestLength: 64,
                  messageLength: 0,
                  fullMessageLength: null,
                  messageLengthSize: 16,
                };
                return (
                  (h.start = function () {
                    (h.messageLength = 0),
                      (h.fullMessageLength = h.messageLength128 = []);
                    for (var t = h.messageLengthSize / 4, r = 0; r < t; ++r)
                      h.fullMessageLength.push(0);
                    (l = e.util.createBuffer()), (o = new Array(n.length));
                    for (var r = 0; r < n.length; ++r) o[r] = n[r].slice(0);
                    return h;
                  }),
                  h.start(),
                  (h.update = function (t, i) {
                    "utf8" === i && (t = e.util.encodeUtf8(t));
                    var n = t.length;
                    (h.messageLength += n),
                      (n = [(n / 4294967296) >>> 0, n >>> 0]);
                    for (var s = h.fullMessageLength.length - 1; s >= 0; --s)
                      (h.fullMessageLength[s] += n[1]),
                        (n[1] =
                          n[0] + ((h.fullMessageLength[s] / 4294967296) >>> 0)),
                        (h.fullMessageLength[s] = h.fullMessageLength[s] >>> 0),
                        (n[0] = (n[1] / 4294967296) >>> 0);
                    return (
                      l.putBytes(t),
                      r(o, u, l),
                      (l.read > 2048 || 0 === l.length()) && l.compact(),
                      h
                    );
                  }),
                  (h.digest = function () {
                    var t = e.util.createBuffer();
                    t.putBytes(l.bytes());
                    var n =
                        h.fullMessageLength[h.fullMessageLength.length - 1] +
                        h.messageLengthSize,
                      a = n & (h.blockLength - 1);
                    t.putBytes(s.substr(0, h.blockLength - a));
                    for (
                      var c,
                        p,
                        f = (e.util.createBuffer(), 8 * h.fullMessageLength[0]),
                        g = 0;
                      g < h.fullMessageLength.length;
                      ++g
                    )
                      (c = 8 * h.fullMessageLength[g + 1]),
                        (p = (c / 4294967296) >>> 0),
                        (f += p),
                        t.putInt32(f >>> 0),
                        (f = c);
                    for (var m = new Array(o.length), g = 0; g < o.length; ++g)
                      m[g] = o[g].slice(0);
                    r(m, u, t);
                    var d,
                      y = e.util.createBuffer();
                    d =
                      "SHA-512" === i
                        ? m.length
                        : "SHA-384" === i
                        ? m.length - 2
                        : m.length - 4;
                    for (var g = 0; g < d; ++g)
                      y.putInt32(m[g][0]),
                        (g !== d - 1 || "SHA-512/224" !== i) &&
                          y.putInt32(m[g][1]);
                    return y;
                  }),
                  h
                );
              }
              throw new Error("Invalid SHA-512 algorithm: " + i);
            });
          var s = null,
            a = !1,
            o = null,
            c = null;
        }
        var i = "sha512";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/sha512", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          (e.md = e.md || {}),
            (e.md.algorithms = { md5: e.md5, sha1: e.sha1, sha256: e.sha256 }),
            (e.md.md5 = e.md5),
            (e.md.sha1 = e.sha1),
            (e.md.sha256 = e.sha256);
        }
        var i = "md";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/md",
          ["require", "module", "./md5", "./sha1", "./sha256", "./sha512"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = (e.hmac = e.hmac || {});
          t.create = function () {
            var t = null,
              r = null,
              i = null,
              n = null,
              s = {};
            return (
              (s.start = function (s, a) {
                if (null !== s)
                  if ("string" == typeof s) {
                    if (((s = s.toLowerCase()), !(s in e.md.algorithms)))
                      throw new Error('Unknown hash algorithm "' + s + '"');
                    r = e.md.algorithms[s].create();
                  } else r = s;
                if (null === a) a = t;
                else {
                  if ("string" == typeof a) a = e.util.createBuffer(a);
                  else if (e.util.isArray(a)) {
                    var o = a;
                    a = e.util.createBuffer();
                    for (var c = 0; c < o.length; ++c) a.putByte(o[c]);
                  }
                  var l = a.length();
                  l > r.blockLength &&
                    (r.start(), r.update(a.bytes()), (a = r.digest())),
                    (i = e.util.createBuffer()),
                    (n = e.util.createBuffer()),
                    (l = a.length());
                  for (var c = 0; c < l; ++c) {
                    var o = a.at(c);
                    i.putByte(54 ^ o), n.putByte(92 ^ o);
                  }
                  if (l < r.blockLength)
                    for (var o = r.blockLength - l, c = 0; c < o; ++c)
                      i.putByte(54), n.putByte(92);
                  (t = a), (i = i.bytes()), (n = n.bytes());
                }
                r.start(), r.update(i);
              }),
              (s.update = function (e) {
                r.update(e);
              }),
              (s.getMac = function () {
                var e = r.digest().bytes();
                return r.start(), r.update(n), r.update(e), r.digest();
              }),
              (s.digest = s.getMac),
              s
            );
          };
        }
        var i = "hmac";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/hmac", ["require", "module", "./md", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(e) {
            for (
              var t = e.name + ": ",
                r = [],
                i = function (e, t) {
                  return " " + t;
                },
                n = 0;
              n < e.values.length;
              ++n
            )
              r.push(e.values[n].replace(/^(\S+\r\n)/, i));
            t += r.join(",") + "\r\n";
            for (var s = 0, a = -1, n = 0; n < t.length; ++n, ++s)
              if (s > 65 && a !== -1) {
                var o = t[a];
                "," === o
                  ? (++a, (t = t.substr(0, a) + "\r\n " + t.substr(a)))
                  : (t = t.substr(0, a) + "\r\n" + o + t.substr(a + 1)),
                  (s = n - a - 1),
                  (a = -1),
                  ++n;
              } else (" " !== t[n] && "\t" !== t[n] && "," !== t[n]) || (a = n);
            return t;
          }
          function r(e) {
            return e.replace(/^\s+/, "");
          }
          var i = (e.pem = e.pem || {});
          (i.encode = function (r, i) {
            i = i || {};
            var n,
              s = "-----BEGIN " + r.type + "-----\r\n";
            if (
              (r.procType &&
                ((n = {
                  name: "Proc-Type",
                  values: [String(r.procType.version), r.procType.type],
                }),
                (s += t(n))),
              r.contentDomain &&
                ((n = { name: "Content-Domain", values: [r.contentDomain] }),
                (s += t(n))),
              r.dekInfo &&
                ((n = { name: "DEK-Info", values: [r.dekInfo.algorithm] }),
                r.dekInfo.parameters && n.values.push(r.dekInfo.parameters),
                (s += t(n))),
              r.headers)
            )
              for (var a = 0; a < r.headers.length; ++a) s += t(r.headers[a]);
            return (
              r.procType && (s += "\r\n"),
              (s += e.util.encode64(r.body, i.maxline || 64) + "\r\n"),
              (s += "-----END " + r.type + "-----\r\n")
            );
          }),
            (i.decode = function (t) {
              for (
                var i,
                  n = [],
                  s =
                    /\s*-----BEGIN ([A-Z0-9- ]+)-----\r?\n?([\x21-\x7e\s]+?(?:\r?\n\r?\n))?([:A-Za-z0-9+\/=\s]+?)-----END \1-----/g,
                  a = /([\x21-\x7e]+):\s*([\x21-\x7e\s^:]+)/,
                  o = /\r?\n/;
                (i = s.exec(t)), i;

              ) {
                var c = {
                  type: i[1],
                  procType: null,
                  contentDomain: null,
                  dekInfo: null,
                  headers: [],
                  body: e.util.decode64(i[3]),
                };
                if ((n.push(c), i[2])) {
                  for (var l = i[2].split(o), u = 0; i && u < l.length; ) {
                    for (
                      var p = l[u].replace(/\s+$/, ""), h = u + 1;
                      h < l.length;
                      ++h
                    ) {
                      var f = l[h];
                      if (!/\s/.test(f[0])) break;
                      (p += f), (u = h);
                    }
                    if ((i = p.match(a))) {
                      for (
                        var g = { name: i[1], values: [] },
                          m = i[2].split(","),
                          d = 0;
                        d < m.length;
                        ++d
                      )
                        g.values.push(r(m[d]));
                      if (c.procType)
                        if (c.contentDomain || "Content-Domain" !== g.name)
                          if (c.dekInfo || "DEK-Info" !== g.name)
                            c.headers.push(g);
                          else {
                            if (0 === g.values.length)
                              throw new Error(
                                'Invalid PEM formatted message. The "DEK-Info" header must have at least one subfield.'
                              );
                            c.dekInfo = {
                              algorithm: m[0],
                              parameters: m[1] || null,
                            };
                          }
                        else c.contentDomain = m[0] || "";
                      else {
                        if ("Proc-Type" !== g.name)
                          throw new Error(
                            'Invalid PEM formatted message. The first encapsulated header must be "Proc-Type".'
                          );
                        if (2 !== g.values.length)
                          throw new Error(
                            'Invalid PEM formatted message. The "Proc-Type" header must have two subfields.'
                          );
                        c.procType = { version: m[0], type: m[1] };
                      }
                    }
                    ++u;
                  }
                  if ("ENCRYPTED" === c.procType && !c.dekInfo)
                    throw new Error(
                      'Invalid PEM formatted message. The "DEK-Info" header must be present if "Proc-Type" is "ENCRYPTED".'
                    );
                }
              }
              if (0 === n.length)
                throw new Error("Invalid PEM formatted message.");
              return n;
            });
        }
        var i = "pem";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/pem", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t, r) {
            var i = function () {
              return new e.des.Algorithm(t, r);
            };
            e.cipher.registerAlgorithm(t, i);
          }
          function r(e) {
            for (
              var t,
                r = [
                  0, 4, 536870912, 536870916, 65536, 65540, 536936448,
                  536936452, 512, 516, 536871424, 536871428, 66048, 66052,
                  536936960, 536936964,
                ],
                i = [
                  0, 1, 1048576, 1048577, 67108864, 67108865, 68157440,
                  68157441, 256, 257, 1048832, 1048833, 67109120, 67109121,
                  68157696, 68157697,
                ],
                n = [
                  0, 8, 2048, 2056, 16777216, 16777224, 16779264, 16779272, 0,
                  8, 2048, 2056, 16777216, 16777224, 16779264, 16779272,
                ],
                s = [
                  0, 2097152, 134217728, 136314880, 8192, 2105344, 134225920,
                  136323072, 131072, 2228224, 134348800, 136445952, 139264,
                  2236416, 134356992, 136454144,
                ],
                a = [
                  0, 262144, 16, 262160, 0, 262144, 16, 262160, 4096, 266240,
                  4112, 266256, 4096, 266240, 4112, 266256,
                ],
                o = [
                  0, 1024, 32, 1056, 0, 1024, 32, 1056, 33554432, 33555456,
                  33554464, 33555488, 33554432, 33555456, 33554464, 33555488,
                ],
                c = [
                  0, 268435456, 524288, 268959744, 2, 268435458, 524290,
                  268959746, 0, 268435456, 524288, 268959744, 2, 268435458,
                  524290, 268959746,
                ],
                l = [
                  0, 65536, 2048, 67584, 536870912, 536936448, 536872960,
                  536938496, 131072, 196608, 133120, 198656, 537001984,
                  537067520, 537004032, 537069568,
                ],
                u = [
                  0, 262144, 0, 262144, 2, 262146, 2, 262146, 33554432,
                  33816576, 33554432, 33816576, 33554434, 33816578, 33554434,
                  33816578,
                ],
                p = [
                  0, 268435456, 8, 268435464, 0, 268435456, 8, 268435464, 1024,
                  268436480, 1032, 268436488, 1024, 268436480, 1032, 268436488,
                ],
                h = [
                  0, 32, 0, 32, 1048576, 1048608, 1048576, 1048608, 8192, 8224,
                  8192, 8224, 1056768, 1056800, 1056768, 1056800,
                ],
                f = [
                  0, 16777216, 512, 16777728, 2097152, 18874368, 2097664,
                  18874880, 67108864, 83886080, 67109376, 83886592, 69206016,
                  85983232, 69206528, 85983744,
                ],
                g = [
                  0, 4096, 134217728, 134221824, 524288, 528384, 134742016,
                  134746112, 16, 4112, 134217744, 134221840, 524304, 528400,
                  134742032, 134746128,
                ],
                m = [
                  0, 4, 256, 260, 0, 4, 256, 260, 1, 5, 257, 261, 1, 5, 257,
                  261,
                ],
                d = e.length() > 8 ? 3 : 1,
                y = [],
                k = [0, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0],
                v = 0,
                E = 0;
              E < d;
              E++
            ) {
              var S = e.getInt32(),
                _ = e.getInt32();
              (t = 252645135 & ((S >>> 4) ^ _)),
                (_ ^= t),
                (S ^= t << 4),
                (t = 65535 & ((_ >>> -16) ^ S)),
                (S ^= t),
                (_ ^= t << -16),
                (t = 858993459 & ((S >>> 2) ^ _)),
                (_ ^= t),
                (S ^= t << 2),
                (t = 65535 & ((_ >>> -16) ^ S)),
                (S ^= t),
                (_ ^= t << -16),
                (t = 1431655765 & ((S >>> 1) ^ _)),
                (_ ^= t),
                (S ^= t << 1),
                (t = 16711935 & ((_ >>> 8) ^ S)),
                (S ^= t),
                (_ ^= t << 8),
                (t = 1431655765 & ((S >>> 1) ^ _)),
                (_ ^= t),
                (S ^= t << 1),
                (t = (S << 8) | ((_ >>> 20) & 240)),
                (S =
                  (_ << 24) |
                  ((_ << 8) & 16711680) |
                  ((_ >>> 8) & 65280) |
                  ((_ >>> 24) & 240)),
                (_ = t);
              for (var b = 0; b < k.length; ++b) {
                k[b]
                  ? ((S = (S << 2) | (S >>> 26)), (_ = (_ << 2) | (_ >>> 26)))
                  : ((S = (S << 1) | (S >>> 27)), (_ = (_ << 1) | (_ >>> 27))),
                  (S &= -15),
                  (_ &= -15);
                var I =
                    r[S >>> 28] |
                    i[(S >>> 24) & 15] |
                    n[(S >>> 20) & 15] |
                    s[(S >>> 16) & 15] |
                    a[(S >>> 12) & 15] |
                    o[(S >>> 8) & 15] |
                    c[(S >>> 4) & 15],
                  A =
                    l[_ >>> 28] |
                    u[(_ >>> 24) & 15] |
                    p[(_ >>> 20) & 15] |
                    h[(_ >>> 16) & 15] |
                    f[(_ >>> 12) & 15] |
                    g[(_ >>> 8) & 15] |
                    m[(_ >>> 4) & 15];
                (t = 65535 & ((A >>> 16) ^ I)),
                  (y[v++] = I ^ t),
                  (y[v++] = A ^ (t << 16));
              }
            }
            return y;
          }
          function i(e, t, r, i) {
            var n,
              f = 32 === e.length ? 3 : 9;
            n =
              3 === f
                ? i
                  ? [30, -2, -2]
                  : [0, 32, 2]
                : i
                ? [94, 62, -2, 32, 64, 2, 30, -2, -2]
                : [0, 32, 2, 62, 30, -2, 64, 96, 2];
            var g,
              m = t[0],
              d = t[1];
            (g = 252645135 & ((m >>> 4) ^ d)),
              (d ^= g),
              (m ^= g << 4),
              (g = 65535 & ((m >>> 16) ^ d)),
              (d ^= g),
              (m ^= g << 16),
              (g = 858993459 & ((d >>> 2) ^ m)),
              (m ^= g),
              (d ^= g << 2),
              (g = 16711935 & ((d >>> 8) ^ m)),
              (m ^= g),
              (d ^= g << 8),
              (g = 1431655765 & ((m >>> 1) ^ d)),
              (d ^= g),
              (m ^= g << 1),
              (m = (m << 1) | (m >>> 31)),
              (d = (d << 1) | (d >>> 31));
            for (var y = 0; y < f; y += 3) {
              for (var k = n[y + 1], v = n[y + 2], E = n[y]; E != k; E += v) {
                var S = d ^ e[E],
                  _ = ((d >>> 4) | (d << 28)) ^ e[E + 1];
                (g = m),
                  (m = d),
                  (d =
                    g ^
                    (a[(S >>> 24) & 63] |
                      c[(S >>> 16) & 63] |
                      u[(S >>> 8) & 63] |
                      h[63 & S] |
                      s[(_ >>> 24) & 63] |
                      o[(_ >>> 16) & 63] |
                      l[(_ >>> 8) & 63] |
                      p[63 & _]));
              }
              (g = m), (m = d), (d = g);
            }
            (m = (m >>> 1) | (m << 31)),
              (d = (d >>> 1) | (d << 31)),
              (g = 1431655765 & ((m >>> 1) ^ d)),
              (d ^= g),
              (m ^= g << 1),
              (g = 16711935 & ((d >>> 8) ^ m)),
              (m ^= g),
              (d ^= g << 8),
              (g = 858993459 & ((d >>> 2) ^ m)),
              (m ^= g),
              (d ^= g << 2),
              (g = 65535 & ((m >>> 16) ^ d)),
              (d ^= g),
              (m ^= g << 16),
              (g = 252645135 & ((m >>> 4) ^ d)),
              (d ^= g),
              (m ^= g << 4),
              (r[0] = m),
              (r[1] = d);
          }
          function n(t) {
            t = t || {};
            var r,
              i = (t.mode || "CBC").toUpperCase(),
              n = "DES-" + i;
            r = t.decrypt
              ? e.cipher.createDecipher(n, t.key)
              : e.cipher.createCipher(n, t.key);
            var s = r.start;
            return (
              (r.start = function (t, i) {
                var n = null;
                i instanceof e.util.ByteBuffer && ((n = i), (i = {})),
                  (i = i || {}),
                  (i.output = n),
                  (i.iv = t),
                  s.call(r, i);
              }),
              r
            );
          }
          (e.des = e.des || {}),
            (e.des.startEncrypting = function (e, t, r, i) {
              var s = n({
                key: e,
                output: r,
                decrypt: !1,
                mode: i || (null === t ? "ECB" : "CBC"),
              });
              return s.start(t), s;
            }),
            (e.des.createEncryptionCipher = function (e, t) {
              return n({ key: e, output: null, decrypt: !1, mode: t });
            }),
            (e.des.startDecrypting = function (e, t, r, i) {
              var s = n({
                key: e,
                output: r,
                decrypt: !0,
                mode: i || (null === t ? "ECB" : "CBC"),
              });
              return s.start(t), s;
            }),
            (e.des.createDecryptionCipher = function (e, t) {
              return n({ key: e, output: null, decrypt: !0, mode: t });
            }),
            (e.des.Algorithm = function (e, t) {
              var r = this;
              (r.name = e),
                (r.mode = new t({
                  blockSize: 8,
                  cipher: {
                    encrypt: function (e, t) {
                      return i(r._keys, e, t, !1);
                    },
                    decrypt: function (e, t) {
                      return i(r._keys, e, t, !0);
                    },
                  },
                })),
                (r._init = !1);
            }),
            (e.des.Algorithm.prototype.initialize = function (t) {
              if (!this._init) {
                var i = e.util.createBuffer(t.key);
                if (0 === this.name.indexOf("3DES") && 24 !== i.length())
                  throw new Error(
                    "Invalid Triple-DES key size: " + 8 * i.length()
                  );
                (this._keys = r(i)), (this._init = !0);
              }
            }),
            t("DES-ECB", e.cipher.modes.ecb),
            t("DES-CBC", e.cipher.modes.cbc),
            t("DES-CFB", e.cipher.modes.cfb),
            t("DES-OFB", e.cipher.modes.ofb),
            t("DES-CTR", e.cipher.modes.ctr),
            t("3DES-ECB", e.cipher.modes.ecb),
            t("3DES-CBC", e.cipher.modes.cbc),
            t("3DES-CFB", e.cipher.modes.cfb),
            t("3DES-OFB", e.cipher.modes.ofb),
            t("3DES-CTR", e.cipher.modes.ctr);
          var s = [
              16843776, 0, 65536, 16843780, 16842756, 66564, 4, 65536, 1024,
              16843776, 16843780, 1024, 16778244, 16842756, 16777216, 4, 1028,
              16778240, 16778240, 66560, 66560, 16842752, 16842752, 16778244,
              65540, 16777220, 16777220, 65540, 0, 1028, 66564, 16777216, 65536,
              16843780, 4, 16842752, 16843776, 16777216, 16777216, 1024,
              16842756, 65536, 66560, 16777220, 1024, 4, 16778244, 66564,
              16843780, 65540, 16842752, 16778244, 16777220, 1028, 66564,
              16843776, 1028, 16778240, 16778240, 0, 65540, 66560, 0, 16842756,
            ],
            a = [
              -2146402272, -2147450880, 32768, 1081376, 1048576, 32,
              -2146435040, -2147450848, -2147483616, -2146402272, -2146402304,
              -2147483648, -2147450880, 1048576, 32, -2146435040, 1081344,
              1048608, -2147450848, 0, -2147483648, 32768, 1081376, -2146435072,
              1048608, -2147483616, 0, 1081344, 32800, -2146402304, -2146435072,
              32800, 0, 1081376, -2146435040, 1048576, -2147450848, -2146435072,
              -2146402304, 32768, -2146435072, -2147450880, 32, -2146402272,
              1081376, 32, 32768, -2147483648, 32800, -2146402304, 1048576,
              -2147483616, 1048608, -2147450848, -2147483616, 1048608, 1081344,
              0, -2147450880, 32800, -2147483648, -2146435040, -2146402272,
              1081344,
            ],
            o = [
              520, 134349312, 0, 134348808, 134218240, 0, 131592, 134218240,
              131080, 134217736, 134217736, 131072, 134349320, 131080,
              134348800, 520, 134217728, 8, 134349312, 512, 131584, 134348800,
              134348808, 131592, 134218248, 131584, 131072, 134218248, 8,
              134349320, 512, 134217728, 134349312, 134217728, 131080, 520,
              131072, 134349312, 134218240, 0, 512, 131080, 134349320,
              134218240, 134217736, 512, 0, 134348808, 134218248, 131072,
              134217728, 134349320, 8, 131592, 131584, 134217736, 134348800,
              134218248, 520, 134348800, 131592, 8, 134348808, 131584,
            ],
            c = [
              8396801, 8321, 8321, 128, 8396928, 8388737, 8388609, 8193, 0,
              8396800, 8396800, 8396929, 129, 0, 8388736, 8388609, 1, 8192,
              8388608, 8396801, 128, 8388608, 8193, 8320, 8388737, 1, 8320,
              8388736, 8192, 8396928, 8396929, 129, 8388736, 8388609, 8396800,
              8396929, 129, 0, 0, 8396800, 8320, 8388736, 8388737, 1, 8396801,
              8321, 8321, 128, 8396929, 129, 1, 8192, 8388609, 8193, 8396928,
              8388737, 8193, 8320, 8388608, 8396801, 128, 8388608, 8192,
              8396928,
            ],
            l = [
              256, 34078976, 34078720, 1107296512, 524288, 256, 1073741824,
              34078720, 1074266368, 524288, 33554688, 1074266368, 1107296512,
              1107820544, 524544, 1073741824, 33554432, 1074266112, 1074266112,
              0, 1073742080, 1107820800, 1107820800, 33554688, 1107820544,
              1073742080, 0, 1107296256, 34078976, 33554432, 1107296256, 524544,
              524288, 1107296512, 256, 33554432, 1073741824, 34078720,
              1107296512, 1074266368, 33554688, 1073741824, 1107820544,
              34078976, 1074266368, 256, 33554432, 1107820544, 1107820800,
              524544, 1107296256, 1107820800, 34078720, 0, 1074266112,
              1107296256, 524544, 33554688, 1073742080, 524288, 0, 1074266112,
              34078976, 1073742080,
            ],
            u = [
              536870928, 541065216, 16384, 541081616, 541065216, 16, 541081616,
              4194304, 536887296, 4210704, 4194304, 536870928, 4194320,
              536887296, 536870912, 16400, 0, 4194320, 536887312, 16384,
              4210688, 536887312, 16, 541065232, 541065232, 0, 4210704,
              541081600, 16400, 4210688, 541081600, 536870912, 536887296, 16,
              541065232, 4210688, 541081616, 4194304, 16400, 536870928, 4194304,
              536887296, 536870912, 16400, 536870928, 541081616, 4210688,
              541065216, 4210704, 541081600, 0, 541065232, 16, 16384, 541065216,
              4210704, 16384, 4194320, 536887312, 0, 541081600, 536870912,
              4194320, 536887312,
            ],
            p = [
              2097152, 69206018, 67110914, 0, 2048, 67110914, 2099202, 69208064,
              69208066, 2097152, 0, 67108866, 2, 67108864, 69206018, 2050,
              67110912, 2099202, 2097154, 67110912, 67108866, 69206016,
              69208064, 2097154, 69206016, 2048, 2050, 69208066, 2099200, 2,
              67108864, 2099200, 67108864, 2099200, 2097152, 67110914, 67110914,
              69206018, 69206018, 2, 2097154, 67108864, 67110912, 2097152,
              69208064, 2050, 2099202, 69208064, 2050, 67108866, 69208066,
              69206016, 2099200, 0, 2, 69208066, 0, 2099202, 69206016, 2048,
              67108866, 67110912, 2048, 2097154,
            ],
            h = [
              268439616, 4096, 262144, 268701760, 268435456, 268439616, 64,
              268435456, 262208, 268697600, 268701760, 266240, 268701696,
              266304, 4096, 64, 268697600, 268435520, 268439552, 4160, 266240,
              262208, 268697664, 268701696, 4160, 0, 0, 268697664, 268435520,
              268439552, 266304, 262144, 266304, 262144, 268701696, 4096, 64,
              268697664, 4096, 266304, 268439552, 64, 268435520, 268697600,
              268697664, 268435456, 262144, 268439616, 0, 268701760, 262208,
              268435520, 268697600, 268439552, 268439616, 0, 268701760, 266240,
              266240, 4160, 4160, 262208, 268435456, 268701696,
            ];
        }
        var i = "des";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/des",
          ["require", "module", "./cipher", "./cipherModes", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var r,
            i = (e.pkcs5 = e.pkcs5 || {}),
            n =
              "undefined" != typeof process &&
              process.versions &&
              process.versions.node;
          n && !e.disableNativeCode && (r = t("crypto")),
            (e.pbkdf2 = i.pbkdf2 =
              function (t, i, s, a, o, c) {
                function l() {
                  return E > f
                    ? c(null, v)
                    : (m.start(null, null),
                      m.update(i),
                      m.update(e.util.int32ToBytes(E)),
                      (d = k = m.digest().getBytes()),
                      (S = 2),
                      u(),
                      void 0);
                }
                function u() {
                  return S <= s
                    ? (m.start(null, null),
                      m.update(k),
                      (y = m.digest().getBytes()),
                      (d = e.util.xorBytes(d, y, p)),
                      (k = y),
                      ++S,
                      e.util.setImmediate(u))
                    : ((v += E < f ? d : d.substr(0, g)), ++E, l(), void 0);
                }
                if (
                  ("function" == typeof o && ((c = o), (o = null)),
                  n &&
                    !e.disableNativeCode &&
                    r.pbkdf2 &&
                    (null === o || "object" != typeof o) &&
                    (r.pbkdf2Sync.length > 4 || !o || "sha1" === o))
                )
                  return (
                    "string" != typeof o && (o = "sha1"),
                    (i = new Buffer(i, "binary")),
                    c
                      ? 4 === r.pbkdf2Sync.length
                        ? r.pbkdf2(t, i, s, a, function (e, t) {
                            return e
                              ? c(e)
                              : void c(null, t.toString("binary"));
                          })
                        : r.pbkdf2(t, i, s, a, o, function (e, t) {
                            return e
                              ? c(e)
                              : void c(null, t.toString("binary"));
                          })
                      : 4 === r.pbkdf2Sync.length
                      ? r.pbkdf2Sync(t, i, s, a).toString("binary")
                      : r.pbkdf2Sync(t, i, s, a, o).toString("binary")
                  );
                if (
                  (("undefined" != typeof o && null !== o) ||
                    (o = e.md.sha1.create()),
                  "string" == typeof o)
                ) {
                  if (!(o in e.md.algorithms))
                    throw new Error("Unknown hash algorithm: " + o);
                  o = e.md[o].create();
                }
                var p = o.digestLength;
                if (a > 4294967295 * p) {
                  var h = new Error("Derived key is too long.");
                  if (c) return c(h);
                  throw h;
                }
                var f = Math.ceil(a / p),
                  g = a - (f - 1) * p,
                  m = e.hmac.create();
                m.start(o, t);
                var d,
                  y,
                  k,
                  v = "";
                if (!c) {
                  for (var E = 1; E <= f; ++E) {
                    m.start(null, null),
                      m.update(i),
                      m.update(e.util.int32ToBytes(E)),
                      (d = k = m.digest().getBytes());
                    for (var S = 2; S <= s; ++S)
                      m.start(null, null),
                        m.update(k),
                        (y = m.digest().getBytes()),
                        (d = e.util.xorBytes(d, y, p)),
                        (k = y);
                    v += E < f ? d : d.substr(0, g);
                  }
                  return v;
                }
                var S,
                  E = 1;
                l();
              });
        }
        var i = "pbkdf2";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pbkdf2",
          ["require", "module", "./hmac", "./md", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var r =
              "undefined" != typeof process &&
              process.versions &&
              process.versions.node,
            i = null;
          !e.disableNativeCode &&
            r &&
            !process.versions["node-webkit"] &&
            (i = t("crypto"));
          var n = (e.prng = e.prng || {});
          n.create = function (t) {
            function r(e) {
              if (o.pools[0].messageLength >= 32) return s(), e();
              var t = (32 - o.pools[0].messageLength) << 5;
              o.seedFile(t, function (t, r) {
                return t ? e(t) : (o.collect(r), s(), e(), void 0);
              });
            }
            function n() {
              if (o.pools[0].messageLength >= 32) return s();
              var e = (32 - o.pools[0].messageLength) << 5;
              o.collect(o.seedFileSync(e)), s();
            }
            function s() {
              var e = o.plugin.md.create();
              e.update(o.pools[0].digest().getBytes()), o.pools[0].start();
              for (var t = 1, r = 1; r < 32; ++r)
                (t = 31 === t ? 2147483648 : t << 2),
                  t % o.reseeds === 0 &&
                    (e.update(o.pools[r].digest().getBytes()),
                    o.pools[r].start());
              var i = e.digest().getBytes();
              e.start(), e.update(i);
              var n = e.digest().getBytes();
              (o.key = o.plugin.formatKey(i)),
                (o.seed = o.plugin.formatSeed(n)),
                (o.reseeds = 4294967295 === o.reseeds ? 0 : o.reseeds + 1),
                (o.generated = 0);
            }
            function a(t) {
              var r = null;
              if ("undefined" != typeof window) {
                var i = window.crypto || window.msCrypto;
                i &&
                  i.getRandomValues &&
                  (r = function (e) {
                    return i.getRandomValues(e);
                  });
              }
              var n = e.util.createBuffer();
              if (r)
                for (; n.length() < t; ) {
                  var s = Math.max(1, Math.min(t - n.length(), 65536) / 4),
                    a = new Uint32Array(Math.floor(s));
                  try {
                    r(a);
                    for (var o = 0; o < a.length; ++o) n.putInt32(a[o]);
                  } catch (c) {
                    if (
                      !(
                        "undefined" != typeof QuotaExceededError &&
                        c instanceof QuotaExceededError
                      )
                    )
                      throw c;
                  }
                }
              if (n.length() < t)
                for (
                  var l, u, p, h = Math.floor(65536 * Math.random());
                  n.length() < t;

                ) {
                  (u = 16807 * (65535 & h)),
                    (l = 16807 * (h >> 16)),
                    (u += (32767 & l) << 16),
                    (u += l >> 15),
                    (u = (2147483647 & u) + (u >> 31)),
                    (h = 4294967295 & u);
                  for (var o = 0; o < 3; ++o)
                    (p = h >>> (o << 3)),
                      (p ^= Math.floor(256 * Math.random())),
                      n.putByte(String.fromCharCode(255 & p));
                }
              return n.getBytes(t);
            }
            for (
              var o = {
                  plugin: t,
                  key: null,
                  seed: null,
                  time: null,
                  reseeds: 0,
                  generated: 0,
                },
                c = t.md,
                l = new Array(32),
                u = 0;
              u < 32;
              ++u
            )
              l[u] = c.create();
            return (
              (o.pools = l),
              (o.pool = 0),
              (o.generate = function (t, i) {
                function n(p) {
                  if (p) return i(p);
                  if (u.length() >= t) return i(null, u.getBytes(t));
                  if ((o.generated > 1048575 && (o.key = null), null === o.key))
                    return e.util.nextTick(function () {
                      r(n);
                    });
                  var h = s(o.key, o.seed);
                  (o.generated += h.length),
                    u.putBytes(h),
                    (o.key = c(s(o.key, a(o.seed)))),
                    (o.seed = l(s(o.key, o.seed))),
                    e.util.setImmediate(n);
                }
                if (!i) return o.generateSync(t);
                var s = o.plugin.cipher,
                  a = o.plugin.increment,
                  c = o.plugin.formatKey,
                  l = o.plugin.formatSeed,
                  u = e.util.createBuffer();
                (o.key = null), n();
              }),
              (o.generateSync = function (t) {
                var r = o.plugin.cipher,
                  i = o.plugin.increment,
                  s = o.plugin.formatKey,
                  a = o.plugin.formatSeed;
                o.key = null;
                for (var c = e.util.createBuffer(); c.length() < t; ) {
                  o.generated > 1048575 && (o.key = null),
                    null === o.key && n();
                  var l = r(o.key, o.seed);
                  (o.generated += l.length),
                    c.putBytes(l),
                    (o.key = s(r(o.key, i(o.seed)))),
                    (o.seed = a(r(o.key, o.seed)));
                }
                return c.getBytes(t);
              }),
              i
                ? ((o.seedFile = function (e, t) {
                    i.randomBytes(e, function (e, r) {
                      return e ? t(e) : void t(null, r.toString());
                    });
                  }),
                  (o.seedFileSync = function (e) {
                    return i.randomBytes(e).toString();
                  }))
                : ((o.seedFile = function (e, t) {
                    try {
                      t(null, a(e));
                    } catch (r) {
                      t(r);
                    }
                  }),
                  (o.seedFileSync = a)),
              (o.collect = function (e) {
                for (var t = e.length, r = 0; r < t; ++r)
                  o.pools[o.pool].update(e.substr(r, 1)),
                    (o.pool = 31 === o.pool ? 0 : o.pool + 1);
              }),
              (o.collectInt = function (e, t) {
                for (var r = "", i = 0; i < t; i += 8)
                  r += String.fromCharCode((e >> i) & 255);
                o.collect(r);
              }),
              (o.registerWorker = function (e) {
                if (e === self)
                  o.seedFile = function (e, t) {
                    function r(e) {
                      var i = e.data;
                      i.forge &&
                        i.forge.prng &&
                        (self.removeEventListener("message", r),
                        t(i.forge.prng.err, i.forge.prng.bytes));
                    }
                    self.addEventListener("message", r),
                      self.postMessage({ forge: { prng: { needed: e } } });
                  };
                else {
                  var t = function (t) {
                    var r = t.data;
                    r.forge &&
                      r.forge.prng &&
                      o.seedFile(r.forge.prng.needed, function (t, r) {
                        e.postMessage({
                          forge: { prng: { err: t, bytes: r } },
                        });
                      });
                  };
                  e.addEventListener("message", t);
                }
              }),
              o
            );
          };
        }
        var i = "prng";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/prng", ["require", "module", "./md", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          (e.random && e.random.getBytes) ||
            !(function (t) {
              function r() {
                var t = e.prng.create(i);
                return (
                  (t.getBytes = function (e, r) {
                    return t.generate(e, r);
                  }),
                  (t.getBytesSync = function (e) {
                    return t.generate(e);
                  }),
                  t
                );
              }
              var i = {},
                n = new Array(4),
                s = e.util.createBuffer();
              (i.formatKey = function (t) {
                var r = e.util.createBuffer(t);
                return (
                  (t = new Array(4)),
                  (t[0] = r.getInt32()),
                  (t[1] = r.getInt32()),
                  (t[2] = r.getInt32()),
                  (t[3] = r.getInt32()),
                  e.aes._expandKey(t, !1)
                );
              }),
                (i.formatSeed = function (t) {
                  var r = e.util.createBuffer(t);
                  return (
                    (t = new Array(4)),
                    (t[0] = r.getInt32()),
                    (t[1] = r.getInt32()),
                    (t[2] = r.getInt32()),
                    (t[3] = r.getInt32()),
                    t
                  );
                }),
                (i.cipher = function (t, r) {
                  return (
                    e.aes._updateBlock(t, r, n, !1),
                    s.putInt32(n[0]),
                    s.putInt32(n[1]),
                    s.putInt32(n[2]),
                    s.putInt32(n[3]),
                    s.getBytes()
                  );
                }),
                (i.increment = function (e) {
                  return ++e[3], e;
                }),
                (i.md = e.md.sha256);
              var a = r(),
                o =
                  "undefined" != typeof process &&
                  process.versions &&
                  process.versions.node,
                c = null;
              if ("undefined" != typeof window) {
                var l = window.crypto || window.msCrypto;
                l &&
                  l.getRandomValues &&
                  (c = function (e) {
                    return l.getRandomValues(e);
                  });
              }
              if (e.disableNativeCode || (!o && !c)) {
                if (
                  ("undefined" == typeof window || void 0 === window.document,
                  a.collectInt(+new Date(), 32),
                  "undefined" != typeof navigator)
                ) {
                  var u = "";
                  for (var p in navigator)
                    try {
                      "string" == typeof navigator[p] && (u += navigator[p]);
                    } catch (h) {}
                  a.collect(u), (u = null);
                }
                t &&
                  (t().mousemove(function (e) {
                    a.collectInt(e.clientX, 16), a.collectInt(e.clientY, 16);
                  }),
                  t().keypress(function (e) {
                    a.collectInt(e.charCode, 8);
                  }));
              }
              if (e.random) for (var p in a) e.random[p] = a[p];
              else e.random = a;
              e.random.createInstance = r;
            })("undefined" != typeof jQuery ? jQuery : null);
        }
        var i = "random";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/random",
          ["require", "module", "./aes", "./md", "./prng", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = [
              217, 120, 249, 196, 25, 221, 181, 237, 40, 233, 253, 121, 74, 160,
              216, 157, 198, 126, 55, 131, 43, 118, 83, 142, 98, 76, 100, 136,
              68, 139, 251, 162, 23, 154, 89, 245, 135, 179, 79, 19, 97, 69,
              109, 141, 9, 129, 125, 50, 189, 143, 64, 235, 134, 183, 123, 11,
              240, 149, 33, 34, 92, 107, 78, 130, 84, 214, 101, 147, 206, 96,
              178, 28, 115, 86, 192, 20, 167, 140, 241, 220, 18, 117, 202, 31,
              59, 190, 228, 209, 66, 61, 212, 48, 163, 60, 182, 38, 111, 191,
              14, 218, 70, 105, 7, 87, 39, 242, 29, 155, 188, 148, 67, 3, 248,
              17, 199, 246, 144, 239, 62, 231, 6, 195, 213, 47, 200, 102, 30,
              215, 8, 232, 234, 222, 128, 82, 238, 247, 132, 170, 114, 172, 53,
              77, 106, 42, 150, 26, 210, 113, 90, 21, 73, 116, 75, 159, 208, 94,
              4, 24, 164, 236, 194, 224, 65, 110, 15, 81, 203, 204, 36, 145,
              175, 80, 161, 244, 112, 57, 153, 124, 58, 133, 35, 184, 180, 122,
              252, 2, 54, 91, 37, 85, 151, 49, 45, 93, 250, 152, 227, 138, 146,
              174, 5, 223, 41, 16, 103, 108, 186, 201, 211, 0, 230, 207, 225,
              158, 168, 44, 99, 22, 1, 63, 88, 226, 137, 169, 13, 56, 52, 27,
              171, 51, 255, 176, 187, 72, 12, 95, 185, 177, 205, 46, 197, 243,
              219, 71, 229, 165, 156, 119, 10, 166, 32, 104, 254, 127, 193, 173,
            ],
            r = [1, 2, 3, 5],
            i = function (e, t) {
              return ((e << t) & 65535) | ((65535 & e) >> (16 - t));
            },
            n = function (e, t) {
              return ((65535 & e) >> t) | ((e << (16 - t)) & 65535);
            };
          (e.rc2 = e.rc2 || {}),
            (e.rc2.expandKey = function (r, i) {
              "string" == typeof r && (r = e.util.createBuffer(r)),
                (i = i || 128);
              var n,
                s = r,
                a = r.length(),
                o = i,
                c = Math.ceil(o / 8),
                l = 255 >> (7 & o);
              for (n = a; n < 128; n++)
                s.putByte(t[(s.at(n - 1) + s.at(n - a)) & 255]);
              for (
                s.setAt(128 - c, t[s.at(128 - c) & l]), n = 127 - c;
                n >= 0;
                n--
              )
                s.setAt(n, t[s.at(n + 1) ^ s.at(n + c)]);
              return s;
            });
          var s = function (t, s, a) {
            var o,
              c,
              l,
              u,
              p = !1,
              h = null,
              f = null,
              g = null,
              m = [];
            for (t = e.rc2.expandKey(t, s), l = 0; l < 64; l++)
              m.push(t.getInt16Le());
            a
              ? ((o = function (e) {
                  for (l = 0; l < 4; l++)
                    (e[l] +=
                      m[u] +
                      (e[(l + 3) % 4] & e[(l + 2) % 4]) +
                      (~e[(l + 3) % 4] & e[(l + 1) % 4])),
                      (e[l] = i(e[l], r[l])),
                      u++;
                }),
                (c = function (e) {
                  for (l = 0; l < 4; l++) e[l] += m[63 & e[(l + 3) % 4]];
                }))
              : ((o = function (e) {
                  for (l = 3; l >= 0; l--)
                    (e[l] = n(e[l], r[l])),
                      (e[l] -=
                        m[u] +
                        (e[(l + 3) % 4] & e[(l + 2) % 4]) +
                        (~e[(l + 3) % 4] & e[(l + 1) % 4])),
                      u--;
                }),
                (c = function (e) {
                  for (l = 3; l >= 0; l--) e[l] -= m[63 & e[(l + 3) % 4]];
                }));
            var d = function (e) {
                var t = [];
                for (l = 0; l < 4; l++) {
                  var r = h.getInt16Le();
                  null !== g && (a ? (r ^= g.getInt16Le()) : g.putInt16Le(r)),
                    t.push(65535 & r);
                }
                u = a ? 0 : 63;
                for (var i = 0; i < e.length; i++)
                  for (var n = 0; n < e[i][0]; n++) e[i][1](t);
                for (l = 0; l < 4; l++)
                  null !== g &&
                    (a ? g.putInt16Le(t[l]) : (t[l] ^= g.getInt16Le())),
                    f.putInt16Le(t[l]);
              },
              y = null;
            return (y = {
              start: function (t, r) {
                t && "string" == typeof t && (t = e.util.createBuffer(t)),
                  (p = !1),
                  (h = e.util.createBuffer()),
                  (f = r || new e.util.createBuffer()),
                  (g = t),
                  (y.output = f);
              },
              update: function (e) {
                for (p || h.putBuffer(e); h.length() >= 8; )
                  d([
                    [5, o],
                    [1, c],
                    [6, o],
                    [1, c],
                    [5, o],
                  ]);
              },
              finish: function (e) {
                var t = !0;
                if (a)
                  if (e) t = e(8, h, !a);
                  else {
                    var r = 8 === h.length() ? 8 : 8 - h.length();
                    h.fillWithByte(r, r);
                  }
                if ((t && ((p = !0), y.update()), !a && (t = 0 === h.length())))
                  if (e) t = e(8, f, !a);
                  else {
                    var i = f.length(),
                      n = f.at(i - 1);
                    n > i ? (t = !1) : f.truncate(n);
                  }
                return t;
              },
            });
          };
          (e.rc2.startEncrypting = function (t, r, i) {
            var n = e.rc2.createEncryptionCipher(t, 128);
            return n.start(r, i), n;
          }),
            (e.rc2.createEncryptionCipher = function (e, t) {
              return s(e, t, !0);
            }),
            (e.rc2.startDecrypting = function (t, r, i) {
              var n = e.rc2.createDecryptionCipher(t, 128);
              return n.start(r, i), n;
            }),
            (e.rc2.createDecryptionCipher = function (e, t) {
              return s(e, t, !1);
            });
        }
        var i = "rc2";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/rc2", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(e, t, r) {
            (this.data = []),
              null != e &&
                ("number" == typeof e
                  ? this.fromNumber(e, t, r)
                  : null == t && "string" != typeof e
                  ? this.fromString(e, 256)
                  : this.fromString(e, t));
          }
          function r() {
            return new t(null);
          }
          function i(e, t, r, i, n, s) {
            for (; --s >= 0; ) {
              var a = t * this.data[e++] + r.data[i] + n;
              (n = Math.floor(a / 67108864)), (r.data[i++] = 67108863 & a);
            }
            return n;
          }
          function n(e, t, r, i, n, s) {
            for (var a = 32767 & t, o = t >> 15; --s >= 0; ) {
              var c = 32767 & this.data[e],
                l = this.data[e++] >> 15,
                u = o * c + l * a;
              (c = a * c + ((32767 & u) << 15) + r.data[i] + (1073741823 & n)),
                (n = (c >>> 30) + (u >>> 15) + o * l + (n >>> 30)),
                (r.data[i++] = 1073741823 & c);
            }
            return n;
          }
          function s(e, t, r, i, n, s) {
            for (var a = 16383 & t, o = t >> 14; --s >= 0; ) {
              var c = 16383 & this.data[e],
                l = this.data[e++] >> 14,
                u = o * c + l * a;
              (c = a * c + ((16383 & u) << 14) + r.data[i] + n),
                (n = (c >> 28) + (u >> 14) + o * l),
                (r.data[i++] = 268435455 & c);
            }
            return n;
          }
          function a(e) {
            return st.charAt(e);
          }
          function o(e, t) {
            var r = at[e.charCodeAt(t)];
            return null == r ? -1 : r;
          }
          function c(e) {
            for (var t = this.t - 1; t >= 0; --t) e.data[t] = this.data[t];
            (e.t = this.t), (e.s = this.s);
          }
          function l(e) {
            (this.t = 1),
              (this.s = e < 0 ? -1 : 0),
              e > 0
                ? (this.data[0] = e)
                : e < -1
                ? (this.data[0] = e + this.DV)
                : (this.t = 0);
          }
          function u(e) {
            var t = r();
            return t.fromInt(e), t;
          }
          function p(e, r) {
            var i;
            if (16 == r) i = 4;
            else if (8 == r) i = 3;
            else if (256 == r) i = 8;
            else if (2 == r) i = 1;
            else if (32 == r) i = 5;
            else {
              if (4 != r) return void this.fromRadix(e, r);
              i = 2;
            }
            (this.t = 0), (this.s = 0);
            for (var n = e.length, s = !1, a = 0; --n >= 0; ) {
              var c = 8 == i ? 255 & e[n] : o(e, n);
              c < 0
                ? "-" == e.charAt(n) && (s = !0)
                : ((s = !1),
                  0 == a
                    ? (this.data[this.t++] = c)
                    : a + i > this.DB
                    ? ((this.data[this.t - 1] |=
                        (c & ((1 << (this.DB - a)) - 1)) << a),
                      (this.data[this.t++] = c >> (this.DB - a)))
                    : (this.data[this.t - 1] |= c << a),
                  (a += i),
                  a >= this.DB && (a -= this.DB));
            }
            8 == i &&
              0 != (128 & e[0]) &&
              ((this.s = -1),
              a > 0 &&
                (this.data[this.t - 1] |= ((1 << (this.DB - a)) - 1) << a)),
              this.clamp(),
              s && t.ZERO.subTo(this, this);
          }
          function h() {
            for (
              var e = this.s & this.DM;
              this.t > 0 && this.data[this.t - 1] == e;

            )
              --this.t;
          }
          function f(e) {
            if (this.s < 0) return "-" + this.negate().toString(e);
            var t;
            if (16 == e) t = 4;
            else if (8 == e) t = 3;
            else if (2 == e) t = 1;
            else if (32 == e) t = 5;
            else {
              if (4 != e) return this.toRadix(e);
              t = 2;
            }
            var r,
              i = (1 << t) - 1,
              n = !1,
              s = "",
              o = this.t,
              c = this.DB - ((o * this.DB) % t);
            if (o-- > 0)
              for (
                c < this.DB &&
                (r = this.data[o] >> c) > 0 &&
                ((n = !0), (s = a(r)));
                o >= 0;

              )
                c < t
                  ? ((r = (this.data[o] & ((1 << c) - 1)) << (t - c)),
                    (r |= this.data[--o] >> (c += this.DB - t)))
                  : ((r = (this.data[o] >> (c -= t)) & i),
                    c <= 0 && ((c += this.DB), --o)),
                  r > 0 && (n = !0),
                  n && (s += a(r));
            return n ? s : "0";
          }
          function g() {
            var e = r();
            return t.ZERO.subTo(this, e), e;
          }
          function m() {
            return this.s < 0 ? this.negate() : this;
          }
          function d(e) {
            var t = this.s - e.s;
            if (0 != t) return t;
            var r = this.t;
            if (((t = r - e.t), 0 != t)) return this.s < 0 ? -t : t;
            for (; --r >= 0; )
              if (0 != (t = this.data[r] - e.data[r])) return t;
            return 0;
          }
          function y(e) {
            var t,
              r = 1;
            return (
              0 != (t = e >>> 16) && ((e = t), (r += 16)),
              0 != (t = e >> 8) && ((e = t), (r += 8)),
              0 != (t = e >> 4) && ((e = t), (r += 4)),
              0 != (t = e >> 2) && ((e = t), (r += 2)),
              0 != (t = e >> 1) && ((e = t), (r += 1)),
              r
            );
          }
          function k() {
            return this.t <= 0
              ? 0
              : this.DB * (this.t - 1) +
                  y(this.data[this.t - 1] ^ (this.s & this.DM));
          }
          function v(e, t) {
            var r;
            for (r = this.t - 1; r >= 0; --r) t.data[r + e] = this.data[r];
            for (r = e - 1; r >= 0; --r) t.data[r] = 0;
            (t.t = this.t + e), (t.s = this.s);
          }
          function E(e, t) {
            for (var r = e; r < this.t; ++r) t.data[r - e] = this.data[r];
            (t.t = Math.max(this.t - e, 0)), (t.s = this.s);
          }
          function S(e, t) {
            var r,
              i = e % this.DB,
              n = this.DB - i,
              s = (1 << n) - 1,
              a = Math.floor(e / this.DB),
              o = (this.s << i) & this.DM;
            for (r = this.t - 1; r >= 0; --r)
              (t.data[r + a + 1] = (this.data[r] >> n) | o),
                (o = (this.data[r] & s) << i);
            for (r = a - 1; r >= 0; --r) t.data[r] = 0;
            (t.data[a] = o), (t.t = this.t + a + 1), (t.s = this.s), t.clamp();
          }
          function _(e, t) {
            t.s = this.s;
            var r = Math.floor(e / this.DB);
            if (r >= this.t) return void (t.t = 0);
            var i = e % this.DB,
              n = this.DB - i,
              s = (1 << i) - 1;
            t.data[0] = this.data[r] >> i;
            for (var a = r + 1; a < this.t; ++a)
              (t.data[a - r - 1] |= (this.data[a] & s) << n),
                (t.data[a - r] = this.data[a] >> i);
            i > 0 && (t.data[this.t - r - 1] |= (this.s & s) << n),
              (t.t = this.t - r),
              t.clamp();
          }
          function b(e, t) {
            for (var r = 0, i = 0, n = Math.min(e.t, this.t); r < n; )
              (i += this.data[r] - e.data[r]),
                (t.data[r++] = i & this.DM),
                (i >>= this.DB);
            if (e.t < this.t) {
              for (i -= e.s; r < this.t; )
                (i += this.data[r]),
                  (t.data[r++] = i & this.DM),
                  (i >>= this.DB);
              i += this.s;
            } else {
              for (i += this.s; r < e.t; )
                (i -= e.data[r]), (t.data[r++] = i & this.DM), (i >>= this.DB);
              i -= e.s;
            }
            (t.s = i < 0 ? -1 : 0),
              i < -1 ? (t.data[r++] = this.DV + i) : i > 0 && (t.data[r++] = i),
              (t.t = r),
              t.clamp();
          }
          function I(e, r) {
            var i = this.abs(),
              n = e.abs(),
              s = i.t;
            for (r.t = s + n.t; --s >= 0; ) r.data[s] = 0;
            for (s = 0; s < n.t; ++s)
              r.data[s + i.t] = i.am(0, n.data[s], r, s, 0, i.t);
            (r.s = 0), r.clamp(), this.s != e.s && t.ZERO.subTo(r, r);
          }
          function A(e) {
            for (var t = this.abs(), r = (e.t = 2 * t.t); --r >= 0; )
              e.data[r] = 0;
            for (r = 0; r < t.t - 1; ++r) {
              var i = t.am(r, t.data[r], e, 2 * r, 0, 1);
              (e.data[r + t.t] += t.am(
                r + 1,
                2 * t.data[r],
                e,
                2 * r + 1,
                i,
                t.t - r - 1
              )) >= t.DV &&
                ((e.data[r + t.t] -= t.DV), (e.data[r + t.t + 1] = 1));
            }
            e.t > 0 && (e.data[e.t - 1] += t.am(r, t.data[r], e, 2 * r, 0, 1)),
              (e.s = 0),
              e.clamp();
          }
          function C(e, i, n) {
            var s = e.abs();
            if (!(s.t <= 0)) {
              var a = this.abs();
              if (a.t < s.t)
                return (
                  null != i && i.fromInt(0), void (null != n && this.copyTo(n))
                );
              null == n && (n = r());
              var o = r(),
                c = this.s,
                l = e.s,
                u = this.DB - y(s.data[s.t - 1]);
              u > 0
                ? (s.lShiftTo(u, o), a.lShiftTo(u, n))
                : (s.copyTo(o), a.copyTo(n));
              var p = o.t,
                h = o.data[p - 1];
              if (0 != h) {
                var f =
                    h * (1 << this.F1) + (p > 1 ? o.data[p - 2] >> this.F2 : 0),
                  g = this.FV / f,
                  m = (1 << this.F1) / f,
                  d = 1 << this.F2,
                  k = n.t,
                  v = k - p,
                  E = null == i ? r() : i;
                for (
                  o.dlShiftTo(v, E),
                    n.compareTo(E) >= 0 && ((n.data[n.t++] = 1), n.subTo(E, n)),
                    t.ONE.dlShiftTo(p, E),
                    E.subTo(o, o);
                  o.t < p;

                )
                  o.data[o.t++] = 0;
                for (; --v >= 0; ) {
                  var S =
                    n.data[--k] == h
                      ? this.DM
                      : Math.floor(n.data[k] * g + (n.data[k - 1] + d) * m);
                  if ((n.data[k] += o.am(0, S, n, v, 0, p)) < S)
                    for (o.dlShiftTo(v, E), n.subTo(E, n); n.data[k] < --S; )
                      n.subTo(E, n);
                }
                null != i && (n.drShiftTo(p, i), c != l && t.ZERO.subTo(i, i)),
                  (n.t = p),
                  n.clamp(),
                  u > 0 && n.rShiftTo(u, n),
                  c < 0 && t.ZERO.subTo(n, n);
              }
            }
          }
          function T(e) {
            var i = r();
            return (
              this.abs().divRemTo(e, null, i),
              this.s < 0 && i.compareTo(t.ZERO) > 0 && e.subTo(i, i),
              i
            );
          }
          function N(e) {
            this.m = e;
          }
          function j(e) {
            return e.s < 0 || e.compareTo(this.m) >= 0 ? e.mod(this.m) : e;
          }
          function w(e) {
            return e;
          }
          function R(e) {
            e.divRemTo(this.m, null, e);
          }
          function O(e, t, r) {
            e.multiplyTo(t, r), this.reduce(r);
          }
          function B(e, t) {
            e.squareTo(t), this.reduce(t);
          }
          function D() {
            if (this.t < 1) return 0;
            var e = this.data[0];
            if (0 == (1 & e)) return 0;
            var t = 3 & e;
            return (
              (t = (t * (2 - (15 & e) * t)) & 15),
              (t = (t * (2 - (255 & e) * t)) & 255),
              (t = (t * (2 - (((65535 & e) * t) & 65535))) & 65535),
              (t = (t * (2 - ((e * t) % this.DV))) % this.DV),
              t > 0 ? this.DV - t : -t
            );
          }
          function P(e) {
            (this.m = e),
              (this.mp = e.invDigit()),
              (this.mpl = 32767 & this.mp),
              (this.mph = this.mp >> 15),
              (this.um = (1 << (e.DB - 15)) - 1),
              (this.mt2 = 2 * e.t);
          }
          function U(e) {
            var i = r();
            return (
              e.abs().dlShiftTo(this.m.t, i),
              i.divRemTo(this.m, null, i),
              e.s < 0 && i.compareTo(t.ZERO) > 0 && this.m.subTo(i, i),
              i
            );
          }
          function L(e) {
            var t = r();
            return e.copyTo(t), this.reduce(t), t;
          }
          function x(e) {
            for (; e.t <= this.mt2; ) e.data[e.t++] = 0;
            for (var t = 0; t < this.m.t; ++t) {
              var r = 32767 & e.data[t],
                i =
                  (r * this.mpl +
                    (((r * this.mph + (e.data[t] >> 15) * this.mpl) &
                      this.um) <<
                      15)) &
                  e.DM;
              for (
                r = t + this.m.t,
                  e.data[r] += this.m.am(0, i, e, t, 0, this.m.t);
                e.data[r] >= e.DV;

              )
                (e.data[r] -= e.DV), e.data[++r]++;
            }
            e.clamp(),
              e.drShiftTo(this.m.t, e),
              e.compareTo(this.m) >= 0 && e.subTo(this.m, e);
          }
          function G(e, t) {
            e.squareTo(t), this.reduce(t);
          }
          function M(e, t, r) {
            e.multiplyTo(t, r), this.reduce(r);
          }
          function K() {
            return 0 == (this.t > 0 ? 1 & this.data[0] : this.s);
          }
          function V(e, i) {
            if (e > 4294967295 || e < 1) return t.ONE;
            var n = r(),
              s = r(),
              a = i.convert(this),
              o = y(e) - 1;
            for (a.copyTo(n); --o >= 0; )
              if ((i.sqrTo(n, s), (e & (1 << o)) > 0)) i.mulTo(s, a, n);
              else {
                var c = n;
                (n = s), (s = c);
              }
            return i.revert(n);
          }
          function F(e, t) {
            var r;
            return (
              (r = e < 256 || t.isEven() ? new N(t) : new P(t)), this.exp(e, r)
            );
          }
          function H() {
            var e = r();
            return this.copyTo(e), e;
          }
          function q() {
            if (this.s < 0) {
              if (1 == this.t) return this.data[0] - this.DV;
              if (0 == this.t) return -1;
            } else {
              if (1 == this.t) return this.data[0];
              if (0 == this.t) return 0;
            }
            return (
              ((this.data[1] & ((1 << (32 - this.DB)) - 1)) << this.DB) |
              this.data[0]
            );
          }
          function Q() {
            return 0 == this.t ? this.s : (this.data[0] << 24) >> 24;
          }
          function J() {
            return 0 == this.t ? this.s : (this.data[0] << 16) >> 16;
          }
          function z(e) {
            return Math.floor((Math.LN2 * this.DB) / Math.log(e));
          }
          function W() {
            return this.s < 0
              ? -1
              : this.t <= 0 || (1 == this.t && this.data[0] <= 0)
              ? 0
              : 1;
          }
          function Y(e) {
            if ((null == e && (e = 10), 0 == this.signum() || e < 2 || e > 36))
              return "0";
            var t = this.chunkSize(e),
              i = Math.pow(e, t),
              n = u(i),
              s = r(),
              a = r(),
              o = "";
            for (this.divRemTo(n, s, a); s.signum() > 0; )
              (o = (i + a.intValue()).toString(e).substr(1) + o),
                s.divRemTo(n, s, a);
            return a.intValue().toString(e) + o;
          }
          function X(e, r) {
            this.fromInt(0), null == r && (r = 10);
            for (
              var i = this.chunkSize(r),
                n = Math.pow(r, i),
                s = !1,
                a = 0,
                c = 0,
                l = 0;
              l < e.length;
              ++l
            ) {
              var u = o(e, l);
              u < 0
                ? "-" == e.charAt(l) && 0 == this.signum() && (s = !0)
                : ((c = r * c + u),
                  ++a >= i &&
                    (this.dMultiply(n),
                    this.dAddOffset(c, 0),
                    (a = 0),
                    (c = 0)));
            }
            a > 0 && (this.dMultiply(Math.pow(r, a)), this.dAddOffset(c, 0)),
              s && t.ZERO.subTo(this, this);
          }
          function Z(e, r, i) {
            if ("number" == typeof r)
              if (e < 2) this.fromInt(1);
              else
                for (
                  this.fromNumber(e, i),
                    this.testBit(e - 1) ||
                      this.bitwiseTo(t.ONE.shiftLeft(e - 1), ae, this),
                    this.isEven() && this.dAddOffset(1, 0);
                  !this.isProbablePrime(r);

                )
                  this.dAddOffset(2, 0),
                    this.bitLength() > e &&
                      this.subTo(t.ONE.shiftLeft(e - 1), this);
            else {
              var n = new Array(),
                s = 7 & e;
              (n.length = (e >> 3) + 1),
                r.nextBytes(n),
                s > 0 ? (n[0] &= (1 << s) - 1) : (n[0] = 0),
                this.fromString(n, 256);
            }
          }
          function $() {
            var e = this.t,
              t = new Array();
            t[0] = this.s;
            var r,
              i = this.DB - ((e * this.DB) % 8),
              n = 0;
            if (e-- > 0)
              for (
                i < this.DB &&
                (r = this.data[e] >> i) != (this.s & this.DM) >> i &&
                (t[n++] = r | (this.s << (this.DB - i)));
                e >= 0;

              )
                i < 8
                  ? ((r = (this.data[e] & ((1 << i) - 1)) << (8 - i)),
                    (r |= this.data[--e] >> (i += this.DB - 8)))
                  : ((r = (this.data[e] >> (i -= 8)) & 255),
                    i <= 0 && ((i += this.DB), --e)),
                  0 != (128 & r) && (r |= -256),
                  0 == n && (128 & this.s) != (128 & r) && ++n,
                  (n > 0 || r != this.s) && (t[n++] = r);
            return t;
          }
          function ee(e) {
            return 0 == this.compareTo(e);
          }
          function te(e) {
            return this.compareTo(e) < 0 ? this : e;
          }
          function re(e) {
            return this.compareTo(e) > 0 ? this : e;
          }
          function ie(e, t, r) {
            var i,
              n,
              s = Math.min(e.t, this.t);
            for (i = 0; i < s; ++i) r.data[i] = t(this.data[i], e.data[i]);
            if (e.t < this.t) {
              for (n = e.s & this.DM, i = s; i < this.t; ++i)
                r.data[i] = t(this.data[i], n);
              r.t = this.t;
            } else {
              for (n = this.s & this.DM, i = s; i < e.t; ++i)
                r.data[i] = t(n, e.data[i]);
              r.t = e.t;
            }
            (r.s = t(this.s, e.s)), r.clamp();
          }
          function ne(e, t) {
            return e & t;
          }
          function se(e) {
            var t = r();
            return this.bitwiseTo(e, ne, t), t;
          }
          function ae(e, t) {
            return e | t;
          }
          function oe(e) {
            var t = r();
            return this.bitwiseTo(e, ae, t), t;
          }
          function ce(e, t) {
            return e ^ t;
          }
          function le(e) {
            var t = r();
            return this.bitwiseTo(e, ce, t), t;
          }
          function ue(e, t) {
            return e & ~t;
          }
          function pe(e) {
            var t = r();
            return this.bitwiseTo(e, ue, t), t;
          }
          function he() {
            for (var e = r(), t = 0; t < this.t; ++t)
              e.data[t] = this.DM & ~this.data[t];
            return (e.t = this.t), (e.s = ~this.s), e;
          }
          function fe(e) {
            var t = r();
            return e < 0 ? this.rShiftTo(-e, t) : this.lShiftTo(e, t), t;
          }
          function ge(e) {
            var t = r();
            return e < 0 ? this.lShiftTo(-e, t) : this.rShiftTo(e, t), t;
          }
          function me(e) {
            if (0 == e) return -1;
            var t = 0;
            return (
              0 == (65535 & e) && ((e >>= 16), (t += 16)),
              0 == (255 & e) && ((e >>= 8), (t += 8)),
              0 == (15 & e) && ((e >>= 4), (t += 4)),
              0 == (3 & e) && ((e >>= 2), (t += 2)),
              0 == (1 & e) && ++t,
              t
            );
          }
          function de() {
            for (var e = 0; e < this.t; ++e)
              if (0 != this.data[e]) return e * this.DB + me(this.data[e]);
            return this.s < 0 ? this.t * this.DB : -1;
          }
          function ye(e) {
            for (var t = 0; 0 != e; ) (e &= e - 1), ++t;
            return t;
          }
          function ke() {
            for (var e = 0, t = this.s & this.DM, r = 0; r < this.t; ++r)
              e += ye(this.data[r] ^ t);
            return e;
          }
          function ve(e) {
            var t = Math.floor(e / this.DB);
            return t >= this.t
              ? 0 != this.s
              : 0 != (this.data[t] & (1 << e % this.DB));
          }
          function Ee(e, r) {
            var i = t.ONE.shiftLeft(e);
            return this.bitwiseTo(i, r, i), i;
          }
          function Se(e) {
            return this.changeBit(e, ae);
          }
          function _e(e) {
            return this.changeBit(e, ue);
          }
          function be(e) {
            return this.changeBit(e, ce);
          }
          function Ie(e, t) {
            for (var r = 0, i = 0, n = Math.min(e.t, this.t); r < n; )
              (i += this.data[r] + e.data[r]),
                (t.data[r++] = i & this.DM),
                (i >>= this.DB);
            if (e.t < this.t) {
              for (i += e.s; r < this.t; )
                (i += this.data[r]),
                  (t.data[r++] = i & this.DM),
                  (i >>= this.DB);
              i += this.s;
            } else {
              for (i += this.s; r < e.t; )
                (i += e.data[r]), (t.data[r++] = i & this.DM), (i >>= this.DB);
              i += e.s;
            }
            (t.s = i < 0 ? -1 : 0),
              i > 0 ? (t.data[r++] = i) : i < -1 && (t.data[r++] = this.DV + i),
              (t.t = r),
              t.clamp();
          }
          function Ae(e) {
            var t = r();
            return this.addTo(e, t), t;
          }
          function Ce(e) {
            var t = r();
            return this.subTo(e, t), t;
          }
          function Te(e) {
            var t = r();
            return this.multiplyTo(e, t), t;
          }
          function Ne(e) {
            var t = r();
            return this.divRemTo(e, t, null), t;
          }
          function je(e) {
            var t = r();
            return this.divRemTo(e, null, t), t;
          }
          function we(e) {
            var t = r(),
              i = r();
            return this.divRemTo(e, t, i), new Array(t, i);
          }
          function Re(e) {
            (this.data[this.t] = this.am(0, e - 1, this, 0, 0, this.t)),
              ++this.t,
              this.clamp();
          }
          function Oe(e, t) {
            if (0 != e) {
              for (; this.t <= t; ) this.data[this.t++] = 0;
              for (this.data[t] += e; this.data[t] >= this.DV; )
                (this.data[t] -= this.DV),
                  ++t >= this.t && (this.data[this.t++] = 0),
                  ++this.data[t];
            }
          }
          function Be() {}
          function De(e) {
            return e;
          }
          function Pe(e, t, r) {
            e.multiplyTo(t, r);
          }
          function Ue(e, t) {
            e.squareTo(t);
          }
          function Le(e) {
            return this.exp(e, new Be());
          }
          function xe(e, t, r) {
            var i = Math.min(this.t + e.t, t);
            for (r.s = 0, r.t = i; i > 0; ) r.data[--i] = 0;
            var n;
            for (n = r.t - this.t; i < n; ++i)
              r.data[i + this.t] = this.am(0, e.data[i], r, i, 0, this.t);
            for (n = Math.min(e.t, t); i < n; ++i)
              this.am(0, e.data[i], r, i, 0, t - i);
            r.clamp();
          }
          function Ge(e, t, r) {
            --t;
            var i = (r.t = this.t + e.t - t);
            for (r.s = 0; --i >= 0; ) r.data[i] = 0;
            for (i = Math.max(t - this.t, 0); i < e.t; ++i)
              r.data[this.t + i - t] = this.am(
                t - i,
                e.data[i],
                r,
                0,
                0,
                this.t + i - t
              );
            r.clamp(), r.drShiftTo(1, r);
          }
          function Me(e) {
            (this.r2 = r()),
              (this.q3 = r()),
              t.ONE.dlShiftTo(2 * e.t, this.r2),
              (this.mu = this.r2.divide(e)),
              (this.m = e);
          }
          function Ke(e) {
            if (e.s < 0 || e.t > 2 * this.m.t) return e.mod(this.m);
            if (e.compareTo(this.m) < 0) return e;
            var t = r();
            return e.copyTo(t), this.reduce(t), t;
          }
          function Ve(e) {
            return e;
          }
          function Fe(e) {
            for (
              e.drShiftTo(this.m.t - 1, this.r2),
                e.t > this.m.t + 1 && ((e.t = this.m.t + 1), e.clamp()),
                this.mu.multiplyUpperTo(this.r2, this.m.t + 1, this.q3),
                this.m.multiplyLowerTo(this.q3, this.m.t + 1, this.r2);
              e.compareTo(this.r2) < 0;

            )
              e.dAddOffset(1, this.m.t + 1);
            for (e.subTo(this.r2, e); e.compareTo(this.m) >= 0; )
              e.subTo(this.m, e);
          }
          function He(e, t) {
            e.squareTo(t), this.reduce(t);
          }
          function qe(e, t, r) {
            e.multiplyTo(t, r), this.reduce(r);
          }
          function Qe(e, t) {
            var i,
              n,
              s = e.bitLength(),
              a = u(1);
            if (s <= 0) return a;
            (i = s < 18 ? 1 : s < 48 ? 3 : s < 144 ? 4 : s < 768 ? 5 : 6),
              (n = s < 8 ? new N(t) : t.isEven() ? new Me(t) : new P(t));
            var o = new Array(),
              c = 3,
              l = i - 1,
              p = (1 << i) - 1;
            if (((o[1] = n.convert(this)), i > 1)) {
              var h = r();
              for (n.sqrTo(o[1], h); c <= p; )
                (o[c] = r()), n.mulTo(h, o[c - 2], o[c]), (c += 2);
            }
            var f,
              g,
              m = e.t - 1,
              d = !0,
              k = r();
            for (s = y(e.data[m]) - 1; m >= 0; ) {
              for (
                s >= l
                  ? (f = (e.data[m] >> (s - l)) & p)
                  : ((f = (e.data[m] & ((1 << (s + 1)) - 1)) << (l - s)),
                    m > 0 && (f |= e.data[m - 1] >> (this.DB + s - l))),
                  c = i;
                0 == (1 & f);

              )
                (f >>= 1), --c;
              if (((s -= c) < 0 && ((s += this.DB), --m), d))
                o[f].copyTo(a), (d = !1);
              else {
                for (; c > 1; ) n.sqrTo(a, k), n.sqrTo(k, a), (c -= 2);
                c > 0 ? n.sqrTo(a, k) : ((g = a), (a = k), (k = g)),
                  n.mulTo(k, o[f], a);
              }
              for (; m >= 0 && 0 == (e.data[m] & (1 << s)); )
                n.sqrTo(a, k),
                  (g = a),
                  (a = k),
                  (k = g),
                  --s < 0 && ((s = this.DB - 1), --m);
            }
            return n.revert(a);
          }
          function Je(e) {
            var t = this.s < 0 ? this.negate() : this.clone(),
              r = e.s < 0 ? e.negate() : e.clone();
            if (t.compareTo(r) < 0) {
              var i = t;
              (t = r), (r = i);
            }
            var n = t.getLowestSetBit(),
              s = r.getLowestSetBit();
            if (s < 0) return t;
            for (
              n < s && (s = n), s > 0 && (t.rShiftTo(s, t), r.rShiftTo(s, r));
              t.signum() > 0;

            )
              (n = t.getLowestSetBit()) > 0 && t.rShiftTo(n, t),
                (n = r.getLowestSetBit()) > 0 && r.rShiftTo(n, r),
                t.compareTo(r) >= 0
                  ? (t.subTo(r, t), t.rShiftTo(1, t))
                  : (r.subTo(t, r), r.rShiftTo(1, r));
            return s > 0 && r.lShiftTo(s, r), r;
          }
          function ze(e) {
            if (e <= 0) return 0;
            var t = this.DV % e,
              r = this.s < 0 ? e - 1 : 0;
            if (this.t > 0)
              if (0 == t) r = this.data[0] % e;
              else
                for (var i = this.t - 1; i >= 0; --i)
                  r = (t * r + this.data[i]) % e;
            return r;
          }
          function We(e) {
            var r = e.isEven();
            if ((this.isEven() && r) || 0 == e.signum()) return t.ZERO;
            for (
              var i = e.clone(),
                n = this.clone(),
                s = u(1),
                a = u(0),
                o = u(0),
                c = u(1);
              0 != i.signum();

            ) {
              for (; i.isEven(); )
                i.rShiftTo(1, i),
                  r
                    ? ((s.isEven() && a.isEven()) ||
                        (s.addTo(this, s), a.subTo(e, a)),
                      s.rShiftTo(1, s))
                    : a.isEven() || a.subTo(e, a),
                  a.rShiftTo(1, a);
              for (; n.isEven(); )
                n.rShiftTo(1, n),
                  r
                    ? ((o.isEven() && c.isEven()) ||
                        (o.addTo(this, o), c.subTo(e, c)),
                      o.rShiftTo(1, o))
                    : c.isEven() || c.subTo(e, c),
                  c.rShiftTo(1, c);
              i.compareTo(n) >= 0
                ? (i.subTo(n, i), r && s.subTo(o, s), a.subTo(c, a))
                : (n.subTo(i, n), r && o.subTo(s, o), c.subTo(a, c));
            }
            return 0 != n.compareTo(t.ONE)
              ? t.ZERO
              : c.compareTo(e) >= 0
              ? c.subtract(e)
              : c.signum() < 0
              ? (c.addTo(e, c), c.signum() < 0 ? c.add(e) : c)
              : c;
          }
          function Ye(e) {
            var t,
              r = this.abs();
            if (1 == r.t && r.data[0] <= ot[ot.length - 1]) {
              for (t = 0; t < ot.length; ++t) if (r.data[0] == ot[t]) return !0;
              return !1;
            }
            if (r.isEven()) return !1;
            for (t = 1; t < ot.length; ) {
              for (var i = ot[t], n = t + 1; n < ot.length && i < ct; )
                i *= ot[n++];
              for (i = r.modInt(i); t < n; ) if (i % ot[t++] == 0) return !1;
            }
            return r.millerRabin(e);
          }
          function Xe(e) {
            var r = this.subtract(t.ONE),
              i = r.getLowestSetBit();
            if (i <= 0) return !1;
            for (var n, s = r.shiftRight(i), a = Ze(), o = 0; o < e; ++o) {
              do n = new t(this.bitLength(), a);
              while (n.compareTo(t.ONE) <= 0 || n.compareTo(r) >= 0);
              var c = n.modPow(s, this);
              if (0 != c.compareTo(t.ONE) && 0 != c.compareTo(r)) {
                for (var l = 1; l++ < i && 0 != c.compareTo(r); )
                  if (((c = c.modPowInt(2, this)), 0 == c.compareTo(t.ONE)))
                    return !1;
                if (0 != c.compareTo(r)) return !1;
              }
            }
            return !0;
          }
          function Ze() {
            return {
              nextBytes: function (e) {
                for (var t = 0; t < e.length; ++t)
                  e[t] = Math.floor(256 * Math.random());
              },
            };
          }
          var $e,
            et = 0xdeadbeefcafe,
            tt = 15715070 == (16777215 & et);
          "undefined" == typeof navigator
            ? ((t.prototype.am = s), ($e = 28))
            : tt && "Microsoft Internet Explorer" == navigator.appName
            ? ((t.prototype.am = n), ($e = 30))
            : tt && "Netscape" != navigator.appName
            ? ((t.prototype.am = i), ($e = 26))
            : ((t.prototype.am = s), ($e = 28)),
            (t.prototype.DB = $e),
            (t.prototype.DM = (1 << $e) - 1),
            (t.prototype.DV = 1 << $e);
          var rt = 52;
          (t.prototype.FV = Math.pow(2, rt)),
            (t.prototype.F1 = rt - $e),
            (t.prototype.F2 = 2 * $e - rt);
          var it,
            nt,
            st = "0123456789abcdefghijklmnopqrstuvwxyz",
            at = new Array();
          for (it = "0".charCodeAt(0), nt = 0; nt <= 9; ++nt) at[it++] = nt;
          for (it = "a".charCodeAt(0), nt = 10; nt < 36; ++nt) at[it++] = nt;
          for (it = "A".charCodeAt(0), nt = 10; nt < 36; ++nt) at[it++] = nt;
          (N.prototype.convert = j),
            (N.prototype.revert = w),
            (N.prototype.reduce = R),
            (N.prototype.mulTo = O),
            (N.prototype.sqrTo = B),
            (P.prototype.convert = U),
            (P.prototype.revert = L),
            (P.prototype.reduce = x),
            (P.prototype.mulTo = M),
            (P.prototype.sqrTo = G),
            (t.prototype.copyTo = c),
            (t.prototype.fromInt = l),
            (t.prototype.fromString = p),
            (t.prototype.clamp = h),
            (t.prototype.dlShiftTo = v),
            (t.prototype.drShiftTo = E),
            (t.prototype.lShiftTo = S),
            (t.prototype.rShiftTo = _),
            (t.prototype.subTo = b),
            (t.prototype.multiplyTo = I),
            (t.prototype.squareTo = A),
            (t.prototype.divRemTo = C),
            (t.prototype.invDigit = D),
            (t.prototype.isEven = K),
            (t.prototype.exp = V),
            (t.prototype.toString = f),
            (t.prototype.negate = g),
            (t.prototype.abs = m),
            (t.prototype.compareTo = d),
            (t.prototype.bitLength = k),
            (t.prototype.mod = T),
            (t.prototype.modPowInt = F),
            (t.ZERO = u(0)),
            (t.ONE = u(1)),
            (Be.prototype.convert = De),
            (Be.prototype.revert = De),
            (Be.prototype.mulTo = Pe),
            (Be.prototype.sqrTo = Ue),
            (Me.prototype.convert = Ke),
            (Me.prototype.revert = Ve),
            (Me.prototype.reduce = Fe),
            (Me.prototype.mulTo = qe),
            (Me.prototype.sqrTo = He);
          var ot = [
              2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59,
              61, 67, 71, 73, 79, 83, 89, 97, 101, 103, 107, 109, 113, 127, 131,
              137, 139, 149, 151, 157, 163, 167, 173, 179, 181, 191, 193, 197,
              199, 211, 223, 227, 229, 233, 239, 241, 251, 257, 263, 269, 271,
              277, 281, 283, 293, 307, 311, 313, 317, 331, 337, 347, 349, 353,
              359, 367, 373, 379, 383, 389, 397, 401, 409, 419, 421, 431, 433,
              439, 443, 449, 457, 461, 463, 467, 479, 487, 491, 499, 503, 509,
            ],
            ct = (1 << 26) / ot[ot.length - 1];
          (t.prototype.chunkSize = z),
            (t.prototype.toRadix = Y),
            (t.prototype.fromRadix = X),
            (t.prototype.fromNumber = Z),
            (t.prototype.bitwiseTo = ie),
            (t.prototype.changeBit = Ee),
            (t.prototype.addTo = Ie),
            (t.prototype.dMultiply = Re),
            (t.prototype.dAddOffset = Oe),
            (t.prototype.multiplyLowerTo = xe),
            (t.prototype.multiplyUpperTo = Ge),
            (t.prototype.modInt = ze),
            (t.prototype.millerRabin = Xe),
            (t.prototype.clone = H),
            (t.prototype.intValue = q),
            (t.prototype.byteValue = Q),
            (t.prototype.shortValue = J),
            (t.prototype.signum = W),
            (t.prototype.toByteArray = $),
            (t.prototype.equals = ee),
            (t.prototype.min = te),
            (t.prototype.max = re),
            (t.prototype.and = se),
            (t.prototype.or = oe),
            (t.prototype.xor = le),
            (t.prototype.andNot = pe),
            (t.prototype.not = he),
            (t.prototype.shiftLeft = fe),
            (t.prototype.shiftRight = ge),
            (t.prototype.getLowestSetBit = de),
            (t.prototype.bitCount = ke),
            (t.prototype.testBit = ve),
            (t.prototype.setBit = Se),
            (t.prototype.clearBit = _e),
            (t.prototype.flipBit = be),
            (t.prototype.add = Ae),
            (t.prototype.subtract = Ce),
            (t.prototype.multiply = Te),
            (t.prototype.divide = Ne),
            (t.prototype.remainder = je),
            (t.prototype.divideAndRemainder = we),
            (t.prototype.modPow = Qe),
            (t.prototype.modInverse = We),
            (t.prototype.pow = Le),
            (t.prototype.gcd = Je),
            (t.prototype.isProbablePrime = Ye),
            (e.jsbn = e.jsbn || {}),
            (e.jsbn.BigInteger = t);
        }
        var i = "jsbn";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/jsbn", ["require", "module"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t, r, i) {
            i || (i = e.md.sha1.create());
            for (
              var n = "", s = Math.ceil(r / i.digestLength), a = 0;
              a < s;
              ++a
            ) {
              var o = String.fromCharCode(
                (a >> 24) & 255,
                (a >> 16) & 255,
                (a >> 8) & 255,
                255 & a
              );
              i.start(), i.update(t + o), (n += i.digest().getBytes());
            }
            return n.substring(0, r);
          }
          var r = (e.pkcs1 = e.pkcs1 || {});
          (r.encode_rsa_oaep = function (r, i, n) {
            var s, a, o, c;
            "string" == typeof n
              ? ((s = n),
                (a = arguments[3] || void 0),
                (o = arguments[4] || void 0))
              : n &&
                ((s = n.label || void 0),
                (a = n.seed || void 0),
                (o = n.md || void 0),
                n.mgf1 && n.mgf1.md && (c = n.mgf1.md)),
              o ? o.start() : (o = e.md.sha1.create()),
              c || (c = o);
            var l = Math.ceil(r.n.bitLength() / 8),
              u = l - 2 * o.digestLength - 2;
            if (i.length > u) {
              var p = new Error("RSAES-OAEP input message length is too long.");
              throw ((p.length = i.length), (p.maxLength = u), p);
            }
            s || (s = ""), o.update(s, "raw");
            for (
              var h = o.digest(), f = "", g = u - i.length, m = 0;
              m < g;
              m++
            )
              f += "\0";
            var d = h.getBytes() + f + "" + i;
            if (a) {
              if (a.length !== o.digestLength) {
                var p = new Error(
                  "Invalid RSAES-OAEP seed. The seed length must match the digest length."
                );
                throw (
                  ((p.seedLength = a.length),
                  (p.digestLength = o.digestLength),
                  p)
                );
              }
            } else a = e.random.getBytes(o.digestLength);
            var y = t(a, l - o.digestLength - 1, c),
              k = e.util.xorBytes(d, y, d.length),
              v = t(k, o.digestLength, c),
              E = e.util.xorBytes(a, v, a.length);
            return "\0" + E + k;
          }),
            (r.decode_rsa_oaep = function (r, i, n) {
              var s, a, o;
              "string" == typeof n
                ? ((s = n), (a = arguments[3] || void 0))
                : n &&
                  ((s = n.label || void 0),
                  (a = n.md || void 0),
                  n.mgf1 && n.mgf1.md && (o = n.mgf1.md));
              var c = Math.ceil(r.n.bitLength() / 8);
              if (i.length !== c) {
                var l = new Error(
                  "RSAES-OAEP encoded message length is invalid."
                );
                throw ((l.length = i.length), (l.expectedLength = c), l);
              }
              if (
                (void 0 === a ? (a = e.md.sha1.create()) : a.start(),
                o || (o = a),
                c < 2 * a.digestLength + 2)
              )
                throw new Error(
                  "RSAES-OAEP key is too short for the hash function."
                );
              s || (s = ""), a.update(s, "raw");
              for (
                var u = a.digest().getBytes(),
                  p = i.charAt(0),
                  h = i.substring(1, a.digestLength + 1),
                  f = i.substring(1 + a.digestLength),
                  g = t(f, a.digestLength, o),
                  m = e.util.xorBytes(h, g, h.length),
                  d = t(m, c - a.digestLength - 1, o),
                  y = e.util.xorBytes(f, d, f.length),
                  k = y.substring(0, a.digestLength),
                  l = "\0" !== p,
                  v = 0;
                v < a.digestLength;
                ++v
              )
                l |= u.charAt(v) !== k.charAt(v);
              for (
                var E = 1, S = a.digestLength, _ = a.digestLength;
                _ < y.length;
                _++
              ) {
                var b = y.charCodeAt(_),
                  I = (1 & b) ^ 1,
                  A = E ? 65534 : 0;
                (l |= b & A), (E &= I), (S += E);
              }
              if (l || 1 !== y.charCodeAt(S))
                throw new Error("Invalid RSAES-OAEP padding.");
              return y.substring(S + 1);
            });
        }
        var i = "pkcs1";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pkcs1",
          ["require", "module", "./util", "./random", "./sha1"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(e, t, n, s) {
            return "workers" in n ? i(e, t, n, s) : r(e, t, n, s);
          }
          function r(t, i, a, o) {
            var l = n(t, i),
              u = 0,
              p = s(l.bitLength());
            "millerRabinTests" in a && (p = a.millerRabinTests);
            var h = 10;
            "maxBlockTime" in a && (h = a.maxBlockTime);
            var f = +new Date();
            do {
              if ((l.bitLength() > t && (l = n(t, i)), l.isProbablePrime(p)))
                return o(null, l);
              l.dAddOffset(c[u++ % 8], 0);
            } while (h < 0 || +new Date() - f < h);
            e.util.setImmediate(function () {
              r(t, i, a, o);
            });
          }
          function i(t, i, s, a) {
            function c() {
              function e(e) {
                if (!g) {
                  --c;
                  var s = e.data;
                  if (s.found) {
                    for (var u = 0; u < r.length; ++u) r[u].terminate();
                    return (g = !0), a(null, new o(s.prime, 16));
                  }
                  l.bitLength() > t && (l = n(t, i));
                  var f = l.toString(16);
                  e.target.postMessage({ hex: f, workLoad: p }),
                    l.dAddOffset(h, 0);
                }
              }
              u = Math.max(1, u);
              for (var r = [], s = 0; s < u; ++s) r[s] = new Worker(f);
              for (var c = u, s = 0; s < u; ++s)
                r[s].addEventListener("message", e);
              var g = !1;
            }
            if ("undefined" == typeof Worker) return r(t, i, s, a);
            var l = n(t, i),
              u = s.workers,
              p = s.workLoad || 100,
              h = (30 * p) / 8,
              f = s.workerScript || "forge/prime.worker.js";
            return u === -1
              ? e.util.estimateCores(function (e, t) {
                  e && (t = 2), (u = t - 1), c();
                })
              : void c();
          }
          function n(e, t) {
            var r = new o(e, t),
              i = e - 1;
            return (
              r.testBit(i) || r.bitwiseTo(o.ONE.shiftLeft(i), u, r),
              r.dAddOffset(31 - r.mod(l).byteValue(), 0),
              r
            );
          }
          function s(e) {
            return e <= 100
              ? 27
              : e <= 150
              ? 18
              : e <= 200
              ? 15
              : e <= 250
              ? 12
              : e <= 300
              ? 9
              : e <= 350
              ? 8
              : e <= 400
              ? 7
              : e <= 500
              ? 6
              : e <= 600
              ? 5
              : e <= 800
              ? 4
              : e <= 1250
              ? 3
              : 2;
          }
          if (!e.prime) {
            var a = (e.prime = e.prime || {}),
              o = e.jsbn.BigInteger,
              c = [6, 4, 2, 4, 2, 4, 6, 2],
              l = new o(null);
            l.fromInt(30);
            var u = function (e, t) {
              return e | t;
            };
            a.generateProbablePrime = function (r, i, n) {
              "function" == typeof i && ((n = i), (i = {})), (i = i || {});
              var s = i.algorithm || "PRIMEINC";
              "string" == typeof s && (s = { name: s }),
                (s.options = s.options || {});
              var a = i.prng || e.random,
                o = {
                  nextBytes: function (e) {
                    for (
                      var t = a.getBytesSync(e.length), r = 0;
                      r < e.length;
                      ++r
                    )
                      e[r] = t.charCodeAt(r);
                  },
                };
              if ("PRIMEINC" === s.name) return t(r, o, s.options, n);
              throw new Error("Invalid prime generation algorithm: " + s.name);
            };
          }
        }
        var i = "prime";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/prime",
          ["require", "module", "./util", "./jsbn", "./random"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(t, r, i) {
            var n = e.util.createBuffer(),
              s = Math.ceil(r.n.bitLength() / 8);
            if (t.length > s - 11) {
              var a = new Error("Message is too long for PKCS#1 v1.5 padding.");
              throw ((a.length = t.length), (a.max = s - 11), a);
            }
            n.putByte(0), n.putByte(i);
            var o,
              c = s - 3 - t.length;
            if (0 === i || 1 === i) {
              o = 0 === i ? 0 : 255;
              for (var l = 0; l < c; ++l) n.putByte(o);
            } else
              for (; c > 0; ) {
                for (var u = 0, p = e.random.getBytes(c), l = 0; l < c; ++l)
                  (o = p.charCodeAt(l)), 0 === o ? ++u : n.putByte(o);
                c = u;
              }
            return n.putByte(0), n.putBytes(t), n;
          }
          function r(t, r, i, n) {
            var s = Math.ceil(r.n.bitLength() / 8),
              a = e.util.createBuffer(t),
              o = a.getByte(),
              c = a.getByte();
            if (
              0 !== o ||
              (i && 0 !== c && 1 !== c) ||
              (!i && 2 != c) ||
              (i && 0 === c && "undefined" == typeof n)
            )
              throw new Error("Encryption block is invalid.");
            var l = 0;
            if (0 === c) {
              l = s - 3 - n;
              for (var u = 0; u < l; ++u)
                if (0 !== a.getByte())
                  throw new Error("Encryption block is invalid.");
            } else if (1 === c)
              for (l = 0; a.length() > 1; ) {
                if (255 !== a.getByte()) {
                  --a.read;
                  break;
                }
                ++l;
              }
            else if (2 === c)
              for (l = 0; a.length() > 1; ) {
                if (0 === a.getByte()) {
                  --a.read;
                  break;
                }
                ++l;
              }
            var p = a.getByte();
            if (0 !== p || l !== s - 3 - a.length())
              throw new Error("Encryption block is invalid.");
            return a.getBytes();
          }
          function i(t, r, i) {
            function n() {
              s(t.pBits, function (e, r) {
                return e
                  ? i(e)
                  : ((t.p = r), null !== t.q ? o(e, t.q) : void s(t.qBits, o));
              });
            }
            function s(t, r) {
              e.prime.generateProbablePrime(t, l, r);
            }
            function o(e, r) {
              if (e) return i(e);
              if (((t.q = r), t.p.compareTo(t.q) < 0)) {
                var l = t.p;
                (t.p = t.q), (t.q = l);
              }
              if (0 !== t.p.subtract(a.ONE).gcd(t.e).compareTo(a.ONE))
                return (t.p = null), void n();
              if (0 !== t.q.subtract(a.ONE).gcd(t.e).compareTo(a.ONE))
                return (t.q = null), void s(t.qBits, o);
              if (
                ((t.p1 = t.p.subtract(a.ONE)),
                (t.q1 = t.q.subtract(a.ONE)),
                (t.phi = t.p1.multiply(t.q1)),
                0 !== t.phi.gcd(t.e).compareTo(a.ONE))
              )
                return (t.p = t.q = null), void n();
              if (((t.n = t.p.multiply(t.q)), t.n.bitLength() !== t.bits))
                return (t.q = null), void s(t.qBits, o);
              var u = t.e.modInverse(t.phi);
              (t.keys = {
                privateKey: c.rsa.setPrivateKey(
                  t.n,
                  t.e,
                  u,
                  t.p,
                  t.q,
                  u.mod(t.p1),
                  u.mod(t.q1),
                  t.q.modInverse(t.p)
                ),
                publicKey: c.rsa.setPublicKey(t.n, t.e),
              }),
                i(null, t.keys);
            }
            "function" == typeof r && ((i = r), (r = {})), (r = r || {});
            var l = {
              algorithm: {
                name: r.algorithm || "PRIMEINC",
                options: {
                  workers: r.workers || 2,
                  workLoad: r.workLoad || 100,
                  workerScript: r.workerScript,
                },
              },
            };
            "prng" in r && (l.prng = r.prng), n();
          }
          function n(t) {
            var r = t.toString(16);
            return r[0] >= "8" && (r = "00" + r), e.util.hexToBytes(r);
          }
          function s(e) {
            return e <= 100
              ? 27
              : e <= 150
              ? 18
              : e <= 200
              ? 15
              : e <= 250
              ? 12
              : e <= 300
              ? 9
              : e <= 350
              ? 8
              : e <= 400
              ? 7
              : e <= 500
              ? 6
              : e <= 600
              ? 5
              : e <= 800
              ? 4
              : e <= 1250
              ? 3
              : 2;
          }
          if ("undefined" == typeof a) var a = e.jsbn.BigInteger;
          var o = e.asn1;
          (e.pki = e.pki || {}), (e.pki.rsa = e.rsa = e.rsa || {});
          var c = e.pki,
            l = [6, 4, 2, 4, 2, 4, 6, 2],
            u = {
              name: "PrivateKeyInfo",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "PrivateKeyInfo.version",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyVersion",
                },
                {
                  name: "PrivateKeyInfo.privateKeyAlgorithm",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "AlgorithmIdentifier.algorithm",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.OID,
                      constructed: !1,
                      capture: "privateKeyOid",
                    },
                  ],
                },
                {
                  name: "PrivateKeyInfo",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.OCTETSTRING,
                  constructed: !1,
                  capture: "privateKey",
                },
              ],
            },
            p = {
              name: "RSAPrivateKey",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "RSAPrivateKey.version",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyVersion",
                },
                {
                  name: "RSAPrivateKey.modulus",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyModulus",
                },
                {
                  name: "RSAPrivateKey.publicExponent",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyPublicExponent",
                },
                {
                  name: "RSAPrivateKey.privateExponent",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyPrivateExponent",
                },
                {
                  name: "RSAPrivateKey.prime1",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyPrime1",
                },
                {
                  name: "RSAPrivateKey.prime2",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyPrime2",
                },
                {
                  name: "RSAPrivateKey.exponent1",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyExponent1",
                },
                {
                  name: "RSAPrivateKey.exponent2",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyExponent2",
                },
                {
                  name: "RSAPrivateKey.coefficient",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "privateKeyCoefficient",
                },
              ],
            },
            h = {
              name: "RSAPublicKey",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "RSAPublicKey.modulus",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "publicKeyModulus",
                },
                {
                  name: "RSAPublicKey.exponent",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "publicKeyExponent",
                },
              ],
            },
            f = (e.pki.rsa.publicKeyValidator = {
              name: "SubjectPublicKeyInfo",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              captureAsn1: "subjectPublicKeyInfo",
              value: [
                {
                  name: "SubjectPublicKeyInfo.AlgorithmIdentifier",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "AlgorithmIdentifier.algorithm",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.OID,
                      constructed: !1,
                      capture: "publicKeyOid",
                    },
                  ],
                },
                {
                  name: "SubjectPublicKeyInfo.subjectPublicKey",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.BITSTRING,
                  constructed: !1,
                  value: [
                    {
                      name: "SubjectPublicKeyInfo.subjectPublicKey.RSAPublicKey",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      optional: !0,
                      captureAsn1: "rsaPublicKey",
                    },
                  ],
                },
              ],
            }),
            g = function (e) {
              var t;
              if (e.algorithm in c.oids) {
                t = c.oids[e.algorithm];
                var r = o.oidToDer(t).getBytes(),
                  i = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []),
                  n = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []);
                n.value.push(o.create(o.Class.UNIVERSAL, o.Type.OID, !1, r)),
                  n.value.push(
                    o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, "")
                  );
                var s = o.create(
                  o.Class.UNIVERSAL,
                  o.Type.OCTETSTRING,
                  !1,
                  e.digest().getBytes()
                );
                return i.value.push(n), i.value.push(s), o.toDer(i).getBytes();
              }
              var a = new Error("Unknown message digest algorithm.");
              throw ((a.algorithm = e.algorithm), a);
            },
            m = function (t, r, i) {
              if (i) return t.modPow(r.e, r.n);
              if (!r.p || !r.q) return t.modPow(r.d, r.n);
              r.dP || (r.dP = r.d.mod(r.p.subtract(a.ONE))),
                r.dQ || (r.dQ = r.d.mod(r.q.subtract(a.ONE))),
                r.qInv || (r.qInv = r.q.modInverse(r.p));
              var n;
              do
                n = new a(
                  e.util.bytesToHex(e.random.getBytes(r.n.bitLength() / 8)),
                  16
                );
              while (n.compareTo(r.n) >= 0 || !n.gcd(r.n).equals(a.ONE));
              t = t.multiply(n.modPow(r.e, r.n)).mod(r.n);
              for (
                var s = t.mod(r.p).modPow(r.dP, r.p),
                  o = t.mod(r.q).modPow(r.dQ, r.q);
                s.compareTo(o) < 0;

              )
                s = s.add(r.p);
              var c = s
                .subtract(o)
                .multiply(r.qInv)
                .mod(r.p)
                .multiply(r.q)
                .add(o);
              return (c = c.multiply(n.modInverse(r.n)).mod(r.n));
            };
          (c.rsa.encrypt = function (r, i, n) {
            var s,
              o = n,
              c = Math.ceil(i.n.bitLength() / 8);
            n !== !1 && n !== !0
              ? ((o = 2 === n), (s = t(r, i, n)))
              : ((s = e.util.createBuffer()), s.putBytes(r));
            for (
              var l = new a(s.toHex(), 16),
                u = m(l, i, o),
                p = u.toString(16),
                h = e.util.createBuffer(),
                f = c - Math.ceil(p.length / 2);
              f > 0;

            )
              h.putByte(0), --f;
            return h.putBytes(e.util.hexToBytes(p)), h.getBytes();
          }),
            (c.rsa.decrypt = function (t, i, n, s) {
              var o = Math.ceil(i.n.bitLength() / 8);
              if (t.length !== o) {
                var c = new Error("Encrypted message length is invalid.");
                throw ((c.length = t.length), (c.expected = o), c);
              }
              var l = new a(e.util.createBuffer(t).toHex(), 16);
              if (l.compareTo(i.n) >= 0)
                throw new Error("Encrypted message is invalid.");
              for (
                var u = m(l, i, n),
                  p = u.toString(16),
                  h = e.util.createBuffer(),
                  f = o - Math.ceil(p.length / 2);
                f > 0;

              )
                h.putByte(0), --f;
              return (
                h.putBytes(e.util.hexToBytes(p)),
                s !== !1 ? r(h.getBytes(), i, n) : h.getBytes()
              );
            }),
            (c.rsa.createKeyPairGenerationState = function (t, r, i) {
              "string" == typeof t && (t = parseInt(t, 10)),
                (t = t || 2048),
                (i = i || {});
              var n,
                s = i.prng || e.random,
                o = {
                  nextBytes: function (e) {
                    for (
                      var t = s.getBytesSync(e.length), r = 0;
                      r < e.length;
                      ++r
                    )
                      e[r] = t.charCodeAt(r);
                  },
                },
                c = i.algorithm || "PRIMEINC";
              if ("PRIMEINC" !== c)
                throw new Error("Invalid key generation algorithm: " + c);
              return (
                (n = {
                  algorithm: c,
                  state: 0,
                  bits: t,
                  rng: o,
                  eInt: r || 65537,
                  e: new a(null),
                  p: null,
                  q: null,
                  qBits: t >> 1,
                  pBits: t - (t >> 1),
                  pqState: 0,
                  num: null,
                  keys: null,
                }),
                n.e.fromInt(n.eInt),
                n
              );
            }),
            (c.rsa.stepKeyPairGenerationState = function (e, t) {
              "algorithm" in e || (e.algorithm = "PRIMEINC");
              var r = new a(null);
              r.fromInt(30);
              for (
                var i,
                  n = 0,
                  o = function (e, t) {
                    return e | t;
                  },
                  u = +new Date(),
                  p = 0;
                null === e.keys && (t <= 0 || p < t);

              ) {
                if (0 === e.state) {
                  var h = null === e.p ? e.pBits : e.qBits,
                    f = h - 1;
                  0 === e.pqState
                    ? ((e.num = new a(h, e.rng)),
                      e.num.testBit(f) ||
                        e.num.bitwiseTo(a.ONE.shiftLeft(f), o, e.num),
                      e.num.dAddOffset(31 - e.num.mod(r).byteValue(), 0),
                      (n = 0),
                      ++e.pqState)
                    : 1 === e.pqState
                    ? e.num.bitLength() > h
                      ? (e.pqState = 0)
                      : e.num.isProbablePrime(s(e.num.bitLength()))
                      ? ++e.pqState
                      : e.num.dAddOffset(l[n++ % 8], 0)
                    : 2 === e.pqState
                    ? (e.pqState =
                        0 === e.num.subtract(a.ONE).gcd(e.e).compareTo(a.ONE)
                          ? 3
                          : 0)
                    : 3 === e.pqState &&
                      ((e.pqState = 0),
                      null === e.p ? (e.p = e.num) : (e.q = e.num),
                      null !== e.p && null !== e.q && ++e.state,
                      (e.num = null));
                } else if (1 === e.state)
                  e.p.compareTo(e.q) < 0 &&
                    ((e.num = e.p), (e.p = e.q), (e.q = e.num)),
                    ++e.state;
                else if (2 === e.state)
                  (e.p1 = e.p.subtract(a.ONE)),
                    (e.q1 = e.q.subtract(a.ONE)),
                    (e.phi = e.p1.multiply(e.q1)),
                    ++e.state;
                else if (3 === e.state)
                  0 === e.phi.gcd(e.e).compareTo(a.ONE)
                    ? ++e.state
                    : ((e.p = null), (e.q = null), (e.state = 0));
                else if (4 === e.state)
                  (e.n = e.p.multiply(e.q)),
                    e.n.bitLength() === e.bits
                      ? ++e.state
                      : ((e.q = null), (e.state = 0));
                else if (5 === e.state) {
                  var g = e.e.modInverse(e.phi);
                  e.keys = {
                    privateKey: c.rsa.setPrivateKey(
                      e.n,
                      e.e,
                      g,
                      e.p,
                      e.q,
                      g.mod(e.p1),
                      g.mod(e.q1),
                      e.q.modInverse(e.p)
                    ),
                    publicKey: c.rsa.setPublicKey(e.n, e.e),
                  };
                }
                (i = +new Date()), (p += i - u), (u = i);
              }
              return null !== e.keys;
            }),
            (c.rsa.generateKeyPair = function (e, t, r, n) {
              1 === arguments.length
                ? "object" == typeof e
                  ? ((r = e), (e = void 0))
                  : "function" == typeof e && ((n = e), (e = void 0))
                : 2 === arguments.length
                ? "number" == typeof e
                  ? "function" == typeof t
                    ? ((n = t), (t = void 0))
                    : "number" != typeof t && ((r = t), (t = void 0))
                  : ((r = e), (n = t), (e = void 0), (t = void 0))
                : 3 === arguments.length &&
                  ("number" == typeof t
                    ? "function" == typeof r && ((n = r), (r = void 0))
                    : ((n = r), (r = t), (t = void 0))),
                (r = r || {}),
                void 0 === e && (e = r.bits || 2048),
                void 0 === t && (t = r.e || 65537);
              var s = c.rsa.createKeyPairGenerationState(e, t, r);
              return n
                ? void i(s, r, n)
                : (c.rsa.stepKeyPairGenerationState(s, 0), s.keys);
            }),
            (c.setRsaPublicKey = c.rsa.setPublicKey =
              function (i, n) {
                var s = { n: i, e: n };
                return (
                  (s.encrypt = function (r, i, n) {
                    if (
                      ("string" == typeof i
                        ? (i = i.toUpperCase())
                        : void 0 === i && (i = "RSAES-PKCS1-V1_5"),
                      "RSAES-PKCS1-V1_5" === i)
                    )
                      i = {
                        encode: function (e, r, i) {
                          return t(e, r, 2).getBytes();
                        },
                      };
                    else if ("RSA-OAEP" === i || "RSAES-OAEP" === i)
                      i = {
                        encode: function (t, r) {
                          return e.pkcs1.encode_rsa_oaep(r, t, n);
                        },
                      };
                    else if (["RAW", "NONE", "NULL", null].indexOf(i) !== -1)
                      i = {
                        encode: function (e) {
                          return e;
                        },
                      };
                    else if ("string" == typeof i)
                      throw new Error(
                        'Unsupported encryption scheme: "' + i + '".'
                      );
                    var a = i.encode(r, s, !0);
                    return c.rsa.encrypt(a, s, !0);
                  }),
                  (s.verify = function (e, t, i) {
                    "string" == typeof i
                      ? (i = i.toUpperCase())
                      : void 0 === i && (i = "RSASSA-PKCS1-V1_5"),
                      "RSASSA-PKCS1-V1_5" === i
                        ? (i = {
                            verify: function (e, t) {
                              t = r(t, s, !0);
                              var i = o.fromDer(t);
                              return e === i.value[1].value;
                            },
                          })
                        : ("NONE" !== i && "NULL" !== i && null !== i) ||
                          (i = {
                            verify: function (e, t) {
                              return (t = r(t, s, !0)), e === t;
                            },
                          });
                    var n = c.rsa.decrypt(t, s, !0, !1);
                    return i.verify(e, n, s.n.bitLength());
                  }),
                  s
                );
              }),
            (c.setRsaPrivateKey = c.rsa.setPrivateKey =
              function (t, i, n, s, a, o, l, u) {
                var p = { n: t, e: i, d: n, p: s, q: a, dP: o, dQ: l, qInv: u };
                return (
                  (p.decrypt = function (t, i, n) {
                    "string" == typeof i
                      ? (i = i.toUpperCase())
                      : void 0 === i && (i = "RSAES-PKCS1-V1_5");
                    var s = c.rsa.decrypt(t, p, !1, !1);
                    if ("RSAES-PKCS1-V1_5" === i) i = { decode: r };
                    else if ("RSA-OAEP" === i || "RSAES-OAEP" === i)
                      i = {
                        decode: function (t, r) {
                          return e.pkcs1.decode_rsa_oaep(r, t, n);
                        },
                      };
                    else {
                      if (["RAW", "NONE", "NULL", null].indexOf(i) === -1)
                        throw new Error(
                          'Unsupported encryption scheme: "' + i + '".'
                        );
                      i = {
                        decode: function (e) {
                          return e;
                        },
                      };
                    }
                    return i.decode(s, p, !1);
                  }),
                  (p.sign = function (e, t) {
                    var r = !1;
                    "string" == typeof t && (t = t.toUpperCase()),
                      void 0 === t || "RSASSA-PKCS1-V1_5" === t
                        ? ((t = { encode: g }), (r = 1))
                        : ("NONE" !== t && "NULL" !== t && null !== t) ||
                          ((t = {
                            encode: function () {
                              return e;
                            },
                          }),
                          (r = 1));
                    var i = t.encode(e, p.n.bitLength());
                    return c.rsa.encrypt(i, p, r);
                  }),
                  p
                );
              }),
            (c.wrapRsaPrivateKey = function (e) {
              return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.INTEGER,
                  !1,
                  o.integerToDer(0).getBytes()
                ),
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OID,
                    !1,
                    o.oidToDer(c.oids.rsaEncryption).getBytes()
                  ),
                  o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, ""),
                ]),
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.OCTETSTRING,
                  !1,
                  o.toDer(e).getBytes()
                ),
              ]);
            }),
            (c.privateKeyFromAsn1 = function (t) {
              var r = {},
                i = [];
              if (
                (o.validate(t, u, r, i) &&
                  (t = o.fromDer(e.util.createBuffer(r.privateKey))),
                (r = {}),
                (i = []),
                !o.validate(t, p, r, i))
              ) {
                var n = new Error(
                  "Cannot read private key. ASN.1 object does not contain an RSAPrivateKey."
                );
                throw ((n.errors = i), n);
              }
              var s, l, h, f, g, m, d, y;
              return (
                (s = e.util.createBuffer(r.privateKeyModulus).toHex()),
                (l = e.util.createBuffer(r.privateKeyPublicExponent).toHex()),
                (h = e.util.createBuffer(r.privateKeyPrivateExponent).toHex()),
                (f = e.util.createBuffer(r.privateKeyPrime1).toHex()),
                (g = e.util.createBuffer(r.privateKeyPrime2).toHex()),
                (m = e.util.createBuffer(r.privateKeyExponent1).toHex()),
                (d = e.util.createBuffer(r.privateKeyExponent2).toHex()),
                (y = e.util.createBuffer(r.privateKeyCoefficient).toHex()),
                c.setRsaPrivateKey(
                  new a(s, 16),
                  new a(l, 16),
                  new a(h, 16),
                  new a(f, 16),
                  new a(g, 16),
                  new a(m, 16),
                  new a(d, 16),
                  new a(y, 16)
                )
              );
            }),
            (c.privateKeyToAsn1 = c.privateKeyToRSAPrivateKey =
              function (e) {
                return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.INTEGER,
                    !1,
                    o.integerToDer(0).getBytes()
                  ),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.n)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.e)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.d)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.p)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.q)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.dP)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.dQ)),
                  o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.qInv)),
                ]);
              }),
            (c.publicKeyFromAsn1 = function (t) {
              var r = {},
                i = [];
              if (o.validate(t, f, r, i)) {
                var n = o.derToOid(r.publicKeyOid);
                if (n !== c.oids.rsaEncryption) {
                  var s = new Error("Cannot read public key. Unknown OID.");
                  throw ((s.oid = n), s);
                }
                t = r.rsaPublicKey;
              }
              if (((i = []), !o.validate(t, h, r, i))) {
                var s = new Error(
                  "Cannot read public key. ASN.1 object does not contain an RSAPublicKey."
                );
                throw ((s.errors = i), s);
              }
              var l = e.util.createBuffer(r.publicKeyModulus).toHex(),
                u = e.util.createBuffer(r.publicKeyExponent).toHex();
              return c.setRsaPublicKey(new a(l, 16), new a(u, 16));
            }),
            (c.publicKeyToAsn1 = c.publicKeyToSubjectPublicKeyInfo =
              function (e) {
                return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(c.oids.rsaEncryption).getBytes()
                    ),
                    o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, ""),
                  ]),
                  o.create(o.Class.UNIVERSAL, o.Type.BITSTRING, !1, [
                    c.publicKeyToRSAPublicKey(e),
                  ]),
                ]);
              }),
            (c.publicKeyToRSAPublicKey = function (e) {
              return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.n)),
                o.create(o.Class.UNIVERSAL, o.Type.INTEGER, !1, n(e.e)),
              ]);
            });
        }
        var i = "rsa";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/rsa",
          [
            "require",
            "module",
            "./asn1",
            "./jsbn",
            "./oids",
            "./pkcs1",
            "./prime",
            "./random",
            "./util",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(e, t) {
            return e.start().update(t).digest().getBytes();
          }
          if ("undefined" == typeof r) var r = e.jsbn.BigInteger;
          var i = e.asn1,
            n = (e.pki = e.pki || {});
          n.pbe = e.pbe = e.pbe || {};
          var s = n.oids,
            a = {
              name: "EncryptedPrivateKeyInfo",
              tagClass: i.Class.UNIVERSAL,
              type: i.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "EncryptedPrivateKeyInfo.encryptionAlgorithm",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "AlgorithmIdentifier.algorithm",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.OID,
                      constructed: !1,
                      capture: "encryptionOid",
                    },
                    {
                      name: "AlgorithmIdentifier.parameters",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.SEQUENCE,
                      constructed: !0,
                      captureAsn1: "encryptionParams",
                    },
                  ],
                },
                {
                  name: "EncryptedPrivateKeyInfo.encryptedData",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.OCTETSTRING,
                  constructed: !1,
                  capture: "encryptedData",
                },
              ],
            },
            o = {
              name: "PBES2Algorithms",
              tagClass: i.Class.UNIVERSAL,
              type: i.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "PBES2Algorithms.keyDerivationFunc",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "PBES2Algorithms.keyDerivationFunc.oid",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.OID,
                      constructed: !1,
                      capture: "kdfOid",
                    },
                    {
                      name: "PBES2Algorithms.params",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.SEQUENCE,
                      constructed: !0,
                      value: [
                        {
                          name: "PBES2Algorithms.params.salt",
                          tagClass: i.Class.UNIVERSAL,
                          type: i.Type.OCTETSTRING,
                          constructed: !1,
                          capture: "kdfSalt",
                        },
                        {
                          name: "PBES2Algorithms.params.iterationCount",
                          tagClass: i.Class.UNIVERSAL,
                          type: i.Type.INTEGER,
                          onstructed: !0,
                          capture: "kdfIterationCount",
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "PBES2Algorithms.encryptionScheme",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "PBES2Algorithms.encryptionScheme.oid",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.OID,
                      constructed: !1,
                      capture: "encOid",
                    },
                    {
                      name: "PBES2Algorithms.encryptionScheme.iv",
                      tagClass: i.Class.UNIVERSAL,
                      type: i.Type.OCTETSTRING,
                      constructed: !1,
                      capture: "encIv",
                    },
                  ],
                },
              ],
            },
            c = {
              name: "pkcs-12PbeParams",
              tagClass: i.Class.UNIVERSAL,
              type: i.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "pkcs-12PbeParams.salt",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.OCTETSTRING,
                  constructed: !1,
                  capture: "salt",
                },
                {
                  name: "pkcs-12PbeParams.iterations",
                  tagClass: i.Class.UNIVERSAL,
                  type: i.Type.INTEGER,
                  constructed: !1,
                  capture: "iterations",
                },
              ],
            };
          (n.encryptPrivateKeyInfo = function (t, r, a) {
            (a = a || {}),
              (a.saltSize = a.saltSize || 8),
              (a.count = a.count || 2048),
              (a.algorithm = a.algorithm || "aes128");
            var o,
              c,
              l,
              u = e.random.getBytesSync(a.saltSize),
              p = a.count,
              h = i.integerToDer(p);
            if (0 === a.algorithm.indexOf("aes") || "des" === a.algorithm) {
              var f, g, m;
              switch (a.algorithm) {
                case "aes128":
                  (o = 16),
                    (f = 16),
                    (g = s["aes128-CBC"]),
                    (m = e.aes.createEncryptionCipher);
                  break;
                case "aes192":
                  (o = 24),
                    (f = 16),
                    (g = s["aes192-CBC"]),
                    (m = e.aes.createEncryptionCipher);
                  break;
                case "aes256":
                  (o = 32),
                    (f = 16),
                    (g = s["aes256-CBC"]),
                    (m = e.aes.createEncryptionCipher);
                  break;
                case "des":
                  (o = 8),
                    (f = 8),
                    (g = s.desCBC),
                    (m = e.des.createEncryptionCipher);
                  break;
                default:
                  var d = new Error(
                    "Cannot encrypt private key. Unknown encryption algorithm."
                  );
                  throw ((d.algorithm = a.algorithm), d);
              }
              var y = e.pkcs5.pbkdf2(r, u, p, o),
                k = e.random.getBytesSync(f),
                v = m(y);
              v.start(k),
                v.update(i.toDer(t)),
                v.finish(),
                (l = v.output.getBytes()),
                (c = i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                  i.create(
                    i.Class.UNIVERSAL,
                    i.Type.OID,
                    !1,
                    i.oidToDer(s.pkcs5PBES2).getBytes()
                  ),
                  i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                    i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                      i.create(
                        i.Class.UNIVERSAL,
                        i.Type.OID,
                        !1,
                        i.oidToDer(s.pkcs5PBKDF2).getBytes()
                      ),
                      i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                        i.create(i.Class.UNIVERSAL, i.Type.OCTETSTRING, !1, u),
                        i.create(
                          i.Class.UNIVERSAL,
                          i.Type.INTEGER,
                          !1,
                          h.getBytes()
                        ),
                      ]),
                    ]),
                    i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                      i.create(
                        i.Class.UNIVERSAL,
                        i.Type.OID,
                        !1,
                        i.oidToDer(g).getBytes()
                      ),
                      i.create(i.Class.UNIVERSAL, i.Type.OCTETSTRING, !1, k),
                    ]),
                  ]),
                ]));
            } else {
              if ("3des" !== a.algorithm) {
                var d = new Error(
                  "Cannot encrypt private key. Unknown encryption algorithm."
                );
                throw ((d.algorithm = a.algorithm), d);
              }
              o = 24;
              var E = new e.util.ByteBuffer(u),
                y = n.pbe.generatePkcs12Key(r, E, 1, p, o),
                k = n.pbe.generatePkcs12Key(r, E, 2, p, o),
                v = e.des.createEncryptionCipher(y);
              v.start(k),
                v.update(i.toDer(t)),
                v.finish(),
                (l = v.output.getBytes()),
                (c = i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                  i.create(
                    i.Class.UNIVERSAL,
                    i.Type.OID,
                    !1,
                    i.oidToDer(s["pbeWithSHAAnd3-KeyTripleDES-CBC"]).getBytes()
                  ),
                  i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
                    i.create(i.Class.UNIVERSAL, i.Type.OCTETSTRING, !1, u),
                    i.create(
                      i.Class.UNIVERSAL,
                      i.Type.INTEGER,
                      !1,
                      h.getBytes()
                    ),
                  ]),
                ]));
            }
            var S = i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
              c,
              i.create(i.Class.UNIVERSAL, i.Type.OCTETSTRING, !1, l),
            ]);
            return S;
          }),
            (n.decryptPrivateKeyInfo = function (t, r) {
              var s = null,
                o = {},
                c = [];
              if (!i.validate(t, a, o, c)) {
                var l = new Error(
                  "Cannot read encrypted private key. ASN.1 object is not a supported EncryptedPrivateKeyInfo."
                );
                throw ((l.errors = c), l);
              }
              var u = i.derToOid(o.encryptionOid),
                p = n.pbe.getCipher(u, o.encryptionParams, r),
                h = e.util.createBuffer(o.encryptedData);
              return p.update(h), p.finish() && (s = i.fromDer(p.output)), s;
            }),
            (n.encryptedPrivateKeyToPem = function (t, r) {
              var n = {
                type: "ENCRYPTED PRIVATE KEY",
                body: i.toDer(t).getBytes(),
              };
              return e.pem.encode(n, { maxline: r });
            }),
            (n.encryptedPrivateKeyFromPem = function (t) {
              var r = e.pem.decode(t)[0];
              if ("ENCRYPTED PRIVATE KEY" !== r.type) {
                var n = new Error(
                  'Could not convert encrypted private key from PEM; PEM header type is "ENCRYPTED PRIVATE KEY".'
                );
                throw ((n.headerType = r.type), n);
              }
              if (r.procType && "ENCRYPTED" === r.procType.type)
                throw new Error(
                  "Could not convert encrypted private key from PEM; PEM is encrypted."
                );
              return i.fromDer(r.body);
            }),
            (n.encryptRsaPrivateKey = function (t, r, s) {
              if (((s = s || {}), !s.legacy)) {
                var a = n.wrapRsaPrivateKey(n.privateKeyToAsn1(t));
                return (
                  (a = n.encryptPrivateKeyInfo(a, r, s)),
                  n.encryptedPrivateKeyToPem(a)
                );
              }
              var o, c, l, u;
              switch (s.algorithm) {
                case "aes128":
                  (o = "AES-128-CBC"),
                    (l = 16),
                    (c = e.random.getBytesSync(16)),
                    (u = e.aes.createEncryptionCipher);
                  break;
                case "aes192":
                  (o = "AES-192-CBC"),
                    (l = 24),
                    (c = e.random.getBytesSync(16)),
                    (u = e.aes.createEncryptionCipher);
                  break;
                case "aes256":
                  (o = "AES-256-CBC"),
                    (l = 32),
                    (c = e.random.getBytesSync(16)),
                    (u = e.aes.createEncryptionCipher);
                  break;
                case "3des":
                  (o = "DES-EDE3-CBC"),
                    (l = 24),
                    (c = e.random.getBytesSync(8)),
                    (u = e.des.createEncryptionCipher);
                  break;
                case "des":
                  (o = "DES-CBC"),
                    (l = 8),
                    (c = e.random.getBytesSync(8)),
                    (u = e.des.createEncryptionCipher);
                  break;
                default:
                  var p = new Error(
                    'Could not encrypt RSA private key; unsupported encryption algorithm "' +
                      s.algorithm +
                      '".'
                  );
                  throw ((p.algorithm = s.algorithm), p);
              }
              var h = e.pbe.opensslDeriveBytes(r, c.substr(0, 8), l),
                f = u(h);
              f.start(c), f.update(i.toDer(n.privateKeyToAsn1(t))), f.finish();
              var g = {
                type: "RSA PRIVATE KEY",
                procType: { version: "4", type: "ENCRYPTED" },
                dekInfo: {
                  algorithm: o,
                  parameters: e.util.bytesToHex(c).toUpperCase(),
                },
                body: f.output.getBytes(),
              };
              return e.pem.encode(g);
            }),
            (n.decryptRsaPrivateKey = function (t, r) {
              var s = null,
                a = e.pem.decode(t)[0];
              if (
                "ENCRYPTED PRIVATE KEY" !== a.type &&
                "PRIVATE KEY" !== a.type &&
                "RSA PRIVATE KEY" !== a.type
              ) {
                var o = new Error(
                  'Could not convert private key from PEM; PEM header type is not "ENCRYPTED PRIVATE KEY", "PRIVATE KEY", or "RSA PRIVATE KEY".'
                );
                throw ((o.headerType = o), o);
              }
              if (a.procType && "ENCRYPTED" === a.procType.type) {
                var c, l;
                switch (a.dekInfo.algorithm) {
                  case "DES-CBC":
                    (c = 8), (l = e.des.createDecryptionCipher);
                    break;
                  case "DES-EDE3-CBC":
                    (c = 24), (l = e.des.createDecryptionCipher);
                    break;
                  case "AES-128-CBC":
                    (c = 16), (l = e.aes.createDecryptionCipher);
                    break;
                  case "AES-192-CBC":
                    (c = 24), (l = e.aes.createDecryptionCipher);
                    break;
                  case "AES-256-CBC":
                    (c = 32), (l = e.aes.createDecryptionCipher);
                    break;
                  case "RC2-40-CBC":
                    (c = 5),
                      (l = function (t) {
                        return e.rc2.createDecryptionCipher(t, 40);
                      });
                    break;
                  case "RC2-64-CBC":
                    (c = 8),
                      (l = function (t) {
                        return e.rc2.createDecryptionCipher(t, 64);
                      });
                    break;
                  case "RC2-128-CBC":
                    (c = 16),
                      (l = function (t) {
                        return e.rc2.createDecryptionCipher(t, 128);
                      });
                    break;
                  default:
                    var o = new Error(
                      'Could not decrypt private key; unsupported encryption algorithm "' +
                        a.dekInfo.algorithm +
                        '".'
                    );
                    throw ((o.algorithm = a.dekInfo.algorithm), o);
                }
                var u = e.util.hexToBytes(a.dekInfo.parameters),
                  p = e.pbe.opensslDeriveBytes(r, u.substr(0, 8), c),
                  h = l(p);
                if (
                  (h.start(u),
                  h.update(e.util.createBuffer(a.body)),
                  !h.finish())
                )
                  return s;
                s = h.output.getBytes();
              } else s = a.body;
              return (
                (s =
                  "ENCRYPTED PRIVATE KEY" === a.type
                    ? n.decryptPrivateKeyInfo(i.fromDer(s), r)
                    : i.fromDer(s)),
                null !== s && (s = n.privateKeyFromAsn1(s)),
                s
              );
            }),
            (n.pbe.generatePkcs12Key = function (t, r, i, n, s, a) {
              var o, c;
              ("undefined" != typeof a && null !== a) ||
                (a = e.md.sha1.create());
              var l = a.digestLength,
                u = a.blockLength,
                p = new e.util.ByteBuffer(),
                h = new e.util.ByteBuffer();
              if (null !== t && void 0 !== t) {
                for (c = 0; c < t.length; c++) h.putInt16(t.charCodeAt(c));
                h.putInt16(0);
              }
              var f = h.length(),
                g = r.length(),
                m = new e.util.ByteBuffer();
              m.fillWithByte(i, u);
              var d = u * Math.ceil(g / u),
                y = new e.util.ByteBuffer();
              for (c = 0; c < d; c++) y.putByte(r.at(c % g));
              var k = u * Math.ceil(f / u),
                v = new e.util.ByteBuffer();
              for (c = 0; c < k; c++) v.putByte(h.at(c % f));
              var E = y;
              E.putBuffer(v);
              for (var S = Math.ceil(s / l), _ = 1; _ <= S; _++) {
                var b = new e.util.ByteBuffer();
                b.putBytes(m.bytes()), b.putBytes(E.bytes());
                for (var I = 0; I < n; I++)
                  a.start(), a.update(b.getBytes()), (b = a.digest());
                var A = new e.util.ByteBuffer();
                for (c = 0; c < u; c++) A.putByte(b.at(c % l));
                var C = Math.ceil(g / u) + Math.ceil(f / u),
                  T = new e.util.ByteBuffer();
                for (o = 0; o < C; o++) {
                  var N = new e.util.ByteBuffer(E.getBytes(u)),
                    j = 511;
                  for (c = A.length() - 1; c >= 0; c--)
                    (j >>= 8), (j += A.at(c) + N.at(c)), N.setAt(c, 255 & j);
                  T.putBuffer(N);
                }
                (E = T), p.putBuffer(b);
              }
              return p.truncate(p.length() - s), p;
            }),
            (n.pbe.getCipher = function (e, t, r) {
              switch (e) {
                case n.oids.pkcs5PBES2:
                  return n.pbe.getCipherForPBES2(e, t, r);
                case n.oids["pbeWithSHAAnd3-KeyTripleDES-CBC"]:
                case n.oids["pbewithSHAAnd40BitRC2-CBC"]:
                  return n.pbe.getCipherForPKCS12PBE(e, t, r);
                default:
                  var i = new Error(
                    "Cannot read encrypted PBE data block. Unsupported OID."
                  );
                  throw (
                    ((i.oid = e),
                    (i.supportedOids = [
                      "pkcs5PBES2",
                      "pbeWithSHAAnd3-KeyTripleDES-CBC",
                      "pbewithSHAAnd40BitRC2-CBC",
                    ]),
                    i)
                  );
              }
            }),
            (n.pbe.getCipherForPBES2 = function (t, r, s) {
              var a = {},
                c = [];
              if (!i.validate(r, o, a, c)) {
                var l = new Error(
                  "Cannot read password-based-encryption algorithm parameters. ASN.1 object is not a supported EncryptedPrivateKeyInfo."
                );
                throw ((l.errors = c), l);
              }
              if (((t = i.derToOid(a.kdfOid)), t !== n.oids.pkcs5PBKDF2)) {
                var l = new Error(
                  "Cannot read encrypted private key. Unsupported key derivation function OID."
                );
                throw ((l.oid = t), (l.supportedOids = ["pkcs5PBKDF2"]), l);
              }
              if (
                ((t = i.derToOid(a.encOid)),
                t !== n.oids["aes128-CBC"] &&
                  t !== n.oids["aes192-CBC"] &&
                  t !== n.oids["aes256-CBC"] &&
                  t !== n.oids["des-EDE3-CBC"] &&
                  t !== n.oids.desCBC)
              ) {
                var l = new Error(
                  "Cannot read encrypted private key. Unsupported encryption scheme OID."
                );
                throw (
                  ((l.oid = t),
                  (l.supportedOids = [
                    "aes128-CBC",
                    "aes192-CBC",
                    "aes256-CBC",
                    "des-EDE3-CBC",
                    "desCBC",
                  ]),
                  l)
                );
              }
              var u = a.kdfSalt,
                p = e.util.createBuffer(a.kdfIterationCount);
              p = p.getInt(p.length() << 3);
              var h, f;
              switch (n.oids[t]) {
                case "aes128-CBC":
                  (h = 16), (f = e.aes.createDecryptionCipher);
                  break;
                case "aes192-CBC":
                  (h = 24), (f = e.aes.createDecryptionCipher);
                  break;
                case "aes256-CBC":
                  (h = 32), (f = e.aes.createDecryptionCipher);
                  break;
                case "des-EDE3-CBC":
                  (h = 24), (f = e.des.createDecryptionCipher);
                  break;
                case "desCBC":
                  (h = 8), (f = e.des.createDecryptionCipher);
              }
              var g = e.pkcs5.pbkdf2(s, u, p, h),
                m = a.encIv,
                d = f(g);
              return d.start(m), d;
            }),
            (n.pbe.getCipherForPKCS12PBE = function (t, r, s) {
              var a = {},
                o = [];
              if (!i.validate(r, c, a, o)) {
                var l = new Error(
                  "Cannot read password-based-encryption algorithm parameters. ASN.1 object is not a supported EncryptedPrivateKeyInfo."
                );
                throw ((l.errors = o), l);
              }
              var u = e.util.createBuffer(a.salt),
                p = e.util.createBuffer(a.iterations);
              p = p.getInt(p.length() << 3);
              var h, f, g;
              switch (t) {
                case n.oids["pbeWithSHAAnd3-KeyTripleDES-CBC"]:
                  (h = 24), (f = 8), (g = e.des.startDecrypting);
                  break;
                case n.oids["pbewithSHAAnd40BitRC2-CBC"]:
                  (h = 5),
                    (f = 8),
                    (g = function (t, r) {
                      var i = e.rc2.createDecryptionCipher(t, 40);
                      return i.start(r, null), i;
                    });
                  break;
                default:
                  var l = new Error(
                    "Cannot read PKCS #12 PBE data block. Unsupported OID."
                  );
                  throw ((l.oid = t), l);
              }
              var m = n.pbe.generatePkcs12Key(s, u, 1, p, h),
                d = n.pbe.generatePkcs12Key(s, u, 2, p, f);
              return g(m, d);
            }),
            (n.pbe.opensslDeriveBytes = function (r, i, n, s) {
              ("undefined" != typeof s && null !== s) ||
                (s = e.md.md5.create()),
                null === i && (i = "");
              for (var a = [t(s, r + i)], o = 16, c = 1; o < n; ++c, o += 16)
                a.push(t(s, a[c - 1] + r + i));
              return a.join("").substr(0, n);
            });
        }
        var i = "pbe";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pbe",
          [
            "require",
            "module",
            "./aes",
            "./asn1",
            "./des",
            "./md",
            "./oids",
            "./pem",
            "./pbkdf2",
            "./random",
            "./rc2",
            "./rsa",
            "./util",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = e.asn1,
            r = (e.pkcs7asn1 = e.pkcs7asn1 || {});
          (e.pkcs7 = e.pkcs7 || {}), (e.pkcs7.asn1 = r);
          var i = {
            name: "ContentInfo",
            tagClass: t.Class.UNIVERSAL,
            type: t.Type.SEQUENCE,
            constructed: !0,
            value: [
              {
                name: "ContentInfo.ContentType",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.OID,
                constructed: !1,
                capture: "contentType",
              },
              {
                name: "ContentInfo.content",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 0,
                constructed: !0,
                optional: !0,
                captureAsn1: "content",
              },
            ],
          };
          r.contentInfoValidator = i;
          var n = {
            name: "EncryptedContentInfo",
            tagClass: t.Class.UNIVERSAL,
            type: t.Type.SEQUENCE,
            constructed: !0,
            value: [
              {
                name: "EncryptedContentInfo.contentType",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.OID,
                constructed: !1,
                capture: "contentType",
              },
              {
                name: "EncryptedContentInfo.contentEncryptionAlgorithm",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SEQUENCE,
                constructed: !0,
                value: [
                  {
                    name: "EncryptedContentInfo.contentEncryptionAlgorithm.algorithm",
                    tagClass: t.Class.UNIVERSAL,
                    type: t.Type.OID,
                    constructed: !1,
                    capture: "encAlgorithm",
                  },
                  {
                    name: "EncryptedContentInfo.contentEncryptionAlgorithm.parameter",
                    tagClass: t.Class.UNIVERSAL,
                    captureAsn1: "encParameter",
                  },
                ],
              },
              {
                name: "EncryptedContentInfo.encryptedContent",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 0,
                capture: "encryptedContent",
                captureAsn1: "encryptedContentAsn1",
              },
            ],
          };
          (r.envelopedDataValidator = {
            name: "EnvelopedData",
            tagClass: t.Class.UNIVERSAL,
            type: t.Type.SEQUENCE,
            constructed: !0,
            value: [
              {
                name: "EnvelopedData.Version",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.INTEGER,
                constructed: !1,
                capture: "version",
              },
              {
                name: "EnvelopedData.RecipientInfos",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SET,
                constructed: !0,
                captureAsn1: "recipientInfos",
              },
            ].concat(n),
          }),
            (r.encryptedDataValidator = {
              name: "EncryptedData",
              tagClass: t.Class.UNIVERSAL,
              type: t.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "EncryptedData.Version",
                  tagClass: t.Class.UNIVERSAL,
                  type: t.Type.INTEGER,
                  constructed: !1,
                  capture: "version",
                },
              ].concat(n),
            });
          var s = {
            name: "SignerInfo",
            tagClass: t.Class.UNIVERSAL,
            type: t.Type.SEQUENCE,
            constructed: !0,
            value: [
              {
                name: "SignerInfo.version",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.INTEGER,
                constructed: !1,
              },
              {
                name: "SignerInfo.issuerAndSerialNumber",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SEQUENCE,
                constructed: !0,
                value: [
                  {
                    name: "SignerInfo.issuerAndSerialNumber.issuer",
                    tagClass: t.Class.UNIVERSAL,
                    type: t.Type.SEQUENCE,
                    constructed: !0,
                    captureAsn1: "issuer",
                  },
                  {
                    name: "SignerInfo.issuerAndSerialNumber.serialNumber",
                    tagClass: t.Class.UNIVERSAL,
                    type: t.Type.INTEGER,
                    constructed: !1,
                    capture: "serial",
                  },
                ],
              },
              {
                name: "SignerInfo.digestAlgorithm",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SEQUENCE,
                constructed: !0,
                value: [
                  {
                    name: "SignerInfo.digestAlgorithm.algorithm",
                    tagClass: t.Class.UNIVERSAL,
                    type: t.Type.OID,
                    constructed: !1,
                    capture: "digestAlgorithm",
                  },
                  {
                    name: "SignerInfo.digestAlgorithm.parameter",
                    tagClass: t.Class.UNIVERSAL,
                    constructed: !1,
                    captureAsn1: "digestParameter",
                    optional: !0,
                  },
                ],
              },
              {
                name: "SignerInfo.authenticatedAttributes",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 0,
                constructed: !0,
                optional: !0,
                capture: "authenticatedAttributes",
              },
              {
                name: "SignerInfo.digestEncryptionAlgorithm",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SEQUENCE,
                constructed: !0,
                capture: "signatureAlgorithm",
              },
              {
                name: "SignerInfo.encryptedDigest",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.OCTETSTRING,
                constructed: !1,
                capture: "signature",
              },
              {
                name: "SignerInfo.unauthenticatedAttributes",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 1,
                constructed: !0,
                optional: !0,
                capture: "unauthenticatedAttributes",
              },
            ],
          };
          (r.signedDataValidator = {
            name: "SignedData",
            tagClass: t.Class.UNIVERSAL,
            type: t.Type.SEQUENCE,
            constructed: !0,
            value: [
              {
                name: "SignedData.Version",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.INTEGER,
                constructed: !1,
                capture: "version",
              },
              {
                name: "SignedData.DigestAlgorithms",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SET,
                constructed: !0,
                captureAsn1: "digestAlgorithms",
              },
              i,
              {
                name: "SignedData.Certificates",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 0,
                optional: !0,
                captureAsn1: "certificates",
              },
              {
                name: "SignedData.CertificateRevocationLists",
                tagClass: t.Class.CONTEXT_SPECIFIC,
                type: 1,
                optional: !0,
                captureAsn1: "crls",
              },
              {
                name: "SignedData.SignerInfos",
                tagClass: t.Class.UNIVERSAL,
                type: t.Type.SET,
                capture: "signerInfos",
                optional: !0,
                value: [s],
              },
            ],
          }),
            (r.recipientInfoValidator = {
              name: "RecipientInfo",
              tagClass: t.Class.UNIVERSAL,
              type: t.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "RecipientInfo.version",
                  tagClass: t.Class.UNIVERSAL,
                  type: t.Type.INTEGER,
                  constructed: !1,
                  capture: "version",
                },
                {
                  name: "RecipientInfo.issuerAndSerial",
                  tagClass: t.Class.UNIVERSAL,
                  type: t.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "RecipientInfo.issuerAndSerial.issuer",
                      tagClass: t.Class.UNIVERSAL,
                      type: t.Type.SEQUENCE,
                      constructed: !0,
                      captureAsn1: "issuer",
                    },
                    {
                      name: "RecipientInfo.issuerAndSerial.serialNumber",
                      tagClass: t.Class.UNIVERSAL,
                      type: t.Type.INTEGER,
                      constructed: !1,
                      capture: "serial",
                    },
                  ],
                },
                {
                  name: "RecipientInfo.keyEncryptionAlgorithm",
                  tagClass: t.Class.UNIVERSAL,
                  type: t.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "RecipientInfo.keyEncryptionAlgorithm.algorithm",
                      tagClass: t.Class.UNIVERSAL,
                      type: t.Type.OID,
                      constructed: !1,
                      capture: "encAlgorithm",
                    },
                    {
                      name: "RecipientInfo.keyEncryptionAlgorithm.parameter",
                      tagClass: t.Class.UNIVERSAL,
                      constructed: !1,
                      captureAsn1: "encParameter",
                    },
                  ],
                },
                {
                  name: "RecipientInfo.encryptedKey",
                  tagClass: t.Class.UNIVERSAL,
                  type: t.Type.OCTETSTRING,
                  constructed: !1,
                  capture: "encKey",
                },
              ],
            });
        }
        var i = "pkcs7asn1";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pkcs7asn1",
          ["require", "module", "./asn1", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          e.mgf = e.mgf || {};
          var t = (e.mgf.mgf1 = e.mgf1 = e.mgf1 || {});
          t.create = function (t) {
            var r = {
              generate: function (r, i) {
                for (
                  var n = new e.util.ByteBuffer(),
                    s = Math.ceil(i / t.digestLength),
                    a = 0;
                  a < s;
                  a++
                ) {
                  var o = new e.util.ByteBuffer();
                  o.putInt32(a),
                    t.start(),
                    t.update(r + o.getBytes()),
                    n.putBuffer(t.digest());
                }
                return n.truncate(n.length() - i), n.getBytes();
              },
            };
            return r;
          };
        }
        var i = "mgf1";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/mgf1", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          (e.mgf = e.mgf || {}), (e.mgf.mgf1 = e.mgf1);
        }
        var i = "mgf";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/mgf", ["require", "module", "./mgf1"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          var t = (e.pss = e.pss || {});
          t.create = function (t) {
            3 === arguments.length &&
              (t = {
                md: arguments[0],
                mgf: arguments[1],
                saltLength: arguments[2],
              });
            var r = t.md,
              i = t.mgf,
              n = r.digestLength,
              s = t.salt || null;
            "string" == typeof s && (s = e.util.createBuffer(s));
            var a;
            if ("saltLength" in t) a = t.saltLength;
            else {
              if (null === s)
                throw new Error(
                  "Salt length not specified or specific salt not given."
                );
              a = s.length();
            }
            if (null !== s && s.length() !== a)
              throw new Error(
                "Given salt length does not match length of given salt."
              );
            var o = t.prng || e.random,
              c = {};
            return (
              (c.encode = function (t, c) {
                var l,
                  u = c - 1,
                  p = Math.ceil(u / 8),
                  h = t.digest().getBytes();
                if (p < n + a + 2)
                  throw new Error("Message is too long to encrypt.");
                var f;
                f = null === s ? o.getBytesSync(a) : s.bytes();
                var g = new e.util.ByteBuffer();
                g.fillWithByte(0, 8),
                  g.putBytes(h),
                  g.putBytes(f),
                  r.start(),
                  r.update(g.getBytes());
                var m = r.digest().getBytes(),
                  d = new e.util.ByteBuffer();
                d.fillWithByte(0, p - a - n - 2), d.putByte(1), d.putBytes(f);
                var y = d.getBytes(),
                  k = p - n - 1,
                  v = i.generate(m, k),
                  E = "";
                for (l = 0; l < k; l++)
                  E += String.fromCharCode(y.charCodeAt(l) ^ v.charCodeAt(l));
                var S = (65280 >> (8 * p - u)) & 255;
                return (
                  (E = String.fromCharCode(E.charCodeAt(0) & ~S) + E.substr(1)),
                  E + m + String.fromCharCode(188)
                );
              }),
              (c.verify = function (t, s, o) {
                var c,
                  l = o - 1,
                  u = Math.ceil(l / 8);
                if (((s = s.substr(-u)), u < n + a + 2))
                  throw new Error(
                    "Inconsistent parameters to PSS signature verification."
                  );
                if (188 !== s.charCodeAt(u - 1))
                  throw new Error("Encoded message does not end in 0xBC.");
                var p = u - n - 1,
                  h = s.substr(0, p),
                  f = s.substr(p, n),
                  g = (65280 >> (8 * u - l)) & 255;
                if (0 !== (h.charCodeAt(0) & g))
                  throw new Error("Bits beyond keysize not zero as expected.");
                var m = i.generate(f, p),
                  d = "";
                for (c = 0; c < p; c++)
                  d += String.fromCharCode(h.charCodeAt(c) ^ m.charCodeAt(c));
                d = String.fromCharCode(d.charCodeAt(0) & ~g) + d.substr(1);
                var y = u - n - a - 2;
                for (c = 0; c < y; c++)
                  if (0 !== d.charCodeAt(c))
                    throw new Error("Leftmost octets not zero as expected");
                if (1 !== d.charCodeAt(y))
                  throw new Error(
                    "Inconsistent PSS signature, 0x01 marker not found"
                  );
                var k = d.substr(-a),
                  v = new e.util.ByteBuffer();
                v.fillWithByte(0, 8),
                  v.putBytes(t),
                  v.putBytes(k),
                  r.start(),
                  r.update(v.getBytes());
                var E = r.digest().getBytes();
                return f === E;
              }),
              c
            );
          };
        }
        var i = "pss";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/pss", ["require", "module", "./random", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(e, t) {
            "string" == typeof t && (t = { shortName: t });
            for (
              var r, i = null, n = 0;
              null === i && n < e.attributes.length;
              ++n
            )
              (r = e.attributes[n]),
                t.type && t.type === r.type
                  ? (i = r)
                  : t.name && t.name === r.name
                  ? (i = r)
                  : t.shortName && t.shortName === r.shortName && (i = r);
            return i;
          }
          function r(t) {
            for (
              var r,
                i,
                n = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []),
                s = t.attributes,
                a = 0;
              a < s.length;
              ++a
            ) {
              r = s[a];
              var c = r.value,
                l = o.Type.PRINTABLESTRING;
              "valueTagClass" in r &&
                ((l = r.valueTagClass),
                l === o.Type.UTF8 && (c = e.util.encodeUtf8(c))),
                (i = o.create(o.Class.UNIVERSAL, o.Type.SET, !0, [
                  o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(r.type).getBytes()
                    ),
                    o.create(o.Class.UNIVERSAL, l, !1, c),
                  ]),
                ])),
                n.value.push(i);
            }
            return n;
          }
          function i(e) {
            for (var t, r = 0; r < e.length; ++r) {
              if (
                ((t = e[r]),
                "undefined" == typeof t.name &&
                  (t.type && t.type in c.oids
                    ? (t.name = c.oids[t.type])
                    : t.shortName &&
                      t.shortName in u &&
                      (t.name = c.oids[u[t.shortName]])),
                "undefined" == typeof t.type)
              ) {
                if (!(t.name && t.name in c.oids)) {
                  var i = new Error("Attribute type not specified.");
                  throw ((i.attribute = t), i);
                }
                t.type = c.oids[t.name];
              }
              if (
                ("undefined" == typeof t.shortName &&
                  t.name &&
                  t.name in u &&
                  (t.shortName = u[t.name]),
                t.type === l.extensionRequest &&
                  ((t.valueConstructed = !0),
                  (t.valueTagClass = o.Type.SEQUENCE),
                  !t.value && t.extensions))
              ) {
                t.value = [];
                for (var s = 0; s < t.extensions.length; ++s)
                  t.value.push(
                    c.certificateExtensionToAsn1(n(t.extensions[s]))
                  );
              }
              if ("undefined" == typeof t.value) {
                var i = new Error("Attribute value not specified.");
                throw ((i.attribute = t), i);
              }
            }
          }
          function n(t, r) {
            if (
              ((r = r || {}),
              "undefined" == typeof t.name &&
                t.id &&
                t.id in c.oids &&
                (t.name = c.oids[t.id]),
              "undefined" == typeof t.id)
            ) {
              if (!(t.name && t.name in c.oids)) {
                var i = new Error("Extension ID not specified.");
                throw ((i.extension = t), i);
              }
              t.id = c.oids[t.name];
            }
            if ("undefined" != typeof t.value) return t;
            if ("keyUsage" === t.name) {
              var n = 0,
                s = 0,
                a = 0;
              t.digitalSignature && ((s |= 128), (n = 7)),
                t.nonRepudiation && ((s |= 64), (n = 6)),
                t.keyEncipherment && ((s |= 32), (n = 5)),
                t.dataEncipherment && ((s |= 16), (n = 4)),
                t.keyAgreement && ((s |= 8), (n = 3)),
                t.keyCertSign && ((s |= 4), (n = 2)),
                t.cRLSign && ((s |= 2), (n = 1)),
                t.encipherOnly && ((s |= 1), (n = 0)),
                t.decipherOnly && ((a |= 128), (n = 7));
              var u = String.fromCharCode(n);
              0 !== a
                ? (u += String.fromCharCode(s) + String.fromCharCode(a))
                : 0 !== s && (u += String.fromCharCode(s)),
                (t.value = o.create(
                  o.Class.UNIVERSAL,
                  o.Type.BITSTRING,
                  !1,
                  u
                ));
            } else if ("basicConstraints" === t.name)
              (t.value = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [])),
                t.cA &&
                  t.value.value.push(
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.BOOLEAN,
                      !1,
                      String.fromCharCode(255)
                    )
                  ),
                "pathLenConstraint" in t &&
                  t.value.value.push(
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.INTEGER,
                      !1,
                      o.integerToDer(t.pathLenConstraint).getBytes()
                    )
                  );
            else if ("extKeyUsage" === t.name) {
              t.value = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []);
              var p = t.value.value;
              for (var h in t)
                t[h] === !0 &&
                  (h in l
                    ? p.push(
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.OID,
                          !1,
                          o.oidToDer(l[h]).getBytes()
                        )
                      )
                    : h.indexOf(".") !== -1 &&
                      p.push(
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.OID,
                          !1,
                          o.oidToDer(h).getBytes()
                        )
                      ));
            } else if ("nsCertType" === t.name) {
              var n = 0,
                s = 0;
              t.client && ((s |= 128), (n = 7)),
                t.server && ((s |= 64), (n = 6)),
                t.email && ((s |= 32), (n = 5)),
                t.objsign && ((s |= 16), (n = 4)),
                t.reserved && ((s |= 8), (n = 3)),
                t.sslCA && ((s |= 4), (n = 2)),
                t.emailCA && ((s |= 2), (n = 1)),
                t.objCA && ((s |= 1), (n = 0));
              var u = String.fromCharCode(n);
              0 !== s && (u += String.fromCharCode(s)),
                (t.value = o.create(
                  o.Class.UNIVERSAL,
                  o.Type.BITSTRING,
                  !1,
                  u
                ));
            } else if (
              "subjectAltName" === t.name ||
              "issuerAltName" === t.name
            ) {
              t.value = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []);
              for (var f, g = 0; g < t.altNames.length; ++g) {
                f = t.altNames[g];
                var u = f.value;
                if (7 === f.type && f.ip) {
                  if (((u = e.util.bytesFromIP(f.ip)), null === u)) {
                    var i = new Error(
                      'Extension "ip" value is not a valid IPv4 or IPv6 address.'
                    );
                    throw ((i.extension = t), i);
                  }
                } else
                  8 === f.type &&
                    (u = f.oid ? o.oidToDer(o.oidToDer(f.oid)) : o.oidToDer(u));
                t.value.value.push(
                  o.create(o.Class.CONTEXT_SPECIFIC, f.type, !1, u)
                );
              }
            } else if ("subjectKeyIdentifier" === t.name && r.cert) {
              var m = r.cert.generateSubjectKeyIdentifier();
              (t.subjectKeyIdentifier = m.toHex()),
                (t.value = o.create(
                  o.Class.UNIVERSAL,
                  o.Type.OCTETSTRING,
                  !1,
                  m.getBytes()
                ));
            }
            if ("undefined" == typeof t.value) {
              var i = new Error("Extension value not specified.");
              throw ((i.extension = t), i);
            }
            return t;
          }
          function s(e, t) {
            switch (e) {
              case l["RSASSA-PSS"]:
                var r = [];
                return (
                  void 0 !== t.hash.algorithmOid &&
                    r.push(
                      o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                        o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                          o.create(
                            o.Class.UNIVERSAL,
                            o.Type.OID,
                            !1,
                            o.oidToDer(t.hash.algorithmOid).getBytes()
                          ),
                          o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, ""),
                        ]),
                      ])
                    ),
                  void 0 !== t.mgf.algorithmOid &&
                    r.push(
                      o.create(o.Class.CONTEXT_SPECIFIC, 1, !0, [
                        o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                          o.create(
                            o.Class.UNIVERSAL,
                            o.Type.OID,
                            !1,
                            o.oidToDer(t.mgf.algorithmOid).getBytes()
                          ),
                          o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                            o.create(
                              o.Class.UNIVERSAL,
                              o.Type.OID,
                              !1,
                              o.oidToDer(t.mgf.hash.algorithmOid).getBytes()
                            ),
                            o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, ""),
                          ]),
                        ]),
                      ])
                    ),
                  void 0 !== t.saltLength &&
                    r.push(
                      o.create(o.Class.CONTEXT_SPECIFIC, 2, !0, [
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.INTEGER,
                          !1,
                          o.integerToDer(t.saltLength).getBytes()
                        ),
                      ])
                    ),
                  o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, r)
                );
              default:
                return o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, "");
            }
          }
          function a(t) {
            var r = o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, []);
            if (0 === t.attributes.length) return r;
            for (var i = t.attributes, n = 0; n < i.length; ++n) {
              var s = i[n],
                a = s.value,
                c = o.Type.UTF8;
              "valueTagClass" in s && (c = s.valueTagClass),
                c === o.Type.UTF8 && (a = e.util.encodeUtf8(a));
              var l = !1;
              "valueConstructed" in s && (l = s.valueConstructed);
              var u = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.OID,
                  !1,
                  o.oidToDer(s.type).getBytes()
                ),
                o.create(o.Class.UNIVERSAL, o.Type.SET, !0, [
                  o.create(o.Class.UNIVERSAL, c, l, a),
                ]),
              ]);
              r.value.push(u);
            }
            return r;
          }
          var o = e.asn1,
            c = (e.pki = e.pki || {}),
            l = c.oids,
            u = {};
          (u.CN = l.commonName),
            (u.commonName = "CN"),
            (u.C = l.countryName),
            (u.countryName = "C"),
            (u.L = l.localityName),
            (u.localityName = "L"),
            (u.ST = l.stateOrProvinceName),
            (u.stateOrProvinceName = "ST"),
            (u.O = l.organizationName),
            (u.organizationName = "O"),
            (u.OU = l.organizationalUnitName),
            (u.organizationalUnitName = "OU"),
            (u.E = l.emailAddress),
            (u.emailAddress = "E");
          var p = e.pki.rsa.publicKeyValidator,
            h = {
              name: "Certificate",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "Certificate.TBSCertificate",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  captureAsn1: "tbsCertificate",
                  value: [
                    {
                      name: "Certificate.TBSCertificate.version",
                      tagClass: o.Class.CONTEXT_SPECIFIC,
                      type: 0,
                      constructed: !0,
                      optional: !0,
                      value: [
                        {
                          name: "Certificate.TBSCertificate.version.integer",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.INTEGER,
                          constructed: !1,
                          capture: "certVersion",
                        },
                      ],
                    },
                    {
                      name: "Certificate.TBSCertificate.serialNumber",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.INTEGER,
                      constructed: !1,
                      capture: "certSerialNumber",
                    },
                    {
                      name: "Certificate.TBSCertificate.signature",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      value: [
                        {
                          name: "Certificate.TBSCertificate.signature.algorithm",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.OID,
                          constructed: !1,
                          capture: "certinfoSignatureOid",
                        },
                        {
                          name: "Certificate.TBSCertificate.signature.parameters",
                          tagClass: o.Class.UNIVERSAL,
                          optional: !0,
                          captureAsn1: "certinfoSignatureParams",
                        },
                      ],
                    },
                    {
                      name: "Certificate.TBSCertificate.issuer",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      captureAsn1: "certIssuer",
                    },
                    {
                      name: "Certificate.TBSCertificate.validity",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      value: [
                        {
                          name: "Certificate.TBSCertificate.validity.notBefore (utc)",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.UTCTIME,
                          constructed: !1,
                          optional: !0,
                          capture: "certValidity1UTCTime",
                        },
                        {
                          name: "Certificate.TBSCertificate.validity.notBefore (generalized)",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.GENERALIZEDTIME,
                          constructed: !1,
                          optional: !0,
                          capture: "certValidity2GeneralizedTime",
                        },
                        {
                          name: "Certificate.TBSCertificate.validity.notAfter (utc)",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.UTCTIME,
                          constructed: !1,
                          optional: !0,
                          capture: "certValidity3UTCTime",
                        },
                        {
                          name: "Certificate.TBSCertificate.validity.notAfter (generalized)",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.GENERALIZEDTIME,
                          constructed: !1,
                          optional: !0,
                          capture: "certValidity4GeneralizedTime",
                        },
                      ],
                    },
                    {
                      name: "Certificate.TBSCertificate.subject",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      captureAsn1: "certSubject",
                    },
                    p,
                    {
                      name: "Certificate.TBSCertificate.issuerUniqueID",
                      tagClass: o.Class.CONTEXT_SPECIFIC,
                      type: 1,
                      constructed: !0,
                      optional: !0,
                      value: [
                        {
                          name: "Certificate.TBSCertificate.issuerUniqueID.id",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.BITSTRING,
                          constructed: !1,
                          capture: "certIssuerUniqueId",
                        },
                      ],
                    },
                    {
                      name: "Certificate.TBSCertificate.subjectUniqueID",
                      tagClass: o.Class.CONTEXT_SPECIFIC,
                      type: 2,
                      constructed: !0,
                      optional: !0,
                      value: [
                        {
                          name: "Certificate.TBSCertificate.subjectUniqueID.id",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.BITSTRING,
                          constructed: !1,
                          capture: "certSubjectUniqueId",
                        },
                      ],
                    },
                    {
                      name: "Certificate.TBSCertificate.extensions",
                      tagClass: o.Class.CONTEXT_SPECIFIC,
                      type: 3,
                      constructed: !0,
                      captureAsn1: "certExtensions",
                      optional: !0,
                    },
                  ],
                },
                {
                  name: "Certificate.signatureAlgorithm",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "Certificate.signatureAlgorithm.algorithm",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.OID,
                      constructed: !1,
                      capture: "certSignatureOid",
                    },
                    {
                      name: "Certificate.TBSCertificate.signature.parameters",
                      tagClass: o.Class.UNIVERSAL,
                      optional: !0,
                      captureAsn1: "certSignatureParams",
                    },
                  ],
                },
                {
                  name: "Certificate.signatureValue",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.BITSTRING,
                  constructed: !1,
                  capture: "certSignature",
                },
              ],
            },
            f = {
              name: "rsapss",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "rsapss.hashAlgorithm",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  type: 0,
                  constructed: !0,
                  value: [
                    {
                      name: "rsapss.hashAlgorithm.AlgorithmIdentifier",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Class.SEQUENCE,
                      constructed: !0,
                      optional: !0,
                      value: [
                        {
                          name: "rsapss.hashAlgorithm.AlgorithmIdentifier.algorithm",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.OID,
                          constructed: !1,
                          capture: "hashOid",
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "rsapss.maskGenAlgorithm",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  type: 1,
                  constructed: !0,
                  value: [
                    {
                      name: "rsapss.maskGenAlgorithm.AlgorithmIdentifier",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Class.SEQUENCE,
                      constructed: !0,
                      optional: !0,
                      value: [
                        {
                          name: "rsapss.maskGenAlgorithm.AlgorithmIdentifier.algorithm",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.OID,
                          constructed: !1,
                          capture: "maskGenOid",
                        },
                        {
                          name: "rsapss.maskGenAlgorithm.AlgorithmIdentifier.params",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.SEQUENCE,
                          constructed: !0,
                          value: [
                            {
                              name: "rsapss.maskGenAlgorithm.AlgorithmIdentifier.params.algorithm",
                              tagClass: o.Class.UNIVERSAL,
                              type: o.Type.OID,
                              constructed: !1,
                              capture: "maskGenHashOid",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "rsapss.saltLength",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  type: 2,
                  optional: !0,
                  value: [
                    {
                      name: "rsapss.saltLength.saltLength",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Class.INTEGER,
                      constructed: !1,
                      capture: "saltLength",
                    },
                  ],
                },
                {
                  name: "rsapss.trailerField",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  type: 3,
                  optional: !0,
                  value: [
                    {
                      name: "rsapss.trailer.trailer",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Class.INTEGER,
                      constructed: !1,
                      capture: "trailer",
                    },
                  ],
                },
              ],
            },
            g = {
              name: "CertificationRequestInfo",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              captureAsn1: "certificationRequestInfo",
              value: [
                {
                  name: "CertificationRequestInfo.integer",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "certificationRequestInfoVersion",
                },
                {
                  name: "CertificationRequestInfo.subject",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  captureAsn1: "certificationRequestInfoSubject",
                },
                p,
                {
                  name: "CertificationRequestInfo.attributes",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  type: 0,
                  constructed: !0,
                  optional: !0,
                  capture: "certificationRequestInfoAttributes",
                  value: [
                    {
                      name: "CertificationRequestInfo.attributes",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      value: [
                        {
                          name: "CertificationRequestInfo.attributes.type",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.OID,
                          constructed: !1,
                        },
                        {
                          name: "CertificationRequestInfo.attributes.value",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.SET,
                          constructed: !0,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            m = {
              name: "CertificationRequest",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              captureAsn1: "csr",
              value: [
                g,
                {
                  name: "CertificationRequest.signatureAlgorithm",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  value: [
                    {
                      name: "CertificationRequest.signatureAlgorithm.algorithm",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.OID,
                      constructed: !1,
                      capture: "csrSignatureOid",
                    },
                    {
                      name: "CertificationRequest.signatureAlgorithm.parameters",
                      tagClass: o.Class.UNIVERSAL,
                      optional: !0,
                      captureAsn1: "csrSignatureParams",
                    },
                  ],
                },
                {
                  name: "CertificationRequest.signature",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.BITSTRING,
                  constructed: !1,
                  capture: "csrSignature",
                },
              ],
            };
          (c.RDNAttributesAsArray = function (e, t) {
            for (var r, i, n, s = [], a = 0; a < e.value.length; ++a) {
              r = e.value[a];
              for (var c = 0; c < r.value.length; ++c)
                (n = {}),
                  (i = r.value[c]),
                  (n.type = o.derToOid(i.value[0].value)),
                  (n.value = i.value[1].value),
                  (n.valueTagClass = i.value[1].type),
                  n.type in l &&
                    ((n.name = l[n.type]),
                    n.name in u && (n.shortName = u[n.name])),
                  t && (t.update(n.type), t.update(n.value)),
                  s.push(n);
            }
            return s;
          }),
            (c.CRIAttributesAsArray = function (e) {
              for (var t = [], r = 0; r < e.length; ++r)
                for (
                  var i = e[r],
                    n = o.derToOid(i.value[0].value),
                    s = i.value[1].value,
                    a = 0;
                  a < s.length;
                  ++a
                ) {
                  var p = {};
                  if (
                    ((p.type = n),
                    (p.value = s[a].value),
                    (p.valueTagClass = s[a].type),
                    p.type in l &&
                      ((p.name = l[p.type]),
                      p.name in u && (p.shortName = u[p.name])),
                    p.type === l.extensionRequest)
                  ) {
                    p.extensions = [];
                    for (var h = 0; h < p.value.length; ++h)
                      p.extensions.push(
                        c.certificateExtensionFromAsn1(p.value[h])
                      );
                  }
                  t.push(p);
                }
              return t;
            });
          var d = function (e, t, r) {
            var i = {};
            if (e !== l["RSASSA-PSS"]) return i;
            r &&
              (i = {
                hash: { algorithmOid: l.sha1 },
                mgf: { algorithmOid: l.mgf1, hash: { algorithmOid: l.sha1 } },
                saltLength: 20,
              });
            var n = {},
              s = [];
            if (!o.validate(t, f, n, s)) {
              var a = new Error("Cannot read RSASSA-PSS parameter block.");
              throw ((a.errors = s), a);
            }
            return (
              void 0 !== n.hashOid &&
                ((i.hash = i.hash || {}),
                (i.hash.algorithmOid = o.derToOid(n.hashOid))),
              void 0 !== n.maskGenOid &&
                ((i.mgf = i.mgf || {}),
                (i.mgf.algorithmOid = o.derToOid(n.maskGenOid)),
                (i.mgf.hash = i.mgf.hash || {}),
                (i.mgf.hash.algorithmOid = o.derToOid(n.maskGenHashOid))),
              void 0 !== n.saltLength &&
                (i.saltLength = n.saltLength.charCodeAt(0)),
              i
            );
          };
          (c.certificateFromPem = function (t, r, i) {
            var n = e.pem.decode(t)[0];
            if (
              "CERTIFICATE" !== n.type &&
              "X509 CERTIFICATE" !== n.type &&
              "TRUSTED CERTIFICATE" !== n.type
            ) {
              var s = new Error(
                'Could not convert certificate from PEM; PEM header type is not "CERTIFICATE", "X509 CERTIFICATE", or "TRUSTED CERTIFICATE".'
              );
              throw ((s.headerType = n.type), s);
            }
            if (n.procType && "ENCRYPTED" === n.procType.type)
              throw new Error(
                "Could not convert certificate from PEM; PEM is encrypted."
              );
            var a = o.fromDer(n.body, i);
            return c.certificateFromAsn1(a, r);
          }),
            (c.certificateToPem = function (t, r) {
              var i = {
                type: "CERTIFICATE",
                body: o.toDer(c.certificateToAsn1(t)).getBytes(),
              };
              return e.pem.encode(i, { maxline: r });
            }),
            (c.publicKeyFromPem = function (t) {
              var r = e.pem.decode(t)[0];
              if ("PUBLIC KEY" !== r.type && "RSA PUBLIC KEY" !== r.type) {
                var i = new Error(
                  'Could not convert public key from PEM; PEM header type is not "PUBLIC KEY" or "RSA PUBLIC KEY".'
                );
                throw ((i.headerType = r.type), i);
              }
              if (r.procType && "ENCRYPTED" === r.procType.type)
                throw new Error(
                  "Could not convert public key from PEM; PEM is encrypted."
                );
              var n = o.fromDer(r.body);
              return c.publicKeyFromAsn1(n);
            }),
            (c.publicKeyToPem = function (t, r) {
              var i = {
                type: "PUBLIC KEY",
                body: o.toDer(c.publicKeyToAsn1(t)).getBytes(),
              };
              return e.pem.encode(i, { maxline: r });
            }),
            (c.publicKeyToRSAPublicKeyPem = function (t, r) {
              var i = {
                type: "RSA PUBLIC KEY",
                body: o.toDer(c.publicKeyToRSAPublicKey(t)).getBytes(),
              };
              return e.pem.encode(i, { maxline: r });
            }),
            (c.getPublicKeyFingerprint = function (t, r) {
              r = r || {};
              var i,
                n = r.md || e.md.sha1.create(),
                s = r.type || "RSAPublicKey";
              switch (s) {
                case "RSAPublicKey":
                  i = o.toDer(c.publicKeyToRSAPublicKey(t)).getBytes();
                  break;
                case "SubjectPublicKeyInfo":
                  i = o.toDer(c.publicKeyToAsn1(t)).getBytes();
                  break;
                default:
                  throw new Error('Unknown fingerprint type "' + r.type + '".');
              }
              n.start(), n.update(i);
              var a = n.digest();
              if ("hex" === r.encoding) {
                var l = a.toHex();
                return r.delimiter ? l.match(/.{2}/g).join(r.delimiter) : l;
              }
              if ("binary" === r.encoding) return a.getBytes();
              if (r.encoding)
                throw new Error('Unknown encoding "' + r.encoding + '".');
              return a;
            }),
            (c.certificationRequestFromPem = function (t, r, i) {
              var n = e.pem.decode(t)[0];
              if ("CERTIFICATE REQUEST" !== n.type) {
                var s = new Error(
                  'Could not convert certification request from PEM; PEM header type is not "CERTIFICATE REQUEST".'
                );
                throw ((s.headerType = n.type), s);
              }
              if (n.procType && "ENCRYPTED" === n.procType.type)
                throw new Error(
                  "Could not convert certification request from PEM; PEM is encrypted."
                );
              var a = o.fromDer(n.body, i);
              return c.certificationRequestFromAsn1(a, r);
            }),
            (c.certificationRequestToPem = function (t, r) {
              var i = {
                type: "CERTIFICATE REQUEST",
                body: o.toDer(c.certificationRequestToAsn1(t)).getBytes(),
              };
              return e.pem.encode(i, { maxline: r });
            }),
            (c.createCertificate = function () {
              var r = {};
              return (
                (r.version = 2),
                (r.serialNumber = "00"),
                (r.signatureOid = null),
                (r.signature = null),
                (r.siginfo = {}),
                (r.siginfo.algorithmOid = null),
                (r.validity = {}),
                (r.validity.notBefore = new Date()),
                (r.validity.notAfter = new Date()),
                (r.issuer = {}),
                (r.issuer.getField = function (e) {
                  return t(r.issuer, e);
                }),
                (r.issuer.addField = function (e) {
                  i([e]), r.issuer.attributes.push(e);
                }),
                (r.issuer.attributes = []),
                (r.issuer.hash = null),
                (r.subject = {}),
                (r.subject.getField = function (e) {
                  return t(r.subject, e);
                }),
                (r.subject.addField = function (e) {
                  i([e]), r.subject.attributes.push(e);
                }),
                (r.subject.attributes = []),
                (r.subject.hash = null),
                (r.extensions = []),
                (r.publicKey = null),
                (r.md = null),
                (r.setSubject = function (e, t) {
                  i(e),
                    (r.subject.attributes = e),
                    delete r.subject.uniqueId,
                    t && (r.subject.uniqueId = t),
                    (r.subject.hash = null);
                }),
                (r.setIssuer = function (e, t) {
                  i(e),
                    (r.issuer.attributes = e),
                    delete r.issuer.uniqueId,
                    t && (r.issuer.uniqueId = t),
                    (r.issuer.hash = null);
                }),
                (r.setExtensions = function (e) {
                  for (var t = 0; t < e.length; ++t) n(e[t], { cert: r });
                  r.extensions = e;
                }),
                (r.getExtension = function (e) {
                  "string" == typeof e && (e = { name: e });
                  for (
                    var t, i = null, n = 0;
                    null === i && n < r.extensions.length;
                    ++n
                  )
                    (t = r.extensions[n]),
                      e.id && t.id === e.id
                        ? (i = t)
                        : e.name && t.name === e.name && (i = t);
                  return i;
                }),
                (r.sign = function (t, i) {
                  r.md = i || e.md.sha1.create();
                  var n = l[r.md.algorithm + "WithRSAEncryption"];
                  if (!n) {
                    var s = new Error(
                      "Could not compute certificate digest. Unknown message digest algorithm OID."
                    );
                    throw ((s.algorithm = r.md.algorithm), s);
                  }
                  (r.signatureOid = r.siginfo.algorithmOid = n),
                    (r.tbsCertificate = c.getTBSCertificate(r));
                  var a = o.toDer(r.tbsCertificate);
                  r.md.update(a.getBytes()), (r.signature = t.sign(r.md));
                }),
                (r.verify = function (t) {
                  var i = !1;
                  if (!r.issued(t)) {
                    var n = t.issuer,
                      s = r.subject,
                      a = new Error(
                        "The parent certificate did not issue the given child certificate; the child certificate's issuer does not match the parent's subject."
                      );
                    throw (
                      ((a.expectedIssuer = n.attributes),
                      (a.actualIssuer = s.attributes),
                      a)
                    );
                  }
                  var u = t.md;
                  if (null === u) {
                    if (t.signatureOid in l) {
                      var p = l[t.signatureOid];
                      switch (p) {
                        case "sha1WithRSAEncryption":
                          u = e.md.sha1.create();
                          break;
                        case "md5WithRSAEncryption":
                          u = e.md.md5.create();
                          break;
                        case "sha256WithRSAEncryption":
                          u = e.md.sha256.create();
                          break;
                        case "sha512WithRSAEncryption":
                          u = e.md.sha512.create();
                          break;
                        case "RSASSA-PSS":
                          u = e.md.sha256.create();
                      }
                    }
                    if (null === u) {
                      var a = new Error(
                        "Could not compute certificate digest. Unknown signature OID."
                      );
                      throw ((a.signatureOid = t.signatureOid), a);
                    }
                    var h = t.tbsCertificate || c.getTBSCertificate(t),
                      f = o.toDer(h);
                    u.update(f.getBytes());
                  }
                  if (null !== u) {
                    var g;
                    switch (t.signatureOid) {
                      case l.sha1WithRSAEncryption:
                        g = void 0;
                        break;
                      case l["RSASSA-PSS"]:
                        var m, d;
                        if (
                          ((m = l[t.signatureParameters.mgf.hash.algorithmOid]),
                          void 0 === m || void 0 === e.md[m])
                        ) {
                          var a = new Error("Unsupported MGF hash function.");
                          throw (
                            ((a.oid =
                              t.signatureParameters.mgf.hash.algorithmOid),
                            (a.name = m),
                            a)
                          );
                        }
                        if (
                          ((d = l[t.signatureParameters.mgf.algorithmOid]),
                          void 0 === d || void 0 === e.mgf[d])
                        ) {
                          var a = new Error("Unsupported MGF function.");
                          throw (
                            ((a.oid = t.signatureParameters.mgf.algorithmOid),
                            (a.name = d),
                            a)
                          );
                        }
                        if (
                          ((d = e.mgf[d].create(e.md[m].create())),
                          (m = l[t.signatureParameters.hash.algorithmOid]),
                          void 0 === m || void 0 === e.md[m])
                        )
                          throw {
                            message: "Unsupported RSASSA-PSS hash function.",
                            oid: t.signatureParameters.hash.algorithmOid,
                            name: m,
                          };
                        g = e.pss.create(
                          e.md[m].create(),
                          d,
                          t.signatureParameters.saltLength
                        );
                    }
                    i = r.publicKey.verify(
                      u.digest().getBytes(),
                      t.signature,
                      g
                    );
                  }
                  return i;
                }),
                (r.isIssuer = function (e) {
                  var t = !1,
                    i = r.issuer,
                    n = e.subject;
                  if (i.hash && n.hash) t = i.hash === n.hash;
                  else if (i.attributes.length === n.attributes.length) {
                    t = !0;
                    for (var s, a, o = 0; t && o < i.attributes.length; ++o)
                      (s = i.attributes[o]),
                        (a = n.attributes[o]),
                        (s.type === a.type && s.value === a.value) || (t = !1);
                  }
                  return t;
                }),
                (r.issued = function (e) {
                  return e.isIssuer(r);
                }),
                (r.generateSubjectKeyIdentifier = function () {
                  return c.getPublicKeyFingerprint(r.publicKey, {
                    type: "RSAPublicKey",
                  });
                }),
                (r.verifySubjectKeyIdentifier = function () {
                  for (
                    var t = l.subjectKeyIdentifier, i = 0;
                    i < r.extensions.length;
                    ++i
                  ) {
                    var n = r.extensions[i];
                    if (n.id === t) {
                      var s = r.generateSubjectKeyIdentifier().getBytes();
                      return e.util.hexToBytes(n.subjectKeyIdentifier) === s;
                    }
                  }
                  return !1;
                }),
                r
              );
            }),
            (c.certificateFromAsn1 = function (r, n) {
              var s = {},
                a = [];
              if (!o.validate(r, h, s, a)) {
                var u = new Error(
                  "Cannot read X.509 certificate. ASN.1 object is not an X509v3 Certificate."
                );
                throw ((u.errors = a), u);
              }
              if ("string" != typeof s.certSignature) {
                for (var p = "\0", f = 0; f < s.certSignature.length; ++f)
                  p += o.toDer(s.certSignature[f]).getBytes();
                s.certSignature = p;
              }
              var g = o.derToOid(s.publicKeyOid);
              if (g !== c.oids.rsaEncryption)
                throw new Error("Cannot read public key. OID is not RSA.");
              var m = c.createCertificate();
              m.version = s.certVersion ? s.certVersion.charCodeAt(0) : 0;
              var y = e.util.createBuffer(s.certSerialNumber);
              (m.serialNumber = y.toHex()),
                (m.signatureOid = e.asn1.derToOid(s.certSignatureOid)),
                (m.signatureParameters = d(
                  m.signatureOid,
                  s.certSignatureParams,
                  !0
                )),
                (m.siginfo.algorithmOid = e.asn1.derToOid(
                  s.certinfoSignatureOid
                )),
                (m.siginfo.parameters = d(
                  m.siginfo.algorithmOid,
                  s.certinfoSignatureParams,
                  !1
                ));
              var k = e.util.createBuffer(s.certSignature);
              ++k.read, (m.signature = k.getBytes());
              var v = [];
              if (
                (void 0 !== s.certValidity1UTCTime &&
                  v.push(o.utcTimeToDate(s.certValidity1UTCTime)),
                void 0 !== s.certValidity2GeneralizedTime &&
                  v.push(
                    o.generalizedTimeToDate(s.certValidity2GeneralizedTime)
                  ),
                void 0 !== s.certValidity3UTCTime &&
                  v.push(o.utcTimeToDate(s.certValidity3UTCTime)),
                void 0 !== s.certValidity4GeneralizedTime &&
                  v.push(
                    o.generalizedTimeToDate(s.certValidity4GeneralizedTime)
                  ),
                v.length > 2)
              )
                throw new Error(
                  "Cannot read notBefore/notAfter validity times; more than two times were provided in the certificate."
                );
              if (v.length < 2)
                throw new Error(
                  "Cannot read notBefore/notAfter validity times; they were not provided as either UTCTime or GeneralizedTime."
                );
              if (
                ((m.validity.notBefore = v[0]),
                (m.validity.notAfter = v[1]),
                (m.tbsCertificate = s.tbsCertificate),
                n)
              ) {
                if (((m.md = null), m.signatureOid in l)) {
                  var g = l[m.signatureOid];
                  switch (g) {
                    case "sha1WithRSAEncryption":
                      m.md = e.md.sha1.create();
                      break;
                    case "md5WithRSAEncryption":
                      m.md = e.md.md5.create();
                      break;
                    case "sha256WithRSAEncryption":
                      m.md = e.md.sha256.create();
                      break;
                    case "sha512WithRSAEncryption":
                      m.md = e.md.sha512.create();
                      break;
                    case "RSASSA-PSS":
                      m.md = e.md.sha256.create();
                  }
                }
                if (null === m.md) {
                  var u = new Error(
                    "Could not compute certificate digest. Unknown signature OID."
                  );
                  throw ((u.signatureOid = m.signatureOid), u);
                }
                var E = o.toDer(m.tbsCertificate);
                m.md.update(E.getBytes());
              }
              var S = e.md.sha1.create();
              (m.issuer.getField = function (e) {
                return t(m.issuer, e);
              }),
                (m.issuer.addField = function (e) {
                  i([e]), m.issuer.attributes.push(e);
                }),
                (m.issuer.attributes = c.RDNAttributesAsArray(s.certIssuer, S)),
                s.certIssuerUniqueId &&
                  (m.issuer.uniqueId = s.certIssuerUniqueId),
                (m.issuer.hash = S.digest().toHex());
              var _ = e.md.sha1.create();
              return (
                (m.subject.getField = function (e) {
                  return t(m.subject, e);
                }),
                (m.subject.addField = function (e) {
                  i([e]), m.subject.attributes.push(e);
                }),
                (m.subject.attributes = c.RDNAttributesAsArray(
                  s.certSubject,
                  _
                )),
                s.certSubjectUniqueId &&
                  (m.subject.uniqueId = s.certSubjectUniqueId),
                (m.subject.hash = _.digest().toHex()),
                s.certExtensions
                  ? (m.extensions = c.certificateExtensionsFromAsn1(
                      s.certExtensions
                    ))
                  : (m.extensions = []),
                (m.publicKey = c.publicKeyFromAsn1(s.subjectPublicKeyInfo)),
                m
              );
            }),
            (c.certificateExtensionsFromAsn1 = function (e) {
              for (var t = [], r = 0; r < e.value.length; ++r)
                for (var i = e.value[r], n = 0; n < i.value.length; ++n)
                  t.push(c.certificateExtensionFromAsn1(i.value[n]));
              return t;
            }),
            (c.certificateExtensionFromAsn1 = function (t) {
              var r = {};
              if (
                ((r.id = o.derToOid(t.value[0].value)),
                (r.critical = !1),
                t.value[1].type === o.Type.BOOLEAN
                  ? ((r.critical = 0 !== t.value[1].value.charCodeAt(0)),
                    (r.value = t.value[2].value))
                  : (r.value = t.value[1].value),
                r.id in l)
              )
                if (((r.name = l[r.id]), "keyUsage" === r.name)) {
                  var i = o.fromDer(r.value),
                    n = 0,
                    s = 0;
                  i.value.length > 1 &&
                    ((n = i.value.charCodeAt(1)),
                    (s = i.value.length > 2 ? i.value.charCodeAt(2) : 0)),
                    (r.digitalSignature = 128 === (128 & n)),
                    (r.nonRepudiation = 64 === (64 & n)),
                    (r.keyEncipherment = 32 === (32 & n)),
                    (r.dataEncipherment = 16 === (16 & n)),
                    (r.keyAgreement = 8 === (8 & n)),
                    (r.keyCertSign = 4 === (4 & n)),
                    (r.cRLSign = 2 === (2 & n)),
                    (r.encipherOnly = 1 === (1 & n)),
                    (r.decipherOnly = 128 === (128 & s));
                } else if ("basicConstraints" === r.name) {
                  var i = o.fromDer(r.value);
                  i.value.length > 0 && i.value[0].type === o.Type.BOOLEAN
                    ? (r.cA = 0 !== i.value[0].value.charCodeAt(0))
                    : (r.cA = !1);
                  var a = null;
                  i.value.length > 0 && i.value[0].type === o.Type.INTEGER
                    ? (a = i.value[0].value)
                    : i.value.length > 1 && (a = i.value[1].value),
                    null !== a && (r.pathLenConstraint = o.derToInteger(a));
                } else if ("extKeyUsage" === r.name)
                  for (
                    var i = o.fromDer(r.value), c = 0;
                    c < i.value.length;
                    ++c
                  ) {
                    var u = o.derToOid(i.value[c].value);
                    u in l ? (r[l[u]] = !0) : (r[u] = !0);
                  }
                else if ("nsCertType" === r.name) {
                  var i = o.fromDer(r.value),
                    n = 0;
                  i.value.length > 1 && (n = i.value.charCodeAt(1)),
                    (r.client = 128 === (128 & n)),
                    (r.server = 64 === (64 & n)),
                    (r.email = 32 === (32 & n)),
                    (r.objsign = 16 === (16 & n)),
                    (r.reserved = 8 === (8 & n)),
                    (r.sslCA = 4 === (4 & n)),
                    (r.emailCA = 2 === (2 & n)),
                    (r.objCA = 1 === (1 & n));
                } else if (
                  "subjectAltName" === r.name ||
                  "issuerAltName" === r.name
                ) {
                  r.altNames = [];
                  for (
                    var p, i = o.fromDer(r.value), h = 0;
                    h < i.value.length;
                    ++h
                  ) {
                    p = i.value[h];
                    var f = { type: p.type, value: p.value };
                    switch ((r.altNames.push(f), p.type)) {
                      case 1:
                      case 2:
                      case 6:
                        break;
                      case 7:
                        f.ip = e.util.bytesToIP(p.value);
                        break;
                      case 8:
                        f.oid = o.derToOid(p.value);
                    }
                  }
                } else if ("subjectKeyIdentifier" === r.name) {
                  var i = o.fromDer(r.value);
                  r.subjectKeyIdentifier = e.util.bytesToHex(i.value);
                }
              return r;
            }),
            (c.certificationRequestFromAsn1 = function (r, n) {
              var s = {},
                a = [];
              if (!o.validate(r, m, s, a)) {
                var u = new Error(
                  "Cannot read PKCS#10 certificate request. ASN.1 object is not a PKCS#10 CertificationRequest."
                );
                throw ((u.errors = a), u);
              }
              if ("string" != typeof s.csrSignature) {
                for (var p = "\0", h = 0; h < s.csrSignature.length; ++h)
                  p += o.toDer(s.csrSignature[h]).getBytes();
                s.csrSignature = p;
              }
              var f = o.derToOid(s.publicKeyOid);
              if (f !== c.oids.rsaEncryption)
                throw new Error("Cannot read public key. OID is not RSA.");
              var g = c.createCertificationRequest();
              (g.version = s.csrVersion ? s.csrVersion.charCodeAt(0) : 0),
                (g.signatureOid = e.asn1.derToOid(s.csrSignatureOid)),
                (g.signatureParameters = d(
                  g.signatureOid,
                  s.csrSignatureParams,
                  !0
                )),
                (g.siginfo.algorithmOid = e.asn1.derToOid(s.csrSignatureOid)),
                (g.siginfo.parameters = d(
                  g.siginfo.algorithmOid,
                  s.csrSignatureParams,
                  !1
                ));
              var y = e.util.createBuffer(s.csrSignature);
              if (
                (++y.read,
                (g.signature = y.getBytes()),
                (g.certificationRequestInfo = s.certificationRequestInfo),
                n)
              ) {
                if (((g.md = null), g.signatureOid in l)) {
                  var f = l[g.signatureOid];
                  switch (f) {
                    case "sha1WithRSAEncryption":
                      g.md = e.md.sha1.create();
                      break;
                    case "md5WithRSAEncryption":
                      g.md = e.md.md5.create();
                      break;
                    case "sha256WithRSAEncryption":
                      g.md = e.md.sha256.create();
                      break;
                    case "sha512WithRSAEncryption":
                      g.md = e.md.sha512.create();
                      break;
                    case "RSASSA-PSS":
                      g.md = e.md.sha256.create();
                  }
                }
                if (null === g.md) {
                  var u = new Error(
                    "Could not compute certification request digest. Unknown signature OID."
                  );
                  throw ((u.signatureOid = g.signatureOid), u);
                }
                var k = o.toDer(g.certificationRequestInfo);
                g.md.update(k.getBytes());
              }
              var v = e.md.sha1.create();
              return (
                (g.subject.getField = function (e) {
                  return t(g.subject, e);
                }),
                (g.subject.addField = function (e) {
                  i([e]), g.subject.attributes.push(e);
                }),
                (g.subject.attributes = c.RDNAttributesAsArray(
                  s.certificationRequestInfoSubject,
                  v
                )),
                (g.subject.hash = v.digest().toHex()),
                (g.publicKey = c.publicKeyFromAsn1(s.subjectPublicKeyInfo)),
                (g.getAttribute = function (e) {
                  return t(g, e);
                }),
                (g.addAttribute = function (e) {
                  i([e]), g.attributes.push(e);
                }),
                (g.attributes = c.CRIAttributesAsArray(
                  s.certificationRequestInfoAttributes || []
                )),
                g
              );
            }),
            (c.createCertificationRequest = function () {
              var r = {};
              return (
                (r.version = 0),
                (r.signatureOid = null),
                (r.signature = null),
                (r.siginfo = {}),
                (r.siginfo.algorithmOid = null),
                (r.subject = {}),
                (r.subject.getField = function (e) {
                  return t(r.subject, e);
                }),
                (r.subject.addField = function (e) {
                  i([e]), r.subject.attributes.push(e);
                }),
                (r.subject.attributes = []),
                (r.subject.hash = null),
                (r.publicKey = null),
                (r.attributes = []),
                (r.getAttribute = function (e) {
                  return t(r, e);
                }),
                (r.addAttribute = function (e) {
                  i([e]), r.attributes.push(e);
                }),
                (r.md = null),
                (r.setSubject = function (e) {
                  i(e), (r.subject.attributes = e), (r.subject.hash = null);
                }),
                (r.setAttributes = function (e) {
                  i(e), (r.attributes = e);
                }),
                (r.sign = function (t, i) {
                  r.md = i || e.md.sha1.create();
                  var n = l[r.md.algorithm + "WithRSAEncryption"];
                  if (!n) {
                    var s = new Error(
                      "Could not compute certification request digest. Unknown message digest algorithm OID."
                    );
                    throw ((s.algorithm = r.md.algorithm), s);
                  }
                  (r.signatureOid = r.siginfo.algorithmOid = n),
                    (r.certificationRequestInfo =
                      c.getCertificationRequestInfo(r));
                  var a = o.toDer(r.certificationRequestInfo);
                  r.md.update(a.getBytes()), (r.signature = t.sign(r.md));
                }),
                (r.verify = function () {
                  var t = !1,
                    i = r.md;
                  if (null === i) {
                    if (r.signatureOid in l) {
                      var n = l[r.signatureOid];
                      switch (n) {
                        case "sha1WithRSAEncryption":
                          i = e.md.sha1.create();
                          break;
                        case "md5WithRSAEncryption":
                          i = e.md.md5.create();
                          break;
                        case "sha256WithRSAEncryption":
                          i = e.md.sha256.create();
                          break;
                        case "sha512WithRSAEncryption":
                          i = e.md.sha512.create();
                          break;
                        case "RSASSA-PSS":
                          i = e.md.sha256.create();
                      }
                    }
                    if (null === i) {
                      var s = new Error(
                        "Could not compute certification request digest. Unknown signature OID."
                      );
                      throw ((s.signatureOid = r.signatureOid), s);
                    }
                    var a =
                        r.certificationRequestInfo ||
                        c.getCertificationRequestInfo(r),
                      u = o.toDer(a);
                    i.update(u.getBytes());
                  }
                  if (null !== i) {
                    var p;
                    switch (r.signatureOid) {
                      case l.sha1WithRSAEncryption:
                        break;
                      case l["RSASSA-PSS"]:
                        var h, f;
                        if (
                          ((h = l[r.signatureParameters.mgf.hash.algorithmOid]),
                          void 0 === h || void 0 === e.md[h])
                        ) {
                          var s = new Error("Unsupported MGF hash function.");
                          throw (
                            ((s.oid =
                              r.signatureParameters.mgf.hash.algorithmOid),
                            (s.name = h),
                            s)
                          );
                        }
                        if (
                          ((f = l[r.signatureParameters.mgf.algorithmOid]),
                          void 0 === f || void 0 === e.mgf[f])
                        ) {
                          var s = new Error("Unsupported MGF function.");
                          throw (
                            ((s.oid = r.signatureParameters.mgf.algorithmOid),
                            (s.name = f),
                            s)
                          );
                        }
                        if (
                          ((f = e.mgf[f].create(e.md[h].create())),
                          (h = l[r.signatureParameters.hash.algorithmOid]),
                          void 0 === h || void 0 === e.md[h])
                        ) {
                          var s = new Error(
                            "Unsupported RSASSA-PSS hash function."
                          );
                          throw (
                            ((s.oid = r.signatureParameters.hash.algorithmOid),
                            (s.name = h),
                            s)
                          );
                        }
                        p = e.pss.create(
                          e.md[h].create(),
                          f,
                          r.signatureParameters.saltLength
                        );
                    }
                    t = r.publicKey.verify(
                      i.digest().getBytes(),
                      r.signature,
                      p
                    );
                  }
                  return t;
                }),
                r
              );
            }),
            (c.getTBSCertificate = function (t) {
              var i = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.INTEGER,
                    !1,
                    o.integerToDer(t.version).getBytes()
                  ),
                ]),
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.INTEGER,
                  !1,
                  e.util.hexToBytes(t.serialNumber)
                ),
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OID,
                    !1,
                    o.oidToDer(t.siginfo.algorithmOid).getBytes()
                  ),
                  s(t.siginfo.algorithmOid, t.siginfo.parameters),
                ]),
                r(t.issuer),
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.UTCTIME,
                    !1,
                    o.dateToUtcTime(t.validity.notBefore)
                  ),
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.UTCTIME,
                    !1,
                    o.dateToUtcTime(t.validity.notAfter)
                  ),
                ]),
                r(t.subject),
                c.publicKeyToAsn1(t.publicKey),
              ]);
              return (
                t.issuer.uniqueId &&
                  i.value.push(
                    o.create(o.Class.CONTEXT_SPECIFIC, 1, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.BITSTRING,
                        !1,
                        String.fromCharCode(0) + t.issuer.uniqueId
                      ),
                    ])
                  ),
                t.subject.uniqueId &&
                  i.value.push(
                    o.create(o.Class.CONTEXT_SPECIFIC, 2, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.BITSTRING,
                        !1,
                        String.fromCharCode(0) + t.subject.uniqueId
                      ),
                    ])
                  ),
                t.extensions.length > 0 &&
                  i.value.push(c.certificateExtensionsToAsn1(t.extensions)),
                i
              );
            }),
            (c.getCertificationRequestInfo = function (e) {
              var t = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.INTEGER,
                  !1,
                  o.integerToDer(e.version).getBytes()
                ),
                r(e.subject),
                c.publicKeyToAsn1(e.publicKey),
                a(e),
              ]);
              return t;
            }),
            (c.distinguishedNameToAsn1 = function (e) {
              return r(e);
            }),
            (c.certificateToAsn1 = function (e) {
              var t = e.tbsCertificate || c.getTBSCertificate(e);
              return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                t,
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OID,
                    !1,
                    o.oidToDer(e.signatureOid).getBytes()
                  ),
                  s(e.signatureOid, e.signatureParameters),
                ]),
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.BITSTRING,
                  !1,
                  String.fromCharCode(0) + e.signature
                ),
              ]);
            }),
            (c.certificateExtensionsToAsn1 = function (e) {
              var t = o.create(o.Class.CONTEXT_SPECIFIC, 3, !0, []),
                r = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []);
              t.value.push(r);
              for (var i = 0; i < e.length; ++i)
                r.value.push(c.certificateExtensionToAsn1(e[i]));
              return t;
            }),
            (c.certificateExtensionToAsn1 = function (e) {
              var t = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, []);
              t.value.push(
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.OID,
                  !1,
                  o.oidToDer(e.id).getBytes()
                )
              ),
                e.critical &&
                  t.value.push(
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.BOOLEAN,
                      !1,
                      String.fromCharCode(255)
                    )
                  );
              var r = e.value;
              return (
                "string" != typeof e.value && (r = o.toDer(r).getBytes()),
                t.value.push(
                  o.create(o.Class.UNIVERSAL, o.Type.OCTETSTRING, !1, r)
                ),
                t
              );
            }),
            (c.certificationRequestToAsn1 = function (e) {
              var t =
                e.certificationRequestInfo || c.getCertificationRequestInfo(e);
              return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                t,
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OID,
                    !1,
                    o.oidToDer(e.signatureOid).getBytes()
                  ),
                  s(e.signatureOid, e.signatureParameters),
                ]),
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.BITSTRING,
                  !1,
                  String.fromCharCode(0) + e.signature
                ),
              ]);
            }),
            (c.createCaStore = function (t) {
              function i(t) {
                if (!t.hash) {
                  var i = e.md.sha1.create();
                  (t.attributes = c.RDNAttributesAsArray(r(t), i)),
                    (t.hash = i.digest().toHex());
                }
                return n.certs[t.hash] || null;
              }
              var n = { certs: {} };
              if (
                ((n.getIssuer = function (e) {
                  var t = i(e.issuer);
                  return t;
                }),
                (n.addCertificate = function (t) {
                  if (
                    ("string" == typeof t && (t = e.pki.certificateFromPem(t)),
                    !t.subject.hash)
                  ) {
                    var i = e.md.sha1.create();
                    (t.subject.attributes = c.RDNAttributesAsArray(
                      r(t.subject),
                      i
                    )),
                      (t.subject.hash = i.digest().toHex());
                  }
                  if (t.subject.hash in n.certs) {
                    var s = n.certs[t.subject.hash];
                    e.util.isArray(s) || (s = [s]), s.push(t);
                  } else n.certs[t.subject.hash] = t;
                }),
                (n.hasCertificate = function (t) {
                  var r = i(t.subject);
                  if (!r) return !1;
                  e.util.isArray(r) || (r = [r]);
                  for (
                    var n = o.toDer(c.certificateToAsn1(t)).getBytes(), s = 0;
                    s < r.length;
                    ++s
                  ) {
                    var a = o.toDer(c.certificateToAsn1(r[s])).getBytes();
                    if (n === a) return !0;
                  }
                  return !1;
                }),
                t)
              )
                for (var s = 0; s < t.length; ++s) {
                  var a = t[s];
                  n.addCertificate(a);
                }
              return n;
            }),
            (c.certificateError = {
              bad_certificate: "forge.pki.BadCertificate",
              unsupported_certificate: "forge.pki.UnsupportedCertificate",
              certificate_revoked: "forge.pki.CertificateRevoked",
              certificate_expired: "forge.pki.CertificateExpired",
              certificate_unknown: "forge.pki.CertificateUnknown",
              unknown_ca: "forge.pki.UnknownCertificateAuthority",
            }),
            (c.verifyCertificateChain = function (t, r, i) {
              r = r.slice(0);
              var n = r.slice(0),
                s = new Date(),
                a = !0,
                o = null,
                l = 0;
              do {
                var u = r.shift(),
                  p = null,
                  h = !1;
                if (
                  ((s < u.validity.notBefore || s > u.validity.notAfter) &&
                    (o = {
                      message: "Certificate is not valid yet or has expired.",
                      error: c.certificateError.certificate_expired,
                      notBefore: u.validity.notBefore,
                      notAfter: u.validity.notAfter,
                      now: s,
                    }),
                  null === o)
                ) {
                  if (
                    ((p = r[0] || t.getIssuer(u)),
                    null === p && u.isIssuer(u) && ((h = !0), (p = u)),
                    p)
                  ) {
                    var f = p;
                    e.util.isArray(f) || (f = [f]);
                    for (var g = !1; !g && f.length > 0; ) {
                      p = f.shift();
                      try {
                        g = p.verify(u);
                      } catch (m) {}
                    }
                    g ||
                      (o = {
                        message: "Certificate signature is invalid.",
                        error: c.certificateError.bad_certificate,
                      });
                  }
                  null === o &&
                    (!p || h) &&
                    !t.hasCertificate(u) &&
                    (o = {
                      message: "Certificate is not trusted.",
                      error: c.certificateError.unknown_ca,
                    });
                }
                if (
                  (null === o &&
                    p &&
                    !u.isIssuer(p) &&
                    (o = {
                      message: "Certificate issuer is invalid.",
                      error: c.certificateError.bad_certificate,
                    }),
                  null === o)
                )
                  for (
                    var d = { keyUsage: !0, basicConstraints: !0 }, y = 0;
                    null === o && y < u.extensions.length;
                    ++y
                  ) {
                    var k = u.extensions[y];
                    k.critical &&
                      !(k.name in d) &&
                      (o = {
                        message:
                          "Certificate has an unsupported critical extension.",
                        error: c.certificateError.unsupported_certificate,
                      });
                  }
                if (null === o && (!a || (0 === r.length && (!p || h)))) {
                  var v = u.getExtension("basicConstraints"),
                    E = u.getExtension("keyUsage");
                  if (
                    (null !== E &&
                      (!E.keyCertSign || null === v) &&
                      (o = {
                        message:
                          "Certificate keyUsage or basicConstraints conflict or indicate that the certificate is not a CA. If the certificate is the only one in the chain or isn't the first then the certificate must be a valid CA.",
                        error: c.certificateError.bad_certificate,
                      }),
                    null === o &&
                      null !== v &&
                      !v.cA &&
                      (o = {
                        message:
                          "Certificate basicConstraints indicates the certificate is not a CA.",
                        error: c.certificateError.bad_certificate,
                      }),
                    null === o && null !== E && "pathLenConstraint" in v)
                  ) {
                    var S = l - 1;
                    S > v.pathLenConstraint &&
                      (o = {
                        message:
                          "Certificate basicConstraints pathLenConstraint violated.",
                        error: c.certificateError.bad_certificate,
                      });
                  }
                }
                var _ = null === o || o.error,
                  b = i ? i(_, l, n) : _;
                if (b !== !0)
                  throw (
                    (_ === !0 &&
                      (o = {
                        message: "The application rejected the certificate.",
                        error: c.certificateError.bad_certificate,
                      }),
                    (b || 0 === b) &&
                      ("object" != typeof b || e.util.isArray(b)
                        ? "string" == typeof b && (o.error = b)
                        : (b.message && (o.message = b.message),
                          b.error && (o.error = b.error))),
                    o)
                  );
                (o = null), (a = !1), ++l;
              } while (r.length > 0);
              return !0;
            });
        }
        var i = "x509";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r.pki;
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/x509",
          [
            "require",
            "module",
            "./aes",
            "./asn1",
            "./des",
            "./md",
            "./mgf",
            "./oids",
            "./pem",
            "./pss",
            "./rsa",
            "./util",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(e, t, r, i) {
            for (var n = [], s = 0; s < e.length; s++)
              for (var a = 0; a < e[s].safeBags.length; a++) {
                var o = e[s].safeBags[a];
                (void 0 !== i && o.type !== i) ||
                  (null !== t
                    ? void 0 !== o.attributes[t] &&
                      o.attributes[t].indexOf(r) >= 0 &&
                      n.push(o)
                    : n.push(o));
              }
            return n;
          }
          function r(t) {
            if (t.composed || t.constructed) {
              for (
                var r = e.util.createBuffer(), i = 0;
                i < t.value.length;
                ++i
              )
                r.putBytes(t.value[i].value);
              (t.composed = t.constructed = !1), (t.value = r.getBytes());
            }
            return t;
          }
          function i(e, t, i, a) {
            if (
              ((t = o.fromDer(t, i)),
              t.tagClass !== o.Class.UNIVERSAL ||
                t.type !== o.Type.SEQUENCE ||
                t.constructed !== !0)
            )
              throw new Error(
                "PKCS#12 AuthenticatedSafe expected to be a SEQUENCE OF ContentInfo"
              );
            for (var l = 0; l < t.value.length; l++) {
              var p = t.value[l],
                h = {},
                f = [];
              if (!o.validate(p, u, h, f)) {
                var g = new Error("Cannot read ContentInfo.");
                throw ((g.errors = f), g);
              }
              var m = { encrypted: !1 },
                d = null,
                y = h.content.value[0];
              switch (o.derToOid(h.contentType)) {
                case c.oids.data:
                  if (
                    y.tagClass !== o.Class.UNIVERSAL ||
                    y.type !== o.Type.OCTETSTRING
                  )
                    throw new Error(
                      "PKCS#12 SafeContents Data is not an OCTET STRING."
                    );
                  d = r(y).value;
                  break;
                case c.oids.encryptedData:
                  (d = n(y, a)), (m.encrypted = !0);
                  break;
                default:
                  var g = new Error("Unsupported PKCS#12 contentType.");
                  throw ((g.contentType = o.derToOid(h.contentType)), g);
              }
              (m.safeBags = s(d, i, a)), e.safeContents.push(m);
            }
          }
          function n(t, i) {
            var n = {},
              s = [];
            if (!o.validate(t, e.pkcs7.asn1.encryptedDataValidator, n, s)) {
              var a = new Error("Cannot read EncryptedContentInfo.");
              throw ((a.errors = s), a);
            }
            var l = o.derToOid(n.contentType);
            if (l !== c.oids.data) {
              var a = new Error(
                "PKCS#12 EncryptedContentInfo ContentType is not Data."
              );
              throw ((a.oid = l), a);
            }
            l = o.derToOid(n.encAlgorithm);
            var u = c.pbe.getCipher(l, n.encParameter, i),
              p = r(n.encryptedContentAsn1),
              h = e.util.createBuffer(p.value);
            if ((u.update(h), !u.finish()))
              throw new Error("Failed to decrypt PKCS#12 SafeContents.");
            return u.output.getBytes();
          }
          function s(e, t, r) {
            if (!t && 0 === e.length) return [];
            if (
              ((e = o.fromDer(e, t)),
              e.tagClass !== o.Class.UNIVERSAL ||
                e.type !== o.Type.SEQUENCE ||
                e.constructed !== !0)
            )
              throw new Error(
                "PKCS#12 SafeContents expected to be a SEQUENCE OF SafeBag."
              );
            for (var i = [], n = 0; n < e.value.length; n++) {
              var s = e.value[n],
                l = {},
                u = [];
              if (!o.validate(s, h, l, u)) {
                var p = new Error("Cannot read SafeBag.");
                throw ((p.errors = u), p);
              }
              var f = {
                type: o.derToOid(l.bagId),
                attributes: a(l.bagAttributes),
              };
              i.push(f);
              var m,
                d,
                y = l.bagValue.value[0];
              switch (f.type) {
                case c.oids.pkcs8ShroudedKeyBag:
                  if (((y = c.decryptPrivateKeyInfo(y, r)), null === y))
                    throw new Error(
                      "Unable to decrypt PKCS#8 ShroudedKeyBag, wrong password?"
                    );
                case c.oids.keyBag:
                  try {
                    f.key = c.privateKeyFromAsn1(y);
                  } catch (k) {
                    (f.key = null), (f.asn1 = y);
                  }
                  continue;
                case c.oids.certBag:
                  (m = g),
                    (d = function () {
                      if (o.derToOid(l.certId) !== c.oids.x509Certificate) {
                        var e = new Error(
                          "Unsupported certificate type, only X.509 supported."
                        );
                        throw ((e.oid = o.derToOid(l.certId)), e);
                      }
                      var r = o.fromDer(l.cert, t);
                      try {
                        f.cert = c.certificateFromAsn1(r, !0);
                      } catch (i) {
                        (f.cert = null), (f.asn1 = r);
                      }
                    });
                  break;
                default:
                  var p = new Error("Unsupported PKCS#12 SafeBag type.");
                  throw ((p.oid = f.type), p);
              }
              if (void 0 !== m && !o.validate(y, m, l, u)) {
                var p = new Error("Cannot read PKCS#12 " + m.name);
                throw ((p.errors = u), p);
              }
              d();
            }
            return i;
          }
          function a(e) {
            var t = {};
            if (void 0 !== e)
              for (var r = 0; r < e.length; ++r) {
                var i = {},
                  n = [];
                if (!o.validate(e[r], f, i, n)) {
                  var s = new Error("Cannot read PKCS#12 BagAttribute.");
                  throw ((s.errors = n), s);
                }
                var a = o.derToOid(i.oid);
                if (void 0 !== c.oids[a]) {
                  t[c.oids[a]] = [];
                  for (var l = 0; l < i.values.length; ++l)
                    t[c.oids[a]].push(i.values[l].value);
                }
              }
            return t;
          }
          var o = e.asn1,
            c = e.pki,
            l = (e.pkcs12 = e.pkcs12 || {}),
            u = {
              name: "ContentInfo",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "ContentInfo.contentType",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.OID,
                  constructed: !1,
                  capture: "contentType",
                },
                {
                  name: "ContentInfo.content",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  constructed: !0,
                  captureAsn1: "content",
                },
              ],
            },
            p = {
              name: "PFX",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "PFX.version",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.INTEGER,
                  constructed: !1,
                  capture: "version",
                },
                u,
                {
                  name: "PFX.macData",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SEQUENCE,
                  constructed: !0,
                  optional: !0,
                  captureAsn1: "mac",
                  value: [
                    {
                      name: "PFX.macData.mac",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.SEQUENCE,
                      constructed: !0,
                      value: [
                        {
                          name: "PFX.macData.mac.digestAlgorithm",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.SEQUENCE,
                          constructed: !0,
                          value: [
                            {
                              name: "PFX.macData.mac.digestAlgorithm.algorithm",
                              tagClass: o.Class.UNIVERSAL,
                              type: o.Type.OID,
                              constructed: !1,
                              capture: "macAlgorithm",
                            },
                            {
                              name: "PFX.macData.mac.digestAlgorithm.parameters",
                              tagClass: o.Class.UNIVERSAL,
                              captureAsn1: "macAlgorithmParameters",
                            },
                          ],
                        },
                        {
                          name: "PFX.macData.mac.digest",
                          tagClass: o.Class.UNIVERSAL,
                          type: o.Type.OCTETSTRING,
                          constructed: !1,
                          capture: "macDigest",
                        },
                      ],
                    },
                    {
                      name: "PFX.macData.macSalt",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.OCTETSTRING,
                      constructed: !1,
                      capture: "macSalt",
                    },
                    {
                      name: "PFX.macData.iterations",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Type.INTEGER,
                      constructed: !1,
                      optional: !0,
                      capture: "macIterations",
                    },
                  ],
                },
              ],
            },
            h = {
              name: "SafeBag",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "SafeBag.bagId",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.OID,
                  constructed: !1,
                  capture: "bagId",
                },
                {
                  name: "SafeBag.bagValue",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  constructed: !0,
                  captureAsn1: "bagValue",
                },
                {
                  name: "SafeBag.bagAttributes",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SET,
                  constructed: !0,
                  optional: !0,
                  capture: "bagAttributes",
                },
              ],
            },
            f = {
              name: "Attribute",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "Attribute.attrId",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.OID,
                  constructed: !1,
                  capture: "oid",
                },
                {
                  name: "Attribute.attrValues",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.SET,
                  constructed: !0,
                  capture: "values",
                },
              ],
            },
            g = {
              name: "CertBag",
              tagClass: o.Class.UNIVERSAL,
              type: o.Type.SEQUENCE,
              constructed: !0,
              value: [
                {
                  name: "CertBag.certId",
                  tagClass: o.Class.UNIVERSAL,
                  type: o.Type.OID,
                  constructed: !1,
                  capture: "certId",
                },
                {
                  name: "CertBag.certValue",
                  tagClass: o.Class.CONTEXT_SPECIFIC,
                  constructed: !0,
                  value: [
                    {
                      name: "CertBag.certValue[0]",
                      tagClass: o.Class.UNIVERSAL,
                      type: o.Class.OCTETSTRING,
                      constructed: !1,
                      capture: "cert",
                    },
                  ],
                },
              ],
            };
          (l.pkcs12FromAsn1 = function (n, s, a) {
            "string" == typeof s
              ? ((a = s), (s = !0))
              : void 0 === s && (s = !0);
            var u = {},
              h = [];
            if (!o.validate(n, p, u, h)) {
              var f = new Error(
                "Cannot read PKCS#12 PFX. ASN.1 object is not an PKCS#12 PFX."
              );
              throw ((f.errors = f), f);
            }
            var g = {
              version: u.version.charCodeAt(0),
              safeContents: [],
              getBags: function (r) {
                var i,
                  n = {};
                return (
                  "localKeyId" in r
                    ? (i = r.localKeyId)
                    : "localKeyIdHex" in r &&
                      (i = e.util.hexToBytes(r.localKeyIdHex)),
                  void 0 === i &&
                    !("friendlyName" in r) &&
                    "bagType" in r &&
                    (n[r.bagType] = t(g.safeContents, null, null, r.bagType)),
                  void 0 !== i &&
                    (n.localKeyId = t(
                      g.safeContents,
                      "localKeyId",
                      i,
                      r.bagType
                    )),
                  "friendlyName" in r &&
                    (n.friendlyName = t(
                      g.safeContents,
                      "friendlyName",
                      r.friendlyName,
                      r.bagType
                    )),
                  n
                );
              },
              getBagsByFriendlyName: function (e, r) {
                return t(g.safeContents, "friendlyName", e, r);
              },
              getBagsByLocalKeyId: function (e, r) {
                return t(g.safeContents, "localKeyId", e, r);
              },
            };
            if (3 !== u.version.charCodeAt(0)) {
              var f = new Error(
                "PKCS#12 PFX of version other than 3 not supported."
              );
              throw ((f.version = u.version.charCodeAt(0)), f);
            }
            if (o.derToOid(u.contentType) !== c.oids.data) {
              var f = new Error(
                "Only PKCS#12 PFX in password integrity mode supported."
              );
              throw ((f.oid = o.derToOid(u.contentType)), f);
            }
            var m = u.content.value[0];
            if (
              m.tagClass !== o.Class.UNIVERSAL ||
              m.type !== o.Type.OCTETSTRING
            )
              throw new Error(
                "PKCS#12 authSafe content data is not an OCTET STRING."
              );
            if (((m = r(m)), u.mac)) {
              var d = null,
                y = 0,
                k = o.derToOid(u.macAlgorithm);
              switch (k) {
                case c.oids.sha1:
                  (d = e.md.sha1.create()), (y = 20);
                  break;
                case c.oids.sha256:
                  (d = e.md.sha256.create()), (y = 32);
                  break;
                case c.oids.sha384:
                  (d = e.md.sha384.create()), (y = 48);
                  break;
                case c.oids.sha512:
                  (d = e.md.sha512.create()), (y = 64);
                  break;
                case c.oids.md5:
                  (d = e.md.md5.create()), (y = 16);
              }
              if (null === d)
                throw new Error("PKCS#12 uses unsupported MAC algorithm: " + k);
              var v = new e.util.ByteBuffer(u.macSalt),
                E =
                  "macIterations" in u
                    ? parseInt(e.util.bytesToHex(u.macIterations), 16)
                    : 1,
                S = l.generateKey(a, v, 3, E, y, d),
                _ = e.hmac.create();
              _.start(d, S), _.update(m.value);
              var b = _.getMac();
              if (b.getBytes() !== u.macDigest)
                throw new Error(
                  "PKCS#12 MAC could not be verified. Invalid password?"
                );
            }
            return i(g, m.value, s, a), g;
          }),
            (l.toPkcs12Asn1 = function (t, r, i, n) {
              (n = n || {}),
                (n.saltSize = n.saltSize || 8),
                (n.count = n.count || 2048),
                (n.algorithm = n.algorithm || n.encAlgorithm || "aes128"),
                "useMac" in n || (n.useMac = !0),
                "localKeyId" in n || (n.localKeyId = null),
                "generateLocalKeyId" in n || (n.generateLocalKeyId = !0);
              var s,
                a = n.localKeyId;
              if (null !== a) a = e.util.hexToBytes(a);
              else if (n.generateLocalKeyId)
                if (r) {
                  var u = e.util.isArray(r) ? r[0] : r;
                  "string" == typeof u && (u = c.certificateFromPem(u));
                  var p = e.md.sha1.create();
                  p.update(o.toDer(c.certificateToAsn1(u)).getBytes()),
                    (a = p.digest().getBytes());
                } else a = e.random.getBytes(20);
              var h = [];
              null !== a &&
                h.push(
                  o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(c.oids.localKeyId).getBytes()
                    ),
                    o.create(o.Class.UNIVERSAL, o.Type.SET, !0, [
                      o.create(o.Class.UNIVERSAL, o.Type.OCTETSTRING, !1, a),
                    ]),
                  ])
                ),
                "friendlyName" in n &&
                  h.push(
                    o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.OID,
                        !1,
                        o.oidToDer(c.oids.friendlyName).getBytes()
                      ),
                      o.create(o.Class.UNIVERSAL, o.Type.SET, !0, [
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.BMPSTRING,
                          !1,
                          n.friendlyName
                        ),
                      ]),
                    ])
                  ),
                h.length > 0 &&
                  (s = o.create(o.Class.UNIVERSAL, o.Type.SET, !0, h));
              var f = [],
                g = [];
              null !== r && (g = e.util.isArray(r) ? r : [r]);
              for (var m = [], d = 0; d < g.length; ++d) {
                (r = g[d]),
                  "string" == typeof r && (r = c.certificateFromPem(r));
                var y = 0 === d ? s : void 0,
                  k = c.certificateToAsn1(r),
                  v = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(c.oids.certBag).getBytes()
                    ),
                    o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                      o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.OID,
                          !1,
                          o.oidToDer(c.oids.x509Certificate).getBytes()
                        ),
                        o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                          o.create(
                            o.Class.UNIVERSAL,
                            o.Type.OCTETSTRING,
                            !1,
                            o.toDer(k).getBytes()
                          ),
                        ]),
                      ]),
                    ]),
                    y,
                  ]);
                m.push(v);
              }
              if (m.length > 0) {
                var E = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, m),
                  S = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(c.oids.data).getBytes()
                    ),
                    o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.OCTETSTRING,
                        !1,
                        o.toDer(E).getBytes()
                      ),
                    ]),
                  ]);
                f.push(S);
              }
              var _ = null;
              if (null !== t) {
                var b = c.wrapRsaPrivateKey(c.privateKeyToAsn1(t));
                _ =
                  null === i
                    ? o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.OID,
                          !1,
                          o.oidToDer(c.oids.keyBag).getBytes()
                        ),
                        o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [b]),
                        s,
                      ])
                    : o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                        o.create(
                          o.Class.UNIVERSAL,
                          o.Type.OID,
                          !1,
                          o.oidToDer(c.oids.pkcs8ShroudedKeyBag).getBytes()
                        ),
                        o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                          c.encryptPrivateKeyInfo(b, i, n),
                        ]),
                        s,
                      ]);
                var I = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [_]),
                  A = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OID,
                      !1,
                      o.oidToDer(c.oids.data).getBytes()
                    ),
                    o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.OCTETSTRING,
                        !1,
                        o.toDer(I).getBytes()
                      ),
                    ]),
                  ]);
                f.push(A);
              }
              var C,
                T = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, f);
              if (n.useMac) {
                var p = e.md.sha1.create(),
                  N = new e.util.ByteBuffer(e.random.getBytes(n.saltSize)),
                  j = n.count,
                  t = l.generateKey(i, N, 3, j, 20),
                  w = e.hmac.create();
                w.start(p, t), w.update(o.toDer(T).getBytes());
                var R = w.getMac();
                C = o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                    o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                      o.create(
                        o.Class.UNIVERSAL,
                        o.Type.OID,
                        !1,
                        o.oidToDer(c.oids.sha1).getBytes()
                      ),
                      o.create(o.Class.UNIVERSAL, o.Type.NULL, !1, ""),
                    ]),
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OCTETSTRING,
                      !1,
                      R.getBytes()
                    ),
                  ]),
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OCTETSTRING,
                    !1,
                    N.getBytes()
                  ),
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.INTEGER,
                    !1,
                    o.integerToDer(j).getBytes()
                  ),
                ]);
              }
              return o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                o.create(
                  o.Class.UNIVERSAL,
                  o.Type.INTEGER,
                  !1,
                  o.integerToDer(3).getBytes()
                ),
                o.create(o.Class.UNIVERSAL, o.Type.SEQUENCE, !0, [
                  o.create(
                    o.Class.UNIVERSAL,
                    o.Type.OID,
                    !1,
                    o.oidToDer(c.oids.data).getBytes()
                  ),
                  o.create(o.Class.CONTEXT_SPECIFIC, 0, !0, [
                    o.create(
                      o.Class.UNIVERSAL,
                      o.Type.OCTETSTRING,
                      !1,
                      o.toDer(T).getBytes()
                    ),
                  ]),
                ]),
                C,
              ]);
            }),
            (l.generateKey = e.pbe.generatePkcs12Key);
        }
        var i = "pkcs12";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pkcs12",
          [
            "require",
            "module",
            "./asn1",
            "./hmac",
            "./oids",
            "./pkcs7asn1",
            "./pbe",
            "./random",
            "./rsa",
            "./sha1",
            "./util",
            "./x509",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = e.asn1,
            r = (e.pki = e.pki || {});
          (r.pemToDer = function (t) {
            var r = e.pem.decode(t)[0];
            if (r.procType && "ENCRYPTED" === r.procType.type)
              throw new Error(
                "Could not convert PEM to DER; PEM is encrypted."
              );
            return e.util.createBuffer(r.body);
          }),
            (r.privateKeyFromPem = function (i) {
              var n = e.pem.decode(i)[0];
              if ("PRIVATE KEY" !== n.type && "RSA PRIVATE KEY" !== n.type) {
                var s = new Error(
                  'Could not convert private key from PEM; PEM header type is not "PRIVATE KEY" or "RSA PRIVATE KEY".'
                );
                throw ((s.headerType = n.type), s);
              }
              if (n.procType && "ENCRYPTED" === n.procType.type)
                throw new Error(
                  "Could not convert private key from PEM; PEM is encrypted."
                );
              var a = t.fromDer(n.body);
              return r.privateKeyFromAsn1(a);
            }),
            (r.privateKeyToPem = function (i, n) {
              var s = {
                type: "RSA PRIVATE KEY",
                body: t.toDer(r.privateKeyToAsn1(i)).getBytes(),
              };
              return e.pem.encode(s, { maxline: n });
            }),
            (r.privateKeyInfoToPem = function (r, i) {
              var n = { type: "PRIVATE KEY", body: t.toDer(r).getBytes() };
              return e.pem.encode(n, { maxline: i });
            });
        }
        var i = "pki";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pki",
          [
            "require",
            "module",
            "./asn1",
            "./oids",
            "./pbe",
            "./pem",
            "./pbkdf2",
            "./pkcs12",
            "./pss",
            "./rsa",
            "./util",
            "./x509",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = function (t, r, i, n) {
              var s = e.util.createBuffer(),
                a = t.length >> 1,
                o = a + (1 & t.length),
                c = t.substr(0, o),
                l = t.substr(a, o),
                u = e.util.createBuffer(),
                p = e.hmac.create();
              i = r + i;
              var h = Math.ceil(n / 16),
                f = Math.ceil(n / 20);
              p.start("MD5", c);
              var g = e.util.createBuffer();
              u.putBytes(i);
              for (var m = 0; m < h; ++m)
                p.start(null, null),
                  p.update(u.getBytes()),
                  u.putBuffer(p.digest()),
                  p.start(null, null),
                  p.update(u.bytes() + i),
                  g.putBuffer(p.digest());
              p.start("SHA1", l);
              var d = e.util.createBuffer();
              u.clear(), u.putBytes(i);
              for (var m = 0; m < f; ++m)
                p.start(null, null),
                  p.update(u.getBytes()),
                  u.putBuffer(p.digest()),
                  p.start(null, null),
                  p.update(u.bytes() + i),
                  d.putBuffer(p.digest());
              return (
                s.putBytes(e.util.xorBytes(g.getBytes(), d.getBytes(), n)), s
              );
            },
            r = function (t, r, i) {
              var n = e.hmac.create();
              n.start("SHA1", t);
              var s = e.util.createBuffer();
              return (
                s.putInt32(r[0]),
                s.putInt32(r[1]),
                s.putByte(i.type),
                s.putByte(i.version.major),
                s.putByte(i.version.minor),
                s.putInt16(i.length),
                s.putBytes(i.fragment.bytes()),
                n.update(s.getBytes()),
                n.digest().getBytes()
              );
            },
            i = function (t, r, i) {
              var n = !1;
              try {
                var s = t.deflate(r.fragment.getBytes());
                (r.fragment = e.util.createBuffer(s)),
                  (r.length = s.length),
                  (n = !0);
              } catch (a) {}
              return n;
            },
            n = function (t, r, i) {
              var n = !1;
              try {
                var s = t.inflate(r.fragment.getBytes());
                (r.fragment = e.util.createBuffer(s)),
                  (r.length = s.length),
                  (n = !0);
              } catch (a) {}
              return n;
            },
            s = function (t, r) {
              var i = 0;
              switch (r) {
                case 1:
                  i = t.getByte();
                  break;
                case 2:
                  i = t.getInt16();
                  break;
                case 3:
                  i = t.getInt24();
                  break;
                case 4:
                  i = t.getInt32();
              }
              return e.util.createBuffer(t.getBytes(i));
            },
            a = function (e, t, r) {
              e.putInt(r.length(), t << 3), e.putBuffer(r);
            },
            o = {};
          (o.Versions = {
            TLS_1_0: { major: 3, minor: 1 },
            TLS_1_1: { major: 3, minor: 2 },
            TLS_1_2: { major: 3, minor: 3 },
          }),
            (o.SupportedVersions = [o.Versions.TLS_1_1, o.Versions.TLS_1_0]),
            (o.Version = o.SupportedVersions[0]),
            (o.MaxFragment = 15360),
            (o.ConnectionEnd = { server: 0, client: 1 }),
            (o.PRFAlgorithm = { tls_prf_sha256: 0 }),
            (o.BulkCipherAlgorithm = { none: null, rc4: 0, des3: 1, aes: 2 }),
            (o.CipherType = { stream: 0, block: 1, aead: 2 }),
            (o.MACAlgorithm = {
              none: null,
              hmac_md5: 0,
              hmac_sha1: 1,
              hmac_sha256: 2,
              hmac_sha384: 3,
              hmac_sha512: 4,
            }),
            (o.CompressionMethod = { none: 0, deflate: 1 }),
            (o.ContentType = {
              change_cipher_spec: 20,
              alert: 21,
              handshake: 22,
              application_data: 23,
              heartbeat: 24,
            }),
            (o.HandshakeType = {
              hello_request: 0,
              client_hello: 1,
              server_hello: 2,
              certificate: 11,
              server_key_exchange: 12,
              certificate_request: 13,
              server_hello_done: 14,
              certificate_verify: 15,
              client_key_exchange: 16,
              finished: 20,
            }),
            (o.Alert = {}),
            (o.Alert.Level = { warning: 1, fatal: 2 }),
            (o.Alert.Description = {
              close_notify: 0,
              unexpected_message: 10,
              bad_record_mac: 20,
              decryption_failed: 21,
              record_overflow: 22,
              decompression_failure: 30,
              handshake_failure: 40,
              bad_certificate: 42,
              unsupported_certificate: 43,
              certificate_revoked: 44,
              certificate_expired: 45,
              certificate_unknown: 46,
              illegal_parameter: 47,
              unknown_ca: 48,
              access_denied: 49,
              decode_error: 50,
              decrypt_error: 51,
              export_restriction: 60,
              protocol_version: 70,
              insufficient_security: 71,
              internal_error: 80,
              user_canceled: 90,
              no_renegotiation: 100,
            }),
            (o.HeartbeatMessageType = {
              heartbeat_request: 1,
              heartbeat_response: 2,
            }),
            (o.CipherSuites = {}),
            (o.getCipherSuite = function (e) {
              var t = null;
              for (var r in o.CipherSuites) {
                var i = o.CipherSuites[r];
                if (
                  i.id[0] === e.charCodeAt(0) &&
                  i.id[1] === e.charCodeAt(1)
                ) {
                  t = i;
                  break;
                }
              }
              return t;
            }),
            (o.handleUnexpected = function (e, t) {
              var r = !e.open && e.entity === o.ConnectionEnd.client;
              r ||
                e.error(e, {
                  message:
                    "Unexpected message. Received TLS record out of order.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.unexpected_message,
                  },
                });
            }),
            (o.handleHelloRequest = function (e, t, r) {
              !e.handshaking &&
                e.handshakes > 0 &&
                (o.queue(
                  e,
                  o.createAlert(e, {
                    level: o.Alert.Level.warning,
                    description: o.Alert.Description.no_renegotiation,
                  })
                ),
                o.flush(e)),
                e.process();
            }),
            (o.parseHelloMessage = function (t, r, i) {
              var n = null,
                a = t.entity === o.ConnectionEnd.client;
              if (i < 38)
                t.error(t, {
                  message: a
                    ? "Invalid ServerHello message. Message too short."
                    : "Invalid ClientHello message. Message too short.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.illegal_parameter,
                  },
                });
              else {
                var c = r.fragment,
                  l = c.length();
                if (
                  ((n = {
                    version: { major: c.getByte(), minor: c.getByte() },
                    random: e.util.createBuffer(c.getBytes(32)),
                    session_id: s(c, 1),
                    extensions: [],
                  }),
                  a
                    ? ((n.cipher_suite = c.getBytes(2)),
                      (n.compression_method = c.getByte()))
                    : ((n.cipher_suites = s(c, 2)),
                      (n.compression_methods = s(c, 1))),
                  (l = i - (l - c.length())),
                  l > 0)
                ) {
                  for (var u = s(c, 2); u.length() > 0; )
                    n.extensions.push({
                      type: [u.getByte(), u.getByte()],
                      data: s(u, 2),
                    });
                  if (!a)
                    for (var p = 0; p < n.extensions.length; ++p) {
                      var h = n.extensions[p];
                      if (0 === h.type[0] && 0 === h.type[1])
                        for (var f = s(h.data, 2); f.length() > 0; ) {
                          var g = f.getByte();
                          if (0 !== g) break;
                          t.session.extensions.server_name.serverNameList.push(
                            s(f, 2).getBytes()
                          );
                        }
                    }
                }
                if (
                  t.session.version &&
                  (n.version.major !== t.session.version.major ||
                    n.version.minor !== t.session.version.minor)
                )
                  return t.error(t, {
                    message:
                      "TLS version change is disallowed during renegotiation.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.protocol_version,
                    },
                  });
                if (a) t.session.cipherSuite = o.getCipherSuite(n.cipher_suite);
                else
                  for (
                    var m = e.util.createBuffer(n.cipher_suites.bytes());
                    m.length() > 0 &&
                    ((t.session.cipherSuite = o.getCipherSuite(m.getBytes(2))),
                    null === t.session.cipherSuite);

                  );
                if (null === t.session.cipherSuite)
                  return t.error(t, {
                    message: "No cipher suites in common.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.handshake_failure,
                    },
                    cipherSuite: e.util.bytesToHex(n.cipher_suite),
                  });
                a
                  ? (t.session.compressionMethod = n.compression_method)
                  : (t.session.compressionMethod = o.CompressionMethod.none);
              }
              return n;
            }),
            (o.createSecurityParameters = function (e, t) {
              var r = e.entity === o.ConnectionEnd.client,
                i = t.random.bytes(),
                n = r ? e.session.sp.client_random : i,
                s = r ? i : o.createRandom().getBytes();
              e.session.sp = {
                entity: e.entity,
                prf_algorithm: o.PRFAlgorithm.tls_prf_sha256,
                bulk_cipher_algorithm: null,
                cipher_type: null,
                enc_key_length: null,
                block_length: null,
                fixed_iv_length: null,
                record_iv_length: null,
                mac_algorithm: null,
                mac_length: null,
                mac_key_length: null,
                compression_algorithm: e.session.compressionMethod,
                pre_master_secret: null,
                master_secret: null,
                client_random: n,
                server_random: s,
              };
            }),
            (o.handleServerHello = function (e, t, r) {
              var i = o.parseHelloMessage(e, t, r);
              if (!e.fail) {
                if (!(i.version.minor <= e.version.minor))
                  return e.error(e, {
                    message: "Incompatible TLS version.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.protocol_version,
                    },
                  });
                (e.version.minor = i.version.minor),
                  (e.session.version = e.version);
                var n = i.session_id.bytes();
                n.length > 0 && n === e.session.id
                  ? ((e.expect = f),
                    (e.session.resuming = !0),
                    (e.session.sp.server_random = i.random.bytes()))
                  : ((e.expect = l),
                    (e.session.resuming = !1),
                    o.createSecurityParameters(e, i)),
                  (e.session.id = n),
                  e.process();
              }
            }),
            (o.handleClientHello = function (t, r, i) {
              var n = o.parseHelloMessage(t, r, i);
              if (!t.fail) {
                var s = n.session_id.bytes(),
                  a = null;
                if (
                  (t.sessionCache &&
                    ((a = t.sessionCache.getSession(s)),
                    null === a
                      ? (s = "")
                      : (a.version.major !== n.version.major ||
                          a.version.minor > n.version.minor) &&
                        ((a = null), (s = ""))),
                  0 === s.length && (s = e.random.getBytes(32)),
                  (t.session.id = s),
                  (t.session.clientHelloVersion = n.version),
                  (t.session.sp = {}),
                  a)
                )
                  (t.version = t.session.version = a.version),
                    (t.session.sp = a.sp);
                else {
                  for (
                    var c, l = 1;
                    l < o.SupportedVersions.length &&
                    ((c = o.SupportedVersions[l]),
                    !(c.minor <= n.version.minor));
                    ++l
                  );
                  (t.version = { major: c.major, minor: c.minor }),
                    (t.session.version = t.version);
                }
                null !== a
                  ? ((t.expect = S),
                    (t.session.resuming = !0),
                    (t.session.sp.client_random = n.random.bytes()))
                  : ((t.expect = t.verifyClient !== !1 ? k : v),
                    (t.session.resuming = !1),
                    o.createSecurityParameters(t, n)),
                  (t.open = !0),
                  o.queue(
                    t,
                    o.createRecord(t, {
                      type: o.ContentType.handshake,
                      data: o.createServerHello(t),
                    })
                  ),
                  t.session.resuming
                    ? (o.queue(
                        t,
                        o.createRecord(t, {
                          type: o.ContentType.change_cipher_spec,
                          data: o.createChangeCipherSpec(),
                        })
                      ),
                      (t.state.pending = o.createConnectionState(t)),
                      (t.state.current.write = t.state.pending.write),
                      o.queue(
                        t,
                        o.createRecord(t, {
                          type: o.ContentType.handshake,
                          data: o.createFinished(t),
                        })
                      ))
                    : (o.queue(
                        t,
                        o.createRecord(t, {
                          type: o.ContentType.handshake,
                          data: o.createCertificate(t),
                        })
                      ),
                      t.fail ||
                        (o.queue(
                          t,
                          o.createRecord(t, {
                            type: o.ContentType.handshake,
                            data: o.createServerKeyExchange(t),
                          })
                        ),
                        t.verifyClient !== !1 &&
                          o.queue(
                            t,
                            o.createRecord(t, {
                              type: o.ContentType.handshake,
                              data: o.createCertificateRequest(t),
                            })
                          ),
                        o.queue(
                          t,
                          o.createRecord(t, {
                            type: o.ContentType.handshake,
                            data: o.createServerHelloDone(t),
                          })
                        ))),
                  o.flush(t),
                  t.process();
              }
            }),
            (o.handleCertificate = function (t, r, i) {
              if (i < 3)
                return t.error(t, {
                  message: "Invalid Certificate message. Message too short.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.illegal_parameter,
                  },
                });
              var n,
                a,
                c = r.fragment,
                l = { certificate_list: s(c, 3) },
                p = [];
              try {
                for (; l.certificate_list.length() > 0; )
                  (n = s(l.certificate_list, 3)),
                    (a = e.asn1.fromDer(n)),
                    (n = e.pki.certificateFromAsn1(a, !0)),
                    p.push(n);
              } catch (h) {
                return t.error(t, {
                  message: "Could not parse certificate list.",
                  cause: h,
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.bad_certificate,
                  },
                });
              }
              var f = t.entity === o.ConnectionEnd.client;
              (!f && t.verifyClient !== !0) || 0 !== p.length
                ? 0 === p.length
                  ? (t.expect = f ? u : v)
                  : (f
                      ? (t.session.serverCertificate = p[0])
                      : (t.session.clientCertificate = p[0]),
                    o.verifyCertificateChain(t, p) && (t.expect = f ? u : v))
                : t.error(t, {
                    message: f
                      ? "No server certificate provided."
                      : "No client certificate provided.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.illegal_parameter,
                    },
                  }),
                t.process();
            }),
            (o.handleServerKeyExchange = function (e, t, r) {
              return r > 0
                ? e.error(e, {
                    message: "Invalid key parameters. Only RSA is supported.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.unsupported_certificate,
                    },
                  })
                : ((e.expect = p), void e.process());
            }),
            (o.handleClientKeyExchange = function (t, r, i) {
              if (i < 48)
                return t.error(t, {
                  message: "Invalid key parameters. Only RSA is supported.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.unsupported_certificate,
                  },
                });
              var n = r.fragment,
                a = { enc_pre_master_secret: s(n, 2).getBytes() },
                c = null;
              if (t.getPrivateKey)
                try {
                  (c = t.getPrivateKey(t, t.session.serverCertificate)),
                    (c = e.pki.privateKeyFromPem(c));
                } catch (l) {
                  t.error(t, {
                    message: "Could not get private key.",
                    cause: l,
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.internal_error,
                    },
                  });
                }
              if (null === c)
                return t.error(t, {
                  message: "No private key set.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.internal_error,
                  },
                });
              try {
                var u = t.session.sp;
                u.pre_master_secret = c.decrypt(a.enc_pre_master_secret);
                var p = t.session.clientHelloVersion;
                if (
                  p.major !== u.pre_master_secret.charCodeAt(0) ||
                  p.minor !== u.pre_master_secret.charCodeAt(1)
                )
                  throw new Error("TLS version rollback attack detected.");
              } catch (l) {
                u.pre_master_secret = e.random.getBytes(48);
              }
              (t.expect = S),
                null !== t.session.clientCertificate && (t.expect = E),
                t.process();
            }),
            (o.handleCertificateRequest = function (e, t, r) {
              if (r < 3)
                return e.error(e, {
                  message: "Invalid CertificateRequest. Message too short.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.illegal_parameter,
                  },
                });
              var i = t.fragment,
                n = {
                  certificate_types: s(i, 1),
                  certificate_authorities: s(i, 2),
                };
              (e.session.certificateRequest = n), (e.expect = h), e.process();
            }),
            (o.handleCertificateVerify = function (t, r, i) {
              if (i < 2)
                return t.error(t, {
                  message: "Invalid CertificateVerify. Message too short.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.illegal_parameter,
                  },
                });
              var n = r.fragment;
              n.read -= 4;
              var a = n.bytes();
              n.read += 4;
              var c = { signature: s(n, 2).getBytes() },
                l = e.util.createBuffer();
              l.putBuffer(t.session.md5.digest()),
                l.putBuffer(t.session.sha1.digest()),
                (l = l.getBytes());
              try {
                var u = t.session.clientCertificate;
                if (!u.publicKey.verify(l, c.signature, "NONE"))
                  throw new Error(
                    "CertificateVerify signature does not match."
                  );
                t.session.md5.update(a), t.session.sha1.update(a);
              } catch (p) {
                return t.error(t, {
                  message: "Bad signature in CertificateVerify.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.handshake_failure,
                  },
                });
              }
              (t.expect = S), t.process();
            }),
            (o.handleServerHelloDone = function (t, r, i) {
              if (i > 0)
                return t.error(t, {
                  message: "Invalid ServerHelloDone message. Invalid length.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.record_overflow,
                  },
                });
              if (null === t.serverCertificate) {
                var n = {
                    message:
                      "No server certificate provided. Not enough security.",
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.insufficient_security,
                    },
                  },
                  s = 0,
                  a = t.verify(t, n.alert.description, s, []);
                if (a !== !0)
                  return (
                    (a || 0 === a) &&
                      ("object" != typeof a || e.util.isArray(a)
                        ? "number" == typeof a && (n.alert.description = a)
                        : (a.message && (n.message = a.message),
                          a.alert && (n.alert.description = a.alert))),
                    t.error(t, n)
                  );
              }
              null !== t.session.certificateRequest &&
                ((r = o.createRecord(t, {
                  type: o.ContentType.handshake,
                  data: o.createCertificate(t),
                })),
                o.queue(t, r)),
                (r = o.createRecord(t, {
                  type: o.ContentType.handshake,
                  data: o.createClientKeyExchange(t),
                })),
                o.queue(t, r),
                (t.expect = d);
              var c = function (e, t) {
                null !== e.session.certificateRequest &&
                  null !== e.session.clientCertificate &&
                  o.queue(
                    e,
                    o.createRecord(e, {
                      type: o.ContentType.handshake,
                      data: o.createCertificateVerify(e, t),
                    })
                  ),
                  o.queue(
                    e,
                    o.createRecord(e, {
                      type: o.ContentType.change_cipher_spec,
                      data: o.createChangeCipherSpec(),
                    })
                  ),
                  (e.state.pending = o.createConnectionState(e)),
                  (e.state.current.write = e.state.pending.write),
                  o.queue(
                    e,
                    o.createRecord(e, {
                      type: o.ContentType.handshake,
                      data: o.createFinished(e),
                    })
                  ),
                  (e.expect = f),
                  o.flush(e),
                  e.process();
              };
              return null === t.session.certificateRequest ||
                null === t.session.clientCertificate
                ? c(t, null)
                : void o.getClientSignature(t, c);
            }),
            (o.handleChangeCipherSpec = function (e, t) {
              if (1 !== t.fragment.getByte())
                return e.error(e, {
                  message: "Invalid ChangeCipherSpec message received.",
                  send: !0,
                  alert: {
                    level: o.Alert.Level.fatal,
                    description: o.Alert.Description.illegal_parameter,
                  },
                });
              var r = e.entity === o.ConnectionEnd.client;
              ((e.session.resuming && r) || (!e.session.resuming && !r)) &&
                (e.state.pending = o.createConnectionState(e)),
                (e.state.current.read = e.state.pending.read),
                ((!e.session.resuming && r) || (e.session.resuming && !r)) &&
                  (e.state.pending = null),
                (e.expect = r ? g : _),
                e.process();
            }),
            (o.handleFinished = function (r, i, n) {
              var s = i.fragment;
              s.read -= 4;
              var a = s.bytes();
              s.read += 4;
              var c = i.fragment.getBytes();
              (s = e.util.createBuffer()),
                s.putBuffer(r.session.md5.digest()),
                s.putBuffer(r.session.sha1.digest());
              var l = r.entity === o.ConnectionEnd.client,
                u = l ? "server finished" : "client finished",
                p = r.session.sp,
                h = 12,
                f = t;
              return (
                (s = f(p.master_secret, u, s.getBytes(), h)),
                s.getBytes() !== c
                  ? r.error(r, {
                      message: "Invalid verify_data in Finished message.",
                      send: !0,
                      alert: {
                        level: o.Alert.Level.fatal,
                        description: o.Alert.Description.decrypt_error,
                      },
                    })
                  : (r.session.md5.update(a),
                    r.session.sha1.update(a),
                    ((r.session.resuming && l) ||
                      (!r.session.resuming && !l)) &&
                      (o.queue(
                        r,
                        o.createRecord(r, {
                          type: o.ContentType.change_cipher_spec,
                          data: o.createChangeCipherSpec(),
                        })
                      ),
                      (r.state.current.write = r.state.pending.write),
                      (r.state.pending = null),
                      o.queue(
                        r,
                        o.createRecord(r, {
                          type: o.ContentType.handshake,
                          data: o.createFinished(r),
                        })
                      )),
                    (r.expect = l ? m : b),
                    (r.handshaking = !1),
                    ++r.handshakes,
                    (r.peerCertificate = l
                      ? r.session.serverCertificate
                      : r.session.clientCertificate),
                    o.flush(r),
                    (r.isConnected = !0),
                    r.connected(r),
                    r.process(),
                    void 0)
              );
            }),
            (o.handleAlert = function (e, t) {
              var r,
                i = t.fragment,
                n = { level: i.getByte(), description: i.getByte() };
              switch (n.description) {
                case o.Alert.Description.close_notify:
                  r = "Connection closed.";
                  break;
                case o.Alert.Description.unexpected_message:
                  r = "Unexpected message.";
                  break;
                case o.Alert.Description.bad_record_mac:
                  r = "Bad record MAC.";
                  break;
                case o.Alert.Description.decryption_failed:
                  r = "Decryption failed.";
                  break;
                case o.Alert.Description.record_overflow:
                  r = "Record overflow.";
                  break;
                case o.Alert.Description.decompression_failure:
                  r = "Decompression failed.";
                  break;
                case o.Alert.Description.handshake_failure:
                  r = "Handshake failure.";
                  break;
                case o.Alert.Description.bad_certificate:
                  r = "Bad certificate.";
                  break;
                case o.Alert.Description.unsupported_certificate:
                  r = "Unsupported certificate.";
                  break;
                case o.Alert.Description.certificate_revoked:
                  r = "Certificate revoked.";
                  break;
                case o.Alert.Description.certificate_expired:
                  r = "Certificate expired.";
                  break;
                case o.Alert.Description.certificate_unknown:
                  r = "Certificate unknown.";
                  break;
                case o.Alert.Description.illegal_parameter:
                  r = "Illegal parameter.";
                  break;
                case o.Alert.Description.unknown_ca:
                  r = "Unknown certificate authority.";
                  break;
                case o.Alert.Description.access_denied:
                  r = "Access denied.";
                  break;
                case o.Alert.Description.decode_error:
                  r = "Decode error.";
                  break;
                case o.Alert.Description.decrypt_error:
                  r = "Decrypt error.";
                  break;
                case o.Alert.Description.export_restriction:
                  r = "Export restriction.";
                  break;
                case o.Alert.Description.protocol_version:
                  r = "Unsupported protocol version.";
                  break;
                case o.Alert.Description.insufficient_security:
                  r = "Insufficient security.";
                  break;
                case o.Alert.Description.internal_error:
                  r = "Internal error.";
                  break;
                case o.Alert.Description.user_canceled:
                  r = "User canceled.";
                  break;
                case o.Alert.Description.no_renegotiation:
                  r = "Renegotiation not supported.";
                  break;
                default:
                  r = "Unknown error.";
              }
              return n.description === o.Alert.Description.close_notify
                ? e.close()
                : (e.error(e, {
                    message: r,
                    send: !1,
                    origin:
                      e.entity === o.ConnectionEnd.client ? "server" : "client",
                    alert: n,
                  }),
                  void e.process());
            }),
            (o.handleHandshake = function (t, r) {
              var i = r.fragment,
                n = i.getByte(),
                s = i.getInt24();
              if (s > i.length())
                return (
                  (t.fragmented = r),
                  (r.fragment = e.util.createBuffer()),
                  (i.read -= 4),
                  t.process()
                );
              (t.fragmented = null), (i.read -= 4);
              var a = i.bytes(s + 4);
              (i.read += 4),
                n in x[t.entity][t.expect]
                  ? (t.entity === o.ConnectionEnd.server &&
                      !t.open &&
                      !t.fail &&
                      ((t.handshaking = !0),
                      (t.session = {
                        version: null,
                        extensions: { server_name: { serverNameList: [] } },
                        cipherSuite: null,
                        compressionMethod: null,
                        serverCertificate: null,
                        clientCertificate: null,
                        md5: e.md.md5.create(),
                        sha1: e.md.sha1.create(),
                      })),
                    n !== o.HandshakeType.hello_request &&
                      n !== o.HandshakeType.certificate_verify &&
                      n !== o.HandshakeType.finished &&
                      (t.session.md5.update(a), t.session.sha1.update(a)),
                    x[t.entity][t.expect][n](t, r, s))
                  : o.handleUnexpected(t, r);
            }),
            (o.handleApplicationData = function (e, t) {
              e.data.putBuffer(t.fragment), e.dataReady(e), e.process();
            }),
            (o.handleHeartbeat = function (t, r) {
              var i = r.fragment,
                n = i.getByte(),
                s = i.getInt16(),
                a = i.getBytes(s);
              if (n === o.HeartbeatMessageType.heartbeat_request) {
                if (t.handshaking || s > a.length) return t.process();
                o.queue(
                  t,
                  o.createRecord(t, {
                    type: o.ContentType.heartbeat,
                    data: o.createHeartbeat(
                      o.HeartbeatMessageType.heartbeat_response,
                      a
                    ),
                  })
                ),
                  o.flush(t);
              } else if (n === o.HeartbeatMessageType.heartbeat_response) {
                if (a !== t.expectedHeartbeatPayload) return t.process();
                t.heartbeatReceived &&
                  t.heartbeatReceived(t, e.util.createBuffer(a));
              }
              t.process();
            });
          var c = 0,
            l = 1,
            u = 2,
            p = 3,
            h = 4,
            f = 5,
            g = 6,
            m = 7,
            d = 8,
            y = 0,
            k = 1,
            v = 2,
            E = 3,
            S = 4,
            _ = 5,
            b = 6,
            I = o.handleUnexpected,
            A = o.handleChangeCipherSpec,
            C = o.handleAlert,
            T = o.handleHandshake,
            N = o.handleApplicationData,
            j = o.handleHeartbeat,
            w = [];
          (w[o.ConnectionEnd.client] = [
            [I, C, T, I, j],
            [I, C, T, I, j],
            [I, C, T, I, j],
            [I, C, T, I, j],
            [I, C, T, I, j],
            [A, C, I, I, j],
            [I, C, T, I, j],
            [I, C, T, N, j],
            [I, C, T, I, j],
          ]),
            (w[o.ConnectionEnd.server] = [
              [I, C, T, I, j],
              [I, C, T, I, j],
              [I, C, T, I, j],
              [I, C, T, I, j],
              [A, C, I, I, j],
              [I, C, T, I, j],
              [I, C, T, N, j],
              [I, C, T, I, j],
            ]);
          var R = o.handleHelloRequest,
            O = o.handleServerHello,
            B = o.handleCertificate,
            D = o.handleServerKeyExchange,
            P = o.handleCertificateRequest,
            U = o.handleServerHelloDone,
            L = o.handleFinished,
            x = [];
          x[o.ConnectionEnd.client] = [
            [I, I, O, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, B, D, P, U, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, D, P, U, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, P, U, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, I, U, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, L],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [R, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
          ];
          var G = o.handleClientHello,
            M = o.handleClientKeyExchange,
            K = o.handleCertificateVerify;
          (x[o.ConnectionEnd.server] = [
            [I, G, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, B, I, I, I, I, I, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, M, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, K, I, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, L],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
            [I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I, I],
          ]),
            (o.generateKeys = function (e, r) {
              var i = t,
                n = r.client_random + r.server_random;
              e.session.resuming ||
                ((r.master_secret = i(
                  r.pre_master_secret,
                  "master secret",
                  n,
                  48
                ).bytes()),
                (r.pre_master_secret = null)),
                (n = r.server_random + r.client_random);
              var s = 2 * r.mac_key_length + 2 * r.enc_key_length,
                a =
                  e.version.major === o.Versions.TLS_1_0.major &&
                  e.version.minor === o.Versions.TLS_1_0.minor;
              a && (s += 2 * r.fixed_iv_length);
              var c = i(r.master_secret, "key expansion", n, s),
                l = {
                  client_write_MAC_key: c.getBytes(r.mac_key_length),
                  server_write_MAC_key: c.getBytes(r.mac_key_length),
                  client_write_key: c.getBytes(r.enc_key_length),
                  server_write_key: c.getBytes(r.enc_key_length),
                };
              return (
                a &&
                  ((l.client_write_IV = c.getBytes(r.fixed_iv_length)),
                  (l.server_write_IV = c.getBytes(r.fixed_iv_length))),
                l
              );
            }),
            (o.createConnectionState = function (e) {
              var t = e.entity === o.ConnectionEnd.client,
                r = function () {
                  var e = {
                    sequenceNumber: [0, 0],
                    macKey: null,
                    macLength: 0,
                    macFunction: null,
                    cipherState: null,
                    cipherFunction: function (e) {
                      return !0;
                    },
                    compressionState: null,
                    compressFunction: function (e) {
                      return !0;
                    },
                    updateSequenceNumber: function () {
                      4294967295 === e.sequenceNumber[1]
                        ? ((e.sequenceNumber[1] = 0), ++e.sequenceNumber[0])
                        : ++e.sequenceNumber[1];
                    },
                  };
                  return e;
                },
                s = { read: r(), write: r() };
              if (
                ((s.read.update = function (e, t) {
                  return (
                    s.read.cipherFunction(t, s.read)
                      ? s.read.compressFunction(e, t, s.read) ||
                        e.error(e, {
                          message: "Could not decompress record.",
                          send: !0,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description:
                              o.Alert.Description.decompression_failure,
                          },
                        })
                      : e.error(e, {
                          message: "Could not decrypt record or bad MAC.",
                          send: !0,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.bad_record_mac,
                          },
                        }),
                    !e.fail
                  );
                }),
                (s.write.update = function (e, t) {
                  return (
                    s.write.compressFunction(e, t, s.write)
                      ? s.write.cipherFunction(t, s.write) ||
                        e.error(e, {
                          message: "Could not encrypt record.",
                          send: !1,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.internal_error,
                          },
                        })
                      : e.error(e, {
                          message: "Could not compress record.",
                          send: !1,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.internal_error,
                          },
                        }),
                    !e.fail
                  );
                }),
                e.session)
              ) {
                var a = e.session.sp;
                switch (
                  (e.session.cipherSuite.initSecurityParameters(a),
                  (a.keys = o.generateKeys(e, a)),
                  (s.read.macKey = t
                    ? a.keys.server_write_MAC_key
                    : a.keys.client_write_MAC_key),
                  (s.write.macKey = t
                    ? a.keys.client_write_MAC_key
                    : a.keys.server_write_MAC_key),
                  e.session.cipherSuite.initConnectionState(s, e, a),
                  a.compression_algorithm)
                ) {
                  case o.CompressionMethod.none:
                    break;
                  case o.CompressionMethod.deflate:
                    (s.read.compressFunction = n),
                      (s.write.compressFunction = i);
                    break;
                  default:
                    throw new Error("Unsupported compression algorithm.");
                }
              }
              return s;
            }),
            (o.createRandom = function () {
              var t = new Date(),
                r = +t + 6e4 * t.getTimezoneOffset(),
                i = e.util.createBuffer();
              return i.putInt32(r), i.putBytes(e.random.getBytes(28)), i;
            }),
            (o.createRecord = function (e, t) {
              if (!t.data) return null;
              var r = {
                type: t.type,
                version: { major: e.version.major, minor: e.version.minor },
                length: t.data.length(),
                fragment: t.data,
              };
              return r;
            }),
            (o.createAlert = function (t, r) {
              var i = e.util.createBuffer();
              return (
                i.putByte(r.level),
                i.putByte(r.description),
                o.createRecord(t, { type: o.ContentType.alert, data: i })
              );
            }),
            (o.createClientHello = function (t) {
              t.session.clientHelloVersion = {
                major: t.version.major,
                minor: t.version.minor,
              };
              for (
                var r = e.util.createBuffer(), i = 0;
                i < t.cipherSuites.length;
                ++i
              ) {
                var n = t.cipherSuites[i];
                r.putByte(n.id[0]), r.putByte(n.id[1]);
              }
              var s = r.length(),
                c = e.util.createBuffer();
              c.putByte(o.CompressionMethod.none);
              var l = c.length(),
                u = e.util.createBuffer();
              if (t.virtualHost) {
                var p = e.util.createBuffer();
                p.putByte(0), p.putByte(0);
                var h = e.util.createBuffer();
                h.putByte(0), a(h, 2, e.util.createBuffer(t.virtualHost));
                var f = e.util.createBuffer();
                a(f, 2, h), a(p, 2, f), u.putBuffer(p);
              }
              var g = u.length();
              g > 0 && (g += 2);
              var m = t.session.id,
                d = m.length + 1 + 2 + 4 + 28 + 2 + s + 1 + l + g,
                y = e.util.createBuffer();
              return (
                y.putByte(o.HandshakeType.client_hello),
                y.putInt24(d),
                y.putByte(t.version.major),
                y.putByte(t.version.minor),
                y.putBytes(t.session.sp.client_random),
                a(y, 1, e.util.createBuffer(m)),
                a(y, 2, r),
                a(y, 1, c),
                g > 0 && a(y, 2, u),
                y
              );
            }),
            (o.createServerHello = function (t) {
              var r = t.session.id,
                i = r.length + 1 + 2 + 4 + 28 + 2 + 1,
                n = e.util.createBuffer();
              return (
                n.putByte(o.HandshakeType.server_hello),
                n.putInt24(i),
                n.putByte(t.version.major),
                n.putByte(t.version.minor),
                n.putBytes(t.session.sp.server_random),
                a(n, 1, e.util.createBuffer(r)),
                n.putByte(t.session.cipherSuite.id[0]),
                n.putByte(t.session.cipherSuite.id[1]),
                n.putByte(t.session.compressionMethod),
                n
              );
            }),
            (o.createCertificate = function (t) {
              var r = t.entity === o.ConnectionEnd.client,
                i = null;
              if (t.getCertificate) {
                var n;
                (n = r
                  ? t.session.certificateRequest
                  : t.session.extensions.server_name.serverNameList),
                  (i = t.getCertificate(t, n));
              }
              var s = e.util.createBuffer();
              if (null !== i)
                try {
                  e.util.isArray(i) || (i = [i]);
                  for (var c = null, l = 0; l < i.length; ++l) {
                    var u = e.pem.decode(i[l])[0];
                    if (
                      "CERTIFICATE" !== u.type &&
                      "X509 CERTIFICATE" !== u.type &&
                      "TRUSTED CERTIFICATE" !== u.type
                    ) {
                      var p = new Error(
                        'Could not convert certificate from PEM; PEM header type is not "CERTIFICATE", "X509 CERTIFICATE", or "TRUSTED CERTIFICATE".'
                      );
                      throw ((p.headerType = u.type), p);
                    }
                    if (u.procType && "ENCRYPTED" === u.procType.type)
                      throw new Error(
                        "Could not convert certificate from PEM; PEM is encrypted."
                      );
                    var h = e.util.createBuffer(u.body);
                    null === c && (c = e.asn1.fromDer(h.bytes(), !1));
                    var f = e.util.createBuffer();
                    a(f, 3, h), s.putBuffer(f);
                  }
                  (i = e.pki.certificateFromAsn1(c)),
                    r
                      ? (t.session.clientCertificate = i)
                      : (t.session.serverCertificate = i);
                } catch (g) {
                  return t.error(t, {
                    message: "Could not send certificate list.",
                    cause: g,
                    send: !0,
                    alert: {
                      level: o.Alert.Level.fatal,
                      description: o.Alert.Description.bad_certificate,
                    },
                  });
                }
              var m = 3 + s.length(),
                d = e.util.createBuffer();
              return (
                d.putByte(o.HandshakeType.certificate),
                d.putInt24(m),
                a(d, 3, s),
                d
              );
            }),
            (o.createClientKeyExchange = function (t) {
              var r = e.util.createBuffer();
              r.putByte(t.session.clientHelloVersion.major),
                r.putByte(t.session.clientHelloVersion.minor),
                r.putBytes(e.random.getBytes(46));
              var i = t.session.sp;
              i.pre_master_secret = r.getBytes();
              var n = t.session.serverCertificate.publicKey;
              r = n.encrypt(i.pre_master_secret);
              var s = r.length + 2,
                a = e.util.createBuffer();
              return (
                a.putByte(o.HandshakeType.client_key_exchange),
                a.putInt24(s),
                a.putInt16(r.length),
                a.putBytes(r),
                a
              );
            }),
            (o.createServerKeyExchange = function (t) {
              var r = 0,
                i = e.util.createBuffer();
              return (
                r > 0 &&
                  (i.putByte(o.HandshakeType.server_key_exchange),
                  i.putInt24(r)),
                i
              );
            }),
            (o.getClientSignature = function (t, r) {
              var i = e.util.createBuffer();
              i.putBuffer(t.session.md5.digest()),
                i.putBuffer(t.session.sha1.digest()),
                (i = i.getBytes()),
                (t.getSignature =
                  t.getSignature ||
                  function (t, r, i) {
                    var n = null;
                    if (t.getPrivateKey)
                      try {
                        (n = t.getPrivateKey(t, t.session.clientCertificate)),
                          (n = e.pki.privateKeyFromPem(n));
                      } catch (s) {
                        t.error(t, {
                          message: "Could not get private key.",
                          cause: s,
                          send: !0,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.internal_error,
                          },
                        });
                      }
                    null === n
                      ? t.error(t, {
                          message: "No private key set.",
                          send: !0,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.internal_error,
                          },
                        })
                      : (r = n.sign(r, null)),
                      i(t, r);
                  }),
                t.getSignature(t, i, r);
            }),
            (o.createCertificateVerify = function (t, r) {
              var i = r.length + 2,
                n = e.util.createBuffer();
              return (
                n.putByte(o.HandshakeType.certificate_verify),
                n.putInt24(i),
                n.putInt16(r.length),
                n.putBytes(r),
                n
              );
            }),
            (o.createCertificateRequest = function (t) {
              var r = e.util.createBuffer();
              r.putByte(1);
              var i = e.util.createBuffer();
              for (var n in t.caStore.certs) {
                var s = t.caStore.certs[n],
                  c = e.pki.distinguishedNameToAsn1(s.subject);
                i.putBuffer(e.asn1.toDer(c));
              }
              var l = 1 + r.length() + 2 + i.length(),
                u = e.util.createBuffer();
              return (
                u.putByte(o.HandshakeType.certificate_request),
                u.putInt24(l),
                a(u, 1, r),
                a(u, 2, i),
                u
              );
            }),
            (o.createServerHelloDone = function (t) {
              var r = e.util.createBuffer();
              return (
                r.putByte(o.HandshakeType.server_hello_done), r.putInt24(0), r
              );
            }),
            (o.createChangeCipherSpec = function () {
              var t = e.util.createBuffer();
              return t.putByte(1), t;
            }),
            (o.createFinished = function (r) {
              var i = e.util.createBuffer();
              i.putBuffer(r.session.md5.digest()),
                i.putBuffer(r.session.sha1.digest());
              var n = r.entity === o.ConnectionEnd.client,
                s = r.session.sp,
                a = 12,
                c = t,
                l = n ? "client finished" : "server finished";
              i = c(s.master_secret, l, i.getBytes(), a);
              var u = e.util.createBuffer();
              return (
                u.putByte(o.HandshakeType.finished),
                u.putInt24(i.length()),
                u.putBuffer(i),
                u
              );
            }),
            (o.createHeartbeat = function (t, r, i) {
              "undefined" == typeof i && (i = r.length);
              var n = e.util.createBuffer();
              n.putByte(t), n.putInt16(i), n.putBytes(r);
              var s = n.length(),
                a = Math.max(16, s - i - 3);
              return n.putBytes(e.random.getBytes(a)), n;
            }),
            (o.queue = function (t, r) {
              if (r) {
                if (r.type === o.ContentType.handshake) {
                  var i = r.fragment.bytes();
                  t.session.md5.update(i), t.session.sha1.update(i), (i = null);
                }
                var n;
                if (r.fragment.length() <= o.MaxFragment) n = [r];
                else {
                  n = [];
                  for (var s = r.fragment.bytes(); s.length > o.MaxFragment; )
                    n.push(
                      o.createRecord(t, {
                        type: r.type,
                        data: e.util.createBuffer(s.slice(0, o.MaxFragment)),
                      })
                    ),
                      (s = s.slice(o.MaxFragment));
                  s.length > 0 &&
                    n.push(
                      o.createRecord(t, {
                        type: r.type,
                        data: e.util.createBuffer(s),
                      })
                    );
                }
                for (var a = 0; a < n.length && !t.fail; ++a) {
                  var c = n[a],
                    l = t.state.current.write;
                  l.update(t, c) && t.records.push(c);
                }
              }
            }),
            (o.flush = function (e) {
              for (var t = 0; t < e.records.length; ++t) {
                var r = e.records[t];
                e.tlsData.putByte(r.type),
                  e.tlsData.putByte(r.version.major),
                  e.tlsData.putByte(r.version.minor),
                  e.tlsData.putInt16(r.fragment.length()),
                  e.tlsData.putBuffer(e.records[t].fragment);
              }
              return (e.records = []), e.tlsDataReady(e);
            });
          var V = function (t) {
              switch (t) {
                case !0:
                  return !0;
                case e.pki.certificateError.bad_certificate:
                  return o.Alert.Description.bad_certificate;
                case e.pki.certificateError.unsupported_certificate:
                  return o.Alert.Description.unsupported_certificate;
                case e.pki.certificateError.certificate_revoked:
                  return o.Alert.Description.certificate_revoked;
                case e.pki.certificateError.certificate_expired:
                  return o.Alert.Description.certificate_expired;
                case e.pki.certificateError.certificate_unknown:
                  return o.Alert.Description.certificate_unknown;
                case e.pki.certificateError.unknown_ca:
                  return o.Alert.Description.unknown_ca;
                default:
                  return o.Alert.Description.bad_certificate;
              }
            },
            F = function (t) {
              switch (t) {
                case !0:
                  return !0;
                case o.Alert.Description.bad_certificate:
                  return e.pki.certificateError.bad_certificate;
                case o.Alert.Description.unsupported_certificate:
                  return e.pki.certificateError.unsupported_certificate;
                case o.Alert.Description.certificate_revoked:
                  return e.pki.certificateError.certificate_revoked;
                case o.Alert.Description.certificate_expired:
                  return e.pki.certificateError.certificate_expired;
                case o.Alert.Description.certificate_unknown:
                  return e.pki.certificateError.certificate_unknown;
                case o.Alert.Description.unknown_ca:
                  return e.pki.certificateError.unknown_ca;
                default:
                  return e.pki.certificateError.bad_certificate;
              }
            };
          (o.verifyCertificateChain = function (t, r) {
            try {
              e.pki.verifyCertificateChain(t.caStore, r, function (r, i, n) {
                var s = (V(r), t.verify(t, r, i, n));
                if (s !== !0) {
                  if ("object" == typeof s && !e.util.isArray(s)) {
                    var a = new Error(
                      "The application rejected the certificate."
                    );
                    throw (
                      ((a.send = !0),
                      (a.alert = {
                        level: o.Alert.Level.fatal,
                        description: o.Alert.Description.bad_certificate,
                      }),
                      s.message && (a.message = s.message),
                      s.alert && (a.alert.description = s.alert),
                      a)
                    );
                  }
                  s !== r && (s = F(s));
                }
                return s;
              });
            } catch (i) {
              var n = i;
              ("object" != typeof n || e.util.isArray(n)) &&
                (n = {
                  send: !0,
                  alert: { level: o.Alert.Level.fatal, description: V(i) },
                }),
                "send" in n || (n.send = !0),
                "alert" in n ||
                  (n.alert = {
                    level: o.Alert.Level.fatal,
                    description: V(n.error),
                  }),
                t.error(t, n);
            }
            return !t.fail;
          }),
            (o.createSessionCache = function (t, r) {
              var i = null;
              if (t && t.getSession && t.setSession && t.order) i = t;
              else {
                (i = {}),
                  (i.cache = t || {}),
                  (i.capacity = Math.max(r || 100, 1)),
                  (i.order = []);
                for (var n in t)
                  i.order.length <= r ? i.order.push(n) : delete t[n];
                (i.getSession = function (t) {
                  var r = null,
                    n = null;
                  if (
                    (t
                      ? (n = e.util.bytesToHex(t))
                      : i.order.length > 0 && (n = i.order[0]),
                    null !== n && n in i.cache)
                  ) {
                    (r = i.cache[n]), delete i.cache[n];
                    for (var s in i.order)
                      if (i.order[s] === n) {
                        i.order.splice(s, 1);
                        break;
                      }
                  }
                  return r;
                }),
                  (i.setSession = function (t, r) {
                    if (i.order.length === i.capacity) {
                      var n = i.order.shift();
                      delete i.cache[n];
                    }
                    var n = e.util.bytesToHex(t);
                    i.order.push(n), (i.cache[n] = r);
                  });
              }
              return i;
            }),
            (o.createConnection = function (t) {
              var r = null;
              r = t.caStore
                ? e.util.isArray(t.caStore)
                  ? e.pki.createCaStore(t.caStore)
                  : t.caStore
                : e.pki.createCaStore();
              var i = t.cipherSuites || null;
              if (null === i) {
                i = [];
                for (var n in o.CipherSuites) i.push(o.CipherSuites[n]);
              }
              var s = t.server
                  ? o.ConnectionEnd.server
                  : o.ConnectionEnd.client,
                a = t.sessionCache
                  ? o.createSessionCache(t.sessionCache)
                  : null,
                l = {
                  version: { major: o.Version.major, minor: o.Version.minor },
                  entity: s,
                  sessionId: t.sessionId,
                  caStore: r,
                  sessionCache: a,
                  cipherSuites: i,
                  connected: t.connected,
                  virtualHost: t.virtualHost || null,
                  verifyClient: t.verifyClient || !1,
                  verify:
                    t.verify ||
                    function (e, t, r, i) {
                      return t;
                    },
                  getCertificate: t.getCertificate || null,
                  getPrivateKey: t.getPrivateKey || null,
                  getSignature: t.getSignature || null,
                  input: e.util.createBuffer(),
                  tlsData: e.util.createBuffer(),
                  data: e.util.createBuffer(),
                  tlsDataReady: t.tlsDataReady,
                  dataReady: t.dataReady,
                  heartbeatReceived: t.heartbeatReceived,
                  closed: t.closed,
                  error: function (e, r) {
                    (r.origin =
                      r.origin ||
                      (e.entity === o.ConnectionEnd.client
                        ? "client"
                        : "server")),
                      r.send &&
                        (o.queue(e, o.createAlert(e, r.alert)), o.flush(e));
                    var i = r.fatal !== !1;
                    i && (e.fail = !0), t.error(e, r), i && e.close(!1);
                  },
                  deflate: t.deflate || null,
                  inflate: t.inflate || null,
                };
              (l.reset = function (e) {
                (l.version = {
                  major: o.Version.major,
                  minor: o.Version.minor,
                }),
                  (l.record = null),
                  (l.session = null),
                  (l.peerCertificate = null),
                  (l.state = { pending: null, current: null }),
                  (l.expect = l.entity === o.ConnectionEnd.client ? c : y),
                  (l.fragmented = null),
                  (l.records = []),
                  (l.open = !1),
                  (l.handshakes = 0),
                  (l.handshaking = !1),
                  (l.isConnected = !1),
                  (l.fail = !e && "undefined" != typeof e),
                  l.input.clear(),
                  l.tlsData.clear(),
                  l.data.clear(),
                  (l.state.current = o.createConnectionState(l));
              }),
                l.reset();
              var u = function (e, t) {
                  var r = t.type - o.ContentType.change_cipher_spec,
                    i = w[e.entity][e.expect];
                  r in i ? i[r](e, t) : o.handleUnexpected(e, t);
                },
                p = function (t) {
                  var r = 0,
                    i = t.input,
                    n = i.length();
                  if (n < 5) r = 5 - n;
                  else {
                    t.record = {
                      type: i.getByte(),
                      version: { major: i.getByte(), minor: i.getByte() },
                      length: i.getInt16(),
                      fragment: e.util.createBuffer(),
                      ready: !1,
                    };
                    var s = t.record.version.major === t.version.major;
                    s &&
                      t.session &&
                      t.session.version &&
                      (s = t.record.version.minor === t.version.minor),
                      s ||
                        t.error(t, {
                          message: "Incompatible TLS version.",
                          send: !0,
                          alert: {
                            level: o.Alert.Level.fatal,
                            description: o.Alert.Description.protocol_version,
                          },
                        });
                  }
                  return r;
                },
                h = function (e) {
                  var t = 0,
                    r = e.input,
                    i = r.length();
                  if (i < e.record.length) t = e.record.length - i;
                  else {
                    e.record.fragment.putBytes(r.getBytes(e.record.length)),
                      r.compact();
                    var n = e.state.current.read;
                    n.update(e, e.record) &&
                      (null !== e.fragmented &&
                        (e.fragmented.type === e.record.type
                          ? (e.fragmented.fragment.putBuffer(e.record.fragment),
                            (e.record = e.fragmented))
                          : e.error(e, {
                              message: "Invalid fragmented record.",
                              send: !0,
                              alert: {
                                level: o.Alert.Level.fatal,
                                description:
                                  o.Alert.Description.unexpected_message,
                              },
                            })),
                      (e.record.ready = !0));
                  }
                  return t;
                };
              return (
                (l.handshake = function (t) {
                  if (l.entity !== o.ConnectionEnd.client)
                    l.error(l, {
                      message: "Cannot initiate handshake as a server.",
                      fatal: !1,
                    });
                  else if (l.handshaking)
                    l.error(l, {
                      message: "Handshake already in progress.",
                      fatal: !1,
                    });
                  else {
                    l.fail && !l.open && 0 === l.handshakes && (l.fail = !1),
                      (l.handshaking = !0),
                      (t = t || "");
                    var r = null;
                    t.length > 0 &&
                      (l.sessionCache && (r = l.sessionCache.getSession(t)),
                      null === r && (t = "")),
                      0 === t.length &&
                        l.sessionCache &&
                        ((r = l.sessionCache.getSession()),
                        null !== r && (t = r.id)),
                      (l.session = {
                        id: t,
                        version: null,
                        cipherSuite: null,
                        compressionMethod: null,
                        serverCertificate: null,
                        certificateRequest: null,
                        clientCertificate: null,
                        sp: {},
                        md5: e.md.md5.create(),
                        sha1: e.md.sha1.create(),
                      }),
                      r && ((l.version = r.version), (l.session.sp = r.sp)),
                      (l.session.sp.client_random = o
                        .createRandom()
                        .getBytes()),
                      (l.open = !0),
                      o.queue(
                        l,
                        o.createRecord(l, {
                          type: o.ContentType.handshake,
                          data: o.createClientHello(l),
                        })
                      ),
                      o.flush(l);
                  }
                }),
                (l.process = function (e) {
                  var t = 0;
                  return (
                    e && l.input.putBytes(e),
                    l.fail ||
                      (null !== l.record &&
                        l.record.ready &&
                        l.record.fragment.isEmpty() &&
                        (l.record = null),
                      null === l.record && (t = p(l)),
                      !l.fail &&
                        null !== l.record &&
                        !l.record.ready &&
                        (t = h(l)),
                      !l.fail &&
                        null !== l.record &&
                        l.record.ready &&
                        u(l, l.record)),
                    t
                  );
                }),
                (l.prepare = function (t) {
                  return (
                    o.queue(
                      l,
                      o.createRecord(l, {
                        type: o.ContentType.application_data,
                        data: e.util.createBuffer(t),
                      })
                    ),
                    o.flush(l)
                  );
                }),
                (l.prepareHeartbeatRequest = function (t, r) {
                  return (
                    t instanceof e.util.ByteBuffer && (t = t.bytes()),
                    "undefined" == typeof r && (r = t.length),
                    (l.expectedHeartbeatPayload = t),
                    o.queue(
                      l,
                      o.createRecord(l, {
                        type: o.ContentType.heartbeat,
                        data: o.createHeartbeat(
                          o.HeartbeatMessageType.heartbeat_request,
                          t,
                          r
                        ),
                      })
                    ),
                    o.flush(l)
                  );
                }),
                (l.close = function (e) {
                  if (!l.fail && l.sessionCache && l.session) {
                    var t = {
                      id: l.session.id,
                      version: l.session.version,
                      sp: l.session.sp,
                    };
                    (t.sp.keys = null), l.sessionCache.setSession(t.id, t);
                  }
                  l.open &&
                    ((l.open = !1),
                    l.input.clear(),
                    (l.isConnected || l.handshaking) &&
                      ((l.isConnected = l.handshaking = !1),
                      o.queue(
                        l,
                        o.createAlert(l, {
                          level: o.Alert.Level.warning,
                          description: o.Alert.Description.close_notify,
                        })
                      ),
                      o.flush(l)),
                    l.closed(l)),
                    l.reset(e);
                }),
                l
              );
            }),
            (e.tls = e.tls || {});
          for (var H in o) "function" != typeof o[H] && (e.tls[H] = o[H]);
          (e.tls.prf_tls1 = t),
            (e.tls.hmac_sha1 = r),
            (e.tls.createSessionCache = o.createSessionCache),
            (e.tls.createConnection = o.createConnection);
        }
        var i = "tls";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/tls",
          [
            "require",
            "module",
            "./asn1",
            "./hmac",
            "./md",
            "./pem",
            "./pki",
            "./random",
            "./util",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(t, i, n) {
            var a = i.entity === e.tls.ConnectionEnd.client;
            (t.read.cipherState = {
              init: !1,
              cipher: e.cipher.createDecipher(
                "AES-CBC",
                a ? n.keys.server_write_key : n.keys.client_write_key
              ),
              iv: a ? n.keys.server_write_IV : n.keys.client_write_IV,
            }),
              (t.write.cipherState = {
                init: !1,
                cipher: e.cipher.createCipher(
                  "AES-CBC",
                  a ? n.keys.client_write_key : n.keys.server_write_key
                ),
                iv: a ? n.keys.client_write_IV : n.keys.server_write_IV,
              }),
              (t.read.cipherFunction = s),
              (t.write.cipherFunction = r),
              (t.read.macLength = t.write.macLength = n.mac_length),
              (t.read.macFunction = t.write.macFunction = o.hmac_sha1);
          }
          function r(t, r) {
            var n = !1,
              s = r.macFunction(r.macKey, r.sequenceNumber, t);
            t.fragment.putBytes(s), r.updateSequenceNumber();
            var a;
            (a =
              t.version.minor === o.Versions.TLS_1_0.minor
                ? r.cipherState.init
                  ? null
                  : r.cipherState.iv
                : e.random.getBytesSync(16)),
              (r.cipherState.init = !0);
            var c = r.cipherState.cipher;
            return (
              c.start({ iv: a }),
              t.version.minor >= o.Versions.TLS_1_1.minor &&
                c.output.putBytes(a),
              c.update(t.fragment),
              c.finish(i) &&
                ((t.fragment = c.output),
                (t.length = t.fragment.length()),
                (n = !0)),
              n
            );
          }
          function i(e, t, r) {
            if (!r) {
              var i = e - (t.length() % e);
              t.fillWithByte(i - 1, i);
            }
            return !0;
          }
          function n(e, t, r) {
            var i = !0;
            if (r) {
              for (
                var n = t.length(), s = t.last(), a = n - 1 - s;
                a < n - 1;
                ++a
              )
                i = i && t.at(a) == s;
              i && t.truncate(s + 1);
            }
            return i;
          }
          function s(t, r) {
            var i = !1;
            ++c;
            var s;
            (s =
              t.version.minor === o.Versions.TLS_1_0.minor
                ? r.cipherState.init
                  ? null
                  : r.cipherState.iv
                : t.fragment.getBytes(16)),
              (r.cipherState.init = !0);
            var l = r.cipherState.cipher;
            l.start({ iv: s }), l.update(t.fragment), (i = l.finish(n));
            var u = r.macLength,
              p = e.random.getBytesSync(u),
              h = l.output.length();
            h >= u
              ? ((t.fragment = l.output.getBytes(h - u)),
                (p = l.output.getBytes(u)))
              : (t.fragment = l.output.getBytes()),
              (t.fragment = e.util.createBuffer(t.fragment)),
              (t.length = t.fragment.length());
            var f = r.macFunction(r.macKey, r.sequenceNumber, t);
            return r.updateSequenceNumber(), (i = a(r.macKey, p, f) && i);
          }
          function a(t, r, i) {
            var n = e.hmac.create();
            return (
              n.start("SHA1", t),
              n.update(r),
              (r = n.digest().getBytes()),
              n.start(null, null),
              n.update(i),
              (i = n.digest().getBytes()),
              r === i
            );
          }
          var o = e.tls;
          (o.CipherSuites.TLS_RSA_WITH_AES_128_CBC_SHA = {
            id: [0, 47],
            name: "TLS_RSA_WITH_AES_128_CBC_SHA",
            initSecurityParameters: function (e) {
              (e.bulk_cipher_algorithm = o.BulkCipherAlgorithm.aes),
                (e.cipher_type = o.CipherType.block),
                (e.enc_key_length = 16),
                (e.block_length = 16),
                (e.fixed_iv_length = 16),
                (e.record_iv_length = 16),
                (e.mac_algorithm = o.MACAlgorithm.hmac_sha1),
                (e.mac_length = 20),
                (e.mac_key_length = 20);
            },
            initConnectionState: t,
          }),
            (o.CipherSuites.TLS_RSA_WITH_AES_256_CBC_SHA = {
              id: [0, 53],
              name: "TLS_RSA_WITH_AES_256_CBC_SHA",
              initSecurityParameters: function (e) {
                (e.bulk_cipher_algorithm = o.BulkCipherAlgorithm.aes),
                  (e.cipher_type = o.CipherType.block),
                  (e.enc_key_length = 32),
                  (e.block_length = 16),
                  (e.fixed_iv_length = 16),
                  (e.record_iv_length = 16),
                  (e.mac_algorithm = o.MACAlgorithm.hmac_sha1),
                  (e.mac_length = 20),
                  (e.mac_key_length = 20);
              },
              initConnectionState: t,
            });
          var c = 0;
        }
        var i = "aesCipherSuites";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/aesCipherSuites",
          ["require", "module", "./aes", "./tls"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          (e.debug = e.debug || {}),
            (e.debug.storage = {}),
            (e.debug.get = function (t, r) {
              var i;
              return (
                "undefined" == typeof t
                  ? (i = e.debug.storage)
                  : t in e.debug.storage &&
                    (i =
                      "undefined" == typeof r
                        ? e.debug.storage[t]
                        : e.debug.storage[t][r]),
                i
              );
            }),
            (e.debug.set = function (t, r, i) {
              t in e.debug.storage || (e.debug.storage[t] = {}),
                (e.debug.storage[t][r] = i);
            }),
            (e.debug.clear = function (t, r) {
              "undefined" == typeof t
                ? (e.debug.storage = {})
                : t in e.debug.storage &&
                  ("undefined" == typeof r
                    ? delete e.debug.storage[t]
                    : delete e.debug.storage[t][r]);
            });
        }
        var i = "debug";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/debug", ["require", "module"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t, r, i, n) {
            t.generate = function (t, s) {
              for (
                var a = new e.util.ByteBuffer(),
                  o = Math.ceil(s / n) + i,
                  c = new e.util.ByteBuffer(),
                  l = i;
                l < o;
                ++l
              ) {
                c.putInt32(l), r.start(), r.update(t + c.getBytes());
                var u = r.digest();
                a.putBytes(u.getBytes(n));
              }
              return a.truncate(a.length() - s), a.getBytes();
            };
          }
          e.kem = e.kem || {};
          var r = e.jsbn.BigInteger;
          (e.kem.rsa = {}),
            (e.kem.rsa.create = function (t, i) {
              i = i || {};
              var n = i.prng || e.random,
                s = {};
              return (
                (s.encrypt = function (i, s) {
                  var a,
                    o = Math.ceil(i.n.bitLength() / 8);
                  do
                    a = new r(e.util.bytesToHex(n.getBytesSync(o)), 16).mod(
                      i.n
                    );
                  while (a.equals(r.ZERO));
                  a = e.util.hexToBytes(a.toString(16));
                  var c = o - a.length;
                  c > 0 &&
                    (a = e.util.fillString(String.fromCharCode(0), c) + a);
                  var l = i.encrypt(a, "NONE"),
                    u = t.generate(a, s);
                  return { encapsulation: l, key: u };
                }),
                (s.decrypt = function (e, r, i) {
                  var n = e.decrypt(r, "NONE");
                  return t.generate(n, i);
                }),
                s
              );
            }),
            (e.kem.kdf1 = function (e, r) {
              t(this, e, 0, r || e.digestLength);
            }),
            (e.kem.kdf2 = function (e, r) {
              t(this, e, 1, r || e.digestLength);
            });
        }
        var i = "kem";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/kem",
          ["require", "module", "./util", "./random", "./jsbn"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          (e.log = e.log || {}),
            (e.log.levels = [
              "none",
              "error",
              "warning",
              "info",
              "debug",
              "verbose",
              "max",
            ]);
          var t = {},
            r = [],
            i = null;
          (e.log.LEVEL_LOCKED = 2),
            (e.log.NO_LEVEL_CHECK = 4),
            (e.log.INTERPOLATE = 8);
          for (var n = 0; n < e.log.levels.length; ++n) {
            var s = e.log.levels[n];
            t[s] = { index: n, name: s.toUpperCase() };
          }
          (e.log.logMessage = function (i) {
            for (var n = t[i.level].index, s = 0; s < r.length; ++s) {
              var a = r[s];
              if (a.flags & e.log.NO_LEVEL_CHECK) a.f(i);
              else {
                var o = t[a.level].index;
                n <= o && a.f(a, i);
              }
            }
          }),
            (e.log.prepareStandard = function (e) {
              "standard" in e ||
                (e.standard =
                  t[e.level].name + " [" + e.category + "] " + e.message);
            }),
            (e.log.prepareFull = function (t) {
              if (!("full" in t)) {
                var r = [t.message];
                (r = r.concat([] || t.arguments)),
                  (t.full = e.util.format.apply(this, r));
              }
            }),
            (e.log.prepareStandardFull = function (t) {
              "standardFull" in t ||
                (e.log.prepareStandard(t), (t.standardFull = t.standard));
            });
          for (
            var a = ["error", "warning", "info", "debug", "verbose"], n = 0;
            n < a.length;
            ++n
          )
            (function (t) {
              e.log[t] = function (r, i) {
                var n = Array.prototype.slice.call(arguments).slice(2),
                  s = {
                    timestamp: new Date(),
                    level: t,
                    category: r,
                    message: i,
                    arguments: n,
                  };
                e.log.logMessage(s);
              };
            })(a[n]);
          if (
            ((e.log.makeLogger = function (t) {
              var r = { flags: 0, f: t };
              return e.log.setLevel(r, "none"), r;
            }),
            (e.log.setLevel = function (t, r) {
              var i = !1;
              if (t && !(t.flags & e.log.LEVEL_LOCKED))
                for (var n = 0; n < e.log.levels.length; ++n) {
                  var s = e.log.levels[n];
                  if (r == s) {
                    (t.level = r), (i = !0);
                    break;
                  }
                }
              return i;
            }),
            (e.log.lock = function (t, r) {
              "undefined" == typeof r || r
                ? (t.flags |= e.log.LEVEL_LOCKED)
                : (t.flags &= ~e.log.LEVEL_LOCKED);
            }),
            (e.log.addLogger = function (e) {
              r.push(e);
            }),
            "undefined" != typeof console && "log" in console)
          ) {
            var o;
            if (
              console.error &&
              console.warn &&
              console.info &&
              console.debug
            ) {
              var c = {
                  error: console.error,
                  warning: console.warn,
                  info: console.info,
                  debug: console.debug,
                  verbose: console.debug,
                },
                l = function (t, r) {
                  e.log.prepareStandard(r);
                  var i = c[r.level],
                    n = [r.standard];
                  (n = n.concat(r.arguments.slice())), i.apply(console, n);
                };
              o = e.log.makeLogger(l);
            } else {
              var l = function (t, r) {
                e.log.prepareStandardFull(r);
              };
              o = e.log.makeLogger(l);
            }
            e.log.setLevel(o, "debug"), e.log.addLogger(o), (i = o);
          } else console = { log: function () {} };
          if (null !== i) {
            var u = e.util.getQueryVariables();
            if (
              ("console.level" in u &&
                e.log.setLevel(i, u["console.level"].slice(-1)[0]),
              "console.lock" in u)
            ) {
              var p = u["console.lock"].slice(-1)[0];
              "true" == p && e.log.lock(i);
            }
          }
          e.log.consoleLogger = i;
        }
        var i = "log";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })("js/log", ["require", "module", "./util"], function () {
          a.apply(null, Array.prototype.slice.call(arguments, 0));
        });
      })(),
      (function () {
        function e(e) {
          function t(t) {
            var r = {},
              i = [];
            if (!h.validate(t, f.asn1.recipientInfoValidator, r, i)) {
              var n = new Error(
                "Cannot read PKCS#7 RecipientInfo. ASN.1 object is not an PKCS#7 RecipientInfo."
              );
              throw ((n.errors = i), n);
            }
            return {
              version: r.version.charCodeAt(0),
              issuer: e.pki.RDNAttributesAsArray(r.issuer),
              serialNumber: e.util.createBuffer(r.serial).toHex(),
              encryptedContent: {
                algorithm: h.derToOid(r.encAlgorithm),
                parameter: r.encParameter.value,
                content: r.encKey,
              },
            };
          }
          function r(t) {
            return h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
              h.create(
                h.Class.UNIVERSAL,
                h.Type.INTEGER,
                !1,
                h.integerToDer(t.version).getBytes()
              ),
              h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                e.pki.distinguishedNameToAsn1({ attributes: t.issuer }),
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.INTEGER,
                  !1,
                  e.util.hexToBytes(t.serialNumber)
                ),
              ]),
              h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.OID,
                  !1,
                  h.oidToDer(t.encryptedContent.algorithm).getBytes()
                ),
                h.create(h.Class.UNIVERSAL, h.Type.NULL, !1, ""),
              ]),
              h.create(
                h.Class.UNIVERSAL,
                h.Type.OCTETSTRING,
                !1,
                t.encryptedContent.content
              ),
            ]);
          }
          function i(e) {
            for (var r = [], i = 0; i < e.length; ++i) r.push(t(e[i]));
            return r;
          }
          function n(e) {
            for (var t = [], i = 0; i < e.length; ++i) t.push(r(e[i]));
            return t;
          }
          function s(t) {
            var r = {},
              i = [];
            if (!h.validate(t, f.asn1.signerInfoValidator, r, i)) {
              var n = new Error(
                "Cannot read PKCS#7 SignerInfo. ASN.1 object is not an PKCS#7 SignerInfo."
              );
              throw ((n.errors = i), n);
            }
            var s = {
              version: r.version.charCodeAt(0),
              issuer: e.pki.RDNAttributesAsArray(r.issuer),
              serialNumber: e.util.createBuffer(r.serial).toHex(),
              digestAlgorithm: h.derToOid(r.digestAlgorithm),
              signatureAlgorithm: h.derToOid(r.signatureAlgorithm),
              signature: r.signature,
              authenticatedAttributes: [],
              unauthenticatedAttributes: [],
            };
            r.authenticatedAttributes || [], r.unauthenticatedAttributes || [];
            return s;
          }
          function a(t) {
            var r = h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
              h.create(
                h.Class.UNIVERSAL,
                h.Type.INTEGER,
                !1,
                h.integerToDer(t.version).getBytes()
              ),
              h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                e.pki.distinguishedNameToAsn1({ attributes: t.issuer }),
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.INTEGER,
                  !1,
                  e.util.hexToBytes(t.serialNumber)
                ),
              ]),
              h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.OID,
                  !1,
                  h.oidToDer(t.digestAlgorithm).getBytes()
                ),
                h.create(h.Class.UNIVERSAL, h.Type.NULL, !1, ""),
              ]),
            ]);
            if (
              (t.authenticatedAttributesAsn1 &&
                r.value.push(t.authenticatedAttributesAsn1),
              r.value.push(
                h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                  h.create(
                    h.Class.UNIVERSAL,
                    h.Type.OID,
                    !1,
                    h.oidToDer(t.signatureAlgorithm).getBytes()
                  ),
                  h.create(h.Class.UNIVERSAL, h.Type.NULL, !1, ""),
                ])
              ),
              r.value.push(
                h.create(h.Class.UNIVERSAL, h.Type.OCTETSTRING, !1, t.signature)
              ),
              t.unauthenticatedAttributes.length > 0)
            ) {
              for (
                var i = h.create(h.Class.CONTEXT_SPECIFIC, 1, !0, []), n = 0;
                n < t.unauthenticatedAttributes.length;
                ++n
              ) {
                var s = t.unauthenticatedAttributes[n];
                i.values.push(c(s));
              }
              r.value.push(i);
            }
            return r;
          }
          function o(e) {
            for (var t = [], r = 0; r < e.length; ++r) t.push(a(e[r]));
            return t;
          }
          function c(t) {
            var r;
            if (t.type === e.pki.oids.contentType)
              r = h.create(
                h.Class.UNIVERSAL,
                h.Type.OID,
                !1,
                h.oidToDer(t.value).getBytes()
              );
            else if (t.type === e.pki.oids.messageDigest)
              r = h.create(
                h.Class.UNIVERSAL,
                h.Type.OCTETSTRING,
                !1,
                t.value.bytes()
              );
            else if (t.type === e.pki.oids.signingTime) {
              var i = new Date("Jan 1, 1950 00:00:00Z"),
                n = new Date("Jan 1, 2050 00:00:00Z"),
                s = t.value;
              if ("string" == typeof s) {
                var a = Date.parse(s);
                s = isNaN(a)
                  ? 13 === s.length
                    ? h.utcTimeToDate(s)
                    : h.generalizedTimeToDate(s)
                  : new Date(a);
              }
              r =
                s >= i && s < n
                  ? h.create(
                      h.Class.UNIVERSAL,
                      h.Type.UTCTIME,
                      !1,
                      h.dateToUtcTime(s)
                    )
                  : h.create(
                      h.Class.UNIVERSAL,
                      h.Type.GENERALIZEDTIME,
                      !1,
                      h.dateToGeneralizedTime(s)
                    );
            }
            return h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
              h.create(
                h.Class.UNIVERSAL,
                h.Type.OID,
                !1,
                h.oidToDer(t.type).getBytes()
              ),
              h.create(h.Class.UNIVERSAL, h.Type.SET, !0, [r]),
            ]);
          }
          function l(t) {
            return [
              h.create(
                h.Class.UNIVERSAL,
                h.Type.OID,
                !1,
                h.oidToDer(e.pki.oids.data).getBytes()
              ),
              h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.OID,
                  !1,
                  h.oidToDer(t.algorithm).getBytes()
                ),
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.OCTETSTRING,
                  !1,
                  t.parameter.getBytes()
                ),
              ]),
              h.create(h.Class.CONTEXT_SPECIFIC, 0, !0, [
                h.create(
                  h.Class.UNIVERSAL,
                  h.Type.OCTETSTRING,
                  !1,
                  t.content.getBytes()
                ),
              ]),
            ];
          }
          function u(t, r, i) {
            var n = {},
              s = [];
            if (!h.validate(r, i, n, s)) {
              var a = new Error(
                "Cannot read PKCS#7 message. ASN.1 object is not a supported PKCS#7 message."
              );
              throw ((a.errors = a), a);
            }
            var o = h.derToOid(n.contentType);
            if (o !== e.pki.oids.data)
              throw new Error(
                "Unsupported PKCS#7 message. Only wrapped ContentType Data supported."
              );
            if (n.encryptedContent) {
              var c = "";
              if (e.util.isArray(n.encryptedContent))
                for (var l = 0; l < n.encryptedContent.length; ++l) {
                  if (n.encryptedContent[l].type !== h.Type.OCTETSTRING)
                    throw new Error(
                      "Malformed PKCS#7 message, expecting encrypted content constructed of only OCTET STRING objects."
                    );
                  c += n.encryptedContent[l].value;
                }
              else c = n.encryptedContent;
              t.encryptedContent = {
                algorithm: h.derToOid(n.encAlgorithm),
                parameter: e.util.createBuffer(n.encParameter.value),
                content: e.util.createBuffer(c),
              };
            }
            if (n.content) {
              var c = "";
              if (e.util.isArray(n.content))
                for (var l = 0; l < n.content.length; ++l) {
                  if (n.content[l].type !== h.Type.OCTETSTRING)
                    throw new Error(
                      "Malformed PKCS#7 message, expecting content constructed of only OCTET STRING objects."
                    );
                  c += n.content[l].value;
                }
              else c = n.content;
              t.content = e.util.createBuffer(c);
            }
            return (t.version = n.version.charCodeAt(0)), (t.rawCapture = n), n;
          }
          function p(t) {
            if (void 0 === t.encryptedContent.key)
              throw new Error("Symmetric key not available.");
            if (void 0 === t.content) {
              var r;
              switch (t.encryptedContent.algorithm) {
                case e.pki.oids["aes128-CBC"]:
                case e.pki.oids["aes192-CBC"]:
                case e.pki.oids["aes256-CBC"]:
                  r = e.aes.createDecryptionCipher(t.encryptedContent.key);
                  break;
                case e.pki.oids.desCBC:
                case e.pki.oids["des-EDE3-CBC"]:
                  r = e.des.createDecryptionCipher(t.encryptedContent.key);
                  break;
                default:
                  throw new Error(
                    "Unsupported symmetric cipher, OID " +
                      t.encryptedContent.algorithm
                  );
              }
              if (
                (r.start(t.encryptedContent.parameter),
                r.update(t.encryptedContent.content),
                !r.finish())
              )
                throw new Error("Symmetric decryption failed.");
              t.content = r.output;
            }
          }
          var h = e.asn1,
            f = (e.pkcs7 = e.pkcs7 || {});
          (f.messageFromPem = function (t) {
            var r = e.pem.decode(t)[0];
            if ("PKCS7" !== r.type) {
              var i = new Error(
                'Could not convert PKCS#7 message from PEM; PEM header type is not "PKCS#7".'
              );
              throw ((i.headerType = r.type), i);
            }
            if (r.procType && "ENCRYPTED" === r.procType.type)
              throw new Error(
                "Could not convert PKCS#7 message from PEM; PEM is encrypted."
              );
            var n = h.fromDer(r.body);
            return f.messageFromAsn1(n);
          }),
            (f.messageToPem = function (t, r) {
              var i = { type: "PKCS7", body: h.toDer(t.toAsn1()).getBytes() };
              return e.pem.encode(i, { maxline: r });
            }),
            (f.messageFromAsn1 = function (t) {
              var r = {},
                i = [];
              if (!h.validate(t, f.asn1.contentInfoValidator, r, i)) {
                var n = new Error(
                  "Cannot read PKCS#7 message. ASN.1 object is not an PKCS#7 ContentInfo."
                );
                throw ((n.errors = i), n);
              }
              var s,
                a = h.derToOid(r.contentType);
              switch (a) {
                case e.pki.oids.envelopedData:
                  s = f.createEnvelopedData();
                  break;
                case e.pki.oids.encryptedData:
                  s = f.createEncryptedData();
                  break;
                case e.pki.oids.signedData:
                  s = f.createSignedData();
                  break;
                default:
                  throw new Error(
                    "Cannot read PKCS#7 message. ContentType with OID " +
                      a +
                      " is not (yet) supported."
                  );
              }
              return s.fromAsn1(r.content.value[0]), s;
            }),
            (f.createSignedData = function () {
              function t() {
                for (var t = {}, r = 0; r < i.signers.length; ++r) {
                  var n = i.signers[r],
                    s = n.digestAlgorithm;
                  s in t || (t[s] = e.md[e.pki.oids[s]].create()),
                    0 === n.authenticatedAttributes.length
                      ? (n.md = t[s])
                      : (n.md = e.md[e.pki.oids[s]].create());
                }
                i.digestAlgorithmIdentifiers = [];
                for (var s in t)
                  i.digestAlgorithmIdentifiers.push(
                    h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                      h.create(
                        h.Class.UNIVERSAL,
                        h.Type.OID,
                        !1,
                        h.oidToDer(s).getBytes()
                      ),
                      h.create(h.Class.UNIVERSAL, h.Type.NULL, !1, ""),
                    ])
                  );
                return t;
              }
              function r(t) {
                if (i.contentInfo.value.length < 2)
                  throw new Error(
                    "Could not sign PKCS#7 message; there is no content to sign."
                  );
                var r = h.derToOid(i.contentInfo.value[0].value),
                  n = i.contentInfo.value[1];
                n = n.value[0];
                var s = h.toDer(n);
                s.getByte(), h.getBerValueLength(s), (s = s.getBytes());
                for (var a in t) t[a].start().update(s);
                for (var l = new Date(), u = 0; u < i.signers.length; ++u) {
                  var p = i.signers[u];
                  if (0 === p.authenticatedAttributes.length) {
                    if (r !== e.pki.oids.data)
                      throw new Error(
                        "Invalid signer; authenticatedAttributes must be present when the ContentInfo content type is not PKCS#7 Data."
                      );
                  } else {
                    p.authenticatedAttributesAsn1 = h.create(
                      h.Class.CONTEXT_SPECIFIC,
                      0,
                      !0,
                      []
                    );
                    for (
                      var f = h.create(h.Class.UNIVERSAL, h.Type.SET, !0, []),
                        g = 0;
                      g < p.authenticatedAttributes.length;
                      ++g
                    ) {
                      var m = p.authenticatedAttributes[g];
                      m.type === e.pki.oids.messageDigest
                        ? (m.value = t[p.digestAlgorithm].digest())
                        : m.type === e.pki.oids.signingTime &&
                          (m.value || (m.value = l)),
                        f.value.push(c(m)),
                        p.authenticatedAttributesAsn1.value.push(c(m));
                    }
                    (s = h.toDer(f).getBytes()), p.md.start().update(s);
                  }
                  p.signature = p.key.sign(p.md, "RSASSA-PKCS1-V1_5");
                }
                i.signerInfos = o(i.signers);
              }
              var i = null;
              return (i = {
                type: e.pki.oids.signedData,
                version: 1,
                certificates: [],
                crls: [],
                signers: [],
                digestAlgorithmIdentifiers: [],
                contentInfo: null,
                signerInfos: [],
                fromAsn1: function (t) {
                  u(i, t, f.asn1.signedDataValidator),
                    (i.certificates = []),
                    (i.crls = []),
                    (i.digestAlgorithmIdentifiers = []),
                    (i.contentInfo = null),
                    (i.signerInfos = []);
                  for (
                    var r = i.rawCapture.certificates.value, n = 0;
                    n < r.length;
                    ++n
                  )
                    i.certificates.push(e.pki.certificateFromAsn1(r[n]));
                },
                toAsn1: function () {
                  i.contentInfo || i.sign();
                  for (var t = [], r = 0; r < i.certificates.length; ++r)
                    t.push(e.pki.certificateToAsn1(i.certificates[r]));
                  var n = [],
                    s = h.create(h.Class.CONTEXT_SPECIFIC, 0, !0, [
                      h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.INTEGER,
                          !1,
                          h.integerToDer(i.version).getBytes()
                        ),
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.SET,
                          !0,
                          i.digestAlgorithmIdentifiers
                        ),
                        i.contentInfo,
                      ]),
                    ]);
                  return (
                    t.length > 0 &&
                      s.value[0].value.push(
                        h.create(h.Class.CONTEXT_SPECIFIC, 0, !0, t)
                      ),
                    n.length > 0 &&
                      s.value[0].value.push(
                        h.create(h.Class.CONTEXT_SPECIFIC, 1, !0, n)
                      ),
                    s.value[0].value.push(
                      h.create(h.Class.UNIVERSAL, h.Type.SET, !0, i.signerInfos)
                    ),
                    h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                      h.create(
                        h.Class.UNIVERSAL,
                        h.Type.OID,
                        !1,
                        h.oidToDer(i.type).getBytes()
                      ),
                      s,
                    ])
                  );
                },
                addSigner: function (t) {
                  var r = t.issuer,
                    n = t.serialNumber;
                  if (t.certificate) {
                    var s = t.certificate;
                    "string" == typeof s && (s = e.pki.certificateFromPem(s)),
                      (r = s.issuer.attributes),
                      (n = s.serialNumber);
                  }
                  var a = t.key;
                  if (!a)
                    throw new Error(
                      "Could not add PKCS#7 signer; no private key specified."
                    );
                  "string" == typeof a && (a = e.pki.privateKeyFromPem(a));
                  var o = t.digestAlgorithm || e.pki.oids.sha1;
                  switch (o) {
                    case e.pki.oids.sha1:
                    case e.pki.oids.sha256:
                    case e.pki.oids.sha384:
                    case e.pki.oids.sha512:
                    case e.pki.oids.md5:
                      break;
                    default:
                      throw new Error(
                        "Could not add PKCS#7 signer; unknown message digest algorithm: " +
                          o
                      );
                  }
                  var c = t.authenticatedAttributes || [];
                  if (c.length > 0) {
                    for (var l = !1, u = !1, p = 0; p < c.length; ++p) {
                      var h = c[p];
                      if (l || h.type !== e.pki.oids.contentType) {
                        if (u || h.type !== e.pki.oids.messageDigest);
                        else if (((u = !0), l)) break;
                      } else if (((l = !0), u)) break;
                    }
                    if (!l || !u)
                      throw new Error(
                        "Invalid signer.authenticatedAttributes. If signer.authenticatedAttributes is specified, then it must contain at least two attributes, PKCS #9 content-type and PKCS #9 message-digest."
                      );
                  }
                  i.signers.push({
                    key: a,
                    version: 1,
                    issuer: r,
                    serialNumber: n,
                    digestAlgorithm: o,
                    signatureAlgorithm: e.pki.oids.rsaEncryption,
                    signature: null,
                    authenticatedAttributes: c,
                    unauthenticatedAttributes: [],
                  });
                },
                sign: function () {
                  if (
                    ("object" != typeof i.content || null === i.contentInfo) &&
                    ((i.contentInfo = h.create(
                      h.Class.UNIVERSAL,
                      h.Type.SEQUENCE,
                      !0,
                      [
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.OID,
                          !1,
                          h.oidToDer(e.pki.oids.data).getBytes()
                        ),
                      ]
                    )),
                    "content" in i)
                  ) {
                    var n;
                    i.content instanceof e.util.ByteBuffer
                      ? (n = i.content.bytes())
                      : "string" == typeof i.content &&
                        (n = e.util.encodeUtf8(i.content)),
                      i.contentInfo.value.push(
                        h.create(h.Class.CONTEXT_SPECIFIC, 0, !0, [
                          h.create(
                            h.Class.UNIVERSAL,
                            h.Type.OCTETSTRING,
                            !1,
                            n
                          ),
                        ])
                      );
                  }
                  if (0 !== i.signers.length) {
                    var s = t();
                    r(s);
                  }
                },
                verify: function () {
                  throw new Error(
                    "PKCS#7 signature verification not yet implemented."
                  );
                },
                addCertificate: function (t) {
                  "string" == typeof t && (t = e.pki.certificateFromPem(t)),
                    i.certificates.push(t);
                },
                addCertificateRevokationList: function (e) {
                  throw new Error("PKCS#7 CRL support not yet implemented.");
                },
              });
            }),
            (f.createEncryptedData = function () {
              var t = null;
              return (t = {
                type: e.pki.oids.encryptedData,
                version: 0,
                encryptedContent: { algorithm: e.pki.oids["aes256-CBC"] },
                fromAsn1: function (e) {
                  u(t, e, f.asn1.encryptedDataValidator);
                },
                decrypt: function (e) {
                  void 0 !== e && (t.encryptedContent.key = e), p(t);
                },
              });
            }),
            (f.createEnvelopedData = function () {
              var t = null;
              return (t = {
                type: e.pki.oids.envelopedData,
                version: 0,
                recipients: [],
                encryptedContent: { algorithm: e.pki.oids["aes256-CBC"] },
                fromAsn1: function (e) {
                  var r = u(t, e, f.asn1.envelopedDataValidator);
                  t.recipients = i(r.recipientInfos.value);
                },
                toAsn1: function () {
                  return h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                    h.create(
                      h.Class.UNIVERSAL,
                      h.Type.OID,
                      !1,
                      h.oidToDer(t.type).getBytes()
                    ),
                    h.create(h.Class.CONTEXT_SPECIFIC, 0, !0, [
                      h.create(h.Class.UNIVERSAL, h.Type.SEQUENCE, !0, [
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.INTEGER,
                          !1,
                          h.integerToDer(t.version).getBytes()
                        ),
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.SET,
                          !0,
                          n(t.recipients)
                        ),
                        h.create(
                          h.Class.UNIVERSAL,
                          h.Type.SEQUENCE,
                          !0,
                          l(t.encryptedContent)
                        ),
                      ]),
                    ]),
                  ]);
                },
                findRecipient: function (e) {
                  for (
                    var r = e.issuer.attributes, i = 0;
                    i < t.recipients.length;
                    ++i
                  ) {
                    var n = t.recipients[i],
                      s = n.issuer;
                    if (
                      n.serialNumber === e.serialNumber &&
                      s.length === r.length
                    ) {
                      for (var a = !0, o = 0; o < r.length; ++o)
                        if (
                          s[o].type !== r[o].type ||
                          s[o].value !== r[o].value
                        ) {
                          a = !1;
                          break;
                        }
                      if (a) return n;
                    }
                  }
                  return null;
                },
                decrypt: function (r, i) {
                  if (
                    void 0 === t.encryptedContent.key &&
                    void 0 !== r &&
                    void 0 !== i
                  )
                    switch (r.encryptedContent.algorithm) {
                      case e.pki.oids.rsaEncryption:
                      case e.pki.oids.desCBC:
                        var n = i.decrypt(r.encryptedContent.content);
                        t.encryptedContent.key = e.util.createBuffer(n);
                        break;
                      default:
                        throw new Error(
                          "Unsupported asymmetric cipher, OID " +
                            r.encryptedContent.algorithm
                        );
                    }
                  p(t);
                },
                addRecipient: function (r) {
                  t.recipients.push({
                    version: 0,
                    issuer: r.issuer.attributes,
                    serialNumber: r.serialNumber,
                    encryptedContent: {
                      algorithm: e.pki.oids.rsaEncryption,
                      key: r.publicKey,
                    },
                  });
                },
                encrypt: function (r, i) {
                  if (void 0 === t.encryptedContent.content) {
                    (i = i || t.encryptedContent.algorithm),
                      (r = r || t.encryptedContent.key);
                    var n, s, a;
                    switch (i) {
                      case e.pki.oids["aes128-CBC"]:
                        (n = 16), (s = 16), (a = e.aes.createEncryptionCipher);
                        break;
                      case e.pki.oids["aes192-CBC"]:
                        (n = 24), (s = 16), (a = e.aes.createEncryptionCipher);
                        break;
                      case e.pki.oids["aes256-CBC"]:
                        (n = 32), (s = 16), (a = e.aes.createEncryptionCipher);
                        break;
                      case e.pki.oids["des-EDE3-CBC"]:
                        (n = 24), (s = 8), (a = e.des.createEncryptionCipher);
                        break;
                      default:
                        throw new Error(
                          "Unsupported symmetric cipher, OID " + i
                        );
                    }
                    if (void 0 === r)
                      r = e.util.createBuffer(e.random.getBytes(n));
                    else if (r.length() != n)
                      throw new Error(
                        "Symmetric key has wrong length; got " +
                          r.length() +
                          " bytes, expected " +
                          n +
                          "."
                      );
                    (t.encryptedContent.algorithm = i),
                      (t.encryptedContent.key = r),
                      (t.encryptedContent.parameter = e.util.createBuffer(
                        e.random.getBytes(s)
                      ));
                    var o = a(r);
                    if (
                      (o.start(t.encryptedContent.parameter.copy()),
                      o.update(t.content),
                      !o.finish())
                    )
                      throw new Error("Symmetric encryption failed.");
                    t.encryptedContent.content = o.output;
                  }
                  for (var c = 0; c < t.recipients.length; ++c) {
                    var l = t.recipients[c];
                    if (void 0 === l.encryptedContent.content)
                      switch (l.encryptedContent.algorithm) {
                        case e.pki.oids.rsaEncryption:
                          l.encryptedContent.content =
                            l.encryptedContent.key.encrypt(
                              t.encryptedContent.key.data
                            );
                          break;
                        default:
                          throw new Error(
                            "Unsupported asymmetric cipher, OID " +
                              l.encryptedContent.algorithm
                          );
                      }
                  }
                },
              });
            });
        }
        var i = "pkcs7";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/pkcs7",
          [
            "require",
            "module",
            "./aes",
            "./asn1",
            "./des",
            "./oids",
            "./pem",
            "./pkcs7asn1",
            "./random",
            "./util",
            "./x509",
          ],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          function t(t, r) {
            var i = r.toString(16);
            i[0] >= "8" && (i = "00" + i);
            var n = e.util.hexToBytes(i);
            t.putInt32(n.length), t.putBytes(n);
          }
          function r(e, t) {
            e.putInt32(t.length), e.putString(t);
          }
          function i() {
            for (
              var t = e.md.sha1.create(), r = arguments.length, i = 0;
              i < r;
              ++i
            )
              t.update(arguments[i]);
            return t.digest();
          }
          var n = (e.ssh = e.ssh || {});
          (n.privateKeyToPutty = function (n, s, a) {
            (a = a || ""), (s = s || "");
            var o = "ssh-rsa",
              c = "" === s ? "none" : "aes256-cbc",
              l = "PuTTY-User-Key-File-2: " + o + "\r\n";
            (l += "Encryption: " + c + "\r\n"), (l += "Comment: " + a + "\r\n");
            var u = e.util.createBuffer();
            r(u, o), t(u, n.e), t(u, n.n);
            var p = e.util.encode64(u.bytes(), 64),
              h = Math.floor(p.length / 66) + 1;
            (l += "Public-Lines: " + h + "\r\n"), (l += p);
            var f = e.util.createBuffer();
            t(f, n.d), t(f, n.p), t(f, n.q), t(f, n.qInv);
            var g;
            if (s) {
              var m = f.length() + 16 - 1;
              m -= m % 16;
              var d = i(f.bytes());
              d.truncate(d.length() - m + f.length()), f.putBuffer(d);
              var y = e.util.createBuffer();
              y.putBuffer(i("\0\0\0\0", s)), y.putBuffer(i("\0\0\0", s));
              var k = e.aes.createEncryptionCipher(y.truncate(8), "CBC");
              k.start(e.util.createBuffer().fillWithByte(0, 16)),
                k.update(f.copy()),
                k.finish();
              var v = k.output;
              v.truncate(16), (g = e.util.encode64(v.bytes(), 64));
            } else g = e.util.encode64(f.bytes(), 64);
            (h = Math.floor(g.length / 66) + 1),
              (l += "\r\nPrivate-Lines: " + h + "\r\n"),
              (l += g);
            var E = i("putty-private-key-file-mac-key", s),
              S = e.util.createBuffer();
            r(S, o),
              r(S, c),
              r(S, a),
              S.putInt32(u.length()),
              S.putBuffer(u),
              S.putInt32(f.length()),
              S.putBuffer(f);
            var _ = e.hmac.create();
            return (
              _.start("sha1", E),
              _.update(S.bytes()),
              (l += "\r\nPrivate-MAC: " + _.digest().toHex() + "\r\n")
            );
          }),
            (n.publicKeyToOpenSSH = function (i, n) {
              var s = "ssh-rsa";
              n = n || "";
              var a = e.util.createBuffer();
              return (
                r(a, s),
                t(a, i.e),
                t(a, i.n),
                s + " " + e.util.encode64(a.bytes()) + " " + n
              );
            }),
            (n.privateKeyToOpenSSH = function (t, r) {
              return r
                ? e.pki.encryptRsaPrivateKey(t, r, {
                    legacy: !0,
                    algorithm: "aes128",
                  })
                : e.pki.privateKeyToPem(t);
            }),
            (n.getPublicKeyFingerprint = function (i, n) {
              n = n || {};
              var s = n.md || e.md.md5.create(),
                a = "ssh-rsa",
                o = e.util.createBuffer();
              r(o, a), t(o, i.e), t(o, i.n), s.start(), s.update(o.getBytes());
              var c = s.digest();
              if ("hex" === n.encoding) {
                var l = c.toHex();
                return n.delimiter ? l.match(/.{2}/g).join(n.delimiter) : l;
              }
              if ("binary" === n.encoding) return c.getBytes();
              if (n.encoding)
                throw new Error('Unknown encoding "' + n.encoding + '".');
              return c;
            });
        }
        var i = "ssh";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/ssh",
          ["require", "module", "./aes", "./hmac", "./md5", "./sha1", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        function e(e) {
          var t = "forge.task",
            r = 0,
            i = {},
            n = 0;
          e.debug.set(t, "tasks", i);
          var s = {};
          e.debug.set(t, "queues", s);
          var a = "?",
            o = 30,
            c = 20,
            l = "ready",
            u = "running",
            p = "blocked",
            h = "sleeping",
            f = "done",
            g = "error",
            m = "stop",
            d = "start",
            y = "block",
            k = "unblock",
            v = "sleep",
            E = "wakeup",
            S = "cancel",
            _ = "fail",
            b = {};
          (b[l] = {}),
            (b[l][m] = l),
            (b[l][d] = u),
            (b[l][S] = f),
            (b[l][_] = g),
            (b[u] = {}),
            (b[u][m] = l),
            (b[u][d] = u),
            (b[u][y] = p),
            (b[u][k] = u),
            (b[u][v] = h),
            (b[u][E] = u),
            (b[u][S] = f),
            (b[u][_] = g),
            (b[p] = {}),
            (b[p][m] = p),
            (b[p][d] = p),
            (b[p][y] = p),
            (b[p][k] = p),
            (b[p][v] = p),
            (b[p][E] = p),
            (b[p][S] = f),
            (b[p][_] = g),
            (b[h] = {}),
            (b[h][m] = h),
            (b[h][d] = h),
            (b[h][y] = h),
            (b[h][k] = h),
            (b[h][v] = h),
            (b[h][E] = h),
            (b[h][S] = f),
            (b[h][_] = g),
            (b[f] = {}),
            (b[f][m] = f),
            (b[f][d] = f),
            (b[f][y] = f),
            (b[f][k] = f),
            (b[f][v] = f),
            (b[f][E] = f),
            (b[f][S] = f),
            (b[f][_] = g),
            (b[g] = {}),
            (b[g][m] = g),
            (b[g][d] = g),
            (b[g][y] = g),
            (b[g][k] = g),
            (b[g][v] = g),
            (b[g][E] = g),
            (b[g][S] = g),
            (b[g][_] = g);
          var I = function (s) {
            (this.id = -1),
              (this.name = s.name || a),
              (this.parent = s.parent || null),
              (this.run = s.run),
              (this.subtasks = []),
              (this.error = !1),
              (this.state = l),
              (this.blocks = 0),
              (this.timeoutId = null),
              (this.swapTime = null),
              (this.userData = null),
              (this.id = n++),
              (i[this.id] = this),
              r >= 1 &&
                e.log.verbose(t, "[%s][%s] init", this.id, this.name, this);
          };
          (I.prototype.debug = function (r) {
            (r = r || ""),
              e.log.debug(
                t,
                r,
                "[%s][%s] task:",
                this.id,
                this.name,
                this,
                "subtasks:",
                this.subtasks.length,
                "queue:",
                s
              );
          }),
            (I.prototype.next = function (e, t) {
              "function" == typeof e && ((t = e), (e = this.name));
              var r = new I({ run: t, name: e, parent: this });
              return (
                (r.state = u),
                (r.type = this.type),
                (r.successCallback = this.successCallback || null),
                (r.failureCallback = this.failureCallback || null),
                this.subtasks.push(r),
                this
              );
            }),
            (I.prototype.parallel = function (t, r) {
              return (
                e.util.isArray(t) && ((r = t), (t = this.name)),
                this.next(t, function (i) {
                  var n = i;
                  n.block(r.length);
                  for (
                    var s = function (t, i) {
                        e.task.start({
                          type: t,
                          run: function (e) {
                            r[i](e);
                          },
                          success: function (e) {
                            n.unblock();
                          },
                          failure: function (e) {
                            n.unblock();
                          },
                        });
                      },
                      a = 0;
                    a < r.length;
                    a++
                  ) {
                    var o = t + "__parallel-" + i.id + "-" + a,
                      c = a;
                    s(o, c);
                  }
                })
              );
            }),
            (I.prototype.stop = function () {
              this.state = b[this.state][m];
            }),
            (I.prototype.start = function () {
              (this.error = !1),
                (this.state = b[this.state][d]),
                this.state === u &&
                  ((this.start = new Date()), this.run(this), C(this, 0));
            }),
            (I.prototype.block = function (e) {
              (e = "undefined" == typeof e ? 1 : e),
                (this.blocks += e),
                this.blocks > 0 && (this.state = b[this.state][y]);
            }),
            (I.prototype.unblock = function (e) {
              return (
                (e = "undefined" == typeof e ? 1 : e),
                (this.blocks -= e),
                0 === this.blocks &&
                  this.state !== f &&
                  ((this.state = u), C(this, 0)),
                this.blocks
              );
            }),
            (I.prototype.sleep = function (e) {
              (e = "undefined" == typeof e ? 0 : e),
                (this.state = b[this.state][v]);
              var t = this;
              this.timeoutId = setTimeout(function () {
                (t.timeoutId = null), (t.state = u), C(t, 0);
              }, e);
            }),
            (I.prototype.wait = function (e) {
              e.wait(this);
            }),
            (I.prototype.wakeup = function () {
              this.state === h &&
                (cancelTimeout(this.timeoutId),
                (this.timeoutId = null),
                (this.state = u),
                C(this, 0));
            }),
            (I.prototype.cancel = function () {
              (this.state = b[this.state][S]),
                (this.permitsNeeded = 0),
                null !== this.timeoutId &&
                  (cancelTimeout(this.timeoutId), (this.timeoutId = null)),
                (this.subtasks = []);
            }),
            (I.prototype.fail = function (e) {
              if (((this.error = !0), T(this, !0), e))
                (e.error = this.error),
                  (e.swapTime = this.swapTime),
                  (e.userData = this.userData),
                  C(e, 0);
              else {
                if (null !== this.parent) {
                  for (var t = this.parent; null !== t.parent; )
                    (t.error = this.error),
                      (t.swapTime = this.swapTime),
                      (t.userData = this.userData),
                      (t = t.parent);
                  T(t, !0);
                }
                this.failureCallback && this.failureCallback(this);
              }
            });
          var A = function (e) {
              (e.error = !1),
                (e.state = b[e.state][d]),
                setTimeout(function () {
                  e.state === u &&
                    ((e.swapTime = +new Date()), e.run(e), C(e, 0));
                }, 0);
            },
            C = function (e, t) {
              var r = t > o || +new Date() - e.swapTime > c,
                i = function (t) {
                  if ((t++, e.state === u))
                    if (
                      (r && (e.swapTime = +new Date()), e.subtasks.length > 0)
                    ) {
                      var i = e.subtasks.shift();
                      (i.error = e.error),
                        (i.swapTime = e.swapTime),
                        (i.userData = e.userData),
                        i.run(i),
                        i.error || C(i, t);
                    } else
                      T(e),
                        e.error ||
                          (null !== e.parent &&
                            ((e.parent.error = e.error),
                            (e.parent.swapTime = e.swapTime),
                            (e.parent.userData = e.userData),
                            C(e.parent, t)));
                };
              r ? setTimeout(i, 0) : i(t);
            },
            T = function (n, a) {
              (n.state = f),
                delete i[n.id],
                r >= 1 && e.log.verbose(t, "[%s][%s] finish", n.id, n.name, n),
                null === n.parent &&
                  (n.type in s
                    ? 0 === s[n.type].length
                      ? e.log.error(
                          t,
                          "[%s][%s] task queue empty [%s]",
                          n.id,
                          n.name,
                          n.type
                        )
                      : s[n.type][0] !== n
                      ? e.log.error(
                          t,
                          "[%s][%s] task not first in queue [%s]",
                          n.id,
                          n.name,
                          n.type
                        )
                      : (s[n.type].shift(),
                        0 === s[n.type].length
                          ? (r >= 1 &&
                              e.log.verbose(
                                t,
                                "[%s][%s] delete queue [%s]",
                                n.id,
                                n.name,
                                n.type
                              ),
                            delete s[n.type])
                          : (r >= 1 &&
                              e.log.verbose(
                                t,
                                "[%s][%s] queue start next [%s] remain:%s",
                                n.id,
                                n.name,
                                n.type,
                                s[n.type].length
                              ),
                            s[n.type][0].start()))
                    : e.log.error(
                        t,
                        "[%s][%s] task queue missing [%s]",
                        n.id,
                        n.name,
                        n.type
                      ),
                  a ||
                    (n.error && n.failureCallback
                      ? n.failureCallback(n)
                      : !n.error && n.successCallback && n.successCallback(n)));
            };
          (e.task = e.task || {}),
            (e.task.start = function (i) {
              var n = new I({ run: i.run, name: i.name || a });
              (n.type = i.type),
                (n.successCallback = i.success || null),
                (n.failureCallback = i.failure || null),
                n.type in s
                  ? s[i.type].push(n)
                  : (r >= 1 &&
                      e.log.verbose(
                        t,
                        "[%s][%s] create queue [%s]",
                        n.id,
                        n.name,
                        n.type
                      ),
                    (s[n.type] = [n]),
                    A(n));
            }),
            (e.task.cancel = function (e) {
              e in s && (s[e] = [s[e][0]]);
            }),
            (e.task.createCondition = function () {
              var e = { tasks: {} };
              return (
                (e.wait = function (t) {
                  t.id in e.tasks || (t.block(), (e.tasks[t.id] = t));
                }),
                (e.notify = function () {
                  var t = e.tasks;
                  e.tasks = {};
                  for (var r in t) t[r].unblock();
                }),
                e
              );
            });
        }
        var i = "task";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return "undefined" == typeof forge && (forge = {}), e(forge);
          var n = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var s,
          a = function (t, r) {
            r.exports = function (r) {
              var n = s
                .map(function (e) {
                  return t(e);
                })
                .concat(e);
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[i]))
                return r[i];
              r.defined[i] = !0;
              for (var a = 0; a < n.length; ++a) n[a](r);
              return r[i];
            };
          },
          o = r;
        (r = function (e, t) {
          return (
            (s = "string" == typeof e ? t.slice(2) : e.slice(2)),
            n
              ? (delete r,
                o.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = o),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/task",
          ["require", "module", "./debug", "./log", "./util"],
          function () {
            a.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      (function () {
        var e = "forge";
        if ("function" != typeof r) {
          if ("object" != typeof module || !module.exports)
            return void (
              "undefined" == typeof forge && (forge = { disableNativeCode: !1 })
            );
          var i = !0;
          r = function (e, r) {
            r(t, module);
          };
        }
        var n,
          s = function (t, r) {
            (r.exports = function (r) {
              var i = n.map(function (e) {
                return t(e);
              });
              if (((r = r || {}), (r.defined = r.defined || {}), r.defined[e]))
                return r[e];
              r.defined[e] = !0;
              for (var s = 0; s < i.length; ++s) i[s](r);
              return r;
            }),
              (r.exports.disableNativeCode = !1),
              r.exports(r.exports);
          },
          a = r;
        (r = function (e, t) {
          return (
            (n = "string" == typeof e ? t.slice(2) : e.slice(2)),
            i
              ? (delete r,
                a.apply(null, Array.prototype.slice.call(arguments, 0)))
              : ((r = a),
                r.apply(null, Array.prototype.slice.call(arguments, 0)))
          );
        })(
          "js/forge",
          [
            "require",
            "module",
            "./aes",
            "./aesCipherSuites",
            "./asn1",
            "./cipher",
            "./cipherModes",
            "./debug",
            "./des",
            "./hmac",
            "./kem",
            "./log",
            "./md",
            "./mgf1",
            "./pbkdf2",
            "./pem",
            "./pkcs7",
            "./pkcs1",
            "./pkcs12",
            "./pki",
            "./prime",
            "./prng",
            "./pss",
            "./random",
            "./rc2",
            "./ssh",
            "./task",
            "./tls",
            "./util",
          ],
          function () {
            s.apply(null, Array.prototype.slice.call(arguments, 0));
          }
        );
      })(),
      t("js/forge")
    );
  }),
  (function (e) {
    function t(e, t) {
      for (var r = 0, i = e.length - 1; i >= 0; i--)
        r += e[e.length - 1 - i] * Math.pow(2, t * i);
      return r;
    }
    function r(e, t, r) {
      r = r || -1;
      for (var i = 0, n = Math.pow(2, t), s = 1; s < 8; s++) {
        if (e < n) {
          var a;
          if (r < 0) (a = new ArrayBuffer(s)), (i = s);
          else {
            if (r < s) return new ArrayBuffer(0);
            (a = new ArrayBuffer(r)), (i = r);
          }
          for (var o = new Uint8Array(a), c = s - 1; c >= 0; c--) {
            var l = Math.pow(2, c * t);
            (o[i - c - 1] = Math.floor(e / l)), (e -= o[i - c - 1] * l);
          }
          return a;
        }
        n *= Math.pow(2, t);
      }
    }
    function i(e) {
      for (var t = e < 0 ? e * -1 : e, i = 128, n = 1; n < 8; n++) {
        if (t <= i) {
          if (e < 0) {
            var a = i - t,
              o = r(a, 8, n),
              c = new Uint8Array(o);
            return (c[0] |= 128), o;
          }
          var o = r(t, 8, n),
            c = new Uint8Array(o);
          if (128 & c[0]) {
            var l = s(o),
              u = new Uint8Array(l);
            (o = new ArrayBuffer(o.byteLength + 1)), (c = new Uint8Array(o));
            for (var p = 0; p < l.byteLength; p++) c[p + 1] = u[p];
            c[0] = 0;
          }
          return o;
        }
        i *= Math.pow(2, 8);
      }
      return new ArrayBuffer(0);
    }
    function n() {
      var e = new Uint8Array(this.value_hex);
      if (this.value_hex.byteLength >= 2) {
        var r = 255 == e[0] && 128 & e[1],
          i = 0 == e[0] && 0 == (128 & e[1]);
        (r || i) && this.warnings.push("Needlessly long format");
      }
      for (
        var n = new ArrayBuffer(this.value_hex.byteLength),
          s = new Uint8Array(n),
          a = 0;
        a < this.value_hex.byteLength;
        a++
      )
        s[a] = 0;
      s[0] = 128 & e[0];
      for (
        var o = t(s, 8),
          c = new ArrayBuffer(this.value_hex.byteLength),
          l = new Uint8Array(c),
          u = 0;
        u < this.value_hex.byteLength;
        u++
      )
        l[u] = e[u];
      l[0] &= 127;
      var p = t(l, 8);
      return p - o;
    }
    function s(e) {
      if (c(e, 0, e.byteLength) === !1) return new ArrayBuffer(0);
      for (
        var t = new Uint8Array(e),
          r = new ArrayBuffer(e.byteLength),
          i = new Uint8Array(r),
          n = 0;
        n < e.byteLength;
        n++
      )
        i[n] = t[n];
      return r;
    }
    function a(e, t, r) {
      if (c(e, t, r) === !1) return new ArrayBuffer(0);
      for (
        var i = new Uint8Array(e, t, r),
          n = new ArrayBuffer(r),
          s = new Uint8Array(n),
          a = 0;
        a < r;
        a++
      )
        s[a] = i[a];
      return n;
    }
    function o(e, t) {
      for (
        var r = new Uint8Array(e),
          i = new Uint8Array(t),
          n = new ArrayBuffer(e.byteLength + t.byteLength),
          s = new Uint8Array(n),
          a = 0;
        a < e.byteLength;
        a++
      )
        s[a] = r[a];
      for (var o = 0; o < t.byteLength; o++) s[e.byteLength + o] = i[o];
      return n;
    }
    function c(e, t, r) {
      return e instanceof ArrayBuffer == !1
        ? ((this.error = 'Wrong parameter: input_buffer must be "ArrayBuffer"'),
          !1)
        : 0 === e.byteLength
        ? ((this.error = "Wrong parameter: input_buffer has zero length"), !1)
        : t < 0
        ? ((this.error = "Wrong parameter: input_offset less than zero"), !1)
        : r < 0
        ? ((this.error = "Wrong parameter: input_length less than zero"), !1)
        : !(e.byteLength - t - r < 0) ||
          ((this.error =
            "End of input reached before message was fully decoded (inconsistent offset and length values)"),
          !1);
    }
    function l(e, t, r) {
      if (c(e, t, r) === !1) return "";
      for (var i = "", n = new Uint8Array(e, t, r), s = 0; s < n.length; s++) {
        var a = n[s].toString(16).toUpperCase();
        i = i + (1 === a.length ? " 0" : " ") + a;
      }
      return i;
    }
    function u(t, r, i) {
      function n(e, t) {
        if (e instanceof t) return e;
        var r = new t();
        return (
          (r.id_block = e.id_block),
          (r.len_block = e.len_block),
          (r.warnings = e.warnings),
          (r.value_before_decode = s(e.value_before_decode)),
          r
        );
      }
      var o = r,
        l = new e.org.pkijs.asn1.ASN1_block();
      if (c(t, r, i) === !1)
        return (l.error = "Wrong input parameters"), { offset: -1, result: l };
      var u = new Uint8Array(t, r, i);
      if (0 == u.length)
        return (this.error = "Zero buffer length"), { offset: -1, result: l };
      var p = l.id_block.fromBER(t, r, i);
      if ((l.warnings.concat(l.id_block.warnings), p == -1))
        return (l.error = l.id_block.error), { offset: -1, result: l };
      if (
        ((r = p),
        (i -= l.id_block.block_length),
        (p = l.len_block.fromBER(t, r, i)),
        l.warnings.concat(l.len_block.warnings),
        p == -1)
      )
        return (l.error = l.len_block.error), { offset: -1, result: l };
      if (
        ((r = p),
        (i -= l.len_block.block_length),
        0 == l.id_block.is_constructed && 1 == l.len_block.is_indefinite_form)
      )
        return (
          (l.error = new String(
            "Indefinite length form used for primitive encoding form"
          )),
          { offset: -1, result: l }
        );
      var h = e.org.pkijs.asn1.ASN1_block;
      switch (l.id_block.tag_class) {
        case 1:
          if (l.id_block.tag_number >= 37 && 0 == l.id_block.is_hex_only)
            return (
              (l.error =
                "UNIVERSAL 37 and upper tags are reserved by ASN.1 standard"),
              { offset: -1, result: l }
            );
          switch (l.id_block.tag_number) {
            case 0:
              if (1 == l.id_block.is_constructed && l.len_block.length > 0)
                return (
                  (l.error = "Type [UNIVERSAL 0] is reserved"),
                  { offset: -1, result: l }
                );
              h = e.org.pkijs.asn1.EOC;
              break;
            case 1:
              h = e.org.pkijs.asn1.BOOLEAN;
              break;
            case 2:
              h = e.org.pkijs.asn1.INTEGER;
              break;
            case 3:
              h = e.org.pkijs.asn1.BITSTRING;
              break;
            case 4:
              h = e.org.pkijs.asn1.OCTETSTRING;
              break;
            case 5:
              h = e.org.pkijs.asn1.NULL;
              break;
            case 6:
              h = e.org.pkijs.asn1.OID;
              break;
            case 10:
              h = e.org.pkijs.asn1.ENUMERATED;
              break;
            case 12:
              h = e.org.pkijs.asn1.UTF8STRING;
              break;
            case 14:
              h = e.org.pkijs.asn1.TIME;
              break;
            case 15:
              return (
                (l.error = "[UNIVERSAL 15] is reserved by ASN.1 standard"),
                { offset: -1, result: l }
              );
            case 16:
              h = e.org.pkijs.asn1.SEQUENCE;
              break;
            case 17:
              h = e.org.pkijs.asn1.SET;
              break;
            case 18:
              h = e.org.pkijs.asn1.NUMERICSTRING;
              break;
            case 19:
              h = e.org.pkijs.asn1.PRINTABLESTRING;
              break;
            case 20:
              h = e.org.pkijs.asn1.TELETEXSTRING;
              break;
            case 21:
              h = e.org.pkijs.asn1.VIDEOTEXSTRING;
              break;
            case 22:
              h = e.org.pkijs.asn1.IA5STRING;
              break;
            case 23:
              h = e.org.pkijs.asn1.UTCTIME;
              break;
            case 24:
              h = e.org.pkijs.asn1.GENERALIZEDTIME;
              break;
            case 25:
              h = e.org.pkijs.asn1.GRAPHICSTRING;
              break;
            case 26:
              h = e.org.pkijs.asn1.VISIBLESTRING;
              break;
            case 27:
              h = e.org.pkijs.asn1.GENERALSTRING;
              break;
            case 28:
              h = e.org.pkijs.asn1.UNIVERSALSTRING;
              break;
            case 29:
              h = e.org.pkijs.asn1.CHARACTERSTRING;
              break;
            case 30:
              h = e.org.pkijs.asn1.BMPSTRING;
              break;
            case 31:
              h = e.org.pkijs.asn1.DATE;
              break;
            case 33:
              h = e.org.pkijs.asn1.DATETIME;
              break;
            default:
              var f;
              (f =
                1 == l.id_block.is_constructed
                  ? new e.org.pkijs.asn1.ASN1_CONSTRUCTED()
                  : new e.org.pkijs.asn1.ASN1_PRIMITIVE()),
                (f.id_block = l.id_block),
                (f.len_block = l.len_block),
                (f.warnings = l.warnings),
                (l = f),
                (p = l.fromBER(t, r, i));
          }
          break;
        case 2:
        case 3:
        case 4:
        default:
          h =
            1 == l.id_block.is_constructed
              ? e.org.pkijs.asn1.ASN1_CONSTRUCTED
              : e.org.pkijs.asn1.ASN1_PRIMITIVE;
      }
      return (
        (l = n(l, h)),
        (p = l.fromBER(
          t,
          r,
          1 == l.len_block.is_indefinite_form ? i : l.len_block.length
        )),
        (l.value_before_decode = a(t, o, l.block_length)),
        { offset: p, result: l }
      );
    }
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.asn1) e.org.pkijs.asn1 = {};
    else if ("object" != typeof e.org.pkijs.asn1)
      throw new Error(
        "Name org.pkijs.asn1 already exists and it's not an object but " +
          typeof e.org.pkijs.asn1
      );
    var p = {};
    (p.base_block = function () {
      arguments[0] instanceof Object
        ? ((this.block_length = e.org.pkijs.getValue(
            arguments[0],
            "block_length",
            0
          )),
          (this.error = e.org.pkijs.getValue(
            arguments[0],
            "error",
            new String()
          )),
          (this.warnings = e.org.pkijs.getValue(
            arguments[0],
            "warnings",
            new Array()
          )),
          "value_before_decode" in arguments[0]
            ? (this.value_before_decode = s(arguments[0].value_before_decode))
            : (this.value_before_decode = new ArrayBuffer(0)))
        : ((this.block_length = 0),
          (this.error = new String()),
          (this.warnings = new Array()),
          (this.value_before_decode = new ArrayBuffer(0)));
    }),
      (p.base_block.prototype.block_name = function () {
        return "base_block";
      }),
      (p.base_block.prototype.toJSON = function () {
        return {
          block_name: p.base_block.prototype.block_name.call(this),
          block_length: this.block_length,
          error: this.error,
          warnings: this.warnings,
          value_before_decode: e.org.pkijs.bufferToHexCodes(
            this.value_before_decode,
            0,
            this.value_before_decode.byteLength
          ),
        };
      }),
      (p.hex_block = function () {
        p.base_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.is_hex_only = e.org.pkijs.getValue(
                arguments[0],
                "is_hex_only",
                !1
              )),
              "value_hex" in arguments[0]
                ? (this.value_hex = s(arguments[0].value_hex))
                : (this.value_hex = new ArrayBuffer(0)))
            : ((this.is_hex_only = !1), (this.value_hex = new ArrayBuffer(0)));
      }),
      (p.hex_block.prototype = new p.base_block()),
      (p.hex_block.constructor = p.hex_block),
      (p.hex_block.prototype.block_name = function () {
        return "hex_block";
      }),
      (p.hex_block.prototype.fromBER = function (e, t, r) {
        if (c.call(this, e, t, r) === !1) return -1;
        var i = new Uint8Array(e, t, r);
        if (0 == i.length) return this.warnings.push("Zero buffer length"), t;
        this.value_hex = new ArrayBuffer(r);
        for (var n = new Uint8Array(this.value_hex), s = 0; s < i.length; s++)
          n[s] = i[s];
        return (this.block_length = r), t + r;
      }),
      (p.hex_block.prototype.toBER = function (e) {
        if (("undefined" == typeof e && (e = !1), this.is_hex_only !== !0))
          return (
            (this.error = 'Flag "is_hex_only" is not set, abort'),
            new ArrayBuffer(0)
          );
        var t = new ArrayBuffer(this.value_hex.byteLength);
        if (e === !0) return t;
        for (
          var r = new Uint8Array(t), i = new Uint8Array(this.value_hex), n = 0;
          n < i.length;
          n++
        )
          r[n] = i[n];
        return t;
      }),
      (p.hex_block.prototype.toJSON = function () {
        var t = p.base_block.prototype.toJSON.call(this);
        return (
          (t.block_name = p.hex_block.prototype.block_name.call(this)),
          (t.is_hex_only = this.is_hex_only),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          t
        );
      }),
      (p.identification_block = function () {
        p.hex_block.call(this, arguments[0]),
          (this.tag_class = -1),
          (this.tag_number = -1),
          (this.is_constructed = !1),
          arguments[0] instanceof Object &&
            "id_block" in arguments[0] &&
            ((this.is_hex_only = e.org.pkijs.getValue(
              arguments[0].id_block,
              "is_hex_only",
              !1
            )),
            (this.value_hex = e.org.pkijs.getValue(
              arguments[0].id_block,
              "value_hex",
              new ArrayBuffer(0)
            )),
            (this.tag_class = e.org.pkijs.getValue(
              arguments[0].id_block,
              "tag_class",
              -1
            )),
            (this.tag_number = e.org.pkijs.getValue(
              arguments[0].id_block,
              "tag_number",
              -1
            )),
            (this.is_constructed = e.org.pkijs.getValue(
              arguments[0].id_block,
              "is_constructed",
              !1
            )));
      }),
      (p.identification_block.prototype = new p.hex_block()),
      (p.identification_block.constructor = p.identification_block),
      (p.identification_block.prototype.block_name = function () {
        return "identification_block";
      }),
      (p.identification_block.prototype.toBER = function (e) {
        "undefined" == typeof e && (e = !1);
        var t = 0;
        switch (this.tag_class) {
          case 1:
            t |= 0;
            break;
          case 2:
            t |= 64;
            break;
          case 3:
            t |= 128;
            break;
          case 4:
            t |= 192;
            break;
          default:
            return (this.error = "Unknown tag class"), new ArrayBuffer(0);
        }
        if (
          (this.is_constructed && (t |= 32),
          this.tag_number < 31 && !this.is_hex_only)
        ) {
          var i = new ArrayBuffer(1),
            n = new Uint8Array(i);
          if (!e) {
            var s = this.tag_number;
            (s &= 31), (t |= s), (n[0] = t);
          }
          return i;
        }
        if (this.is_hex_only === !1) {
          var a = r(this.tag_number, 7),
            o = new Uint8Array(a),
            c = a.byteLength,
            i = new ArrayBuffer(c + 1),
            n = new Uint8Array(i);
          if (((n[0] = 31 | t), !e)) {
            for (var l = 0; l < c - 1; l++) n[l + 1] = 128 | o[l];
            n[c] = o[c - 1];
          }
          return i;
        }
        var i = new ArrayBuffer(this.value_hex.byteLength + 1),
          n = new Uint8Array(i);
        if (((n[0] = 31 | t), e === !1)) {
          for (
            var u = new Uint8Array(this.value_hex), l = 0;
            l < u.length - 1;
            l++
          )
            n[l + 1] = 128 | u[l];
          n[this.value_hex.byteLength] = u[u.length - 1];
        }
        return i;
      }),
      (p.identification_block.prototype.fromBER = function (e, r, i) {
        if (c.call(this, e, r, i) === !1) return -1;
        var n = new Uint8Array(e, r, i);
        if (0 == n.length) return (this.error = "Zero buffer length"), -1;
        var s = 192 & n[0];
        switch (s) {
          case 0:
            this.tag_class = 1;
            break;
          case 64:
            this.tag_class = 2;
            break;
          case 128:
            this.tag_class = 3;
            break;
          case 192:
            this.tag_class = 4;
            break;
          default:
            return (this.error = "Unknown tag class"), -1;
        }
        32 == (32 & n[0])
          ? (this.is_constructed = !0)
          : (this.is_constructed = !1),
          (this.is_hex_only = !1);
        var a = 31 & n[0];
        if (31 != a) (this.tag_number = a), (this.block_length = 1);
        else {
          var o = 1;
          this.value_hex = new ArrayBuffer(255);
          for (var l = 255, u = new Uint8Array(this.value_hex); 128 & n[o]; ) {
            if (((u[o - 1] = 127 & n[o]), o++, o >= n.length))
              return (
                (this.error =
                  "End of input reached before message was fully decoded"),
                -1
              );
            if (o == l) {
              l += 255;
              for (
                var p = new ArrayBuffer(l), h = new Uint8Array(p), f = 0;
                f < u.length;
                f++
              )
                h[f] = u[f];
              (this.value_hex = new ArrayBuffer(l)),
                (u = new Uint8Array(this.value_hex));
            }
          }
          (this.block_length = o + 1), (u[o - 1] = 127 & n[o]);
          for (
            var p = new ArrayBuffer(o), h = new Uint8Array(p), f = 0;
            f < o;
            f++
          )
            h[f] = u[f];
          (this.value_hex = new ArrayBuffer(o)),
            (u = new Uint8Array(this.value_hex)),
            u.set(h),
            this.block_length <= 9
              ? (this.tag_number = t(u, 7))
              : ((this.is_hex_only = !0),
                this.warnings.push("Tag too long, represented as hex-coded"));
        }
        if (1 == this.tag_class && this.is_constructed)
          switch (this.tag_number) {
            case 1:
            case 2:
            case 5:
            case 6:
            case 9:
            case 14:
            case 23:
            case 24:
            case 31:
            case 32:
            case 33:
            case 34:
              return (
                (this.error = "Constructed encoding used for primitive type"),
                -1
              );
          }
        return r + this.block_length;
      }),
      (p.identification_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name =
            p.identification_block.prototype.block_name.call(this)),
          (e.tag_class = this.tag_class),
          (e.tag_number = this.tag_number),
          (e.is_constructed = this.is_constructed),
          e
        );
      }),
      (p.length_block = function () {
        p.base_block.call(this, arguments[0]),
          (this.is_indefinite_form = !1),
          (this.long_form_used = !1),
          (this.length = 0),
          arguments[0] instanceof Object &&
            "len_block" in arguments[0] &&
            ((this.is_indefinite_form = e.org.pkijs.getValue(
              arguments[0].len_block,
              "is_indefinite_form",
              !1
            )),
            (this.long_form_used = e.org.pkijs.getValue(
              arguments[0].len_block,
              "long_form_used",
              !1
            )),
            (this.length = e.org.pkijs.getValue(
              arguments[0].len_block,
              "length",
              0
            )));
      }),
      (p.length_block.prototype = new p.base_block()),
      (p.length_block.constructor = p.length_block),
      (p.length_block.prototype.block_name = function () {
        return "length_block";
      }),
      (p.length_block.prototype.fromBER = function (e, r, i) {
        if (c.call(this, e, r, i) === !1) return -1;
        var n = new Uint8Array(e, r, i);
        if (0 == n.length) return (this.error = "Zero buffer length"), -1;
        if (255 == n[0])
          return (this.error = "Length block 0xFF is reserved by standard"), -1;
        if (
          (128 == n[0]
            ? (this.is_indefinite_form = !0)
            : (this.is_indefinite_form = !1),
          1 == this.is_indefinite_form)
        )
          return (this.block_length = 1), r + this.block_length;
        if (
          (128 & n[0] ? (this.long_form_used = !0) : (this.long_form_used = !1),
          0 == this.long_form_used)
        )
          return (
            (this.length = n[0]), (this.block_length = 1), r + this.block_length
          );
        var s = 127 & n[0];
        if (s > 8) return (this.error = "Too big integer"), -1;
        if (s + 1 > n.length)
          return (
            (this.error =
              "End of input reached before message was fully decoded"),
            -1
          );
        for (var a = new Uint8Array(s), o = 0; o < s; o++) a[o] = n[o + 1];
        return (
          0 == a[s - 1] && this.warnings.push("Needlessly long encoded length"),
          (this.length = t(a, 8)),
          this.long_form_used &&
            this.length <= 127 &&
            this.warnings.push("Unneccesary usage of long length form"),
          (this.block_length = s + 1),
          r + this.block_length
        );
      }),
      (p.length_block.prototype.toBER = function (e) {
        if (
          ("undefined" == typeof e && (e = !1),
          this.length > 127 && (this.long_form_used = !0),
          this.is_indefinite_form)
        ) {
          var t = new ArrayBuffer(1);
          if (e === !1) {
            var i = new Uint8Array(t);
            i[0] = 128;
          }
          return t;
        }
        if (this.long_form_used === !0) {
          var n = r(this.length, 8);
          if (n.byteLength > 127)
            return (this.error = "Too big length"), new ArrayBuffer(0);
          var t = new ArrayBuffer(n.byteLength + 1);
          if (e === !0) return t;
          var s = new Uint8Array(n),
            i = new Uint8Array(t);
          i[0] = 128 | n.byteLength;
          for (var a = 0; a < n.byteLength; a++) i[a + 1] = s[a];
          return t;
        }
        var t = new ArrayBuffer(1);
        if (e === !1) {
          var i = new Uint8Array(t);
          i[0] = this.length;
        }
        return t;
      }),
      (p.length_block.prototype.toJSON = function () {
        var e = p.base_block.prototype.toJSON.call(this);
        return (
          (e.block_name = p.length_block.prototype.block_name.call(this)),
          (e.is_indefinite_form = this.is_indefinite_form),
          (e.long_form_used = this.long_form_used),
          (e.length = this.length),
          e
        );
      }),
      (p.value_block = function () {
        p.base_block.call(this, arguments[0]);
      }),
      (p.value_block.prototype = new p.base_block()),
      (p.value_block.constructor = p.value_block),
      (p.value_block.prototype.block_name = function () {
        return "value_block";
      }),
      (p.value_block.prototype.toJSON = function () {
        var e = p.base_block.prototype.toJSON.call(this);
        return (
          (e.block_name = p.value_block.prototype.block_name.call(this)), e
        );
      }),
      (e.org.pkijs.asn1.ASN1_block = function () {
        p.base_block.call(this, arguments[0]),
          arguments[0] instanceof Object &&
            ((this.name = e.org.pkijs.getValue(arguments[0], "name", "")),
            (this.optional = e.org.pkijs.getValue(
              arguments[0],
              "optional",
              !1
            )),
            "primitive_schema" in arguments[0] &&
              (this.primitive_schema = arguments[0].primitive_schema)),
          (this.id_block = new p.identification_block(arguments[0])),
          (this.len_block = new p.length_block(arguments[0])),
          (this.value_block = new p.value_block(arguments[0]));
      }),
      (e.org.pkijs.asn1.ASN1_block.prototype = new p.base_block()),
      (e.org.pkijs.asn1.ASN1_block.constructor = e.org.pkijs.asn1.ASN1_block),
      (e.org.pkijs.asn1.ASN1_block.prototype.block_name = function () {
        return "ASN1_block";
      }),
      (e.org.pkijs.asn1.ASN1_block.prototype.fromBER = function (e, t, r) {
        var i = this.value_block.fromBER(
          e,
          t,
          1 == this.len_block.is_indefinite_form ? r : this.len_block.length
        );
        return i == -1
          ? ((this.error = this.value_block.error), i)
          : (0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            i);
      }),
      (e.org.pkijs.asn1.ASN1_block.prototype.toBER = function (e) {
        "undefined" == typeof e && (e = !1);
        var t,
          r = this.id_block.toBER(e),
          i = this.value_block.toBER(!0);
        this.len_block.length = i.byteLength;
        var n = this.len_block.toBER(e);
        t = o(r, n);
        var s;
        if (
          ((s =
            e === !1
              ? this.value_block.toBER(e)
              : new ArrayBuffer(this.len_block.length)),
          (t = o(t, s)),
          this.len_block.is_indefinite_form === !0)
        ) {
          var a = new ArrayBuffer(2);
          if (e === !1) {
            var c = new Uint8Array(a);
            (c[0] = 0), (c[1] = 0);
          }
          t = o(t, a);
        }
        return t;
      }),
      (e.org.pkijs.asn1.ASN1_block.prototype.toJSON = function () {
        var t = p.base_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.ASN1_block.prototype.block_name.call(this)),
          (t.id_block = this.id_block.toJSON()),
          (t.len_block = this.len_block.toJSON()),
          (t.value_block = this.value_block.toJSON()),
          "name" in this && (t.name = this.name),
          "optional" in this && (t.optional = this.optional),
          "primitive_schema" in this &&
            (t.primitive_schema = this.primitive_schema.toJSON()),
          t
        );
      }),
      (p.ASN1_PRIMITIVE_value_block = function () {
        p.value_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ("value_hex" in arguments[0]
                ? (this.value_hex = s(arguments[0].value_hex))
                : (this.value_hex = new ArrayBuffer(0)),
              (this.is_hex_only = e.org.pkijs.getValue(
                arguments[0],
                "is_hex_only",
                !0
              )))
            : ((this.value_hex = new ArrayBuffer(0)), (this.is_hex_only = !0));
      }),
      (p.ASN1_PRIMITIVE_value_block.prototype = new p.value_block()),
      (p.ASN1_PRIMITIVE_value_block.constructor = p.ASN1_PRIMITIVE_value_block),
      (p.ASN1_PRIMITIVE_value_block.prototype.fromBER = function (e, t, r) {
        if (c.call(this, e, t, r) === !1) return -1;
        var i = new Uint8Array(e, t, r);
        if (0 == i.length) return this.warnings.push("Zero buffer length"), t;
        this.value_hex = new ArrayBuffer(i.length);
        for (var n = new Uint8Array(this.value_hex), s = 0; s < i.length; s++)
          n[s] = i[s];
        return (this.block_length = r), t + r;
      }),
      (p.ASN1_PRIMITIVE_value_block.prototype.toBER = function (e) {
        return s(this.value_hex);
      }),
      (p.ASN1_PRIMITIVE_value_block.prototype.block_name = function () {
        return "ASN1_PRIMITIVE_value_block";
      }),
      (p.ASN1_PRIMITIVE_value_block.prototype.toJSON = function () {
        var t = p.value_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            p.ASN1_PRIMITIVE_value_block.prototype.block_name.call(this)),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          (t.is_hex_only = this.is_hex_only),
          t
        );
      }),
      (e.org.pkijs.asn1.ASN1_PRIMITIVE = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.id_block.is_constructed = !1),
          (this.value_block = new p.ASN1_PRIMITIVE_value_block(arguments[0]));
      }),
      (e.org.pkijs.asn1.ASN1_PRIMITIVE.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.ASN1_PRIMITIVE.constructor =
        e.org.pkijs.asn1.ASN1_PRIMITIVE),
      (e.org.pkijs.asn1.ASN1_PRIMITIVE.prototype.block_name = function () {
        return "PRIMITIVE";
      }),
      (e.org.pkijs.asn1.ASN1_PRIMITIVE.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.ASN1_PRIMITIVE.prototype.block_name.call(this)),
          t
        );
      }),
      (p.ASN1_CONSTRUCTED_value_block = function () {
        p.value_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.value = e.org.pkijs.getValue(
                arguments[0],
                "value",
                new Array()
              )),
              (this.is_indefinite_form = e.org.pkijs.getValue(
                arguments[0],
                "is_indefinite_form",
                !1
              )))
            : ((this.value = new Array()), (this.is_indefinite_form = !1));
      }),
      (p.ASN1_CONSTRUCTED_value_block.prototype = new p.value_block()),
      (p.ASN1_CONSTRUCTED_value_block.constructor =
        p.ASN1_CONSTRUCTED_value_block),
      (p.ASN1_CONSTRUCTED_value_block.prototype.fromBER = function (t, r, i) {
        function n(e, t) {
          return 1 == e ? 1 : t;
        }
        var s = r,
          o = i;
        if (c.call(this, t, r, i) === !1) return -1;
        var l = new Uint8Array(t, r, i);
        if (0 == l.length) return this.warnings.push("Zero buffer length"), r;
        for (var p = r; n(this.is_indefinite_form, i) > 0; ) {
          var h = u(t, p, i);
          if (h.offset == -1)
            return (
              (this.error = h.result.error),
              this.warnings.concat(h.result.warnings),
              -1
            );
          if (
            ((p = h.offset),
            (this.block_length += h.result.block_length),
            (i -= h.result.block_length),
            this.value.push(h.result),
            1 == this.is_indefinite_form &&
              h.result.block_name() ==
                e.org.pkijs.asn1.EOC.prototype.block_name())
          )
            break;
        }
        return (
          1 == this.is_indefinite_form &&
            (this.value[this.value.length - 1].block_name() ==
            e.org.pkijs.asn1.EOC.prototype.block_name()
              ? this.value.pop()
              : this.warnings.push("No EOC block encoded")),
          (this.value_before_decode = a(t, s, o)),
          p
        );
      }),
      (p.ASN1_CONSTRUCTED_value_block.prototype.toBER = function (e) {
        "undefined" == typeof e && (e = !1);
        for (var t = new ArrayBuffer(0), r = 0; r < this.value.length; r++) {
          var i = this.value[r].toBER(e);
          t = o(t, i);
        }
        return t;
      }),
      (p.ASN1_CONSTRUCTED_value_block.prototype.block_name = function () {
        return "ASN1_CONSTRUCTED_value_block";
      }),
      (p.ASN1_CONSTRUCTED_value_block.prototype.toJSON = function () {
        var e = p.value_block.prototype.toJSON.call(this);
        (e.block_name =
          p.ASN1_CONSTRUCTED_value_block.prototype.block_name.call(this)),
          (e.is_indefinite_form = this.is_indefinite_form),
          (e.value = new Array());
        for (var t = 0; t < this.value.length; t++)
          e.value.push(this.value[t].toJSON());
        return e;
      }),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.id_block.is_constructed = !0),
          (this.value_block = new p.ASN1_CONSTRUCTED_value_block(arguments[0]));
      }),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED.constructor =
        e.org.pkijs.asn1.ASN1_CONSTRUCTED),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.block_name = function () {
        return "CONSTRUCTED";
      }),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.fromBER = function (
        e,
        t,
        r
      ) {
        this.value_block.is_indefinite_form = this.len_block.is_indefinite_form;
        var i = this.value_block.fromBER(
          e,
          t,
          1 == this.len_block.is_indefinite_form ? r : this.len_block.length
        );
        return i == -1
          ? ((this.error = this.value_block.error), i)
          : (0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            i);
      }),
      (e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.block_name.call(this)),
          t
        );
      }),
      (p.EOC_value_block = function () {
        p.value_block.call(this, arguments[0]);
      }),
      (p.EOC_value_block.prototype = new p.value_block()),
      (p.EOC_value_block.constructor = p.EOC_value_block),
      (p.EOC_value_block.prototype.fromBER = function (e, t, r) {
        return t;
      }),
      (p.EOC_value_block.prototype.toBER = function (e) {
        return new ArrayBuffer(0);
      }),
      (p.EOC_value_block.prototype.block_name = function () {
        return "EOC_value_block";
      }),
      (p.EOC_value_block.prototype.toJSON = function () {
        var e = p.value_block.prototype.toJSON.call(this);
        return (
          (e.block_name = p.EOC_value_block.prototype.block_name.call(this)), e
        );
      }),
      (e.org.pkijs.asn1.EOC = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.EOC_value_block()),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 0);
      }),
      (e.org.pkijs.asn1.EOC.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.EOC.constructor = p.EOC_value_block),
      (e.org.pkijs.asn1.EOC.prototype.block_name = function () {
        return "END_OF_CONTENT";
      }),
      (e.org.pkijs.asn1.EOC.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name = e.org.pkijs.asn1.EOC.prototype.block_name.call(this)),
          t
        );
      }),
      (p.BOOLEAN_value_block = function () {
        if (
          (p.value_block.call(this, arguments[0]),
          arguments[0] instanceof Object)
        ) {
          if (
            ((this.value = e.org.pkijs.getValue(arguments[0], "value", !1)),
            (this.is_hex_only = e.org.pkijs.getValue(
              arguments[0],
              "is_hex_only",
              !1
            )),
            "value_hex" in arguments[0])
          )
            this.value_hex = s(arguments[0].value_hex);
          else if (((this.value_hex = new ArrayBuffer(1)), this.value === !0)) {
            var t = new Uint8Array(this.value_hex);
            t[0] = 255;
          }
        } else
          (this.value = !1),
            (this.is_hex_only = !1),
            (this.value_hex = new ArrayBuffer(1));
      }),
      (p.BOOLEAN_value_block.prototype = new p.value_block()),
      (p.BOOLEAN_value_block.constructor = p.BOOLEAN_value_block),
      (p.BOOLEAN_value_block.prototype.fromBER = function (e, t, r) {
        if (c.call(this, e, t, r) === !1) return -1;
        var i = new Uint8Array(e, t, r);
        r > 1 &&
          this.warnings.push("BOOLEAN value encoded in more then 1 octet"),
          0 == i[0] ? (this.value = !1) : (this.value = !0),
          (this.is_hex_only = !0),
          (this.value_hex = new ArrayBuffer(i.length));
        for (var n = new Uint8Array(this.value_hex), s = 0; s < i.length; s++)
          n[s] = i[s];
        return (this.block_length = r), t + r;
      }),
      (p.BOOLEAN_value_block.prototype.toBER = function (e) {
        return "undefined" == typeof e && (e = !1), this.value_hex;
      }),
      (p.BOOLEAN_value_block.prototype.block_name = function () {
        return "BOOLEAN_value_block";
      }),
      (p.BOOLEAN_value_block.prototype.toJSON = function () {
        var t = p.value_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            p.BOOLEAN_value_block.prototype.block_name.call(this)),
          (t.value = this.value),
          (t.is_hex_only = this.is_hex_only),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          t
        );
      }),
      (e.org.pkijs.asn1.BOOLEAN = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.BOOLEAN_value_block(arguments[0])),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 1);
      }),
      (e.org.pkijs.asn1.BOOLEAN.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.BOOLEAN.constructor = p.BOOLEAN_value_block),
      (e.org.pkijs.asn1.BOOLEAN.prototype.block_name = function () {
        return "BOOLEAN";
      }),
      (e.org.pkijs.asn1.BOOLEAN.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.BOOLEAN.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.SEQUENCE = function () {
        e.org.pkijs.asn1.ASN1_CONSTRUCTED.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 16);
      }),
      (e.org.pkijs.asn1.SEQUENCE.prototype =
        new e.org.pkijs.asn1.ASN1_CONSTRUCTED()),
      (e.org.pkijs.asn1.SEQUENCE.constructor = e.org.pkijs.asn1.SEQUENCE),
      (e.org.pkijs.asn1.SEQUENCE.prototype.block_name = function () {
        return "SEQUENCE";
      }),
      (e.org.pkijs.asn1.SEQUENCE.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.SEQUENCE.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.SET = function () {
        e.org.pkijs.asn1.ASN1_CONSTRUCTED.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 17);
      }),
      (e.org.pkijs.asn1.SET.prototype =
        new e.org.pkijs.asn1.ASN1_CONSTRUCTED()),
      (e.org.pkijs.asn1.SET.constructor = e.org.pkijs.asn1.SET),
      (e.org.pkijs.asn1.SET.prototype.block_name = function () {
        return "SET";
      }),
      (e.org.pkijs.asn1.SET.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_CONSTRUCTED.prototype.toJSON.call(this);
        return (
          (t.block_name = e.org.pkijs.asn1.SET.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.NULL = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 5);
      }),
      (e.org.pkijs.asn1.NULL.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.NULL.constructor = e.org.pkijs.asn1.NULL),
      (e.org.pkijs.asn1.NULL.prototype.block_name = function () {
        return "NULL";
      }),
      (e.org.pkijs.asn1.NULL.prototype.fromBER = function (e, t, r) {
        return (
          this.len_block.length > 0 &&
            this.warnings.push("Non-zero length of value block for NULL type"),
          0 === this.id_block.error.length &&
            (this.block_length += this.id_block.block_length),
          0 === this.len_block.error.length &&
            (this.block_length += this.len_block.block_length),
          (this.block_length += r),
          t + r
        );
      }),
      (e.org.pkijs.asn1.NULL.prototype.toBER = function (e) {
        "undefined" == typeof e && (e = !1);
        var t = new ArrayBuffer(2);
        if (e === !0) return t;
        var r = new Uint8Array(t);
        return (r[0] = 5), (r[1] = 0), t;
      }),
      (e.org.pkijs.asn1.NULL.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.NULL.prototype.block_name.call(this)),
          t
        );
      }),
      (p.OCTETSTRING_value_block = function () {
        p.ASN1_CONSTRUCTED_value_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.is_constructed = e.org.pkijs.getValue(
                arguments[0],
                "is_constructed",
                !1
              )),
              (this.is_hex_only = e.org.pkijs.getValue(
                arguments[0],
                "is_hex_only",
                !1
              )),
              "value_hex" in arguments[0]
                ? (this.value_hex = s(arguments[0].value_hex))
                : (this.value_hex = new ArrayBuffer(0)))
            : ((this.is_constructed = !1),
              (this.is_hex_only = !1),
              (this.value_hex = new ArrayBuffer(0)));
      }),
      (p.OCTETSTRING_value_block.prototype =
        new p.ASN1_CONSTRUCTED_value_block()),
      (p.OCTETSTRING_value_block.constructor = p.OCTETSTRING_value_block),
      (p.OCTETSTRING_value_block.prototype.fromBER = function (t, r, i) {
        var n = 0;
        if (1 == this.is_constructed) {
          if (
            ((this.is_hex_only = !1),
            (n = p.ASN1_CONSTRUCTED_value_block.prototype.fromBER.call(
              this,
              t,
              r,
              i
            )),
            n == -1)
          )
            return n;
          for (var s = 0; s < this.value.length; s++) {
            var a = this.value[s].block_name();
            if (a == e.org.pkijs.asn1.EOC.prototype.block_name()) {
              if (1 == this.is_indefinite_form) break;
              return (
                (this.error =
                  "EOC is unexpected, OCTET STRING may consists of OCTET STRINGs only"),
                -1
              );
            }
            if (a != e.org.pkijs.asn1.OCTETSTRING.prototype.block_name())
              return (
                (this.error =
                  "OCTET STRING may consists of OCTET STRINGs only"),
                -1
              );
          }
        } else
          (this.is_hex_only = !0),
            (n = p.hex_block.prototype.fromBER.call(this, t, r, i)),
            (this.block_length = i);
        return n;
      }),
      (p.OCTETSTRING_value_block.prototype.toBER = function (e) {
        if (("undefined" == typeof e && (e = !1), this.is_constructed === !0))
          return p.ASN1_CONSTRUCTED_value_block.prototype.toBER.call(this, e);
        var t = new ArrayBuffer(this.value_hex.byteLength);
        return e === !0
          ? t
          : 0 == this.value_hex.byteLength
          ? t
          : (t = s(this.value_hex));
      }),
      (p.OCTETSTRING_value_block.prototype.block_name = function () {
        return "OCTETSTRING_value_block";
      }),
      (p.OCTETSTRING_value_block.prototype.toJSON = function () {
        var t = p.ASN1_CONSTRUCTED_value_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            p.OCTETSTRING_value_block.prototype.block_name.call(this)),
          (t.is_constructed = this.is_constructed),
          (t.is_hex_only = this.is_hex_only),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          t
        );
      }),
      (e.org.pkijs.asn1.OCTETSTRING = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.OCTETSTRING_value_block(arguments[0])),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 4);
      }),
      (e.org.pkijs.asn1.OCTETSTRING.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.OCTETSTRING.constructor = e.org.pkijs.asn1.OCTETSTRING),
      (e.org.pkijs.asn1.OCTETSTRING.prototype.fromBER = function (t, r, i) {
        return (
          (this.value_block.is_constructed = this.id_block.is_constructed),
          (this.value_block.is_indefinite_form =
            this.len_block.is_indefinite_form),
          0 == i
            ? (0 == this.id_block.error.length &&
                (this.block_length += this.id_block.block_length),
              0 == this.len_block.error.length &&
                (this.block_length += this.len_block.block_length),
              r)
            : e.org.pkijs.asn1.ASN1_block.prototype.fromBER.call(this, t, r, i)
        );
      }),
      (e.org.pkijs.asn1.OCTETSTRING.prototype.block_name = function () {
        return "OCTETSTRING";
      }),
      (e.org.pkijs.asn1.OCTETSTRING.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.OCTETSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (p.BITSTRING_value_block = function () {
        p.ASN1_CONSTRUCTED_value_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.unused_bits = e.org.pkijs.getValue(
                arguments[0],
                "unused_bits",
                0
              )),
              (this.is_constructed = e.org.pkijs.getValue(
                arguments[0],
                "is_constructed",
                !1
              )),
              (this.is_hex_only = e.org.pkijs.getValue(
                arguments[0],
                "is_hex_only",
                !1
              )),
              "value_hex" in arguments[0]
                ? (this.value_hex = s(arguments[0].value_hex))
                : (this.value_hex = new ArrayBuffer(0)),
              (this.block_length = this.value_hex.byteLength))
            : ((this.unused_bits = 0),
              (this.is_constructed = !1),
              (this.is_hex_only = !1),
              (this.value_hex = new ArrayBuffer(0)));
      }),
      (p.BITSTRING_value_block.prototype =
        new p.ASN1_CONSTRUCTED_value_block()),
      (p.BITSTRING_value_block.constructor = p.BITSTRING_value_block),
      (p.BITSTRING_value_block.prototype.fromBER = function (t, r, i) {
        if (0 == i) return r;
        var n = -1;
        if (1 == this.is_constructed) {
          if (
            ((n = p.ASN1_CONSTRUCTED_value_block.prototype.fromBER.call(
              this,
              t,
              r,
              i
            )),
            n == -1)
          )
            return n;
          for (var s = 0; s < this.value.length; s++) {
            var a = this.value[s].block_name();
            if (a == e.org.pkijs.asn1.EOC.prototype.block_name()) {
              if (1 == this.is_indefinite_form) break;
              return (
                (this.error =
                  "EOC is unexpected, BIT STRING may consists of BIT STRINGs only"),
                -1
              );
            }
            if (a != e.org.pkijs.asn1.BITSTRING.prototype.block_name())
              return (
                (this.error = "BIT STRING may consists of BIT STRINGs only"), -1
              );
            if (this.unused_bits > 0 && this.value[s].unused_bits > 0)
              return (
                (this.error =
                  'Usign of "unused bits" inside constructive BIT STRING allowed for least one only'),
                -1
              );
            if (
              ((this.unused_bits = this.value[s].unused_bits),
              this.unused_bits > 7)
            )
              return (
                (this.error = "Unused bits for BITSTRING must be in range 0-7"),
                -1
              );
          }
          return n;
        }
        if (c.call(this, t, r, i) === !1) return -1;
        var o = new Uint8Array(t, r, i);
        if (((this.unused_bits = o[0]), this.unused_bits > 7))
          return (
            (this.error = "Unused bits for BITSTRING must be in range 0-7"), -1
          );
        this.value_hex = new ArrayBuffer(o.length - 1);
        for (var l = new Uint8Array(this.value_hex), s = 0; s < i - 1; s++)
          l[s] = o[s + 1];
        return (this.block_length = o.length), r + i;
      }),
      (p.BITSTRING_value_block.prototype.toBER = function (e) {
        if (("undefined" == typeof e && (e = !1), this.is_constructed === !0))
          return p.ASN1_CONSTRUCTED_value_block.prototype.toBER.call(this, e);
        if (e === !0) return new ArrayBuffer(this.value_hex.byteLength + 1);
        if (0 == this.value_hex.byteLength) return new ArrayBuffer(0);
        var t = new Uint8Array(this.value_hex),
          r = new ArrayBuffer(this.value_hex.byteLength + 1),
          i = new Uint8Array(r);
        i[0] = this.unused_bits;
        for (var n = 0; n < this.value_hex.byteLength; n++) i[n + 1] = t[n];
        return r;
      }),
      (p.BITSTRING_value_block.prototype.block_name = function () {
        return "BITSTRING_value_block";
      }),
      (p.BITSTRING_value_block.prototype.toJSON = function () {
        var t = p.ASN1_CONSTRUCTED_value_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            p.BITSTRING_value_block.prototype.block_name.call(this)),
          (t.unused_bits = this.unused_bits),
          (t.is_constructed = this.is_constructed),
          (t.is_hex_only = this.is_hex_only),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          t
        );
      }),
      (e.org.pkijs.asn1.BITSTRING = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.BITSTRING_value_block(arguments[0])),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 3);
      }),
      (e.org.pkijs.asn1.BITSTRING.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.BITSTRING.constructor = e.org.pkijs.asn1.BITSTRING),
      (e.org.pkijs.asn1.BITSTRING.prototype.block_name = function () {
        return "BITSTRING";
      }),
      (e.org.pkijs.asn1.BITSTRING.prototype.fromBER = function (t, r, i) {
        return 0 == i
          ? r
          : ((this.value_block.is_constructed = this.id_block.is_constructed),
            (this.value_block.is_indefinite_form =
              this.len_block.is_indefinite_form),
            e.org.pkijs.asn1.ASN1_block.prototype.fromBER.call(this, t, r, i));
      }),
      (e.org.pkijs.asn1.BITSTRING.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.BITSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (p.INTEGER_value_block = function () {
        p.value_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.value_dec = e.org.pkijs.getValue(
                arguments[0],
                "value",
                0
              )),
              (this.is_hex_only = e.org.pkijs.getValue(
                arguments[0],
                "is_hex_only",
                !1
              )),
              "value_hex" in arguments[0]
                ? ((this.value_hex = s(arguments[0].value_hex)),
                  this.value_hex.byteLength >= 4
                    ? (this.is_hex_only = !0)
                    : (this.value_dec = n.call(this)))
                : (this.value_hex = i(this.value_dec)))
            : ((this.value_dec = 0),
              (this.is_hex_only = !1),
              (this.value_hex = new ArrayBuffer(0)));
      }),
      (p.INTEGER_value_block.prototype = new p.value_block()),
      (p.INTEGER_value_block.constructor = p.INTEGER_value_block),
      (p.INTEGER_value_block.prototype.fromBER = function (e, t, r) {
        var i = p.hex_block.prototype.fromBER.call(this, e, t, r);
        return i == -1
          ? i
          : (this.value_hex.byteLength > 4
              ? (this.warnings.push("Too big INTEGER for decoding, hex only"),
                (this.is_hex_only = !0))
              : (this.value_dec = n.call(this)),
            (this.block_length = r),
            t + r);
      }),
      (p.INTEGER_value_block.prototype.toBER = function (e) {
        if (("undefined" == typeof e && (e = !1), this.is_hex_only === !1)) {
          var t = i(this.value_dec);
          return 0 == t.byteLength
            ? ((this.error = "Error during encoding INTEGER value"),
              new ArrayBuffer(0))
            : s(t);
        }
        return s(this.value_hex);
      }),
      (p.INTEGER_value_block.prototype.block_name = function () {
        return "INTEGER_value_block";
      }),
      (p.INTEGER_value_block.prototype.toJSON = function () {
        var t = p.value_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            p.INTEGER_value_block.prototype.block_name.call(this)),
          (t.value_dec = this.value_dec),
          (t.is_hex_only = this.is_hex_only),
          (t.value_hex = e.org.pkijs.bufferToHexCodes(
            this.value_hex,
            0,
            this.value_hex.byteLength
          )),
          t
        );
      }),
      (e.org.pkijs.asn1.INTEGER = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.INTEGER_value_block(arguments[0])),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 2);
      }),
      (e.org.pkijs.asn1.INTEGER.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.INTEGER.constructor = e.org.pkijs.asn1.INTEGER),
      (e.org.pkijs.asn1.INTEGER.prototype.block_name = function () {
        return "INTEGER";
      }),
      (e.org.pkijs.asn1.INTEGER.prototype.isEqual = function () {
        return arguments[0] instanceof e.org.pkijs.asn1.INTEGER
          ? this.value_block.is_hex_only && arguments[0].value_block.is_hex_only
            ? e.org.pkijs.isEqual_buffer(
                this.value_block.value_hex,
                arguments[0].value_block.value_hex
              )
            : this.value_block.is_hex_only ===
                arguments[0].value_block.is_hex_only &&
              this.value_block.value_dec == arguments[0].value_block.value_dec
          : arguments[0] instanceof ArrayBuffer &&
              e.org.pkijs.isEqual_buffer(
                this.value_block.value_hex,
                arguments[0].value_block.value_hex
              );
      }),
      (e.org.pkijs.asn1.INTEGER.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.INTEGER.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.ENUMERATED = function () {
        e.org.pkijs.asn1.INTEGER.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 10);
      }),
      (e.org.pkijs.asn1.ENUMERATED.prototype = new e.org.pkijs.asn1.INTEGER()),
      (e.org.pkijs.asn1.ENUMERATED.constructor = e.org.pkijs.asn1.ENUMERATED),
      (e.org.pkijs.asn1.ENUMERATED.prototype.block_name = function () {
        return "ENUMERATED";
      }),
      (e.org.pkijs.asn1.ENUMERATED.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.INTEGER.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.ENUMERATED.prototype.block_name.call(this)),
          t
        );
      }),
      (p.SID_value_block = function () {
        p.hex_block.call(this, arguments[0]),
          arguments[0] instanceof Object
            ? ((this.value_dec = e.org.pkijs.getValue(
                arguments[0],
                "value_dec",
                -1
              )),
              (this.is_first_sid = e.org.pkijs.getValue(
                arguments[0],
                "is_first_sid",
                !1
              )))
            : ((this.value_dec = -1), (this.is_first_sid = !1));
      }),
      (p.SID_value_block.prototype = new p.hex_block()),
      (p.SID_value_block.constructor = p.SID_value_block),
      (p.SID_value_block.prototype.block_name = function () {
        return "sid_block";
      }),
      (p.SID_value_block.prototype.fromBER = function (e, r, i) {
        if (0 == i) return r;
        if (c.call(this, e, r, i) === !1) return -1;
        var n = new Uint8Array(e, r, i);
        this.value_hex = new ArrayBuffer(i);
        for (
          var a = new Uint8Array(this.value_hex), o = 0;
          o < i &&
          ((a[o] = 127 & n[o]), this.block_length++, 0 != (128 & n[o]));
          o++
        );
        for (
          var l = new ArrayBuffer(this.block_length),
            u = new Uint8Array(l),
            o = 0;
          o < this.block_length;
          o++
        )
          u[o] = a[o];
        return (
          (this.value_hex = s(l)),
          (a = new Uint8Array(this.value_hex)),
          0 != (128 & n[this.block_length - 1])
            ? ((this.error =
                "End of input reached before message was fully decoded"),
              -1)
            : (0 == a[0] &&
                this.warnings.push("Needlessly long format of SID encoding"),
              this.block_length <= 8
                ? (this.value_dec = t(a, 7))
                : ((this.is_hex_only = !0),
                  this.warnings.push("Too big SID for decoding, hex only")),
              r + this.block_length)
        );
      }),
      (p.SID_value_block.prototype.toBER = function (e) {
        if (("undefined" == typeof e && (e = !1), this.is_hex_only)) {
          if (e === !0) return new ArrayBuffer(this.value_hex.byteLength);
          for (
            var t = new Uint8Array(this.value_hex),
              i = new ArrayBuffer(this.block_length),
              n = new Uint8Array(i),
              s = 0;
            s < this.block_length - 1;
            s++
          )
            n[s] = 128 | t[s];
          n[this.block_length - 1] = t[this.block_length - 1];
        }
        var a = r(this.value_dec, 7);
        if (0 === a.byteLength)
          return (
            (this.error = "Error during encoding SID value"), new ArrayBuffer(0)
          );
        var i = new ArrayBuffer(a.byteLength);
        if (e === !1) {
          for (
            var o = new Uint8Array(a), n = new Uint8Array(i), s = 0;
            s < a.byteLength - 1;
            s++
          )
            n[s] = 128 | o[s];
          n[a.byteLength - 1] = o[a.byteLength - 1];
        }
        return i;
      }),
      (p.SID_value_block.prototype.toString = function () {
        var e = "";
        if (this.is_hex_only === !0) e = l(this.value_hex);
        else if (this.is_first_sid) {
          var t = this.value_dec;
          this.value_dec <= 39
            ? (e = "0.")
            : this.value_dec <= 79
            ? ((e = "1."), (t -= 40))
            : ((e = "2."), (t -= 80)),
            (e += t.toString());
        } else e = this.value_dec.toString();
        return e;
      }),
      (p.SID_value_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name = p.SID_value_block.prototype.block_name.call(this)),
          (e.value_dec = this.value_dec),
          (e.is_first_sid = this.is_first_sid),
          e
        );
      }),
      (p.OID_value_block = function () {
        p.value_block.call(this, arguments[0]),
          (this.value = new Array()),
          arguments[0] instanceof Object &&
            this.fromString(e.org.pkijs.getValue(arguments[0], "value", ""));
      }),
      (p.OID_value_block.prototype = new p.value_block()),
      (p.OID_value_block.constructor = p.OID_value_block),
      (p.OID_value_block.prototype.fromBER = function (e, t, r) {
        for (var i = t; r > 0; ) {
          var n = new p.SID_value_block();
          if (((i = n.fromBER(e, i, r)), i == -1))
            return (this.block_length = 0), (this.error = n.error), i;
          0 == this.value.length && (n.is_first_sid = !0),
            (this.block_length += n.block_length),
            (r -= n.block_length),
            this.value.push(n);
        }
        return i;
      }),
      (p.OID_value_block.prototype.toBER = function (e) {
        "undefined" == typeof e && (e = !1);
        for (var t = new ArrayBuffer(0), r = 0; r < this.value.length; r++) {
          var i = this.value[r].toBER(e);
          if (0 === i.byteLength)
            return (this.error = this.value[r].error), new ArrayBuffer(0);
          t = o(t, i);
        }
        return t;
      }),
      (p.OID_value_block.prototype.fromString = function (e) {
        this.value = new Array();
        var t = 0,
          r = 0,
          i = "",
          n = !1;
        do
          if (
            ((r = e.indexOf(".", t)),
            (i = r === -1 ? e.substr(t) : e.substr(t, r - t)),
            (t = r + 1),
            n)
          ) {
            var s = this.value[0],
              a = 0;
            switch (s.value_dec) {
              case 0:
                break;
              case 1:
                a = 40;
                break;
              case 2:
                a = 80;
                break;
              default:
                return (this.value = new Array()), !1;
            }
            var o = parseInt(i, 10);
            if (Number.isNaN(o)) return !0;
            (s.value_dec = o + a), (n = !1);
          } else {
            var s = new p.SID_value_block();
            if (((s.value_dec = parseInt(i, 10)), Number.isNaN(s.value_dec)))
              return !0;
            0 === this.value.length && ((s.is_first_sid = !0), (n = !0)),
              this.value.push(s);
          }
        while (r !== -1);
        return !0;
      }),
      (p.OID_value_block.prototype.toString = function () {
        for (var e = "", t = !1, r = 0; r < this.value.length; r++) {
          t = this.value[r].is_hex_only;
          var i = this.value[r].toString();
          0 !== r && (e += "."),
            t
              ? ((i = "{" + i + "}"),
                this.value[r].is_first_sid
                  ? (e = "2.{" + i + " - 80}")
                  : (e += i))
              : (e += i);
        }
        return e;
      }),
      (p.OID_value_block.prototype.block_name = function () {
        return "OID_value_block";
      }),
      (p.OID_value_block.prototype.toJSON = function () {
        var e = p.value_block.prototype.toJSON.call(this);
        (e.block_name = p.OID_value_block.prototype.block_name.call(this)),
          (e.value = p.OID_value_block.prototype.toString.call(this)),
          (e.sid_array = new Array());
        for (var t = 0; t < this.value.length; t++)
          e.sid_array.push(this.value[t].toJSON());
        return e;
      }),
      (e.org.pkijs.asn1.OID = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.OID_value_block(arguments[0])),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 6);
      }),
      (e.org.pkijs.asn1.OID.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.OID.constructor = e.org.pkijs.asn1.OID),
      (e.org.pkijs.asn1.OID.prototype.block_name = function () {
        return "OID";
      }),
      (e.org.pkijs.asn1.OID.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name = e.org.pkijs.asn1.OID.prototype.block_name.call(this)),
          t
        );
      }),
      (p.UTF8STRING_value_block = function () {
        p.hex_block.call(this, arguments[0]),
          (this.is_hex_only = !0),
          (this.value = "");
      }),
      (p.UTF8STRING_value_block.prototype = new p.hex_block()),
      (p.UTF8STRING_value_block.constructor = p.UTF8STRING_value_block),
      (p.UTF8STRING_value_block.prototype.block_name = function () {
        return "UTF8STRING_value_block";
      }),
      (p.UTF8STRING_value_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name =
            p.UTF8STRING_value_block.prototype.block_name.call(this)),
          (e.value = this.value),
          e
        );
      }),
      (e.org.pkijs.asn1.UTF8STRING = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.UTF8STRING_value_block()),
          arguments[0] instanceof Object &&
            "value" in arguments[0] &&
            e.org.pkijs.asn1.UTF8STRING.prototype.fromString.call(
              this,
              arguments[0].value
            ),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 12);
      }),
      (e.org.pkijs.asn1.UTF8STRING.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.UTF8STRING.constructor = e.org.pkijs.asn1.UTF8STRING),
      (e.org.pkijs.asn1.UTF8STRING.prototype.block_name = function () {
        return "UTF8STRING";
      }),
      (e.org.pkijs.asn1.UTF8STRING.prototype.fromBER = function (t, r, i) {
        var n = this.value_block.fromBER(
          t,
          r,
          1 == this.len_block.is_indefinite_form ? i : this.len_block.length
        );
        return n == -1
          ? ((this.error = this.value_block.error), n)
          : (e.org.pkijs.asn1.UTF8STRING.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            n);
      }),
      (e.org.pkijs.asn1.UTF8STRING.prototype.fromBuffer = function (e) {
        this.value_block.value = String.fromCharCode.apply(
          null,
          new Uint8Array(e)
        );
        try {
          this.value_block.value = decodeURIComponent(
            escape(this.value_block.value)
          );
        } catch (t) {
          this.warnings.push(
            'Error during "decodeURIComponent": ' + t + ", using raw string"
          );
        }
      }),
      (e.org.pkijs.asn1.UTF8STRING.prototype.fromString = function (e) {
        var t = unescape(encodeURIComponent(e)),
          r = t.length;
        this.value_block.value_hex = new ArrayBuffer(r);
        for (
          var i = new Uint8Array(this.value_block.value_hex), n = 0;
          n < r;
          n++
        )
          i[n] = t.charCodeAt(n);
        this.value_block.value = e;
      }),
      (e.org.pkijs.asn1.UTF8STRING.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.UTF8STRING.prototype.block_name.call(this)),
          t
        );
      }),
      (p.BMPSTRING_value_block = function () {
        p.hex_block.call(this, arguments[0]),
          (this.is_hex_only = !0),
          (this.value = "");
      }),
      (p.BMPSTRING_value_block.prototype = new p.hex_block()),
      (p.BMPSTRING_value_block.constructor = p.BMPSTRING_value_block),
      (p.BMPSTRING_value_block.prototype.block_name = function () {
        return "BMPSTRING_value_block";
      }),
      (p.BMPSTRING_value_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name =
            p.BMPSTRING_value_block.prototype.block_name.call(this)),
          (e.value = this.value),
          e
        );
      }),
      (e.org.pkijs.asn1.BMPSTRING = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.BMPSTRING_value_block()),
          arguments[0] instanceof Object &&
            "value" in arguments[0] &&
            e.org.pkijs.asn1.BMPSTRING.prototype.fromString.call(
              this,
              arguments[0].value
            ),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 30);
      }),
      (e.org.pkijs.asn1.BMPSTRING.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.BMPSTRING.constructor = e.org.pkijs.asn1.BMPSTRING),
      (e.org.pkijs.asn1.BMPSTRING.prototype.block_name = function () {
        return "BMPSTRING";
      }),
      (e.org.pkijs.asn1.BMPSTRING.prototype.fromBER = function (t, r, i) {
        var n = this.value_block.fromBER(
          t,
          r,
          1 == this.len_block.is_indefinite_form ? i : this.len_block.length
        );
        return n == -1
          ? ((this.error = this.value_block.error), n)
          : (e.org.pkijs.asn1.BMPSTRING.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            n);
      }),
      (e.org.pkijs.asn1.BMPSTRING.prototype.fromBuffer = function (t) {
        for (
          var r = e.org.pkijs.copyBuffer(t), i = new Uint8Array(r), n = 0;
          n < i.length;
          n += 2
        ) {
          var s = i[n];
          (i[n] = i[n + 1]), (i[n + 1] = s);
        }
        this.value_block.value = String.fromCharCode.apply(
          null,
          new Uint16Array(r)
        );
      }),
      (e.org.pkijs.asn1.BMPSTRING.prototype.fromString = function (e) {
        var t = e.length;
        this.value_block.value_hex = new ArrayBuffer(2 * t);
        for (
          var i = new Uint8Array(this.value_block.value_hex), n = 0;
          n < t;
          n++
        ) {
          var s = r(e.charCodeAt(n), 8),
            a = new Uint8Array(s);
          if (!(a.length > 2))
            for (var o = 2 - a.length, c = a.length - 1; c >= 0; c--)
              i[2 * n + c + o] = a[c];
        }
        this.value_block.value = e;
      }),
      (e.org.pkijs.asn1.BMPSTRING.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.BMPSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (p.UNIVERSALSTRING_value_block = function () {
        p.hex_block.call(this, arguments[0]),
          (this.is_hex_only = !0),
          (this.value = "");
      }),
      (p.UNIVERSALSTRING_value_block.prototype = new p.hex_block()),
      (p.UNIVERSALSTRING_value_block.constructor =
        p.UNIVERSALSTRING_value_block),
      (p.UNIVERSALSTRING_value_block.prototype.block_name = function () {
        return "UNIVERSALSTRING_value_block";
      }),
      (p.UNIVERSALSTRING_value_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name =
            p.UNIVERSALSTRING_value_block.prototype.block_name.call(this)),
          (e.value = this.value),
          e
        );
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.UNIVERSALSTRING_value_block()),
          arguments[0] instanceof Object &&
            "value" in arguments[0] &&
            e.org.pkijs.asn1.UNIVERSALSTRING.prototype.fromString.call(
              this,
              arguments[0].value
            ),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 28);
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype =
        new e.org.pkijs.asn1.ASN1_block()),
      (e.org.pkijs.asn1.UNIVERSALSTRING.constructor =
        e.org.pkijs.asn1.UNIVERSALSTRING),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.block_name = function () {
        return "UNIVERSALSTRING";
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.fromBER = function (t, r, i) {
        var n = this.value_block.fromBER(
          t,
          r,
          1 == this.len_block.is_indefinite_form ? i : this.len_block.length
        );
        return n == -1
          ? ((this.error = this.value_block.error), n)
          : (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            n);
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.fromBuffer = function (t) {
        for (
          var r = e.org.pkijs.copyBuffer(t), i = new Uint8Array(r), n = 0;
          n < i.length;
          n += 4
        )
          (i[n] = i[n + 3]),
            (i[n + 1] = i[n + 2]),
            (i[n + 2] = 0),
            (i[n + 3] = 0);
        this.value_block.value = String.fromCharCode.apply(
          null,
          new Uint32Array(r)
        );
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.fromString = function (e) {
        var t = e.length;
        this.value_block.value_hex = new ArrayBuffer(4 * t);
        for (
          var i = new Uint8Array(this.value_block.value_hex), n = 0;
          n < t;
          n++
        ) {
          var s = r(e.charCodeAt(n), 8),
            a = new Uint8Array(s);
          if (!(a.length > 4))
            for (var o = 4 - a.length, c = a.length - 1; c >= 0; c--)
              i[4 * n + c + o] = a[c];
        }
        this.value_block.value = e;
      }),
      (e.org.pkijs.asn1.UNIVERSALSTRING.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.UNIVERSALSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (p.SIMPLESTRING_value_block = function () {
        p.hex_block.call(this, arguments[0]),
          (this.value = ""),
          (this.is_hex_only = !0);
      }),
      (p.SIMPLESTRING_value_block.prototype = new p.hex_block()),
      (p.SIMPLESTRING_value_block.constructor = p.SIMPLESTRING_value_block),
      (p.SIMPLESTRING_value_block.prototype.block_name = function () {
        return "SIMPLESTRING_value_block";
      }),
      (p.SIMPLESTRING_value_block.prototype.toJSON = function () {
        var e = p.hex_block.prototype.toJSON.call(this);
        return (
          (e.block_name =
            p.SIMPLESTRING_value_block.prototype.block_name.call(this)),
          (e.value = this.value),
          e
        );
      }),
      (p.SIMPLESTRING_block = function () {
        e.org.pkijs.asn1.ASN1_block.call(this, arguments[0]),
          (this.value_block = new p.SIMPLESTRING_value_block()),
          arguments[0] instanceof Object &&
            "value" in arguments[0] &&
            p.SIMPLESTRING_block.prototype.fromString.call(
              this,
              arguments[0].value
            );
      }),
      (p.SIMPLESTRING_block.prototype = new e.org.pkijs.asn1.ASN1_block()),
      (p.SIMPLESTRING_block.constructor = p.SIMPLESTRING_block),
      (p.SIMPLESTRING_block.prototype.block_name = function () {
        return "SIMPLESTRING";
      }),
      (p.SIMPLESTRING_block.prototype.fromBER = function (e, t, r) {
        var i = this.value_block.fromBER(
          e,
          t,
          1 == this.len_block.is_indefinite_form ? r : this.len_block.length
        );
        return i == -1
          ? ((this.error = this.value_block.error), i)
          : (p.SIMPLESTRING_block.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            i);
      }),
      (p.SIMPLESTRING_block.prototype.fromBuffer = function (e) {
        this.value_block.value = String.fromCharCode.apply(
          null,
          new Uint8Array(e)
        );
      }),
      (p.SIMPLESTRING_block.prototype.fromString = function (e) {
        var t = e.length;
        this.value_block.value_hex = new ArrayBuffer(t);
        for (
          var r = new Uint8Array(this.value_block.value_hex), i = 0;
          i < t;
          i++
        )
          r[i] = e.charCodeAt(i);
        this.value_block.value = e;
      }),
      (p.SIMPLESTRING_block.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.ASN1_block.prototype.toJSON.call(this);
        return (
          (t.block_name = p.SIMPLESTRING_block.prototype.block_name.call(this)),
          (t.block_name = p.value_block.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.NUMERICSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 18);
      }),
      (e.org.pkijs.asn1.NUMERICSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.NUMERICSTRING.constructor =
        e.org.pkijs.asn1.NUMERICSTRING),
      (e.org.pkijs.asn1.NUMERICSTRING.prototype.block_name = function () {
        return "NUMERICSTRING";
      }),
      (e.org.pkijs.asn1.NUMERICSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.NUMERICSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.PRINTABLESTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 19);
      }),
      (e.org.pkijs.asn1.PRINTABLESTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.PRINTABLESTRING.constructor =
        e.org.pkijs.asn1.PRINTABLESTRING),
      (e.org.pkijs.asn1.PRINTABLESTRING.prototype.block_name = function () {
        return "PRINTABLESTRING";
      }),
      (e.org.pkijs.asn1.PRINTABLESTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.PRINTABLESTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.TELETEXSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 20);
      }),
      (e.org.pkijs.asn1.TELETEXSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.TELETEXSTRING.constructor =
        e.org.pkijs.asn1.TELETEXSTRING),
      (e.org.pkijs.asn1.TELETEXSTRING.prototype.block_name = function () {
        return "TELETEXSTRING";
      }),
      (e.org.pkijs.asn1.TELETEXSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.TELETEXSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.VIDEOTEXSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 21);
      }),
      (e.org.pkijs.asn1.VIDEOTEXSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.VIDEOTEXSTRING.constructor =
        e.org.pkijs.asn1.VIDEOTEXSTRING),
      (e.org.pkijs.asn1.VIDEOTEXSTRING.prototype.block_name = function () {
        return "VIDEOTEXSTRING";
      }),
      (e.org.pkijs.asn1.VIDEOTEXSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.VIDEOTEXSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.IA5STRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 22);
      }),
      (e.org.pkijs.asn1.IA5STRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.IA5STRING.constructor = e.org.pkijs.asn1.IA5STRING),
      (e.org.pkijs.asn1.IA5STRING.prototype.block_name = function () {
        return "IA5STRING";
      }),
      (e.org.pkijs.asn1.IA5STRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.IA5STRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.GRAPHICSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 25);
      }),
      (e.org.pkijs.asn1.GRAPHICSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.GRAPHICSTRING.constructor =
        e.org.pkijs.asn1.GRAPHICSTRING),
      (e.org.pkijs.asn1.GRAPHICSTRING.prototype.block_name = function () {
        return "GRAPHICSTRING";
      }),
      (e.org.pkijs.asn1.GRAPHICSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.GRAPHICSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.VISIBLESTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 26);
      }),
      (e.org.pkijs.asn1.VISIBLESTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.VISIBLESTRING.constructor =
        e.org.pkijs.asn1.VISIBLESTRING),
      (e.org.pkijs.asn1.VISIBLESTRING.prototype.block_name = function () {
        return "VISIBLESTRING";
      }),
      (e.org.pkijs.asn1.VISIBLESTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.VISIBLESTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.GENERALSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 27);
      }),
      (e.org.pkijs.asn1.GENERALSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.GENERALSTRING.constructor =
        e.org.pkijs.asn1.GENERALSTRING),
      (e.org.pkijs.asn1.GENERALSTRING.prototype.block_name = function () {
        return "GENERALSTRING";
      }),
      (e.org.pkijs.asn1.GENERALSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.GENERALSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.CHARACTERSTRING = function () {
        p.SIMPLESTRING_block.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 29);
      }),
      (e.org.pkijs.asn1.CHARACTERSTRING.prototype = new p.SIMPLESTRING_block()),
      (e.org.pkijs.asn1.CHARACTERSTRING.constructor =
        e.org.pkijs.asn1.CHARACTERSTRING),
      (e.org.pkijs.asn1.CHARACTERSTRING.prototype.block_name = function () {
        return "CHARACTERSTRING";
      }),
      (e.org.pkijs.asn1.CHARACTERSTRING.prototype.toJSON = function () {
        var t = p.SIMPLESTRING_block.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.CHARACTERSTRING.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.UTCTIME = function () {
        if (
          (e.org.pkijs.asn1.VISIBLESTRING.call(this, arguments[0]),
          (this.year = 0),
          (this.month = 0),
          (this.day = 0),
          (this.hour = 0),
          (this.minute = 0),
          (this.second = 0),
          arguments[0] instanceof Object && "value" in arguments[0])
        ) {
          e.org.pkijs.asn1.UTCTIME.prototype.fromString.call(
            this,
            arguments[0].value
          ),
            (this.value_block.value_hex = new ArrayBuffer(
              arguments[0].value.length
            ));
          for (
            var t = new Uint8Array(this.value_block.value_hex), r = 0;
            r < str.length;
            r++
          )
            t[r] = arguments[0].value.charCodeAt(r);
        }
        arguments[0] instanceof Object &&
          "value_date" in arguments[0] &&
          (e.org.pkijs.asn1.UTCTIME.prototype.fromDate.call(
            this,
            arguments[0].value_date
          ),
          (this.value_block.value_hex =
            e.org.pkijs.asn1.UTCTIME.prototype.toBuffer.call(this))),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 23);
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype =
        new e.org.pkijs.asn1.VISIBLESTRING()),
      (e.org.pkijs.asn1.UTCTIME.constructor = e.org.pkijs.asn1.UTCTIME),
      (e.org.pkijs.asn1.UTCTIME.prototype.fromBER = function (t, r, i) {
        var n = this.value_block.fromBER(
          t,
          r,
          1 == this.len_block.is_indefinite_form ? i : this.len_block.length
        );
        return n == -1
          ? ((this.error = this.value_block.error), n)
          : (e.org.pkijs.asn1.UTCTIME.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            n);
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.fromBuffer = function (t) {
        e.org.pkijs.asn1.UTCTIME.prototype.fromString.call(
          this,
          String.fromCharCode.apply(null, new Uint8Array(t))
        );
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.toBuffer = function () {
        for (
          var t = e.org.pkijs.asn1.UTCTIME.prototype.toString.call(this),
            r = new ArrayBuffer(t.length),
            i = new Uint8Array(r),
            n = 0;
          n < t.length;
          n++
        )
          i[n] = t.charCodeAt(n);
        return r;
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.fromDate = function (e) {
        (this.year = e.getFullYear()),
          (this.month = e.getMonth() + 1),
          (this.day = e.getDate()),
          (this.hour = e.getHours()),
          (this.minute = e.getMinutes()),
          (this.second = e.getSeconds());
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.toDate = function () {
        return new Date(
          this.year,
          this.month - 1,
          this.day,
          this.hour,
          this.minute,
          this.second
        );
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.fromString = function (e) {
        var t = /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})Z/gi,
          r = t.exec(e);
        if (null === r)
          return void (this.error = "Wrong input string for convertion");
        var i = parseInt(r[1], 10);
        i >= 50 ? (this.year = 1900 + i) : (this.year = 2e3 + i),
          (this.month = parseInt(r[2], 10)),
          (this.day = parseInt(r[3], 10)),
          (this.hour = parseInt(r[4], 10)),
          (this.minute = parseInt(r[5], 10)),
          (this.second = parseInt(r[6], 10));
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.toString = function () {
        var t = new Array(7);
        return (
          (t[0] = e.org.pkijs.padNumber(
            this.year < 2e3 ? this.year - 1900 : this.year - 2e3,
            2
          )),
          (t[1] = e.org.pkijs.padNumber(this.month, 2)),
          (t[2] = e.org.pkijs.padNumber(this.day, 2)),
          (t[3] = e.org.pkijs.padNumber(this.hour, 2)),
          (t[4] = e.org.pkijs.padNumber(this.minute, 2)),
          (t[5] = e.org.pkijs.padNumber(this.second, 2)),
          (t[6] = "Z"),
          t.join("")
        );
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.block_name = function () {
        return "UTCTIME";
      }),
      (e.org.pkijs.asn1.UTCTIME.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.VISIBLESTRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.UTCTIME.prototype.block_name.call(this)),
          (t.year = this.year),
          (t.month = this.month),
          (t.day = this.day),
          (t.hour = this.hour),
          (t.minute = this.minute),
          (t.second = this.second),
          t
        );
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME = function () {
        if (
          (e.org.pkijs.asn1.VISIBLESTRING.call(this, arguments[0]),
          (this.year = 0),
          (this.month = 0),
          (this.day = 0),
          (this.hour = 0),
          (this.minute = 0),
          (this.second = 0),
          arguments[0] instanceof Object && "value" in arguments[0])
        ) {
          e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromString.call(
            this,
            arguments[0].value
          ),
            (this.value_block.value_hex = new ArrayBuffer(
              arguments[0].value.length
            ));
          for (
            var t = new Uint8Array(this.value_block.value_hex), r = 0;
            r < str.length;
            r++
          )
            t[r] = arguments[0].value.charCodeAt(r);
        }
        arguments[0] instanceof Object &&
          "value_date" in arguments[0] &&
          (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromDate.call(
            this,
            arguments[0].value_date
          ),
          (this.value_block.value_hex =
            e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toBuffer.call(this))),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 24);
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype =
        new e.org.pkijs.asn1.VISIBLESTRING()),
      (e.org.pkijs.asn1.GENERALIZEDTIME.constructor =
        e.org.pkijs.asn1.GENERALIZEDTIME),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromBER = function (t, r, i) {
        var n = this.value_block.fromBER(
          t,
          r,
          1 == this.len_block.is_indefinite_form ? i : this.len_block.length
        );
        return n == -1
          ? ((this.error = this.value_block.error), n)
          : (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromBuffer.call(
              this,
              this.value_block.value_hex
            ),
            0 == this.id_block.error.length &&
              (this.block_length += this.id_block.block_length),
            0 == this.len_block.error.length &&
              (this.block_length += this.len_block.block_length),
            0 == this.value_block.error.length &&
              (this.block_length += this.value_block.block_length),
            n);
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromBuffer = function (t) {
        e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromString.call(
          this,
          String.fromCharCode.apply(null, new Uint8Array(t))
        );
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toBuffer = function () {
        for (
          var t =
              e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toString.call(this),
            r = new ArrayBuffer(t.length),
            i = new Uint8Array(r),
            n = 0;
          n < t.length;
          n++
        )
          i[n] = t.charCodeAt(n);
        return r;
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromDate = function (e) {
        (this.year = e.getFullYear()),
          (this.month = e.getMonth() + 1),
          (this.day = e.getDate()),
          (this.hour = e.getHours()),
          (this.minute = e.getMinutes()),
          (this.second = e.getSeconds());
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toDate = function () {
        return new Date(
          this.year,
          this.month - 1,
          this.day,
          this.hour,
          this.minute,
          this.second
        );
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.fromString = function (e) {
        var t = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})Z/gi,
          r = t.exec(e);
        return null === r
          ? void (this.error = "Wrong input string for convertion")
          : ((this.year = parseInt(r[1], 10)),
            (this.month = parseInt(r[2], 10)),
            (this.day = parseInt(r[3], 10)),
            (this.hour = parseInt(r[4], 10)),
            (this.minute = parseInt(r[5], 10)),
            void (this.second = parseInt(r[6], 10)));
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toString = function () {
        var t = new Array(7);
        return (
          (t[0] = e.org.pkijs.padNumber(this.year, 4)),
          (t[1] = e.org.pkijs.padNumber(this.month, 2)),
          (t[2] = e.org.pkijs.padNumber(this.day, 2)),
          (t[3] = e.org.pkijs.padNumber(this.hour, 2)),
          (t[4] = e.org.pkijs.padNumber(this.minute, 2)),
          (t[5] = e.org.pkijs.padNumber(this.second, 2)),
          (t[6] = "Z"),
          t.join("")
        );
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.block_name = function () {
        return "GENERALIZEDTIME";
      }),
      (e.org.pkijs.asn1.GENERALIZEDTIME.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.VISIBLESTRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.GENERALIZEDTIME.prototype.block_name.call(this)),
          (t.year = this.year),
          (t.month = this.month),
          (t.day = this.day),
          (t.hour = this.hour),
          (t.minute = this.minute),
          (t.second = this.second),
          t
        );
      }),
      (e.org.pkijs.asn1.DATE = function () {
        e.org.pkijs.asn1.UTF8STRING.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 31);
      }),
      (e.org.pkijs.asn1.DATE.prototype = new e.org.pkijs.asn1.UTF8STRING()),
      (e.org.pkijs.asn1.DATE.constructor = e.org.pkijs.asn1.DATE),
      (e.org.pkijs.asn1.DATE.prototype.block_name = function () {
        return "DATE";
      }),
      (e.org.pkijs.asn1.DATE.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.UTF8STRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.DATE.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.TIMEOFDAY = function () {
        e.org.pkijs.asn1.UTF8STRING.call(this, arguments[0]);
      }),
      (e.org.pkijs.asn1.TIMEOFDAY.prototype =
        new e.org.pkijs.asn1.UTF8STRING()),
      (e.org.pkijs.asn1.TIMEOFDAY.constructor = e.org.pkijs.asn1.TIMEOFDAY),
      (e.org.pkijs.asn1.TIMEOFDAY.prototype.block_name = function () {
        return "TIMEOFDAY";
      }),
      (e.org.pkijs.asn1.DATE.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.UTF8STRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.TIMEOFDAY.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.DATETIME = function () {
        e.org.pkijs.asn1.UTF8STRING.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 33);
      }),
      (e.org.pkijs.asn1.DATETIME.prototype = new e.org.pkijs.asn1.UTF8STRING()),
      (e.org.pkijs.asn1.DATETIME.constructor = e.org.pkijs.asn1.DATETIME),
      (e.org.pkijs.asn1.DATETIME.prototype.block_name = function () {
        return "DATETIME";
      }),
      (e.org.pkijs.asn1.DATETIME.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.UTF8STRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.DATETIME.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.DURATION = function () {
        e.org.pkijs.asn1.UTF8STRING.call(this, arguments[0]);
      }),
      (e.org.pkijs.asn1.DURATION.prototype = new e.org.pkijs.asn1.UTF8STRING()),
      (e.org.pkijs.asn1.DURATION.constructor = e.org.pkijs.asn1.DURATION),
      (e.org.pkijs.asn1.DURATION.prototype.block_name = function () {
        return "DURATION";
      }),
      (e.org.pkijs.asn1.DURATION.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.UTF8STRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.DURATION.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.TIME = function () {
        e.org.pkijs.asn1.UTF8STRING.call(this, arguments[0]),
          (this.id_block.tag_class = 1),
          (this.id_block.tag_number = 14);
      }),
      (e.org.pkijs.asn1.TIME.prototype = new e.org.pkijs.asn1.UTF8STRING()),
      (e.org.pkijs.asn1.TIME.constructor = e.org.pkijs.asn1.TIME),
      (e.org.pkijs.asn1.TIME.prototype.block_name = function () {
        return "TIME";
      }),
      (e.org.pkijs.asn1.TIME.prototype.toJSON = function () {
        var t = e.org.pkijs.asn1.UTF8STRING.prototype.toJSON.call(this);
        return (
          (t.block_name =
            e.org.pkijs.asn1.TIME.prototype.block_name.call(this)),
          t
        );
      }),
      (e.org.pkijs.asn1.CHOICE = function () {
        arguments[0] instanceof Object &&
          ((this.value = e.org.pkijs.getValue(
            arguments[0],
            "value",
            new Array()
          )),
          (this.optional = e.org.pkijs.getValue(arguments[0], "optional", !1)));
      }),
      (e.org.pkijs.asn1.ANY = function () {
        arguments[0] instanceof Object &&
          ((this.name = e.org.pkijs.getValue(arguments[0], "name", "")),
          (this.optional = e.org.pkijs.getValue(arguments[0], "optional", !1)));
      }),
      (e.org.pkijs.asn1.REPEATED = function () {
        arguments[0] instanceof Object &&
          ((this.name = e.org.pkijs.getValue(arguments[0], "name", "")),
          (this.optional = e.org.pkijs.getValue(arguments[0], "optional", !1)),
          (this.value = e.org.pkijs.getValue(
            arguments[0],
            "value",
            new e.org.pkijs.asn1.ANY()
          )),
          (this.local = e.org.pkijs.getValue(arguments[0], "local", !1)));
      }),
      (e.org.pkijs.fromBER = function (t) {
        if (0 == t.byteLength) {
          var r = new e.org.pkijs.asn1.ASN1_block();
          return (r.error = "Input buffer has zero length"), r;
        }
        return u(t, 0, t.byteLength);
      }),
      (e.org.pkijs.compareSchema = function (t, r, i) {
        if (i instanceof e.org.pkijs.asn1.CHOICE) {
          for (var n = !1, s = 0; s < i.value.length; s++) {
            var a = e.org.pkijs.compareSchema(t, r, i.value[s]);
            if (a.verified === !0) return { verified: !0, result: t };
          }
          if (n === !1) {
            var o = {
              verified: !1,
              result: { error: "Wrong values for CHOICE type" },
            };
            return i.hasOwnProperty("name") && (o.name = i.name), o;
          }
        }
        if (i instanceof e.org.pkijs.asn1.ANY)
          return (
            i.hasOwnProperty("name") && (t[i.name] = r),
            { verified: !0, result: t }
          );
        if (t instanceof Object == !1)
          return { verified: !1, result: { error: "Wrong root object" } };
        if (r instanceof Object == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 data" } };
        if (i instanceof Object == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if ("id_block" in i == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if ("fromBER" in i.id_block == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if ("toBER" in i.id_block == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        var c = i.id_block.toBER(!1);
        if (0 === c.byteLength)
          return {
            verified: !1,
            result: { error: "Error encoding id_block for ASN.1 schema" },
          };
        var l = i.id_block.fromBER(c, 0, c.byteLength);
        if (l === -1)
          return {
            verified: !1,
            result: { error: "Error decoding id_block for ASN.1 schema" },
          };
        if (i.id_block.hasOwnProperty("tag_class") === !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if (i.id_block.tag_class !== r.id_block.tag_class)
          return { verified: !1, result: t };
        if (i.id_block.hasOwnProperty("tag_number") === !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if (i.id_block.tag_number !== r.id_block.tag_number)
          return { verified: !1, result: t };
        if (i.id_block.hasOwnProperty("is_constructed") === !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if (i.id_block.is_constructed !== r.id_block.is_constructed)
          return { verified: !1, result: t };
        if ("is_hex_only" in i.id_block == !1)
          return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
        if (i.id_block.is_hex_only !== r.id_block.is_hex_only)
          return { verified: !1, result: t };
        if (i.id_block.is_hex_only === !0) {
          if ("value_hex" in i.id_block == !1)
            return { verified: !1, result: { error: "Wrong ASN.1 schema" } };
          var u = new Uint8Array(i.id_block.value_hex),
            p = new Uint8Array(r.id_block.value_hex);
          if (u.length !== p.length) return { verified: !1, result: t };
          for (var h = 0; h < u.length; h++)
            if (u[h] !== p[1]) return { verified: !1, result: t };
        }
        if (
          (i.hasOwnProperty("name") &&
            ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
            "" !== i.name && (t[i.name] = r)),
          i.id_block.is_constructed === !0)
        ) {
          var f = 0,
            a = { verified: !1 },
            g = i.value_block.value.length;
          if (
            (g > 0 &&
              i.value_block.value[0] instanceof e.org.pkijs.asn1.REPEATED &&
              (g = r.value_block.value.length),
            0 === g)
          )
            return { verified: !0, result: t };
          if (
            0 === r.value_block.value.length &&
            0 !== i.value_block.value.length
          ) {
            for (var m = !0, h = 0; h < i.value_block.value.length; h++)
              m = m && (i.value_block.value[h].optional || !1);
            return m === !0
              ? { verified: !0, result: t }
              : (i.hasOwnProperty("name") &&
                  ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                  "" !== i.name && delete t[i.name]),
                (t.error = "Inconsistent object length"),
                { verified: !1, result: t });
          }
          for (var h = 0; h < g; h++)
            if (h - f >= r.value_block.value.length) {
              if (i.value_block.value[h].optional === !1) {
                var o = { verified: !1, result: t };
                return (
                  (t.error =
                    "Inconsistent length between ASN.1 data and schema"),
                  i.hasOwnProperty("name") &&
                    ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                    "" !== i.name && (delete t[i.name], (o.name = i.name))),
                  o
                );
              }
            } else if (
              i.value_block.value[0] instanceof e.org.pkijs.asn1.REPEATED
            ) {
              if (
                ((a = e.org.pkijs.compareSchema(
                  t,
                  r.value_block.value[h],
                  i.value_block.value[0].value
                )),
                a.verified === !1)
              ) {
                if (i.value_block.value[0].optional !== !0)
                  return (
                    i.hasOwnProperty("name") &&
                      ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                      "" !== i.name && delete t[i.name]),
                    a
                  );
                f++;
              }
              if (
                "name" in i.value_block.value[0] &&
                i.value_block.value[0].name.length > 0
              ) {
                var d = {};
                (d =
                  "local" in i.value_block.value[0] &&
                  i.value_block.value[0].local === !0
                    ? r
                    : t),
                  "undefined" == typeof d[i.value_block.value[0].name] &&
                    (d[i.value_block.value[0].name] = new Array()),
                  d[i.value_block.value[0].name].push(r.value_block.value[h]);
              }
            } else if (
              ((a = e.org.pkijs.compareSchema(
                t,
                r.value_block.value[h - f],
                i.value_block.value[h]
              )),
              a.verified === !1)
            ) {
              if (i.value_block.value[h].optional !== !0)
                return (
                  i.hasOwnProperty("name") &&
                    ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                    "" !== i.name && delete t[i.name]),
                  a
                );
              f++;
            }
          if (a.verified === !1) {
            var o = { verified: !1, result: t };
            return (
              i.hasOwnProperty("name") &&
                ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                "" !== i.name && (delete t[i.name], (o.name = i.name))),
              o
            );
          }
          return { verified: !0, result: t };
        }
        if ("primitive_schema" in i && "value_hex" in r.value_block) {
          var y = e.org.pkijs.fromBER(r.value_block.value_hex);
          if (y.offset === -1) {
            var o = { verified: !1, result: y.result };
            return (
              i.hasOwnProperty("name") &&
                ((i.name = i.name.replace(/^\s+|\s+$/g, "")),
                "" !== i.name && (delete t[i.name], (o.name = i.name))),
              o
            );
          }
          return e.org.pkijs.compareSchema(t, y.result, i.primitive_schema);
        }
        return { verified: !0, result: t };
      }),
      (e.org.pkijs.verifySchema = function (t, r) {
        if (r instanceof Object == !1)
          return { varified: !1, result: { error: "Wrong ASN.1 schema type" } };
        var i = e.org.pkijs.fromBER(t);
        return i.offset === -1
          ? { verified: !1, result: i.result }
          : e.org.pkijs.compareSchema(i.result, i.result, r);
      }),
      (e.org.pkijs.fromJSON = function (e) {});
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.schema) e.org.pkijs.schema = {};
    else if ("object" != typeof e.org.pkijs.schema)
      throw new Error(
        "Name org.pkijs.schema already exists and it's not an object but " +
          typeof e.org.pkijs.schema
      );
    if ("undefined" == typeof e.org.pkijs.schema.cms)
      e.org.pkijs.schema.cms = {};
    else if ("object" != typeof e.org.pkijs.schema.cms)
      throw new Error(
        "Name org.pkijs.schema.cms already exists and it's not an object but " +
          typeof e.org.pkijs.schema.cms
      );
    if ("undefined" == typeof e.org.pkijs.schema.x509)
      e.org.pkijs.schema.x509 = {};
    else if ("object" != typeof e.org.pkijs.schema.x509)
      throw new Error(
        "Name org.pkijs.schema.x509 already exists and it's not an object but " +
          typeof e.org.pkijs.schema.x509
      );
    (e.org.pkijs.schema.CMS_CONTENT_INFO = function () {
      var t = e.org.pkijs.getNames(arguments[0]);
      return new e.org.pkijs.asn1.SEQUENCE({
        name: t.block_name || "ContentInfo",
        value: [
          new e.org.pkijs.asn1.OID({ name: t.contentType || "contentType" }),
          new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
            id_block: { tag_class: 3, tag_number: 0 },
            value: [new e.org.pkijs.asn1.ANY({ name: t.content || "content" })],
          }),
        ],
      });
    }),
      (e.org.pkijs.schema.cms.OtherCertificateFormat = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({
              name: t.otherCertFormat || "otherCertFormat",
            }),
            new e.org.pkijs.asn1.ANY({ name: t.otherCert || "otherCert" }),
          ],
        });
      }),
      (e.org.pkijs.schema.CMS_CERTIFICATE_SET = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SET({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.certificates || "",
              value: new e.org.pkijs.asn1.CHOICE({
                value: [
                  e.org.pkijs.schema.CERT(),
                  new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                    id_block: { tag_class: 3, tag_number: 3 },
                    value: [
                      new e.org.pkijs.asn1.OID(),
                      new e.org.pkijs.asn1.ANY(),
                    ],
                  }),
                ],
              }),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OtherRevocationInfoFormat = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({
              name: t.otherRevInfoFormat || "otherRevInfoFormat",
            }),
            new e.org.pkijs.asn1.ANY({
              name: t.otherRevInfo || "otherRevInfo",
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CSM_REVOCATION_INFO_CHOICES = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SET({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.crls || "",
              value: new e.org.pkijs.asn1.CHOICE({
                value: [
                  e.org.pkijs.schema.CRL(),
                  new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                    id_block: { tag_class: 3, tag_number: 1 },
                    value: [
                      new e.org.pkijs.asn1.OID(),
                      new e.org.pkijs.asn1.ANY(),
                    ],
                  }),
                ],
              }),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.IssuerAndSerialNumber = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.RDN(t.issuer || {}),
            new e.org.pkijs.asn1.INTEGER({ name: t.serialNumber || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.Attribute = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.attrType || "" }),
            new e.org.pkijs.asn1.SET({
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.attrValues || "",
                  value: new e.org.pkijs.asn1.ANY(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.RSAES_OAEP_params = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              optional: !0,
              value: [
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.hashAlgorithm || {}),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 1 },
              optional: !0,
              value: [
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
                  t.maskGenAlgorithm || {}
                ),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 2 },
              optional: !0,
              value: [
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
                  t.pSourceAlgorithm || {}
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.SignedUnsignedAttributes = function (t, r) {
        var i = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
          name: i.block_name || "",
          optional: !0,
          id_block: { tag_class: 3, tag_number: r },
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: i.attributes || "",
              value: e.org.pkijs.schema.cms.Attribute(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CMS_SIGNER_INFO = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: "SignerInfo",
          value: [
            new e.org.pkijs.asn1.INTEGER({
              name: t.version || "SignerInfo.version",
            }),
            new e.org.pkijs.asn1.CHOICE({
              value: [
                e.org.pkijs.schema.cms.IssuerAndSerialNumber(
                  t.sid || { names: { block_name: "SignerInfo.sid" } }
                ),
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  optional: !0,
                  name: t.sid || "SignerInfo.sid",
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [new e.org.pkijs.asn1.OCTETSTRING()],
                }),
              ],
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.digestAlgorithm || {
                names: { block_name: "SignerInfo.digestAlgorithm" },
              }
            ),
            e.org.pkijs.schema.cms.SignedUnsignedAttributes(
              t.signedAttrs || {
                names: { block_name: "SignerInfo.signedAttrs" },
              },
              0
            ),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.signatureAlgorithm || {
                names: { block_name: "SignerInfo.signatureAlgorithm" },
              }
            ),
            new e.org.pkijs.asn1.OCTETSTRING({
              name: t.signature || "SignerInfo.signature",
            }),
            e.org.pkijs.schema.cms.SignedUnsignedAttributes(
              t.unsignedAttrs || {
                names: { block_name: "SignerInfo.unsignedAttrs" },
              },
              1
            ),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.EncapsulatedContentInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.eContentType || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.ANY({ name: t.eContent || "" })],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CMS_SIGNED_DATA = function (t, r) {
        var t = e.org.pkijs.getNames(arguments[0]);
        return (
          "undefined" == typeof r && (r = !1),
          new e.org.pkijs.asn1.SEQUENCE({
            name: t.block_name || "SignedData",
            value: [
              new e.org.pkijs.asn1.INTEGER({
                name: t.version || "SignedData.version",
              }),
              new e.org.pkijs.asn1.SET({
                value: [
                  new e.org.pkijs.asn1.REPEATED({
                    name: t.digestAlgorithms || "SignedData.digestAlgorithms",
                    value: e.org.pkijs.schema.ALGORITHM_IDENTIFIER(),
                  }),
                ],
              }),
              e.org.pkijs.schema.cms.EncapsulatedContentInfo(
                t.encapContentInfo || {
                  names: { block_name: "SignedData.encapContentInfo" },
                }
              ),
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: e.org.pkijs.schema.CMS_CERTIFICATE_SET(
                  t.certificates || {
                    names: { certificates: "SignedData.certificates" },
                  }
                ).value_block.value,
              }),
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 1 },
                value: e.org.pkijs.schema.CSM_REVOCATION_INFO_CHOICES(
                  t.crls || { names: { crls: "SignedData.crls" } }
                ).value_block.value,
              }),
              new e.org.pkijs.asn1.SET({
                value: [
                  new e.org.pkijs.asn1.REPEATED({
                    name: t.signerInfos || "SignedData.signerInfos",
                    value: e.org.pkijs.schema.CMS_SIGNER_INFO(),
                  }),
                ],
              }),
            ],
          })
        );
      }),
      (e.org.pkijs.schema.cms.ECC_CMS_SharedInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.keyInfo || {}),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.entityUInfo || "",
              id_block: { tag_class: 3, tag_number: 0 },
              optional: !0,
              value: [new e.org.pkijs.asn1.OCTETSTRING()],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.suppPubInfo || "",
              id_block: { tag_class: 3, tag_number: 2 },
              value: [new e.org.pkijs.asn1.OCTETSTRING()],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.PBKDF2_params = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.CHOICE({
              value: [
                new e.org.pkijs.asn1.OCTETSTRING({
                  name: t.salt_primitive || "",
                }),
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
                  t.salt_constructed || {}
                ),
              ],
            }),
            new e.org.pkijs.asn1.INTEGER({ name: t.iterationCount || "" }),
            new e.org.pkijs.asn1.INTEGER({
              name: t.keyLength || "",
              optional: !0,
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.prf || { names: { optional: !0 } }
            ),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.RecipientIdentifier = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            e.org.pkijs.schema.cms.IssuerAndSerialNumber({
              names: { block_name: t.block_name || "" },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.OCTETSTRING()],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.KeyTransRecipientInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            e.org.pkijs.schema.cms.RecipientIdentifier(t.rid || {}),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.keyEncryptionAlgorithm || {}
            ),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.encryptedKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OriginatorPublicKey = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.algorithm || {}),
            new e.org.pkijs.asn1.BITSTRING({ name: t.publicKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OriginatorIdentifierOrKey = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            e.org.pkijs.schema.cms.IssuerAndSerialNumber({
              names: { block_name: t.block_name || "" },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              id_block: { tag_class: 3, tag_number: 0 },
              name: t.block_name || "",
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 1 },
              name: t.block_name || "",
              value:
                e.org.pkijs.schema.cms.OriginatorPublicKey().value_block.value,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OtherKeyAttribute = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          optional: t.optional || !0,
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.keyAttrId || "" }),
            new e.org.pkijs.asn1.ANY({ optional: !0, name: t.keyAttr || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.RecipientKeyIdentifier = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OCTETSTRING({
              name: t.subjectKeyIdentifier || "",
            }),
            new e.org.pkijs.asn1.GENERALIZEDTIME({
              optional: !0,
              name: t.date || "",
            }),
            e.org.pkijs.schema.cms.OtherKeyAttribute(t.other || {}),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.KeyAgreeRecipientIdentifier = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            e.org.pkijs.schema.cms.IssuerAndSerialNumber(
              t.issuerAndSerialNumber || {
                names: { block_name: t.block_name || "" },
              }
            ),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 0 },
              value: e.org.pkijs.schema.cms.RecipientKeyIdentifier(
                t.rKeyId || { names: { block_name: t.block_name || "" } }
              ).value_block.value,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.RecipientEncryptedKey = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.cms.KeyAgreeRecipientIdentifier(t.rid || {}),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.encryptedKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.RecipientEncryptedKeys = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.RecipientEncryptedKeys || "",
              value: e.org.pkijs.schema.cms.RecipientEncryptedKey(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.KeyAgreeRecipientInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.cms.OriginatorIdentifierOrKey(
                  t.originator || {}
                ),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [new e.org.pkijs.asn1.OCTETSTRING({ name: t.ukm || "" })],
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.keyEncryptionAlgorithm || {}
            ),
            e.org.pkijs.schema.cms.RecipientEncryptedKeys(
              t.recipientEncryptedKeys || {}
            ),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.KEKIdentifier = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.keyIdentifier || "" }),
            new e.org.pkijs.asn1.GENERALIZEDTIME({
              optional: !0,
              name: t.date || "",
            }),
            e.org.pkijs.schema.cms.OtherKeyAttribute(t.other || {}),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.KEKRecipientInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            e.org.pkijs.schema.cms.KEKIdentifier(t.kekid || {}),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.keyEncryptionAlgorithm || {}
            ),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.encryptedKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.PasswordRecipientinfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.keyDerivationAlgorithm || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value:
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER().value_block.value,
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.keyEncryptionAlgorithm || {}
            ),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.encryptedKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OtherRecipientInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.oriType || "" }),
            new e.org.pkijs.asn1.ANY({ name: t.oriValue || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.CMS_RECIPIENT_INFO = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            e.org.pkijs.schema.cms.KeyTransRecipientInfo({
              names: { block_name: t.block_name || "" },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 1 },
              value:
                e.org.pkijs.schema.cms.KeyAgreeRecipientInfo().value_block
                  .value,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 2 },
              value:
                e.org.pkijs.schema.cms.KEKRecipientInfo().value_block.value,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 3 },
              value:
                e.org.pkijs.schema.cms.PasswordRecipientinfo().value_block
                  .value,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 4 },
              value:
                e.org.pkijs.schema.cms.OtherRecipientInfo().value_block.value,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.OriginatorInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.certs || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: e.org.pkijs.schema.CMS_CERTIFICATE_SET().value_block.value,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.crls || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value:
                e.org.pkijs.schema.CSM_REVOCATION_INFO_CHOICES().value_block
                  .value,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.cms.EncryptedContentInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.contentType || "" }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.contentEncryptionAlgorithm || {}
            ),
            new e.org.pkijs.asn1.CHOICE({
              value: [
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  name: t.encryptedContent || "",
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [
                    new e.org.pkijs.asn1.REPEATED({
                      value: new e.org.pkijs.asn1.OCTETSTRING(),
                    }),
                  ],
                }),
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: t.encryptedContent || "",
                  id_block: { tag_class: 3, tag_number: 0 },
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CMS_ENVELOPED_DATA = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.originatorInfo || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: e.org.pkijs.schema.cms.OriginatorInfo().value_block.value,
            }),
            new e.org.pkijs.asn1.SET({
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.recipientInfos || "",
                  value: e.org.pkijs.schema.CMS_RECIPIENT_INFO(),
                }),
              ],
            }),
            e.org.pkijs.schema.cms.EncryptedContentInfo(
              t.encryptedContentInfo || {}
            ),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.unprotectedAttrs || "",
                  value: e.org.pkijs.schema.ATTRIBUTE(),
                }),
              ],
            }),
          ],
        });
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.simpl) e.org.pkijs.simpl = {};
    else if ("object" != typeof e.org.pkijs.simpl)
      throw new Error(
        "Name org.pkijs.simpl already exists and it's not an object but " +
          typeof e.org.pkijs.simpl
      );
    if ("undefined" == typeof e.org.pkijs.simpl.cms) e.org.pkijs.simpl.cms = {};
    else if ("object" != typeof e.org.pkijs.simpl.cms)
      throw new Error(
        "Name org.pkijs.simpl.cms already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.cms
      );
    if ("undefined" == typeof e.org.pkijs.simpl.x509)
      e.org.pkijs.simpl.x509 = {};
    else if ("object" != typeof e.org.pkijs.simpl.x509)
      throw new Error(
        "Name org.pkijs.simpl.x509 already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.x509
      );
    (e.org.pkijs.simpl.cms.kdfWithCounter = function (t, r, i, n) {
      switch (t.toUpperCase()) {
        case "SHA-1":
        case "SHA-256":
        case "SHA-384":
        case "SHA-512":
          break;
        default:
          return new Promise(function (e, r) {
            r("Unknown hash function: " + t);
          });
      }
      if (r instanceof ArrayBuffer == !1)
        return new Promise(function (e, t) {
          t('Please set "Zbuffer" as "ArrayBuffer"');
        });
      if (0 === r.byteLength)
        return new Promise(function (e, t) {
          t('"Zbuffer" has zero length, error');
        });
      if (n instanceof ArrayBuffer == !1)
        return new Promise(function (e, t) {
          t('Please set "SharedInfo" as "ArrayBuffer"');
        });
      if (i > 255)
        return new Promise(function (e, t) {
          t('Please set "Counter" variable to value less or equal to 255');
        });
      var s = new ArrayBuffer(4),
        a = new Uint8Array(s);
      (a[0] = 0), (a[1] = 0), (a[2] = 0), (a[3] = i);
      var o = new ArrayBuffer(0),
        c = e.org.pkijs.getCrypto();
      return "undefined" == typeof c
        ? new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          })
        : ((o = e.org.pkijs.concat_buffers(o, r)),
          (o = e.org.pkijs.concat_buffers(o, s)),
          (o = e.org.pkijs.concat_buffers(o, n)),
          c.digest({ name: t }, o).then(function (e) {
            return { counter: i, result: e };
          }));
    }),
      (e.org.pkijs.simpl.cms.kdf = function (t, r, i, n) {
        var s = 0,
          a = 1,
          o = new Array();
        switch (t.toUpperCase()) {
          case "SHA-1":
            s = 160;
            break;
          case "SHA-256":
            s = 256;
            break;
          case "SHA-384":
            s = 384;
            break;
          case "SHA-512":
            s = 512;
            break;
          default:
            return new Promise(function (e, r) {
              r("Unknown hash function: " + t);
            });
        }
        if (r instanceof ArrayBuffer == !1)
          return new Promise(function (e, t) {
            t('Please set "Zbuffer" as "ArrayBuffer"');
          });
        if (0 === r.byteLength)
          return new Promise(function (e, t) {
            t('"Zbuffer" has zero length, error');
          });
        if (n instanceof ArrayBuffer == !1)
          return new Promise(function (e, t) {
            t('Please set "SharedInfo" as "ArrayBuffer"');
          });
        var c = i / s;
        Math.floor(c) > 0 && ((a = Math.floor(c)), c - a > 0 && a++);
        for (var l = 1; l <= a; l++)
          o.push(e.org.pkijs.simpl.cms.kdfWithCounter(t, r, l, n));
        return Promise.all(o).then(
          function (e) {
            for (var t = new ArrayBuffer(0), r = 1, n = !0; n; ) {
              n = !1;
              for (var s = 0; s < e.length; s++)
                if (e[s].counter === r) {
                  (t = org.pkijs.concat_buffers(t, e[s].result)), (n = !0);
                  break;
                }
              r++;
            }
            if (((i >>= 3), t.byteLength > i)) {
              for (
                var a = new ArrayBuffer(i),
                  o = new Uint8Array(a),
                  c = new Uint8Array(t),
                  s = 0;
                s < i;
                s++
              )
                o[s] = c[s];
              return a;
            }
            return t;
          },
          function (e) {
            return new Promise(function (t, r) {
              r(e);
            });
          }
        );
      }),
      (e.org.pkijs.simpl.CMS_CONTENT_INFO = function () {
        (this.contentType = ""),
          (this.content = new e.org.pkijs.asn1.ANY()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_CONTENT_INFO.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.contentType = arguments[0].contentType || ""),
              (this.content =
                arguments[0].content || new e.org.pkijs.asn1.ANY()));
      }),
      (e.org.pkijs.simpl.CMS_CONTENT_INFO.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_CONTENT_INFO()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_CONTENT_INFO"
          );
        (this.contentType = r.result.contentType.value_block.toString()),
          (this.content = r.result.content);
      }),
      (e.org.pkijs.simpl.CMS_CONTENT_INFO.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.OID({ value: this.contentType }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              value: [this.content],
            }),
          ],
        });
      }),
      (e.org.pkijs.simpl.CMS_CONTENT_INFO.prototype.toJSON = function () {
        var t = { contentType: this.contentType };
        return (
          this.content instanceof e.org.pkijs.asn1.ANY ||
            (t.content = this.content.toJSON()),
          t
        );
      }),
      (e.org.pkijs.simpl.cms.OtherCertificateFormat = function () {
        (this.otherCertFormat = ""),
          (this.otherCert = new e.org.pkijs.asn1.ANY()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OtherCertificateFormat.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.otherCertFormat = arguments[0].contentType || ""),
              (this.otherCert =
                arguments[0].content || new e.org.pkijs.asn1.ANY()));
      }),
      (e.org.pkijs.simpl.cms.OtherCertificateFormat.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.OtherCertificateFormat()
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for OtherCertificateFormat"
            );
          (this.otherCertFormat =
            r.result.otherCertFormat.value_block.toString()),
            (this.otherCert = r.result.otherCert);
        }),
      (e.org.pkijs.simpl.cms.OtherCertificateFormat.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.OID({ value: this.otherCertFormat }),
              this.otherCert,
            ],
          });
        }),
      (e.org.pkijs.simpl.cms.OtherCertificateFormat.prototype.toJSON =
        function () {
          var t = { otherCertFormat: this.otherCertFormat };
          return (
            this.otherCert instanceof e.org.pkijs.asn1.ANY ||
              (t.otherCert = this.otherCert.toJSON()),
            t
          );
        }),
      (e.org.pkijs.simpl.cms.OtherRevocationInfoFormat = function () {
        (this.otherRevInfoFormat = ""),
          (this.otherRevInfo = new e.org.pkijs.asn1.ANY()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OtherRevocationInfoFormat.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.otherRevInfoFormat =
                arguments[0].otherRevInfoFormat || ""),
              (this.otherRevInfo =
                arguments[0].otherRevInfo || new e.org.pkijs.asn1.ANY()));
      }),
      (e.org.pkijs.simpl.cms.OtherRevocationInfoFormat.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.OtherRevocationInfoFormat()
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for OtherRevocationInfoFormat"
            );
          (this.otherRevInfoFormat =
            r.result.otherRevInfoFormat.value_block.toString()),
            (this.otherRevInfo = r.result.otherRevInfo);
        }),
      (e.org.pkijs.simpl.cms.OtherRevocationInfoFormat.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.OID({ value: this.otherRevInfoFormat }),
              this.otherRevInfo,
            ],
          });
        }),
      (e.org.pkijs.simpl.cms.OtherRevocationInfoFormat.prototype.toJSON =
        function () {
          var t = { otherRevInfoFormat: this.otherRevInfoFormat };
          return (
            this.otherRevInfo instanceof e.org.pkijs.asn1.ANY ||
              (t.otherRevInfo = this.otherRevInfo.toJSON()),
            t
          );
        }),
      (e.org.pkijs.simpl.CMS_CERTIFICATE_SET = function () {
        (this.certificates = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_CERTIFICATE_SET.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.certificates = arguments[0].certificates || new Array());
      }),
      (e.org.pkijs.simpl.CMS_CERTIFICATE_SET.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_CERTIFICATE_SET()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_CERTIFICATE_SET"
          );
        for (var i = r.result.certificates, n = 0; n < i; n++)
          1 === i.id_block.tag_class
            ? this.certificates.push(
                new e.org.pkijs.simpl.CERT({ schema: i[n] })
              )
            : this.certificates.push(i[n]);
      }),
      (e.org.pkijs.simpl.CMS_CERTIFICATE_SET.prototype.toSchema = function () {
        for (var t = new Array(), r = 0; r < this.certificates.length; r++)
          this.certificates[r] instanceof e.org.pkijs.simpl.CERT
            ? t.push(this.certificates[r].toSchema())
            : t.push(this.certificates[r]);
        return new e.org.pkijs.asn1.SET({ value: t });
      }),
      (e.org.pkijs.simpl.CMS_CERTIFICATE_SET.prototype.toJSON = function () {
        var e = {};
        e.certificates = new Array();
        for (var t = 0; t < this.certificates.length; t++)
          e.certificates.push(this.certificates[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES = function () {
        (this.crls = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.crls = arguments[0].crls || new Array());
      }),
      (e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.CSM_REVOCATION_INFO_CHOICES()
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for CSM_REVOCATION_INFO_CHOICES"
            );
          for (var i = r.result.crls, n = 0; n < i; n++)
            1 === i.id_block.tag_class
              ? this.crls.push(new e.org.pkijs.simpl.CRL({ schema: i[n] }))
              : this.crls.push(i[n]);
        }),
      (e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES.prototype.toSchema =
        function () {
          for (var t = new Array(), r = 0; r < this.crls.length; r++)
            this.crls[r] instanceof e.org.pkijs.simpl.CRL
              ? t.push(this.crls[r].toSchema())
              : t.push(this.crls[r]);
          return new e.org.pkijs.asn1.SET({ value: t });
        }),
      (e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES.prototype.toJSON =
        function () {
          var e = {};
          e.crls = new Array();
          for (var t = 0; t < this.crls.length; t++)
            e.crls.push(this.crls[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.cms.IssuerAndSerialNumber = function () {
        (this.issuer = new e.org.pkijs.simpl.RDN()),
          (this.serialNumber = new e.org.pkijs.asn1.INTEGER()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.IssuerAndSerialNumber.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.issuer =
                arguments[0].issuer || new e.org.pkijs.simpl.RDN()),
              (this.serialNumber =
                arguments[0].serialNumber || new e.org.pkijs.asn1.INTEGER()));
      }),
      (e.org.pkijs.simpl.cms.IssuerAndSerialNumber.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.IssuerAndSerialNumber({
              names: {
                issuer: { names: { block_name: "issuer" } },
                serialNumber: "serialNumber",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for IssuerAndSerialNumber"
            );
          (this.issuer = new e.org.pkijs.simpl.RDN({
            schema: r.result.issuer,
          })),
            (this.serialNumber = r.result.serialNumber);
        }),
      (e.org.pkijs.simpl.cms.IssuerAndSerialNumber.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [this.issuer.toSchema(), this.serialNumber],
          });
        }),
      (e.org.pkijs.simpl.cms.IssuerAndSerialNumber.prototype.toJSON =
        function () {
          return {
            issuer: this.issuer.toJSON(),
            serialNumber: this.serialNumber.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.Attribute = function () {
        (this.attrType = ""),
          (this.attrValues = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.Attribute.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.attrType = arguments[0].attrType || ""),
              (this.attrValues = arguments[0].attrValues || new Array()));
      }),
      (e.org.pkijs.simpl.cms.Attribute.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.Attribute({
            names: { attrType: "attrType", attrValues: "attrValues" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for Attribute"
          );
        switch (
          ((this.attrType = r.result.attrType.value_block.toString()),
          (this.attrValues = r.result.attrValues),
          this.attrType)
        ) {
          case "0.4.0.1733.2.5":
            this.parsedValue = new e.org.pkijs.simpl.cades.ATSHashIndex({
              schema: this.attrValues[0],
            });
            break;
          case "0.4.0.1733.2.4":
            this.parsedValue =
              new e.org.pkijs.simpl.cades.archive_time_stamp_v3({
                schema: this.attrValues[0],
              });
            break;
          case "1.2.840.113549.1.9.16.2.14":
            this.parsedValue = new e.org.pkijs.simpl.cades.signature_time_stamp(
              { schema: this.attrValues[0] }
            );
            break;
          case "1.2.840.113549.1.9.16.2.21":
            this.parsedValue =
              new e.org.pkijs.simpl.cades.complete_certificate_references({
                schema: this.attrValues[0],
              });
            break;
          case "1.2.840.113549.1.9.16.2.22":
            this.parsedValue =
              new e.org.pkijs.simpl.cades.complete_revocation_references({
                schema: this.attrValues[0],
              });
            break;
          case "1.2.840.113549.1.9.16.2.25":
            this.parsedValue = new e.org.pkijs.simpl.cades.CAdES_C_Timestamp({
              schema: this.attrValues[0],
            });
            break;
          case "1.2.840.113549.1.9.16.2.23":
            this.parsedValue = new e.org.pkijs.simpl.cades.certificate_values({
              schema: this.attrValues[0],
            });
            break;
          case "1.2.840.113549.1.9.16.2.24":
            this.parsedValue = new e.org.pkijs.simpl.cades.revocation_values({
              schema: this.attrValues[0],
            });
        }
      }),
      (e.org.pkijs.simpl.cms.Attribute.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.OID({ value: this.attrType }),
            new e.org.pkijs.asn1.SET({ value: this.attrValues }),
          ],
        });
      }),
      (e.org.pkijs.simpl.cms.Attribute.prototype.toJSON = function () {
        var e = { attrType: this.attrType };
        e.attrValues = new Array();
        for (var t = 0; t < this.attrValues.length; t++)
          e.attrValues.push(this.attrValues[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.x509.RSAES_OAEP_params = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.RSAES_OAEP_params.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("hashAlgorithm" in arguments[0] &&
              (this.hashAlgorithm = arguments[0].hashAlgorithm),
            "maskGenAlgorithm" in arguments[0] &&
              (this.maskGenAlgorithm = arguments[0].maskGenAlgorithm),
            "pSourceAlgorithm" in arguments[0] &&
              (this.pSourceAlgorithm = arguments[0].pSourceAlgorithm));
      }),
      (e.org.pkijs.simpl.x509.RSAES_OAEP_params.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.RSAES_OAEP_params({
              names: {
                hashAlgorithm: { names: { block_name: "hashAlgorithm" } },
                maskGenAlgorithm: { names: { block_name: "maskGenAlgorithm" } },
                pSourceAlgorithm: { names: { block_name: "pSourceAlgorithm" } },
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RSAES_OAEP_params"
            );
          "hashAlgorithm" in r.result &&
            (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.hashAlgorithm,
            })),
            "maskGenAlgorithm" in r.result &&
              (this.maskGenAlgorithm =
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  schema: r.result.maskGenAlgorithm,
                })),
            "pSourceAlgorithm" in r.result &&
              (this.pSourceAlgorithm =
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  schema: r.result.pSourceAlgorithm,
                }));
        }),
      (e.org.pkijs.simpl.x509.RSAES_OAEP_params.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            "hashAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [this.hashAlgorithm.toSchema()],
                })
              ),
            "maskGenAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [this.maskGenAlgorithm.toSchema()],
                })
              ),
            "pSourceAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 2 },
                  value: [this.pSourceAlgorithm.toSchema()],
                })
              ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.x509.RSAES_OAEP_params.prototype.toJSON = function () {
        var e = {};
        return (
          "hashAlgorithm" in this &&
            (e.hashAlgorithm = this.hashAlgorithm.toJSON()),
          "maskGenAlgorithm" in this &&
            (e.maskGenAlgorithm = this.maskGenAlgorithm.toJSON()),
          "pSourceAlgorithm" in this &&
            (e.pSourceAlgorithm = this.pSourceAlgorithm.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.cms.PBKDF2_params = function () {
        (this.salt = new e.org.pkijs.emptyObject()),
          (this.iterationCount = 0),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.PBKDF2_params.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.salt = arguments[0].salt || new e.org.pkijs.emptyObject()),
              (this.iterationCount = arguments[0].iterationCount || 0),
              "keyLength" in arguments[0] &&
                (this.keyLength = arguments[0].keyLength),
              "prf" in arguments[0] && (this.prf = arguments[0].prf));
      }),
      (e.org.pkijs.simpl.cms.PBKDF2_params.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.PBKDF2_params({
            names: {
              salt_primitive: "salt",
              salt_constructed: { names: { block_name: "salt" } },
              iterationCount: "iterationCount",
              keyLength: "keyLength",
              prf: { names: { block_name: "prf", optional: !0 } },
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PBKDF2_params"
          );
        (this.salt = r.result.salt),
          (this.iterationCount = r.result.iterationCount.value_block.value_dec),
          "keyLength" in r.result &&
            (this.keyLength = r.result.keyLength.value_block.value_dec),
          "prf" in r.result &&
            (this.prf = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.prf,
            }));
      }),
      (e.org.pkijs.simpl.cms.PBKDF2_params.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(this.salt),
          t.push(new e.org.pkijs.asn1.INTEGER({ value: this.iterationCount })),
          "keyLength" in this &&
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.keyLength })),
          "prf" in this && t.push(this.prf.toSchema()),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.cms.PBKDF2_params.prototype.toJSON = function () {
        var e = {
          salt: this.salt.toJSON(),
          iterationCount: this.iterationCount,
        };
        return (
          "keyLength" in this && (e.keyLength = this.keyLength),
          "prf" in this && (e.prf = this.prf.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.cms.SignedUnsignedAttributes = function () {
        (this.type = 0),
          (this.attributes = new Array()),
          (this.encoded_value = new ArrayBuffer(0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.SignedUnsignedAttributes.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.type = arguments[0].type || 0),
              (this.attributes = arguments[0].attributes || new Array()));
      }),
      (e.org.pkijs.simpl.cms.SignedUnsignedAttributes.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.SignedUnsignedAttributes(
              { names: { attributes: "attributes" } },
              this.type
            )
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for SignedUnsignedAttributes"
            );
          (this.type = r.result.id_block.tag_number),
            (this.encoded_value = r.result.value_before_decode);
          var i = new Uint8Array(this.encoded_value);
          if (((i[0] = 49), "attributes" in r.result != !1))
            for (var n = r.result.attributes, s = 0; s < n.length; s++)
              this.attributes.push(
                new e.org.pkijs.simpl.cms.Attribute({ schema: n[s] })
              );
          else if (0 === this.type)
            throw new Error("Wrong structure of SignedUnsignedAttributes");
        }),
      (e.org.pkijs.simpl.cms.SignedUnsignedAttributes.prototype.toSchema =
        function () {
          for (var t = new Array(), r = 0; r < this.attributes.length; r++)
            t.push(this.attributes[r].toSchema());
          return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
            optional: !0,
            id_block: { tag_class: 3, tag_number: this.type },
            value: t,
          });
        }),
      (e.org.pkijs.simpl.cms.SignedUnsignedAttributes.prototype.toJSON =
        function () {
          var e = { type: this.type };
          e.attributes = new Array();
          for (var t = 0; t < this.attributes.length; t++)
            e.attributes.push(this.attributes[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.CMS_SIGNER_INFO = function () {
        (this.version = 0),
          (this.sid = new e.org.pkijs.asn1.ANY()),
          (this.digestAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signature = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_SIGNER_INFO.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.sid = arguments[0].sid || new e.org.pkijs.asn1.ANY()),
              (this.digestAlgorithm =
                arguments[0].digestAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              "signedAttrs" in arguments[0] &&
                (this.signedAttrs = arguments[0].signedAttrs),
              (this.signatureAlgorithm =
                arguments[0].signatureAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signature =
                arguments[0].signature || new e.org.pkijs.asn1.OCTETSTRING()),
              "unsignedAttrs" in arguments[0] &&
                (this.unsignedAttrs = arguments[0].unsignedAttrs));
      }),
      (e.org.pkijs.simpl.CMS_SIGNER_INFO.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_SIGNER_INFO()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_SIGNER_INFO"
          );
        this.version = r.result["SignerInfo.version"].value_block.value_dec;
        var i = r.result["SignerInfo.sid"];
        1 === i.id_block.tag_class
          ? (this.sid = new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
              schema: i,
            }))
          : (this.sid = i),
          (this.digestAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result["SignerInfo.digestAlgorithm"],
          })),
          "SignerInfo.signedAttrs" in r.result &&
            (this.signedAttrs =
              new e.org.pkijs.simpl.cms.SignedUnsignedAttributes({
                schema: r.result["SignerInfo.signedAttrs"],
              })),
          (this.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER(
            { schema: r.result["SignerInfo.signatureAlgorithm"] }
          )),
          (this.signature = r.result["SignerInfo.signature"]),
          "SignerInfo.unsignedAttrs" in r.result &&
            ((this.unsignedAttrs =
              new e.org.pkijs.simpl.cms.SignedUnsignedAttributes()),
            (this.unsignedAttrs.type = 1),
            this.unsignedAttrs.fromSchema(
              r.result["SignerInfo.unsignedAttrs"]
            ));
      }),
      (e.org.pkijs.simpl.CMS_SIGNER_INFO.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          this.sid instanceof e.org.pkijs.simpl.cms.IssuerAndSerialNumber
            ? t.push(this.sid.toSchema())
            : t.push(this.sid),
          t.push(this.digestAlgorithm.toSchema()),
          "signedAttrs" in this && t.push(this.signedAttrs.toSchema()),
          t.push(this.signatureAlgorithm.toSchema()),
          t.push(this.signature),
          "unsignedAttrs" in this && t.push(this.unsignedAttrs.toSchema()),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.CMS_SIGNER_INFO.prototype.toJSON = function () {
        var t = { version: this.version };
        return (
          this.sid instanceof e.org.pkijs.asn1.ANY ||
            (t.sid = this.sid.toJSON()),
          (t.digestAlgorithm = this.digestAlgorithm.toJSON()),
          "signedAttrs" in this && (t.signedAttrs = this.signedAttrs.toJSON()),
          (t.signatureAlgorithm = this.signatureAlgorithm.toJSON()),
          (t.signature = this.signature.toJSON()),
          "unsignedAttrs" in this &&
            (t.unsignedAttrs = this.unsignedAttrs.toJSON()),
          t
        );
      }),
      (e.org.pkijs.simpl.cms.EncapsulatedContentInfo = function () {
        if (
          ((this.eContentType = ""),
          arguments[0] instanceof Object && "schema" in arguments[0])
        )
          e.org.pkijs.simpl.cms.EncapsulatedContentInfo.prototype.fromSchema.call(
            this,
            arguments[0].schema
          );
        else if (
          arguments[0] instanceof Object &&
          ((this.eContentType = arguments[0].eContentType || ""),
          "eContent" in arguments[0] &&
            ((this.eContent = arguments[0].eContent),
            1 === this.eContent.id_block.tag_class &&
              4 === this.eContent.id_block.tag_number &&
              this.eContent.id_block.is_constructed === !1))
        ) {
          for (
            var t = new e.org.pkijs.asn1.OCTETSTRING({
                id_block: { is_constructed: !0 },
                is_constructed: !0,
              }),
              r = 0,
              i = this.eContent.value_block.value_hex.byteLength;
            i > 0;

          ) {
            for (
              var n = new Uint8Array(
                  this.eContent.value_block.value_hex,
                  r,
                  r + 65536 > this.eContent.value_block.value_hex.byteLength
                    ? this.eContent.value_block.value_hex.byteLength - r
                    : 65536
                ),
                s = new ArrayBuffer(n.length),
                a = new Uint8Array(s),
                o = 0;
              o < a.length;
              o++
            )
              a[o] = n[o];
            t.value_block.value.push(
              new e.org.pkijs.asn1.OCTETSTRING({ value_hex: s })
            ),
              (i -= n.length),
              (r += n.length);
          }
          this.eContent = t;
        }
      }),
      (e.org.pkijs.simpl.cms.EncapsulatedContentInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.EncapsulatedContentInfo({
              names: { eContentType: "eContentType", eContent: "eContent" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for EncapsulatedContentInfo"
            );
          (this.eContentType = r.result.eContentType.value_block.toString()),
            "eContent" in r.result && (this.eContent = r.result.eContent);
        }),
      (e.org.pkijs.simpl.cms.EncapsulatedContentInfo.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(new e.org.pkijs.asn1.OID({ value: this.eContentType })),
            "eContent" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [this.eContent],
                })
              ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.EncapsulatedContentInfo.prototype.toJSON =
        function () {
          var e = { eContentType: this.eContentType };
          return "eContent" in this && (e.eContent = this.eContent.toJSON()), e;
        }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA = function () {
        (this.version = 0),
          (this.digestAlgorithms = new Array()),
          (this.encapContentInfo =
            new e.org.pkijs.simpl.cms.EncapsulatedContentInfo()),
          (this.signerInfos = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.digestAlgorithms =
                arguments[0].digestAlgorithms || new Array()),
              (this.encapContentInfo =
                arguments[0].encapContentInfo ||
                new e.org.pkijs.simpl.cms.EncapsulatedContentInfo()),
              "certificates" in arguments[0] &&
                (this.certificates = arguments[0].certificates),
              "crls" in arguments[0] && (this.crls = arguments[0].crls),
              (this.signerInfos = arguments[0].signerInfos || new Array()));
      }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_SIGNED_DATA()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_SIGNED_DATA"
          );
        this.version = r.result["SignedData.version"].value_block.value_dec;
        for (
          var i = r.result["SignedData.digestAlgorithms"], n = 0;
          n < i.length;
          n++
        )
          this.digestAlgorithms.push(
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({ schema: i[n] })
          );
        if (
          ((this.encapContentInfo =
            new e.org.pkijs.simpl.cms.EncapsulatedContentInfo({
              schema: r.result["SignedData.encapContentInfo"],
            })),
          "SignedData.certificates" in r.result)
        ) {
          this.certificates = new Array();
          for (
            var s = r.result["SignedData.certificates"], a = 0;
            a < s.length;
            a++
          )
            1 === s[a].id_block.tag_class
              ? this.certificates.push(
                  new e.org.pkijs.simpl.CERT({ schema: s[a] })
                )
              : ((s[a].id_block.tag_class = 1),
                (s[a].id_block.tag_number = 16),
                this.certificates.push(
                  new e.org.pkijs.simpl.cms.OtherCertificateFormat({
                    schema: s[a],
                  })
                ));
        }
        if ("SignedData.crls" in r.result) {
          this.crls = new Array();
          for (var o = r.result["SignedData.crls"], c = 0; c < o.length; c++)
            1 === o[c].id_block.tag_class
              ? this.crls.push(new e.org.pkijs.simpl.CRL({ schema: o[c] }))
              : ((o[c].id_block.tag_class = 1),
                (o[c].id_block.tag_number = 16),
                this.crls.push(
                  new e.org.pkijs.simpl.cms.OtherRevocationInfoFormat({
                    schema: o[c],
                  })
                ));
        }
        for (
          var l = r.result["SignedData.signerInfos"], u = 0;
          u < l.length;
          u++
        )
          this.signerInfos.push(
            new e.org.pkijs.simpl.CMS_SIGNER_INFO({ schema: l[u] })
          );
      }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r = new Array();
        r.push(new e.org.pkijs.asn1.INTEGER({ value: this.version }));
        for (var i = new Array(), n = 0; n < this.digestAlgorithms.length; n++)
          i.push(this.digestAlgorithms[n].toSchema(t));
        if (
          (r.push(new e.org.pkijs.asn1.SET({ value: i })),
          r.push(this.encapContentInfo.toSchema()),
          "certificates" in this)
        ) {
          for (var s = new Array(), a = 0; a < this.certificates.length; a++)
            if (
              this.certificates[a] instanceof
              e.org.pkijs.simpl.cms.OtherCertificateFormat
            ) {
              var o = this.certificates[a].toSchema(t);
              (o.id_block.tag_class = 3),
                (o.id_block.tag_number = 3),
                s.push(o);
            } else s.push(this.certificates[a].toSchema(t));
          r.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: s,
            })
          );
        }
        if ("crls" in this) {
          for (var c = new Array(), l = 0; l < this.crls.length; l++)
            if (
              this.crls[l] instanceof
              e.org.pkijs.simpl.cms.OtherRevocationInfoFormat
            ) {
              var u = this.crls[l].toSchema(t);
              (u.id_block.tag_class = 3),
                (u.id_block.tag_number = 1),
                c.push(u);
            } else c.push(this.crls[l].toSchema(t));
          r.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: c,
            })
          );
        }
        for (var p = new Array(), h = 0; h < this.signerInfos.length; h++)
          p.push(this.signerInfos[h].toSchema(t));
        return (
          r.push(new e.org.pkijs.asn1.SET({ value: p })),
          new e.org.pkijs.asn1.SEQUENCE({ value: r })
        );
      }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.verify = function () {
        function t(e) {
          if (
            e.issuer.isEqual(l.issuer) === !0 &&
            e.serialNumber.isEqual(l.serialNumber) === !0
          )
            return null;
          for (var t = !1, r = 0; r < e.extensions.length; r++)
            "2.5.29.19" === e.extensions[r].extnID &&
              "cA" in e.extensions[r].parsedValue &&
              e.extensions[r].parsedValue.cA === !0 &&
              (t = !0);
          return t ? e : null;
        }
        var r = Promise.resolve(),
          i = this.certificates,
          n = this.signerInfos,
          s = this.encapContentInfo,
          a = new ArrayBuffer(0),
          o = "",
          c = -1,
          l = {},
          u = new Array(),
          p = this,
          h = e.org.pkijs.getCrypto();
        if ("undefined" == typeof h)
          return new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          });
        if (
          (arguments[0] instanceof Object &&
            ("signer" in arguments[0] && (c = arguments[0].signer),
            "data" in arguments[0] && (a = arguments[0].data),
            "trusted_certs" in arguments[0] &&
              (u = arguments[0].trusted_certs)),
          c === -1)
        )
          return new Promise(function (e, t) {
            t("Unable to get signer index from input parameters");
          });
        if ("certificates" in this == !1)
          return new Promise(function (e, t) {
            t("No certificates attached to this signed data");
          });
        r =
          this.signerInfos[c].sid instanceof
          e.org.pkijs.simpl.cms.IssuerAndSerialNumber
            ? r.then(function () {
                for (var e = 0; i.length; e++)
                  if (
                    i[e].issuer.isEqual(n[c].sid.issuer) &&
                    i[e].serialNumber.isEqual(n[c].sid.serialNumber)
                  )
                    return (
                      (l = i[e]),
                      new Promise(function (e, t) {
                        e();
                      })
                    );
                return new Promise(function (e, t) {
                  t("Unable to find signer certificate");
                });
              })
            : r.then(function () {
                for (var t = new Array(), r = 0; r < i.length; r++)
                  t.push(
                    h.digest(
                      { name: "sha-1" },
                      new Uint8Array(
                        i[
                          r
                        ].subjectPublicKeyInfo.subjectPublicKey.value_block.value_hex
                      )
                    )
                  );
                return Promise.all(t).then(
                  function (t) {
                    for (var r = 0; r < i.length; r++)
                      if (
                        e.org.pkijs.isEqual_buffer(
                          t[r],
                          n[c].sid.value_block.value_hex
                        )
                      )
                        return (
                          (l = i[r]),
                          new Promise(function (e, t) {
                            e();
                          })
                        );
                    return new Promise(function (e, t) {
                      t("Unable to find signer certificate");
                    });
                  },
                  function (e) {
                    return new Promise(function (e, t) {
                      t("Unable to find signer certificate");
                    });
                  }
                );
              });
        for (var f = new Array(), g = 0; g < this.certificates.length; g++)
          f.push(t(this.certificates[g]));
        return (
          (r = r.then(function (t) {
            return Promise.all(f).then(
              function (t) {
                var r = new Array();
                r.push(l);
                for (var i = 0; i < t.length; i++)
                  null !== t[i] && r.push(t[i]);
                var n = new e.org.pkijs.simpl.CERT_CHAIN({
                  certs: r,
                  trusted_certs: u,
                });
                return (
                  "crls" in p && (n.crls = p.crls),
                  n.verify().then(
                    function (e) {
                      return e.result === !0
                        ? new Promise(function (e, t) {
                            e();
                          })
                        : new Promise(function (e, t) {
                            t("Validation of signer's certificate failed");
                          });
                    },
                    function (e) {
                      return new Promise(function (t, r) {
                        r(
                          "Validation of signer's certificate failed with error: " +
                            (e instanceof Object ? e.result_message : e)
                        );
                      });
                    }
                  )
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Error during checking certificates for CA flag: " + e);
                });
              }
            );
          })),
          (r = r.then(function () {
            var t = e.org.pkijs.getAlgorithmByOID(
              n[c].digestAlgorithm.algorithm_id
            );
            return "name" in t == !1
              ? new Promise(function (e, t) {
                  t(
                    "Unsupported signature algorithm: " +
                      p.signerInfos[c].digestAlgorithm.algorithm_id
                  );
                })
              : ((o = t.name),
                new Promise(function (e, t) {
                  e();
                }));
          })),
          (r = r.then(function () {
            if ("signedAttrs" in n[c]) a = n[c].signedAttrs.encoded_value;
            else if ("eContent" in s)
              if (
                1 === s.eContent.id_block.tag_class &&
                4 === s.eContent.id_block.tag_number
              )
                if (s.eContent.id_block.is_constructed === !1)
                  a = s.eContent.value_block.value_hex;
                else
                  for (var t = 0; t < s.eContent.value_block.value.length; t++)
                    a = e.org.pkijs.concat_buffers(
                      a,
                      s.eContent.value_block.value[t].value_block.value_hex
                    );
              else a = s.eContent.value_block.value_hex;
            else if (0 === a.byteLength)
              return new Promise(function (e, t) {
                t("Missed detached data input array");
              });
          })),
          (r = r.then(function () {
            var t = e.org.pkijs.getAlgorithmByOID(
              l.signatureAlgorithm.algorithm_id
            );
            if ("name" in t == !1)
              return new Promise(function (e, t) {
                t(
                  "Unsupported public key algorithm: " +
                    l.signatureAlgorithm.algorithm_id
                );
              });
            var r = t.name,
              i = e.org.pkijs.getAlgorithmParameters(r, "importkey");
            "hash" in i.algorithm && (i.algorithm.hash.name = o);
            var n = l.subjectPublicKeyInfo.toSchema(),
              s = n.toBER(!1),
              a = new Uint8Array(s);
            return h.importKey("spki", a, i.algorithm, !0, i.usages);
          })),
          (r = r.then(
            function (t) {
              var r = e.org.pkijs.getAlgorithmParameters(
                t.algorithm.name,
                "verify"
              );
              "hash" in r.algorithm && (r.algorithm.hash.name = o);
              var i = n[c].signature.value_block.value_hex;
              if ("ECDSA" === t.algorithm.name) {
                var s = e.org.pkijs.fromBER(i);
                i = e.org.pkijs.createECDSASignatureFromCMS(s.result);
              }
              if ("RSA-PSS" === t.algorithm.name) {
                var l;
                try {
                  l = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                    schema: n[c].signatureAlgorithm.algorithm_params,
                  });
                } catch (u) {
                  return new Promise(function (e, t) {
                    t(u);
                  });
                }
                "saltLength" in l
                  ? (r.algorithm.saltLength = l.saltLength)
                  : (r.algorithm.saltLength = 20);
                var p = "SHA-1";
                if ("hashAlgorithm" in l) {
                  var f = e.org.pkijs.getAlgorithmByOID(
                    l.hashAlgorithm.algorithm_id
                  );
                  if ("name" in f == !1)
                    return new Promise(function (e, t) {
                      t(
                        "Unrecognized hash algorithm: " +
                          l.hashAlgorithm.algorithm_id
                      );
                    });
                  p = f.name;
                }
                r.algorithm.hash.name = p;
              }
              return h.verify(
                r.algorithm,
                t,
                new Uint8Array(i),
                new Uint8Array(a)
              );
            },
            function (e) {
              return new Promise(function (t, r) {
                r(e);
              });
            }
          ))
        );
      }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.sign = function (
        t,
        r,
        i,
        n
      ) {
        var s = this;
        n = n || new ArrayBuffer(0);
        var a = "";
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if (
          ("undefined" == typeof i && (i = "SHA-1"),
          (a = e.org.pkijs.getOIDByAlgorithm({ name: i })),
          "" === a)
        )
          return new Promise(function (e, t) {
            t("Unsupported hash algorithm: " + i);
          });
        for (var o = !1, c = 0; c < s.digestAlgorithms.length; c++)
          if (s.digestAlgorithms[c].algorithm_id === a) {
            o = !0;
            break;
          }
        o === !1 &&
          s.digestAlgorithms.push(
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: a,
              algorithm_params: new e.org.pkijs.asn1.NULL(),
            })
          ),
          (s.signerInfos[r].digestAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: a,
              algorithm_params: new e.org.pkijs.asn1.NULL(),
            }));
        var l = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((l.algorithm.hash.name = i), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            s.signerInfos[r].signatureAlgorithm.algorithm_id =
              e.org.pkijs.getOIDByAlgorithm(l.algorithm);
            break;
          case "RSA-PSS":
            switch (i.toUpperCase()) {
              case "SHA-256":
                l.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                l.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                l.algorithm.saltLength = 64;
            }
            var u = {};
            if ("SHA-1" !== i.toUpperCase()) {
              if (((a = e.org.pkijs.getOIDByAlgorithm({ name: i })), "" === a))
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + i);
                });
              (u.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: a,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (u.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: u.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== l.algorithm.saltLength &&
              (u.saltLength = l.algorithm.saltLength);
            var p = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(u);
            s.signerInfos[r].signatureAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: "1.2.840.113549.1.1.10",
                algorithm_params: p.toSchema(),
              });
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        if ("signedAttrs" in s.signerInfos[r])
          if (0 !== s.signerInfos[r].signedAttrs.encoded_value.byteLength)
            n = s.signerInfos[r].signedAttrs.encoded_value;
          else {
            n = s.signerInfos[r].signedAttrs.toSchema(!0).toBER(!1);
            var h = new Uint8Array(n);
            h[0] = 49;
          }
        else if ("eContent" in s.encapContentInfo)
          if (
            1 === s.encapContentInfo.eContent.id_block.tag_class &&
            4 === s.encapContentInfo.eContent.id_block.tag_number
          )
            if (s.encapContentInfo.eContent.id_block.is_constructed === !1)
              n = s.encapContentInfo.eContent.value_block.value_hex;
            else
              for (
                var c = 0;
                c < s.encapContentInfo.eContent.value_block.value.length;
                c++
              )
                n = e.org.pkijs.concat_buffers(
                  n,
                  s.encapContentInfo.eContent.value_block.value[c].value_block
                    .value_hex
                );
          else n = s.encapContentInfo.eContent.value_block.value_hex;
        else if (0 === n.byteLength)
          return new Promise(function (e, t) {
            t("Missed detached data input array");
          });
        var f = e.org.pkijs.getCrypto();
        return "undefined" == typeof f
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : f.sign(l.algorithm, t, new Uint8Array(n)).then(
              function (t) {
                return (
                  "ECDSA" === l.algorithm.name &&
                    (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (s.signerInfos[r].signature =
                    new e.org.pkijs.asn1.OCTETSTRING({ value_hex: t })),
                  new Promise(function (e, r) {
                    e(t);
                  })
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.CMS_SIGNED_DATA.prototype.toJSON = function () {
        var e = { version: this.version };
        e.digestAlgorithms = new Array();
        for (var t = 0; t < this.digestAlgorithms.length; t++)
          e.digestAlgorithms.push(this.digestAlgorithms[t].toJSON());
        if (
          ((e.encapContentInfo = this.encapContentInfo.toJSON()),
          "certificates" in this)
        ) {
          e.certificates = new Array();
          for (var t = 0; t < this.certificates.length; t++)
            e.certificates.push(this.certificates[t].toJSON());
        }
        if ("crls" in this) {
          e.crls = new Array();
          for (var t = 0; t < this.crls.length; t++)
            e.crls.push(this.crls[t].toJSON());
        }
        e.signerInfos = new Array();
        for (var t = 0; t < this.signerInfos.length; t++)
          e.signerInfos.push(this.signerInfos[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo = function () {
        (this.keyInfo = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.suppPubInfo = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.keyInfo =
                arguments[0].keyInfo ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              "entityUInfo" in arguments[0] &&
                (this.entityUInfo = arguments[0].entityUInfo),
              (this.suppPubInfo =
                arguments[0].suppPubInfo ||
                new e.org.pkijs.asn1.OCTETSTRING()));
      }),
      (e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.ECC_CMS_SharedInfo({
              names: {
                keyInfo: { names: { block_name: "keyInfo" } },
                entityUInfo: "entityUInfo",
                suppPubInfo: "suppPubInfo",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for ECC_CMS_SharedInfo"
            );
          (this.keyInfo = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result.keyInfo,
          })),
            "entityUInfo" in r.result &&
              (this.entityUInfo = r.result.entityUInfo.value_block.value[0]),
            (this.suppPubInfo = r.result.suppPubInfo.value_block.value[0]);
        }),
      (e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(this.keyInfo.toSchema()),
            "entityUInfo" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [this.entityUInfo],
                })
              ),
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 2 },
                value: [this.suppPubInfo],
              })
            ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo.prototype.toJSON = function () {
        var e = { keyInfo: this.keyInfo.toJSON() };
        return (
          "entityUInfo" in this && (e.entityUInfo = this.entityUInfo.toJSON()),
          (e.suppPubInfo = this.suppPubInfo.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.cms.RecipientIdentifier = function () {
        (this.variant = -1),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.RecipientIdentifier.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.variant = arguments[0].variant || -1),
              "value" in arguments[0] && (this.value = arguments[0].value));
      }),
      (e.org.pkijs.simpl.cms.RecipientIdentifier.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.RecipientIdentifier({
              names: { block_name: "block_name" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RecipientIdentifier"
            );
          1 === r.result.block_name.id_block.tag_class
            ? ((this.variant = 1),
              (this.value = new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                schema: r.result.block_name,
              })))
            : ((this.variant = 2),
              (this.value = r.result.block_name.value_block.value[0]));
        }),
      (e.org.pkijs.simpl.cms.RecipientIdentifier.prototype.toSchema =
        function () {
          switch (this.variant) {
            case 1:
              return this.value.toSchema();
            case 2:
              return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: [this.value],
              });
            default:
              return new e.org.pkijs.asn1.ANY();
          }
          return new e.org.pkijs.asn1.ANY();
        }),
      (e.org.pkijs.simpl.cms.RecipientIdentifier.prototype.toJSON =
        function () {
          var e = { variant: this.variant };
          return (
            (1 != this.variant && 2 !== this.variant) ||
              (e.value = this.value.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.cms.KeyTransRecipientInfo = function () {
        (this.version = -1),
          (this.rid = new e.org.pkijs.emptyObject()),
          (this.keyEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.encryptedKey = new e.org.pkijs.asn1.OCTETSTRING()),
          (this.recipientCertificate = new e.org.pkijs.simpl.CERT()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.KeyTransRecipientInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || -1),
              (this.rid = arguments[0].rid || new e.org.pkijs.emptyObject()),
              (this.keyEncryptionAlgorithm =
                arguments[0].keyEncryptionAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.encryptedKey =
                arguments[0].encryptedKey ||
                new e.org.pkijs.asn1.OCTETSTRING()),
              (this.recipientCertificate =
                arguments[0].recipientCertificate ||
                new e.org.pkijs.simpl.CERT()));
      }),
      (e.org.pkijs.simpl.cms.KeyTransRecipientInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.KeyTransRecipientInfo({
              names: {
                version: "version",
                rid: { names: { block_name: "rid" } },
                keyEncryptionAlgorithm: {
                  names: { block_name: "keyEncryptionAlgorithm" },
                },
                encryptedKey: "encryptedKey",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for KeyTransRecipientInfo"
            );
          (this.version = r.result.version.value_block.value_dec),
            3 === r.result.rid.id_block.tag_class
              ? (this.rid = r.result.rid.value_block.value[0])
              : (this.rid = new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                  schema: r.result.rid,
                })),
            (this.keyEncryptionAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                schema: r.result.keyEncryptionAlgorithm,
              })),
            (this.encryptedKey = r.result.encryptedKey);
        }),
      (e.org.pkijs.simpl.cms.KeyTransRecipientInfo.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
            this.rid instanceof e.org.pkijs.simpl.cms.IssuerAndSerialNumber
              ? t.push(this.rid.toSchema())
              : t.push(
                  new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                    id_block: { tag_class: 3, tag_number: 0 },
                    value: [this.rid],
                  })
                ),
            t.push(this.keyEncryptionAlgorithm.toSchema()),
            t.push(this.encryptedKey),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.KeyTransRecipientInfo.prototype.toJSON =
        function () {
          return {
            version: this.version,
            rid: this.rid.toJSON(),
            keyEncryptionAlgorithm: this.keyEncryptionAlgorithm.toJSON(),
            encryptedKey: this.encryptedKey.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.OriginatorPublicKey = function () {
        (this.algorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.publicKey = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OriginatorPublicKey.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.algorithm =
                arguments[0].algorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.publicKey =
                arguments[0].publicKey || new e.org.pkijs.asn1.BITSTRING()));
      }),
      (e.org.pkijs.simpl.cms.OriginatorPublicKey.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.OriginatorPublicKey({
              names: {
                algorithm: { names: { block_name: "algorithm" } },
                publicKey: "publicKey",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for OriginatorPublicKey"
            );
          (this.algorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result.algorithm,
          })),
            (this.publicKey = r.result.publicKey);
        }),
      (e.org.pkijs.simpl.cms.OriginatorPublicKey.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [this.algorithm.toSchema(), this.publicKey],
          });
        }),
      (e.org.pkijs.simpl.cms.OriginatorPublicKey.prototype.toJSON =
        function () {
          return {
            algorithm: this.algorithm.toJSON(),
            publicKey: this.publicKey.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey = function () {
        (this.variant = -1),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.variant = arguments[0].variant || -1),
              "value" in arguments[0] && (this.value = arguments[0].value));
      }),
      (e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.OriginatorIdentifierOrKey({
              names: { block_name: "block_name" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for OriginatorIdentifierOrKey"
            );
          1 === r.result.block_name.id_block.tag_class
            ? ((this.variant = 1),
              (this.value = new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                schema: r.result.block_name,
              })))
            : 0 === r.result.block_name.id_block.tag_number
            ? ((r.result.block_name.id_block.tag_class = 1),
              (r.result.block_name.id_block.tag_number = 4),
              (this.variant = 2),
              (this.value = r.result.block_name))
            : ((r.result.block_name.id_block.tag_class = 1),
              (r.result.block_name.id_block.tag_number = 16),
              (this.variant = 3),
              (this.value = new e.org.pkijs.simpl.cms.OriginatorPublicKey({
                schema: r.result.block_name,
              })));
        }),
      (e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey.prototype.toSchema =
        function () {
          switch (this.variant) {
            case 1:
              return this.value.toSchema();
            case 2:
              return (
                (this.value.id_block.tag_class = 3),
                (this.value.id_block.tag_number = 0),
                this.value
              );
            case 3:
              var t = this.value.toSchema();
              return (t.id_block.tag_class = 3), (t.id_block.tag_number = 1), t;
            default:
              return new e.org.pkijs.asn1.ANY();
          }
          return new e.org.pkijs.asn1.ANY();
        }),
      (e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey.prototype.toJSON =
        function () {
          var e = { variant: this.variant };
          return (
            (1 != this.variant && 2 !== this.variant && 3 !== this.variant) ||
              (e.value = this.value.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.cms.OtherKeyAttribute = function () {
        (this.keyAttrId = ""),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OtherKeyAttribute.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.keyAttrId =
                arguments[0].keyAttrId || new e.org.pkijs.asn1.OID()),
              "keyAttr" in arguments[0] &&
                (this.keyAttr = arguments[0].keyAttr));
      }),
      (e.org.pkijs.simpl.cms.OtherKeyAttribute.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.OtherKeyAttribute({
            names: { keyAttrId: "keyAttrId", keyAttr: "keyAttr" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OtherKeyAttribute"
          );
        (this.keyAttrId = r.result.keyAttrId.value_block.toString()),
          "keyAttr" in r.result && (this.keyAttr = r.result.keyAttr);
      }),
      (e.org.pkijs.simpl.cms.OtherKeyAttribute.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(new e.org.pkijs.asn1.OID({ value: this.keyAttrId })),
            "keyAttr" in this && t.push(this.keyAttr.toSchema()),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.OtherKeyAttribute.prototype.toJSON = function () {
        var e = { keyAttrId: this.keyAttrId };
        return "keyAttr" in this && (e.keyAttr = this.keyAttr.toJSON()), e;
      }),
      (e.org.pkijs.simpl.cms.RecipientKeyIdentifier = function () {
        (this.subjectKeyIdentifier = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.RecipientKeyIdentifier.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.subjectKeyIdentifier =
                arguments[0].subjectKeyIdentifier ||
                new e.org.pkijs.asn1.OCTETSTRING()),
              "date" in arguments[0] && (this.date = arguments[0].date),
              "other" in arguments[0] && (this.other = arguments[0].other));
      }),
      (e.org.pkijs.simpl.cms.RecipientKeyIdentifier.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.RecipientKeyIdentifier({
              names: {
                subjectKeyIdentifier: "subjectKeyIdentifier",
                date: "date",
                other: { names: { block_name: "other" } },
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RecipientKeyIdentifier"
            );
          (this.subjectKeyIdentifier = r.result.subjectKeyIdentifier),
            "date" in r.result && (this.date = r.result.date),
            "other" in r.result &&
              (this.other = new e.org.pkijs.simpl.cms.OtherKeyAttribute({
                schema: r.result.other,
              }));
        }),
      (e.org.pkijs.simpl.cms.RecipientKeyIdentifier.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(this.subjectKeyIdentifier),
            "date" in this && t.push(this.date),
            "other" in this && t.push(this.other.toSchema()),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.RecipientKeyIdentifier.prototype.toJSON =
        function () {
          var e = { subjectKeyIdentifier: this.subjectKeyIdentifier.toJSON() };
          return (
            "date" in this && (e.date = this.date),
            "other" in this && (e.other = this.other.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier = function () {
        (this.variant = -1),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.variant = arguments[0].variant || -1),
              "value" in arguments[0] && (this.value = arguments[0].value));
      }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.KeyAgreeRecipientIdentifier({
              names: { block_name: "block_name" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for KeyAgreeRecipientIdentifier"
            );
          1 === r.result.block_name.id_block.tag_class
            ? ((this.variant = 1),
              (this.value = new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                schema: r.result.block_name,
              })))
            : ((this.variant = 2),
              (r.result.block_name.id_block.tag_class = 1),
              (r.result.block_name.id_block.tag_number = 16),
              (this.value = new e.org.pkijs.simpl.cms.RecipientKeyIdentifier({
                schema: r.result.block_name,
              })));
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier.prototype.toSchema =
        function () {
          switch (this.variant) {
            case 1:
              return this.value.toSchema();
            case 2:
              return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: this.value.toSchema().value_block.value,
              });
            default:
              return new e.org.pkijs.asn1.ANY();
          }
          return new e.org.pkijs.asn1.ANY();
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier.prototype.toJSON =
        function () {
          var e = { variant: this.variant };
          return (
            (1 != this.variant && 2 !== this.variant) ||
              (e.value = this.value.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKey = function () {
        (this.rid = new e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier()),
          (this.encryptedKey = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.RecipientEncryptedKey.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.rid =
                arguments[0].rid ||
                new e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier()),
              (this.encryptedKey =
                arguments[0].encryptedKey ||
                new e.org.pkijs.asn1.OCTETSTRING()));
      }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKey.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.RecipientEncryptedKey({
              names: {
                rid: { names: { block_name: "rid" } },
                encryptedKey: "encryptedKey",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RecipientEncryptedKey"
            );
          (this.rid = new e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier({
            schema: r.result.rid,
          })),
            (this.encryptedKey = r.result.encryptedKey);
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKey.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [this.rid.toSchema(), this.encryptedKey],
          });
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKey.prototype.toJSON =
        function () {
          return {
            rid: this.rid.toJSON(),
            encryptedKey: this.encryptedKey.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKeys = function () {
        (this.encryptedKeys = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.RecipientEncryptedKeys.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.encryptedKeys = arguments[0].encryptedKeys || new Array());
      }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKeys.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.RecipientEncryptedKeys({
              names: { RecipientEncryptedKeys: "RecipientEncryptedKeys" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RecipientEncryptedKeys"
            );
          for (
            var i = r.result.RecipientEncryptedKeys, n = 0;
            n < i.length;
            n++
          )
            this.encryptedKeys.push(
              new e.org.pkijs.simpl.cms.RecipientEncryptedKey({ schema: i[n] })
            );
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKeys.prototype.toSchema =
        function () {
          for (var t = new Array(), r = 0; r < this.encryptedKeys.length; r++)
            t.push(this.encryptedKeys[r].toSchema());
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.cms.RecipientEncryptedKeys.prototype.toJSON =
        function () {
          var e = {};
          e.encryptedKeys = new Array();
          for (var t = 0; t < this.encryptedKeys.length; t++)
            e.encryptedKeys.push(this.encryptedKeys[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo = function () {
        (this.version = 0),
          (this.originator =
            new e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey()),
          (this.keyEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.recipientEncryptedKeys =
            new e.org.pkijs.simpl.cms.RecipientEncryptedKeys()),
          (this.recipientCertificate = new e.org.pkijs.simpl.CERT()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.originator =
                arguments[0].originator ||
                new e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey()),
              "ukm" in arguments[0] && (this.ukm = arguments[0].ukm),
              (this.keyEncryptionAlgorithm =
                arguments[0].keyEncryptionAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.recipientEncryptedKeys =
                arguments[0].recipientEncryptedKeys ||
                new e.org.pkijs.simpl.cms.RecipientEncryptedKeys()),
              (this.recipientCertificate =
                arguments[0].recipientCertificate ||
                new e.org.pkijs.simpl.CERT()));
      }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.KeyAgreeRecipientInfo({
              names: {
                version: "version",
                originator: { names: { block_name: "originator" } },
                ukm: "ukm",
                keyEncryptionAlgorithm: {
                  names: { block_name: "keyEncryptionAlgorithm" },
                },
                recipientEncryptedKeys: {
                  names: { block_name: "recipientEncryptedKeys" },
                },
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for KeyAgreeRecipientInfo"
            );
          (this.version = r.result.version.value_block.value_dec),
            (this.originator =
              new e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey({
                schema: r.result.originator,
              })),
            "ukm" in r.result && (this.ukm = r.result.ukm),
            (this.keyEncryptionAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                schema: r.result.keyEncryptionAlgorithm,
              })),
            (this.recipientEncryptedKeys =
              new e.org.pkijs.simpl.cms.RecipientEncryptedKeys({
                schema: r.result.recipientEncryptedKeys,
              }));
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: [this.originator.toSchema()],
              })
            ),
            "ukm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [this.ukm],
                })
              ),
            t.push(this.keyEncryptionAlgorithm.toSchema()),
            t.push(this.recipientEncryptedKeys.toSchema()),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo.prototype.toJSON =
        function () {
          return {
            version: this.version,
            originator: this.originator.toJSON(),
            ukm: this.ukm.toJSON(),
            keyEncryptionAlgorithm: this.keyEncryptionAlgorithm.toJSON(),
            recipientEncryptedKeys: this.recipientEncryptedKeys.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.KEKIdentifier = function () {
        (this.keyIdentifier = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.KEKIdentifier.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.keyIdentifier =
                arguments[0].keyIdentifier ||
                new e.org.pkijs.asn1.OCTETSTRING()),
              "date" in arguments[0] && (this.date = arguments[0].date),
              "other" in arguments[0] && (this.other = arguments[0].other));
      }),
      (e.org.pkijs.simpl.cms.KEKIdentifier.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.KEKIdentifier({
            names: {
              keyIdentifier: "keyIdentifier",
              date: "date",
              other: { names: { block_name: "other" } },
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for KEKIdentifier"
          );
        (this.keyIdentifier = r.result.keyIdentifier),
          "date" in r.result && (this.date = r.result.date),
          "other" in r.result &&
            (this.other = new e.org.pkijs.simpl.cms.OtherKeyAttribute({
              schema: r.result.other,
            }));
      }),
      (e.org.pkijs.simpl.cms.KEKIdentifier.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(this.keyIdentifier),
          "date" in this && t.push(this.date),
          "other" in this && t.push(this.other.toSchema()),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.cms.KEKIdentifier.prototype.toJSON = function () {
        var e = { keyIdentifier: this.keyIdentifier.toJSON() };
        return (
          "date" in this && (e.date = this.date),
          "other" in this && (e.other = this.other.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.cms.KEKRecipientInfo = function () {
        (this.version = 0),
          (this.kekid = new e.org.pkijs.simpl.cms.KEKIdentifier()),
          (this.keyEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.encryptedKey = new e.org.pkijs.asn1.OCTETSTRING()),
          (this.preDefinedKEK = new ArrayBuffer(0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.KEKRecipientInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.kekid =
                arguments[0].kekid ||
                new e.org.pkijs.simpl.cms.KEKIdentifier()),
              (this.keyEncryptionAlgorithm =
                arguments[0].keyEncryptionAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.encryptedKey =
                arguments[0].encryptedKey ||
                new e.org.pkijs.asn1.OCTETSTRING()),
              (this.preDefinedKEK =
                arguments[0].preDefinedKEK || new ArrayBuffer(0)));
      }),
      (e.org.pkijs.simpl.cms.KEKRecipientInfo.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.KEKRecipientInfo({
            names: {
              version: "version",
              kekid: { names: { block_name: "kekid" } },
              keyEncryptionAlgorithm: {
                names: { block_name: "keyEncryptionAlgorithm" },
              },
              encryptedKey: "encryptedKey",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for KEKRecipientInfo"
          );
        (this.version = r.result.version.value_block.value_dec),
          (this.kekid = new e.org.pkijs.simpl.cms.KEKIdentifier({
            schema: r.result.kekid,
          })),
          (this.keyEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.keyEncryptionAlgorithm,
            })),
          (this.encryptedKey = r.result.encryptedKey);
      }),
      (e.org.pkijs.simpl.cms.KEKRecipientInfo.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.INTEGER({ value: this.version }),
            this.kekid.toSchema(),
            this.keyEncryptionAlgorithm.toSchema(),
            this.encryptedKey,
          ],
        });
      }),
      (e.org.pkijs.simpl.cms.KEKRecipientInfo.prototype.toJSON = function () {
        return {
          version: this.version,
          kekid: this.originator.toJSON(),
          keyEncryptionAlgorithm: this.keyEncryptionAlgorithm.toJSON(),
          encryptedKey: this.encryptedKey.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.cms.PasswordRecipientinfo = function () {
        (this.version = -1),
          (this.keyEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.encryptedKey = new e.org.pkijs.asn1.OCTETSTRING()),
          (this.password = new ArrayBuffer(0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.PasswordRecipientinfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ("version" in arguments[0]
                ? (this.version = arguments[0].version)
                : (this.version = -1),
              "keyDerivationAlgorithm" in arguments[0] &&
                (this.keyDerivationAlgorithm =
                  arguments[0].keyDerivationAlgorithm),
              (this.keyEncryptionAlgorithm =
                arguments[0].keyEncryptionAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.encryptedKey =
                arguments[0].encryptedKey ||
                new e.org.pkijs.asn1.OCTETSTRING()),
              (this.password = arguments[0].password || new ArrayBuffer(0)));
      }),
      (e.org.pkijs.simpl.cms.PasswordRecipientinfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.PasswordRecipientinfo({
              names: {
                version: "version",
                keyDerivationAlgorithm: "keyDerivationAlgorithm",
                keyEncryptionAlgorithm: {
                  names: { block_name: "keyEncryptionAlgorithm" },
                },
                encryptedKey: "encryptedKey",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for PasswordRecipientinfo"
            );
          (this.version = r.result.version.value_block.value_dec),
            "keyDerivationAlgorithm" in r.result &&
              ((r.result.keyDerivationAlgorithm.id_block.tag_class = 1),
              (r.result.keyDerivationAlgorithm.id_block.tag_number = 16),
              (this.keyDerivationAlgorithm =
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  schema: r.result.keyDerivationAlgorithm,
                }))),
            (this.keyEncryptionAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                schema: r.result.keyEncryptionAlgorithm,
              })),
            (this.encryptedKey = r.result.encryptedKey);
        }),
      (e.org.pkijs.simpl.cms.PasswordRecipientinfo.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
            "keyDerivationAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value:
                    this.keyDerivationAlgorithm.toSchema().value_block.value,
                })
              ),
            t.push(this.keyEncryptionAlgorithm.toSchema()),
            t.push(this.encryptedKey),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.cms.PasswordRecipientinfo.prototype.toJSON =
        function () {
          return {
            version: this.version,
            keyDerivationAlgorithm: this.keyDerivationAlgorithm.toJSON(),
            keyEncryptionAlgorithm: this.keyEncryptionAlgorithm.toJSON(),
            encryptedKey: this.encryptedKey.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.cms.OtherRecipientInfo = function () {
        (this.oriType = ""),
          (this.oriValue = new e.org.pkijs.asn1.ANY()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OtherRecipientInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.oriType = arguments[0].oriType || ""),
              (this.oriValue =
                arguments[0].oriValue || new e.org.pkijs.asn1.ANY()));
      }),
      (e.org.pkijs.simpl.cms.OtherRecipientInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.OtherRecipientInfo({
              names: { oriType: "oriType", oriValue: "oriValue" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for OtherRecipientInfo"
            );
          (this.oriType = r.result.oriType.value_block.toString()),
            (this.oriValue = r.result.oriValue);
        }),
      (e.org.pkijs.simpl.cms.OtherRecipientInfo.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.OID({ value: this.oriType }),
              this.oriValue,
            ],
          });
        }),
      (e.org.pkijs.simpl.cms.OtherRecipientInfo.prototype.toJSON = function () {
        var t = { oriType: this.oriType };
        return (
          this.oriValue instanceof e.org.pkijs.asn1.ANY ||
            (t.oriValue = this.oriValue.toJSON()),
          t
        );
      }),
      (e.org.pkijs.simpl.CMS_RECIPIENT_INFO = function () {
        (this.variant = -1),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_RECIPIENT_INFO.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.variant = arguments[0].variant || -1),
              "value" in arguments[0] && (this.value = arguments[0].value));
      }),
      (e.org.pkijs.simpl.CMS_RECIPIENT_INFO.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_RECIPIENT_INFO({
            names: { block_name: "block_name" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_RECIPIENT_INFO"
          );
        if (1 === r.result.block_name.id_block.tag_class)
          (this.variant = 1),
            (this.value = new e.org.pkijs.simpl.cms.KeyTransRecipientInfo({
              schema: r.result.block_name,
            }));
        else {
          var i = r.result.block_name.id_block.tag_number;
          switch (
            ((r.result.block_name.id_block.tag_class = 1),
            (r.result.block_name.id_block.tag_number = 16),
            i)
          ) {
            case 1:
              (this.variant = 2),
                (this.value = new e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo({
                  schema: r.result.block_name,
                }));
              break;
            case 2:
              (this.variant = 3),
                (this.value = new e.org.pkijs.simpl.cms.KEKRecipientInfo({
                  schema: r.result.block_name,
                }));
              break;
            case 3:
              (this.variant = 4),
                (this.value = new e.org.pkijs.simpl.cms.PasswordRecipientinfo({
                  schema: r.result.block_name,
                }));
              break;
            case 4:
              (this.variant = 5),
                (this.value = new e.org.pkijs.simpl.cms.OtherRecipientInfo({
                  schema: r.result.block_name,
                }));
          }
        }
      }),
      (e.org.pkijs.simpl.CMS_RECIPIENT_INFO.prototype.toSchema = function () {
        var t = this.value.toSchema();
        switch (this.variant) {
          case 1:
            return t;
          case 2:
          case 3:
          case 4:
            return (
              (t.id_block.tag_class = 3),
              (t.id_block.tag_number = this.variant - 1),
              t
            );
          default:
            return new e.org.pkijs.asn1.ANY();
        }
        return new e.org.pkijs.asn1.ANY();
      }),
      (e.org.pkijs.simpl.CMS_RECIPIENT_INFO.prototype.toJSON = function () {
        var e = { variant: this.variant };
        return (
          (1 != this.variant && 2 !== this.variant) ||
            (e.value = this.value.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.cms.OriginatorInfo = function () {
        (this.certs = new e.org.pkijs.simpl.CMS_CERTIFICATE_SET()),
          (this.crls = new e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.cms.OriginatorInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.certs =
                arguments[0].certs ||
                new e.org.pkijs.simpl.CMS_CERTIFICATE_SET()),
              (this.crls =
                arguments[0].crls ||
                new e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES()));
      }),
      (e.org.pkijs.simpl.cms.OriginatorInfo.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.cms.OriginatorInfo({
            names: { certs: "certs", crls: "crls" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OriginatorInfo"
          );
        (r.result.certs.id_block.tag_class = 1),
          (r.result.certs.id_block.tag_number = 17),
          (this.certs = new e.org.pkijs.simpl.CMS_CERTIFICATE_SET({
            schema: r.result.certs,
          })),
          (r.result.crls.id_block.tag_class = 1),
          (r.result.crls.id_block.tag_number = 17),
          (this.crls = new e.org.pkijs.simpl.CSM_REVOCATION_INFO_CHOICES({
            schema: r.result.crls,
          }));
      }),
      (e.org.pkijs.simpl.cms.OriginatorInfo.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: this.certs.toSchema().value_block.value,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: this.crls.toSchema().value_block.value,
            }),
          ],
        });
      }),
      (e.org.pkijs.simpl.cms.OriginatorInfo.prototype.toJSON = function () {
        return { certs: this.certs.toJSON(), crls: this.crls.toJSON() };
      }),
      (e.org.pkijs.simpl.cms.EncryptedContentInfo = function () {
        if (
          ((this.contentType = ""),
          (this.contentEncryptionAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          arguments[0] instanceof Object && "schema" in arguments[0])
        )
          e.org.pkijs.simpl.cms.EncryptedContentInfo.prototype.fromSchema.call(
            this,
            arguments[0].schema
          );
        else if (
          arguments[0] instanceof Object &&
          ((this.contentType = arguments[0].contentType || ""),
          (this.contentEncryptionAlgorithm =
            arguments[0].contentEncryptionAlgorithm ||
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          "encryptedContent" in arguments[0] &&
            ((this.encryptedContent = arguments[0].encryptedContent),
            1 === this.encryptedContent.id_block.tag_class &&
              4 === this.encryptedContent.id_block.tag_number &&
              this.encryptedContent.id_block.is_constructed === !1))
        ) {
          for (
            var t = new e.org.pkijs.asn1.OCTETSTRING({
                id_block: { is_constructed: !0 },
                is_constructed: !0,
              }),
              r = 0,
              i = this.encryptedContent.value_block.value_hex.byteLength;
            i > 0;

          ) {
            for (
              var n = new Uint8Array(
                  this.encryptedContent.value_block.value_hex,
                  r,
                  r + 1024 >
                  this.encryptedContent.value_block.value_hex.byteLength
                    ? this.encryptedContent.value_block.value_hex.byteLength - r
                    : 1024
                ),
                s = new ArrayBuffer(n.length),
                a = new Uint8Array(s),
                o = 0;
              o < a.length;
              o++
            )
              a[o] = n[o];
            t.value_block.value.push(
              new e.org.pkijs.asn1.OCTETSTRING({ value_hex: s })
            ),
              (i -= n.length),
              (r += n.length);
          }
          this.encryptedContent = t;
        }
      }),
      (e.org.pkijs.simpl.cms.EncryptedContentInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.cms.EncryptedContentInfo({
              names: {
                contentType: "contentType",
                contentEncryptionAlgorithm: {
                  names: { block_name: "contentEncryptionAlgorithm" },
                },
                encryptedContent: "encryptedContent",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for EncryptedContentInfo"
            );
          (this.contentType = r.result.contentType.value_block.toString()),
            (this.contentEncryptionAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                schema: r.result.contentEncryptionAlgorithm,
              })),
            "encryptedContent" in r.result &&
              ((this.encryptedContent = r.result.encryptedContent),
              (this.encryptedContent.id_block.tag_class = 1),
              (this.encryptedContent.id_block.tag_number = 4));
        }),
      (e.org.pkijs.simpl.cms.EncryptedContentInfo.prototype.toSchema =
        function () {
          var t = { is_indefinite_form: !1 },
            r = new Array();
          if (
            (r.push(new e.org.pkijs.asn1.OID({ value: this.contentType })),
            r.push(this.contentEncryptionAlgorithm.toSchema()),
            "encryptedContent" in this)
          ) {
            t.is_indefinite_form =
              this.encryptedContent.id_block.is_constructed;
            var i = this.encryptedContent;
            (i.id_block.tag_class = 3),
              (i.id_block.tag_number = 0),
              (i.len_block.is_indefinite_form =
                this.encryptedContent.id_block.is_constructed),
              r.push(i);
          }
          return new e.org.pkijs.asn1.SEQUENCE({ len_block: t, value: r });
        }),
      (e.org.pkijs.simpl.cms.EncryptedContentInfo.prototype.toJSON =
        function () {
          var e = {
            contentType: this.contentType,
            contentEncryptionAlgorithm:
              this.contentEncryptionAlgorithm.toJSON(),
          };
          return (
            "encryptedContent" in this &&
              (e.encryptedContent = this.encryptedContent.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA = function () {
        (this.version = 0),
          (this.recipientInfos = new Array()),
          (this.encryptedContentInfo =
            new e.org.pkijs.simpl.cms.EncryptedContentInfo()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              "originatorInfo" in arguments[0] &&
                (this.originatorInfo = arguments[0].originatorInfo),
              (this.recipientInfos =
                arguments[0].recipientInfos || new Array()),
              (this.encryptedContentInfo =
                arguments[0].encryptedContentInfo ||
                new e.org.pkijs.simpl.cms.EncryptedContentInfo()),
              "unprotectedAttrs" in arguments[0] &&
                (this.unprotectedAttrs = arguments[0].unprotectedAttrs));
      }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CMS_ENVELOPED_DATA({
            names: {
              version: "version",
              originatorInfo: "originatorInfo",
              recipientInfos: "recipientInfos",
              encryptedContentInfo: {
                names: { block_name: "encryptedContentInfo" },
              },
              unprotectedAttrs: "unprotectedAttrs",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CMS_ENVELOPED_DATA"
          );
        (this.version = r.result.version.value_block.value_dec),
          "originatorInfo" in r.result &&
            ((r.result.originatorInfo.id_block.tag_class = 1),
            (r.result.originatorInfo.id_block.tag_number = 16),
            (this.originatorInfo = new e.org.pkijs.simpl.cms.OriginatorInfo({
              schema: r.result.originatorInfo,
            })));
        for (var i = r.result.recipientInfos, n = 0; n < i.length; n++)
          this.recipientInfos.push(
            new e.org.pkijs.simpl.CMS_RECIPIENT_INFO({ schema: i[n] })
          );
        if (
          ((this.encryptedContentInfo =
            new e.org.pkijs.simpl.cms.EncryptedContentInfo({
              schema: r.result.encryptedContentInfo,
            })),
          "unprotectedAttrs" in r.result)
        ) {
          this.unprotectedAttrs = new Array();
          for (var s = r.result.unprotectedAttrs, a = 0; a < s.length; a++)
            this.unprotectedAttrs.push(
              new e.org.pkijs.simpl.ATTRIBUTE({ schema: s[a] })
            );
        }
      }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.toSchema = function () {
        var t = new Array();
        t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          "originatorInfo" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: this.originatorInfo.toSchema().value_block.value,
              })
            );
        for (var r = new Array(), i = 0; i < this.recipientInfos.length; i++)
          r.push(this.recipientInfos[i].toSchema());
        if (
          (t.push(new e.org.pkijs.asn1.SET({ value: r })),
          t.push(this.encryptedContentInfo.toSchema()),
          "unprotectedAttrs" in this)
        ) {
          for (
            var n = new Array(), s = 0;
            s < this.unprotectedAttrs.length;
            s++
          )
            n.push(this.unprotectedAttrs[s].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: n,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.toJSON = function () {
        var e = { version: this.version };
        "originatorInfo" in this &&
          (e.originatorInfo = this.originatorInfo.toJSON()),
          (e.recipientInfos = new Array());
        for (var t = 0; t < this.recipientInfos.length; t++)
          e.recipientInfos.push(this.recipientInfos[t].toJSON());
        if (
          ((e.encryptedContentInfo = this.encryptedContentInfo.toJSON()),
          "unprotectedAttrs" in this)
        ) {
          e.unprotectedAttrs = new Array();
          for (var t = 0; t < this.unprotectedAttrs.length; t++)
            e.unprotectedAttrs.push(this.unprotectedAttrs[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.addRecipientByCertificate =
        function (t, r, i) {
          var n = 0,
            s = r || {};
          if (
            t.subjectPublicKeyInfo.algorithm.algorithm_id.indexOf(
              "1.2.840.113549"
            ) !== -1
          )
            (n = 1), (i = 1);
          else {
            if (
              t.subjectPublicKeyInfo.algorithm.algorithm_id.indexOf(
                "1.2.840.10045"
              ) === -1
            )
              throw new Error(
                "Unknown type of certificate's public key: " +
                  t.subjectPublicKeyInfo.algorithm.algorithm_id
              );
            (n = 2), (i = 2);
          }
          switch (
            ("oaepHashAlgorithm" in s == !1 &&
              (s.oaepHashAlgorithm = "SHA-512"),
            "kdfAlgorithm" in s == !1 && (s.kdfAlgorithm = "SHA-512"),
            "kekEncryptionLength" in s == !1 && (s.kekEncryptionLength = 256),
            i)
          ) {
            case 1:
              var a = e.org.pkijs.getOIDByAlgorithm({ name: "RSA-OAEP" });
              if ("" === a) throw new Error("Can not find OID for OAEP");
              var o = e.org.pkijs.getOIDByAlgorithm({
                name: s.oaepHashAlgorithm,
              });
              if ("" === o)
                throw new Error(
                  "Unknown OAEP hash algorithm: " + s.oaepHashAlgorithm
                );
              var c = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  algorithm_id: o,
                  algorithm_params: new e.org.pkijs.asn1.NULL(),
                }),
                l = new e.org.pkijs.simpl.x509.RSAES_OAEP_params({
                  hashAlgorithm: c,
                  maskGenAlgorithm: new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: c.toSchema(),
                  }),
                }),
                u = new e.org.pkijs.simpl.cms.KeyTransRecipientInfo({
                  version: 0,
                  rid: new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                    issuer: t.issuer,
                    serialNumber: t.serialNumber,
                  }),
                  keyEncryptionAlgorithm:
                    new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                      algorithm_id: a,
                      algorithm_params: l.toSchema(),
                    }),
                  recipientCertificate: t,
                });
              this.recipientInfos.push(
                new e.org.pkijs.simpl.CMS_RECIPIENT_INFO({
                  variant: 1,
                  value: u,
                })
              );
              break;
            case 2:
              var p = new e.org.pkijs.simpl.cms.RecipientEncryptedKey({
                  rid: new e.org.pkijs.simpl.cms.KeyAgreeRecipientIdentifier({
                    variant: 1,
                    value: new e.org.pkijs.simpl.cms.IssuerAndSerialNumber({
                      issuer: t.issuer,
                      serialNumber: t.serialNumber,
                    }),
                  }),
                }),
                h = e.org.pkijs.getOIDByAlgorithm({
                  name: "AES-KW",
                  length: s.kekEncryptionLength,
                });
              if ("" === h)
                throw new Error(
                  "Unknown length for key encryption algorithm: " +
                    s.kekEncryptionLength
                );
              var f = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  algorithm_id: h,
                  algorithm_params: new e.org.pkijs.asn1.NULL(),
                }),
                g = e.org.pkijs.getOIDByAlgorithm({
                  name: "ECDH",
                  kdf: s.kdfAlgorithm,
                });
              if ("" === g)
                throw new Error("Unknown KDF algorithm: " + s.kdfAlgorithm);
              var m = new ArrayBuffer(64),
                d = new Uint8Array(m);
              e.org.pkijs.getRandomValues(d);
              var u = new e.org.pkijs.simpl.cms.KeyAgreeRecipientInfo({
                version: 3,
                ukm: new e.org.pkijs.asn1.OCTETSTRING({ value_hex: m }),
                keyEncryptionAlgorithm:
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: g,
                    algorithm_params: f.toSchema(),
                  }),
                recipientEncryptedKeys:
                  new e.org.pkijs.simpl.cms.RecipientEncryptedKeys({
                    encryptedKeys: [p],
                  }),
                recipientCertificate: t,
              });
              this.recipientInfos.push(
                new e.org.pkijs.simpl.CMS_RECIPIENT_INFO({
                  variant: 2,
                  value: u,
                })
              );
              break;
            default:
              throw new Error('Unknown "variant" value: ' + i);
          }
          return !0;
        }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.addRecipientByPreDefinedData =
        function (t, r, i) {
          var n = r || {};
          if (t instanceof ArrayBuffer == !1)
            throw new Error('Please pass "preDefinedData" in ArrayBuffer type');
          if (0 === t.byteLength)
            throw new Error("Pre-defined data could have zero length");
          if ("keyIdentifier" in n == !1) {
            var s = new ArrayBuffer(16),
              a = new Uint8Array(s);
            e.org.pkijs.getRandomValues(a), (n.keyIdentifier = s);
          }
          switch (
            ("hmacHashAlgorithm" in n == !1 &&
              (n.hmacHashAlgorithm = "SHA-512"),
            "iterationCount" in n == !1 && (n.iterationCount = 2048),
            "keyEncryptionAlgorithm" in n == !1 &&
              (n.keyEncryptionAlgorithm = { name: "AES-KW", length: 256 }),
            "keyEncryptionAlgorithmParams" in n == !1 &&
              (n.keyEncryptionAlgorithmParams = new e.org.pkijs.asn1.NULL()),
            i)
          ) {
            case 1:
              var o = e.org.pkijs.getOIDByAlgorithm(n.keyEncryptionAlgorithm);
              if ("" === o)
                throw new Error('Incorrect value for "keyEncryptionAlgorithm"');
              var c = new e.org.pkijs.simpl.cms.KEKRecipientInfo({
                version: 4,
                kekid: new e.org.pkijs.simpl.cms.KEKIdentifier({
                  keyIdentifier: new e.org.pkijs.asn1.OCTETSTRING({
                    value_hex: n.keyIdentifier,
                  }),
                }),
                keyEncryptionAlgorithm:
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: o,
                    algorithm_params: n.keyEncryptionAlgorithmParams,
                  }),
                preDefinedKEK: t,
              });
              this.recipientInfos.push(
                new e.org.pkijs.simpl.CMS_RECIPIENT_INFO({
                  variant: 3,
                  value: c,
                })
              );
              break;
            case 2:
              var l = e.org.pkijs.getOIDByAlgorithm({ name: "PBKDF2" });
              if ("" === l) throw new Error("Can not find OID for PBKDF2");
              var u = new ArrayBuffer(64),
                p = new Uint8Array(u);
              e.org.pkijs.getRandomValues(p);
              var h = e.org.pkijs.getOIDByAlgorithm({
                name: "HMAC",
                hash: { name: n.hmacHashAlgorithm },
              });
              if ("" === h)
                throw new Error(
                  'Incorrect value for "hmacHashAlgorithm": ' +
                    n.hmacHashAlgorithm
                );
              var f = new e.org.pkijs.simpl.cms.PBKDF2_params({
                  salt: new e.org.pkijs.asn1.OCTETSTRING({ value_hex: u }),
                  iterationCount: n.iterationCount,
                  prf: new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: h,
                    algorithm_params: new e.org.pkijs.asn1.NULL(),
                  }),
                }),
                o = e.org.pkijs.getOIDByAlgorithm(n.keyEncryptionAlgorithm);
              if ("" === o)
                throw new Error('Incorrect value for "keyEncryptionAlgorithm"');
              var c = new e.org.pkijs.simpl.cms.PasswordRecipientinfo({
                version: 0,
                keyDerivationAlgorithm:
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: l,
                    algorithm_params: f.toSchema(),
                  }),
                keyEncryptionAlgorithm:
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: o,
                    algorithm_params: n.keyEncryptionAlgorithmParams,
                  }),
                password: t,
              });
              this.recipientInfos.push(
                new e.org.pkijs.simpl.CMS_RECIPIENT_INFO({
                  variant: 4,
                  value: c,
                })
              );
              break;
            default:
              throw new Error('Unknown value for "variant": ' + i);
          }
        }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.encrypt = function (
        t,
        r
      ) {
        function i(t) {
          var r,
            i,
            n,
            s,
            a,
            c = Promise.resolve();
          return (
            (c = c.then(
              function (r) {
                var i =
                  o.recipientInfos[t].value.recipientCertificate
                    .subjectPublicKeyInfo.algorithm.algorithm_params;
                if (i instanceof e.org.pkijs.asn1.OID == !1)
                  return new Promise(function (e, r) {
                    r('Incorrect "recipientCertificate" for index ' + t);
                  });
                var a = i.value_block.toString();
                switch (a) {
                  case "1.2.840.10045.3.1.7":
                    (n = "P-256"), (s = 256);
                    break;
                  case "1.3.132.0.34":
                    (n = "P-384"), (s = 384);
                    break;
                  case "1.3.132.0.35":
                    (n = "P-521"), (s = 528);
                    break;
                  default:
                    return new Promise(function (e, r) {
                      r("Incorrect curve OID for index " + t);
                    });
                }
                return n;
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return y.generateKey({ name: "ECDH", namedCurve: e }, !0, [
                  "deriveBits",
                ]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return (
                  (r = e.publicKey), (i = e.privateKey), y.exportKey("spki", r)
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return (
                  (a = e),
                  o.recipientInfos[t].value.recipientCertificate.getPublicKey({
                    algorithm: {
                      algorithm: { name: "ECDH", namedCurve: n },
                      usages: [],
                    },
                  })
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return y.deriveBits({ name: "ECDH", public: e }, i, s);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (r) {
                var i = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    schema:
                      o.recipientInfos[t].value.keyEncryptionAlgorithm
                        .algorithm_params,
                  }),
                  n = e.org.pkijs.getAlgorithmByOID(i.algorithm_id);
                if ("name" in n == !1)
                  return new Promise(function (e, t) {
                    t(
                      "Incorrect OID for key encryption algorithm: " +
                        i.algorithm_id
                    );
                  });
                for (
                  var s = n.length,
                    a = new ArrayBuffer(4),
                    c = new Uint8Array(a),
                    l = 3;
                  l >= 0;
                  l--
                )
                  (c[l] = s), (s >>= 8);
                var u = new e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo({
                    keyInfo: new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                      algorithm_id: i.algorithm_id,
                      algorithm_params: new e.org.pkijs.asn1.NULL(),
                    }),
                    entityUInfo: o.recipientInfos[t].value.ukm,
                    suppPubInfo: new e.org.pkijs.asn1.OCTETSTRING({
                      value_hex: a,
                    }),
                  }),
                  p = u.toSchema().toBER(!1),
                  h = e.org.pkijs.getAlgorithmByOID(
                    o.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_id
                  );
                return "name" in h == !1
                  ? new Promise(function (e, r) {
                      r(
                        "Incorrect OID for key encryption algorithm: " +
                          o.recipientInfos[t].value.keyEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : e.org.pkijs.simpl.cms.kdf(h.kdf, r, n.length, p);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return y.importKey("raw", e, { name: "AES-KW" }, !0, [
                  "wrapKey",
                ]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return y.wrapKey("raw", p, e, { name: "AES-KW" });
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (r) {
                var i = org.pkijs.fromBER(a),
                  n = new e.org.pkijs.simpl.cms.OriginatorIdentifierOrKey();
                (n.variant = 3),
                  (n.value = new e.org.pkijs.simpl.cms.OriginatorPublicKey({
                    schema: i.result,
                  })),
                  "algorithm_params" in n.value.algorithm &&
                    delete n.value.algorithm.algorithm_params,
                  (o.recipientInfos[t].value.originator = n),
                  (o.recipientInfos[
                    t
                  ].value.recipientEncryptedKeys.encryptedKeys[0].encryptedKey =
                    new e.org.pkijs.asn1.OCTETSTRING({ value_hex: r }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function n(t) {
          var r = Promise.resolve();
          return (
            (r = r.then(
              function (r) {
                var i =
                    o.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_params,
                  n = new e.org.pkijs.simpl.x509.RSAES_OAEP_params({
                    schema: i,
                  }),
                  s = e.org.pkijs.getAlgorithmByOID(
                    n.hashAlgorithm.algorithm_id
                  );
                return "name" in s == !1
                  ? new Promise(function (e, t) {
                      t(
                        "Incorrect OID for hash algorithm: " +
                          n.hashAlgorithm.algorithm_id
                      );
                    })
                  : o.recipientInfos[t].value.recipientCertificate.getPublicKey(
                      {
                        algorithm: {
                          algorithm: {
                            name: "RSA-OAEP",
                            hash: { name: s.name },
                          },
                          usages: ["encrypt", "wrapKey"],
                        },
                      }
                    );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (r = r.then(
              function (e) {
                return y.encrypt({ name: "RSA-OAEP" }, e, f);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (r = r.then(
              function (r) {
                o.recipientInfos[t].value.encryptedKey =
                  new e.org.pkijs.asn1.OCTETSTRING({ value_hex: r });
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function s(t) {
          var r,
            i = Promise.resolve();
          return (
            (i = i.then(
              function (i) {
                return (
                  (r = e.org.pkijs.getAlgorithmByOID(
                    o.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_id
                  )),
                  "name" in r == !1
                    ? new Promise(function (e, r) {
                        r(
                          'Incorrect OID for "keyEncryptionAlgorithm": ' +
                            o.recipientInfos[t].value.keyEncryptionAlgorithm
                              .algorithm_id
                        );
                      })
                    : y.importKey(
                        "raw",
                        new Uint8Array(o.recipientInfos[t].value.preDefinedKEK),
                        r,
                        !0,
                        ["wrapKey"]
                      )
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (i = i.then(
              function (e) {
                return y.wrapKey("raw", p, e, r);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (i = i.then(
              function (r) {
                o.recipientInfos[t].value.encryptedKey =
                  new e.org.pkijs.asn1.OCTETSTRING({ value_hex: r });
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function a(t) {
          var r,
            i,
            n = Promise.resolve();
          return (
            (n = n.then(
              function (i) {
                if ("keyDerivationAlgorithm" in o.recipientInfos[t].value == !1)
                  return new Promise(function (e, t) {
                    t('Please append encoded "keyDerivationAlgorithm"');
                  });
                if (
                  "algorithm_params" in
                    o.recipientInfos[t].value.keyDerivationAlgorithm ==
                  !1
                )
                  return new Promise(function (e, t) {
                    t('Incorrectly encoded "keyDerivationAlgorithm"');
                  });
                try {
                  r = new e.org.pkijs.simpl.cms.PBKDF2_params({
                    schema:
                      o.recipientInfos[t].value.keyDerivationAlgorithm
                        .algorithm_params,
                  });
                } catch (n) {
                  return new Promise(function (e, t) {
                    t('Incorrectly encoded "keyDerivationAlgorithm"');
                  });
                }
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (e) {
                var r = new Uint8Array(o.recipientInfos[t].value.password);
                return y.importKey("raw", r, "PBKDF2", !0, ["deriveKey"]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (n) {
                if (
                  ((i = e.org.pkijs.getAlgorithmByOID(
                    o.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_id
                  )),
                  "name" in i == !1)
                )
                  return new Promise(function (e, r) {
                    r(
                      'Incorrect OID for "keyEncryptionAlgorithm": ' +
                        o.recipientInfos[t].value.keyEncryptionAlgorithm
                          .algorithm_id
                    );
                  });
                var s = "SHA-1";
                if ("prf" in r) {
                  var a = e.org.pkijs.getAlgorithmByOID(r.prf.algorithm_id);
                  if ("name" in a == !1)
                    return new Promise(function (e, t) {
                      t("Incorrect OID for HMAC hash algorithm");
                    });
                  s = a.hash.name;
                }
                var c = new Uint8Array(r.salt.value_block.value_hex),
                  l = r.iterationCount;
                return y.deriveKey(
                  { name: "PBKDF2", hash: { name: s }, salt: c, iterations: l },
                  n,
                  i,
                  !0,
                  ["wrapKey"]
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (e) {
                return y.wrapKey("raw", p, e, i);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (r) {
                o.recipientInfos[t].value.encryptedKey =
                  new e.org.pkijs.asn1.OCTETSTRING({ value_hex: r });
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        var o = this,
          c = Promise.resolve(),
          l = new ArrayBuffer(16),
          u = new Uint8Array(l);
        e.org.pkijs.getRandomValues(u);
        var p,
          h,
          f,
          g = new Uint8Array(r),
          m = new Array(),
          d = e.org.pkijs.getOIDByAlgorithm(t);
        if ("" === d)
          return new Promise(function (e, t) {
            t('Wrong "contentEncryptionAlgorithm" value');
          });
        var y = e.org.pkijs.getCrypto();
        return "undefined" == typeof y
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : ((c = c.then(function (e) {
              return y.generateKey(t, !0, ["encrypt"]);
            })),
            (c = c.then(
              function (e) {
                return (p = e), y.encrypt({ name: t.name, iv: u }, p, g);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c
              .then(
                function (e) {
                  return (h = e), y.exportKey("raw", p);
                },
                function (e) {
                  return new Promise(function (t, r) {
                    r(e);
                  });
                }
              )
              .then(
                function (e) {
                  return (f = e), !0;
                },
                function (e) {
                  return new Promise(function (t, r) {
                    r(e);
                  });
                }
              )),
            (c = c.then(
              function (t) {
                (o.version = 2),
                  (o.encryptedContentInfo =
                    new e.org.pkijs.simpl.cms.EncryptedContentInfo({
                      contentType: "1.2.840.113549.1.7.1",
                      contentEncryptionAlgorithm:
                        new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                          algorithm_id: d,
                          algorithm_params: new e.org.pkijs.asn1.OCTETSTRING({
                            value_hex: l,
                          }),
                        }),
                      encryptedContent: new e.org.pkijs.asn1.OCTETSTRING({
                        value_hex: h,
                      }),
                    }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                for (var t = 0; t < o.recipientInfos.length; t++) {
                  var r = Promise.resolve();
                  switch (o.recipientInfos[t].variant) {
                    case 1:
                      r = n(t);
                      break;
                    case 2:
                      r = i(t);
                      break;
                    case 3:
                      r = s(t);
                      break;
                    case 4:
                      r = a(t);
                      break;
                    default:
                      return new Promise(function (e, r) {
                        r("Uknown recipient type in array with index " + t);
                      });
                  }
                  m.push(r);
                }
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (c = c.then(
              function (e) {
                return Promise.all(m);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )));
      }),
      (e.org.pkijs.simpl.CMS_ENVELOPED_DATA.prototype.decrypt = function (
        t,
        r
      ) {
        function n(t) {
          var r,
            i,
            n,
            s,
            a = Promise.resolve();
          return (
            (a = a.then(
              function (s) {
                if ("recipientCertificate" in u == !1)
                  return new Promise(function (e, t) {
                    t(
                      'Parameter "recipientCertificate" is mandatory for "KeyAgreeRecipientInfo"'
                    );
                  });
                if ("recipientPrivateKey" in u == !1)
                  return new Promise(function (e, t) {
                    t(
                      'Parameter "recipientPrivateKey" is mandatory for "KeyAgreeRecipientInfo"'
                    );
                  });
                var a =
                  u.recipientCertificate.subjectPublicKeyInfo.algorithm
                    .algorithm_params;
                if (a instanceof e.org.pkijs.asn1.OID == !1)
                  return new Promise(function (e, r) {
                    r('Incorrect "recipientCertificate" for index ' + t);
                  });
                switch ((n = a.value_block.toString())) {
                  case "1.2.840.10045.3.1.7":
                    (r = "P-256"), (i = 256);
                    break;
                  case "1.3.132.0.34":
                    (r = "P-384"), (i = 384);
                    break;
                  case "1.3.132.0.35":
                    (r = "P-521"), (i = 528);
                    break;
                  default:
                    return new Promise(function (e, r) {
                      r("Incorrect curve OID for index " + t);
                    });
                }
                return p.importKey(
                  "pkcs8",
                  u.recipientPrivateKey,
                  { name: "ECDH", namedCurve: r },
                  !0,
                  ["deriveBits"]
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (a = a.then(
              function (i) {
                (s = i),
                  "algorithm_params" in
                    c.recipientInfos[t].value.originator.value.algorithm ==
                    !1 &&
                    (c.recipientInfos[
                      t
                    ].value.originator.value.algorithm.algorithm_params =
                      new e.org.pkijs.asn1.OID({ value: n }));
                var a = c.recipientInfos[t].value.originator.value
                  .toSchema()
                  .toBER(!1);
                return p.importKey(
                  "spki",
                  a,
                  { name: "ECDH", namedCurve: r },
                  !0,
                  []
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (a = a.then(
              function (e) {
                return p.deriveBits({ name: "ECDH", public: e }, s, i);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (a = a.then(
              function (r) {
                var i = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    schema:
                      c.recipientInfos[t].value.keyEncryptionAlgorithm
                        .algorithm_params,
                  }),
                  n = e.org.pkijs.getAlgorithmByOID(i.algorithm_id);
                if ("name" in n == !1)
                  return new Promise(function (e, t) {
                    t(
                      "Incorrect OID for key encryption algorithm: " +
                        i.algorithm_id
                    );
                  });
                for (
                  var s = n.length,
                    a = new ArrayBuffer(4),
                    o = new Uint8Array(a),
                    l = 3;
                  l >= 0;
                  l--
                )
                  (o[l] = s), (s >>= 8);
                var u = new e.org.pkijs.simpl.cms.ECC_CMS_SharedInfo({
                    keyInfo: new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                      algorithm_id: i.algorithm_id,
                      algorithm_params: new e.org.pkijs.asn1.NULL(),
                    }),
                    entityUInfo: c.recipientInfos[t].value.ukm,
                    suppPubInfo: new e.org.pkijs.asn1.OCTETSTRING({
                      value_hex: a,
                    }),
                  }),
                  p = u.toSchema().toBER(!1),
                  h = e.org.pkijs.getAlgorithmByOID(
                    c.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_id
                  );
                return "name" in h == !1
                  ? new Promise(function (e, r) {
                      r(
                        "Incorrect OID for key encryption algorithm: " +
                          c.recipientInfos[t].value.keyEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : e.org.pkijs.simpl.cms.kdf(h.kdf, r, n.length, p);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (a = a.then(
              function (e) {
                return p.importKey("raw", e, { name: "AES-KW" }, !0, [
                  "unwrapKey",
                ]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (a = a.then(
              function (r) {
                var i = e.org.pkijs.getAlgorithmByOID(
                  c.encryptedContentInfo.contentEncryptionAlgorithm.algorithm_id
                );
                return "name" in i == !1
                  ? new Promise(function (e, t) {
                      t(
                        'Incorrect "contentEncryptionAlgorithm": ' +
                          c.encryptedContentInfo.contentEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : p.unwrapKey(
                      "raw",
                      c.recipientInfos[t].value.recipientEncryptedKeys
                        .encryptedKeys[0].encryptedKey.value_block.value_hex,
                      r,
                      { name: "AES-KW" },
                      i,
                      !0,
                      ["decrypt"]
                    );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function s(t) {
          var r = Promise.resolve();
          return (
            (r = r.then(
              function (r) {
                if ("recipientPrivateKey" in u == !1)
                  return new Promise(function (e, t) {
                    t(
                      'Parameter "recipientPrivateKey" is mandatory for "KeyTransRecipientInfo"'
                    );
                  });
                var i =
                    c.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_params,
                  n = new e.org.pkijs.simpl.x509.RSAES_OAEP_params({
                    schema: i,
                  }),
                  s = e.org.pkijs.getAlgorithmByOID(
                    n.hashAlgorithm.algorithm_id
                  );
                return "name" in s == !1
                  ? new Promise(function (e, t) {
                      t(
                        "Incorrect OID for hash algorithm: " +
                          n.hashAlgorithm.algorithm_id
                      );
                    })
                  : p.importKey(
                      "pkcs8",
                      u.recipientPrivateKey,
                      { name: "RSA-OAEP", hash: { name: s.name } },
                      !0,
                      ["decrypt"]
                    );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (r = r.then(
              function (e) {
                return p.decrypt(
                  { name: "RSA-OAEP" },
                  e,
                  c.recipientInfos[t].value.encryptedKey.value_block.value_hex
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (r = r.then(
              function (t) {
                var r = e.org.pkijs.getAlgorithmByOID(
                  c.encryptedContentInfo.contentEncryptionAlgorithm.algorithm_id
                );
                return "name" in r == !1
                  ? new Promise(function (e, t) {
                      t(
                        'Incorrect "contentEncryptionAlgorithm": ' +
                          c.encryptedContentInfo.contentEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : p.importKey("raw", t, r, !0, ["decrypt"]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function a(t) {
          var r,
            i = Promise.resolve();
          return (
            (i = i.then(
              function (i) {
                return "preDefinedData" in u == !1
                  ? new Promise(function (e, t) {
                      t(
                        'Parameter "preDefinedData" is mandatory for "KEKRecipientInfo"'
                      );
                    })
                  : ((r = e.org.pkijs.getAlgorithmByOID(
                      c.recipientInfos[t].value.keyEncryptionAlgorithm
                        .algorithm_id
                    )),
                    "name" in r == !1
                      ? new Promise(function (e, r) {
                          r(
                            'Incorrect OID for "keyEncryptionAlgorithm": ' +
                              c.recipientInfos[t].value.keyEncryptionAlgorithm
                                .algorithm_id
                          );
                        })
                      : p.importKey("raw", u.preDefinedData, r, !0, [
                          "unwrapKey",
                        ]));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (i = i.then(
              function (i) {
                var n = e.org.pkijs.getAlgorithmByOID(
                  c.encryptedContentInfo.contentEncryptionAlgorithm.algorithm_id
                );
                return "name" in n == !1
                  ? new Promise(function (e, t) {
                      t(
                        'Incorrect "contentEncryptionAlgorithm": ' +
                          c.encryptedContentInfo.contentEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : p.unwrapKey(
                      "raw",
                      c.recipientInfos[t].value.encryptedKey.value_block
                        .value_hex,
                      i,
                      r,
                      n,
                      !0,
                      ["decrypt"]
                    );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        function o(t) {
          var r,
            i,
            n = Promise.resolve();
          return (
            (n = n.then(
              function (i) {
                if ("preDefinedData" in u == !1)
                  return new Promise(function (e, t) {
                    t(
                      'Parameter "preDefinedData" is mandatory for "KEKRecipientInfo"'
                    );
                  });
                if ("keyDerivationAlgorithm" in c.recipientInfos[t].value == !1)
                  return new Promise(function (e, t) {
                    t('Please append encoded "keyDerivationAlgorithm"');
                  });
                if (
                  "algorithm_params" in
                    c.recipientInfos[t].value.keyDerivationAlgorithm ==
                  !1
                )
                  return new Promise(function (e, t) {
                    t('Incorrectly encoded "keyDerivationAlgorithm"');
                  });
                try {
                  r = new e.org.pkijs.simpl.cms.PBKDF2_params({
                    schema:
                      c.recipientInfos[t].value.keyDerivationAlgorithm
                        .algorithm_params,
                  });
                } catch (n) {
                  return new Promise(function (e, t) {
                    t('Incorrectly encoded "keyDerivationAlgorithm"');
                  });
                }
                return p.importKey("raw", u.preDefinedData, "PBKDF2", !0, [
                  "deriveKey",
                ]);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (n) {
                if (
                  ((i = e.org.pkijs.getAlgorithmByOID(
                    c.recipientInfos[t].value.keyEncryptionAlgorithm
                      .algorithm_id
                  )),
                  "name" in i == !1)
                )
                  return new Promise(function (e, r) {
                    r(
                      'Incorrect OID for "keyEncryptionAlgorithm": ' +
                        c.recipientInfos[t].value.keyEncryptionAlgorithm
                          .algorithm_id
                    );
                  });
                var s = "SHA-1";
                if ("prf" in r) {
                  var a = e.org.pkijs.getAlgorithmByOID(r.prf.algorithm_id);
                  if ("name" in a == !1)
                    return new Promise(function (e, t) {
                      t("Incorrect OID for HMAC hash algorithm");
                    });
                  s = a.hash.name;
                }
                var o = new Uint8Array(r.salt.value_block.value_hex),
                  l = r.iterationCount;
                return p.deriveKey(
                  { name: "PBKDF2", hash: { name: s }, salt: o, iterations: l },
                  n,
                  i,
                  !0,
                  ["unwrapKey"]
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (n = n.then(
              function (r) {
                var n = e.org.pkijs.getAlgorithmByOID(
                  c.encryptedContentInfo.contentEncryptionAlgorithm.algorithm_id
                );
                return "name" in n == !1
                  ? new Promise(function (e, t) {
                      t(
                        'Incorrect "contentEncryptionAlgorithm": ' +
                          c.encryptedContentInfo.contentEncryptionAlgorithm
                            .algorithm_id
                      );
                    })
                  : p.unwrapKey(
                      "raw",
                      c.recipientInfos[t].value.encryptedKey.value_block
                        .value_hex,
                      r,
                      i,
                      n,
                      !0,
                      ["decrypt"]
                    );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            ))
          );
        }
        var c = this,
          l = Promise.resolve(),
          u = r || {};
        if (t + 1 > this.recipientInfos.length)
          return new Promise(function (e, t) {
            t(
              'Maximum value for "index" is: ' +
                (this.recipientInfos.length - 1)
            );
          });
        var p = e.org.pkijs.getCrypto();
        return "undefined" == typeof p
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : ((l = l.then(
              function (e) {
                var r = Promise.resolve();
                switch (c.recipientInfos[t].variant) {
                  case 1:
                    r = s(t);
                    break;
                  case 2:
                    r = n(t);
                    break;
                  case 3:
                    r = a(t);
                    break;
                  case 4:
                    r = o(t);
                    break;
                  default:
                    return new Promise(function (e, t) {
                      t("Uknown recipient type in array with index " + i);
                    });
                }
                return r;
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )),
            (l = l.then(
              function (t) {
                var r = e.org.pkijs.getAlgorithmByOID(
                  c.encryptedContentInfo.contentEncryptionAlgorithm.algorithm_id
                );
                if ("name" in r == !1)
                  return new Promise(function (e, t) {
                    t(
                      'Incorrect "contentEncryptionAlgorithm": ' +
                        c.encryptedContentInfo.contentEncryptionAlgorithm
                          .algorithm_id
                    );
                  });
                var i =
                    c.encryptedContentInfo.contentEncryptionAlgorithm
                      .algorithm_params.value_block.value_hex,
                  n = new Uint8Array(i),
                  s = new ArrayBuffer(0);
                if (
                  c.encryptedContentInfo.encryptedContent.id_block
                    .is_constructed === !1
                )
                  s =
                    c.encryptedContentInfo.encryptedContent.value_block
                      .value_hex;
                else
                  for (
                    var a = 0;
                    a <
                    c.encryptedContentInfo.encryptedContent.value_block.value
                      .length;
                    a++
                  )
                    s = e.org.pkijs.concat_buffers(
                      s,
                      c.encryptedContentInfo.encryptedContent.value_block.value[
                        a
                      ].value_block.value_hex
                    );
                return p.decrypt({ name: r.name, iv: n }, t, s);
              },
              function (e) {
                return new Promise(function (t, r) {
                  r(e);
                });
              }
            )));
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    (e.org.pkijs.emptyObject = function () {
      (this.toJSON = function () {
        return {};
      }),
        (this.toSchema = function () {
          return {};
        });
    }),
      (e.org.pkijs.getNames = function (e) {
        var t = {};
        return e instanceof Object && (t = e.names || {}), t;
      }),
      (e.org.pkijs.inheriteObjectFields = function (e) {
        for (i in e.prototype)
          "function" != typeof e.prototype[i] && (this[i] = e.prototype[i]);
      }),
      (e.org.pkijs.getUTCDate = function (e) {
        var t = e;
        return new Date(t.getTime() + 6e4 * t.getTimezoneOffset());
      }),
      (e.org.pkijs.padNumber = function (e, t) {
        for (
          var r = e.toString(10), i = t - r.length, n = new Array(i), s = 0;
          s < i;
          s++
        )
          n[s] = "0";
        var a = n.join("");
        return a.concat(r);
      }),
      (e.org.pkijs.getValue = function (e, t, r) {
        return t in e ? e[t] : r;
      }),
      (e.org.pkijs.isEqual_view = function (e, t) {
        if (e.length !== t.length) return !1;
        for (var r = 0; r < e.length; r++) if (e[r] != t[r]) return !1;
        return !0;
      }),
      (e.org.pkijs.isEqual_buffer = function (t, r) {
        if (t.byteLength != r.byteLength) return !1;
        var i = new Uint8Array(t),
          n = new Uint8Array(r);
        return e.org.pkijs.isEqual_view(i, n);
      }),
      (e.org.pkijs.concat_buffers = function (e, t) {
        for (
          var r = new Uint8Array(e),
            i = new Uint8Array(t),
            n = new ArrayBuffer(e.byteLength + t.byteLength),
            s = new Uint8Array(n),
            a = 0;
          a < e.byteLength;
          a++
        )
          s[a] = r[a];
        for (var o = 0; o < t.byteLength; o++) s[e.byteLength + o] = i[o];
        return n;
      }),
      (e.org.pkijs.copyBuffer = function (e) {
        for (
          var t = new ArrayBuffer(e.byteLength),
            r = new Uint8Array(t),
            i = new Uint8Array(e),
            n = 0;
          n < i.length;
          n++
        )
          r[n] = i[n];
        return t;
      }),
      (e.org.pkijs.getCrypto = function () {
        var t;
        return (
          "crypto" in e &&
            ("webkitSubtle" in e.crypto && (t = e.crypto.webkitSubtle),
            "subtle" in e.crypto && (t = e.crypto.subtle)),
          t
        );
      }),
      (e.org.pkijs.stringPrep = function (e) {
        var t = e.replace(/^\s+|\s+$/g, "");
        return (t = t.replace(/\s+/g, " ")), (t = t.toLowerCase());
      }),
      (e.org.pkijs.bufferToHexCodes = function (e, t, r) {
        for (
          var i = "", n = new Uint8Array(e, t, r), s = 0;
          s < n.length;
          s++
        ) {
          var a = n[s].toString(16).toUpperCase();
          i = i + (1 === a.length ? "0" : "") + a;
        }
        return i;
      }),
      (e.org.pkijs.bufferFromHexCodes = function (e) {
        var t = e.length,
          r = new ArrayBuffer(t >> 1),
          i = new Uint8Array(r),
          n = {};
        (n[0] = 0),
          (n[1] = 1),
          (n[2] = 2),
          (n[3] = 3),
          (n[4] = 4),
          (n[5] = 5),
          (n[6] = 6),
          (n[7] = 7),
          (n[8] = 8),
          (n[9] = 9),
          (n.A = 10),
          (n.a = 10),
          (n.B = 11),
          (n.b = 11),
          (n.C = 12),
          (n.c = 12),
          (n.D = 13),
          (n.d = 13),
          (n.E = 14),
          (n.e = 14),
          (n.F = 15),
          (n.f = 15);
        for (var s = 0, a = 0, o = 0; o < t; o++)
          o % 2
            ? ((a |= n[e.charAt(o)]), (i[s] = a), s++)
            : (a = n[e.charAt(o)] << 4);
        return r;
      }),
      (e.org.pkijs.getRandomValues = function (t) {
        if ("crypto" in e) return e.crypto.getRandomValues(t);
        throw new Error("No support for Web Cryptography API");
      }),
      (e.org.pkijs.getAlgorithmParameters = function (t, r) {
        var i = { algorithm: {}, usages: [] };
        switch (t.toUpperCase()) {
          case "RSASSA-PKCS1-V1_5":
            switch (r.toLowerCase()) {
              case "generatekey":
                i = {
                  algorithm: {
                    name: "RSASSA-PKCS1-v1_5",
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: { name: "SHA-256" },
                  },
                  usages: ["sign", "verify"],
                };
                break;
              case "verify":
              case "sign":
              case "importkey":
                i = {
                  algorithm: {
                    name: "RSASSA-PKCS1-v1_5",
                    hash: { name: "SHA-256" },
                  },
                  usages: ["verify"],
                };
                break;
              case "exportkey":
              default:
                return { algorithm: { name: "RSASSA-PKCS1-v1_5" }, usages: [] };
            }
            break;
          case "RSA-PSS":
            switch (r.toLowerCase()) {
              case "sign":
              case "verify":
                i = {
                  algorithm: {
                    name: "RSA-PSS",
                    hash: { name: "SHA-1" },
                    saltLength: 20,
                  },
                  usages: ["sign", "verify"],
                };
                break;
              case "generatekey":
                i = {
                  algorithm: {
                    name: "RSA-PSS",
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: { name: "SHA-1" },
                  },
                  usages: ["sign", "verify"],
                };
                break;
              case "importkey":
                i = {
                  algorithm: { name: "RSA-PSS", hash: { name: "SHA-1" } },
                  usages: ["verify"],
                };
                break;
              case "exportkey":
              default:
                return { algorithm: { name: "RSA-PSS" }, usages: [] };
            }
            break;
          case "RSA-OAEP":
            switch (r.toLowerCase()) {
              case "encrypt":
              case "decrypt":
                i = {
                  algorithm: { name: "RSA-OAEP" },
                  usages: ["encrypt", "decrypt"],
                };
                break;
              case "generatekey":
                i = {
                  algorithm: {
                    name: "RSA-OAEP",
                    modulusLength: 2048,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: { name: "SHA-256" },
                  },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
                break;
              case "importkey":
                i = {
                  algorithm: { name: "RSA-OAEP", hash: { name: "SHA-256" } },
                  usages: ["encrypt"],
                };
                break;
              case "exportkey":
              default:
                return { algorithm: { name: "RSA-OAEP" }, usages: [] };
            }
            break;
          case "ECDSA":
            switch (r.toLowerCase()) {
              case "generatekey":
                i = {
                  algorithm: { name: "ECDSA", namedCurve: "P-256" },
                  usages: ["sign", "verify"],
                };
                break;
              case "importkey":
                i = {
                  algorithm: { name: "ECDSA", namedCurve: "P-256" },
                  usages: ["verify"],
                };
                break;
              case "verify":
              case "sign":
                i = {
                  algorithm: { name: "ECDSA", hash: { name: "SHA-256" } },
                  usages: ["sign"],
                };
                break;
              default:
                return { algorithm: { name: "ECDSA" }, usages: [] };
            }
            break;
          case "ECDH":
            switch (r.toLowerCase()) {
              case "exportkey":
              case "importkey":
              case "generatekey":
                i = {
                  algorithm: { name: "ECDH", namedCurve: "P-256" },
                  usages: ["deriveKey", "deriveBits"],
                };
                break;
              case "derivekey":
              case "derivebits":
                i = {
                  algorithm: { name: "ECDH", namedCurve: "P-256", public: [] },
                  usages: ["encrypt", "decrypt"],
                };
                break;
              default:
                return { algorithm: { name: "ECDH" }, usages: [] };
            }
            break;
          case "AES-CTR":
            switch (r.toLowerCase()) {
              case "importkey":
              case "exportkey":
              case "generatekey":
                i = {
                  algorithm: { name: "AES-CTR", length: 256 },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
                break;
              case "decrypt":
              case "encrypt":
                i = {
                  algorithm: {
                    name: "AES-CTR",
                    counter: new Uint8Array(16),
                    length: 10,
                  },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
              default:
                return { algorithm: { name: "AES-CTR" }, usages: [] };
            }
            break;
          case "AES-CBC":
            switch (r.toLowerCase()) {
              case "importkey":
              case "exportkey":
              case "generatekey":
                i = {
                  algorithm: { name: "AES-CBC", length: 256 },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
                break;
              case "decrypt":
              case "encrypt":
                i = {
                  algorithm: {
                    name: "AES-CBC",
                    iv: e.org.pkijs.getRandomValues(new Uint8Array(16)),
                  },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
              default:
                return { algorithm: { name: "AES-CBC" }, usages: [] };
            }
            break;
          case "AES-GCM":
            switch (r.toLowerCase()) {
              case "importkey":
              case "exportkey":
              case "generatekey":
                i = {
                  algorithm: { name: "AES-GCM", length: 256 },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
                break;
              case "decrypt":
              case "encrypt":
                i = {
                  algorithm: {
                    name: "AES-GCM",
                    iv: e.org.pkijs.getRandomValues(new Uint8Array(16)),
                  },
                  usages: ["encrypt", "decrypt", "wrapKey", "unwrapKey"],
                };
              default:
                return { algorithm: { name: "AES-GCM" }, usages: [] };
            }
            break;
          case "AES-KW":
            switch (r.toLowerCase()) {
              case "importkey":
              case "exportkey":
              case "generatekey":
              case "wrapkey":
              case "unwrapkey":
                i = {
                  algorithm: { name: "AES-KW", length: 256 },
                  usages: ["wrapKey", "unwrapKey"],
                };
                break;
              default:
                return { algorithm: { name: "AES-KW" }, usages: [] };
            }
            break;
          case "HMAC":
            switch (r.toLowerCase()) {
              case "sign":
              case "verify":
                i = { algorithm: { name: "HMAC" }, usages: ["sign", "verify"] };
                break;
              case "importkey":
              case "exportkey":
              case "generatekey":
                i = {
                  algorithm: {
                    name: "HMAC",
                    length: 10,
                    hash: { name: "SHA-256" },
                  },
                  usages: ["sign", "verify"],
                };
                break;
              default:
                return { algorithm: { name: "HMAC" }, usages: [] };
            }
            break;
          case "HKDF":
            switch (r.toLowerCase()) {
              case "derivekey":
                i = {
                  algorithm: {
                    name: "HKDF",
                    hash: "SHA-256",
                    salt: new Uint8Array(),
                    info: new Uint8Array(),
                  },
                  usages: ["encrypt", "decrypt"],
                };
                break;
              default:
                return { algorithm: { name: "HKDF" }, usages: [] };
            }
            break;
          case "PBKDF2":
            switch (r.toLowerCase()) {
              case "derivekey":
                i = {
                  algorithm: {
                    name: "PBKDF2",
                    hash: { name: "SHA-256" },
                    salt: new Uint8Array(),
                    iterations: 1e3,
                  },
                  usages: ["encrypt", "decrypt"],
                };
                break;
              default:
                return { algorithm: { name: "PBKDF2" }, usages: [] };
            }
        }
        return i;
      }),
      (e.org.pkijs.getOIDByAlgorithm = function (e) {
        var t = "";
        switch (e.name.toUpperCase()) {
          case "RSASSA-PKCS1-V1_5":
            switch (e.hash.name.toUpperCase()) {
              case "SHA-1":
                t = "1.2.840.113549.1.1.5";
                break;
              case "SHA-256":
                t = "1.2.840.113549.1.1.11";
                break;
              case "SHA-384":
                t = "1.2.840.113549.1.1.12";
                break;
              case "SHA-512":
                t = "1.2.840.113549.1.1.13";
            }
            break;
          case "RSA-PSS":
            t = "1.2.840.113549.1.1.10";
            break;
          case "RSA-OAEP":
            t = "1.2.840.113549.1.1.7";
            break;
          case "ECDSA":
            switch (e.hash.name.toUpperCase()) {
              case "SHA-1":
                t = "1.2.840.10045.4.1";
                break;
              case "SHA-256":
                t = "1.2.840.10045.4.3.2";
                break;
              case "SHA-384":
                t = "1.2.840.10045.4.3.3";
                break;
              case "SHA-512":
                t = "1.2.840.10045.4.3.4";
            }
            break;
          case "ECDH":
            switch (e.kdf.toUpperCase()) {
              case "SHA-1":
                t = "1.3.133.16.840.63.0.2";
                break;
              case "SHA-256":
                t = "1.3.132.1.11.1";
                break;
              case "SHA-384":
                t = "1.3.132.1.11.2";
                break;
              case "SHA-512":
                t = "1.3.132.1.11.3";
            }
            break;
          case "AES-CTR":
            break;
          case "AES-CBC":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.2";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.22";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.42";
            }
            break;
          case "AES-CMAC":
            break;
          case "AES-GCM":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.6";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.26";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.46";
            }
            break;
          case "AES-CFB":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.4";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.24";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.44";
            }
            break;
          case "AES-KW":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.5";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.25";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.45";
            }
            break;
          case "HMAC":
            switch (e.hash.name.toUpperCase()) {
              case "SHA-1":
                t = "1.2.840.113549.2.7";
                break;
              case "SHA-256":
                t = "1.2.840.113549.2.9";
                break;
              case "SHA-384":
                t = "1.2.840.113549.2.10";
                break;
              case "SHA-512":
                t = "1.2.840.113549.2.11";
            }
            break;
          case "DH":
            t = "1.2.840.113549.1.9.16.3.5";
            break;
          case "SHA-1":
            t = "1.3.14.3.2.26";
            break;
          case "SHA-256":
            t = "2.16.840.1.101.3.4.2.1";
            break;
          case "SHA-384":
            t = "2.16.840.1.101.3.4.2.2";
            break;
          case "SHA-512":
            t = "2.16.840.1.101.3.4.2.3";
            break;
          case "CONCAT":
            break;
          case "HKDF":
            break;
          case "PBKDF2":
            t = "1.2.840.113549.1.5.12";
            break;
          case "P-256":
            t = "1.2.840.10045.3.1.7";
            break;
          case "P-384":
            t = "1.3.132.0.34";
            break;
          case "P-521":
            t = "1.3.132.0.35";
        }
        return t;
      }),
      (e.org.pkijs.getAlgorithmByOID = function (e) {
        var t = {};
        switch (e) {
          case "1.2.840.113549.1.1.5":
            t = { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-1" } };
            break;
          case "1.2.840.113549.1.1.11":
            t = { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-256" } };
            break;
          case "1.2.840.113549.1.1.12":
            t = { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-384" } };
            break;
          case "1.2.840.113549.1.1.13":
            t = { name: "RSASSA-PKCS1-v1_5", hash: { name: "SHA-512" } };
            break;
          case "1.2.840.113549.1.1.10":
            t = { name: "RSA-PSS" };
            break;
          case "1.2.840.113549.1.1.7":
            t = { name: "RSA-OAEP" };
            break;
          case "1.2.840.10045.4.1":
            t = { name: "ECDSA", hash: { name: "SHA-1" } };
            break;
          case "1.2.840.10045.4.3.2":
            t = { name: "ECDSA", hash: { name: "SHA-256" } };
            break;
          case "1.2.840.10045.4.3.3":
            t = { name: "ECDSA", hash: { name: "SHA-384" } };
            break;
          case "1.2.840.10045.4.3.4":
            t = { name: "ECDSA", hash: { name: "SHA-512" } };
            break;
          case "1.3.133.16.840.63.0.2":
            t = { name: "ECDH", kdf: "SHA-1" };
            break;
          case "1.3.132.1.11.1":
            t = { name: "ECDH", kdf: "SHA-256" };
            break;
          case "1.3.132.1.11.2":
            t = { name: "ECDH", kdf: "SHA-384" };
            break;
          case "1.3.132.1.11.3":
            t = { name: "ECDH", kdf: "SHA-512" };
            break;
          case "2.16.840.1.101.3.4.1.2":
            t = { name: "AES-CBC", length: 128 };
            break;
          case "2.16.840.1.101.3.4.1.22":
            t = { name: "AES-CBC", length: 192 };
            break;
          case "2.16.840.1.101.3.4.1.42":
            t = { name: "AES-CBC", length: 256 };
            break;
          case "2.16.840.1.101.3.4.1.6":
            t = { name: "AES-GCM", length: 128 };
            break;
          case "2.16.840.1.101.3.4.1.26":
            t = { name: "AES-GCM", length: 192 };
            break;
          case "2.16.840.1.101.3.4.1.46":
            t = { name: "AES-GCM", length: 256 };
            break;
          case "2.16.840.1.101.3.4.1.4":
            t = { name: "AES-CFB", length: 128 };
            break;
          case "2.16.840.1.101.3.4.1.24":
            t = { name: "AES-CFB", length: 192 };
            break;
          case "2.16.840.1.101.3.4.1.44":
            t = { name: "AES-CFB", length: 256 };
            break;
          case "2.16.840.1.101.3.4.1.5":
            t = { name: "AES-KW", length: 128 };
            break;
          case "2.16.840.1.101.3.4.1.25":
            t = { name: "AES-KW", length: 192 };
            break;
          case "2.16.840.1.101.3.4.1.45":
            t = { name: "AES-KW", length: 256 };
            break;
          case "1.2.840.113549.2.7":
            t = { name: "HMAC", hash: { name: "SHA-1" } };
            break;
          case "1.2.840.113549.2.9":
            t = { name: "HMAC", hash: { name: "SHA-256" } };
            break;
          case "1.2.840.113549.2.10":
            t = { name: "HMAC", hash: { name: "SHA-384" } };
            break;
          case "1.2.840.113549.2.11":
            t = { name: "HMAC", hash: { name: "SHA-512" } };
            break;
          case "1.2.840.113549.1.9.16.3.5":
            t = { name: "DH" };
            break;
          case "1.3.14.3.2.26":
            t = { name: "SHA-1" };
            break;
          case "2.16.840.1.101.3.4.2.1":
            t = { name: "SHA-256" };
            break;
          case "2.16.840.1.101.3.4.2.2":
            t = { name: "SHA-384" };
            break;
          case "2.16.840.1.101.3.4.2.3":
            t = { name: "SHA-512" };
            break;
          case "1.2.840.113549.1.5.12":
            t = { name: "PBKDF2" };
            break;
          case "1.2.840.10045.3.1.7":
            t = { name: "P-256" };
            break;
          case "1.3.132.0.34":
            t = { name: "P-384" };
            break;
          case "1.3.132.0.35":
            t = { name: "P-521" };
        }
        return t;
      }),
      (e.org.pkijs.getHashAlgorithm = function (t) {
        var r = "";
        switch (t.algorithm_id) {
          case "1.2.840.10045.4.1":
          case "1.2.840.113549.1.1.5":
            r = "SHA-1";
            break;
          case "1.2.840.10045.4.3.2":
          case "1.2.840.113549.1.1.11":
            r = "SHA-256";
            break;
          case "1.2.840.10045.4.3.3":
          case "1.2.840.113549.1.1.12":
            r = "SHA-384";
            break;
          case "1.2.840.10045.4.3.4":
          case "1.2.840.113549.1.1.13":
            r = "SHA-512";
            break;
          case "1.2.840.113549.1.1.10":
            var i;
            try {
              if (
                ((i = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                  schema: t.algorithm_params,
                })),
                "hashAlgorithm" in i)
              ) {
                var n = e.org.pkijs.getAlgorithmByOID(
                  i.hashAlgorithm.algorithm_id
                );
                if ("name" in n == !1) return "";
                r = n.name;
              } else r = "SHA-1";
            } catch (s) {}
        }
        return r;
      }),
      (e.org.pkijs.createCMSECDSASignature = function (t) {
        if (t.byteLength % 2 != 0) return new ArrayBuffer(0);
        for (
          var r,
            i,
            n,
            s,
            a = 0,
            o = t.byteLength / 2,
            c = new Uint8Array(t),
            l = new ArrayBuffer(o),
            u = new Uint8Array(l),
            p = new ArrayBuffer(o),
            h = new Uint8Array(p);
          a < o;
          a++
        )
          u[a] = c[a];
        if (128 & u[0]) {
          (r = new ArrayBuffer(o + 1)), (i = new Uint8Array(r)), (i[0] = 0);
          for (var f = 0; f < o; f++) i[f + 1] = u[f];
        } else (r = l), (i = u);
        for (; a < t.byteLength; a++) h[a - o] = c[a];
        if (128 & h[0]) {
          (n = new ArrayBuffer(o + 1)), (s = new Uint8Array(n)), (s[0] = 0);
          for (var f = 0; f < o; f++) s[f + 1] = h[f];
        } else (n = p), (s = h);
        var g = new e.org.pkijs.asn1.INTEGER();
        (g.value_block.is_hex_only = !0),
          (g.value_block.value_hex = e.org.pkijs.copyBuffer(r));
        var m = new e.org.pkijs.asn1.INTEGER();
        (m.value_block.is_hex_only = !0),
          (m.value_block.value_hex = e.org.pkijs.copyBuffer(n));
        var d = new e.org.pkijs.asn1.SEQUENCE({ value: [g, m] });
        return d.toBER(!1);
      }),
      (e.org.pkijs.createECDSASignatureFromCMS = function (e) {
        var t = 0,
          r = 0,
          i = 0,
          n = e.value_block.value[0].value_block.value_hex.byteLength,
          s = e.value_block.value[1].value_block.value_hex.byteLength,
          a = new Uint8Array(e.value_block.value[0].value_block.value_hex);
        0 === a[0] && 128 & a[1] ? ((t = n - 1), (r = 1)) : (t = n);
        var o = new Uint8Array(e.value_block.value[1].value_block.value_hex);
        0 === o[0] && 128 & o[1] ? ((t += s - 1), (i = 1)) : (t += s);
        for (
          var c = new ArrayBuffer(t), l = new Uint8Array(c), u = r;
          u < n;
          u++
        )
          l[u - r] = a[u];
        for (var u = i; u < s; u++) l[u - i + n - r] = o[u];
        return c;
      }),
      (e.org.pkijs.getEncryptionAlgorithm = function (e) {
        var t = "";
        switch (e.name.toUpperCase()) {
          case "AES-CBC":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.2";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.22";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.42";
            }
            break;
          case "AES-GCM":
            switch (e.length) {
              case 128:
                t = "2.16.840.1.101.3.4.1.6";
                break;
              case 192:
                t = "2.16.840.1.101.3.4.1.26";
                break;
              case 256:
                t = "2.16.840.1.101.3.4.1.46";
            }
        }
        return t;
      }),
      (e.org.pkijs.getAlgorithmByEncryptionOID = function (e) {
        var t = "";
        switch (e) {
          case "2.16.840.1.101.3.4.1.2":
          case "2.16.840.1.101.3.4.1.22":
          case "2.16.840.1.101.3.4.1.42":
            t = "AES-CBC";
            break;
          case "2.16.840.1.101.3.4.1.6":
          case "2.16.840.1.101.3.4.1.26":
          case "2.16.840.1.101.3.4.1.46":
            t = "AES-GCM";
        }
        return t;
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.schema) e.org.pkijs.schema = {};
    else if ("object" != typeof e.org.pkijs.schema)
      throw new Error(
        "Name org.pkijs.schema already exists and it's not an object but " +
          typeof e.org.pkijs.schema
      );
    if ("undefined" == typeof e.org.pkijs.schema.ocsp)
      e.org.pkijs.schema.ocsp = {};
    else if ("object" != typeof e.org.pkijs.schema.ocsp)
      throw new Error(
        "Name org.pkijs.schema.ocsp already exists and it's not an object but " +
          typeof e.org.pkijs.schema.ocsp
      );
    if ("undefined" == typeof e.org.pkijs.schema.tsp)
      e.org.pkijs.schema.tsp = {};
    else if ("object" != typeof e.org.pkijs.schema.tsp)
      throw new Error(
        "Name org.pkijs.schema.tsp already exists and it's not an object but " +
          typeof e.org.pkijs.schema.tsp
      );
    (e.org.pkijs.schema.ocsp.CertID = function () {
      var t = e.org.pkijs.getNames(arguments[0]);
      return new e.org.pkijs.asn1.SEQUENCE({
        name: t.block_name || "",
        value: [
          e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
            t.signature || { names: { block_name: t.hashAlgorithm || "" } }
          ),
          new e.org.pkijs.asn1.OCTETSTRING({ name: t.issuerNameHash || "" }),
          new e.org.pkijs.asn1.OCTETSTRING({ name: t.issuerKeyHash || "" }),
          new e.org.pkijs.asn1.INTEGER({ name: t.serialNumber || "" }),
        ],
      });
    }),
      (e.org.pkijs.schema.ocsp.Request = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ocsp.CertID(t.reqCert || {}),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.EXTENSIONS(
                  t.extensions || {
                    names: { block_name: t.singleRequestExtensions || "" },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.TBSRequest = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "TBSRequest",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.INTEGER({
                  name: t.TBSRequest_version || "TBSRequest.version",
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                e.org.pkijs.schema.GENERAL_NAME(
                  t.requestorName || {
                    names: { block_name: "TBSRequest.requestorName" },
                  }
                ),
              ],
            }),
            new e.org.pkijs.asn1.SEQUENCE({
              name: t.requestList || "TBSRequest.requestList",
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.requests || "TBSRequest.requests",
                  value: e.org.pkijs.schema.ocsp.Request(t.requestNames || {}),
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
              value: [
                e.org.pkijs.schema.EXTENSIONS(
                  t.extensions || {
                    names: {
                      block_name:
                        t.requestExtensions || "TBSRequest.requestExtensions",
                    },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.Signature = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.signatureAlgorithm || {}),
            new e.org.pkijs.asn1.BITSTRING({ name: t.signature || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.SEQUENCE({
                  value: [
                    new e.org.pkijs.asn1.REPEATED({
                      name: t.certs || "",
                      value: e.org.pkijs.schema.CERT(t.certs || {}),
                    }),
                  ],
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.OCSP_REQUEST = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "OCSPRequest",
          value: [
            e.org.pkijs.schema.ocsp.TBSRequest(
              t.tbsRequest || { names: { block_name: "tbsRequest" } }
            ),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.ocsp.Signature(
                  t.optionalSignature || {
                    names: { block_name: "optionalSignature" },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.ResponderID = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                e.org.pkijs.schema.RDN(
                  t.byName || { names: { block_name: "" } }
                ),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.block_name || "",
              id_block: { tag_class: 3, tag_number: 2 },
              value: [
                new e.org.pkijs.asn1.OCTETSTRING({ name: t.byKey || "" }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.ResponseBytes = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.responseType || "" }),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.response || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.OCSP_RESPONSE = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "OCSPResponse",
          value: [
            new e.org.pkijs.asn1.ENUMERATED({
              name: t.responseStatus || "responseStatus",
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.ocsp.ResponseBytes(
                  t.responseBytes || { names: { block_name: "responseBytes" } }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.SingleResponse = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ocsp.CertID(t.certID || {}),
            new e.org.pkijs.asn1.CHOICE({
              value: [
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: t.certStatus || "",
                  id_block: { tag_class: 3, tag_number: 0 },
                  len_block_length: 1,
                }),
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  name: t.certStatus || "",
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [
                    new e.org.pkijs.asn1.GENERALIZEDTIME(),
                    new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                      optional: !0,
                      id_block: { tag_class: 3, tag_number: 0 },
                      value: [new e.org.pkijs.asn1.ENUMERATED()],
                    }),
                  ],
                }),
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: t.certStatus || "",
                  id_block: { tag_class: 3, tag_number: 2 },
                  len_block: { length: 1 },
                }),
              ],
            }),
            new e.org.pkijs.asn1.GENERALIZEDTIME({ name: t.thisUpdate || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.GENERALIZEDTIME({
                  name: t.nextUpdate || "",
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [e.org.pkijs.schema.EXTENSIONS(t.singleExtensions || {})],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ocsp.ResponseData = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "ResponseData",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.INTEGER({
                  name: t.version || "ResponseData.version",
                }),
              ],
            }),
            new e.org.pkijs.asn1.CHOICE({
              value: [
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  name: t.responderID || "ResponseData.responderID",
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [
                    e.org.pkijs.schema.RDN(
                      t.ResponseData_byName || {
                        names: { block_name: "ResponseData.byName" },
                      }
                    ),
                  ],
                }),
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  name: t.responderID || "ResponseData.responderID",
                  id_block: { tag_class: 3, tag_number: 2 },
                  value: [
                    new e.org.pkijs.asn1.OCTETSTRING({
                      name: t.ResponseData_byKey || "ResponseData.byKey",
                    }),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.GENERALIZEDTIME({
              name: t.producedAt || "ResponseData.producedAt",
            }),
            new e.org.pkijs.asn1.SEQUENCE({
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: "ResponseData.responses",
                  value: e.org.pkijs.schema.ocsp.SingleResponse(
                    t.response || {}
                  ),
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                e.org.pkijs.schema.EXTENSIONS(
                  t.extensions || {
                    names: { block_name: "ResponseData.responseExtensions" },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.OCSP_BASIC_RESPONSE = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "BasicOCSPResponse",
          value: [
            e.org.pkijs.schema.ocsp.ResponseData(
              t.tbsResponseData || {
                names: { block_name: "BasicOCSPResponse.tbsResponseData" },
              }
            ),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.signatureAlgorithm || {
                names: { block_name: "BasicOCSPResponse.signatureAlgorithm" },
              }
            ),
            new e.org.pkijs.asn1.BITSTRING({
              name: t.signature || "BasicOCSPResponse.signature",
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.SEQUENCE({
                  value: [
                    new e.org.pkijs.asn1.REPEATED({
                      name: "BasicOCSPResponse.certs",
                      value: e.org.pkijs.schema.CERT(t.certs || {}),
                    }),
                  ],
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.tsp.MessageImprint = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.hashAlgorithm || {}),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.hashedMessage || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.TSP_REQUEST = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "TimeStampReq",
          value: [
            new e.org.pkijs.asn1.INTEGER({
              name: t.version || "TimeStampReq.version",
            }),
            e.org.pkijs.schema.tsp.MessageImprint(
              t.messageImprint || {
                names: { block_name: "TimeStampReq.messageImprint" },
              }
            ),
            new e.org.pkijs.asn1.OID({
              name: t.reqPolicy || "TimeStampReq.reqPolicy",
              optional: !0,
            }),
            new e.org.pkijs.asn1.INTEGER({
              name: t.nonce || "TimeStampReq.nonce",
              optional: !0,
            }),
            new e.org.pkijs.asn1.BOOLEAN({
              name: t.certReq || "TimeStampReq.certReq",
              optional: !0,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.extensions || "TimeStampReq.extensions",
                  value: e.org.pkijs.schema.EXTENSION(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.tsp.Accuracy = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          optional: !0,
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.seconds || "" }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.millis || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.micros || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.TST_INFO = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "TSTInfo",
          value: [
            new e.org.pkijs.asn1.INTEGER({
              name: t.version || "TSTInfo.version",
            }),
            new e.org.pkijs.asn1.OID({ name: t.policy || "TSTInfo.policy" }),
            e.org.pkijs.schema.tsp.MessageImprint(
              t.messageImprint || {
                names: { block_name: "TSTInfo.messageImprint" },
              }
            ),
            new e.org.pkijs.asn1.INTEGER({
              name: t.serialNumber || "TSTInfo.serialNumber",
            }),
            new e.org.pkijs.asn1.GENERALIZEDTIME({
              name: t.genTime || "TSTInfo.genTime",
            }),
            e.org.pkijs.schema.tsp.Accuracy(
              t.accuracy || { names: { block_name: "TSTInfo.accuracy" } }
            ),
            new e.org.pkijs.asn1.BOOLEAN({
              name: t.ordering || "TSTInfo.ordering",
              optional: !0,
            }),
            new e.org.pkijs.asn1.INTEGER({
              name: t.nonce || "TSTInfo.nonce",
              optional: !0,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.GENERAL_NAME(
                  t.tsa || { names: { block_name: "TSTInfo.tsa" } }
                ),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.extensions || "TSTInfo.extensions",
                  value: e.org.pkijs.schema.EXTENSION(t.extension || {}),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.tsp.PKIStatusInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.status || "" }),
            new e.org.pkijs.asn1.SEQUENCE({
              optional: !0,
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.statusStrings || "",
                  value: new e.org.pkijs.asn1.UTF8STRING(),
                }),
              ],
            }),
            new e.org.pkijs.asn1.BITSTRING({
              name: t.failInfo || "",
              optional: !0,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.TSP_RESPONSE = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "TimeStampResp",
          value: [
            e.org.pkijs.schema.tsp.PKIStatusInfo(
              t.status || { names: { block_name: "TimeStampResp.status" } }
            ),
            e.org.pkijs.schema.CMS_CONTENT_INFO(
              t.timeStampToken || {
                names: { block_name: "TimeStampResp.timeStampToken" },
              },
              !0
            ),
          ],
        });
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.simpl) e.org.pkijs.simpl = {};
    else if ("object" != typeof e.org.pkijs.simpl)
      throw new Error(
        "Name org.pkijs.simpl already exists and it's not an object but " +
          typeof e.org.pkijs.simpl
      );
    if ("undefined" == typeof e.org.pkijs.simpl.ocsp)
      e.org.pkijs.simpl.ocsp = {};
    else if ("object" != typeof e.org.pkijs.simpl.ocsp)
      throw new Error(
        "Name org.pkijs.simpl.ocsp already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.ocsp
      );
    if ("undefined" == typeof e.org.pkijs.simpl.tsp) e.org.pkijs.simpl.tsp = {};
    else if ("object" != typeof e.org.pkijs.simpl.tsp)
      throw new Error(
        "Name org.pkijs.simpl.tsp already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.tsp
      );
    (e.org.pkijs.simpl.ocsp.CertID = function () {
      (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
        (this.issuerNameHash = new e.org.pkijs.asn1.OCTETSTRING()),
        (this.issuerKeyHash = new e.org.pkijs.asn1.OCTETSTRING()),
        (this.serialNumber = new e.org.pkijs.asn1.INTEGER()),
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.ocsp.CertID.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ((this.hashAlgorithm =
              arguments[0].hashAlgorithm ||
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
            (this.issuerNameHash =
              arguments[0].issuerNameHash ||
              new e.org.pkijs.asn1.OCTETSTRING()),
            (this.issuerKeyHash =
              arguments[0].issuerKeyHash || new e.org.pkijs.asn1.OCTETSTRING()),
            (this.serialNumber =
              arguments[0].serialNumber || new e.org.pkijs.asn1.INTEGER()));
    }),
      (e.org.pkijs.simpl.ocsp.CertID.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.CertID({
            names: {
              hashAlgorithm: "hashAlgorithm",
              issuerNameHash: "issuerNameHash",
              issuerKeyHash: "issuerKeyHash",
              serialNumber: "serialNumber",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CertID"
          );
        (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.hashAlgorithm,
        })),
          (this.issuerNameHash = r.result.issuerNameHash),
          (this.issuerKeyHash = r.result.issuerKeyHash),
          (this.serialNumber = r.result.serialNumber);
      }),
      (e.org.pkijs.simpl.ocsp.CertID.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            this.hashAlgorithm.toSchema(),
            this.issuerNameHash,
            this.issuerKeyHash,
            this.serialNumber,
          ],
        });
      }),
      (e.org.pkijs.simpl.ocsp.CertID.prototype.toJSON = function () {
        return {
          hashAlgorithm: this.hashAlgorithm.toJSON(),
          issuerNameHash: this.issuerNameHash.toJSON(),
          issuerKeyHash: this.issuerKeyHash.toJSON(),
          serialNumber: this.serialNumber.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.ocsp.Request = function () {
        (this.reqCert = new e.org.pkijs.simpl.ocsp.CertID()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.Request.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.reqCert =
                arguments[0].reqCert || new e.org.pkijs.simpl.ocsp.CertID()),
              "singleRequestExtensions" in arguments[0] &&
                (this.singleRequestExtensions =
                  arguments[0].singleRequestExtensions));
      }),
      (e.org.pkijs.simpl.ocsp.Request.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.Request({
            names: {
              reqCert: { names: { block_name: "reqCert" } },
              singleRequestExtensions: {
                names: { block_name: "singleRequestExtensions" },
              },
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for Request"
          );
        if (
          ((this.reqCert = new e.org.pkijs.simpl.ocsp.CertID({
            schema: r.result.reqCert,
          })),
          "singleRequestExtensions" in r.result)
        ) {
          this.singleRequestExtensions = new Array();
          for (
            var i = r.result.singleRequestExtensions.value_block.value, n = 0;
            n < i.length;
            n++
          )
            this.singleRequestExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({
                schema: i[n],
              })
            );
        }
      }),
      (e.org.pkijs.simpl.ocsp.Request.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(this.reqCert.toSchema()), "singleRequestExtensions" in this)
        ) {
          for (
            var r = new Array(), i = 0;
            i < this.singleRequestExtensions.length;
            i++
          )
            r.push(this.singleRequestExtensions[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: r })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.ocsp.Request.prototype.toJSON = function () {
        var e = { reqCert: this.reqCert.toJSON() };
        if ("singleRequestExtensions" in this) {
          e.singleRequestExtensions = new Array();
          for (var t = 0; t < this.singleRequestExtensions.length; t++)
            e.singleRequestExtensions.push(
              this.singleRequestExtensions[t].toJSON()
            );
        }
        return e;
      }),
      (e.org.pkijs.simpl.ocsp.TBSRequest = function () {
        (this.tbs = new ArrayBuffer(0)),
          (this.requestList = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.TBSRequest.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbs = arguments[0].tbs || new ArrayBuffer(0)),
              "version" in arguments[0] &&
                (this.version = arguments[0].version),
              "requestorName" in arguments[0] &&
                (this.requestorName = arguments[0].requestorName),
              (this.requestList = arguments[0].requestList || new Array()),
              "requestExtensions" in arguments[0] &&
                (this.requestExtensions = arguments[0].requestExtensions));
      }),
      (e.org.pkijs.simpl.ocsp.TBSRequest.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.TBSRequest()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for TBSRequest"
          );
        (this.tbs = r.result.TBSRequest.value_before_decode),
          "TBSRequest.version" in r.result &&
            (this.version =
              r.result["TBSRequest.version"].value_block.value_dec),
          "TBSRequest.requestorName" in r.result &&
            (this.requestorName = new e.org.pkijs.simpl.GENERAL_NAME({
              schema: r.result["TBSRequest.requestorName"],
            }));
        for (var i = r.result["TBSRequest.requests"], n = 0; n < i.length; n++)
          this.requestList.push(
            new e.org.pkijs.simpl.ocsp.Request({ schema: i[n] })
          );
        if ("TBSRequest.requestExtensions" in r.result) {
          this.requestExtensions = new Array();
          for (
            var s = r.result["TBSRequest.requestExtensions"].value_block.value,
              n = 0;
            n < s.length;
            n++
          )
            this.requestExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: s[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.ocsp.TBSRequest.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r;
        if (t === !1) {
          if (0 === this.tbs.byteLength)
            return e.org.pkijs.schema.ocsp.TBSRequest();
          r = e.org.pkijs.fromBER(this.tbs).result;
        } else {
          var i = new Array();
          "version" in this &&
            i.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: [new e.org.pkijs.asn1.INTEGER({ value: this.version })],
              })
            ),
            "requestorName" in this &&
              i.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [this.requestorName.toSchema()],
                })
              );
          for (var n = new Array(), s = 0; s < this.requestList.length; s++)
            n.push(this.requestList[s].toSchema());
          if (
            (i.push(new e.org.pkijs.asn1.SEQUENCE({ value: n })),
            "requestExtensions" in this)
          ) {
            for (
              var a = new Array(), o = 0;
              o < this.requestExtensions.length;
              o++
            )
              a.push(this.requestExtensions[o].toSchema());
            i.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 2 },
                value: [new e.org.pkijs.asn1.SEQUENCE({ value: a })],
              })
            );
          }
          r = new e.org.pkijs.asn1.SEQUENCE({ value: i });
        }
        return r;
      }),
      (e.org.pkijs.simpl.ocsp.TBSRequest.prototype.toJSON = function () {
        var e = {};
        "version" in this && (e.version = this.version),
          "requestorName" in this &&
            (e.requestorName = this.requestorName.toJSON()),
          (e.requestList = new Array());
        for (var t = 0; t < this.requestList.length; t++)
          e.requestList.push(this.requestList[t].toJSON());
        if ("requestExtensions" in this) {
          e.requestExtensions = new Array();
          for (var t = 0; t < this.requestExtensions.length; t++)
            e.requestExtensions.push(this.requestExtensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.ocsp.Signature = function () {
        (this.signatureAlgorithm =
          new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signature = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.Signature.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.signatureAlgorithm =
                arguments[0].signatureAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signature =
                arguments[0].signature || new e.org.pkijs.asn1.BITSTRING()),
              "certs" in arguments[0] && (this.certs = arguments[0].certs));
      }),
      (e.org.pkijs.simpl.ocsp.Signature.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.Signature({
            names: {
              signatureAlgorithm: {
                names: { block_name: "signatureAlgorithm" },
              },
              signature: "signature",
              certs: "certs",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ocsp.Signature"
          );
        if (
          ((this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.signatureAlgorithm,
            })),
          (this.signature = r.result.signature),
          "certs" in r.result)
        ) {
          this.certs = new Array();
          for (var i = r.result.certs, n = 0; n < i; n++)
            this.certs.push(new e.org.pkijs.simpl.CERT({ schema: i[n] }));
        }
      }),
      (e.org.pkijs.simpl.ocsp.Signature.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(this.signatureAlgorithm.toSchema()),
          t.push(this.signature),
          "certs" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.certs.length; i++)
            r.push(this.certs[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: r })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.ocsp.Signature.prototype.toJSON = function () {
        var e = {
          signatureAlgorithm: this.signatureAlgorithm.toJSON(),
          signature: this.signature.toJSON(),
        };
        if ("certs" in this) {
          e.certs = new Array();
          for (var t = 0; t < this.certs.length; t++)
            e.certs.push(this.certs[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST = function () {
        (this.tbsRequest = new e.org.pkijs.simpl.ocsp.TBSRequest()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.OCSP_REQUEST.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbsRequest =
                arguments[0].tbsRequest ||
                new e.org.pkijs.simpl.ocsp.TBSRequest()),
              "optionalSignature" in arguments[0] &&
                (this.optionalSignature = arguments[0].optionalSignature));
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.OCSP_REQUEST()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OCSP_REQUEST"
          );
        (this.tbsRequest = new e.org.pkijs.simpl.ocsp.TBSRequest({
          schema: r.result.tbsRequest,
        })),
          "optionalSignature" in r.result &&
            (this.optionalSignature = new e.org.pkijs.simpl.ocsp.Signature({
              schema: r.result.optionalSignature,
            }));
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r = new Array();
        return (
          r.push(this.tbsRequest.toSchema(t)),
          "optionalSignature" in this &&
            r.push(this.optionalSignature.toSchema()),
          new e.org.pkijs.asn1.SEQUENCE({ value: r })
        );
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST.prototype.sign = function (t, r) {
        var i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if ("undefined" == typeof r) r = "SHA-1";
        else {
          var n = e.org.pkijs.getOIDByAlgorithm({ name: r });
          if ("" === n)
            return new Promise(function (e, t) {
              t("Unsupported hash algorithm: " + r);
            });
        }
        if ("optionalSignature" in this == !1)
          return new Promise(function (e, t) {
            t('Need to create "optionalSignature" field before signing');
          });
        var s = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((s.algorithm.hash.name = r), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            i.optionalSignature.signatureAlgorithm.algorithm_id =
              e.org.pkijs.getOIDByAlgorithm(s.algorithm);
            break;
          case "RSA-PSS":
            switch (r.toUpperCase()) {
              case "SHA-256":
                s.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                s.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                s.algorithm.saltLength = 64;
            }
            var a = {};
            if ("SHA-1" !== r.toUpperCase()) {
              var o = e.org.pkijs.getOIDByAlgorithm({ name: r });
              if ("" === o)
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + r);
                });
              (a.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: o,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (a.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: a.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== s.algorithm.saltLength &&
              (a.saltLength = s.algorithm.saltLength);
            var c = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(a);
            i.optionalSignature.signatureAlgorithm =
              new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: "1.2.840.113549.1.1.10",
                algorithm_params: c.toSchema(),
              });
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        var l = this.tbsRequest.toSchema(!0).toBER(!1),
          u = e.org.pkijs.getCrypto();
        return "undefined" == typeof u
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : u.sign(s.algorithm, t, new Uint8Array(l)).then(
              function (t) {
                "ECDSA" === s.algorithm.name &&
                  (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (i.optionalSignature.signature =
                    new e.org.pkijs.asn1.BITSTRING({ value_hex: t }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST.prototype.toJSON = function () {
        var e = {};
        "version" in this && (e.version = this.version),
          "requestorName" in this &&
            (e.requestorName = this.requestorName.toJSON()),
          (e.requestList = new Array());
        for (var t = 0; t < this.requestList.length; t++)
          e.requestList.push(this.requestList[t].toJSON());
        if ("requestExtensions" in this) {
          e.requestExtensions = new Array();
          for (var t = 0; t < this.requestExtensions.length; t++)
            e.requestExtensions.push(this.requestExtensions[t].toJSON());
        }
        return (
          "optionalSignature" in this &&
            (e.optionalSignature = this.optionalSignature.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.OCSP_REQUEST.prototype.createForCertificate =
        function (t, r) {
          var i,
            n,
            s,
            a,
            o,
            c = this,
            l = Promise.resolve(),
            u = e.org.pkijs.getCrypto();
          return "undefined" == typeof u
            ? new Promise(function (e, t) {
                t("Unable to create WebCrypto object");
              })
            : "hashAlgorithm" in r
            ? ((i = r.hashAlgorithm),
              (n = e.org.pkijs.getOIDByAlgorithm({ name: i })),
              "" === n
                ? new Promise(function (e, t) {
                    t('Incorrect "hashAlgorithm": ' + i);
                  })
                : "issuerCertificate" in r
                ? ((o = r.issuerCertificate),
                  (l = l.then(
                    function (e) {
                      var t = o.subject.toSchema().toBER(!1);
                      return u.digest({ name: i }, t);
                    },
                    function (e) {
                      return new Promise(function (t, r) {
                        r(e);
                      });
                    }
                  )),
                  (l = l.then(
                    function (e) {
                      s = e;
                      var t =
                        o.subjectPublicKeyInfo.subjectPublicKey.value_block
                          .value_hex;
                      return u.digest({ name: i }, t);
                    },
                    function (e) {
                      return new Promise(function (t, r) {
                        r(e);
                      });
                    }
                  )),
                  (l = l.then(
                    function (r) {
                      (a = r),
                        (c.tbsRequest = new e.org.pkijs.simpl.ocsp.TBSRequest({
                          requestList: [
                            new e.org.pkijs.simpl.ocsp.Request({
                              reqCert: new e.org.pkijs.simpl.ocsp.CertID({
                                hashAlgorithm:
                                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                                    algorithm_id: n,
                                    algorithm_params:
                                      new e.org.pkijs.asn1.NULL(),
                                  }),
                                issuerNameHash:
                                  new e.org.pkijs.asn1.OCTETSTRING({
                                    value_hex: s,
                                  }),
                                issuerKeyHash: new e.org.pkijs.asn1.OCTETSTRING(
                                  { value_hex: a }
                                ),
                                serialNumber: t.serialNumber,
                              }),
                            }),
                          ],
                        }));
                    },
                    function (e) {
                      return new Promise(function (t, r) {
                        r(e);
                      });
                    }
                  )))
                : new Promise(function (e, t) {
                    t(
                      'Parameter "issuerCertificate" is mandatory for "OCSP_REQUEST.createForCertificate"'
                    );
                  }))
            : new Promise(function (e, t) {
                t(
                  'Parameter "hashAlgorithm" is mandatory for "OCSP_REQUEST.createForCertificate"'
                );
              });
        }),
      (e.org.pkijs.simpl.ocsp.ResponseBytes = function () {
        (this.responseType = ""),
          (this.response = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.ResponseBytes.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.responseType = arguments[0].responseType || ""),
              (this.response =
                arguments[0].response || new e.org.pkijs.asn1.OCTETSTRING()));
      }),
      (e.org.pkijs.simpl.ocsp.ResponseBytes.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.ResponseBytes({
            names: { responseType: "responseType", response: "response" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ResponseBytes"
          );
        (this.responseType = r.result.responseType.value_block.toString()),
          (this.response = r.result.response);
      }),
      (e.org.pkijs.simpl.ocsp.ResponseBytes.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.OID({ value: this.responseType }),
            this.response,
          ],
        });
      }),
      (e.org.pkijs.simpl.ocsp.ResponseBytes.prototype.toJSON = function () {
        return {
          responseType: this.responseType,
          response: this.response.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE = function () {
        (this.responseStatus = new e.org.pkijs.asn1.ENUMERATED()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.OCSP_RESPONSE.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.responseStatus =
                arguments[0].responseStatus ||
                new e.org.pkijs.asn1.ENUMERATED()),
              "responseBytes" in arguments[0] &&
                (this.responseBytes = arguments[0].responseBytes));
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.OCSP_RESPONSE()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OCSP_RESPONSE"
          );
        (this.responseStatus = r.result.responseStatus),
          "responseBytes" in r.result &&
            (this.responseBytes = new e.org.pkijs.simpl.ocsp.ResponseBytes({
              schema: r.result.responseBytes,
            }));
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(this.responseStatus),
          "responseBytes" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: [this.responseBytes.toSchema()],
              })
            ),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE.prototype.verify = function () {
        var t = this;
        if ("responseBytes" in this == !1)
          return new Promise(function (e, t) {
            t("Empty ResponseBytes field");
          });
        if ("1.3.6.1.5.5.7.48.1.1" === this.responseBytes.responseType) {
          var r = e.org.pkijs.fromBER(
              this.responseBytes.response.value_block.value_hex
            ),
            i = new e.org.pkijs.simpl.OCSP_BASIC_RESPONSE({ schema: r.result });
          return i.verify();
        }
        return new Promise(function (e, r) {
          r("Unknown ResponseBytes type: " + t.responseBytes.responseType);
        });
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE.prototype.sign = function (t, r) {
        var i = this;
        if ("1.3.6.1.5.5.7.48.1.1" === this.responseBytes.responseType) {
          var n = e.org.pkijs.fromBER(
              this.responseBytes.response.value_block.value_hex
            ),
            s = new e.org.pkijs.simpl.OCSP_BASIC_RESPONSE({ schema: n.result });
          return s.sign(t, r);
        }
        return new Promise(function (e, t) {
          t("Unknown ResponseBytes type: " + i.responseBytes.responseType);
        });
      }),
      (e.org.pkijs.simpl.OCSP_RESPONSE.prototype.toJSON = function () {
        var e = { responseStatus: this.responseStatus.toJSON() };
        return (
          "responseBytes" in this &&
            (e.responseBytes = this.responseBytes.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.ocsp.SingleResponse = function () {
        (this.certID = new e.org.pkijs.simpl.ocsp.CertID()),
          (this.certStatus = new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
            id_block: { tag_class: 3, tag_number: 3 },
            value: [],
          })),
          (this.thisUpdate = new Date(0, 0, 0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.SingleResponse.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.certID =
                arguments[0].certID || new e.org.pkijs.simpl.ocsp.CertID()),
              (this.certStatus =
                arguments[0].certStatus ||
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 3 },
                  value: [],
                })),
              (this.thisUpdate = arguments[0].thisUpdate || new Date(0, 0, 0)),
              "nextUpdate" in arguments[0] &&
                (this.nextUpdate = arguments[0].nextUpdate),
              "singleExtensions" in arguments[0] &&
                (this.singleExtensions = arguments[0].singleExtensions));
      }),
      (e.org.pkijs.simpl.ocsp.SingleResponse.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.SingleResponse({
            names: {
              certID: { names: { block_name: "certID" } },
              certStatus: "certStatus",
              thisUpdate: "thisUpdate",
              nextUpdate: "nextUpdate",
              singleExtensions: "singleExtensions",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for SingleResponse"
          );
        if (
          ((this.certID = new e.org.pkijs.simpl.ocsp.CertID({
            schema: r.result.certID,
          })),
          (this.certStatus = r.result.certStatus),
          (this.thisUpdate = r.result.thisUpdate.toDate()),
          "nextUpdate" in r.result &&
            (this.nextUpdate = r.result.nextUpdate.toDate()),
          "singleExtensions" in r.result)
        ) {
          this.singleExtensions = new Array();
          for (
            var i = r.result.singleExtensions.value_block.value, n = 0;
            n < i.length;
            n++
          )
            this.singleExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: i[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.ocsp.SingleResponse.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(this.certID.toSchema()),
          t.push(this.certStatus),
          t.push(
            new e.org.pkijs.asn1.GENERALIZEDTIME({
              value_date: this.thisUpdate,
            })
          ),
          "nextUpdate" in this &&
            t.push(
              new e.org.pkijs.asn1.GENERALIZEDTIME({
                value_date: this.nextUpdate,
              })
            ),
          "singleExtensions" in this)
        ) {
          for (
            var r = new Array(), i = 0;
            i < this.singleExtensions.length;
            i++
          )
            r.push(this.singleExtensions[i].toSchema());
          t.push(new e.org.pkijs.asn1.SEQUENCE({ value: r }));
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.ocsp.SingleResponse.prototype.toJSON = function () {
        var e = {
          certID: this.certID.toJSON(),
          certStatus: this.certStatus.toJSON(),
          thisUpdate: this.thisUpdate,
        };
        if (
          ("nextUpdate" in this && (e.nextUpdate = this.nextUpdate),
          "singleExtensions" in this)
        ) {
          e.singleExtensions = new Array();
          for (var t = 0; t < this.singleExtensions.length; t++)
            e.singleExtensions.push(this.singleExtensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.ocsp.ResponseData = function () {
        (this.tbs = new ArrayBuffer(0)),
          (this.responderID = new e.org.pkijs.simpl.RDN()),
          (this.producedAt = new Date(0, 0, 0)),
          (this.responses = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ocsp.ResponseData.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ("version" in arguments[0] &&
                (this.version = arguments[0].version),
              (this.responderID =
                arguments[0].responderID ||
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 10 },
                  value: [],
                })),
              (this.producedAt = arguments[0].producedAt || new Date(0, 0, 0)),
              (this.responses = arguments[0].responses || new Array()),
              "responseExtensions" in arguments[0] &&
                (this.responseExtensions = arguments[0].responseExtensions));
      }),
      (e.org.pkijs.simpl.ocsp.ResponseData.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ocsp.ResponseData()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ocsp.ResponseData"
          );
        (this.tbs = r.result.ResponseData.value_before_decode),
          "ResponseData.version" in r.result &&
            (this.version =
              r.result["ResponseData.version"].value_block.value_dec),
          1 === r.result["ResponseData.responderID"].id_block.tag_number
            ? (this.responderID = new e.org.pkijs.simpl.RDN({
                schema:
                  r.result["ResponseData.responderID"].value_block.value[0],
              }))
            : (this.responderID =
                r.result["ResponseData.responderID"].value_block.value[0]),
          (this.producedAt = r.result["ResponseData.producedAt"].toDate());
        for (
          var i = r.result["ResponseData.responses"], n = 0;
          n < i.length;
          n++
        )
          this.responses.push(
            new e.org.pkijs.simpl.ocsp.SingleResponse({ schema: i[n] })
          );
        if ("ResponseData.responseExtensions" in r.result) {
          this.responseExtensions = new Array();
          for (
            var s =
                r.result["ResponseData.responseExtensions"].value_block.value,
              n = 0;
            n < s.length;
            n++
          )
            this.responseExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: s[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.ocsp.ResponseData.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r;
        if (t === !1) {
          if (0 === this.tbs.length)
            return e.org.pkijs.schema.ocsp.ResponseData();
          r = e.org.pkijs.fromBER(this.tbs).result;
        } else {
          var i = new Array();
          "version" in this &&
            i.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: [new e.org.pkijs.asn1.INTEGER({ value: this.version })],
              })
            ),
            this.responderID instanceof e.org.pkijs.simpl.RDN
              ? i.push(
                  new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                    id_block: { tag_class: 3, tag_number: 1 },
                    value: [this.responderID.toSchema()],
                  })
                )
              : i.push(
                  new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                    id_block: { tag_class: 3, tag_number: 2 },
                    value: [this.responderID],
                  })
                ),
            i.push(
              new e.org.pkijs.asn1.GENERALIZEDTIME({
                value_date: this.producedAt,
              })
            );
          for (var n = new Array(), s = 0; s < this.responses.length; s++)
            n.push(this.responses[s].toSchema());
          if (
            (i.push(new e.org.pkijs.asn1.SEQUENCE({ value: n })),
            "responseExtensions" in this)
          ) {
            for (
              var a = new Array(), o = 0;
              o < this.responseExtensions.length;
              o++
            )
              a.push(this.responseExtensions[o].toSchema());
            i.push(new e.org.pkijs.asn1.SEQUENCE({ value: a }));
          }
          r = new e.org.pkijs.asn1.SEQUENCE({ value: i });
        }
        return r;
      }),
      (e.org.pkijs.simpl.ocsp.ResponseData.prototype.toJSON = function () {
        var e = {};
        if (
          ("version" in this && (e.version = this.version),
          "responderID" in this && (e.responderID = this.responderID),
          "producedAt" in this && (e.producedAt = this.producedAt),
          "responses" in this)
        ) {
          e.responses = new Array();
          for (var t = 0; t < this.responses.length; t++)
            e.responses.push(this.responses[t].toJSON());
        }
        if ("responseExtensions" in this) {
          e.responseExtensions = new Array();
          for (var t = 0; t < this.responseExtensions.length; t++)
            e.responseExtensions.push(this.responseExtensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE = function () {
        (this.tbsResponseData = new e.org.pkijs.simpl.ocsp.ResponseData()),
          (this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signature = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbsResponseData =
                arguments[0].tbsResponseData ||
                new e.org.pkijs.simpl.ocsp.ResponseData()),
              (this.signatureAlgorithm =
                arguments[0].signatureAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signature =
                arguments[0].signature || new e.org.pkijs.asn1.BITSTRING()),
              "certs" in arguments[0] && (this.certs = arguments[0].certs));
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.OCSP_BASIC_RESPONSE()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OCSP_BASIC_RESPONSE"
          );
        if (
          ((this.tbsResponseData = new e.org.pkijs.simpl.ocsp.ResponseData({
            schema: r.result["BasicOCSPResponse.tbsResponseData"],
          })),
          (this.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER(
            { schema: r.result["BasicOCSPResponse.signatureAlgorithm"] }
          )),
          (this.signature = r.result["BasicOCSPResponse.signature"]),
          "BasicOCSPResponse.certs" in r.result)
        ) {
          this.certs = new Array();
          for (
            var i = r.result["BasicOCSPResponse.certs"], n = 0;
            n < i.length;
            n++
          )
            this.certs.push(new e.org.pkijs.simpl.CERT({ schema: i[n] }));
        }
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(this.tbsResponseData.toSchema()),
          t.push(this.signatureAlgorithm.toSchema()),
          t.push(this.signature),
          "certs" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.certs.length; i++)
            r.push(this.certs[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: r })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.verify = function () {
        function t(e) {
          if (
            e.issuer.isEqual(i.issuer) === !0 &&
            e.serialNumber.isEqual(i.serialNumber) === !0
          )
            return null;
          for (var t = !1, r = 0; r < e.extensions.length; r++)
            "2.5.29.19" === e.extensions[r].extnID &&
              "cA" in e.extensions[r].parsedValue &&
              e.extensions[r].parsedValue.cA === !0 &&
              (t = !0);
          return t ? e : null;
        }
        if ("certs" in this == !1)
          return new Promise(function (e, t) {
            t("No certificates attached to the BasicOCSPResponce");
          });
        var r = this,
          i = null,
          n = this.certs,
          s =
            (new Uint8Array(this.signature.value_block.value_hex),
            new Uint8Array(this.tbsResponseData.tbs)),
          a = -1,
          o = Promise.resolve(),
          c = "",
          l = new Array();
        arguments[0] instanceof Object &&
          "trusted_certs" in arguments[0] &&
          (l = arguments[0].trusted_certs);
        var u = e.org.pkijs.getCrypto();
        if ("undefined" == typeof u)
          return new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          });
        if (
          ((c = e.org.pkijs.getHashAlgorithm(this.signatureAlgorithm)),
          "" === c)
        )
          return new Promise(function (e, t) {
            t(
              "Unsupported signature algorithm: " +
                r.signatureAlgorithm.algorithm_id
            );
          });
        var p = 0,
          h = {};
        if (this.tbsResponseData.responderID instanceof e.org.pkijs.simpl.RDN)
          (p = 0), (h = this.tbsResponseData.responderID);
        else {
          if (
            !(
              this.tbsResponseData.responderID instanceof
              e.org.pkijs.asn1.OCTETSTRING
            )
          )
            return new Promise(function (e, t) {
              t("Wrong value for responderID");
            });
          (p = 1), (h = this.tbsResponseData.responderID);
        }
        o =
          0 === p
            ? o.then(function () {
                for (var e = 0; e < n.length; e++)
                  if (n[e].subject.isEqual(h)) {
                    a = e;
                    break;
                  }
              })
            : o.then(function () {
                for (var t = new Array(), r = 0; r < n.length; r++)
                  t.push(
                    u.digest(
                      { name: "sha-1" },
                      new Uint8Array(
                        n[
                          r
                        ].subjectPublicKeyInfo.subjectPublicKey.value_block.value_hex
                      )
                    )
                  );
                return Promise.all(t).then(function (t) {
                  for (var r = 0; r < n.length; r++)
                    if (
                      e.org.pkijs.isEqual_buffer(t[r], h.value_block.value_hex)
                    ) {
                      a = r;
                      break;
                    }
                });
              });
        var f = new Array();
        return (
          (o = o.then(function (s) {
            if (a === -1)
              return new Promise(function (e, t) {
                t("Correct certificate was not found in OCSP response");
              });
            i = n[a];
            for (var o = 0; o < r.certs.length; o++) f.push(t(r.certs[o]));
            return Promise.all(f).then(
              function (t) {
                var n = new Array();
                n.push(i);
                for (var s = 0; s < t.length; s++)
                  null !== t[s] && n.push(t[s]);
                var a = new e.org.pkijs.simpl.CERT_CHAIN({
                  certs: n,
                  trusted_certs: l,
                });
                return (
                  "crls" in r && (a.crls = r.crls),
                  a.verify().then(
                    function (e) {
                      return e.result === !0
                        ? new Promise(function (e, t) {
                            e();
                          })
                        : new Promise(function (e, t) {
                            t("Validation of signer's certificate failed");
                          });
                    },
                    function (e) {
                      return new Promise(function (t, r) {
                        r(
                          "Validation of signer's certificate failed with error: " +
                            (e instanceof Object ? e.result_message : e)
                        );
                      });
                    }
                  )
                );
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Error during checking certificates for CA flag: " + e);
                });
              }
            );
          })),
          (o = o.then(function () {
            var t = e.org.pkijs.getAlgorithmByOID(
              n[a].signatureAlgorithm.algorithm_id
            );
            if ("name" in t == !1)
              return new Promise(function (e, t) {
                t(
                  "Unsupported public key algorithm: " +
                    n[a].signatureAlgorithm.algorithm_id
                );
              });
            var r = t.name,
              i = e.org.pkijs.getAlgorithmParameters(r, "importkey");
            "hash" in i.algorithm && (i.algorithm.hash.name = c);
            var s = n[a].subjectPublicKeyInfo.toSchema(),
              o = s.toBER(!1),
              l = new Uint8Array(o);
            return u.importKey("spki", l, i.algorithm, !0, i.usages);
          })),
          (o = o.then(function (t) {
            var i = e.org.pkijs.getAlgorithmParameters(
              t.algorithm.name,
              "verify"
            );
            "hash" in i.algorithm && (i.algorithm.hash.name = c);
            var n = r.signature.value_block.value_hex;
            if ("ECDSA" === t.algorithm.name) {
              var a = e.org.pkijs.fromBER(n);
              n = e.org.pkijs.createECDSASignatureFromCMS(a.result);
            }
            if ("RSA-PSS" === t.algorithm.name) {
              var o;
              try {
                o = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                  schema: r.signatureAlgorithm.algorithm_params,
                });
              } catch (l) {
                return new Promise(function (e, t) {
                  t(l);
                });
              }
              "saltLength" in o
                ? (i.algorithm.saltLength = o.saltLength)
                : (i.algorithm.saltLength = 20);
              var p = "SHA-1";
              if ("hashAlgorithm" in o) {
                var h = e.org.pkijs.getAlgorithmByOID(
                  o.hashAlgorithm.algorithm_id
                );
                if ("name" in h == !1)
                  return new Promise(function (e, t) {
                    t(
                      "Unrecognized hash algorithm: " +
                        o.hashAlgorithm.algorithm_id
                    );
                  });
                p = h.name;
              }
              i.algorithm.hash.name = p;
            }
            return u.verify(i.algorithm, t, new Uint8Array(n), s);
          }))
        );
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.sign = function (t, r) {
        var i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if ("undefined" == typeof r) r = "SHA-1";
        else {
          var n = e.org.pkijs.getOIDByAlgorithm({ name: r });
          if ("" === n)
            return new Promise(function (e, t) {
              t("Unsupported hash algorithm: " + r);
            });
        }
        var s = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((s.algorithm.hash.name = r), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            i.signatureAlgorithm.algorithm_id = e.org.pkijs.getOIDByAlgorithm(
              s.algorithm
            );
            break;
          case "RSA-PSS":
            switch (r.toUpperCase()) {
              case "SHA-256":
                s.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                s.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                s.algorithm.saltLength = 64;
            }
            var a = {};
            if ("SHA-1" !== r.toUpperCase()) {
              var o = e.org.pkijs.getOIDByAlgorithm({ name: r });
              if ("" === o)
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + r);
                });
              (a.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: o,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (a.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: a.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== s.algorithm.saltLength &&
              (a.saltLength = s.algorithm.saltLength);
            var c = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(a);
            i.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: "1.2.840.113549.1.1.10",
              algorithm_params: c.toSchema(),
            });
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        i.tbsResponseData.tbs = i.tbsResponseData.toSchema(!0).toBER(!1);
        var l = e.org.pkijs.getCrypto();
        return "undefined" == typeof l
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : l.sign(s.algorithm, t, new Uint8Array(i.tbsResponseData.tbs)).then(
              function (t) {
                "ECDSA" === s.algorithm.name &&
                  (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (i.signature = new e.org.pkijs.asn1.BITSTRING({
                    value_hex: t,
                  }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.OCSP_BASIC_RESPONSE.prototype.toJSON = function () {
        var e = {
          tbsResponseData: this.tbsResponseData.toJSON(),
          signatureAlgorithm: this.signatureAlgorithm.toJSON(),
          signature: this.signature.toJSON(),
        };
        if ("certs" in this) {
          e.certs = new Array();
          for (var t = 0; t < this.certs.length; t++)
            e.certs.push(this.certs[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.tsp.MessageImprint = function () {
        (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.hashedMessage = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.tsp.MessageImprint.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.hashAlgorithm =
                arguments[0].hashAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.hashedMessage =
                arguments[0].hashedMessage ||
                new e.org.pkijs.asn1.OCTETSTRING()));
      }),
      (e.org.pkijs.simpl.tsp.MessageImprint.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.tsp.MessageImprint({
            names: {
              hashAlgorithm: { names: { block_name: "hashAlgorithm" } },
              hashedMessage: "hashedMessage",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for MessageImprint"
          );
        (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.hashAlgorithm,
        })),
          (this.hashedMessage = r.result.hashedMessage);
      }),
      (e.org.pkijs.simpl.tsp.MessageImprint.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [this.hashAlgorithm.toSchema(), this.hashedMessage],
        });
      }),
      (e.org.pkijs.simpl.tsp.MessageImprint.prototype.toJSON = function () {
        return {
          hashAlgorithm: this.hashAlgorithm.toJSON(),
          hashedMessage: this.hashedMessage.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.TSP_REQUEST = function () {
        (this.version = 0),
          (this.messageImprint = new e.org.pkijs.simpl.tsp.MessageImprint()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.TSP_REQUEST.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.messageImprint =
                arguments[0].messageImprint ||
                new e.org.pkijs.simpl.tsp.MessageImprint()),
              "reqPolicy" in arguments[0] &&
                (this.reqPolicy = arguments[0].reqPolicy),
              "nonce" in arguments[0] && (this.nonce = arguments[0].nonce),
              "certReq" in arguments[0] &&
                (this.certReq = arguments[0].certReq),
              "extensions" in arguments[0] &&
                (this.extensions = arguments[0].extensions));
      }),
      (e.org.pkijs.simpl.TSP_REQUEST.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.TSP_REQUEST()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for TSP_REQUEST"
          );
        if (
          ((this.version =
            r.result["TimeStampReq.version"].value_block.value_dec),
          (this.messageImprint = new e.org.pkijs.simpl.tsp.MessageImprint({
            schema: r.result["TimeStampReq.messageImprint"],
          })),
          "TimeStampReq.reqPolicy" in r.result &&
            (this.reqPolicy =
              r.result["TimeStampReq.reqPolicy"].value_block.toString()),
          "TimeStampReq.nonce" in r.result &&
            (this.nonce = r.result["TimeStampReq.nonce"]),
          "TimeStampReq.certReq" in r.result &&
            (this.certReq = r.result["TimeStampReq.certReq"].value_block.value),
          "TimeStampReq.extensions" in r.result)
        ) {
          this.extensions = new Array();
          for (
            var i = r.result["TimeStampReq.extensions"], n = 0;
            n < i.length;
            n++
          )
            this.extensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: i[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.TSP_REQUEST.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(this.messageImprint.toSchema()),
          "reqPolicy" in this &&
            t.push(new e.org.pkijs.asn1.OID({ value: this.reqPolicy })),
          "nonce" in this && t.push(this.nonce),
          "certReq" in this &&
            t.push(new e.org.pkijs.asn1.BOOLEAN({ value: this.certReq })),
          "extensions" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.extensions.length; i++)
            r.push(this.extensions[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: r,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.TSP_REQUEST.prototype.toJSON = function () {
        var e = {
          version: this.version,
          messageImprint: this.messageImprint.toJSON(),
        };
        if (
          ("reqPolicy" in this && (e.reqPolicy = this.reqPolicy),
          "nonce" in this && (e.nonce = this.nonce.toJSON()),
          "certReq" in this && (e.certReq = this.certReq),
          "extensions" in this)
        ) {
          e.extensions = new Array();
          for (var t = 0; t < this.extensions.length; t++)
            e.extensions.push(this.extensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.tsp.Accuracy = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.tsp.Accuracy.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("seconds" in arguments[0] && (this.seconds = arguments[0].seconds),
            "millis" in arguments[0] && (this.millis = arguments[0].millis),
            "micros" in arguments[0] && (this.micros = arguments[0].micros));
      }),
      (e.org.pkijs.simpl.tsp.Accuracy.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.tsp.Accuracy({
            names: { seconds: "seconds", millis: "millis", micros: "micros" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for tsp.Accuracy"
          );
        if (
          ("seconds" in r.result &&
            (this.seconds = r.result.seconds.value_block.value_dec),
          "millis" in r.result)
        ) {
          var i = new e.org.pkijs.asn1.INTEGER({
            value_hex: r.result.millis.value_block.value_hex,
          });
          this.millis = i.value_block.value_dec;
        }
        if ("micros" in r.result) {
          var n = new e.org.pkijs.asn1.INTEGER({
            value_hex: r.result.micros.value_block.value_hex,
          });
          this.micros = n.value_block.value_dec;
        }
      }),
      (e.org.pkijs.simpl.tsp.Accuracy.prototype.toSchema = function () {
        var t = new Array();
        if (
          ("seconds" in this &&
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.seconds })),
          "millis" in this)
        ) {
          var r = new e.org.pkijs.asn1.INTEGER({ value: this.millis });
          t.push(
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              id_block: { tag_class: 3, tag_number: 0 },
              value_hex: r.value_block.value_hex,
            })
          );
        }
        if ("micros" in this) {
          var i = new e.org.pkijs.asn1.INTEGER({ value: this.micros });
          t.push(
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              id_block: { tag_class: 3, tag_number: 1 },
              value_hex: i.value_block.value_hex,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.tsp.Accuracy.prototype.toJSON = function () {
        var e = {};
        return (
          "seconds" in this && (e.seconds = this.seconds),
          "millis" in this && (e.millis = this.millis),
          "micros" in this && (e.micros = this.micros),
          e
        );
      }),
      (e.org.pkijs.simpl.TST_INFO = function () {
        (this.version = 0),
          (this.policy = ""),
          (this.messageImprint = new e.org.pkijs.simpl.tsp.MessageImprint()),
          (this.serialNumber = new e.org.pkijs.asn1.INTEGER()),
          (this.genTime = new Date(0, 0, 0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.TST_INFO.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.policy = arguments[0].policy || ""),
              (this.messageImprint =
                arguments[0].messageImprint ||
                new e.org.pkijs.simpl.tsp.MessageImprint()),
              (this.serialNumber =
                arguments[0].serialNumber || new e.org.pkijs.asn1.INTEGER()),
              (this.genTime = arguments[0].genTime || new Date(0, 0, 0)),
              "accuracy" in arguments[0] &&
                (this.accuracy = arguments[0].accuracy),
              "ordering" in arguments[0] &&
                (this.ordering = arguments[0].ordering),
              "nonce" in arguments[0] && (this.nonce = arguments[0].nonce),
              "tsa" in arguments[0] && (this.tsa = arguments[0].tsa),
              "extensions" in arguments[0] &&
                (this.extensions = arguments[0].extensions));
      }),
      (e.org.pkijs.simpl.TST_INFO.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(t, t, e.org.pkijs.schema.TST_INFO());
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for TST_INFO"
          );
        if (
          ((this.version = r.result["TSTInfo.version"].value_block.value_dec),
          (this.policy = r.result["TSTInfo.policy"].value_block.toString()),
          (this.messageImprint = new e.org.pkijs.simpl.tsp.MessageImprint({
            schema: r.result["TSTInfo.messageImprint"],
          })),
          (this.serialNumber = r.result["TSTInfo.serialNumber"]),
          (this.genTime = r.result["TSTInfo.genTime"].toDate()),
          "TSTInfo.accuracy" in r.result &&
            (this.accuracy = new e.org.pkijs.simpl.tsp.Accuracy({
              schema: r.result["TSTInfo.accuracy"],
            })),
          "TSTInfo.ordering" in r.result &&
            (this.ordering = r.result["TSTInfo.ordering"].value_block.value),
          "TSTInfo.nonce" in r.result &&
            (this.nonce = r.result["TSTInfo.nonce"]),
          "TSTInfo.tsa" in r.result &&
            (this.tsa = new e.org.pkijs.simpl.GENERAL_NAME({
              schema: r.result["TSTInfo.tsa"],
            })),
          "TSTInfo.extensions" in r.result)
        )
          for (var i = r.result["TSTInfo.extensions"], n = 0; n < i.length; n++)
            this.extensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: i[n] })
            );
      }),
      (e.org.pkijs.simpl.TST_INFO.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(new e.org.pkijs.asn1.OID({ value: this.policy })),
          t.push(this.messageImprint.toSchema()),
          t.push(this.serialNumber),
          t.push(
            new e.org.pkijs.asn1.GENERALIZEDTIME({ value_date: this.genTime })
          ),
          "accuracy" in this && t.push(this.accuracy.toSchema()),
          "ordering" in this &&
            t.push(new e.org.pkijs.asn1.BOOLEAN({ value: this.ordering })),
          "nonce" in this && t.push(this.nonce),
          "tsa" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: [this.tsa.toSchema()],
              })
            ),
          "extensions" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.extensions.length; i++)
            r.push(this.extensions[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: r,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.TST_INFO.prototype.toJSON = function () {
        var e = {
          version: this.version,
          policy: this.policy,
          messageImprint: this.messageImprint.toJSON(),
          serialNumber: this.serialNumber.toJSON(),
          genTime: this.genTime,
        };
        if (
          ("accuracy" in this && (e.accuracy = this.accuracy.toJSON()),
          "ordering" in this && (e.ordering = this.ordering),
          "nonce" in this && (e.nonce = this.nonce.toJSON()),
          "tsa" in this && (e.tsa = this.tsa.toJSON()),
          "extensions" in this)
        ) {
          e.extensions = new Array();
          for (var t = 0; t < this.extensions.length; t++)
            e.extensions.push(this.extensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.tsp.PKIStatusInfo = function () {
        (this.status = 2),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.tsp.PKIStatusInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.status = e.org.pkijs.getValue(arguments[0], "status", 2)),
              "statusStrings" in arguments[0] &&
                (this.statusStrings = arguments[0].statusStrings),
              "failInfo" in arguments[0] &&
                (this.failInfo = arguments[0].failInfo));
      }),
      (e.org.pkijs.simpl.tsp.PKIStatusInfo.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.tsp.PKIStatusInfo({
            names: {
              status: "status",
              statusStrings: "statusStrings",
              failInfo: "failInfo",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PKIStatusInfo"
          );
        var i = r.result.status;
        if (
          i.value_block.is_hex_only === !0 ||
          i.value_block.value_dec < 0 ||
          i.value_block.value_dec > 5
        )
          throw new Error('PKIStatusInfo "status" has invalid value');
        (this.status = i.value_block.value_dec),
          "statusStrings" in r.result &&
            (this.statusStrings = r.result.statusStrings),
          "failInfo" in r.result && (this.failInfo = r.result.failInfo);
      }),
      (e.org.pkijs.simpl.tsp.PKIStatusInfo.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(new e.org.pkijs.asn1.INTEGER({ value: this.status })),
          "statusStrings" in this &&
            t.push(
              new e.org.pkijs.asn1.SEQUENCE({
                optional: !0,
                value: this.statusStrings,
              })
            ),
          "failInfo" in this && t.push(this.failInfo),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.tsp.PKIStatusInfo.prototype.toJSON = function () {
        var e = { status: this.status };
        if ("statusStrings" in this) {
          e.statusStrings = new Array();
          for (var t = 0; t < this.statusStrings.length; t++)
            e.statusStrings.push(this.statusStrings[t].toJSON());
        }
        return "failInfo" in this && (e.failInfo = this.failInfo.toJSON()), e;
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE = function () {
        (this.status = new e.org.pkijs.simpl.tsp.PKIStatusInfo()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.TSP_RESPONSE.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.status =
                arguments[0].status ||
                new e.org.pkijs.simpl.tsp.PKIStatusInfo()),
              "timeStampToken" in arguments[0] &&
                (this.timeStampToken = arguments[0].timeStampToken));
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.TSP_RESPONSE()
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for TSP_RESPONSE"
          );
        (this.status = new e.org.pkijs.simpl.tsp.PKIStatusInfo({
          schema: r.result["TimeStampResp.status"],
        })),
          "TimeStampResp.timeStampToken" in r.result &&
            (this.timeStampToken = new e.org.pkijs.simpl.CMS_CONTENT_INFO({
              schema: r.result["TimeStampResp.timeStampToken"],
            }));
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(this.status.toSchema()),
          "timeStampToken" in this && t.push(this.timeStampToken.toSchema()),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE.prototype.verify = function () {
        var t = this;
        if ("timeStampToken" in this == !1)
          return new Promise(function (e, t) {
            t("timeStampToken is absent in TSP response");
          });
        var r = new Array();
        if (
          (arguments[0] instanceof Object &&
            "trusted_certs" in arguments[0] &&
            (r = arguments[0].trusted_certs),
          "1.2.840.113549.1.7.2" !== this.timeStampToken.contentType)
        )
          return new Promise(function (e, r) {
            r(
              "Wrong format of timeStampToken: " + t.timeStampToken.contentType
            );
          });
        var i = new e.org.pkijs.simpl.CMS_SIGNED_DATA({
          schema: this.timeStampToken.content,
        });
        return i.verify({ signer: 0, trusted_certs: r });
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE.prototype.sign = function (t, r) {
        var i = this;
        if ("timeStampToken" in this == !1)
          return new Promise(function (e, t) {
            t("timeStampToken is absent in TSP response");
          });
        if ("1.2.840.113549.1.7.2" !== this.timeStampToken.contentType)
          return new Promise(function (e, t) {
            t(
              "Wrong format of timeStampToken: " + i.timeStampToken.contentType
            );
          });
        var n = new e.org.pkijs.simpl.CMS_SIGNED_DATA({
          schema: this.timeStampToken.content,
        });
        return n.sign(t, 0, r);
      }),
      (e.org.pkijs.simpl.TSP_RESPONSE.prototype.toJSON = function () {
        var e = { status: this.status };
        return (
          "timeStampToken" in this &&
            (e.timeStampToken = this.timeStampToken.toJSON()),
          e
        );
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.schema) e.org.pkijs.schema = {};
    else if ("object" != typeof e.org.pkijs.schema)
      throw new Error(
        "Name org.pkijs.schema already exists and it's not an object but " +
          typeof e.org.pkijs.schema
      );
    if ("undefined" == typeof e.org.pkijs.schema.x509)
      e.org.pkijs.schema.x509 = {};
    else if ("object" != typeof e.org.pkijs.schema.x509)
      throw new Error(
        "Name org.pkijs.schema.x509 already exists and it's not an object but " +
          typeof e.org.pkijs.schema.x509
      );
    var t = {};
    (e.org.pkijs.schema.TIME = function (t, r) {
      var i = e.org.pkijs.getNames(arguments[0]),
        n = r || !1;
      return new e.org.pkijs.asn1.CHOICE({
        optional: n,
        value: [
          new e.org.pkijs.asn1.UTCTIME({ name: i.utcTimeName || "" }),
          new e.org.pkijs.asn1.GENERALIZEDTIME({
            name: i.generalTimeName || "",
          }),
        ],
      });
    }),
      (t.tbsCertificate = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "tbsCertificate",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.INTEGER({
                  name: t.tbsCertificate_version || "tbsCertificate.version",
                }),
              ],
            }),
            new e.org.pkijs.asn1.INTEGER({
              name:
                t.tbsCertificate_serialNumber || "tbsCertificate.serialNumber",
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.signature || {
                names: { block_name: "tbsCertificate.signature" },
              }
            ),
            e.org.pkijs.schema.RDN(
              t.issuer || { names: { block_name: "tbsCertificate.issuer" } }
            ),
            new e.org.pkijs.asn1.SEQUENCE({
              name: t.tbsCertificate_validity || "tbsCertificate.validity",
              value: [
                e.org.pkijs.schema.TIME(
                  t.not_before || {
                    names: {
                      utcTimeName: "tbsCertificate.notBefore",
                      generalTimeName: "tbsCertificate.notBefore",
                    },
                  }
                ),
                e.org.pkijs.schema.TIME(
                  t.not_after || {
                    names: {
                      utcTimeName: "tbsCertificate.notAfter",
                      generalTimeName: "tbsCertificate.notAfter",
                    },
                  }
                ),
              ],
            }),
            e.org.pkijs.schema.RDN(
              t.subject || { names: { block_name: "tbsCertificate.subject" } }
            ),
            e.org.pkijs.schema.PUBLIC_KEY_INFO(
              t.subjectPublicKeyInfo || {
                names: { block_name: "tbsCertificate.subjectPublicKeyInfo" },
              }
            ),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name:
                t.tbsCertificate_issuerUniqueID ||
                "tbsCertificate.issuerUniqueID",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name:
                t.tbsCertificate_subjectUniqueID ||
                "tbsCertificate.subjectUniqueID",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 3 },
              value: [
                e.org.pkijs.schema.EXTENSIONS(
                  t.extensions || {
                    names: { block_name: "tbsCertificate.extensions" },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CERT = function () {
        var r = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: r.block_name || "",
          value: [
            t.tbsCertificate(r.tbsCertificate),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              r.signatureAlgorithm || {
                names: { block_name: "signatureAlgorithm" },
              }
            ),
            new e.org.pkijs.asn1.BITSTRING({
              name: r.signatureValue || "signatureValue",
            }),
          ],
        });
      }),
      (t.tbsCertList = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "tbsCertList",
          value: [
            new e.org.pkijs.asn1.INTEGER({
              optional: !0,
              name: t.tbsCertList_version || "tbsCertList.version",
              value: 2,
            }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.signature || { names: { block_name: "tbsCertList.signature" } }
            ),
            e.org.pkijs.schema.RDN(
              t.issuer || { names: { block_name: "tbsCertList.issuer" } }
            ),
            e.org.pkijs.schema.TIME(
              t.tbsCertList_thisUpdate || {
                names: {
                  utcTimeName: "tbsCertList.thisUpdate",
                  generalTimeName: "tbsCertList.thisUpdate",
                },
              }
            ),
            e.org.pkijs.schema.TIME(
              t.tbsCertList_thisUpdate || {
                names: {
                  utcTimeName: "tbsCertList.nextUpdate",
                  generalTimeName: "tbsCertList.nextUpdate",
                },
              },
              !0
            ),
            new e.org.pkijs.asn1.SEQUENCE({
              optional: !0,
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name:
                    t.tbsCertList_revokedCertificates ||
                    "tbsCertList.revokedCertificates",
                  value: new e.org.pkijs.asn1.SEQUENCE({
                    value: [
                      new e.org.pkijs.asn1.INTEGER(),
                      e.org.pkijs.schema.TIME(),
                      e.org.pkijs.schema.EXTENSIONS({}, !0),
                    ],
                  }),
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                e.org.pkijs.schema.EXTENSIONS(
                  t.crlExtensions || {
                    names: { block_name: "tbsCertList.extensions" },
                  }
                ),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.CRL = function () {
        var r = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: r.block_name || "CertificateList",
          value: [
            t.tbsCertList(arguments[0]),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              r.signatureAlgorithm || {
                names: { block_name: "signatureAlgorithm" },
              }
            ),
            new e.org.pkijs.asn1.BITSTRING({
              name: r.signatureValue || "signatureValue",
            }),
          ],
        });
      }),
      (t.CertificationRequestInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.CertificationRequestInfo || "CertificationRequestInfo",
          value: [
            new e.org.pkijs.asn1.INTEGER({
              name:
                t.CertificationRequestInfo_version ||
                "CertificationRequestInfo.version",
            }),
            new e.org.pkijs.schema.RDN(
              t.subject || {
                names: { block_name: "CertificationRequestInfo.subject" },
              }
            ),
            new e.org.pkijs.schema.PUBLIC_KEY_INFO({
              names: {
                block_name: "CertificationRequestInfo.subjectPublicKeyInfo",
              },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  optional: !0,
                  name:
                    t.CertificationRequestInfo_attributes ||
                    "CertificationRequestInfo.attributes",
                  value: e.org.pkijs.schema.ATTRIBUTE(t.attributes || {}),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.PKCS10 = function () {
        var r = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            t.CertificationRequestInfo(r.certificationRequestInfo || {}),
            new e.org.pkijs.asn1.SEQUENCE({
              name: r.signatureAlgorithm || "signatureAlgorithm",
              value: [
                new e.org.pkijs.asn1.OID(),
                new e.org.pkijs.asn1.ANY({ optional: !0 }),
              ],
            }),
            new e.org.pkijs.asn1.BITSTRING({
              name: r.signatureValue || "signatureValue",
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.PKCS8 = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
              t.privateKeyAlgorithm || ""
            ),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.privateKey || "" }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.attributes || "",
                  value: e.org.pkijs.schema.ATTRIBUTE(),
                }),
              ],
            }),
          ],
        });
      }),
      (t.BuiltInStandardAttributes = function (t) {
        "undefined" == typeof t && (t = !1);
        var r = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          optional: t,
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 2, tag_number: 1 },
              name: r.country_name || "",
              value: [
                new e.org.pkijs.asn1.CHOICE({
                  value: [
                    new e.org.pkijs.asn1.NUMERICSTRING(),
                    new e.org.pkijs.asn1.PRINTABLESTRING(),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 2, tag_number: 2 },
              name: r.administration_domain_name || "",
              value: [
                new e.org.pkijs.asn1.CHOICE({
                  value: [
                    new e.org.pkijs.asn1.NUMERICSTRING(),
                    new e.org.pkijs.asn1.PRINTABLESTRING(),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              name: r.network_address || "",
              is_hex_only: !0,
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              name: r.terminal_identifier || "",
              is_hex_only: !0,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
              name: r.private_domain_name || "",
              value: [
                new e.org.pkijs.asn1.CHOICE({
                  value: [
                    new e.org.pkijs.asn1.NUMERICSTRING(),
                    new e.org.pkijs.asn1.PRINTABLESTRING(),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 3 },
              name: r.organization_name || "",
              is_hex_only: !0,
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              optional: !0,
              name: r.numeric_user_identifier || "",
              id_block: { tag_class: 3, tag_number: 4 },
              is_hex_only: !0,
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              name: r.personal_name || "",
              id_block: { tag_class: 3, tag_number: 5 },
              value: [
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  id_block: { tag_class: 3, tag_number: 0 },
                  is_hex_only: !0,
                }),
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 1 },
                  is_hex_only: !0,
                }),
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 2 },
                  is_hex_only: !0,
                }),
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 3 },
                  is_hex_only: !0,
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              name: r.organizational_unit_names || "",
              id_block: { tag_class: 3, tag_number: 6 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  value: new e.org.pkijs.asn1.PRINTABLESTRING(),
                }),
              ],
            }),
          ],
        });
      }),
      (t.BuiltInDomainDefinedAttributes = function (t) {
        return (
          "undefined" == typeof t && (t = !1),
          new e.org.pkijs.asn1.SEQUENCE({
            optional: t,
            value: [
              new e.org.pkijs.asn1.PRINTABLESTRING(),
              new e.org.pkijs.asn1.PRINTABLESTRING(),
            ],
          })
        );
      }),
      (t.ExtensionAttributes = function (t) {
        return (
          "undefined" == typeof t && (t = !1),
          new e.org.pkijs.asn1.SET({
            optional: t,
            value: [
              new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                is_hex_only: !0,
              }),
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 1 },
                value: [new e.org.pkijs.asn1.ANY()],
              }),
            ],
          })
        );
      }),
      (e.org.pkijs.schema.GENERAL_NAME = function () {
        var r = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.CHOICE({
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              name: r.block_name || "",
              value: [
                new e.org.pkijs.asn1.OID(),
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [new e.org.pkijs.asn1.ANY()],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: r.block_name || "",
              id_block: { tag_class: 3, tag_number: 1 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: r.block_name || "",
              id_block: { tag_class: 3, tag_number: 2 },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 3 },
              name: r.block_name || "",
              value: [
                t.BuiltInStandardAttributes(!1),
                t.BuiltInDomainDefinedAttributes(!0),
                t.ExtensionAttributes(!0),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 4 },
              name: r.block_name || "",
              value: [e.org.pkijs.schema.RDN(r.directoryName || {})],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 5 },
              name: r.block_name || "",
              value: [
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [
                    new e.org.pkijs.asn1.CHOICE({
                      value: [
                        new e.org.pkijs.asn1.TELETEXSTRING(),
                        new e.org.pkijs.asn1.PRINTABLESTRING(),
                        new e.org.pkijs.asn1.UNIVERSALSTRING(),
                        new e.org.pkijs.asn1.UTF8STRING(),
                        new e.org.pkijs.asn1.BMPSTRING(),
                      ],
                    }),
                  ],
                }),
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [
                    new e.org.pkijs.asn1.CHOICE({
                      value: [
                        new e.org.pkijs.asn1.TELETEXSTRING(),
                        new e.org.pkijs.asn1.PRINTABLESTRING(),
                        new e.org.pkijs.asn1.UNIVERSALSTRING(),
                        new e.org.pkijs.asn1.UTF8STRING(),
                        new e.org.pkijs.asn1.BMPSTRING(),
                      ],
                    }),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: r.block_name || "",
              id_block: { tag_class: 3, tag_number: 6 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: r.block_name || "",
              id_block: { tag_class: 3, tag_number: 7 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: r.block_name || "",
              id_block: { tag_class: 3, tag_number: 8 },
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ALGORITHM_IDENTIFIER = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          optional: t.optional || !1,
          value: [
            new e.org.pkijs.asn1.OID({ name: t.algorithmIdentifier || "" }),
            new e.org.pkijs.asn1.ANY({
              name: t.algorithmParams || "",
              optional: !0,
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.RSAPublicKey = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.modulus || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.publicExponent || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.OtherPrimeInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.prime || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.exponent || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.coefficient || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.RSAPrivateKey = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.INTEGER({ name: t.version || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.modulus || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.publicExponent || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.privateExponent || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.prime1 || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.prime2 || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.exponent1 || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.exponent2 || "" }),
            new e.org.pkijs.asn1.INTEGER({ name: t.coefficient || "" }),
            new e.org.pkijs.asn1.SEQUENCE({
              optional: !0,
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.otherPrimeInfos || "",
                  value: e.org.pkijs.schema.x509.OtherPrimeInfo(
                    t.otherPrimeInfo || {}
                  ),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.RSASSA_PSS_params = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              optional: !0,
              value: [
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.hashAlgorithm || {}),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 1 },
              optional: !0,
              value: [
                e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
                  t.maskGenAlgorithm || {}
                ),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 2 },
              optional: !0,
              value: [
                new e.org.pkijs.asn1.INTEGER({ name: t.saltLength || "" }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 3 },
              optional: !0,
              value: [
                new e.org.pkijs.asn1.INTEGER({ name: t.trailerField || "" }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.PUBLIC_KEY_INFO = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.ALGORITHM_IDENTIFIER(t.algorithm || {}),
            new e.org.pkijs.asn1.BITSTRING({ name: t.subjectPublicKey || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.ATTRIBUTE = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.type || "" }),
            new e.org.pkijs.asn1.SET({
              name: t.set_name || "",
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.values || "",
                  value: new e.org.pkijs.asn1.ANY(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.ATTR_TYPE_AND_VALUE = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.type || "" }),
            new e.org.pkijs.asn1.ANY({ name: t.value || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.RDN = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.repeated_sequence || "",
              value: new e.org.pkijs.asn1.SET({
                value: [
                  new e.org.pkijs.asn1.REPEATED({
                    name: t.repeated_set || "",
                    value: e.org.pkijs.schema.ATTR_TYPE_AND_VALUE(
                      t.attr_type_and_value || {}
                    ),
                  }),
                ],
              }),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.EXTENSION = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.extnID || "" }),
            new e.org.pkijs.asn1.BOOLEAN({
              name: t.critical || "",
              optional: !0,
            }),
            new e.org.pkijs.asn1.OCTETSTRING({ name: t.extnValue || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.EXTENSIONS = function (t, r) {
        var i = e.org.pkijs.getNames(arguments[0]),
          n = r || !1;
        return new e.org.pkijs.asn1.SEQUENCE({
          optional: n,
          name: i.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: i.extensions || "",
              value: e.org.pkijs.schema.EXTENSION(i.extension || {}),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.AuthorityKeyIdentifier = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.keyIdentifier || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.authorityCertIssuer || "",
                  value: e.org.pkijs.schema.GENERAL_NAME(),
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.authorityCertSerialNumber || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PrivateKeyUsagePeriod = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.notBefore || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.notAfter || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.AltName = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.altNames || "",
              value: e.org.pkijs.schema.GENERAL_NAME(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.SubjectDirectoryAttributes = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.attributes || "",
              value: e.org.pkijs.schema.ATTRIBUTE(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.GeneralSubtree = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            e.org.pkijs.schema.GENERAL_NAME(t.base || ""),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.INTEGER({ name: t.minimum || "" })],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [new e.org.pkijs.asn1.INTEGER({ name: t.maximum || "" })],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.NameConstraints = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.permittedSubtrees || "",
                  value: e.org.pkijs.schema.x509.GeneralSubtree(),
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.excludedSubtrees || "",
                  value: e.org.pkijs.schema.x509.GeneralSubtree(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.BasicConstraints = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.BOOLEAN({ optional: !0, name: t.cA || "" }),
            new e.org.pkijs.asn1.INTEGER({
              optional: !0,
              name: t.pathLenConstraint || "",
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PolicyQualifierInfo = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.policyQualifierId || "" }),
            new e.org.pkijs.asn1.ANY({ name: t.qualifier || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PolicyInformation = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.policyIdentifier || "" }),
            new e.org.pkijs.asn1.SEQUENCE({
              optional: !0,
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.policyQualifiers || "",
                  value: e.org.pkijs.schema.x509.PolicyQualifierInfo(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.CertificatePolicies = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.certificatePolicies || "",
              value: e.org.pkijs.schema.x509.PolicyInformation(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PolicyMapping = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.issuerDomainPolicy || "" }),
            new e.org.pkijs.asn1.OID({ name: t.subjectDomainPolicy || "" }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PolicyMappings = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.mappings || "",
              value: e.org.pkijs.schema.x509.PolicyMapping(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.PolicyConstraints = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.requireExplicitPolicy || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.inhibitPolicyMapping || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.ExtKeyUsage = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.keyPurposes || "",
              value: new e.org.pkijs.asn1.OID(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.DistributionPoint = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.CHOICE({
                  value: [
                    new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                      name: t.distributionPoint || "",
                      optional: !0,
                      id_block: { tag_class: 3, tag_number: 0 },
                      value: [
                        new e.org.pkijs.asn1.REPEATED({
                          name: t.distributionPoint_names || "",
                          value: e.org.pkijs.schema.GENERAL_NAME(),
                        }),
                      ],
                    }),
                    new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                      name: t.distributionPoint || "",
                      optional: !0,
                      id_block: { tag_class: 3, tag_number: 1 },
                      value: e.org.pkijs.schema.RDN().value_block.value,
                    }),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.reasons || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              name: t.cRLIssuer || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
              value: [
                new e.org.pkijs.asn1.REPEATED({
                  name: t.cRLIssuer_names || "",
                  value: e.org.pkijs.schema.GENERAL_NAME(),
                }),
              ],
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.CRLDistributionPoints = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.distributionPoints || "",
              value: e.org.pkijs.schema.x509.DistributionPoint(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.AccessDescription = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.OID({ name: t.accessMethod || "" }),
            e.org.pkijs.schema.GENERAL_NAME(t.accessLocation || ""),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.InfoAccess = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.REPEATED({
              name: t.accessDescriptions || "",
              value: e.org.pkijs.schema.x509.AccessDescription(),
            }),
          ],
        });
      }),
      (e.org.pkijs.schema.x509.IssuingDistributionPoint = function () {
        var t = e.org.pkijs.getNames(arguments[0]);
        return new e.org.pkijs.asn1.SEQUENCE({
          name: t.block_name || "",
          value: [
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [
                new e.org.pkijs.asn1.CHOICE({
                  value: [
                    new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                      name: t.distributionPoint || "",
                      id_block: { tag_class: 3, tag_number: 0 },
                      value: [
                        new e.org.pkijs.asn1.REPEATED({
                          name: t.distributionPoint_names || "",
                          value: e.org.pkijs.schema.GENERAL_NAME(),
                        }),
                      ],
                    }),
                    new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                      name: t.distributionPoint || "",
                      id_block: { tag_class: 3, tag_number: 1 },
                      value: e.org.pkijs.schema.RDN().value_block.value,
                    }),
                  ],
                }),
              ],
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.onlyContainsUserCerts || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.onlyContainsCACerts || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 2 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.onlySomeReasons || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 3 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.indirectCRL || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 4 },
            }),
            new e.org.pkijs.asn1.ASN1_PRIMITIVE({
              name: t.onlyContainsAttributeCerts || "",
              optional: !0,
              id_block: { tag_class: 3, tag_number: 5 },
            }),
          ],
        });
      });
  })("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.simpl) e.org.pkijs.simpl = {};
    else if ("object" != typeof e.org.pkijs.simpl)
      throw new Error(
        "Name org.pkijs.simpl already exists and it's not an object but " +
          typeof e.org.pkijs.simpl
      );
    if ("undefined" == typeof e.org.pkijs.simpl.x509)
      e.org.pkijs.simpl.x509 = {};
    else if ("object" != typeof e.org.pkijs.simpl.x509)
      throw new Error(
        "Name org.pkijs.simpl.x509 already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.x509
      );
    (e.org.pkijs.simpl.TIME = function () {
      (this.type = 0),
        (this.value = new Date(0, 0, 0)),
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.TIME.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ((this.type = arguments[0].type || 0),
            (this.value = arguments[0].value || new Date(0, 0, 0)));
    }),
      (e.org.pkijs.simpl.TIME.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.TIME({
            names: {
              utcTimeName: "utcTimeName",
              generalTimeName: "generalTimeName",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for TIME"
          );
        "utcTimeName" in r.result &&
          ((this.type = 0), (this.value = r.result.utcTimeName.toDate())),
          "generalTimeName" in r.result &&
            ((this.type = 1), (this.value = r.result.generalTimeName.toDate()));
      }),
      (e.org.pkijs.simpl.TIME.prototype.toSchema = function () {
        var t = {};
        return (
          0 === this.type &&
            (t = new e.org.pkijs.asn1.UTCTIME({ value_date: this.value })),
          1 === this.type &&
            (t = new e.org.pkijs.asn1.GENERALIZEDTIME({
              value_date: this.value,
            })),
          t
        );
      }),
      (e.org.pkijs.simpl.TIME.prototype.toJSON = function () {
        return { type: this.type, value: this.value };
      }),
      (e.org.pkijs.simpl.GENERAL_NAME = function () {
        (this.NameType = 9),
          (this.Name = {}),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.GENERAL_NAME.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.NameType = arguments[0].NameType || 9),
              (this.Name = arguments[0].Name || {}));
      }),
      (e.org.pkijs.simpl.GENERAL_NAME.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.GENERAL_NAME({
            names: {
              block_name: "block_name",
              otherName: "otherName",
              rfc822Name: "rfc822Name",
              dNSName: "dNSName",
              x400Address: "x400Address",
              directoryName: { names: { block_name: "directoryName" } },
              ediPartyName: "ediPartyName",
              uniformResourceIdentifier: "uniformResourceIdentifier",
              iPAddress: "iPAddress",
              registeredID: "registeredID",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for GENERAL_NAME"
          );
        switch (
          ((this.NameType = r.result.block_name.id_block.tag_number),
          this.NameType)
        ) {
          case 0:
            this.Name = r.result.block_name;
            break;
          case 1:
          case 2:
          case 6:
            var i = r.result.block_name;
            (i.id_block.tag_class = 1), (i.id_block.tag_number = 22);
            var n = i.toBER(!1);
            this.Name = e.org.pkijs.fromBER(n).result.value_block.value;
            break;
          case 3:
            this.Name = r.result.block_name;
            break;
          case 4:
            this.Name = new e.org.pkijs.simpl.RDN({
              schema: r.result.directoryName,
            });
            break;
          case 5:
            this.Name = r.result.ediPartyName;
            break;
          case 7:
            this.Name = new e.org.pkijs.asn1.OCTETSTRING({
              value_hex: r.result.block_name.value_block.value_hex,
            });
            break;
          case 8:
            var i = r.result.block_name;
            (i.id_block.tag_class = 1), (i.id_block.tag_number = 6);
            var n = i.toBER(!1);
            this.Name = e.org.pkijs.fromBER(n).result.value_block.toString();
        }
      }),
      (e.org.pkijs.simpl.GENERAL_NAME.prototype.toSchema = function (t) {
        switch (this.NameType) {
          case 0:
          case 3:
          case 5:
            return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: this.NameType },
              value: [this.Name],
            });
          case 1:
          case 2:
          case 6:
            var r = new e.org.pkijs.asn1.IA5STRING({ value: this.Name });
            return (
              (r.id_block.tag_class = 3),
              (r.id_block.tag_number = this.NameType),
              r
            );
          case 4:
            return new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 4 },
              value: [this.Name.toSchema()],
            });
          case 7:
            var r = this.Name;
            return (
              (r.id_block.tag_class = 3),
              (r.id_block.tag_number = this.NameType),
              r
            );
          case 8:
            var r = new e.org.pkijs.asn1.OID({ value: this.Name });
            return (
              (r.id_block.tag_class = 3),
              (r.id_block.tag_number = this.NameType),
              r
            );
          default:
            return e.org.pkijs.schema.GENERAL_NAME();
        }
      }),
      (e.org.pkijs.simpl.GENERAL_NAME.prototype.toJSON = function () {
        var e = { NameType: this.NameType };
        return (
          "string" == typeof this.Name
            ? (e.Name = this.Name)
            : (e.Name = this.Name.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.GENERAL_NAMES = function () {
        (this.names = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.GENERAL_NAMES.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.names = arguments[0].names || new Array());
      }),
      (e.org.pkijs.simpl.GENERAL_NAMES.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.REPEATED({
                name: "names",
                value: e.org.pkijs.schema.GENERAL_NAME(),
              }),
            ],
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for GENERAL_NAMES"
          );
        for (var i = r.result.names, n = 0; n < i.length; n++)
          this.names.push(new e.org.pkijs.simpl.GENERAL_NAME({ schema: i[n] }));
      }),
      (e.org.pkijs.simpl.GENERAL_NAMES.prototype.toSchema = function (t) {
        for (var r = new Array(), i = 0; i < this.names.length; i++)
          r.push(this.names[i].toSchema());
        return new e.org.pkijs.asn1.SEQUENCE({ value: r });
      }),
      (e.org.pkijs.simpl.GENERAL_NAMES.prototype.toJSON = function () {
        for (var e = new Array(), t = 0; t < this.names.length; t++)
          e.push(this.names[t].toJSON());
        return { names: e };
      }),
      (e.org.pkijs.simpl.ALGORITHM_IDENTIFIER = function () {
        (this.algorithm_id = ""),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ALGORITHM_IDENTIFIER.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.algorithm_id = arguments[0].algorithm_id || ""),
              "algorithm_params" in arguments[0] &&
                (this.algorithm_params = arguments[0].algorithm_params));
      }),
      (e.org.pkijs.simpl.ALGORITHM_IDENTIFIER.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ALGORITHM_IDENTIFIER({
            names: {
              algorithmIdentifier: "algorithm",
              algorithmParams: "params",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ALGORITHM_IDENTIFIER"
          );
        (this.algorithm_id = r.result.algorithm.value_block.toString()),
          "params" in r.result && (this.algorithm_params = r.result.params);
      }),
      (e.org.pkijs.simpl.ALGORITHM_IDENTIFIER.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(new e.org.pkijs.asn1.OID({ value: this.algorithm_id })),
          "algorithm_params" in this && t.push(this.algorithm_params),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.ALGORITHM_IDENTIFIER.prototype.getCommonName =
        function () {}),
      (e.org.pkijs.simpl.ALGORITHM_IDENTIFIER.prototype.toJSON = function () {
        var e = { algorithm_id: this.algorithm_id };
        return (
          "algorithm_params" in this &&
            (e.algorithm_params = this.algorithm_params.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.x509.RSAPublicKey = function () {
        (this.modulus = new e.org.pkijs.asn1.INTEGER()),
          (this.publicExponent = new e.org.pkijs.asn1.INTEGER()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.RSAPublicKey.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.modulus =
                arguments[0].modulus || new e.org.pkijs.asn1.INTEGER()),
              (this.publicExponent =
                arguments[0].publicExponent || new e.org.pkijs.asn1.INTEGER()));
      }),
      (e.org.pkijs.simpl.x509.RSAPublicKey.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.RSAPublicKey({
            names: { modulus: "modulus", publicExponent: "publicExponent" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for RSAPublicKey"
          );
        (this.modulus = r.result.modulus),
          (this.publicExponent = r.result.publicExponent);
      }),
      (e.org.pkijs.simpl.x509.RSAPublicKey.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [this.modulus, this.publicExponent],
        });
      }),
      (e.org.pkijs.simpl.x509.RSAPublicKey.prototype.toJSON = function () {
        return {
          modulus: this.modulus.toJSON(),
          publicExponent: this.publicExponent.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.x509.OtherPrimeInfo = function () {
        (this.prime = new e.org.pkijs.asn1.INTEGER()),
          (this.exponent = new e.org.pkijs.asn1.INTEGER()),
          (this.coefficient = new e.org.pkijs.asn1.INTEGER()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.OtherPrimeInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.prime =
                arguments[0].prime || new e.org.pkijs.asn1.INTEGER()),
              (this.exponent =
                arguments[0].exponent || new e.org.pkijs.asn1.INTEGER()),
              (this.coefficient =
                arguments[0].coefficient || new e.org.pkijs.asn1.INTEGER()));
      }),
      (e.org.pkijs.simpl.x509.OtherPrimeInfo.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.OtherPrimeInfo({
            names: {
              prime: "prime",
              exponent: "exponent",
              coefficient: "coefficient",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for OtherPrimeInfo"
          );
        (this.prime = r.result.prime),
          (this.exponent = r.result.exponent),
          (this.coefficient = r.result.coefficient);
      }),
      (e.org.pkijs.simpl.x509.OtherPrimeInfo.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [this.prime, this.exponent, this.coefficient],
        });
      }),
      (e.org.pkijs.simpl.x509.OtherPrimeInfo.prototype.toJSON = function () {
        return {
          prime: this.prime.toJSON(),
          exponent: this.exponent.toJSON(),
          coefficient: this.coefficient.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.x509.RSAPrivateKey = function () {
        (this.version = 0),
          (this.modulus = new e.org.pkijs.asn1.INTEGER()),
          (this.publicExponent = new e.org.pkijs.asn1.INTEGER()),
          (this.privateExponent = new e.org.pkijs.asn1.INTEGER()),
          (this.prime1 = new e.org.pkijs.asn1.INTEGER()),
          (this.prime2 = new e.org.pkijs.asn1.INTEGER()),
          (this.exponent1 = new e.org.pkijs.asn1.INTEGER()),
          (this.exponent2 = new e.org.pkijs.asn1.INTEGER()),
          (this.coefficient = new e.org.pkijs.asn1.INTEGER()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.RSAPrivateKey.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.modulus =
                arguments[0].modulus || new e.org.pkijs.asn1.INTEGER()),
              (this.publicExponent =
                arguments[0].publicExponent || new e.org.pkijs.asn1.INTEGER()),
              (this.privateExponent =
                arguments[0].privateExponent || new e.org.pkijs.asn1.INTEGER()),
              (this.prime1 =
                arguments[0].prime1 || new e.org.pkijs.asn1.INTEGER()),
              (this.prime2 =
                arguments[0].prime2 || new e.org.pkijs.asn1.INTEGER()),
              (this.exponent1 =
                arguments[0].exponent1 || new e.org.pkijs.asn1.INTEGER()),
              (this.exponent2 =
                arguments[0].exponent2 || new e.org.pkijs.asn1.INTEGER()),
              (this.coefficient =
                arguments[0].coefficient || new e.org.pkijs.asn1.INTEGER()),
              "otherPrimeInfos" in arguments[0] &&
                (this.otherPrimeInfos =
                  arguments[0].otherPrimeInfos || new Array()));
      }),
      (e.org.pkijs.simpl.x509.RSAPrivateKey.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.RSAPrivateKey({
            names: {
              version: "version",
              modulus: "modulus",
              publicExponent: "publicExponent",
              privateExponent: "privateExponent",
              prime1: "prime1",
              prime2: "prime2",
              exponent1: "exponent1",
              exponent2: "exponent2",
              coefficient: "coefficient",
              otherPrimeInfos: "otherPrimeInfos",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for RSAPrivateKey"
          );
        if (
          ((this.version = r.result.version.value_block.value_dec),
          (this.modulus = r.result.modulus),
          (this.publicExponent = r.result.publicExponent),
          (this.privateExponent = r.result.privateExponent),
          (this.prime1 = r.result.prime1),
          (this.prime2 = r.result.prime2),
          (this.exponent1 = r.result.exponent1),
          (this.exponent2 = r.result.exponent2),
          (this.coefficient = r.result.coefficient),
          "otherPrimeInfos" in r.result)
        )
          for (var i = r.result.otherPrimeInfos, n = 0; n < i.length; n++)
            this.otherPrimeInfos.push(
              new e.org.pkijs.simpl.x509.OtherPrimeInfo({ schema: i[n] })
            );
      }),
      (e.org.pkijs.simpl.x509.RSAPrivateKey.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(this.modulus),
          t.push(this.publicExponent),
          t.push(this.privateExponent),
          t.push(this.prime1),
          t.push(this.prime2),
          t.push(this.exponent1),
          t.push(this.exponent2),
          t.push(this.coefficient),
          "otherPrimeInfos" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.otherPrimeInfos.length; i++)
            r.push(this.otherPrimeInfos[i].toSchema());
          t.push(new e.org.pkijs.asn1.SEQUENCE({ value: r }));
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.RSAPrivateKey.prototype.toJSON = function () {
        var e = {
          version: this.version,
          modulus: this.modulus.toJSON(),
          publicExponent: this.publicExponent.toJSON(),
          privateExponent: this.privateExponent.toJSON(),
          prime1: this.prime1.toJSON(),
          prime2: this.prime2.toJSON(),
          exponent1: this.exponent1.toJSON(),
          exponent2: this.exponent2.toJSON(),
          coefficient: this.coefficient.toJSON(),
        };
        if ("otherPrimeInfos" in this) {
          e.otherPrimeInfos = new Array();
          for (var t = 0; t < this.otherPrimeInfos.length; t++)
            e.otherPrimeInfos.push(this.otherPrimeInfos[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.x509.RSASSA_PSS_params = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.RSASSA_PSS_params.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("hashAlgorithm" in arguments[0] &&
              (this.hashAlgorithm = arguments[0].hashAlgorithm),
            "maskGenAlgorithm" in arguments[0] &&
              (this.maskGenAlgorithm = arguments[0].maskGenAlgorithm),
            "saltLength" in arguments[0] &&
              (this.saltLength = arguments[0].saltLength),
            "trailerField" in arguments[0] &&
              (this.trailerField = arguments[0].trailerField));
      }),
      (e.org.pkijs.simpl.x509.RSASSA_PSS_params.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.RSASSA_PSS_params({
              names: {
                hashAlgorithm: { names: { block_name: "hashAlgorithm" } },
                maskGenAlgorithm: { names: { block_name: "maskGenAlgorithm" } },
                saltLength: "saltLength",
                trailerField: "trailerField",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for RSASSA_PSS_params"
            );
          "hashAlgorithm" in r.result &&
            (this.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.hashAlgorithm,
            })),
            "maskGenAlgorithm" in r.result &&
              (this.maskGenAlgorithm =
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                  schema: r.result.maskGenAlgorithm,
                })),
            "saltLength" in r.result &&
              (this.saltLength = r.result.saltLength.value_block.value_dec),
            "trailerField" in r.result &&
              (this.trailerField = r.result.trailerField.value_block.value_dec);
        }),
      (e.org.pkijs.simpl.x509.RSASSA_PSS_params.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            "hashAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value: [this.hashAlgorithm.toSchema()],
                })
              ),
            "maskGenAlgorithm" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 1 },
                  value: [this.maskGenAlgorithm.toSchema()],
                })
              ),
            "saltLength" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 2 },
                  value: [
                    new e.org.pkijs.asn1.INTEGER({ value: this.saltLength }),
                  ],
                })
              ),
            "trailerField" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  id_block: { tag_class: 3, tag_number: 3 },
                  value: [
                    new e.org.pkijs.asn1.INTEGER({ value: this.trailerField }),
                  ],
                })
              ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.x509.RSASSA_PSS_params.prototype.toJSON = function () {
        var e = {};
        return (
          "hashAlgorithm" in this &&
            (e.hashAlgorithm = this.hashAlgorithm.toJSON()),
          "maskGenAlgorithm" in this &&
            (e.maskGenAlgorithm = this.maskGenAlgorithm.toJSON()),
          "saltLength" in this && (e.saltLength = this.saltLength.toJSON()),
          "trailerField" in this &&
            (e.trailerField = this.trailerField.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.PUBLIC_KEY_INFO = function () {
        (this.algorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.subjectPublicKey = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.algorithm =
                arguments[0].algorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.subjectPublicKey =
                arguments[0].subjectPublicKey ||
                new e.org.pkijs.asn1.BITSTRING()));
      }),
      (e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.PUBLIC_KEY_INFO({
            names: {
              algorithm: { names: { block_name: "algorithm" } },
              subjectPublicKey: "subjectPublicKey",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PUBLIC_KEY_INFO"
          );
        (this.algorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.algorithm,
        })),
          (this.subjectPublicKey = r.result.subjectPublicKey);
      }),
      (e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [this.algorithm.toSchema(), this.subjectPublicKey],
        });
      }),
      (e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.importKey = function (t) {
        var r = Promise.resolve(),
          i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide publicKey input parameter");
          });
        var n = e.org.pkijs.getCrypto();
        return "undefined" == typeof n
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : ((r = r.then(function () {
              return n.exportKey("spki", t);
            })),
            (r = r.then(
              function (t) {
                var r = e.org.pkijs.fromBER(t);
                try {
                  e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.fromSchema.call(
                    i,
                    r.result
                  );
                } catch (n) {
                  return new Promise(function (e, t) {
                    t("Error during initializing object from schema");
                  });
                }
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Error during exporting public key: " + e);
                });
              }
            )));
      }),
      (e.org.pkijs.simpl.PUBLIC_KEY_INFO.prototype.toJSON = function () {
        return {
          algorithm: this.algorithm.toJSON(),
          subjectPublicKey: this.subjectPublicKey.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE = function () {
        (this.type = ""),
          (this.value = {}),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.type = arguments[0].type || ""),
              (this.value = arguments[0].value || {}));
      }),
      (e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ATTR_TYPE_AND_VALUE({
            names: { type: "type", value: "typeValue" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ATTR_TYPE_AND_VALUE"
          );
        (this.type = r.result.type.value_block.toString()),
          (this.value = r.result.typeValue);
      }),
      (e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [new e.org.pkijs.asn1.OID({ value: this.type }), this.value],
        });
      }),
      (e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE.prototype.isEqual = function () {
        if (arguments[0] instanceof e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE) {
          if (this.type !== arguments[0].type) return !1;
          if (
            (this.value instanceof e.org.pkijs.asn1.UTF8STRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.UTF8STRING) ||
            (this.value instanceof e.org.pkijs.asn1.BMPSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.BMPSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.UNIVERSALSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.UNIVERSALSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.NUMERICSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.NUMERICSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.PRINTABLESTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.PRINTABLESTRING) ||
            (this.value instanceof e.org.pkijs.asn1.TELETEXSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.TELETEXSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.VIDEOTEXSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.VIDEOTEXSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.IA5STRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.IA5STRING) ||
            (this.value instanceof e.org.pkijs.asn1.GRAPHICSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.GRAPHICSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.VISIBLESTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.VISIBLESTRING) ||
            (this.value instanceof e.org.pkijs.asn1.GENERALSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.GENERALSTRING) ||
            (this.value instanceof e.org.pkijs.asn1.CHARACTERSTRING &&
              arguments[0].value instanceof e.org.pkijs.asn1.CHARACTERSTRING)
          ) {
            var t = e.org.pkijs.stringPrep(this.value.value_block.value),
              r = e.org.pkijs.stringPrep(arguments[0].value.value_block.value);
            if (0 !== t.localeCompare(r)) return !1;
          } else if (
            e.org.pkijs.isEqual_buffer(
              this.value.value_before_decode,
              arguments[0].value.value_before_decode
            ) === !1
          )
            return !1;
          return !0;
        }
        return !1;
      }),
      (e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE.prototype.toJSON = function () {
        var e = { type: this.type };
        return (
          0 !== Object.keys(this.value).length
            ? (e.value = this.value.toJSON())
            : (e.value = this.value),
          e
        );
      }),
      (e.org.pkijs.simpl.RDN = function () {
        (this.types_and_values = new Array()),
          (this.value_before_decode = new ArrayBuffer(0)),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.RDN.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.types_and_values =
                arguments[0].types_and_values || new Array()),
              (this.value_before_decode =
                arguments[0].value_before_decode || new ArrayBuffer(0)));
      }),
      (e.org.pkijs.simpl.RDN.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.RDN({
            names: { block_name: "RDN", repeated_set: "types_and_values" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for RDN"
          );
        if ("types_and_values" in r.result)
          for (var i = r.result.types_and_values, n = 0; n < i.length; n++)
            this.types_and_values.push(
              new e.org.pkijs.simpl.ATTR_TYPE_AND_VALUE({ schema: i[n] })
            );
        this.value_before_decode = r.result.RDN.value_before_decode;
      }),
      (e.org.pkijs.simpl.RDN.prototype.toSchema = function () {
        if (0 === this.value_before_decode.byteLength) {
          for (
            var t = new Array(), r = 0;
            r < this.types_and_values.length;
            r++
          )
            t.push(this.types_and_values[r].toSchema());
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [new e.org.pkijs.asn1.SET({ value: t })],
          });
        }
        var i = e.org.pkijs.fromBER(this.value_before_decode);
        return i.result;
      }),
      (e.org.pkijs.simpl.RDN.prototype.isEqual = function () {
        if (arguments[0] instanceof e.org.pkijs.simpl.RDN) {
          if (
            this.types_and_values.length != arguments[0].types_and_values.length
          )
            return !1;
          for (var t = 0; t < this.types_and_values.length; t++)
            if (
              this.types_and_values[t].isEqual(
                arguments[0].types_and_values[t]
              ) === !1
            )
              return !1;
          return !0;
        }
        return (
          arguments[0] instanceof ArrayBuffer &&
          e.org.pkijs.isEqual_buffer(this.value_before_decode, arguments[0])
        );
      }),
      (e.org.pkijs.simpl.RDN.prototype.toJSON = function () {
        for (
          var e = { types_and_values: new Array() }, t = 0;
          t < this.types_and_values.length;
          t++
        )
          e.types_and_values.push(this.types_and_values[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.x509.AuthorityKeyIdentifier = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.AuthorityKeyIdentifier.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("keyIdentifier" in arguments[0] &&
              (this.keyIdentifier = arguments[0].keyIdentifier),
            "authorityCertIssuer" in arguments[0] &&
              (this.authorityCertIssuer = arguments[0].authorityCertIssuer),
            "authorityCertSerialNumber" in arguments[0] &&
              (this.authorityCertSerialNumber =
                arguments[0].authorityCertSerialNumber));
      }),
      (e.org.pkijs.simpl.x509.AuthorityKeyIdentifier.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.AuthorityKeyIdentifier({
              names: {
                keyIdentifier: "keyIdentifier",
                authorityCertIssuer: "authorityCertIssuer",
                authorityCertSerialNumber: "authorityCertSerialNumber",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for AuthorityKeyIdentifier"
            );
          if (
            ("keyIdentifier" in r.result &&
              ((r.result.keyIdentifier.id_block.tag_class = 1),
              (r.result.keyIdentifier.id_block.tag_number = 4),
              (this.keyIdentifier = r.result.keyIdentifier)),
            "authorityCertIssuer" in r.result)
          ) {
            this.authorityCertIssuer = new Array();
            for (var i = r.result.authorityCertIssuer, n = 0; n < i.length; n++)
              this.authorityCertIssuer.push(
                new e.org.pkijs.simpl.GENERAL_NAME({ schema: i[n] })
              );
          }
          "authorityCertSerialNumber" in r.result &&
            ((r.result.authorityCertSerialNumber.id_block.tag_class = 1),
            (r.result.authorityCertSerialNumber.id_block.tag_number = 2),
            (this.authorityCertSerialNumber =
              r.result.authorityCertSerialNumber));
        }),
      (e.org.pkijs.simpl.x509.AuthorityKeyIdentifier.prototype.toSchema =
        function () {
          var t = new Array();
          if ("keyIdentifier" in this) {
            var r = this.keyIdentifier;
            (r.id_block.tag_class = 3), (r.id_block.tag_number = 0), t.push(r);
          }
          if ("authorityCertIssuer" in this) {
            for (
              var i = new Array(), n = 0;
              n < this.authorityCertIssuer.length;
              n++
            )
              i.push(this.authorityCertIssuer[n].toSchema());
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 1 },
                value: [new e.org.pkijs.asn1.SEQUENCE({ value: i })],
              })
            );
          }
          if ("authorityCertSerialNumber" in this) {
            var r = this.authorityCertSerialNumber;
            (r.id_block.tag_class = 3), (r.id_block.tag_number = 2), t.push(r);
          }
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.AuthorityKeyIdentifier.prototype.toJSON =
        function () {
          var e = {};
          if (
            ("keyIdentifier" in this &&
              (e.keyIdentifier = this.keyIdentifier.toJSON()),
            "authorityCertIssuer" in this)
          ) {
            e.authorityCertIssuer = new Array();
            for (var t = 0; t < this.authorityCertIssuer.length; t++)
              e.authorityCertIssuer.push(this.authorityCertIssuer[t].toJSON());
          }
          return (
            "authorityCertSerialNumber" in this &&
              (e.authorityCertSerialNumber =
                this.authorityCertSerialNumber.toJSON()),
            e
          );
        }),
      (e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("notBefore" in arguments[0] &&
              (this.notBefore = arguments[0].notBefore),
            "notAfter" in arguments[0] &&
              (this.notAfter = arguments[0].notAfter));
      }),
      (e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.PrivateKeyUsagePeriod({
              names: { notBefore: "notBefore", notAfter: "notAfter" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for PrivateKeyUsagePeriod"
            );
          if ("notBefore" in r.result) {
            var i = new e.org.pkijs.asn1.GENERALIZEDTIME();
            i.fromBuffer(r.result.notBefore.value_block.value_hex),
              (this.notBefore = i.toDate());
          }
          if ("notAfter" in r.result) {
            var n = new e.org.pkijs.asn1.GENERALIZEDTIME({
              value_hex: r.result.notAfter.value_block.value_hex,
            });
            n.fromBuffer(r.result.notAfter.value_block.value_hex),
              (this.notAfter = n.toDate());
          }
        }),
      (e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            "notBefore" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  id_block: { tag_class: 3, tag_number: 0 },
                  value_hex: new e.org.pkijs.asn1.GENERALIZEDTIME({
                    value_date: this.notBefore,
                  }).value_block.value_hex,
                })
              ),
            "notAfter" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  id_block: { tag_class: 3, tag_number: 1 },
                  value_hex: new e.org.pkijs.asn1.GENERALIZEDTIME({
                    value_date: this.notAfter,
                  }).value_block.value_hex,
                })
              ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod.prototype.toJSON =
        function () {
          var e = {};
          return (
            "notBefore" in this && (e.notBefore = this.notBefore),
            "notAfter" in this && (e.notAfter = this.notAfter),
            e
          );
        }),
      (e.org.pkijs.simpl.x509.AltName = function () {
        (this.altNames = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.AltName.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.altNames = arguments[0].altNames || new Array());
      }),
      (e.org.pkijs.simpl.x509.AltName.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.AltName({ names: { altNames: "altNames" } })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for AltName"
          );
        if ("altNames" in r.result)
          for (var i = r.result.altNames, n = 0; n < i.length; n++)
            this.altNames.push(
              new e.org.pkijs.simpl.GENERAL_NAME({ schema: i[n] })
            );
      }),
      (e.org.pkijs.simpl.x509.AltName.prototype.toSchema = function () {
        for (var t = new Array(), r = 0; r < this.altNames.length; r++)
          t.push(this.altNames[r].toSchema());
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.AltName.prototype.toJSON = function () {
        for (
          var e = { altNames: new Array() }, t = 0;
          t < this.altNames.length;
          t++
        )
          e.altNames.push(this.altNames[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.x509.SubjectDirectoryAttributes = function () {
        (this.attributes = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.SubjectDirectoryAttributes.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.attributes = arguments[0].attributes || new Array());
      }),
      (e.org.pkijs.simpl.x509.SubjectDirectoryAttributes.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.SubjectDirectoryAttributes({
              names: { attributes: "attributes" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for SubjectDirectoryAttributes"
            );
          for (var i = r.result.attributes, n = 0; n < i.length; n++)
            this.attributes.push(
              new e.org.pkijs.simpl.ATTRIBUTE({ schema: i[n] })
            );
        }),
      (e.org.pkijs.simpl.x509.SubjectDirectoryAttributes.prototype.toSchema =
        function () {
          for (var t = new Array(), r = 0; r < this.attributes.length; r++)
            t.push(this.attributes[r].toSchema());
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.SubjectDirectoryAttributes.prototype.toJSON =
        function () {
          for (
            var e = { attributes: new Array() }, t = 0;
            t < this.attributes.length;
            t++
          )
            e.attributes.push(this.attributes[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.x509.PolicyMapping = function () {
        (this.issuerDomainPolicy = ""),
          (this.subjectDomainPolicy = ""),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.PolicyMapping.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.issuerDomainPolicy =
                arguments[0].issuerDomainPolicy || ""),
              (this.subjectDomainPolicy =
                arguments[0].subjectDomainPolicy || ""));
      }),
      (e.org.pkijs.simpl.x509.PolicyMapping.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.PolicyMapping({
            names: {
              issuerDomainPolicy: "issuerDomainPolicy",
              subjectDomainPolicy: "subjectDomainPolicy",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PolicyMapping"
          );
        (this.issuerDomainPolicy =
          r.result.issuerDomainPolicy.value_block.toString()),
          (this.subjectDomainPolicy =
            r.result.subjectDomainPolicy.value_block.toString());
      }),
      (e.org.pkijs.simpl.x509.PolicyMapping.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.OID({ value: this.issuerDomainPolicy }),
            new e.org.pkijs.asn1.OID({ value: this.subjectDomainPolicy }),
          ],
        });
      }),
      (e.org.pkijs.simpl.x509.PolicyMapping.prototype.toJSON = function () {
        return {
          issuerDomainPolicy: this.issuerDomainPolicy,
          subjectDomainPolicy: this.subjectDomainPolicy,
        };
      }),
      (e.org.pkijs.simpl.x509.PolicyMappings = function () {
        (this.mappings = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.PolicyMappings.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.mappings = arguments[0].mappings || new Array());
      }),
      (e.org.pkijs.simpl.x509.PolicyMappings.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.PolicyMappings({
            names: { mappings: "mappings" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PolicyMappings"
          );
        for (var i = r.result.mappings, n = 0; n < i.length; n++)
          this.mappings.push(
            new e.org.pkijs.simpl.x509.PolicyMapping({ schema: i[n] })
          );
      }),
      (e.org.pkijs.simpl.x509.PolicyMappings.prototype.toSchema = function () {
        for (var t = new Array(), r = 0; r < this.mappings.length; r++)
          t.push(this.mappings.toSchema());
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.PolicyMappings.prototype.toJSON = function () {
        for (
          var e = { mappings: new Array() }, t = 0;
          t < this.mappings.length;
          t++
        )
          e.mappings.push(this.mappings[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.x509.GeneralSubtree = function () {
        (this.base = new e.org.pkijs.simpl.GENERAL_NAME()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.GeneralSubtree.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.base =
                arguments[0].base || new e.org.pkijs.simpl.GENERAL_NAME()),
              "minimum" in arguments[0] &&
                (this.minimum = arguments[0].minimum),
              "maximum" in arguments[0] &&
                (this.maximum = arguments[0].maximum));
      }),
      (e.org.pkijs.simpl.x509.GeneralSubtree.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.GeneralSubtree({
            names: {
              base: { names: { block_name: "base" } },
              minimum: "minimum",
              maximum: "maximum",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for "
          );
        (this.base = new e.org.pkijs.simpl.GENERAL_NAME({
          schema: r.result.base,
        })),
          "minimum" in r.result &&
            (r.result.minimum.value_block.is_hex_only
              ? (this.minimum = r.result.minimum)
              : (this.minimum = r.result.minimum.value_block.value_dec)),
          "maximum" in r.result &&
            (r.result.maximum.value_block.is_hex_only
              ? (this.maximum = r.result.maximum)
              : (this.maximum = r.result.maximum.value_block.value_dec));
      }),
      (e.org.pkijs.simpl.x509.GeneralSubtree.prototype.toSchema = function () {
        var t = new Array();
        if ((t.push(this.base.toSchema()), "minimum" in this)) {
          var r = 0;
          (r =
            this.minimum instanceof e.org.pkijs.asn1.INTEGER
              ? this.minimum
              : new e.org.pkijs.asn1.INTEGER({ value: this.minimum })),
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: [r],
              })
            );
        }
        if ("maximum" in this) {
          var i = 0;
          (i =
            this.maximum instanceof e.org.pkijs.asn1.INTEGER
              ? this.maximum
              : new e.org.pkijs.asn1.INTEGER({ value: this.maximum })),
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 1 },
                value: [i],
              })
            );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.GeneralSubtree.prototype.toJSON = function () {
        var e = { base: this.base.toJSON() };
        return (
          "minimum" in this &&
            ("number" == typeof this.minimum
              ? (e.minimum = this.minimum)
              : (e.minimum = this.minimum.toJSON())),
          "maximum" in this &&
            ("number" == typeof this.maximum
              ? (e.maximum = this.maximum)
              : (e.maximum = this.maximum.toJSON())),
          e
        );
      }),
      (e.org.pkijs.simpl.x509.NameConstraints = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.NameConstraints.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("permittedSubtrees" in arguments[0] &&
              (this.permittedSubtrees = arguments[0].permittedSubtrees),
            "excludedSubtrees" in arguments[0] &&
              (this.excludedSubtrees = arguments[0].excludedSubtrees));
      }),
      (e.org.pkijs.simpl.x509.NameConstraints.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.NameConstraints({
            names: {
              permittedSubtrees: "permittedSubtrees",
              excludedSubtrees: "excludedSubtrees",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for NameConstraints"
          );
        if ("permittedSubtrees" in r.result) {
          this.permittedSubtrees = new Array();
          for (var i = r.result.permittedSubtrees, n = 0; n < i.length; n++)
            this.permittedSubtrees.push(
              new e.org.pkijs.simpl.x509.GeneralSubtree({ schema: i[n] })
            );
        }
        if ("excludedSubtrees" in r.result) {
          this.excludedSubtrees = new Array();
          for (var s = r.result.excludedSubtrees, n = 0; n < s.length; n++)
            this.excludedSubtrees.push(
              new e.org.pkijs.simpl.x509.GeneralSubtree({ schema: s[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.x509.NameConstraints.prototype.toSchema = function () {
        var t = new Array();
        if ("permittedSubtrees" in this) {
          for (
            var r = new Array(), i = 0;
            i < this.permittedSubtrees.length;
            i++
          )
            r.push(this.permittedSubtrees[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: r })],
            })
          );
        }
        if ("excludedSubtrees" in this) {
          for (
            var n = new Array(), i = 0;
            i < this.excludedSubtrees.length;
            i++
          )
            n.push(this.excludedSubtrees[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 1 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: n })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.NameConstraints.prototype.toJSON = function () {
        var e = {};
        if ("permittedSubtrees" in this) {
          e.permittedSubtrees = new Array();
          for (var t = 0; t < this.permittedSubtrees.length; t++)
            e.permittedSubtrees.push(this.permittedSubtrees[t].toJSON());
        }
        if ("excludedSubtrees" in this) {
          e.excludedSubtrees = new Array();
          for (var t = 0; t < this.excludedSubtrees.length; t++)
            e.excludedSubtrees.push(this.excludedSubtrees[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.x509.BasicConstraints = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.BasicConstraints.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("cA" in arguments[0] && (this.cA = arguments[0].cA),
            "pathLenConstraint" in arguments[0] &&
              (this.pathLenConstraint = arguments[0].pathLenConstraint));
      }),
      (e.org.pkijs.simpl.x509.BasicConstraints.prototype.fromSchema = function (
        t
      ) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.BasicConstraints({
            names: { cA: "cA", pathLenConstraint: "pathLenConstraint" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for BasicConstraints"
          );
        "cA" in r.result && (this.cA = r.result.cA.value_block.value),
          "pathLenConstraint" in r.result &&
            (this.pathLenConstraint =
              r.result.pathLenConstraint.value_block.value_dec);
      }),
      (e.org.pkijs.simpl.x509.BasicConstraints.prototype.toSchema =
        function () {
          var t = new Array();
          return (
            "cA" in this &&
              t.push(new e.org.pkijs.asn1.BOOLEAN({ value: this.cA })),
            "pathLenConstraint" in this &&
              t.push(
                new e.org.pkijs.asn1.INTEGER({ value: this.pathLenConstraint })
              ),
            new e.org.pkijs.asn1.SEQUENCE({ value: t })
          );
        }),
      (e.org.pkijs.simpl.x509.BasicConstraints.prototype.toJSON = function () {
        var e = {};
        return (
          "cA" in this && (e.cA = this.cA),
          "pathLenConstraint" in this &&
            (e.pathLenConstraint = this.pathLenConstraint),
          e
        );
      }),
      (e.org.pkijs.simpl.x509.PolicyQualifierInfo = function () {
        (this.policyQualifierId = ""),
          (this.qualifier = new e.org.pkijs.asn1.ANY()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.PolicyQualifierInfo.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.policyQualifierId = arguments[0].policyQualifierId || ""),
              (this.qualifier =
                arguments[0].qualifier || new e.org.pkijs.asn1.ANY()));
      }),
      (e.org.pkijs.simpl.x509.PolicyQualifierInfo.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.PolicyQualifierInfo({
              names: {
                policyQualifierId: "policyQualifierId",
                qualifier: "qualifier",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for PolicyQualifierInfo"
            );
          (this.policyQualifierId =
            r.result.policyQualifierId.value_block.toString()),
            (this.qualifier = r.result.qualifier);
        }),
      (e.org.pkijs.simpl.x509.PolicyQualifierInfo.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.OID({ value: this.policyQualifierId }),
              this.qualifier,
            ],
          });
        }),
      (e.org.pkijs.simpl.x509.PolicyQualifierInfo.prototype.toJSON =
        function () {
          return {
            policyQualifierId: this.policyQualifierId,
            qualifier: this.qualifier.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.x509.PolicyInformation = function () {
        (this.policyIdentifier = ""),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.PolicyInformation.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.policyIdentifier = arguments[0].policyIdentifier || ""),
              "policyQualifiers" in arguments[0] &&
                (this.policyQualifiers = arguments[0].policyQualifiers));
      }),
      (e.org.pkijs.simpl.x509.PolicyInformation.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.PolicyInformation({
              names: {
                policyIdentifier: "policyIdentifier",
                policyQualifiers: "policyQualifiers",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for PolicyInformation"
            );
          if (
            ((this.policyIdentifier =
              r.result.policyIdentifier.value_block.toString()),
            "policyQualifiers" in r.result)
          ) {
            this.policyQualifiers = new Array();
            for (var i = r.result.policyQualifiers, n = 0; n < i.length; n++)
              this.policyQualifiers.push(
                new e.org.pkijs.simpl.x509.PolicyQualifierInfo({ schema: i[n] })
              );
          }
        }),
      (e.org.pkijs.simpl.x509.PolicyInformation.prototype.toSchema =
        function () {
          var t = new Array();
          if (
            (t.push(new e.org.pkijs.asn1.OID({ value: this.policyIdentifier })),
            "policyQualifiers" in this)
          ) {
            for (
              var r = new Array(), i = 0;
              i < this.policyQualifiers.length;
              i++
            )
              r.push(this.policyQualifiers[i].toSchema());
            t.push(new e.org.pkijs.asn1.SEQUENCE({ value: r }));
          }
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.PolicyInformation.prototype.toJSON = function () {
        var e = { policyIdentifier: this.policyIdentifier };
        if ("policyQualifiers" in this) {
          e.policyQualifiers = new Array();
          for (var t = 0; t < this.policyQualifiers.length; t++)
            e.policyQualifiers.push(this.policyQualifiers[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.x509.CertificatePolicies = function () {
        (this.certificatePolicies = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.CertificatePolicies.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.certificatePolicies =
                arguments[0].certificatePolicies || new Array());
      }),
      (e.org.pkijs.simpl.x509.CertificatePolicies.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.CertificatePolicies({
              names: { certificatePolicies: "certificatePolicies" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for CertificatePolicies"
            );
          for (var i = r.result.certificatePolicies, n = 0; n < i.length; n++)
            this.certificatePolicies.push(
              new e.org.pkijs.simpl.x509.PolicyInformation({ schema: i[n] })
            );
        }),
      (e.org.pkijs.simpl.x509.CertificatePolicies.prototype.toSchema =
        function () {
          for (
            var t = new Array(), r = 0;
            r < this.certificatePolicies.length;
            r++
          )
            t.push(this.certificatePolicies[r].toSchema());
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.CertificatePolicies.prototype.toJSON =
        function () {
          for (
            var e = { certificatePolicies: new Array() }, t = 0;
            t < this.certificatePolicies.length;
            t++
          )
            e.certificatePolicies.push(this.certificatePolicies[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.x509.PolicyConstraints = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.PolicyConstraints.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ((this.requireExplicitPolicy =
              arguments[0].requireExplicitPolicy || 0),
            (this.inhibitPolicyMapping =
              arguments[0].inhibitPolicyMapping || 0));
      }),
      (e.org.pkijs.simpl.x509.PolicyConstraints.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.PolicyConstraints({
              names: {
                requireExplicitPolicy: "requireExplicitPolicy",
                inhibitPolicyMapping: "inhibitPolicyMapping",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for PolicyConstraints"
            );
          if ("requireExplicitPolicy" in r.result) {
            var i = r.result.requireExplicitPolicy;
            (i.id_block.tag_class = 1), (i.id_block.tag_number = 2);
            var n = i.toBER(!1),
              s = e.org.pkijs.fromBER(n);
            this.requireExplicitPolicy = s.result.value_block.value_dec;
          }
          if ("inhibitPolicyMapping" in r.result) {
            var a = r.result.inhibitPolicyMapping;
            (a.id_block.tag_class = 1), (a.id_block.tag_number = 2);
            var o = a.toBER(!1),
              c = e.org.pkijs.fromBER(o);
            this.inhibitPolicyMapping = c.result.value_block.value_dec;
          }
        }),
      (e.org.pkijs.simpl.x509.PolicyConstraints.prototype.toSchema =
        function () {
          var t = new Array();
          if ("requireExplicitPolicy" in this) {
            var r = new e.org.pkijs.asn1.INTEGER({
              value: this.requireExplicitPolicy,
            });
            (r.id_block.tag_class = 3), (r.id_block.tag_number = 0), t.push(r);
          }
          if ("inhibitPolicyMapping" in this) {
            var i = new e.org.pkijs.asn1.INTEGER({
              value: this.inhibitPolicyMapping,
            });
            (r.id_block.tag_class = 3), (r.id_block.tag_number = 1), t.push(i);
          }
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.PolicyConstraints.prototype.toJSON = function () {
        var e = {};
        return (
          "requireExplicitPolicy" in this &&
            (e.requireExplicitPolicy = this.requireExplicitPolicy),
          "inhibitPolicyMapping" in this &&
            (e.inhibitPolicyMapping = this.inhibitPolicyMapping),
          e
        );
      }),
      (e.org.pkijs.simpl.x509.ExtKeyUsage = function () {
        (this.keyPurposes = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.ExtKeyUsage.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.keyPurposes = arguments[0].keyPurposes || new Array());
      }),
      (e.org.pkijs.simpl.x509.ExtKeyUsage.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.ExtKeyUsage({
            names: { keyPurposes: "keyPurposes" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ExtKeyUsage"
          );
        for (var i = r.result.keyPurposes, n = 0; n < i.length; n++)
          this.keyPurposes.push(i[n].value_block.toString());
      }),
      (e.org.pkijs.simpl.x509.ExtKeyUsage.prototype.toSchema = function () {
        for (var t = new Array(), r = 0; r < this.keyPurposes.length; r++)
          t.push(new e.org.pkijs.asn1.OID({ value: this.keyPurposes[r] }));
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.ExtKeyUsage.prototype.toJSON = function () {
        for (
          var e = { keyPurposes: new Array() }, t = 0;
          t < this.keyPurposes.length;
          t++
        )
          e.keyPurposes.push(this.keyPurposes[t]);
        return e;
      }),
      (e.org.pkijs.simpl.x509.DistributionPoint = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.DistributionPoint.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("distributionPoint" in arguments[0] &&
              (this.distributionPoint = arguments[0].distributionPoint),
            "reasons" in arguments[0] && (this.reasons = arguments[0].reasons),
            "cRLIssuer" in arguments[0] &&
              (this.cRLIssuer = arguments[0].cRLIssuer));
      }),
      (e.org.pkijs.simpl.x509.DistributionPoint.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.DistributionPoint({
              names: {
                distributionPoint: "distributionPoint",
                distributionPoint_names: "distributionPoint_names",
                reasons: "reasons",
                cRLIssuer: "cRLIssuer",
                cRLIssuer_names: "cRLIssuer_names",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for DistributionPoint"
            );
          if ("distributionPoint" in r.result) {
            if (0 == r.result.distributionPoint.id_block.tag_number) {
              this.distributionPoint = new Array();
              for (
                var i = r.result.distributionPoint_names, n = 0;
                n < i.length;
                n++
              )
                this.distributionPoint.push(
                  new e.org.pkijs.simpl.GENERAL_NAME({ schema: i[n] })
                );
            }
            1 == r.result.distributionPoint.id_block.tag_number &&
              ((r.result.distributionPoint.id_block.tag_class = 1),
              (r.result.distributionPoint.id_block.tag_number = 16),
              (this.distributionPoint = new e.org.pkijs.simpl.RDN({
                schema: r.result.distributionPoint,
              })));
          }
          if (
            ("reasons" in r.result &&
              (this.reasons = new e.org.pkijs.asn1.BITSTRING({
                value_hex: r.result.reasons.value_block.value_hex,
              })),
            "cRLIssuer" in r.result)
          ) {
            this.cRLIssuer = new Array();
            for (var s = r.result.cRLIssuer_names, n = 0; n < s; n++)
              this.cRLIssuer.push(
                new e.org.pkijs.simpl.GENERAL_NAME({ schema: s[n] })
              );
          }
        }),
      (e.org.pkijs.simpl.x509.DistributionPoint.prototype.toSchema =
        function () {
          var t = new Array();
          if ("distributionPoint" in this) {
            var r;
            if (this.distributionPoint instanceof Array) {
              for (
                var i = new Array(), n = 0;
                n < this.distributionPoint.length;
                n++
              )
                i.push(this.distributionPoint[n].toSchema());
              r = new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: i,
              });
            } else
              r = new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 1 },
                value: [this.distributionPoint.toSchema()],
              });
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
                value: [r],
              })
            );
          }
          if (
            ("reasons" in this &&
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  id_block: { tag_class: 3, tag_number: 1 },
                  value_hex: this.reasons.value_block.value_hex,
                })
              ),
            "cRLIssuer" in this)
          ) {
            for (
              var s = new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                  name: names.cRLIssuer || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 2 },
                }),
                n = 0;
              n < this.cRLIssuer.length;
              n++
            )
              s.value_block.value.push(this.cRLIssuer[n].toSchema());
            t.push(s);
          }
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.DistributionPoint.prototype.toJSON = function () {
        var e = {};
        if ("distributionPoint" in this)
          if (this.distributionPoint instanceof Array) {
            e.distributionPoint = new Array();
            for (var t = 0; t < this.distributionPoint.length; t++)
              e.distributionPoint.push(this.distributionPoint[t].toJSON());
          } else e.distributionPoint = this.distributionPoint.toJSON();
        if (
          ("reasons" in this && (e.reasons = this.reasons.toJSON()),
          "cRLIssuer" in this)
        ) {
          e.cRLIssuer = new Array();
          for (var t = 0; t < this.cRLIssuer.length; t++)
            e.cRLIssuer.push(this.cRLIssuer[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.x509.CRLDistributionPoints = function () {
        (this.distributionPoints = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.CRLDistributionPoints.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.distributionPoints =
                arguments[0].distributionPoints || new Array());
      }),
      (e.org.pkijs.simpl.x509.CRLDistributionPoints.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.CRLDistributionPoints({
              names: { distributionPoints: "distributionPoints" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for CRLDistributionPoints"
            );
          for (var i = r.result.distributionPoints, n = 0; n < i.length; n++)
            this.distributionPoints.push(
              new e.org.pkijs.simpl.x509.DistributionPoint({ schema: i[n] })
            );
        }),
      (e.org.pkijs.simpl.x509.CRLDistributionPoints.prototype.toSchema =
        function () {
          for (
            var t = new Array(), r = 0;
            r < this.distributionPoints.length;
            r++
          )
            t.push(this.distributionPoints[r].toSchema());
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.CRLDistributionPoints.prototype.toJSON =
        function () {
          for (
            var e = { distributionPoints: new Array() }, t = 0;
            t < this.distributionPoints.length;
            t++
          )
            e.distributionPoints.push(this.distributionPoints[t].toJSON());
          return e;
        }),
      (e.org.pkijs.simpl.x509.AccessDescription = function () {
        (this.accessMethod = ""),
          (this.accessLocation = new e.org.pkijs.simpl.GENERAL_NAME()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.AccessDescription.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.accessMethod = arguments[0].accessMethod || ""),
              (this.accessLocation =
                arguments[0].accessLocation ||
                new e.org.pkijs.simpl.GENERAL_NAME()));
      }),
      (e.org.pkijs.simpl.x509.AccessDescription.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.AccessDescription({
              names: {
                accessMethod: "accessMethod",
                accessLocation: { names: { block_name: "accessLocation" } },
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for AccessDescription"
            );
          (this.accessMethod = r.result.accessMethod.value_block.toString()),
            (this.accessLocation = new e.org.pkijs.simpl.GENERAL_NAME({
              schema: r.result.accessLocation,
            }));
        }),
      (e.org.pkijs.simpl.x509.AccessDescription.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.OID({ value: this.accessMethod }),
              this.accessLocation.toSchema(),
            ],
          });
        }),
      (e.org.pkijs.simpl.x509.AccessDescription.prototype.toJSON = function () {
        return {
          accessMethod: this.accessMethod,
          accessLocation: this.accessLocation.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.x509.InfoAccess = function () {
        (this.accessDescriptions = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.x509.InfoAccess.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.accessDescriptions =
                arguments[0].accessDescriptions || new Array());
      }),
      (e.org.pkijs.simpl.x509.InfoAccess.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.x509.InfoAccess({
            names: { accessDescriptions: "accessDescriptions" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for InfoAccess"
          );
        for (var i = r.result.accessDescriptions, n = 0; n < i.length; n++)
          this.accessDescriptions.push(
            new e.org.pkijs.simpl.x509.AccessDescription({ schema: i[n] })
          );
      }),
      (e.org.pkijs.simpl.x509.InfoAccess.prototype.toSchema = function () {
        for (
          var t = new Array(), r = 0;
          r < this.accessDescriptions.length;
          r++
        )
          t.push(this.accessDescriptions[r].toSchema());
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.x509.InfoAccess.prototype.toJSON = function () {
        for (
          var e = { accessDescriptions: new Array() }, t = 0;
          t < this.accessDescriptions.length;
          t++
        )
          e.accessDescriptions.push(this.accessDescriptions[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.x509.IssuingDistributionPoint = function () {
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.x509.IssuingDistributionPoint.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ("distributionPoint" in arguments[0] &&
              (this.distributionPoint = arguments[0].distributionPoint),
            "onlyContainsUserCerts" in arguments[0] &&
              (this.onlyContainsUserCerts = arguments[0].onlyContainsUserCerts),
            "onlyContainsCACerts" in arguments[0] &&
              (this.onlyContainsCACerts = arguments[0].onlyContainsCACerts),
            "onlySomeReasons" in arguments[0] &&
              (this.onlySomeReasons = arguments[0].onlySomeReasons),
            "indirectCRL" in arguments[0] &&
              (this.indirectCRL = arguments[0].indirectCRL),
            "onlyContainsAttributeCerts" in arguments[0] &&
              (this.onlyContainsAttributeCerts =
                arguments[0].onlyContainsAttributeCerts));
      }),
      (e.org.pkijs.simpl.x509.IssuingDistributionPoint.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.x509.IssuingDistributionPoint({
              names: {
                distributionPoint: "distributionPoint",
                onlyContainsUserCerts: "onlyContainsUserCerts",
                onlyContainsCACerts: "onlyContainsCACerts",
                onlySomeReasons: "onlySomeReasons",
                indirectCRL: "indirectCRL",
                onlyContainsAttributeCerts: "onlyContainsAttributeCerts",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for IssuingDistributionPoint"
            );
          if ("distributionPoint" in r.result) {
            if (0 == r.result.distributionPoint.id_block.tag_number) {
              this.distributionPoint = new Array();
              for (
                var i = r.result.distributionPoint_names, n = 0;
                n < i.length;
                n++
              )
                this.distributionPoint.push(
                  new e.org.pkijs.simpl.GENERAL_NAME({ schema: i[n] })
                );
            }
            1 == r.result.distributionPoint.id_block.tag_number &&
              ((r.result.distributionPoint.id_block.tag_class = 1),
              (r.result.distributionPoint.id_block.tag_number = 16),
              (this.distributionPoint = new e.org.pkijs.simpl.RDN({
                schema: r.result.distributionPoint,
              })));
          }
          if ("onlyContainsUserCerts" in r.result) {
            var s = new Uint8Array(
              r.result.onlyContainsUserCerts.value_block.value_hex
            );
            this.onlyContainsUserCerts = 0 !== s[0];
          }
          if ("onlyContainsCACerts" in r.result) {
            var s = new Uint8Array(
              r.result.onlyContainsCACerts.value_block.value_hex
            );
            this.onlyContainsCACerts = 0 !== s[0];
          }
          if ("onlySomeReasons" in r.result) {
            var s = new Uint8Array(
              r.result.onlySomeReasons.value_block.value_hex
            );
            this.onlySomeReasons = s[0];
          }
          if ("indirectCRL" in r.result) {
            var s = new Uint8Array(r.result.indirectCRL.value_block.value_hex);
            this.indirectCRL = 0 !== s[0];
          }
          if ("onlyContainsAttributeCerts" in r.result) {
            var s = new Uint8Array(
              r.result.onlyContainsAttributeCerts.value_block.value_hex
            );
            this.onlyContainsAttributeCerts = 0 !== s[0];
          }
        }),
      (e.org.pkijs.simpl.x509.IssuingDistributionPoint.prototype.toSchema =
        function () {
          var t = new Array();
          if ("distributionPoint" in this) {
            var r;
            if (this.distributionPoint instanceof Array) {
              r = new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                id_block: { tag_class: 3, tag_number: 0 },
              });
              for (var i = 0; i < this.distributionPoint.length; i++)
                r.value_block.value.push(this.distributionPoint[i].toSchema());
            } else
              (r = this.distributionPoint.toSchema()),
                (r.id_block.tag_class = 3),
                (r.id_block.tag_number = 1);
            t.push(r);
          }
          if ("onlyContainsUserCerts" in this) {
            var n = new ArrayBuffer(1),
              s = new Uint8Array(n);
            (s[0] = this.onlyContainsUserCerts === !1 ? 0 : 255),
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: names.onlyContainsUserCerts || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 1 },
                  value_hex: n,
                })
              );
          }
          if ("onlyContainsCACerts" in this) {
            var n = new ArrayBuffer(1),
              s = new Uint8Array(n);
            (s[0] = this.onlyContainsCACerts === !1 ? 0 : 255),
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: names.onlyContainsUserCerts || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 2 },
                  value_hex: n,
                })
              );
          }
          if ("onlySomeReasons" in this) {
            var n = new ArrayBuffer(1),
              s = new Uint8Array(n);
            (s[0] = this.onlySomeReasons),
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: names.onlyContainsUserCerts || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 3 },
                  value_hex: n,
                })
              );
          }
          if ("indirectCRL" in this) {
            var n = new ArrayBuffer(1),
              s = new Uint8Array(n);
            (s[0] = this.indirectCRL === !1 ? 0 : 255),
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: names.onlyContainsUserCerts || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 4 },
                  value_hex: n,
                })
              );
          }
          if ("onlyContainsAttributeCerts" in this) {
            var n = new ArrayBuffer(1),
              s = new Uint8Array(n);
            (s[0] = this.onlyContainsAttributeCerts === !1 ? 0 : 255),
              t.push(
                new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                  name: names.onlyContainsUserCerts || "",
                  optional: !0,
                  id_block: { tag_class: 3, tag_number: 5 },
                  value_hex: n,
                })
              );
          }
          return new e.org.pkijs.asn1.SEQUENCE({ value: t });
        }),
      (e.org.pkijs.simpl.x509.IssuingDistributionPoint.prototype.toJSON =
        function () {
          var e = {};
          if ("distributionPoint" in this)
            if (this.distributionPoint instanceof Array) {
              e.distributionPoint = new Array();
              for (var t = 0; t < this.distributionPoint.length; t++)
                e.distributionPoint.push(this.distributionPoint[t].toJSON());
            } else e.distributionPoint = this.distributionPoint.toJSON();
          return (
            "onlyContainsUserCerts" in this &&
              (e.onlyContainsUserCerts = this.onlyContainsUserCerts),
            "onlyContainsCACerts" in this &&
              (e.onlyContainsCACerts = this.onlyContainsCACerts),
            "onlySomeReasons" in this &&
              (e.onlySomeReasons = this.onlySomeReasons.toJSON()),
            "indirectCRL" in this && (e.indirectCRL = this.indirectCRL),
            "onlyContainsAttributeCerts" in this &&
              (e.onlyContainsAttributeCerts = this.onlyContainsAttributeCerts),
            e
          );
        }),
      (e.org.pkijs.simpl.EXTENSION = function () {
        (this.extnID = ""),
          (this.critical = !1),
          (this.extnValue = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.EXTENSION.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.extnID = arguments[0].extnID || ""),
              (this.critical = arguments[0].critical || !1),
              "extnValue" in arguments[0]
                ? (this.extnValue = new e.org.pkijs.asn1.OCTETSTRING({
                    value_hex: arguments[0].extnValue,
                  }))
                : (this.extnValue = new e.org.pkijs.asn1.OCTETSTRING()),
              "parsedValue" in arguments[0] &&
                (this.parsedValue = arguments[0].parsedValue));
      }),
      (e.org.pkijs.simpl.EXTENSION.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.EXTENSION({
            names: {
              extnID: "extnID",
              critical: "critical",
              extnValue: "extnValue",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for EXTENSION"
          );
        (this.extnID = r.result.extnID.value_block.toString()),
          "critical" in r.result &&
            (this.critical = r.result.critical.value_block.value),
          (this.extnValue = r.result.extnValue);
        var r = e.org.pkijs.fromBER(this.extnValue.value_block.value_hex);
        if (r.offset !== -1)
          switch (this.extnID) {
            case "2.5.29.9":
              this.parsedValue =
                new e.org.pkijs.simpl.x509.SubjectDirectoryAttributes({
                  schema: r.result,
                });
              break;
            case "2.5.29.14":
              this.parsedValue = r.result;
              break;
            case "2.5.29.15":
              this.parsedValue = r.result;
              break;
            case "2.5.29.16":
              this.parsedValue =
                new e.org.pkijs.simpl.x509.PrivateKeyUsagePeriod({
                  schema: r.result,
                });
              break;
            case "2.5.29.17":
            case "2.5.29.18":
              this.parsedValue = new e.org.pkijs.simpl.x509.AltName({
                schema: r.result,
              });
              break;
            case "2.5.29.19":
              this.parsedValue = new e.org.pkijs.simpl.x509.BasicConstraints({
                schema: r.result,
              });
              break;
            case "2.5.29.20":
            case "2.5.29.27":
              this.parsedValue = r.result;
              break;
            case "2.5.29.21":
              this.parsedValue = r.result;
              break;
            case "2.5.29.24":
              this.parsedValue = r.result;
              break;
            case "2.5.29.28":
              this.parsedValue =
                new e.org.pkijs.simpl.x509.IssuingDistributionPoint({
                  schema: r.result,
                });
              break;
            case "2.5.29.29":
              this.parsedValue = new e.org.pkijs.simpl.GENERAL_NAMES({
                schema: r.result,
              });
              break;
            case "2.5.29.30":
              this.parsedValue = new e.org.pkijs.simpl.x509.NameConstraints({
                schema: r.result,
              });
              break;
            case "2.5.29.31":
            case "2.5.29.46":
              this.parsedValue =
                new e.org.pkijs.simpl.x509.CRLDistributionPoints({
                  schema: r.result,
                });
              break;
            case "2.5.29.32":
              this.parsedValue = new e.org.pkijs.simpl.x509.CertificatePolicies(
                { schema: r.result }
              );
              break;
            case "2.5.29.33":
              this.parsedValue = new e.org.pkijs.simpl.x509.PolicyMappings({
                schema: r.result,
              });
              break;
            case "2.5.29.35":
              this.parsedValue =
                new e.org.pkijs.simpl.x509.AuthorityKeyIdentifier({
                  schema: r.result,
                });
              break;
            case "2.5.29.36":
              this.parsedValue = new e.org.pkijs.simpl.x509.PolicyConstraints({
                schema: r.result,
              });
              break;
            case "2.5.29.37":
              this.parsedValue = new e.org.pkijs.simpl.x509.ExtKeyUsage({
                schema: r.result,
              });
              break;
            case "2.5.29.54":
              this.parsedValue = r.result;
              break;
            case "1.3.6.1.5.5.7.1.1":
            case "1.3.6.1.5.5.7.1.11":
              this.parsedValue = new e.org.pkijs.simpl.x509.InfoAccess({
                schema: r.result,
              });
          }
      }),
      (e.org.pkijs.simpl.EXTENSION.prototype.toSchema = function () {
        var t = new Array();
        return (
          t.push(new e.org.pkijs.asn1.OID({ value: this.extnID })),
          this.critical &&
            t.push(new e.org.pkijs.asn1.BOOLEAN({ value: this.critical })),
          t.push(this.extnValue),
          new e.org.pkijs.asn1.SEQUENCE({ value: t })
        );
      }),
      (e.org.pkijs.simpl.EXTENSION.prototype.toJSON = function () {
        var e = {
          extnID: this.extnID,
          critical: this.critical,
          extnValue: this.extnValue.toJSON(),
        };
        return (
          "parsedValue" in this && (e.parsedValue = this.parsedValue.toJSON()),
          e
        );
      }),
      (e.org.pkijs.simpl.EXTENSIONS = function () {
        (this.extensions_array = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.EXTENSIONS.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              (this.extensions_array =
                arguments[0].extensions_array || new Array());
      }),
      (e.org.pkijs.simpl.EXTENSIONS.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.EXTENSIONS({ names: { extensions: "extensions" } })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for EXTENSIONS"
          );
        for (var i = 0; i < r.result.extensions.length; i++)
          this.extensions_array.push(
            new e.org.pkijs.simpl.EXTENSION({ schema: r.result.extensions[i] })
          );
      }),
      (e.org.pkijs.simpl.EXTENSIONS.prototype.toSchema = function () {
        for (var t = new Array(), r = 0; r < this.extensions_array.length; r++)
          t.push(this.extensions_array[r].toSchema());
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.EXTENSIONS.prototype.toJSON = function () {
        for (
          var e = { extensions_array: new Array() }, t = 0;
          t < this.extensions_array.length;
          t++
        )
          e.extensions_array.push(this.extensions_array[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.CERT = function () {
        (this.tbs = new ArrayBuffer(0)),
          (this.serialNumber = new e.org.pkijs.asn1.INTEGER()),
          (this.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.issuer = new e.org.pkijs.simpl.RDN()),
          (this.notBefore = new e.org.pkijs.simpl.TIME()),
          (this.notAfter = new e.org.pkijs.simpl.TIME()),
          (this.subject = new e.org.pkijs.simpl.RDN()),
          (this.subjectPublicKeyInfo = new e.org.pkijs.simpl.PUBLIC_KEY_INFO()),
          (this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signatureValue = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CERT.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbs = arguments[0].tbs || new ArrayBuffer(0)),
              "version" in arguments[0] &&
                (this.version = arguments[0].version),
              (this.serialNumber =
                arguments[0].serialNumber || new e.org.pkijs.asn1.INTEGER()),
              (this.signature =
                arguments[0].signature ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.issuer =
                arguments[0].issuer || new e.org.pkijs.simpl.RDN()),
              (this.notBefore =
                arguments[0].not_before || new e.org.pkijs.simpl.TIME()),
              (this.notAfter =
                arguments[0].not_after || new e.org.pkijs.simpl.TIME()),
              (this.subject =
                arguments[0].subject || new e.org.pkijs.simpl.RDN()),
              (this.subjectPublicKeyInfo =
                arguments[0].subjectPublicKeyInfo ||
                new e.org.pkijs.simpl.PUBLIC_KEY_INFO()),
              "issuerUniqueID" in arguments[0] &&
                (this.issuerUniqueID = arguments[0].issuerUniqueID),
              "subjectUniqueID" in arguments[0] &&
                (this.subjectUniqueID = arguments[0].subjectUniqueID),
              "extensions" in arguments[0] &&
                (this.extensions = arguments[0].extensions),
              (this.signatureAlgorithm =
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signatureValue = new e.org.pkijs.asn1.BITSTRING()));
      }),
      (e.org.pkijs.simpl.CERT.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.CERT({
            names: {
              tbsCertificate: {
                names: {
                  extensions: {
                    names: { extensions: "tbsCertificate.extensions" },
                  },
                },
              },
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CERT"
          );
        if (
          ((this.tbs = r.result.tbsCertificate.value_before_decode),
          "tbsCertificate.version" in r.result &&
            (this.version =
              r.result["tbsCertificate.version"].value_block.value_dec),
          (this.serialNumber = r.result["tbsCertificate.serialNumber"]),
          (this.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result["tbsCertificate.signature"],
          })),
          (this.issuer = new e.org.pkijs.simpl.RDN({
            schema: r.result["tbsCertificate.issuer"],
          })),
          (this.notBefore = new e.org.pkijs.simpl.TIME({
            schema: r.result["tbsCertificate.notBefore"],
          })),
          (this.notAfter = new e.org.pkijs.simpl.TIME({
            schema: r.result["tbsCertificate.notAfter"],
          })),
          (this.subject = new e.org.pkijs.simpl.RDN({
            schema: r.result["tbsCertificate.subject"],
          })),
          (this.subjectPublicKeyInfo = new e.org.pkijs.simpl.PUBLIC_KEY_INFO({
            schema: r.result["tbsCertificate.subjectPublicKeyInfo"],
          })),
          "tbsCertificate.issuerUniqueID" in r.result &&
            (this.issuerUniqueID =
              r.result["tbsCertificate.issuerUniqueID"].value_block.value_hex),
          "tbsCertificate.subjectUniqueID" in r.result &&
            (this.issuerUniqueID =
              r.result["tbsCertificate.subjectUniqueID"].value_block.value_hex),
          "tbsCertificate.extensions" in r.result)
        ) {
          this.extensions = new Array();
          for (
            var i = r.result["tbsCertificate.extensions"], n = 0;
            n < i.length;
            n++
          )
            this.extensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: i[n] })
            );
        }
        (this.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.signatureAlgorithm,
        })),
          (this.signatureValue = r.result.signatureValue);
      }),
      (e.org.pkijs.simpl.CERT.prototype.encodeTBS = function () {
        var t = new Array();
        if (
          ("version" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 0 },
                value: [new e.org.pkijs.asn1.INTEGER({ value: this.version })],
              })
            ),
          t.push(this.serialNumber),
          t.push(this.signature.toSchema()),
          t.push(this.issuer.toSchema()),
          t.push(
            new e.org.pkijs.asn1.SEQUENCE({
              value: [this.notBefore.toSchema(), this.notAfter.toSchema()],
            })
          ),
          t.push(this.subject.toSchema()),
          t.push(this.subjectPublicKeyInfo.toSchema()),
          "issuerUniqueID" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 1 },
                value_hex: this.issuerUniqueID,
              })
            ),
          "subjectUniqueID" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_PRIMITIVE({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 2 },
                value_hex: this.subjectUniqueID,
              })
            ),
          "subjectUniqueID" in this &&
            t.push(
              new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
                optional: !0,
                id_block: { tag_class: 3, tag_number: 3 },
                value: [this.extensions.toSchema()],
              })
            ),
          "extensions" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.extensions.length; i++)
            r.push(this.extensions[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 3 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: r })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.CERT.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r = {};
        if (t === !1) {
          if (0 === this.tbs.length) return e.org.pkijs.schema.CERT().value[0];
          var i = e.org.pkijs.fromBER(this.tbs);
          r = i.result;
        } else r = e.org.pkijs.simpl.CERT.prototype.encodeTBS.call(this);
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [r, this.signatureAlgorithm.toSchema(), this.signatureValue],
        });
      }),
      (e.org.pkijs.simpl.CERT.prototype.verify = function () {
        var t = Promise.resolve(),
          r = {},
          i = this.signatureValue,
          n = this.tbs,
          s = this;
        if (this.issuer.isEqual(this.subject)) r = this.subjectPublicKeyInfo;
        else if (
          (arguments[0] instanceof Object &&
            "issuerCertificate" in arguments[0] &&
            (r = arguments[0].issuerCertificate.subjectPublicKeyInfo),
          r instanceof e.org.pkijs.simpl.PUBLIC_KEY_INFO == !1)
        )
          return new Promise(function (e, t) {
            t("Please provide issuer certificate as a parameter");
          });
        var a = e.org.pkijs.getCrypto();
        if ("undefined" == typeof a)
          return new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          });
        var o = e.org.pkijs.getHashAlgorithm(this.signatureAlgorithm);
        return "" === o
          ? new Promise(function (e, t) {
              t(
                "Unsupported signature algorithm: " +
                  s.signatureAlgorithm.algorithm_id
              );
            })
          : ((t = t.then(function () {
              var t = e.org.pkijs.getAlgorithmByOID(
                s.signatureAlgorithm.algorithm_id
              );
              if ("name" in t == !1)
                return new Promise(function (e, t) {
                  t(
                    "Unsupported public key algorithm: " +
                      s.signatureAlgorithm.algorithm_id
                  );
                });
              var i = t.name,
                n = e.org.pkijs.getAlgorithmParameters(i, "importkey");
              "hash" in n.algorithm && (n.algorithm.hash.name = o);
              var c = r.toSchema(),
                l = c.toBER(!1),
                u = new Uint8Array(l);
              return a.importKey("spki", u, n.algorithm, !0, n.usages);
            })),
            (t = t.then(function (t) {
              var r = e.org.pkijs.getAlgorithmParameters(
                t.algorithm.name,
                "verify"
              );
              "hash" in r.algorithm && (r.algorithm.hash.name = o);
              var c = i.value_block.value_hex;
              if ("ECDSA" === t.algorithm.name) {
                var l = e.org.pkijs.fromBER(c);
                c = e.org.pkijs.createECDSASignatureFromCMS(l.result);
              }
              if ("RSA-PSS" === t.algorithm.name) {
                var u;
                try {
                  u = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                    schema: s.signatureAlgorithm.algorithm_params,
                  });
                } catch (p) {
                  return new Promise(function (e, t) {
                    t(p);
                  });
                }
                "saltLength" in u
                  ? (r.algorithm.saltLength = u.saltLength)
                  : (r.algorithm.saltLength = 20);
                var h = "SHA-1";
                if ("hashAlgorithm" in u) {
                  var f = e.org.pkijs.getAlgorithmByOID(
                    u.hashAlgorithm.algorithm_id
                  );
                  if ("name" in f == !1)
                    return new Promise(function (e, t) {
                      t(
                        "Unrecognized hash algorithm: " +
                          u.hashAlgorithm.algorithm_id
                      );
                    });
                  h = f.name;
                }
                r.algorithm.hash.name = h;
              }
              return a.verify(
                r.algorithm,
                t,
                new Uint8Array(c),
                new Uint8Array(n)
              );
            })));
      }),
      (e.org.pkijs.simpl.CERT.prototype.sign = function (t, r) {
        var i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if ("undefined" == typeof r) r = "SHA-1";
        else {
          var n = e.org.pkijs.getOIDByAlgorithm({ name: r });
          if ("" === n)
            return new Promise(function (e, t) {
              t("Unsupported hash algorithm: " + r);
            });
        }
        var s = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((s.algorithm.hash.name = r), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            (i.signature.algorithm_id = e.org.pkijs.getOIDByAlgorithm(
              s.algorithm
            )),
              (i.signatureAlgorithm.algorithm_id = i.signature.algorithm_id);
            break;
          case "RSA-PSS":
            switch (r.toUpperCase()) {
              case "SHA-256":
                s.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                s.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                s.algorithm.saltLength = 64;
            }
            var a = {};
            if ("SHA-1" !== r.toUpperCase()) {
              var o = e.org.pkijs.getOIDByAlgorithm({ name: r });
              if ("" === o)
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + r);
                });
              (a.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: o,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (a.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: a.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== s.algorithm.saltLength &&
              (a.saltLength = s.algorithm.saltLength);
            var c = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(a);
            (i.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: "1.2.840.113549.1.1.10",
              algorithm_params: c.toSchema(),
            })),
              (i.signatureAlgorithm = i.signature);
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        i.tbs = e.org.pkijs.simpl.CERT.prototype.encodeTBS.call(this).toBER(!1);
        var l = e.org.pkijs.getCrypto();
        return "undefined" == typeof l
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : l.sign(s.algorithm, t, new Uint8Array(i.tbs)).then(
              function (t) {
                "ECDSA" === s.algorithm.name &&
                  (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (i.signatureValue = new e.org.pkijs.asn1.BITSTRING({
                    value_hex: t,
                  }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.CERT.prototype.getPublicKey = function () {
        var t,
          r = e.org.pkijs.getCrypto();
        if ("undefined" == typeof r)
          return new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          });
        if (arguments[0] instanceof Object) {
          if (!("algorithm" in arguments[0]))
            return new Promise(function (e, t) {
              t('Absent mandatory parameter "algorithm"');
            });
          t = arguments[0].algorithm;
        } else {
          var i = e.org.pkijs.getHashAlgorithm(this.signatureAlgorithm);
          if ("" === i)
            return new Promise(function (e, t) {
              t(
                "Unsupported signature algorithm: " +
                  this.signatureAlgorithm.algorithm_id
              );
            });
          var n = e.org.pkijs.getAlgorithmByOID(
            this.signatureAlgorithm.algorithm_id
          );
          if ("name" in n == !1)
            return new Promise(function (e, t) {
              t(
                "Unsupported public key algorithm: " +
                  this.signatureAlgorithm.algorithm_id
              );
            });
          var s = n.name;
          (t = e.org.pkijs.getAlgorithmParameters(s, "importkey")),
            "hash" in t.algorithm && (t.algorithm.hash.name = i);
        }
        var a = this.subjectPublicKeyInfo.toSchema(),
          o = a.toBER(!1),
          c = new Uint8Array(o);
        return r.importKey("spki", c, t.algorithm, !0, t.usages);
      }),
      (e.org.pkijs.simpl.CERT.prototype.getKeyHash = function () {
        var t = e.org.pkijs.getCrypto();
        return "undefined" == typeof t
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : t.digest(
              { name: "sha-1" },
              new Uint8Array(
                this.subjectPublicKeyInfo.subjectPublicKey.value_block.value_hex
              )
            );
      }),
      (e.org.pkijs.simpl.CERT.prototype.toJSON = function () {
        var t = {
          tbs: e.org.pkijs.bufferToHexCodes(this.tbs, 0, this.tbs.byteLength),
          serialNumber: this.serialNumber.toJSON(),
          signature: this.signature.toJSON(),
          issuer: this.issuer.toJSON(),
          notBefore: this.notBefore.toJSON(),
          notAfter: this.notAfter.toJSON(),
          subject: this.subject.toJSON(),
          subjectPublicKeyInfo: this.subjectPublicKeyInfo.toJSON(),
          signatureAlgorithm: this.signatureAlgorithm.toJSON(),
          signatureValue: this.signatureValue.toJSON(),
        };
        if (
          ("version" in this && (t.version = this.version),
          "issuerUniqueID" in this &&
            (t.issuerUniqueID = e.org.pkijs.bufferToHexCodes(
              this.issuerUniqueID,
              0,
              this.issuerUniqueID.byteLength
            )),
          "subjectUniqueID" in this &&
            (t.subjectUniqueID = e.org.pkijs.bufferToHexCodes(
              this.subjectUniqueID,
              0,
              this.subjectUniqueID.byteLength
            )),
          "extensions" in this)
        ) {
          t.extensions = new Array();
          for (var r = 0; r < this.extensions.length; r++)
            t.extensions.push(this.extensions[r].toJSON());
        }
        return t;
      }),
      (e.org.pkijs.simpl.REV_CERT = function () {
        (this.userCertificate = new e.org.pkijs.asn1.INTEGER()),
          (this.revocationDate = new e.org.pkijs.simpl.TIME()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.REV_CERT.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.userCertificate =
                arguments[0].userCertificate || new e.org.pkijs.asn1.INTEGER()),
              (this.revocationDate =
                arguments[0].revocationDate || new e.org.pkijs.simpl.TIME()),
              "crlEntryExtensions" in arguments[0] &&
                (this.crlEntryExtensions = arguments[0].crlEntryExtensions));
      }),
      (e.org.pkijs.simpl.REV_CERT.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          new e.org.pkijs.asn1.SEQUENCE({
            value: [
              new e.org.pkijs.asn1.INTEGER({ name: "userCertificate" }),
              e.org.pkijs.schema.TIME({
                names: {
                  utcTimeName: "revocationDate",
                  generalTimeName: "revocationDate",
                },
              }),
              e.org.pkijs.schema.EXTENSIONS(
                { names: { block_name: "crlEntryExtensions" } },
                !0
              ),
            ],
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for REV_CERT"
          );
        if (
          ((this.userCertificate = r.result.userCertificate),
          (this.revocationDate = new e.org.pkijs.simpl.TIME({
            schema: r.result.revocationDate,
          })),
          "crlEntryExtensions" in r.result)
        ) {
          this.crlEntryExtensions = new Array();
          for (
            var i = r.result.crlEntryExtensions.value_block.value, n = 0;
            n < i.length;
            n++
          )
            this.crlEntryExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: i[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.REV_CERT.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(this.userCertificate),
          t.push(this.revocationDate.toSchema()),
          "crlEntryExtensions" in this)
        ) {
          for (
            var r = new Array(), i = 0;
            i < this.crlEntryExtensions.length;
            i++
          )
            r.push(this.crlEntryExtensions[i].toSchema());
          t.push(new e.org.pkijs.asn1.SEQUENCE({ value: r }));
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.REV_CERT.prototype.toJSON = function () {
        var e = {
          userCertificate: this.userCertificate.toJSON(),
          revocationDate: this.revocationDate.toJSON,
        };
        if ("crlEntryExtensions" in this) {
          e.crlEntryExtensions = new Array();
          for (var t = 0; t < this.crlEntryExtensions.length; t++)
            e.crlEntryExtensions.push(this.crlEntryExtensions[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.CRL = function () {
        (this.tbs = new ArrayBuffer(0)),
          (this.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.issuer = new e.org.pkijs.simpl.RDN()),
          (this.thisUpdate = new e.org.pkijs.simpl.TIME()),
          (this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signatureValue = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.CRL.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbs = arguments[0].tbs || new ArrayBuffer(0)),
              "version" in arguments[0] &&
                (this.version = arguments[0].version),
              (this.signature =
                arguments[0].signature ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.issuer =
                arguments[0].issuer || new e.org.pkijs.simpl.RDN()),
              (this.thisUpdate =
                arguments[0].thisUpdate || new e.org.pkijs.simpl.TIME()),
              "nextUpdate" in arguments[0] &&
                (this.nextUpdate = arguments[0].nextUpdate),
              "revokedCertificates" in arguments[0] &&
                (this.revokedCertificates = arguments[0].revokedCertificates),
              "crlExtensions" in arguments[0] &&
                (this.crlExtensions = arguments[0].crlExtensions),
              (this.signatureAlgorithm =
                arguments[0].signatureAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signatureValue =
                arguments[0].signatureValue ||
                new e.org.pkijs.asn1.BITSTRING()));
      }),
      (e.org.pkijs.simpl.CRL.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(t, t, e.org.pkijs.schema.CRL());
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for CRL"
          );
        if (
          ((this.tbs = r.result.tbsCertList.value_before_decode),
          "tbsCertList.version" in r.result &&
            (this.version =
              r.result["tbsCertList.version"].value_block.value_dec),
          (this.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result["tbsCertList.signature"],
          })),
          (this.issuer = new e.org.pkijs.simpl.RDN({
            schema: r.result["tbsCertList.issuer"],
          })),
          (this.thisUpdate = new e.org.pkijs.simpl.TIME({
            schema: r.result["tbsCertList.thisUpdate"],
          })),
          "tbsCertList.nextUpdate" in r.result &&
            (this.nextUpdate = new e.org.pkijs.simpl.TIME({
              schema: r.result["tbsCertList.nextUpdate"],
            })),
          "tbsCertList.revokedCertificates" in r.result)
        ) {
          this.revokedCertificates = new Array();
          for (
            var i = r.result["tbsCertList.revokedCertificates"], n = 0;
            n < i.length;
            n++
          )
            this.revokedCertificates.push(
              new e.org.pkijs.simpl.REV_CERT({ schema: i[n] })
            );
        }
        if ("tbsCertList.extensions" in r.result) {
          this.crlExtensions = new Array();
          for (
            var s = r.result["tbsCertList.extensions"].value_block.value, n = 0;
            n < s.length;
            n++
          )
            this.crlExtensions.push(
              new e.org.pkijs.simpl.EXTENSION({ schema: s[n] })
            );
        }
        (this.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.signatureAlgorithm,
        })),
          (this.signatureValue = r.result.signatureValue);
      }),
      (e.org.pkijs.simpl.CRL.prototype.encodeTBS = function () {
        var t = new Array();
        if (
          ("version" in this &&
            t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(this.signature.toSchema()),
          t.push(this.issuer.toSchema()),
          t.push(this.thisUpdate.toSchema()),
          "nextUpdate" in this && t.push(this.nextUpdate.toSchema()),
          "revokedCertificates" in this)
        ) {
          for (
            var r = new Array(), i = 0;
            i < this.revokedCertificates.length;
            i++
          )
            r.push(this.revokedCertificates[i].toSchema());
          t.push(new e.org.pkijs.asn1.SEQUENCE({ value: r }));
        }
        if ("crlExtensions" in this) {
          for (var n = new Array(), s = 0; s < this.crlExtensions.length; s++)
            n.push(this.crlExtensions[s].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: [new e.org.pkijs.asn1.SEQUENCE({ value: n })],
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.CRL.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r;
        if (t === !1) {
          if (0 === this.tbs.length) return e.org.pkijs.schema.CRL();
          r = e.org.pkijs.fromBER(this.tbs).result;
        } else r = e.org.pkijs.simpl.CRL.prototype.encodeTBS.call(this);
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [r, this.signatureAlgorithm.toSchema(), this.signatureValue],
        });
      }),
      (e.org.pkijs.simpl.CRL.prototype.verify = function () {
        var t = Promise.resolve(),
          r = this.signatureValue,
          i = this.tbs,
          n = -1,
          s = this;
        if (
          (arguments[0] instanceof Object &&
            ("issuerCertificate" in arguments[0] &&
              (n = arguments[0].issuerCertificate.subjectPublicKeyInfo),
            "publicKeyInfo" in arguments[0] &&
              (n = arguments[0].publicKeyInfo)),
          n instanceof e.org.pkijs.simpl.PUBLIC_KEY_INFO == !1)
        )
          return new Promise(function (e, t) {
            t("Issuer's certificate must be provided as an input parameter");
          });
        var a = e.org.pkijs.getCrypto();
        if ("undefined" == typeof a)
          return new Promise(function (e, t) {
            t("Unable to create WebCrypto object");
          });
        var o = e.org.pkijs.getHashAlgorithm(this.signatureAlgorithm);
        return "" === o
          ? new Promise(function (e, t) {
              t(
                "Unsupported signature algorithm: " +
                  s.signatureAlgorithm.algorithm_id
              );
            })
          : ((t = t.then(function () {
              var t = e.org.pkijs.getAlgorithmByOID(s.signature.algorithm_id);
              if ("name" in t === "")
                return new Promise(function (e, t) {
                  t(
                    "Unsupported public key algorithm: " +
                      s.signature.algorithm_id
                  );
                });
              var r = t.name,
                i = e.org.pkijs.getAlgorithmParameters(r, "importkey");
              "hash" in i.algorithm && (i.algorithm.hash.name = o);
              var c = n.toSchema(),
                l = c.toBER(!1),
                u = new Uint8Array(l);
              return a.importKey("spki", u, i.algorithm, !0, i.usages);
            })),
            (t = t.then(function (t) {
              var n = e.org.pkijs.getAlgorithmParameters(
                t.algorithm.name,
                "verify"
              );
              "hash" in n.algorithm && (n.algorithm.hash.name = o);
              var c = r.value_block.value_hex;
              if ("ECDSA" === t.algorithm.name) {
                var l = e.org.pkijs.fromBER(c);
                c = e.org.pkijs.createECDSASignatureFromCMS(l.result);
              }
              if ("RSA-PSS" === t.algorithm.name) {
                var u;
                try {
                  u = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                    schema: s.signatureAlgorithm.algorithm_params,
                  });
                } catch (p) {
                  return new Promise(function (e, t) {
                    t(p);
                  });
                }
                "saltLength" in u
                  ? (n.algorithm.saltLength = u.saltLength)
                  : (n.algorithm.saltLength = 20);
                var h = "SHA-1";
                if ("hashAlgorithm" in u) {
                  var f = e.org.pkijs.getAlgorithmByOID(
                    u.hashAlgorithm.algorithm_id
                  );
                  if ("name" in f == !1)
                    return new Promise(function (e, t) {
                      t(
                        "Unrecognized hash algorithm: " +
                          u.hashAlgorithm.algorithm_id
                      );
                    });
                  h = f.name;
                }
                n.algorithm.hash.name = h;
              }
              return a.verify(
                n.algorithm,
                t,
                new Uint8Array(c),
                new Uint8Array(i)
              );
            })));
      }),
      (e.org.pkijs.simpl.CRL.prototype.sign = function (t, r) {
        var i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if ("undefined" == typeof r) r = "SHA-1";
        else {
          var n = e.org.pkijs.getOIDByAlgorithm({ name: r });
          if ("" === n)
            return new Promise(function (e, t) {
              t("Unsupported hash algorithm: " + r);
            });
        }
        var s = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((s.algorithm.hash.name = r), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            (i.signature.algorithm_id = e.org.pkijs.getOIDByAlgorithm(
              s.algorithm
            )),
              (i.signatureAlgorithm.algorithm_id = i.signature.algorithm_id);
            break;
          case "RSA-PSS":
            switch (r.toUpperCase()) {
              case "SHA-256":
                s.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                s.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                s.algorithm.saltLength = 64;
            }
            var a = {};
            if ("SHA-1" !== r.toUpperCase()) {
              var o = e.org.pkijs.getOIDByAlgorithm({ name: r });
              if ("" === o)
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + r);
                });
              (a.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: o,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (a.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: a.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== s.algorithm.saltLength &&
              (a.saltLength = s.algorithm.saltLength);
            var c = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(a);
            (i.signature = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: "1.2.840.113549.1.1.10",
              algorithm_params: c.toSchema(),
            })),
              (i.signatureAlgorithm = i.signature);
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        i.tbs = e.org.pkijs.simpl.CRL.prototype.encodeTBS.call(this).toBER(!1);
        var l = e.org.pkijs.getCrypto();
        return "undefined" == typeof l
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : l.sign(s.algorithm, t, new Uint8Array(i.tbs)).then(
              function (t) {
                "ECDSA" === s.algorithm.name &&
                  (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (i.signatureValue = new e.org.pkijs.asn1.BITSTRING({
                    value_hex: t,
                  }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.CRL.prototype.isCertificateRevoked = function () {
        var t = {};
        if (
          (arguments[0] instanceof Object &&
            "certificate" in arguments[0] &&
            (t = arguments[0].certificate),
          t instanceof e.org.pkijs.simpl.CERT == !1)
        )
          return !1;
        if (this.issuer.isEqual(t.issuer) === !1) return !1;
        if ("revokedCertificates" in this == !1) return !1;
        for (var r = 0; r < this.revokedCertificates.length; r++)
          if (
            this.revokedCertificates[r].userCertificate.isEqual(t.serialNumber)
          )
            return !0;
        return !1;
      }),
      (e.org.pkijs.simpl.CRL.prototype.toJSON = function () {
        var t = {
          tbs: e.org.pkijs.bufferToHexCodes(this.tbs, 0, this.tbs.byteLength),
          signature: this.signature.toJSON(),
          issuer: this.issuer.toJSON(),
          thisUpdate: this.thisUpdate.toJSON(),
          signatureAlgorithm: this.signatureAlgorithm.toJSON(),
          signatureValue: this.signatureValue.toJSON(),
        };
        if (
          ("version" in this && (t.version = this.version),
          "nextUpdate" in this && (t.nextUpdate = this.nextUpdate.toJSON()),
          "revokedCertificates" in this)
        ) {
          t.revokedCertificates = new Array();
          for (var r = 0; r < this.revokedCertificates.length; r++)
            t.revokedCertificates.push(this.revokedCertificates[r].toJSON());
        }
        if ("crlExtensions" in this) {
          t.crlExtensions = new Array();
          for (var r = 0; r < this.crlExtensions.length; r++)
            t.crlExtensions.push(this.crlExtensions[r].toJSON());
        }
        return t;
      }),
      (e.org.pkijs.simpl.ATTRIBUTE = function () {
        (this.type = ""),
          (this.values = new Array()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.ATTRIBUTE.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.type = arguments[0].type || ""),
              (this.values = arguments[0].values || new Array()));
      }),
      (e.org.pkijs.simpl.ATTRIBUTE.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.ATTRIBUTE({
            names: { type: "type", values: "values" },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for ATTRIBUTE"
          );
        (this.type = r.result.type.value_block.toString()),
          (this.values = r.result.values);
      }),
      (e.org.pkijs.simpl.ATTRIBUTE.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            new e.org.pkijs.asn1.OID({ value: this.type }),
            new e.org.pkijs.asn1.SET({ value: this.values }),
          ],
        });
      }),
      (e.org.pkijs.simpl.ATTRIBUTE.prototype.toJSON = function () {
        for (
          var e = { type: this.type, values: new Array() }, t = 0;
          t < this.values.length;
          t++
        )
          e.values.push(this.values[t].toJSON());
        return e;
      }),
      (e.org.pkijs.simpl.PKCS10 = function () {
        (this.tbs = new ArrayBuffer(0)),
          (this.version = 0),
          (this.subject = new e.org.pkijs.simpl.RDN()),
          (this.subjectPublicKeyInfo = new e.org.pkijs.simpl.PUBLIC_KEY_INFO()),
          (this.signatureAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.signatureValue = new e.org.pkijs.asn1.BITSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.PKCS10.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.tbs = arguments[0].tbs || new ArrayBuffer(0)),
              (this.version = arguments[0].version || 0),
              (this.subject =
                arguments[0].subject || new e.org.pkijs.simpl.RDN()),
              (this.subjectPublicKeyInfo =
                arguments[0].subjectPublicKeyInfo ||
                new e.org.pkijs.simpl.PUBLIC_KEY_INFO()),
              "attributes" in arguments[0] &&
                (this.attributes = arguments[0].attributes),
              (this.signatureAlgorithm =
                arguments[0].signatureAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.signatureValue =
                arguments[0].signatureValue ||
                new e.org.pkijs.asn1.BITSTRING()));
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(t, t, e.org.pkijs.schema.PKCS10());
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PKCS10"
          );
        if (
          ((this.tbs = r.result.CertificationRequestInfo.value_before_decode),
          (this.version =
            r.result["CertificationRequestInfo.version"].value_block.value_dec),
          (this.subject = new e.org.pkijs.simpl.RDN({
            schema: r.result["CertificationRequestInfo.subject"],
          })),
          (this.subjectPublicKeyInfo = new e.org.pkijs.simpl.PUBLIC_KEY_INFO({
            schema: r.result["CertificationRequestInfo.subjectPublicKeyInfo"],
          })),
          "CertificationRequestInfo.attributes" in r.result)
        ) {
          this.attributes = new Array();
          for (
            var i = r.result["CertificationRequestInfo.attributes"], n = 0;
            n < i.length;
            n++
          )
            this.attributes.push(
              new e.org.pkijs.simpl.ATTRIBUTE({ schema: i[n] })
            );
        }
        (this.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
          schema: r.result.signatureAlgorithm,
        })),
          (this.signatureValue = r.result.signatureValue);
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.encodeTBS = function () {
        var t = new Array();
        if (
          (t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(this.subject.toSchema()),
          t.push(this.subjectPublicKeyInfo.toSchema()),
          "attributes" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.attributes.length; i++)
            r.push(this.attributes[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              id_block: { tag_class: 3, tag_number: 0 },
              value: r,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.toSchema = function (t) {
        "undefined" == typeof t && (t = !1);
        var r;
        if (t === !1) {
          if (0 === this.tbs.length) return e.org.pkijs.schema.PKCS10();
          r = e.org.pkijs.fromBER(this.tbs).result;
        } else r = e.org.pkijs.simpl.PKCS10.prototype.encodeTBS.call(this);
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [r, this.signatureAlgorithm.toSchema(), this.signatureValue],
        });
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.verify = function () {
        var t = this,
          r = "",
          i = Promise.resolve(),
          n = this.subjectPublicKeyInfo,
          s = this.signatureValue,
          a = this.tbs,
          o = e.org.pkijs.getCrypto();
        return "undefined" == typeof o
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : ((r = e.org.pkijs.getHashAlgorithm(this.signatureAlgorithm)),
            "" === r
              ? new Promise(function (e, r) {
                  r(
                    "Unsupported signature algorithm: " +
                      t.signatureAlgorithm.algorithm_id
                  );
                })
              : ((i = i.then(function () {
                  var i = e.org.pkijs.getAlgorithmByOID(
                    t.signatureAlgorithm.algorithm_id
                  );
                  if ("name" in i == !1)
                    return new Promise(function (e, r) {
                      r(
                        "Unsupported public key algorithm: " +
                          t.signatureAlgorithm.algorithm_id
                      );
                    });
                  var s = i.name,
                    a = e.org.pkijs.getAlgorithmParameters(s, "importkey");
                  "hash" in a.algorithm && (a.algorithm.hash.name = r);
                  var c = n.toSchema(),
                    l = c.toBER(!1),
                    u = new Uint8Array(l);
                  return o.importKey("spki", u, a.algorithm, !0, a.usages);
                })),
                (i = i.then(function (i) {
                  var n = e.org.pkijs.getAlgorithmParameters(
                    i.algorithm.name,
                    "verify"
                  );
                  "hash" in n.algorithm && (n.algorithm.hash.name = r);
                  var c = s.value_block.value_hex;
                  if ("ECDSA" === i.algorithm.name) {
                    var l = e.org.pkijs.fromBER(c);
                    c = e.org.pkijs.createECDSASignatureFromCMS(l.result);
                  }
                  if ("RSA-PSS" === i.algorithm.name) {
                    var u;
                    try {
                      u = new e.org.pkijs.simpl.x509.RSASSA_PSS_params({
                        schema: t.signatureAlgorithm.algorithm_params,
                      });
                    } catch (p) {
                      return new Promise(function (e, t) {
                        t(p);
                      });
                    }
                    "saltLength" in u
                      ? (n.algorithm.saltLength = u.saltLength)
                      : (n.algorithm.saltLength = 20);
                    var h = "SHA-1";
                    if ("hashAlgorithm" in u) {
                      var f = e.org.pkijs.getAlgorithmByOID(
                        u.hashAlgorithm.algorithm_id
                      );
                      if ("name" in f == !1)
                        return new Promise(function (e, t) {
                          t(
                            "Unrecognized hash algorithm: " +
                              u.hashAlgorithm.algorithm_id
                          );
                        });
                      h = f.name;
                    }
                    n.algorithm.hash.name = h;
                  }
                  return o.verify(
                    n.algorithm,
                    i,
                    new Uint8Array(c),
                    new Uint8Array(a)
                  );
                }))));
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.sign = function (t, r) {
        var i = this;
        if ("undefined" == typeof t)
          return new Promise(function (e, t) {
            t("Need to provide a private key for signing");
          });
        if ("undefined" == typeof r) r = "SHA-1";
        else {
          var n = e.org.pkijs.getOIDByAlgorithm({ name: r });
          if ("" === n)
            return new Promise(function (e, t) {
              t("Unsupported hash algorithm: " + r);
            });
        }
        var s = e.org.pkijs.getAlgorithmParameters(t.algorithm.name, "sign");
        switch (((s.algorithm.hash.name = r), t.algorithm.name.toUpperCase())) {
          case "RSASSA-PKCS1-V1_5":
          case "ECDSA":
            i.signatureAlgorithm.algorithm_id = e.org.pkijs.getOIDByAlgorithm(
              s.algorithm
            );
            break;
          case "RSA-PSS":
            switch (r.toUpperCase()) {
              case "SHA-256":
                s.algorithm.saltLength = 32;
                break;
              case "SHA-384":
                s.algorithm.saltLength = 48;
                break;
              case "SHA-512":
                s.algorithm.saltLength = 64;
            }
            var a = {};
            if ("SHA-1" !== r.toUpperCase()) {
              var o = e.org.pkijs.getOIDByAlgorithm({ name: r });
              if ("" === o)
                return new Promise(function (e, t) {
                  t("Unsupported hash algorithm: " + r);
                });
              (a.hashAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                algorithm_id: o,
                algorithm_params: new e.org.pkijs.asn1.NULL(),
              })),
                (a.maskGenAlgorithm =
                  new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
                    algorithm_id: "1.2.840.113549.1.1.8",
                    algorithm_params: a.hashAlgorithm.toSchema(),
                  }));
            }
            20 !== s.algorithm.saltLength &&
              (a.saltLength = s.algorithm.saltLength);
            var c = new e.org.pkijs.simpl.x509.RSASSA_PSS_params(a);
            i.signatureAlgorithm = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              algorithm_id: "1.2.840.113549.1.1.10",
              algorithm_params: c.toSchema(),
            });
            break;
          default:
            return new Promise(function (e, r) {
              r("Unsupported signature algorithm: " + t.algorithm.name);
            });
        }
        i.tbs = e.org.pkijs.simpl.PKCS10.prototype.encodeTBS
          .call(this)
          .toBER(!1);
        var l = e.org.pkijs.getCrypto();
        return "undefined" == typeof l
          ? new Promise(function (e, t) {
              t("Unable to create WebCrypto object");
            })
          : l.sign(s.algorithm, t, new Uint8Array(i.tbs)).then(
              function (t) {
                "ECDSA" === s.algorithm.name &&
                  (t = e.org.pkijs.createCMSECDSASignature(t)),
                  (i.signatureValue = new e.org.pkijs.asn1.BITSTRING({
                    value_hex: t,
                  }));
              },
              function (e) {
                return new Promise(function (t, r) {
                  r("Signing error: " + e);
                });
              }
            );
      }),
      (e.org.pkijs.simpl.PKCS10.prototype.toJSON = function () {
        var t = {
          tbs: e.org.pkijs.bufferToHexCodes(this.tbs, 0, this.tbs.byteLength),
          version: this.version,
          subject: this.subject.toJSON(),
          subjectPublicKeyInfo: this.subjectPublicKeyInfo.toJSON(),
          signatureAlgorithm: this.signatureAlgorithm.toJSON(),
          signatureValue: this.signatureValue.toJSON(),
        };
        if ("attributes" in this) {
          t.attributes = new Array();
          for (var r = 0; r < this.attributes.length; r++)
            t.attributes.push(this.attributes[r].toJSON());
        }
        return t;
      }),
      (e.org.pkijs.simpl.PKCS8 = function () {
        (this.version = 0),
          (this.privateKeyAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          (this.privateKey = new e.org.pkijs.asn1.OCTETSTRING()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.PKCS8.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.version = arguments[0].version || 0),
              (this.privateKeyAlgorithm =
                arguments[0].privateKeyAlgorithm ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
              (this.privateKey =
                arguments[0].privateKey || new e.org.pkijs.asn1.OCTETSTRING()),
              "attributes" in arguments[0] &&
                (this.attributes = arguments[0].attributes));
      }),
      (e.org.pkijs.simpl.PKCS8.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.PKCS8({
            names: {
              version: "version",
              privateKeyAlgorithm: {
                names: { block_name: "privateKeyAlgorithm" },
              },
              privateKey: "privateKey",
              attributes: "attributes",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PKCS8"
          );
        if (
          ((this.version = r.result.version.value_block.value_dec),
          (this.privateKeyAlgorithm =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
              schema: r.result.privateKeyAlgorithm,
            })),
          (this.privateKey = r.result.privateKey),
          "attributes" in r.result)
        ) {
          this.attributes = new Array();
          for (var i = r.result.attributes, n = 0; n < i.length; n++)
            this.attributes.push(
              new e.org.pkijs.simpl.ATTRIBUTE({ schema: i[n] })
            );
        }
      }),
      (e.org.pkijs.simpl.PKCS8.prototype.toSchema = function () {
        var t = new Array();
        if (
          (t.push(new e.org.pkijs.asn1.INTEGER({ value: this.version })),
          t.push(this.privateKeyAlgorithm.toSchema()),
          t.push(this.privateKey),
          "attributes" in this)
        ) {
          for (var r = new Array(), i = 0; i < this.attributes.length; i++)
            r.push(this.attributes[i].toSchema());
          t.push(
            new e.org.pkijs.asn1.ASN1_CONSTRUCTED({
              optional: !0,
              id_block: { tag_class: 3, tag_number: 0 },
              value: r,
            })
          );
        }
        return new e.org.pkijs.asn1.SEQUENCE({ value: t });
      }),
      (e.org.pkijs.simpl.PKCS8.prototype.toJSON = function () {
        var e = {
          version: this.version,
          privateKeyAlgorithm: this.privateKeyAlgorithm.toJSON(),
          privateKey: this.privateKey.toJSON(),
        };
        if ("attributes" in this) {
          e.attributes = new Array();
          for (var t = 0; t < this.attributes.length; t++)
            e.attributes.push(this.attributes[t].toJSON());
        }
        return e;
      }),
      (e.org.pkijs.simpl.CERT_CHAIN = function () {
        (this.trusted_certs = new Array()),
          (this.certs = new Array()),
          (this.crls = new Array()),
          arguments[0] instanceof Object &&
            ((this.trusted_certs = arguments[0].trusted_certs || new Array()),
            (this.certs = arguments[0].certs || new Array()),
            (this.crls = arguments[0].crls || new Array()));
      }),
      (e.org.pkijs.simpl.CERT_CHAIN.prototype.sort = function () {
        function e(e, t) {
          function r(e) {
            return a.crls[e].verify({ issuerCertificate: t }).then(
              function (t) {
                return t === !0
                  ? new Promise(function (t, r) {
                      t(a.crls[e]);
                    })
                  : (e++,
                    e < a.crls.length
                      ? r(e)
                      : new Promise(function (e, t) {
                          t({
                            result: !1,
                            result_code: 9,
                            result_message:
                              "Unable to find CRL for issuer's certificate",
                          });
                        }));
              },
              function (e) {
                return new Promise(function (e, t) {
                  t({
                    result: !1,
                    result_code: 10,
                    result_message:
                      "Unable to find CRL for issuer's certificate",
                  });
                });
              }
            );
          }
          var i = Promise.resolve();
          return (
            (i = i.then(function () {
              if (e.notBefore.value > s || e.notAfter.value < s)
                return new Promise(function (e, t) {
                  t({
                    result: !1,
                    result_code: 8,
                    result_message:
                      "Certificate validity period is out of checking date",
                  });
                });
            })),
            0 === a.crls.length
              ? i.then(function () {
                  return new Promise(function (e, t) {
                    e();
                  });
                })
              : ((i = i.then(function () {
                  return r(0);
                })),
                (i = i.then(
                  function (t) {
                    return t.isCertificateRevoked({ certificate: e }) === !0
                      ? new Promise(function (e, t) {
                          t({
                            result: !1,
                            result_code: 11,
                            result_message: "Subject certificate was revoked",
                          });
                        })
                      : new Promise(function (e, t) {
                          e();
                        });
                  },
                  function (e) {
                    return new Promise(function (e, t) {
                      e();
                    });
                  }
                )))
          );
        }
        function t() {
          return r(m, 0).then(
            function (r) {
              if (
                (i.push(n[r]),
                (m = n[r]),
                n.splice(r, 1),
                m.issuer.isEqual(m.subject) === !0)
              ) {
                for (
                  var s = 0 === a.trusted_certs.length, o = 0;
                  o < a.trusted_certs.length;
                  o++
                )
                  if (
                    m.issuer.isEqual(a.trusted_certs[o].issuer) === !0 &&
                    m.subject.isEqual(a.trusted_certs[o].subject) === !0 &&
                    m.serialNumber.isEqual(a.trusted_certs[o].serialNumber) ===
                      !0
                  ) {
                    s = !0;
                    break;
                  }
                return s === !1
                  ? new Promise(function (e, t) {
                      t({
                        result: !1,
                        result_code: 22,
                        result_message:
                          'Self-signed root certificate not in "trusted certificates" array',
                      });
                    })
                  : m.verify().then(
                      function (t) {
                        return t === !0
                          ? e(m, m).then(
                              function () {
                                return new Promise(function (e, t) {
                                  e(i);
                                });
                              },
                              function (e) {
                                return new Promise(function (t, r) {
                                  r({
                                    result: !1,
                                    result_code: 12,
                                    result_message: e,
                                  });
                                });
                              }
                            )
                          : new Promise(function (e, t) {
                              t({
                                result: !1,
                                result_code: 13,
                                result_message:
                                  "Unable to build certificate chain - signature of root certificate is invalid",
                              });
                            });
                      },
                      function (e) {
                        return new Promise(function (t, r) {
                          r({ result: !1, result_code: 14, result_message: e });
                        });
                      }
                    );
              }
              return n.length > 0
                ? t()
                : 0 !== a.trusted_certs.length
                ? ((n = a.trusted_certs.splice(0)), t())
                : new Promise(function (e, t) {
                    t({
                      result: !1,
                      result_code: 23,
                      result_message: "Root certificate not found",
                    });
                  });
            },
            function (e) {
              return new Promise(function (t, r) {
                r(e);
              });
            }
          );
        }
        function r(t, i) {
          return n[i].subject.isEqual(t.issuer) === !0
            ? t.verify({ issuerCertificate: n[i] }).then(
                function (s) {
                  return s === !0
                    ? e(t, n[i]).then(
                        function () {
                          return new Promise(function (e, t) {
                            e(i);
                          });
                        },
                        function (e) {
                          return new Promise(function (t, r) {
                            r({
                              result: !1,
                              result_code: 16,
                              result_message: e,
                            });
                          });
                        }
                      )
                    : i < n.length - 1
                    ? r(t, i + 1)
                    : new Promise(function (e, t) {
                        t({
                          result: !1,
                          result_code: 17,
                          result_message:
                            "Unable to build certificate chain - incomplete certificate chain or signature of some certificate is invalid",
                        });
                      });
                },
                function (e) {
                  return new Promise(function (e, t) {
                    t({
                      result: !1,
                      result_code: 18,
                      result_message:
                        "Unable to build certificate chain - error during certificate signature verification",
                    });
                  });
                }
              )
            : i < n.length - 1
            ? r(t, i + 1)
            : new Promise(function (e, t) {
                t({
                  result: !1,
                  result_code: 19,
                  result_message:
                    "Unable to build certificate chain - incomplete certificate chain",
                });
              });
        }
        var i = new Array(),
          n = this.certs.slice(0),
          s = new Date(),
          a = this;
        if (0 === n.length)
          return new Promise(function (e, t) {
            t({
              result: !1,
              result_code: 2,
              result_message: "Certificate's array can not be empty",
            });
          });
        for (var o = -1, c = 0; c < n.length; c++) {
          var l = !1;
          if ("extensions" in n[c]) {
            for (
              var u = !1, p = !1, h = !1, f = 0;
              f < n[c].extensions.length;
              f++
            ) {
              if (
                n[c].extensions[f].critical === !0 &&
                "parsedValue" in n[c].extensions[f] == !1
              )
                return new Promise(function (e, t) {
                  t({
                    result: !1,
                    result_code: 6,
                    result_message:
                      "Unable to parse critical certificate extension: " +
                      n[c].extensions[f].extnID,
                  });
                });
              if ("2.5.29.15" === n[c].extensions[f].extnID) {
                p = !0;
                var g = new Uint8Array(
                  n[c].extensions[f].parsedValue.value_block.value_hex
                );
                4 === (4 & g[0]) && (u = !0), 2 === (2 & g[0]) && (h = !0);
              }
              "2.5.29.19" === n[c].extensions[f].extnID &&
                "cA" in n[c].extensions[f].parsedValue &&
                n[c].extensions[f].parsedValue.cA === !0 &&
                (l = !0);
            }
            if (u === !0 && l === !1)
              return new Promise(function (e, t) {
                t({
                  result: !1,
                  result_code: 3,
                  result_message:
                    'Unable to build certificate chain - using "keyCertSign" flag set without BasicConstaints',
                });
              });
            if (p === !0 && l === !0 && u === !1)
              return new Promise(function (e, t) {
                t({
                  result: !1,
                  result_code: 4,
                  result_message:
                    'Unable to build certificate chain - "keyCertSign" flag was not set',
                });
              });
            if (l === !0 && p === !0 && h === !1)
              return new Promise(function (e, t) {
                t({
                  result: !1,
                  result_code: 5,
                  result_message:
                    'Unable to build certificate chain - intermediate certificate must have "cRLSign" key usage flag',
                });
              });
          }
          if (l === !1) {
            if (0 !== i.length)
              return new Promise(function (e, t) {
                t({
                  result: !1,
                  result_code: 7,
                  result_message:
                    "Unable to build certificate chain - more than one possible end-user certificate",
                });
              });
            i.push(n[c]), (o = c);
          }
        }
        if ((n.splice(o, 1), 0 === i.length))
          return new Promise(function (e, t) {
            t({
              result: !1,
              result_code: 1,
              result_message: "Can't find end-user certificate",
            });
          });
        if (0 === n.length) {
          if (i[0].issuer.isEqual(i[0].subject) === !0)
            return new Promise(function (e, t) {
              e(i);
            });
          if (0 === this.trusted_certs.length)
            return new Promise(function (e, t) {
              t({
                result: !1,
                result_code: 70,
                result_message: "Can't find root certificate",
              });
            });
          n = a.trusted_certs.splice(0);
        }
        var m = i[0];
        return t();
      }),
      (e.org.pkijs.simpl.CERT_CHAIN.prototype.verify = function () {
        if (0 === this.certs.length)
          return new Promise(function (e, t) {
            t("Empty certificate array");
          });
        var t = Promise.resolve(),
          r = this,
          i = new Array();
        i.push("2.5.29.32.0");
        var n = !1,
          s = !1,
          a = !1,
          o = new Array(),
          c = new Array(),
          l = new Array();
        new Date();
        arguments[0] instanceof Object &&
          ("initial_policy_set" in arguments[0] &&
            (i = arguments[0].initial_policy_set),
          "initial_explicit_policy" in arguments[0] &&
            (n = arguments[0].initial_explicit_policy),
          "initial_policy_mapping_inhibit" in arguments[0] &&
            (s = arguments[0].initial_policy_mapping_inhibit),
          "initial_inhibit_policy" in arguments[0] &&
            (a = arguments[0].initial_inhibit_policy),
          "initial_permitted_subtrees_set" in arguments[0] &&
            (o = arguments[0].initial_permitted_subtrees_set),
          "initial_excluded_subtrees_set" in arguments[0] &&
            (c = arguments[0].initial_excluded_subtrees_set),
          "initial_required_name_forms" in arguments[0] &&
            (l = arguments[0].initial_required_name_forms));
        var u = n,
          p = s,
          h = a,
          f = new Array(3);
        (f[0] = !1), (f[1] = !1), (f[2] = !1);
        var g = 0,
          m = 0,
          d = 0,
          y = o,
          k = c,
          v = l,
          E = 1;
        return (
          (t = e.org.pkijs.simpl.CERT_CHAIN.prototype.sort
            .call(this)
            .then(function (e) {
              r.certs = e;
            })),
          (t = t.then(function () {
            var e = new Array();
            e.push("2.5.29.32.0");
            for (
              var t = new Array(), n = new Array(r.certs.length - 1), s = 0;
              s < r.certs.length - 1;
              s++
            )
              n[s] = !0;
            t.push(n);
            for (
              var a = new Array(r.certs.length - 1),
                o = new Array(r.certs.length - 1),
                c = r.certs.length - 2;
              c >= 0;
              c--, E++
            )
              if ("extensions" in r.certs[c]) {
                for (var l = 0; l < r.certs[c].extensions.length; l++) {
                  if ("2.5.29.32" === r.certs[c].extensions[l].extnID) {
                    o[c] = r.certs[c].extensions[l].parsedValue;
                    for (
                      var y = 0;
                      y <
                      r.certs[c].extensions[l].parsedValue.certificatePolicies
                        .length;
                      y++
                    ) {
                      for (var k = -1, v = 0; v < e.length; v++)
                        if (
                          r.certs[c].extensions[l].parsedValue
                            .certificatePolicies[y].policyIdentifier === e[v]
                        ) {
                          k = v;
                          break;
                        }
                      if (k === -1) {
                        e.push(
                          r.certs[c].extensions[l].parsedValue
                            .certificatePolicies[y].policyIdentifier
                        );
                        var S = new Array(r.certs.length - 1);
                        (S[c] = !0), t.push(S);
                      } else t[k][c] = !0;
                    }
                  }
                  "2.5.29.33" === r.certs[c].extensions[l].extnID &&
                    (a[c] = r.certs[c].extensions[l].parsedValue),
                    "2.5.29.36" === r.certs[c].extensions[l].extnID &&
                      0 == u &&
                      (0 ===
                      r.certs[c].extensions[l].parsedValue.requireExplicitPolicy
                        ? (u = !0)
                        : f[0] === !1
                        ? ((f[0] = !0),
                          (g =
                            r.certs[c].extensions[l].parsedValue
                              .requireExplicitPolicy))
                        : (g =
                            g >
                            r.certs[c].extensions[l].parsedValue
                              .requireExplicitPolicy
                              ? r.certs[c].extensions[l].parsedValue
                                  .requireExplicitPolicy
                              : g),
                      0 ===
                      r.certs[c].extensions[l].parsedValue.inhibitPolicyMapping
                        ? (p = !0)
                        : f[1] === !1
                        ? ((f[1] = !0),
                          (m =
                            r.certs[c].extensions[l].parsedValue
                              .requireExplicitPolicy))
                        : (m =
                            m >
                            r.certs[c].extensions[l].parsedValue
                              .requireExplicitPolicy
                              ? r.certs[c].extensions[l].parsedValue
                                  .requireExplicitPolicy
                              : m)),
                    "2.5.29.54" === r.certs[c].extensions[l].extnID &&
                      h === !1 &&
                      (0 ===
                      r.certs[c].extensions[l].parsedValue.value_block.value_dec
                        ? (h = !0)
                        : f[2] === !1
                        ? ((f[2] = !0),
                          (d =
                            r.certs[c].extensions[l].parsedValue.value_block
                              .value_dec))
                        : (d =
                            d >
                            r.certs[c].extensions[l].parsedValue.value_block
                              .value_dec
                              ? r.certs[c].extensions[l].parsedValue.value_block
                                  .value_dec
                              : d));
                }
                if (
                  (h === !0 && delete t[0][c],
                  "undefined" != typeof o[c] &&
                    "undefined" != typeof a[c] &&
                    p === !1)
                )
                  for (var _ = 0; _ < o[c].certificatePolicies.length; _++) {
                    for (var b = "", I = 0; I < a[c].mappings.length; I++) {
                      if (
                        a[c].mappings[I].subjectDomainPolicy ===
                        o[c].certificatePolicies[_].policyIdentifier
                      ) {
                        b = a[c].mappings[I].issuerDomainPolicy;
                        break;
                      }
                      if (
                        a[c].mappings[I].issuerDomainPolicy ===
                        o[c].certificatePolicies[_].policyIdentifier
                      ) {
                        b = a[c].mappings[I].subjectDomainPolicy;
                        break;
                      }
                    }
                    if ("" !== b) {
                      for (var A = -1, C = 0; C < e.length; C++)
                        if (e[C] === b) {
                          A = C;
                          break;
                        }
                      A !== -1 && (t[A][c] = !0);
                    }
                  }
                u === !1 &&
                  f[0] === !0 &&
                  (g--, 0 === g && ((u = !0), (f[0] = !1))),
                  p === !1 &&
                    f[1] === !0 &&
                    (m--, 0 === m && ((p = !0), (f[1] = !1))),
                  h === !1 &&
                    f[2] === !0 &&
                    (d--, 0 === d && ((h = !0), (f[2] = !1)));
              }
            for (var T = new Array(), c = 0; c < t.length; c++) {
              for (var N = !0, l = 0; l < r.certs.length - 1; l++)
                if ("undefined" == typeof t[c][l]) {
                  N = !1;
                  break;
                }
              N === !0 && T.push(e[c]);
            }
            for (var j = new Array(), c = 0; c < T.length; c++)
              for (var l = 0; l < i.length; l++)
                if (i[l] === T[c]) {
                  j.push(i[l]);
                  break;
                }
            return {
              result: j.length > 0,
              result_code: 0,
              result_message:
                j.length > 0
                  ? ""
                  : 'Zero "user_constr_policies" array, no intersections with "auth_constr_policies"',
              auth_constr_policies: T,
              user_constr_policies: j,
              explicit_policy_indicator: u,
              policy_mappings: a,
            };
          })),
          (t = t.then(function (t) {
            function i(t, r) {
              var i = e.org.pkijs.stringPrep(t),
                n = e.org.pkijs.stringPrep(r),
                s = i.split("."),
                a = n.split("."),
                o = s.length,
                c = a.length;
              if (0 === o || 0 === c || o < c) return !1;
              for (var l = 0; l < o; l++) if (0 === s[l].length) return !1;
              for (var l = 0; l < c; l++)
                if (0 === a[l].length) {
                  if (0 === l) {
                    if (1 === c) return !1;
                    continue;
                  }
                  return !1;
                }
              for (var l = 0; l < c; l++)
                if (
                  0 !== a[c - 1 - l].length &&
                  0 !== s[o - 1 - l].localeCompare(a[c - 1 - l])
                )
                  return !1;
              return !0;
            }
            function n(t, r) {
              var n = e.org.pkijs.stringPrep(t),
                s = e.org.pkijs.stringPrep(r),
                a = n.split("@"),
                o = s.split("@");
              if (0 === a.length || 0 === o.length || a.length < o.length)
                return !1;
              if (1 === o.length) {
                var c = i(a[1], o[0]);
                if (c) {
                  var l = a[1].split("."),
                    u = o[0].split(".");
                  return 0 === u[0].length || l.length === u.length;
                }
                return !1;
              }
              return 0 === n.localeCompare(s);
            }
            function s(t, r) {
              var n = e.org.pkijs.stringPrep(t),
                s = e.org.pkijs.stringPrep(r),
                a = n.split("/"),
                o = s.split("/");
              if (o.length > 1) return !1;
              if (a.length > 1)
                for (var c = 0; c < a.length; c++)
                  if (a[c].length > 0 && ":" !== a[c].charAt(a[c].length - 1)) {
                    var l = a[c].split(":");
                    n = l[0];
                    break;
                  }
              var u = i(n, s);
              if (u) {
                var p = n.split("."),
                  h = s.split(".");
                return 0 === h[0].length || p.length === h.length;
              }
              return !1;
            }
            function a(e, t) {
              var r = new Uint8Array(e.value_block.value_hex),
                i = new Uint8Array(t.value_block.value_hex);
              if (4 === r.length && 8 === i.length) {
                for (var n = 0; n < 4; n++)
                  if ((r[n] ^ i[n]) & i[n + 4]) return !1;
                return !0;
              }
              if (16 === r.length && 32 === i.length) {
                for (var n = 0; n < 16; n++)
                  if ((r[n] ^ i[n]) & i[n + 16]) return !1;
                return !0;
              }
              return !1;
            }
            function o(e, t) {
              if (
                0 === e.types_and_values.length ||
                0 === t.types_and_values.length
              )
                return !0;
              if (e.types_and_values.length < t.types_and_values.length)
                return !1;
              for (
                var r = !0, i = 0, n = 0;
                n < t.types_and_values.length;
                n++
              ) {
                for (var s = !1, a = i; a < e.types_and_values.length; a++)
                  if (
                    ((s = e.types_and_values[a].isEqual(t.types_and_values[n])),
                    e.types_and_values[a].type === t.types_and_values[n].type &&
                      (r = r && s),
                    s === !0)
                  ) {
                    if (0 === i || i === a) {
                      i = a + 1;
                      break;
                    }
                    return !1;
                  }
                if (s === !1) return !1;
              }
              return 0 !== i && r;
            }
            if (t.result === !1) return t;
            E = 1;
            for (var c = r.certs.length - 2; c >= 0; c--, E++) {
              var l = new Array(),
                u = new Array(),
                p = new Array();
              if ("extensions" in r.certs[c])
                for (var h = 0; h < r.certs[c].extensions.length; h++)
                  "2.5.29.30" === r.certs[c].extensions[h].extnID &&
                    ("permittedSubtrees" in
                      r.certs[c].extensions[h].parsedValue &&
                      (u = u.concat(
                        r.certs[c].extensions[h].parsedValue.permittedSubtrees
                      )),
                    "excludedSubtrees" in
                      r.certs[c].extensions[h].parsedValue &&
                      (p = p.concat(
                        r.certs[c].extensions[h].parsedValue.excludedSubtrees
                      ))),
                    "2.5.29.17" === r.certs[c].extensions[h].extnID &&
                      (l = l.concat(
                        r.certs[c].extensions[h].parsedValue.altNames
                      )),
                    "1.2.840.113549.1.9.1" ===
                      r.certs[c].extensions[h].extnID &&
                      email_addresses.push(
                        r.certs[c].extensions[h].parsedValue.value
                      );
              for (var f = !(v.length > 0), h = 0; h < v.length; h++)
                switch (v[h].base.NameType) {
                  case 4:
                    if (
                      v[h].base.Name.types_and_values.length !==
                      r.certs[c].subject.types_and_values.length
                    )
                      continue;
                    f = !0;
                    for (
                      var g = 0;
                      g < r.certs[c].subject.types_and_values.length;
                      g++
                    )
                      if (
                        r.certs[c].subject.types_and_values[g].type !==
                        v[h].base.Name.types_and_values[g].type
                      ) {
                        f = !1;
                        break;
                      }
                    if (f === !0) break;
                }
              if (f === !1)
                return (
                  (t.result = !1),
                  (t.result_code = 21),
                  (t.result_message = "No neccessary name form found"),
                  new Promise(function (e, r) {
                    r(t);
                  })
                );
              var m = new Array();
              (m[0] = new Array()),
                (m[1] = new Array()),
                (m[2] = new Array()),
                (m[3] = new Array()),
                (m[4] = new Array());
              for (var h = 0; h < y.length; h++)
                switch (y[h].base.NameType) {
                  case 1:
                    m[0].push(y[h]);
                    break;
                  case 2:
                    m[1].push(y[h]);
                    break;
                  case 4:
                    m[2].push(y[h]);
                    break;
                  case 6:
                    m[3].push(y[h]);
                    break;
                  case 7:
                    m[4].push(y[h]);
                }
              for (var d = 0; d < 5; d++) {
                for (var S = !1, _ = m[d], h = 0; h < _.length; h++) {
                  switch (d) {
                    case 0:
                      if (l.length >= 0)
                        for (var g = 0; g < l.length; g++)
                          1 === l[g].NameType &&
                            (S = S || n(l[g].Name, _[h].base.Name));
                      else
                        for (
                          var g = 0;
                          g < r.certs[c].subject.types_and_values.length;
                          g++
                        )
                          ("1.2.840.113549.1.9.1" !==
                            r.certs[c].subject.types_and_values[g].type &&
                            "0.9.2342.19200300.100.1.3" !==
                              r.certs[c].subject.types_and_values[g].type) ||
                            (S =
                              S ||
                              n(
                                r.certs[c].subject.types_and_values[g].value
                                  .value_block.value,
                                _[h].base.Name
                              ));
                      break;
                    case 1:
                      if (l.length > 0)
                        for (var g = 0; g < l.length; g++)
                          2 === l[g].NameType &&
                            (S = S || i(l[g].Name, _[h].base.Name));
                      break;
                    case 2:
                      S = o(r.certs[c].subject, _[h].base.Name);
                      break;
                    case 3:
                      if (l.length > 0)
                        for (var g = 0; g < l.length; g++)
                          6 === l[g].NameType &&
                            (S = S || s(l[g].Name, _[h].base.Name));
                      break;
                    case 4:
                      if (l.length > 0)
                        for (var g = 0; g < l.length; g++)
                          7 === l[g].NameType &&
                            (S = S || a(l[g].Name, _[h].base.Name));
                  }
                  if (S) break;
                }
                if (S === !1 && _.length > 0)
                  return (
                    (t.result = !1),
                    (t.result_code = 41),
                    (t.result_message =
                      'Failed to meet "permitted sub-trees" name constraint'),
                    new Promise(function (e, r) {
                      r(t);
                    })
                  );
              }
              for (var b = !1, h = 0; h < k.length; h++) {
                switch (k[h].base.NameType) {
                  case 1:
                    if (l.length >= 0)
                      for (var g = 0; g < l.length; g++)
                        1 === l[g].NameType &&
                          (b = b || n(l[g].Name, k[h].base.Name));
                    else
                      for (
                        var g = 0;
                        g < r.subject.types_and_values.length;
                        g++
                      )
                        ("1.2.840.113549.1.9.1" !==
                          r.subject.types_and_values[g].type &&
                          "0.9.2342.19200300.100.1.3" !==
                            r.subject.types_and_values[g].type) ||
                          (b =
                            b ||
                            n(
                              r.subject.types_and_values[g].value.value_block
                                .value,
                              k[h].base.Name
                            ));
                    break;
                  case 2:
                    if (l.length > 0)
                      for (var g = 0; g < l.length; g++)
                        2 === l[g].NameType &&
                          (b = b || i(l[g].Name, k[h].base.Name));
                    break;
                  case 4:
                    b = b || o(r.certs[c].subject, k[h].base.Name);
                    break;
                  case 6:
                    if (l.length > 0)
                      for (var g = 0; g < l.length; g++)
                        6 === l[g].NameType &&
                          (b = b || s(l[g].Name, k[h].base.Name));
                    break;
                  case 7:
                    if (l.length > 0)
                      for (var g = 0; g < l.length; g++)
                        7 === l[g].NameType &&
                          (b = b || a(l[g].Name, k[h].base.Name));
                }
                if (b) break;
              }
              if (b === !0)
                return (
                  (t.result = !1),
                  (t.result_code = 42),
                  (t.result_message =
                    'Failed to meet "excluded sub-trees" name constraint'),
                  new Promise(function (e, r) {
                    r(t);
                  })
                );
              (y = y.concat(u)), (k = k.concat(p));
            }
            return t;
          }))
        );
      });
  })("undefined" != typeof exports ? exports : window);
var SD_PBKDF2 = "1.2.840.113549.1.5.12",
  SD_PBES2 = "1.2.840.113549.1.5.13";
!(function (e) {
  if ("undefined" == typeof e.org) e.org = {};
  else if ("object" != typeof e.org)
    throw new Error("Name org already exists and it's not an object");
  if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
  else if ("object" != typeof e.org.pkijs)
    throw new Error(
      "Name org.pkijs already exists and it's not an object but " +
        typeof e.org.pkijs
    );
  if ("undefined" == typeof e.org.pkijs.schema) e.org.pkijs.schema = {};
  else if ("object" != typeof e.org.pkijs.schema)
    throw new Error(
      "Name org.pkijs.schema already exists and it's not an object but " +
        typeof e.org.pkijs.schema
    );
  if ("undefined" == typeof e.org.pkijs.schema.pkcs8)
    e.org.pkijs.schema.pkcs8 = {};
  else if ("object" != typeof e.org.pkijs.schema.pkcs8)
    throw new Error(
      "Name org.pkijs.schema.pkcs8 already exists and it's not an object but " +
        typeof e.org.pkijs.schema.pkcs8
    );
  (e.org.pkijs.schema.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52 = function () {
    var t = e.org.pkijs.getNames(arguments[0]);
    return new e.org.pkijs.asn1.SEQUENCE({
      name: t.block_name || "encryptedPrivateKeyInfo",
      value: [
        e.org.pkijs.schema.pkcs8.ENCRYPTIONALGORITHM(
          t.algorithm || {
            names: {
              block_name: t.encryptionAlgorithm || "encryptionAlgorithm",
            },
          }
        ),
        new e.org.pkijs.asn1.OCTETSTRING({
          name: t.encryptedData || "encryptedData",
        }),
      ],
    });
  }),
    (e.org.pkijs.schema.pkcs8.ENCRYPTIONALGORITHM = function () {
      var t = e.org.pkijs.getNames(arguments[0]);
      return new e.org.pkijs.asn1.SEQUENCE({
        name: t.block_name || "",
        value: [
          new e.org.pkijs.asn1.OID({
            name: t.encryptionAlgorithm || "algorithm",
          }),
          e.org.pkijs.schema.pkcs8.PARAMETERS(
            t.encryptionAlgorithm || {
              names: { block_name: t.parameters || "" },
            }
          ),
        ],
      });
    }),
    (e.org.pkijs.schema.pkcs8.PARAMETERS = function () {
      var t = e.org.pkijs.getNames(arguments[0]);
      return new e.org.pkijs.asn1.SEQUENCE({
        name: t.block_name || "",
        value: [
          e.org.pkijs.schema.pkcs8.ENCALG_WithPBKDF2_params(
            t.parameters || {
              names: { block_name: t.EncAlgWPBKDF2params || "" },
            }
          ),
          e.org.pkijs.schema.ALGORITHM_IDENTIFIER(
            t.parameters || { names: { block_name: "encryptionScheme" } }
          ),
        ],
      });
    }),
    (e.org.pkijs.schema.pkcs8.ENCALG_WithPBKDF2_params = function () {
      var t = e.org.pkijs.getNames(arguments[0]);
      return new e.org.pkijs.asn1.SEQUENCE({
        name: t.block_name || "",
        value: [
          new e.org.pkijs.asn1.OID({
            name: t.EncAlgWPBKDF2params || "keyDerivationFunc",
          }),
          e.org.pkijs.schema.cms.PBKDF2_params(
            t.parameters || { names: { block_name: t.keyDevParams || "" } }
          ),
        ],
      });
    });
})("undefined" != typeof exports ? exports : window),
  (function (e) {
    if ("undefined" == typeof e.org) e.org = {};
    else if ("object" != typeof e.org)
      throw new Error("Name org already exists and it's not an object");
    if ("undefined" == typeof e.org.pkijs) e.org.pkijs = {};
    else if ("object" != typeof e.org.pkijs)
      throw new Error(
        "Name org.pkijs already exists and it's not an object but " +
          typeof e.org.pkijs
      );
    if ("undefined" == typeof e.org.pkijs.simpl) e.org.pkijs.simpl = {};
    else if ("object" != typeof e.org.pkijs.simpl)
      throw new Error(
        "Name org.pkijs.simpl already exists and it's not an object but " +
          typeof e.org.pkijs.simpl
      );
    if ("undefined" == typeof e.org.pkijs.simpl.pkcs8)
      e.org.pkijs.simpl.pkcs8 = {};
    else if ("object" != typeof e.org.pkijs.simpl.pkcs8)
      throw new Error(
        "Name org.pkijs.simpl.pkcs8 already exists and it's not an object but " +
          typeof e.org.pkijs.simpl.pkcs8
      );
    (e.org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52 = function () {
      (this.encryptionAlgorithm =
        new e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM()),
        (this.encryptedData = new e.org.pkijs.asn1.OCTETSTRING()),
        arguments[0] instanceof Object && "schema" in arguments[0]
          ? e.org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52.prototype.fromSchema.call(
              this,
              arguments[0].schema
            )
          : arguments[0] instanceof Object &&
            ((this.encryptionAlgorithm =
              arguments[0].encryptionAlgorithm ||
              new e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM()),
            (this.encryptedData =
              arguments[0].encryptedData ||
              new e.org.pkijs.asn1.OCTETSTRING()));
    }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52({
              names: {
                encryptionAlgorithm: "encryptionAlgorithm",
                encryptedData: "encryptedData",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for ENCRYPTED_PRIVATEKEY_INFO_WP52"
            );
          (this.encryptionAlgorithm =
            new e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM({
              schema: r.result.encryptionAlgorithm,
            })),
            (this.encryptedData = r.result.encryptedData);
        }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [this.encryptionAlgorithm.toSchema(), this.encryptedData],
          });
        }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTED_PRIVATEKEY_INFO_WP52.prototype.toJSON =
        function () {
          return {
            encryptionAlgorithm: this.encryptionAlgorithm.toJSON(),
            encryptedData: this.encryptedData.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM = function () {
        (this.algorithm = new e.org.pkijs.asn1.OID()),
          (this.parameters = new e.org.pkijs.simpl.pkcs8.PARAMETERS()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.algorithm =
                arguments[0].algorithm || new e.org.pkijs.asn1.OID()),
              (this.parameters =
                arguments[0].parameters ||
                e.org.pkijs.simpl.pkcs8.PARAMETERS()));
      }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.pkcs8.ENCRYPTIONALGORITHM({
              names: { algorithm: "algorithm", parameters: "parameters" },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for ENCRYPTIONALGORITHM"
            );
          (this.algorithm = r.result.algorithm),
            (this.parameters = new e.org.pkijs.simpl.pkcs8.PARAMETERS({
              schema: r.result.parameters,
            }));
        }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [this.algorithm.toSchema(), this.parameters.toSchema()],
          });
        }),
      (e.org.pkijs.simpl.pkcs8.ENCRYPTIONALGORITHM.prototype.toJSON =
        function () {
          return {
            algorithm: this.algorithm.toJSON(),
            parameters: this.parameters.toJSON(),
          };
        }),
      (e.org.pkijs.simpl.pkcs8.PARAMETERS = function () {
        (this.EncAlgWPBKDF2params =
          new e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params()),
          (this.encryptionScheme =
            new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.pkcs8.PARAMETERS.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.EncAlgWPBKDF2params =
                arguments[0].EncAlgWPBKDF2params ||
                new e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params()),
              (this.encryptionScheme =
                arguments[0].encryptionScheme ||
                new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER()));
      }),
      (e.org.pkijs.simpl.pkcs8.PARAMETERS.prototype.fromSchema = function (t) {
        var r = e.org.pkijs.compareSchema(
          t,
          t,
          e.org.pkijs.schema.pkcs8.PARAMETERS({
            names: {
              EncAlgWPBKDF2params: "EncAlgWPBKDF2params",
              encryptionScheme: "encryptionScheme",
            },
          })
        );
        if (r.verified === !1)
          throw new Error(
            "Object's schema was not verified against input data for PARAMETERS"
          );
        (this.EncAlgWPBKDF2params =
          new e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params({
            schema: r.result.EncAlgWPBKDF2params,
          })),
          (this.encryptionScheme = new e.org.pkijs.simpl.ALGORITHM_IDENTIFIER({
            schema: r.result.encryptionScheme,
          }));
      }),
      (e.org.pkijs.simpl.pkcs8.PARAMETERS.prototype.toSchema = function () {
        return new e.org.pkijs.asn1.SEQUENCE({
          value: [
            this.EncAlgWPBKDF2params.toSchema(),
            this.encryptionScheme.toSchema(),
          ],
        });
      }),
      (e.org.pkijs.simpl.pkcs8.PARAMETERS.prototype.toJSON = function () {
        return {
          EncAlgWPBKDF2params: this.EncAlgWPBKDF2params.toJSON(),
          encryptionScheme: this.encryptionScheme.toJSON(),
        };
      }),
      (e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params = function () {
        (this.keyDerivationFunc = new e.org.pkijs.asn1.OID()),
          (this.keyDevParams = new e.org.pkijs.simpl.cms.PBKDF2_params()),
          arguments[0] instanceof Object && "schema" in arguments[0]
            ? e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params.prototype.fromSchema.call(
                this,
                arguments[0].schema
              )
            : arguments[0] instanceof Object &&
              ((this.keyDerivationFunc =
                arguments[0].keyDerivationFunc || new e.org.pkijs.asn1.OID()),
              (this.keyDevParams =
                arguments[0].keyDevParams ||
                new e.org.pkijs.simpl.cms.PBKDF2_params()));
      }),
      (e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params.prototype.fromSchema =
        function (t) {
          var r = e.org.pkijs.compareSchema(
            t,
            t,
            e.org.pkijs.schema.pkcs8.ENCALG_WithPBKDF2_params({
              names: {
                keyDerivationFunc: "keyDerivationFunc",
                keyDevParams: "keyDevParams",
              },
            })
          );
          if (r.verified === !1)
            throw new Error(
              "Object's schema was not verified against input data for ENCALG_WithPBKDF2_params"
            );
          (this.keyDerivationFunc = r.result.keyDerivationFunc),
            (this.keyDevParams = new e.org.pkijs.simpl.cms.PBKDF2_params({
              schema: r.result.keyDevParams,
            }));
        }),
      (e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params.prototype.toSchema =
        function () {
          return new e.org.pkijs.asn1.SEQUENCE({
            value: [
              this.keyDerivationFunc.toSchema(),
              this.keyDevParams.toSchema(),
            ],
          });
        }),
      (e.org.pkijs.simpl.pkcs8.ENCALG_WithPBKDF2_params.prototype.toJSON =
        function () {
          return {
            keyDerivationFunc: this.keyDerivationFunc.toJSON(),
            keyDevParams: this.keyDevParams.toJSON(),
          };
        });
  })("undefined" != typeof exports ? exports : window);
var SD_SHA1_NAME = "SHA-1",
  SD_SHA256_NAME = "SHA-256",
  SD_SHA384_NAME = "SHA-384",
  SD_SHA512_NAME = "SHA-512",
  CMS_SIGNED_DATA = "1.2.840.113549.1.7.2",
  DATA_CONTENT_TYPE = "1.2.840.113549.1.7.1",
  SD_RSAENCRYPTION = "1.2.840.113549.1.1.1",
  SD_SHA1_DOID = "1.3.14.3.2.26",
  SD_SHA256_DOID = "2.16.840.1.101.3.4.2.1",
  SD_SHA384_DOID = "2.16.840.1.101.3.4.2.2",
  SD_SHA512_DOID = "2.16.840.1.101.3.4.2.3",
  SD_UNKNOWN_ALGORITHM = "An unknown hash Algorithm was supplied",
  SD_ERROR_ENCODING_PKCS7 = "Error during encoding of PKCS#7 Signed Data: ",
  SD_ERROR_EMPTY_SIGNATURE =
    "An empty or invalid value was received for signature",
  SD_ERROR_EMPTY_CERTIFICATE =
    "An empty or invalid value was received for certificate",
  SD_TripleDES = "1.2.840.113549.3.7",
  SD_AES_128 = "2.16.840.1.101.3.4.1.2",
  SD_AES_192 = "2.16.840.1.101.3.4.1.22",
  SD_AES_256 = "2.16.840.1.101.3.4.1.42",
  SDSGL_ERR_00 = "Unexpected format or file",
  SDSGL_ERR_01 = "Error while decoding pkcs#8 encrypted private key stream",
  SDSGL_ERR_02 = "Invalid or unsupported algorithm: ",
  SDSGL_ERR_03 = "Error while decrypting private key",
  SDSGL_ERR_04 = "Error while deriving symmetric key",
  ADJUSTED_3DES_KEY_LENGTH = 48;
(longToByteArray = function (e) {
  for (var t = [0, 0, 0, 0, 0, 0, 0, 0], r = 0; r < t.length; r++) {
    var i = 255 & e;
    (t[r] = i), (e = (e - i) / 256);
  }
  return t;
}),
  (byteArrayToLong = function (e) {
    for (var t = 0, r = e.length - 1; r >= 0; r--) t = 256 * t + e[r];
    return t;
  });
var decodeHexStringToByteArray = function (e) {
  for (var t = []; e.length >= 2; )
    t.push(parseInt(e.substring(0, 2), 16)), (e = e.substring(2, e.length));
  return t;
};
String.prototype.startsWith ||
  (String.prototype.startsWith = function (e, t) {
    return (t = t || 0), this.substr(t, e.length) === e;
  });
var OID_ALG = "1.2.840.113549.1.5.3",
  openOldKey = function (e, t) {
    for (
      var r = (forge.pki, forge.asn1),
        i = r.fromDer(forge.util.createBuffer(e, "binary")),
        n = "";
      ;

    ) {
      if (16 != i.type || 1 != i.composed) {
        n = "No se ha recibido una llave privada: " + i.type;
        break;
      }
      if (2 != i.value.length) {
        n = "No se ha recibido una llave privada: " + i.value.length;
        break;
      }
      if (16 != i.value[0].type || 1 != i.value[0].composed) {
        n = "No se ha recibido una llave privada: " + i.value[0].type;
        break;
      }
      if (2 != i.value[0].value.length) {
        n = "No se ha recibido una llave privada: " + i.value[0].value.length;
        break;
      }
      if (6 != i.value[0].value[0].type) {
        n = "No se ha recibido una llave privada: " + i.value[0].value.length;
        break;
      }
      var s = r.derToOid(i.value[0].value[0].value);
      if (OID_ALG != s) {
        n = "Algoritmo de cifrado no esperado: " + s;
        break;
      }
      if (2 != i.value[0].value[1].value.length) {
        n = "No se ha recibido una llave privada: " + i.value[1].value.length;
        break;
      }
      if (4 != i.value[0].value[1].value[0].type) {
        n =
          "No se localizaron los bytes del salt: " +
          i.value[0].value[1].value[0].type;
        break;
      }
      var a = i.value[0].value[1].value[0].value,
        o = forge.util.bytesToHex(i.value[0].value[1].value[1].value),
        c = byteArrayToLong(decodeHexStringToByteArray(o)),
        l = i.value[1].value,
        u = pbkdf1(t, forge.util.bytesToHex(a), c, forge.util.bytesToHex(l));
      ("" != u && u.startsWith("3082")) ||
        (n =
          "Error al desencriptar la llave privada, de favor verifique su password.");
      break;
    }
    return "" == n ? forge.util.encode64(forge.util.hexToBytes(u)) : "" + n;
  },
  SHA1 = "sha1",
  SHA256 = "sha256",
  SHA384 = "sha384",
  SHA512 = "sha512",
  CERT_HEADER = "-----BEGIN CERTIFICATE-----",
  CERT_TRAILER = "-----END CERTIFICATE-----",
  KEY_HEADER = "-----BEGIN PRIVATE KEY-----",
  KEY_TRAILER = "-----END PRIVATE KEY-----",
  SHA1_LEN = 20,
  SHA256_LEN = 32,
  SHA384_LEN = 48,
  SHA512_LEN = 64,

  signHash_2 = function (e, t, r, i, n, s) {
    try {
      if (!analizeSignatureVsHashAlg(e, t)) {
        var a =
          "[Error] - El algoritmo no corresponde con el hash proporcionado";
        return a;
      }
      var o,
        c,
        l = r,
        u = i;
      if (s)
        if (l.startsWith("MI")) c = CERT_HEADER + l + CERT_TRAILER;
        else if (l.startsWith("LS0tL")) c = forge.util.decode64(l);
        else {
          if (!l.startsWith("TUlJ")) {
            var a = "[Error] - No se ha seleccionado un certificado digital";
            return a;
          }
          c = CERT_HEADER + forge.util.decode64(certB64Byte) + CERT_TRAILER;
        }
      if (u.startsWith("MI")) o = KEY_HEADER + u + KEY_TRAILER;
      else if (u.startsWith("LS0tL")) o = forge.util.decode64(u);
      else {
        if (!u.startsWith("TUlJ")) {
          var a = "[Error] - No se ha seleccionado una llave privada";
          return a;
        }
        o = KEY_HEADER + forge.util.decode64(u) + KEY_TRAILER;
      }
      var p = forge.pki;
      if (s) var h = p.certificateFromPem(c);
      var f = p.privateKeyFromPem(o);
      if (null === f) {
        var a =
          "[Error] - No fue posible acceder a la llave privada. Posiblemente la clave no sea correcta";
        return a;
      }
      if (s && !checkConsistency(f, h.publicKey)) {
        var a = "[Error] - El par de llaves proporcionado no es consistente";
        return a;
      }

      var g,
        m = forge.util.decode64(e),
        d = packHash(t, m),
        y = forge.pki.rsa.encrypt(d.data, f, 1);
      return (g = s ? codeDetachedPKCS7(c, y, t) : forge.util.encode64(y));
    } catch (k) {
      var v = "[Error] - " + k.message;
      return (
        v.indexOf(
          "Cannot read private key. ASN.1 object does not contain an RSAPrivateKey"
        ) != -1 &&
          (v =
            "[Error] - No fue posible acceder a la llave privada. Es posible que la clave no sea correcta"),
        v
      );
    }
  },

  checkConsistency = function (e, t) {
    var r = forge.md.sha1.create();
    r.update("SeguriData");
    var i,
      n = e.sign(r);
    try {
      i = t.verify(r.digest().bytes(), n);
    } catch (s) {
      i = !1;
    }
    return i;
  },
  analizeSignatureVsHashAlg = function (e, t) {
    var r = forge.util.decode64(e),
      i = -1;
    return (
      t == SHA1
        ? (i = SHA1_LEN)
        : t == SHA256
        ? (i = SHA256_LEN)
        : t == SHA384
        ? (i = SHA384_LEN)
        : t == SHA512 && (i = SHA512_LEN),
      r.length == i
    );
  },
  packHash = function (e, t) {
    var r = forge.asn1,
      i = forge.pki,
      n = r.create(r.Class.UNIVERSAL, r.Type.SEQUENCE, !0, [
        r.create(r.Class.UNIVERSAL, r.Type.SEQUENCE, !0, [
          r.create(
            r.Class.UNIVERSAL,
            r.Type.OID,
            !1,
            r.oidToDer(i.oids[e]).getBytes()
          ),
          r.create(r.Class.UNIVERSAL, r.Type.NULL, !1, ""),
        ]),
        r.create(r.Class.UNIVERSAL, r.Type.OCTETSTRING, !1, t),
      ]),
      s = r.toDer(n);
    return s;
  },
  getCertificateFromPEM = function (e) {
    var t = forge.pki,
      r = t.certificateFromPem(e);
    return r;
  },
  codeIssuerAndSerialNumber = function (e) {
    var t = (forge.pki, forge.asn1),
      r = e.value[0].value[1],
      i = e.value[0].value[3],
      n = t.create(t.Class.UNIVERSAL, t.Type.SEQUENCE, !0, [
        i,
        t.create(t.Class.UNIVERSAL, t.Type.INTEGER, !1, r.value),
      ]);
    t.toDer(n);
    return n;
  },
  codeSignerInfo = function (e, t, r) {
    var i = forge.asn1,
      n = forge.pki,
      s = i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
        i.create(i.Class.UNIVERSAL, i.Type.INTEGER, !1, ""),
        codeIssuerAndSerialNumber(e),
        i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
          i.create(
            i.Class.UNIVERSAL,
            i.Type.OID,
            !1,
            i.oidToDer(n.oids[r]).getBytes()
          ),
          i.create(i.Class.UNIVERSAL, i.Type.NULL, !1, ""),
        ]),
        i.create(i.Class.UNIVERSAL, i.Type.SEQUENCE, !0, [
          i.create(
            i.Class.UNIVERSAL,
            i.Type.OID,
            !1,
            i.oidToDer(n.oids.rsaEncryption).getBytes()
          ),
          i.create(i.Class.UNIVERSAL, i.Type.NULL, !1, ""),
        ]),
        i.create(i.Class.UNIVERSAL, i.Type.OCTETSTRING, !1, t),
      ]),
      a = i.toDer(s);
    return a;
  },
  packCertificate = function (e) {
    var t = forge.asn1,
      r = t.create(t.Class.UNIVERSAL, t.Type.CONTEXT_SPECIFIC, !0, e);
    t.toDer(r);
    return r;
  },
  codeHashAlgorithm = function (e) {
    var t = forge.asn1,
      r = forge.pki,
      i = t.create(t.Class.UNIVERSAL, t.Type.SEQUENCE, !0, [
        t.create(
          t.Class.UNIVERSAL,
          t.Type.OID,
          !1,
          t.oidToDer(r.oids[e]).getBytes()
        ),
        t.create(t.Class.UNIVERSAL, t.Type.NULL, !1, ""),
      ]);
    t.toDer(i);
    return i;
  },
  codePkcs7DataOid = function (e) {
    var t = forge.asn1,
      r = forge.pki,
      i = t.create(t.Class.UNIVERSAL, t.Type.SEQUENCE, !0, [
        t.create(
          t.Class.UNIVERSAL,
          t.Type.OID,
          !1,
          t.oidToDer(r.oids[e]).getBytes()
        ),
      ]),
      n = t.toDer(i);
    return n;
  },
  codeSignedData = function (e, t, r, i) {
    var n = forge.asn1,
      s = forge.pki,
      a = n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
        n.create(
          n.Class.UNIVERSAL,
          n.Type.OID,
          !1,
          n.oidToDer(s.oids.signedData).getBytes()
        ),
        n.create(n.Class.CONTEXT_SPECIFIC, 160, !0, [
          n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
            n.create(n.Class.UNIVERSAL, n.Type.INTEGER, !1, ""),
            n.create(n.Class.UNIVERSAL, n.Type.SET, !0, [
              n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
                n.create(
                  n.Class.UNIVERSAL,
                  n.Type.OID,
                  !1,
                  n.oidToDer(s.oids[r]).getBytes()
                ),
                n.create(n.Class.UNIVERSAL, n.Type.NULL, !1, ""),
              ]),
            ]),
            n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
              n.create(
                n.Class.UNIVERSAL,
                n.Type.OID,
                !1,
                n.oidToDer(s.oids.data).getBytes()
              ),
            ]),
            n.create(n.Class.CONTEXT_SPECIFIC, 160, !1, e),
            n.create(n.Class.UNIVERSAL, n.Type.SET, !0, [
              n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
                n.create(n.Class.UNIVERSAL, n.Type.INTEGER, !1, ""),
                codeIssuerAndSerialNumber(t),
                n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
                  n.create(
                    n.Class.UNIVERSAL,
                    n.Type.OID,
                    !1,
                    n.oidToDer(s.oids[r]).getBytes()
                  ),
                  n.create(n.Class.UNIVERSAL, n.Type.NULL, !1, ""),
                ]),
                n.create(n.Class.UNIVERSAL, n.Type.SEQUENCE, !0, [
                  n.create(
                    n.Class.UNIVERSAL,
                    n.Type.OID,
                    !1,
                    n.oidToDer(s.oids.rsaEncryption).getBytes()
                  ),
                ]),
                n.create(n.Class.UNIVERSAL, n.Type.OCTETSTRING, !1, i),
              ]),
            ]),
          ]),
        ]),
      ]),
      o = n.toDer(a),
      c = forge.util.encode64(forge.util.hexToBytes(forge.util.bytesToHex(o)));
    return c;
  },
  codeDetachedPKCS7 = function (e, t, r) {
    var i = (forge.asn1, forge.pki),
      n = getCertificateFromPEM(e),
      s = i.certificateToAsn1(n),
      a = e
        .replace("-----BEGIN CERTIFICATE-----", "")
        .replace("-----END CERTIFICATE-----", "");
    a = forge.util.decode64(a);
    var o = codeSignedData(a, s, r, t);
    return o;
  };
